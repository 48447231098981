import React from 'react'
import PropTypes from 'prop-types'

import ProductNestedFieldCell from '../ProductNestedFieldCell'
import LocationCategorySelect from './LocationCategorySelect'

const LocationCategoryCell = ({ productValues, locationCategory, setLocationCategory }) => {
  return (
    <ProductNestedFieldCell productValues={productValues} productFieldPath="location.category.name">
      <LocationCategorySelect locationCategory={locationCategory} setLocationCategory={setLocationCategory} />
    </ProductNestedFieldCell>
  )
}

LocationCategoryCell.propTypes = {
  productValues: PropTypes.object,
  locationCategory: PropTypes.object,
  setLocationCategory: PropTypes.func
}

export default LocationCategoryCell

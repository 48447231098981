import React from 'react'
import PropTypes from 'prop-types'

import ContractFieldWrapper from '../ContractFieldWrapper'
import ControllerMembersPaginatedSelect from '../../ControllerMembersPaginatedSelect'

import { BUYER } from '../../../fields'

const BuyerField = ({ formik, isEditMode }) => {
  const { values, errors, touched } = formik

  const buyer = values[BUYER]
  const error = errors[BUYER]
  const fieldTouched = touched[BUYER]

  const preview = buyer?.label

  return (
    <ContractFieldWrapper
      label={'Buyer'}
      maxWidth={'200px'}
      isEditMode={isEditMode}
      preview={preview}
      error={error}
      fieldTouched={fieldTouched}
    >
      <ControllerMembersPaginatedSelect formik={formik} />
    </ContractFieldWrapper>
  )
}

BuyerField.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired
}

export default BuyerField

import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import {
  getMediaProductLocationCategoriesIsLoadingSelector,
  mediaProductLocationCategoriesSelector
} from '../../../../../modules/selectors/mediaOrdersProductLocations'

export const LOCATION_CATEGORIES = 'locationCategories'

export const LocationCategoriesFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const mediaProductLocationCategories = useSelector(mediaProductLocationCategoriesSelector)
  const getMediaProductLocationCategoriesIsLoading = useSelector(getMediaProductLocationCategoriesIsLoadingSelector)

  const { [LOCATION_CATEGORIES]: locationCategories } = filtersState

  const formattedMediaProductLocationCategoriesOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationCategories])

  const setLocationCategoryHandler = useCallback(
    (name, newLocationCategories) => {
      const updatedFiltersState = {
        ...filtersState,
        [LOCATION_CATEGORIES]: newLocationCategories
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  return (
    <MultiSelectBox
      name="Location Category"
      options={formattedMediaProductLocationCategoriesOptions}
      isLoading={getMediaProductLocationCategoriesIsLoading}
      value={locationCategories}
      placeholder="Location Category"
      setFieldValue={setLocationCategoryHandler}
      portaled
    />
  )
}

LocationCategoriesFilter.propTypes = {
  Context: PropTypes.object
}

export default LocationCategoriesFilter

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import TableNoData from '../../../../../components/Table/TableNoData'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import ProductSubCategoriesTableData from './ProductSubCategoriesTableData'

import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import { clearGetMediaSubCategories, getMediaSubCategories } from '../../../../../modules/actions/mediaOrdersProducts'
import {
  getMediaSubCategoriesErrorSelector,
  getMediaSubCategoriesIsLoadingSelector,
  mediaSubCategoriesNextSelector,
  mediaSubCategoriesSelector,
  mediaSubCategoriesWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { columnsSchema } from './constants/columns'

const ProductSubCategoriesTable = () => {
  const { t } = useTranslation()

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const mediaSubCategoriesNext = useSelector(mediaSubCategoriesNextSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const params = useMemo(
    () => ({
      controller: selectedControllerId
    }),
    [selectedControllerId]
  )

  const loadMoreHandler = useLoadPaginatedList({
    params,
    action: getMediaSubCategories,
    clearAction: clearGetMediaSubCategories,
    next: mediaSubCategoriesNext
  })

  const skeletonProps = useMemo(() => ({ cols: columnsSchema }), [])

  return (
    <TableDataLoader
      itemsLength={mediaSubCategories.length}
      errorSelector={getMediaSubCategoriesErrorSelector}
      wasLoadedSelector={mediaSubCategoriesWasLoadedSelector}
      isLoadingSelector={getMediaSubCategoriesIsLoadingSelector}
      skeletonProps={skeletonProps}
      noDataContent={<TableNoData title={t('There are no asset types')} />}
      loadMore={loadMoreHandler}
    >
      <ProductSubCategoriesTableData />
    </TableDataLoader>
  )
}

export default ProductSubCategoriesTable

import React, { useMemo } from 'react'
import { isFuture } from 'date-fns'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../components/Button'

import { combineDateAndTime } from '../../../../../../../helpers/date'

import useStyles from './styles'

const ButtonColumn = ({ requirementFileId, mediaId, uploadedFile, deadline, openUploadFileForm, handleDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const deadlineDate = useMemo(() => new Date(deadline), [deadline])
  const isDeadlineInFuture = isFuture(combineDateAndTime(deadlineDate, '23:59'))
  return !!uploadedFile ? (
    uploadedFile?.approval_status !== 'approved' && isDeadlineInFuture && (
      <Button onClick={handleDelete({ requirementFileId, uploadedFile, mediaId })} className={classes.replaceButton}>
        {t('Replace')}
      </Button>
    )
  ) : (
    <Button solid onClick={openUploadFileForm(requirementFileId, mediaId)}>
      {t('Upload')}
    </Button>
  )
}

ButtonColumn.propTypes = {
  requirementFileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mediaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  uploadedFile: PropTypes.object,
  deadline: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  openUploadFileForm: PropTypes.func.isRequired
}

export default ButtonColumn

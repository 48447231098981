import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Field from '../../../../components/Form/Field'
import Tabs from '../../../../features/components/Tabs'
import MultipleEmailRecipientsField from '../MultipleEmailRecipientsField'
import SelfAccountMembersPaginatedSelect from '../SelfAccountMembersPaginatedSelect'

import {
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../Multiplatform/MediaOrderForms/AmendmentContractSendForm/fields'

import { EMAIL, NOTIFICATION_EMAILS } from '../fields'
import { getRecipientsOptions } from './fields'

import useStyles from './styles'

const RecipientsTabs = ({ formik, selfAccountId, selfAccountMembersIdsToFilter = [], maxRecipientsCount }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { values, setFieldValue } = formik

  // if maxRecipientsCount is 1, then it is not multi, for other values it is multi
  const isMulti = maxRecipientsCount !== 1
  const recipientsOptions = useMemo(() => getRecipientsOptions(isMulti), [isMulti])

  return (
    <>
      <Tabs
        options={recipientsOptions}
        selectedValue={values[USER_OPTION]}
        onSelectValue={value => setFieldValue(USER_OPTION, value)}
        className={classes.recipientsTabs}
      />
      {values[USER_OPTION] === SEND_TO_PLATFORM_USER ? (
        <SelfAccountMembersPaginatedSelect
          formik={formik}
          selfAccountId={selfAccountId}
          isMulti={isMulti}
          optionsIdsToFilter={selfAccountMembersIdsToFilter}
        />
      ) : isMulti ? (
        <MultipleEmailRecipientsField formik={formik} maxCount={maxRecipientsCount} />
      ) : (
        <Field placeholder={t('Email address')} formik={formik} name={`${NOTIFICATION_EMAILS}[0][${EMAIL}]`} />
      )}
    </>
  )
}

RecipientsTabs.propTypes = {
  formik: PropTypes.object,
  // if value not set it means multi and no limit
  maxRecipientsCount: PropTypes.number,
  selfAccountId: PropTypes.number,
  selfAccountMembersIdsToFilter: PropTypes.arrayOf(PropTypes.number)
}

export default RecipientsTabs

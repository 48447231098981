import { getFormData, madeRequest } from '../../helpers/api'

import store from '../../store'

import { updateFileUploadProgress } from '../actions/files'

import { ENDPOINTS } from '../../constants/api'
import { FOOTFALL_DATA_CSV_FILE } from '../../pages/Settings/FootfallData/UploadFootfallDataSection/FootfallDataFileUpload'

// PLOP_APPEND_PATTERN_ANCHOR

export function getFootfallDataAsCsvService(params) {
  return madeRequest('GET', {
    params,
    returnHeaders: true,
    url: `${ENDPOINTS.footfallData}download_csv/`
  })
}

export function bulkUploadFootfallDataService(data) {
  return madeRequest('POST', {
    data: getFormData(data),
    url: ENDPOINTS.footfallDataBulkUpload,
    onUploadProgress: event => {
      if (event.total) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total)
        store.dispatch(
          updateFileUploadProgress({
            [FOOTFALL_DATA_CSV_FILE]: percentCompleted
          })
        )
      }
    }
  })
}

export function getFootfallAreasService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.footfallAreas
  })
}

export function createFootfallAreasService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.footfallAreas
  })
}

export function updateFootfallAreaService(id, data) {
  return madeRequest('PUT', {
    data,
    url: `${ENDPOINTS.footfallAreas}${id}/`
  })
}

export function updateFootfallAreasService(id, data, requestMethod) {
  return madeRequest(requestMethod || 'PUT', {
    data,
    url: `${ENDPOINTS.footfallAreas}${id}/`
  })
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { bulkUpdateBookingMediaFile } from '../../../../../../../modules/actions/mediaOrdersBookings'
import { BUYER_DISAPPROVED, DISAPPROVED } from '../../../../../../../features/components/UploadedFilesField/helpers'

export default function useFileStatusUpdateToBE() {
  const dispatch = useDispatch()

  const bulkUpdateStatusHandler = useCallback(
    ({ ids, status, disapprovedReason }) => {
      dispatch(
        bulkUpdateBookingMediaFile({
          ids,
          approval_status: status,
          ...(status === DISAPPROVED && { disapproved_reason: disapprovedReason }),
          ...(status === BUYER_DISAPPROVED && { buyer_disapproved_reason: disapprovedReason })
        })
      )
    },
    [dispatch]
  )

  return bulkUpdateStatusHandler
}

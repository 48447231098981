import React from 'react'

import ImagePreview from './ImagePreview'

const ImagesPreview = React.memo(({ images, fileRemove }) => {
  return images.map((file, index) => {
    return <ImagePreview file={file} fileRemove={fileRemove} index={index} />
  })
})

export default ImagesPreview

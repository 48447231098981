import React, { forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMergeRefs } from '@floating-ui/react'

import { useTooltipContext } from '../index'

import useStyles from './styles'

export const TooltipTrigger = forwardRef(({ children, asChild = false, className, ...props }, propRef) => {
  const classes = useStyles()
  const context = useTooltipContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.isOpen ? 'open' : 'closed'
      })
    )
  }

  return (
    <div
      className={classnames(className, classes.triggerElement)}
      ref={ref}
      {...context.getReferenceProps(props)}
      data-state={context.isOpen ? 'open' : 'closed'}
    >
      {children}
    </div>
  )
})

TooltipTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  asChild: PropTypes.bool,
  className: PropTypes.string
}

export default TooltipTrigger

import React from 'react'
import PropTypes from 'prop-types'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductCreateForm from './MediaProductCreateForm'

import { PRODUCT_CREATE } from '../../../../../constants/forms'

const MediaProductCreate = ({ controllerId }) => {
  return (
    <FormDrawerWrapper
      showOpenButton={false}
      formName={PRODUCT_CREATE}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
    >
      <MediaProductCreateForm controllerId={controllerId} />
    </FormDrawerWrapper>
  )
}

MediaProductCreate.propTypes = {
  controllerId: PropTypes.number.isRequired
}

export default MediaProductCreate

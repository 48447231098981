import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'
import StoresPaginatedSelectField from '../../StoresPaginatedSelectField'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'

import { clearUpdateFootfallArea, updateFootfallArea } from '../../../../../../modules/actions/footfallData'
import {
  updateFootfallAreaErrorSelector,
  updateFootfallAreaIsLoadingSelector,
  footfallAreaWasUpdatedSelector
} from '../../../../../../modules/selectors/footfallData'

import { FOOTFALL_AREAS_EDIT } from '../../../../../../constants/forms'
import { NAME } from '../../fields'
import { getInitialValues } from './fields'
import { validationSchema } from '../../validation'
import { transformValuesToBE } from '../../CreateFootfallAreas/CreateFootfallAreasForm/formatters'

function FootfallAreasEditForm({ footfallAreaData }) {
  const dispatch = useDispatch()
  const editId = footfallAreaData?.id

  const footfallAreaWasUpdated = useSelector(footfallAreaWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(updateFootfallArea(editId, transformedData))
    },
    [dispatch, editId]
  )

  const initialValues = useMemo(() => getInitialValues(footfallAreaData), [footfallAreaData])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearUpdateFootfallArea()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={FOOTFALL_AREAS_EDIT}
      // processing
      successSubmit={footfallAreaWasUpdated}
      errorSelector={updateFootfallAreaErrorSelector}
      isLoadingSelector={updateFootfallAreaIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <FieldsSection title="Footfall Areas">
        <FieldRow title="Name">
          <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
        </FieldRow>
        <FieldRow title="Stores">
          <StoresPaginatedSelectField formik={formik} />
        </FieldRow>
      </FieldsSection>
    </Form>
  )
}

export default FootfallAreasEditForm

import { useCallback } from 'react'

import { replaceRouteParams } from '../../../../../../../helpers/url'

import { ROUTE_PARAMS, ROUTES } from '../../../../../../../constants/routes'

export const useOpenMediaOrderSummaryInNewTab = ({ mediaOrderId, accountId }) => {
  const routeWithParams = replaceRouteParams(ROUTES.mediaOrderSummary, {
    [ROUTE_PARAMS.selfAccount]: accountId,
    [ROUTE_PARAMS.mediaOrderId]: mediaOrderId
  })

  return useCallback(() => {
    window.open(routeWithParams, '_blank', 'noopener,noreferrer')
  }, [routeWithParams])
}

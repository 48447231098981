import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../../../components/Form/ProgressButton'

import { useClearMediaOrderForm } from '../../hooks/useClearMediaOrderForm'
import useRedirectFormDrawer from '../../../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import { clearCreateQuotation, createQuotation } from '../../../../../../modules/actions/quotations'
import {
  createdQuotationSelector,
  createQuotationErrorSelector,
  createQuotationIsLoadingSelector,
  quotationWasCreatedSelector
} from '../../../../../../modules/selectors/quotations'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'

import { ROUTE_PARAMS, ROUTES } from '../../../../../../constants/routes'

import useStyles from './styles'

function QuotationCreate({ contractId, buttonText }) {
  const dispatch = useDispatch()

  const classes = useStyles()

  const createdQuotation = useSelector(createdQuotationSelector)
  const selfAccountId = useSelector(selectedSelfAccountSelector)
  const quotationWasCreated = useSelector(quotationWasCreatedSelector)
  const createQuotationError = useSelector(createQuotationErrorSelector)
  const createQuotationIsLoading = useSelector(createQuotationIsLoadingSelector)

  const handleSubmitFormClear = useClearMediaOrderForm()
  const handleRedirect = useRedirectFormDrawer()

  const quotationId = createdQuotation?.id

  const handleQuotationCreate = useCallback(() => {
    // to create a quotation on BE we actually update the contract and set quotation field to true
    dispatch(
      createQuotation(contractId, {
        quotation: true,
        without_requirements: true
      })
    )
  }, [dispatch, contractId])

  const onQuotationCreate = useCallback(() => {
    handleSubmitFormClear()

    handleRedirect(
      {
        [ROUTE_PARAMS.selfAccount]: selfAccountId,
        id: quotationId
      },
      ROUTES.proposalsEdit
    )
  }, [selfAccountId, quotationId, handleSubmitFormClear, handleRedirect])

  const handleClear = useCallback(() => {
    dispatch(clearCreateQuotation())
  }, [dispatch])

  return (
    <ProgressButton
      onClick={handleQuotationCreate}
      successSubmit={quotationWasCreated}
      isFormLoading={createQuotationIsLoading}
      formError={createQuotationError}
      clearHandler={handleClear}
      onSuccessSubmit={onQuotationCreate}
      solid={false}
      wrapperClassName={classes.buttonWrapper}
    >
      {buttonText}
    </ProgressButton>
  )
}

QuotationCreate.propTypes = {
  contractId: PropTypes.number,
  className: PropTypes.string,
  buttonText: PropTypes.string
}

export default QuotationCreate

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useManageFormsDrawer from './useManageFormsDrawer'

// this hook helps to avoid data fetching when drawer is not closed
// also this helps to clear data when drawer was closed during the data loading process or data was cached
export default function useManageEditFormData({ formName, getDataHandler, clearDataHandler, loadedDataId }) {
  const dispatch = useDispatch()

  const { isFormOpen, selectedEditItemId } = useManageFormsDrawer({
    onAfterFormClose: clearDataHandler,
    formName
  })

  useEffect(() => {
    // fetch data only when the form is opened and the edit item is selected
    if (isFormOpen && selectedEditItemId) {
      getDataHandler(selectedEditItemId)
    }
  }, [dispatch, isFormOpen, getDataHandler, selectedEditItemId])

  useEffect(() => {
    // clear data if some wrong data is loaded, here are few cases how it can be:
    // - some data could be cached(fetched not cleared from different pages)
    // - user could open edit form and close without waiting for data to be loaded, the default clear is not invoked

    const isCorrectDataLoaded = String(loadedDataId) === String(selectedEditItemId)
    if (loadedDataId && selectedEditItemId && !isCorrectDataLoaded) {
      clearDataHandler()
    }
  }, [clearDataHandler, selectedEditItemId, loadedDataId])

  return selectedEditItemId
}

import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../../../components/Form'
import RecipientsTable from '../../../../../../../features/components/MediaOrders/RecipientsTable'
import UpdateMediaOrderRecipientsFormContent from './UpdateMediaOrderRecipientsFormContent'

import { useMediaOrderRecipients } from '../../../../../../../features/components/MediaOrders/RecipientsTable/useMediaOrderRecipients'

import { transformValuesToBE } from './formatters'
import { showToasts } from '../../../../../../../helpers/toasts'
import { selectorPlaceholder } from '../../../../../../../hooks/useRequestHandler'

import {
  mediaOrderSelector,
  updateMediaOrderErrorSelector,
  updateMediaOrderIsLoadingSelector,
  updateMediaOrderWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrders'
import { membersSelector } from '../../../../../../../modules/selectors/selfAccountTeam'
import { clearUpdateMediaOrder, updateMediaOrder } from '../../../../../../../modules/actions/mediaOrders'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'

import { UPDATE_MEDIA_ORDER_RECIPIENTS } from '../../../../../../../constants/forms'

import { TOAST_TYPE } from '../../../../../../../constants/other'
import { validationSchema } from './validation'
import { getInitialValues } from './fields'

function UpdateMediaOrderRecipientsForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [newRecipientSubmitted, setNewRecipientSubmitted] = useState(false)

  const members = useSelector(membersSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaOrderWasUpdated = useSelector(updateMediaOrderWasUpdatedSelector)
  const updateMediaOrderIsLoading = useSelector(updateMediaOrderIsLoadingSelector)
  const { id: mediaOrderId } = mediaOrder
  const { hasRecipients } = useMediaOrderRecipients()

  const onSubmit = useCallback(
    values => {
      const formattedValues = transformValuesToBE(mediaOrder, values)

      const data = {
        ...formattedValues,
        controller: controllerId
      }

      setNewRecipientSubmitted(true)
      dispatch(updateMediaOrder(mediaOrderId, data))
    },
    [dispatch, controllerId, mediaOrder, mediaOrderId]
  )

  const initialValues = useMemo(() => {
    return getInitialValues(members)
  }, [members])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const onSuccessSubmitHandler = useCallback(() => {
    showToasts({
      type: TOAST_TYPE.success,
      message: t('Confirmation email sent successfully')
    })
  }, [t])

  const clearItemSubmit = useCallback(() => {
    setNewRecipientSubmitted(false)
    dispatch(clearUpdateMediaOrder())
  }, [dispatch])

  return (
    <Form
      formik={formik}
      formName={UPDATE_MEDIA_ORDER_RECIPIENTS}
      buttonProps={{
        disabled: updateMediaOrderIsLoading
      }}
      // processing
      successSubmit={newRecipientSubmitted && mediaOrderWasUpdated}
      errorSelector={newRecipientSubmitted ? updateMediaOrderErrorSelector : selectorPlaceholder}
      isLoadingSelector={newRecipientSubmitted ? updateMediaOrderIsLoadingSelector : selectorPlaceholder}
      // after form submit
      clearHandler={clearItemSubmit}
      onSuccessSubmit={onSuccessSubmitHandler}
      submitText="Send"
    >
      {hasRecipients && <RecipientsTable />}
      <UpdateMediaOrderRecipientsFormContent formik={formik} />
    </Form>
  )
}

export default UpdateMediaOrderRecipientsForm

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

import Button from '../../../components/Button'
import TableActionsSkeleton from '../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/TableActions/Skeleton'
import TableDataLoader from '../../../components/Table/TableDataLoader'
import ProposalBuilderPageBreadcrumbs from './ProposalBuilderPageBreadcrumbs'
import ProductsCalendarSkeleton from '../ProductsCalendarPreview/SkeletonToggle'
import AdditionalContractInfoSkeleton from '../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/AdditionalContractInfo/AdditionalContractInfoSkeleton'

import {
  contractWasLoadedSelector,
  getContractErrorSelector,
  getContractIsLoadingSelector
} from '../../../modules/selectors/contracts'

import useCommonStyles from '../../../styles/common/listPage'

function SkeletonQuotationBuilderForm({ showHeading = true, hasTopMargin = true }) {
  const commonClasses = useCommonStyles()

  return (
    <div>
      {showHeading && (
        <div className={commonClasses.heading}>
          <ProposalBuilderPageBreadcrumbs />
          <Button isSmall>
            <Skeleton width={120} />
          </Button>
        </div>
      )}
      <AdditionalContractInfoSkeleton hasTopMargin={hasTopMargin} />
      <ProductsCalendarSkeleton />
      <TableActionsSkeleton />
      <TableDataLoader
        errorSelector={getContractErrorSelector}
        wasLoadedSelector={contractWasLoadedSelector}
        isLoadingSelector={getContractIsLoadingSelector}
      />
    </div>
  )
}

SkeletonQuotationBuilderForm.propTypes = {
  showHeading: PropTypes.bool,
  hasTopMargin: PropTypes.bool
}

export default SkeletonQuotationBuilderForm

import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

import Field from '../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../features/formatters'

import {
  getMediaSubCategoriesIsLoadingSelector,
  mediaSubCategoriesSelector,
  mediaSubCategoriesWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'

import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_SUB_CATEGORY, MEDIA_SUB_SUB_CATEGORY } from '../../fields'
import { MEDIA_CATEGORY } from '../../MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const MediaSubAndSubSubCategoryFields = ({ formik }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const { values, setFieldValue } = formik

  const getMediaSubCategoriesIsLoading = useSelector(getMediaSubCategoriesIsLoadingSelector)
  const mediaSubCategoriesWasLoaded = useSelector(mediaSubCategoriesWasLoadedSelector)

  const {
    [MEDIA_CATEGORY]: mediaCategory,
    [MEDIA_SUB_CATEGORY]: mediaSubCategoryId,
    [MEDIA_SUB_SUB_CATEGORY]: mediaSubSubCategoryId
  } = values

  const formattedMediaSubCategoriesOptions = useMemo(
    () =>
      formatOptionsList({
        list: mediaSubCategories,
        valueName: 'id',
        labelName: 'name'
      }),
    [mediaSubCategories]
  )

  const formattedMediaSubSubCategoriesOptions = useMemo(() => {
    const selectedMediaSubCategory = mediaSubCategories.find(
      mediaSubCategory => mediaSubCategory.id === mediaSubCategoryId
    )
    const subSubCategories = selectedMediaSubCategory ? selectedMediaSubCategory.sub_sub_categories : []
    return formatOptionsList({
      list: subSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaSubCategories, mediaSubCategoryId])

  const loadMediaSubCategories = useCallback(() => {
    dispatch(
      getMediaSubCategories({
        controller: selectedControllerId,
        media_category: mediaCategory
      })
    )
  }, [dispatch, selectedControllerId, mediaCategory])

  const clearMediaSubCategories = useCallback(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  const clearMediaSubCategory = useCallback(() => {
    setFieldValue(MEDIA_SUB_CATEGORY, '')
  }, [setFieldValue])

  const clearMediaSubSubCategory = useCallback(() => {
    setFieldValue(MEDIA_SUB_SUB_CATEGORY, '')
  }, [setFieldValue])

  const onSubCategoryChangeHandler = useCallback(() => {
    if (mediaSubSubCategoryId) {
      clearMediaSubSubCategory()
    }
  }, [mediaSubSubCategoryId, clearMediaSubSubCategory])

  useEffect(() => {
    if (mediaCategory) {
      loadMediaSubCategories()
    } else {
      clearMediaSubCategories()
    }
  }, [mediaCategory, loadMediaSubCategories, clearMediaSubCategories])

  useEffect(() => {
    return () => {
      clearMediaSubCategories()
    }
  }, [clearMediaSubCategories])

  if (getMediaSubCategoriesIsLoading) {
    return <Skeleton height={38} />
  }

  if (mediaSubCategoriesWasLoaded && !mediaSubCategories.length) {
    return null
  }

  return (
    <>
      <Field
        className={classes.subCategorySelect}
        formik={formik}
        name={MEDIA_SUB_CATEGORY}
        placeholder="Select sub category"
        autoComplete="off"
        options={formattedMediaSubCategoriesOptions}
        showClearInputIcon={!!mediaSubCategoryId}
        onClearInput={clearMediaSubCategory}
        onValueChange={onSubCategoryChangeHandler}
      />
      {!!formattedMediaSubSubCategoriesOptions?.length && (
        <Field
          formik={formik}
          name={MEDIA_SUB_SUB_CATEGORY}
          placeholder="Select sub sub category"
          autoComplete="off"
          options={formattedMediaSubSubCategoriesOptions}
          showClearInputIcon={!!mediaSubSubCategoryId}
          onClearInput={clearMediaSubSubCategory}
        />
      )}
    </>
  )
}

export default MediaSubAndSubSubCategoryFields

import {
  ATTRIBUTE,
  BOOKING_EMAIL_TEXT,
  CAN_SELECT_MULTIPLE,
  MEDIA_PRODUCT_ATTRIBUTE,
  NAME,
  OPTIONS,
  REQUIRED,
  VALUES
} from '../../fields'
import { MEDIA_CATEGORY } from '../../../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import { mediaCategories } from '../../../../../../../constants/mediaOrders'

export const DELETED_OPTIONS_IDS = 'deletedOptionsIds'

const formatAttributeValuesToFE = bookingVariable => {
  if (!bookingVariable[MEDIA_PRODUCT_ATTRIBUTE]?.[ATTRIBUTE] || !bookingVariable[MEDIA_PRODUCT_ATTRIBUTE]?.[VALUES]) {
    return []
  }

  if (bookingVariable[MEDIA_PRODUCT_ATTRIBUTE][ATTRIBUTE] === MEDIA_CATEGORY) {
    return mediaCategories.filter(category => bookingVariable[MEDIA_PRODUCT_ATTRIBUTE][VALUES].includes(category.value))
  }

  return bookingVariable[MEDIA_PRODUCT_ATTRIBUTE][VALUES].map(item => ({
    ...item,
    value: item.id,
    label: item.name
  }))
}

export const getInitialValues = (bookingVariable, bookingVariableOptions) => {
  return {
    [NAME]: bookingVariable[NAME] || '',
    [MEDIA_PRODUCT_ATTRIBUTE]: {
      [ATTRIBUTE]: bookingVariable[MEDIA_PRODUCT_ATTRIBUTE]?.[ATTRIBUTE] || '',
      [VALUES]: formatAttributeValuesToFE(bookingVariable)
    },
    [REQUIRED]: bookingVariable[REQUIRED] || false,
    [CAN_SELECT_MULTIPLE]: bookingVariable[CAN_SELECT_MULTIPLE] || false,
    [BOOKING_EMAIL_TEXT]: bookingVariable[BOOKING_EMAIL_TEXT] || '',
    // transform options array to object
    [OPTIONS]:
      bookingVariableOptions?.reduce((acc, option) => {
        acc[option.id] = option
        return acc
      }, {}) || {},
    [DELETED_OPTIONS_IDS]: []
  }
}

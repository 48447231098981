import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  statusChip: {
    display: 'block', // make ellipsis work,
    margin: 0
  }
})

export default useStyles

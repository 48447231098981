import { createStyles } from '../../../../../../../../styles/helpers'

import { darkGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  requested: {
    color: darkGrey,
    fontSize: 12
  }
})

export default useStyles

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import ProposalSendForm from './ProposalSendForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { clearActiveContract, clearGetContract, getContract } from '../../../../../modules/actions/contracts'
import { contractSelector } from '../../../../../modules/selectors/contracts'

import { PROPOSAL_SEND } from '../../../../../constants/forms'

function ProposalSendDrawer({ contractData, shouldFetchData }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loadedContractData = useSelector(contractSelector)

  const { selectedEditItemId, isFormOpen } = useManageFormsDrawer({
    formName: PROPOSAL_SEND
  })

  const quotationData = useMemo(() => {
    if (contractData) {
      return contractData
    } else {
      return loadedContractData
    }
  }, [contractData, loadedContractData])

  const hasData = Boolean(quotationData?.id)

  useEffect(() => {
    if (shouldFetchData && isFormOpen && !hasData) {
      dispatch(getContract(selectedEditItemId))
    }
  }, [dispatch, selectedEditItemId, shouldFetchData, isFormOpen, hasData])

  useEffect(() => {
    if (shouldFetchData && !isFormOpen) {
      // clear data for proposals list page on drawer close
      dispatch(clearActiveContract())
      dispatch(clearGetContract())
    }
  }, [dispatch, isFormOpen, shouldFetchData])

  return (
    <FormDrawerWrapper
      formName={PROPOSAL_SEND}
      title={`Manage recipients, Proposal - ${quotationData?.media_order_sequential_id || ''}`}
      description={t(
        'Send this proposal to a user or email address. They will receive an email asking them to sign and submit the booking contract, at which time the inventory will be confirmed. Until that time, it will continue to show as reserved. '
      )}
      // show loading while without_requirements is being updated
      isFormLoading={!hasData}
      showOpenButton={false}
    >
      <ProposalSendForm quotationData={quotationData} />
    </FormDrawerWrapper>
  )
}

ProposalSendDrawer.propTypes = {
  // determines if we should fetch contract from BE or it will be passed through contractData prop
  shouldFetchData: PropTypes.bool,
  contractData: PropTypes.object
}

export default ProposalSendDrawer

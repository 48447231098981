import React from 'react'
import { useTranslation } from 'react-i18next'

import Page from '../index'
import MediaOrdersTable from './MediaOrdersTable'
import MediaOrdersActions from './MediaOrdersActions'
import { MediaOrdersTableContextProvider } from './MediaOrdersTableContext'

import useCommonStyles from '../../styles/common/listPage'

const MediaOrders = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  return (
    <Page helmetTitle="helmetTitle.MediaOrdersPage">
      <div className={commonClasses.heading}>
        <h3 className={commonClasses.title}>{t('Media bookings')}</h3>
        <MediaOrdersActions />
      </div>
      <MediaOrdersTableContextProvider>
        <MediaOrdersTable />
      </MediaOrdersTableContextProvider>
    </Page>
  )
}

export default MediaOrders

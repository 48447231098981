import React, { useCallback } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { DATES_FORMAT_FULL_YEAR } from '../../../../../../../../constants/dates'

import useStyles from './styles'

const ProductAndPeriodCell = ({ isGroup = false, product, date_start, date_end }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const renderDates = useCallback(() => {
    if (date_start && date_end) {
      return (
        <p
          className={classes.period}
        >{`${format(new Date(date_start), DATES_FORMAT_FULL_YEAR)} - ${format(new Date(date_end), DATES_FORMAT_FULL_YEAR)}`}</p>
      )
    }
    if (date_start) {
      return <p className={classes.period}>{`${format(new Date(date_start), DATES_FORMAT_FULL_YEAR)}`}</p>
    } else {
      return null
    }
  }, [date_start, date_end, classes.period])

  return (
    <div className={classes.cellWrapper}>
      {isGroup ? (
        <h4 className={classes.multipleAssets}>{t('Multiple Assets')}</h4>
      ) : (
        <h4 className={classes.productName}>{product}</h4>
      )}

      {renderDates()}
    </div>
  )
}

ProductAndPeriodCell.propTypes = {
  isGroup: PropTypes.bool,
  product: PropTypes.string,
  date_start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  date_end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
}

export default ProductAndPeriodCell

import React, { useCallback, useMemo, useState } from 'react'
import { addMonths, lastDayOfMonth, startOfMonth } from 'date-fns'

import FormField from '../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import FootfallDataDateRangeFilter from './FootfallDataDateRangeFilter'
import Select from '../../../../components/Select'
import FootfallDataDownloadCsv from '../FootfallDataDownloadCsv'
import { formatDateToBE } from '../../../../constants/dates'

import { FOOTFALL_TYPE, FOOTFALL_TYPE_EXTERNAL, footfallTypesOptions } from './fields'

import useFormWrapperStyles from '../../../../styles/common/formWrapper'
import useStyles from './styles'

const DownloadFootfallDataSection = () => {
  const formWrapperClasses = useFormWrapperStyles()
  const classes = useStyles()

  const [dateRangeFilter, setDateRangeFilter] = useState({
    // preset last month by default
    startDate: startOfMonth(addMonths(new Date(), -1)),
    endDate: lastDayOfMonth(addMonths(new Date(), -1))
  })

  const [footfallTypeFilter, setFootfallTypeFilter] = useState(FOOTFALL_TYPE_EXTERNAL)

  const paramsData = useMemo(() => {
    return {
      [FOOTFALL_TYPE]: footfallTypeFilter,
      start_date: formatDateToBE(new Date(dateRangeFilter.startDate)),
      end_date: formatDateToBE(new Date(dateRangeFilter.endDate))
    }
  }, [dateRangeFilter.startDate, dateRangeFilter.endDate, footfallTypeFilter])

  const onFootfallTypeChange = useCallback(
    option => {
      setFootfallTypeFilter(option.value)
    },
    [setFootfallTypeFilter]
  )

  return (
    <div className={formWrapperClasses.formWrapper}>
      <FormField label="Date range">
        <FootfallDataDateRangeFilter dateRangeFilter={dateRangeFilter} setDateRangeFilter={setDateRangeFilter} />
      </FormField>
      <FormField label="Footfall Type" isLastFormField>
        <Select
          className={classes.select}
          placeholder="footfall type"
          value={footfallTypeFilter}
          onChange={onFootfallTypeChange}
          options={footfallTypesOptions}
        />
      </FormField>
      <div className={formWrapperClasses.formFooter}>
        <FootfallDataDownloadCsv paramsData={paramsData} />
      </div>
    </div>
  )
}

export default DownloadFootfallDataSection

import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  firstNameFieldWrapper: {
    marginTop: 0
  },
  recipientsHeader: {
    alignItems: 'center'
  }
})

export default useStyles

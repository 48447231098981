import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Chip from '../../../../../../components/Chip'

import { getDaysDiffLabel } from '../../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/helpers'

import { mediaOrderSelector } from '../../../../../../modules/selectors/mediaOrders'

import { bookedMediaStatusesLabels, statusColors } from '../../../../../../constants/bookedMedia'

import useCommonStyles from '../../../../../../styles/common/listPage'
import useStyles from './styles'

const MediaOrderInfo = () => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const mediaOrder = useSelector(mediaOrderSelector)
  const { name: mediaOrderName, created } = mediaOrder

  const statusText = useMemo(() => bookedMediaStatusesLabels[mediaOrder.status], [mediaOrder.status])
  const statusColor = useMemo(() => statusColors[mediaOrder.status], [mediaOrder.status])

  return (
    <>
      <h3 className={commonClasses.title}>{mediaOrderName}</h3>
      <p className={classes.detail}>Created: {getDaysDiffLabel(created)}</p>
      <Chip text={statusText} color={statusColor} />
    </>
  )
}

export default MediaOrderInfo

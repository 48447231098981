import {
  ATTRIBUTE,
  BOOKING_EMAIL_TEXT,
  CAN_SELECT_MULTIPLE,
  MEDIA_PRODUCT_ATTRIBUTE,
  NAME,
  OPTIONS,
  REQUIRED,
  VALUES
} from '../../fields'
import { MEDIA_CATEGORY } from '../../../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export const initialValues = {
  [NAME]: '',
  [MEDIA_PRODUCT_ATTRIBUTE]: {
    [ATTRIBUTE]: MEDIA_CATEGORY,
    [VALUES]: []
  },
  [REQUIRED]: false,
  [CAN_SELECT_MULTIPLE]: false,
  [BOOKING_EMAIL_TEXT]: '',
  [OPTIONS]: {}
}

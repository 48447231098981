import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'

import { useCreateAndSendQuotation } from './useCreateAndSendQuotation'
import useRedirectFormDrawer from '../../../../FormDrawerWrapper/useRedirectFormDrawer'
import { useClearMediaOrderForm } from '../../../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/hooks/useClearMediaOrderForm'

import { membersSelector } from '../../../../../../modules/selectors/selfAccountTeam'
import { createdQuotationSelector } from '../../../../../../modules/selectors/quotations'
import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'

import { formatOptionsList } from '../../../../../formatters'
import { getInitialValues } from './fields'

import { MODAL_TYPE } from '../../../../../../constants/other'
import { ROUTE_PARAMS, ROUTES } from '../../../../../../constants/routes'
import { SIGNATORY_PERMISSION } from '../../../../../../constants/permissions'
import { PROPOSAL_PLATFORM_USER_RECEIVER } from '../../../../../../forms/Multiplatform/MediaOrderForms/Proposals/ProposalSendDrawer/ProposalSendForm/fields'

import useStyles from './styles'

function QuotationCreateAndSend({ contractId, buttonText }) {
  const { t } = useTranslation()

  const classes = useStyles()

  const selfAccountTeamMembers = useSelector(membersSelector)
  const createdQuotation = useSelector(createdQuotationSelector)
  const selfAccountId = useSelector(selectedSelfAccountSelector)

  const handleSubmitFormClear = useClearMediaOrderForm()
  const handleRedirect = useRedirectFormDrawer()

  const quotationId = createdQuotation?.id

  const formattedSelfAccountTeamMembers = useMemo(() => {
    const signatoryList = selfAccountTeamMembers.filter(({ permissions }) =>
      permissions?.find(permission => permission === SIGNATORY_PERMISSION)
    )
    return formatOptionsList({
      list: signatoryList,
      labelName: 'full_name',
      valueName: 'user'
    })
  }, [selfAccountTeamMembers])

  const {
    onSubmit: onCreateSubmit,
    onClearSubmit,
    isLoadingSelector,
    errorSelector,
    successSubmit
  } = useCreateAndSendQuotation(contractId)

  const onSubmit = useCallback(values => onCreateSubmit(values), [onCreateSubmit])
  const initialValues = useMemo(() => {
    return getInitialValues(formattedSelfAccountTeamMembers)
  }, [formattedSelfAccountTeamMembers])

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  })

  const onQuotationCreate = useCallback(() => {
    handleSubmitFormClear()

    handleRedirect(
      {
        [ROUTE_PARAMS.selfAccount]: selfAccountId
      },
      `${ROUTES.proposals}?message=${MODAL_TYPE.quotationWasSent}&itemId=${quotationId}`
    )
  }, [selfAccountId, quotationId, handleSubmitFormClear, handleRedirect])

  return (
    <Form
      formName="adFormContent"
      formik={formik}
      submitText={buttonText}
      successSubmit={successSubmit}
      onSuccessSubmit={onQuotationCreate}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      clearHandler={onClearSubmit}
      buttonProps={{
        disabled: !formik.values[PROPOSAL_PLATFORM_USER_RECEIVER]
      }}
    >
      <Field
        formik={formik}
        className={classes.signatorySelect}
        name={PROPOSAL_PLATFORM_USER_RECEIVER}
        placeholder={t('Select signatory')}
        options={formattedSelfAccountTeamMembers}
        menuHeightLimit={130}
      />
    </Form>
  )
}

QuotationCreateAndSend.propTypes = {
  contractId: PropTypes.number,
  className: PropTypes.string,
  quotationParams: PropTypes.object,
  buttonProps: PropTypes.object
}

export default QuotationCreateAndSend

import React from 'react'
import { useTranslation } from 'react-i18next'

import ContentSection from '../../../features/components/ContentSection'
import MediaProductSpecificationsDocuments from './SpecificationsDocuments'
import BookingVariables from './BookingVariables'

import useSettingsClasses from '../styles'

const BookingEmailAttachments = () => {
  const { t } = useTranslation()
  const settingsClasses = useSettingsClasses()

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Booking Email Attachments')}</h1>

      <ContentSection title="Booking Email Attachments">
        <MediaProductSpecificationsDocuments />
        <BookingVariables />
      </ContentSection>
    </>
  )
}

export default BookingEmailAttachments

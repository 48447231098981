import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useCommonStyles from '../../../styles/common/listPage'

function TableNoData({ isCentered, title, children }) {
  const commonClasses = useCommonStyles()

  return (
    <div
      className={classnames(commonClasses.noDataContent, {
        [commonClasses.noDataContentInTheMiddleOfTheTable]: isCentered
      })}
    >
      {title && <div> {title}</div>}
      {children}
      <br />
    </div>
  )
}

TableNoData.propTypes = {
  isCentered: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node
}

export default TableNoData

import React from 'react'
import { useTranslation } from 'react-i18next'

import Spoiler from '../../../../../../../components/Spoiler'
import Field from '../../../../../../../components/Form/Field'
import MediaSubSubCategoryField from './MediaSubSubCategoryField'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MinimizedListField from '../../../../../../../features/components/Form/MinimizedListField'

import { initialSubSubCategoryValue, MEDIA_CATEGORY, NAME, MEDIA_SUB_SUB_CATEGORY } from '../../../fields'
import { mediaCategories } from '../../../../../../../constants/mediaOrders'

function MediaSubCategoryFields({ formik, allowDeleteSubSubCategory = true }) {
  const { t } = useTranslation()

  const hasSubSubCategories = formik.values[MEDIA_SUB_SUB_CATEGORY]?.length > 0
  return (
    <FieldsSection title="Asset Type">
      <FieldRow title="Category">
        <Field
          formik={formik}
          name={MEDIA_CATEGORY}
          options={mediaCategories}
          placeholder="Category"
          autoComplete="off"
        />
      </FieldRow>
      <FieldRow title="Name">
        <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
      </FieldRow>

      <Spoiler title="Advanced asset type settings" initialOpen={hasSubSubCategories}>
        <FieldRow title="Asset Subtype">
          <MinimizedListField
            formik={formik}
            fieldPath={MEDIA_SUB_SUB_CATEGORY}
            initialItemIdKey="id"
            initialListValue={initialSubSubCategoryValue}
            firstItemText={t('+ Add subtype')}
            nextItemText={t('+ Add another subtype')}
            titleLocalizationVariable={'mediaSubSubCategoryTitle'}
            allowDelete={allowDeleteSubSubCategory}
          >
            <MediaSubSubCategoryField formik={formik} />
          </MinimizedListField>
        </FieldRow>
      </Spoiler>
    </FieldsSection>
  )
}

export default MediaSubCategoryFields

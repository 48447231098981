import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

export const selectorPlaceholder = () => {}

// this hook helps to process all important data fetching processes i.e.:
// create initial request for requestHandler
// clear data on success or error request made

export default function useRequestHandler(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useRequestHandler')
  const {
    requestHandler,
    clearHandler,
    onSuccessSubmit,
    makeInitialRequest = false,
    isLoadingRequestSelector = selectorPlaceholder,
    successRequestSelector = selectorPlaceholder,
    errorRequestSelector = selectorPlaceholder,
    clearOnError = true,
    clearOnSuccess = true,
    clearOnUnmount = true
  } = props
  const isLoading = useSelector(isLoadingRequestSelector)
  const requestSuccess = useSelector(successRequestSelector)
  const requestError = useSelector(errorRequestSelector)

  useEffect(() => {
    // initial data request on component mount
    if (makeInitialRequest) {
      requestHandler()
    }
  }, [makeInitialRequest, requestHandler])

  useEffect(() => {
    if (requestSuccess && onSuccessSubmit) {
      // make onSuccess callback
      onSuccessSubmit()
    }
    // clear after success
    // usually this is needed for manual POST, PUT, DELETE requests which are not handled in ProgressButton or by some form flow
    if (clearOnSuccess && requestSuccess) {
      clearHandler()
    }
  }, [clearHandler, clearOnSuccess, onSuccessSubmit, requestSuccess])

  useEffect(() => {
    // clear after error
    if (clearOnError && requestError) {
      clearHandler()
    }
  }, [clearHandler, clearOnError, requestError])

  useEffect(
    () => () => {
      // clear data on component unmount if it was made but not cleared
      clearOnUnmount && clearHandler()
    },
    [clearHandler, clearOnUnmount]
  )

  return useMemo(
    () => ({
      isLoading,
      requestSuccess,
      requestError
    }),
    [isLoading, requestSuccess, requestError]
  )
}

const propTypes = {
  requestHandler: PropTypes.func,
  clearHandler: PropTypes.func.isRequired,
  onSuccessSubmit: PropTypes.func,
  makeInitialRequest: PropTypes.bool,
  isLoadingRequestSelector: PropTypes.func,
  successRequestSelector: PropTypes.func,
  errorRequestSelector: PropTypes.func,
  clearOnError: PropTypes.bool,
  clearOnSuccess: PropTypes.bool
}

import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import HeaderCell from './HeaderCell'
import SortableCell from './SortableCell'

import { TABLE_ROW_CLASSNAME } from '../../../constants/pdf'
import { ROW_WITH_EXPAND_BUTTON_SPACING, SKIP_CELL_FOR_COPY } from '../constants'

import { phonesDownSize } from '../../../styles/const/breakpoints'

import useStyles from '../styles'

const TableHeader = React.memo(
  ({
    cols,
    rowTemplate,
    tanstackHeaderGroup,
    tanstackSort,
    tableSort,
    stickyHeader,
    hasMobileHiddenColumns,
    hasExpandableRow,
    isBigRow
  }) => {
    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
    const classes = useStyles({ isBigRow, isMobile, hasMobileHiddenColumns, stickyHeader })

    const headers = tanstackHeaderGroup?.headers

    const HeadLineColumns = useCallback(() => {
      // if isMobile do filter for showing only mobile headers
      return (isMobile ? cols.filter(({ showOnMobile }) => showOnMobile !== false) : cols).map((colData, index) => {
        const header = headers?.[index]
        const isSortable = Boolean(colData.onSortingChange) || header.column.getCanSort()

        // index is used as key because columns are not removing or adding dynamically
        return isSortable ? (
          <SortableCell
            key={index}
            {...colData}
            tableSort={tableSort}
            tanstackSort={tanstackSort}
            tanstackHeader={header}
          />
        ) : (
          <HeaderCell key={index} {...colData} />
        )
      })
    }, [isMobile, cols, headers, tanstackSort, tableSort])

    return (
      <div
        className={classnames(
          classes[TABLE_ROW_CLASSNAME],
          classes.headlineRow,
          {
            [classes[ROW_WITH_EXPAND_BUTTON_SPACING]]: hasExpandableRow,
            [classes.stickyHeader]: stickyHeader
          },
          rowTemplate?.headlineRowClassName
        )}
      >
        <HeadLineColumns />
      </div>
    )
  }
)

const colsPropTypeShape = {
  showOnMobile: PropTypes.bool,
  attributes: PropTypes.shape({
    // allow to add HTML elements attributes to cell
    [SKIP_CELL_FOR_COPY]: PropTypes.bool // allow to skip cell for copy
  }),
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableHeader.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
  rowTemplate: PropTypes.shape({
    headlineRowClassName: PropTypes.string
  }),
  hasMobileHiddenColumns: PropTypes.bool,
  hasExpandableRow: PropTypes.bool,
  isBigRow: PropTypes.bool
}

export default TableHeader

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { getSelectedPeriodLabel } from './helpers'

import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../fields'

import useStyles from './styles'

const Periods = ({ productValues }) => {
  const classes = useStyles()

  const productData = productValues.data
  const productPeriod = productData?.period

  const groupedPeriods = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]
  const hasGroupedPeriods = groupedPeriods.length > 0

  const selectedPeriodLabel = useMemo(
    () => getSelectedPeriodLabel({ groupedPeriods, productPeriod }),
    [groupedPeriods, productPeriod]
  )

  if (!hasGroupedPeriods) {
    return null
  }

  return <div className={classes.periods}>({selectedPeriodLabel})</div>
}

Periods.propTypes = {
  productValues: PropTypes.object
}

export default Periods

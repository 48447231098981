import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import MediaProductCreate from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate'
import MediaProductEdit from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/editForms/MediaProductEdit'
import CreateProductBtn from './MediaProductsDataContent/ProductsTable/CreateProductBtn'
import ProductInventory from './ProductInventory'
import MediaProductGroupCreate from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductGroupCreate'
import MediaProductGroupEdit from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/editForms/MediaProductGroupEdit'
import MediaProductsDataContent from './MediaProductsDataContent'

import { clearGetProductTags } from '../../../modules/actions/tags'
import { clearDeleteProduct } from '../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'
import { deleteProductSelector } from '../../../modules/selectors/mediaOrdersProducts'

import useSettingsClasses from '../styles'

const MediaProducts = () => {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { wasDeleted: productWasDeleted } = useSelector(deleteProductSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    if (productWasDeleted) {
      dispatch(clearDeleteProduct())
    }
  }, [dispatch, productWasDeleted])

  useEffect(() => {
    return () => {
      // clear tags on unmount which fetcheed for edit forms
      dispatch(clearGetProductTags())
    }
  }, [dispatch])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Media Products')}</h1>
      <ContentSection title="Media Products">
        <ContentRow
          title="Product list"
          description="Manage media products and inventory."
          leftColumnChildren={<CreateProductBtn />}
        >
          <MediaProductsDataContent />
        </ContentRow>
      </ContentSection>

      <MediaProductCreate controllerId={controllerId} />
      <MediaProductGroupCreate controllerId={controllerId} />
      <MediaProductEdit />
      <MediaProductGroupEdit />
      <ProductInventory />
    </>
  )
}

export default MediaProducts

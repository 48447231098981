import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../../../../features/components/Form/MultiSelectBox'
import MediaSubAndSubSubCategoryFields from '../../../../../ReusableFormFIelds/MediaSubAndSubSubCategoryFields'

import { DIGITAL, mediaCategories } from '../../../../../../../../../constants/mediaOrders'
import { CHANNEL_OPTION, CHANNEL_OPTION_OFFSITE, MEDIA_CATEGORY, PLATFORMS, platformsOptions } from '../../../fields'

import { MEDIA_SUB_CATEGORY, MEDIA_SUB_SUB_CATEGORY, platforms } from '../../../../../fields'

import useStyles from './styles'

const MediaCategoryFields = ({ formik, allowShowSelectChannelField = true }) => {
  const classes = useStyles()

  const { values, setFieldValue, errors, touched } = formik

  const {
    [MEDIA_CATEGORY]: mediaCategory,
    [MEDIA_SUB_CATEGORY]: mediaSubCategory,
    [MEDIA_SUB_SUB_CATEGORY]: mediaSubSubCategory
  } = values

  const showSelectChannelField = mediaCategory === DIGITAL
  const showSubAndSubSubCategories = mediaCategory && mediaCategory !== DIGITAL
  const showSelectPlatformField = values[CHANNEL_OPTION] === CHANNEL_OPTION_OFFSITE

  const clearSelectedMediaSubCategory = useCallback(() => {
    setFieldValue(MEDIA_SUB_CATEGORY, '')
  }, [setFieldValue])

  const clearSelectedMediaSubSubCategory = useCallback(() => {
    setFieldValue(MEDIA_SUB_SUB_CATEGORY, '')
  }, [setFieldValue])

  const onCategoryChangeHandler = useCallback(() => {
    if (mediaSubCategory) {
      clearSelectedMediaSubCategory()
    }
    if (mediaSubSubCategory) {
      clearSelectedMediaSubSubCategory()
    }
  }, [mediaSubCategory, mediaSubSubCategory, clearSelectedMediaSubCategory, clearSelectedMediaSubSubCategory])

  return (
    <>
      <Field
        formik={formik}
        name={MEDIA_CATEGORY}
        options={mediaCategories}
        placeholder="Category"
        autoComplete="off"
        className={classes.mediaCategoryField}
        onValueChange={onCategoryChangeHandler}
      />
      {showSelectChannelField && allowShowSelectChannelField && (
        <>
          <Field
            formik={formik}
            name={CHANNEL_OPTION}
            options={platformsOptions}
            placeholder="Select channel"
            className={classes.selectChannelField}
          />
          {showSelectPlatformField && (
            <MultiSelectBox
              formik={formik}
              name={PLATFORMS}
              value={values[PLATFORMS]}
              setFieldValue={setFieldValue}
              options={platforms}
              placeholder="Select platform"
              error={errors[PLATFORMS]}
              touched={touched[PLATFORMS]}
            />
          )}
        </>
      )}

      {showSubAndSubSubCategories && <MediaSubAndSubSubCategoryFields formik={formik} />}
    </>
  )
}

MediaCategoryFields.propTypes = {
  formik: PropTypes.object.isRequired,
  allowShowSelectChannelField: PropTypes.bool,
  allowShowStaticOrDigitalOOHField: PropTypes.bool
}

export default MediaCategoryFields

import React from 'react'
import PropTypes from 'prop-types'

import MediaThumbnail from '../../../../../../../features/components/MediaThumbnail'

import useBlobUrl from '../../../../../../../pages/Settings/BookingEmailAttachments/BookingVariables/forms/useBlobUrl'

const ImagePreview = ({ file, fileRemove, index }) => {
  const blob = useBlobUrl(file)
  const imgUrl = file.image || blob

  return (
    <MediaThumbnail
      key={index}
      // media={{ thumbnailUrl: imgUrl }}
      thumbnailUrl={imgUrl}
      mediaType={'image'}
      // download image on click
      onMediaClick={() => window.open(imgUrl)}
      onRemoveClick={() => fileRemove(index)}
    />
  )
}

ImagePreview.propTypes = {
  file: PropTypes.object.isRequired,
  fileRemove: PropTypes.func,
  index: PropTypes.number
}

export default ImagePreview

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ContractFields from './ContractFields'

import { useEditActions } from '../useEditActions'

import { BRAND, CAMPAIGN_NAME, VARIABLES } from '../fields'

import useStyles from './styles'

const AdditionalContractInfo = ({
  formik,
  isEditContract,
  isEditMode,
  setIsEditMode,
  allowAutoSave,
  hasTopMargin,
  children
}) => {
  const classes = useStyles({ isEditMode })

  const { values } = formik

  const defaultValues = useMemo(() => {
    return {
      [BRAND]: values[BRAND],
      [CAMPAIGN_NAME]: values[CAMPAIGN_NAME],
      [VARIABLES]: values[VARIABLES]
    }
  }, [values])

  const { handleStartEditContract, handleSaveContract, handleResetContract } = useEditActions({
    formik,
    defaultValues,
    allowAutoSave,
    setIsEditMode
  })

  return (
    <>
      {isEditMode && <div className={classes.overlay} onClick={handleResetContract} />}
      <div className={classnames(classes.container, { [classes.topMargin]: hasTopMargin })}>
        <div className={classes.mediaOrderInfo}>{children}</div>
        <ContractFields
          formik={formik}
          isEditContract={isEditContract}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          allowAutoSave={allowAutoSave}
          handleStartEditContract={handleStartEditContract}
          handleSaveContract={handleSaveContract}
        />
      </div>
    </>
  )
}

AdditionalContractInfo.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditContract: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  allowAutoSave: PropTypes.bool,
  hasTopMargin: PropTypes.bool,
  infoSectionChildren: PropTypes.node
}

export default AdditionalContractInfo

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import Table from '../../../../../../components/Table'
import SpecificationsDocumentsActions from '../SpecificationsDocumentsActions'

import {
  mediaProductAttachedFilesSelector,
  updateMediaProductAttachedFileIsLoadingSelector,
  updateMediaProductAttachedFileIdSelector,
  deleteMediaProductAttachedFileIdSelector,
  deleteMediaProductAttachedFileIsLoadingSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'

import { tableColumnsSize } from '../../../../MediaProductOptions/ProductTags/MediaTagsTable'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const SpecificationsDocumentsTableData = () => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const updateMediaProductAttachedFileIsLoading = useSelector(updateMediaProductAttachedFileIsLoadingSelector)
  const updateMediaProductAttachedFileId = useSelector(updateMediaProductAttachedFileIdSelector)
  const deleteMediaProductAttachedFileId = useSelector(deleteMediaProductAttachedFileIdSelector)
  const deleteMediaProductAttachedFileIsLoading = useSelector(deleteMediaProductAttachedFileIsLoadingSelector)

  const mediaProductAttachedFiles = useSelector(mediaProductAttachedFilesSelector)

  const loadingTagId = useMemo(() => {
    if (updateMediaProductAttachedFileIsLoading) {
      return updateMediaProductAttachedFileId
    }
    if (deleteMediaProductAttachedFileIsLoading) {
      return deleteMediaProductAttachedFileId
    }

    // default value null if nothing is updating or deleting
    return null
  }, [
    updateMediaProductAttachedFileId,
    updateMediaProductAttachedFileIsLoading,
    deleteMediaProductAttachedFileId,
    deleteMediaProductAttachedFileIsLoading
  ])

  const tagsColumns = useMemo(
    () => [
      {
        fieldKey: 'file_name'
      },
      {
        Cell: data => <SpecificationsDocumentsActions data={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile]
  )

  return (
    <Table
      hideHeadlineRow
      data={mediaProductAttachedFiles}
      cols={tagsColumns}
      itemUpdatingId={loadingTagId}
      tableId="SpecificationsDocumentsTableData"
      hideFooterRow
    />
  )
}

export default SpecificationsDocumentsTableData

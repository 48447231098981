import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import RefreshButton from '../../../../../../features/components/Filters/RefreshButton'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import DropdownFilters from '../../../../../../features/components/DropdownFilters'
import {
  BRAND_CATEGORY,
  BrandCategory
} from '../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import FileStatusFilter, { FILE_STATUS } from './FileStatusFilter'
import BuyersFilter, {
  BUYER_FILTER
} from '../../../../../../features/components/DropdownFilters/components/BuyersFilter'

import usePermissions from '../../../../../../hooks/usePermissions'

import { mediaSubCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { ApprovalsDataProvider, ApprovalsFiltersContext } from './ApprovalsFiltersContext'
import { MEDIA_CATEGORIES_FILTERS_PERMISSION } from '../../../../../../constants/permissions'

import useStyles from './styles'

const ApprovalsFilters = ({ onFiltersChange }) => {
  const permissions = usePermissions()
  const classes = useStyles()

  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const fetchOptionsHandler = useCallback(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    dispatch(clearGetMediaSubCategories())
  }, [dispatch])

  const filters = useMemo(() => {
    return [
      {
        component: <SubCategoriesFilter Context={ApprovalsFiltersContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show:
          permissions && permissions.can('manage', MEDIA_CATEGORIES_FILTERS_PERMISSION) && !!mediaSubCategories.length
      },
      {
        component: <FileStatusFilter Context={ApprovalsFiltersContext} />,
        title: 'File Status',
        filterName: FILE_STATUS,
        show: true,
        allowReset: false
      },
      {
        component: <BrandCategory Context={ApprovalsFiltersContext} />,
        title: 'Brand Category',
        filterName: BRAND_CATEGORY,
        show: true
      },
      {
        component: <BuyersFilter Context={ApprovalsFiltersContext} />,
        title: 'Buyer',
        filterName: BUYER_FILTER,
        show: true
      }
    ]
  }, [permissions, mediaSubCategories.length])

  return (
    <ApprovalsDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <RefreshButton refreshDataHandler={onFiltersChange} Context={ApprovalsFiltersContext} />

        <DropdownFilters filters={filters} Context={ApprovalsFiltersContext} onInitialOpen={fetchOptionsHandler} />
      </div>
    </ApprovalsDataProvider>
  )
}

ApprovalsFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default ApprovalsFilters

import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../../../components/Form'
import BookingVariablesFormContent from '../../BookingVariablesFormContent'
import { transformValuesToBE } from './formatters'

import {
  bookingVariableOptionsSelector,
  bookingVariableSelector,
  updateBookingVariableErrorSelector,
  updateBookingVariableIsLoadingSelector,
  updateBookingVariableWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'
import {
  clearUpdateBookingVariable,
  updateBookingVariable
} from '../../../../../../../modules/actions/mediaOrdersBookings'

import { BOOKING_VARIABLE_EDIT } from '../../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { DELETED_OPTIONS_IDS, getInitialValues } from './fields'
import { OPTIONS } from '../../fields'

function BookingVariableEditForm() {
  const dispatch = useDispatch()

  const bookingVariable = useSelector(bookingVariableSelector)
  const bookingVariableOptions = useSelector(bookingVariableOptionsSelector)
  const itemWasUpdated = useSelector(updateBookingVariableWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const formattedValues = transformValuesToBE({ values, initialOptions: bookingVariableOptions })

      dispatch(updateBookingVariable(bookingVariable?.id, formattedValues))
    },
    [dispatch, bookingVariable?.id, bookingVariableOptions]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearUpdateBookingVariable())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues(bookingVariable, bookingVariableOptions)
  }, [bookingVariable, bookingVariableOptions])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const { values, setFieldValue } = formik

  const newFetchedOptions = useMemo(() => {
    return bookingVariableOptions.filter(item => {
      return !values[DELETED_OPTIONS_IDS]?.includes(item.id) && !values[OPTIONS]?.[item.id]
    })
  }, [bookingVariableOptions, values])

  // In edit form we fetch paginated options, so we need to update options in formik values
  useEffect(() => {
    if (newFetchedOptions.length) {
      setFieldValue(OPTIONS, {
        ...values[OPTIONS],
        ...newFetchedOptions.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    }
  }, [newFetchedOptions, values, setFieldValue])

  return (
    <Form
      formik={formik}
      formName={BOOKING_VARIABLE_EDIT}
      successSubmit={itemWasUpdated}
      errorSelector={updateBookingVariableErrorSelector}
      isLoadingSelector={updateBookingVariableIsLoadingSelector}
      clearHandler={handleClearHandler}
    >
      <BookingVariablesFormContent formik={formik} />
    </Form>
  )
}

export default BookingVariableEditForm

import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../../modules/selectors/forms'

export const useProgressItems = ({ showFilesRequirements = true }) => {
  const activeStep = useSelector(activeStepSelector)

  const progressItems = [
    {
      show: true,
      label: 'Enter product details'
    },
    {
      show: true,
      label: 'Set the pricing'
    },
    {
      show: showFilesRequirements,
      label: 'Specify any required files'
    },
    {
      show: true,
      label: 'Add inventory'
    },
    {
      show: true,
      label: 'Upload image'
    }
  ]
    .filter(item => item.show)
    .map((item, index) => {
      return {
        ...item,
        isActive: activeStep === index,
        isSuccess: activeStep > index
      }
    })

  return progressItems
}

import * as Yup from 'yup'

import { notificationUsersValidation } from '../../../../../ReusableFormFields/RecipientsFields/validation'
import {
  EMAIL,
  NOTIFICATION_EMAILS,
  SEND_BY_EMAIL,
  USER_OPTION
} from '../../../../../ReusableFormFields/RecipientsFields/fields'

export const validationSchema = Yup.object({
  ...notificationUsersValidation,
  [NOTIFICATION_EMAILS]: Yup.array().when(USER_OPTION, {
    is: SEND_BY_EMAIL,
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          [EMAIL]: Yup.string().email('Invalid email').required('Please enter email')
        })
      )
  })
})

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

import TableDataLoader from '../../../components/Table/TableDataLoader'
import MediaOrdersTableData from '../../../features/components/MediaOrders/MediaOrdersTableData'

import { MediaOrdersTableContext } from '../MediaOrdersTableContext'

import { getMediaOrdersTableSchema } from './constants/columnsSchema'

import {
  mediaOrdersWasLoadedSelector,
  mediaOrdersIsLoadingSelector,
  mediaOrdersErrorSelector,
  mediaOrdersSelector
} from '../../../modules/selectors/mediaOrders'

import { tableColumnsSize, mobileColumnsSchema, tableColumnMinSize } from './constants/columnSizes'
import { phonesDownSize } from '../../../styles/const/breakpoints'
import useCommonStyles from '../../../styles/common/table'

const MediaOrdersTable = () => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const commonClasses = useCommonStyles()

  const mediaOrders = useSelector(mediaOrdersSelector)
  const { loadMoreMediaOrdersHandler } = useContext(MediaOrdersTableContext)

  const mediaOrdersColumnsSchema = useMemo(() => getMediaOrdersTableSchema(tableColumnsSize), [])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no media orders')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaOrders.length}
      errorSelector={mediaOrdersErrorSelector}
      wasLoadedSelector={mediaOrdersWasLoadedSelector}
      isLoadingSelector={mediaOrdersIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : mediaOrdersColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreMediaOrdersHandler}
    >
      <MediaOrdersTableData tableColumnsSize={tableColumnsSize} tableColumnMinSize={tableColumnMinSize} />
    </TableDataLoader>
  )
}

export default MediaOrdersTable

import { createStyles } from '../../../../../../../../../../../styles/helpers'

import { phonesDown } from '../../../../../../../../../../../styles/const/breakpoints'
import { angleIcon } from '../../../../../../../../../../../styles/common/components'
import { black } from '../../../../../../../../../../../styles/const/colors'

const useStyles = createStyles(theme => ({
  // part of range styles are in the index.js main JSS styles
  // that is because of issue of reusing same rule for different selectors in react-jss
  dateRangeDropdown: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateRanges: {
    display: 'flex',
    backgroundColor: 'white'
  },
  range: {
    position: 'relative',

    '& .rdrNextPrevButton': {
      width: 13,
      height: 'auto',
      background: 'none',

      '&:before': {
        extend: angleIcon
      },
      '& i': {
        display: 'none'
      }
    },

    '& .rdrNextPrevButton:before': {
      width: [13, '!important']
    },

    '& .rdrPprevButton': {
      transform: 'rotate(90deg)'
    },
    '& .rdrNextButton': {
      transform: 'rotate(-90deg)'
    },

    '& .rdrDayNumber, & .rdrDayToday .rdrDayNumber span': {
      fontWeight: 400
    },

    '& .rdrMonthAndYearPickers': {
      display: 'none'
    },
    '& .rdrMonthName': {
      position: 'absolute',
      top: 0,
      padding: '16px 0',
      fontSize: 16,
      color: black
    },

    '& .rdrMonth': {
      position: 'relative'
    },
    '& .rdrMonth:first-child .rdrMonthName': {
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -100%)'
    },
    '& .rdrMonth:nth-child(2) .rdrMonthName': {
      right: '50%',
      transform: 'translate(50%, -100%)'
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: theme.brandPrimary
    }
  },

  [`@media screen and (${phonesDown})`]: {
    range: {
      '& .rdrMonth:first-child .rdrMonthName': {
        left: '50%'
      }
    }
  }
}))

export default useStyles

import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductGroupCreateForm from './MediaProductGroupCreateForm'

import { MEDIA_PRODUCT_GROUP_CREATE } from '../../../../../constants/forms'

function MediaProductGroupCreate() {
  return (
    <FormDrawerWrapper
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
      formName={MEDIA_PRODUCT_GROUP_CREATE}
      showOpenButton={false}
    >
      <MediaProductGroupCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaProductGroupCreate

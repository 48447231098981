import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Icon from '../../../../../../../../components/Icon'
import ButtonIcon from '../../../../../../../../features/components/Buttons/ButtonIcon'

import { useHandleAutoSave } from '../../../../useHandleAutoSave'
import { getVariablesToBeAsked } from '../../../../AdditionalContractInfo/ContractFields/helpers'

import { ReactComponent as PencilIcon } from '../../../../../../../../assets/icons/pencil.svg'
import { ReactComponent as CheckmarkIcon } from '../../../../../../../../assets/icons/checked-circle.svg'
import { ReactComponent as CloseIcon } from '../../../../../../../../assets/icons/close-circle-2.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/icons/trash.svg'
import { PRODUCT_PERIODS_DATES, MEDIA_PRODUCTS, VARIABLES, formatInitialVariable } from '../../../../fields'

import { bookingVariablesSelector } from '../../../../../../../../modules/selectors/mediaOrdersBookings'

import useStyles from './styles'

const ActionsCell = ({
  formik,
  productIndex,
  productValues,
  editProductId,
  allowAutoSave,
  handleDeleteProduct,
  handleProductEdit
}) => {
  const classes = useStyles()
  const { setFieldValue, setFieldTouched, validateForm, submitForm, values } = formik

  const bookingVariables = useSelector(bookingVariablesSelector)

  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  // set productValues as default values to avoid case when newly product is selected and there is no data yet
  const [initialValues, setInitialValues] = useState(productValues)

  const productId = productValues.id
  const isEditMode = editProductId === productId

  const onSuccessSave = useCallback(() => {
    handleProductEdit(null)

    // Determine which variables should be visible by comparing bookingVariables and selected media products
    const variablesToBeAsked = getVariablesToBeAsked(bookingVariables, values[MEDIA_PRODUCTS])
    // If there are more variables to be asked than those already in the form...
    if (variablesToBeAsked.length > values[VARIABLES].length) {
      // Filter out variables already present in the form's VARIABLES array and format the new variables
      const variablesToAdd = variablesToBeAsked
        .filter(variable => {
          // Exclude variables that are already in the form state by comparing their IDs
          return !values[VARIABLES].find(value => value.data.id === variable.id)
        })
        // Prepare each new variable for insertion into the form
        .map(variable => formatInitialVariable(variable))

      // Update the form state by appending the new variables to the existing list
      setFieldValue(VARIABLES, [...values[VARIABLES], ...variablesToAdd])
    }
  }, [handleProductEdit, setFieldValue, bookingVariables, values])

  const handleSaveProduct = useHandleAutoSave({
    onSuccessSave,
    productPath,
    setFieldTouched,
    validateForm,
    submitForm,
    allowAutoSave
  })

  const handleStartEditProduct = useCallback(() => {
    // save the values before edit start, to be able to reset on these
    setInitialValues(productValues)
    handleProductEdit(productId)
  }, [productValues, handleProductEdit, productId])

  const handleResetProduct = useCallback(() => {
    setFieldValue(productPath, initialValues)

    const hasPeriods = initialValues[PRODUCT_PERIODS_DATES].length > 0
    if (!hasPeriods) {
      // we can't keep empty product, so delete it
      handleDeleteProduct(productId)
    }
    // turn off edit mode
    handleProductEdit(null)
  }, [setFieldValue, productPath, initialValues, handleProductEdit, handleDeleteProduct, productId])

  if (!productValues.data) {
    // don't show edit mode if product wasn't selected
    return (
      <ButtonIcon className={classes.smallButton} Icon={DeleteIcon} onClick={() => handleDeleteProduct(productId)} />
    )
  }

  if (!isEditMode) {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonIcon className={classes.smallButton} Icon={PencilIcon} onClick={handleStartEditProduct} />
        <ButtonIcon className={classes.smallButton} Icon={DeleteIcon} onClick={() => handleDeleteProduct(productId)} />
      </div>
    )
  }

  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.bigButton} onClick={handleResetProduct}>
        <Icon>
          <CloseIcon />
        </Icon>
      </div>
      <div className={classes.bigButton} onClick={handleSaveProduct}>
        <Icon>
          <CheckmarkIcon />
        </Icon>
      </div>
    </div>
  )
}

ActionsCell.propTypes = {
  formik: PropTypes.object.isRequired,
  productIndex: PropTypes.number.isRequired,
  productValues: PropTypes.object.isRequired,
  allowAutoSave: PropTypes.bool.isRequired,
  editProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleDeleteProduct: PropTypes.func.isRequired,
  handleProductEdit: PropTypes.func.isRequired
}

export default ActionsCell

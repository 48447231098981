import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductTagsEditForm from './MediaProductTagsEditForm'
import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import {
  productTagsSelector,
  productTagsIsLoadingSelector,
  productTagsWasLoadedSelector
} from '../../../../../modules/selectors/tags'

import { EDIT_PRODUCT_TAGS } from '../../../../../constants/forms'

function MediaProductTagsEdit() {
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: EDIT_PRODUCT_TAGS
  })

  const productTagsIsLoading = useSelector(productTagsIsLoadingSelector)
  const productTagsWasLoaded = useSelector(productTagsWasLoadedSelector)

  const productTags = useSelector(productTagsSelector)

  const isFormLoading = useMemo(() => {
    return productTagsIsLoading || !productTagsWasLoaded
  }, [productTagsIsLoading, productTagsWasLoaded])

  const editProductTagData = useMemo(
    () => productTags.find(tag => tag?.id === Number(selectedEditItemId)) || {},
    [productTags, selectedEditItemId]
  )

  return (
    <FormDrawerWrapper
      formName={EDIT_PRODUCT_TAGS}
      title="Edit tag"
      isFormLoading={isFormLoading}
      showOpenButton={false}
      closeOnSubmit={true}
    >
      <MediaProductTagsEditForm editProductTagData={editProductTagData} />
    </FormDrawerWrapper>
  )
}

export default MediaProductTagsEdit

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import Table from '../../../../../../components/Table'
import DueDateField from './DueDateField'
import ModalForDisapprovalReason from './ModalForDisapprovalReason'
import ButtonColumn from './ButtonColumn'
import FileStatusCell from './FileStatusCell'
import RequestedFileCell from './RequestedFileCell'
import FileInstancesModal from '../FileInstancesModal'

import useGetAccessibleUrl from '../../../../../../features/hooks/useGetAccessibleUrl'
import {
  useGetUpdatingId,
  useHandleDeleteUploadFile,
  useOpenFileForm,
  useGetDropdownOptions,
  useGetUpdatingIds
} from './hooks'
import { useDisapprovalModal } from './hooks/useDisapprovalModal'
import useFileStatusUpdateToBE from './hooks/useFileStatusUpdateToBE'
import { useFileInstancesModal } from './hooks/useFileInstancesModal'

import { getStatusForDisapprovalAction } from '../../../../../../features/components/UploadedFilesField/helpers'
import { isControllerBuyerApprovalSelector } from '../../../../../../modules/selectors/app'
import { bookedMediaSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import { areUrlsEqual } from '../FileInstancesModal/helpers'
import { formatDateFullYear } from '../../../../../../constants/dates'

import { tableColumnsSize } from '../index'
import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'

const RequestedFilesTableData = ({ files }) => {
  const { handleFileDownloading } = useGetAccessibleUrl()

  const bookedMedia = useSelector(bookedMediaSelector)
  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)

  const handleFileDownload = useCallback(
    uploadedFile => () => {
      handleFileDownloading({
        url: uploadedFile?.file_url,
        file_name: uploadedFile?.name
      })
    },
    [handleFileDownloading]
  )

  const { showFileInstancesModal, handleCloseFileInstancesModal, fileInstancesModalData, openFileInstancesModal } =
    useFileInstancesModal()

  const bulkUpdateStatusHandler = useFileStatusUpdateToBE()

  const handleStatusChange = useCallback(
    data => {
      const fileUrl = data.uploadedFile.file_url
      const allFileIds = bookedMedia.flatMap(media =>
        media.uploaded_files.filter(file => areUrlsEqual(file.file_url, fileUrl)).map(file => file.id)
      )

      if (allFileIds.length > 1) {
        // If the user updates an uploaded file status (either using chip update method or Actions dropdown) and there is at
        // least one more uploaded file that has the same uploaded_file file_url in the same media order then prompt modal

        openFileInstancesModal({
          ...data,
          allFileIds
        })
      } else {
        // If there is only one uploaded file still use the bulk update method
        bulkUpdateStatusHandler({
          ids: [data.uploadedFile.id],
          status: data.status,
          disapprovedReason: data[DISAPPROVED_REASON]
        })
      }
    },
    [bookedMedia, bulkUpdateStatusHandler, openFileInstancesModal]
  )

  const {
    handleShowDisapprovalModal,
    handleCloseDisapprovalModal,
    showDisapprovalReasonModal,
    selectedRequirementId,
    selectedUploadedFile
  } = useDisapprovalModal()

  const onDisapproveStatusChange = useCallback(
    ({ uploadedFile, [DISAPPROVED_REASON]: disapprovedReason }) => {
      const disapprovalActionStatus = getStatusForDisapprovalAction(uploadedFile, isControllerBuyerApproval)

      handleStatusChange({
        status: disapprovalActionStatus,
        uploadedFile,
        [DISAPPROVED_REASON]: disapprovedReason
      })
      handleCloseDisapprovalModal()
    },
    [handleStatusChange, handleCloseDisapprovalModal, isControllerBuyerApproval]
  )

  const getItemUpdatingId = useGetUpdatingId()
  const updatingIds = useGetUpdatingIds(files)

  const openUploadFileForm = useOpenFileForm()

  const handleDelete = useHandleDeleteUploadFile()

  const getDropdownOptions = useGetDropdownOptions({
    handleDelete,
    openUploadFileForm,
    handleFileDownload,
    handleShowDisapprovalModal,
    handleStatusChange
  })

  const requestedFilesColumns = useMemo(
    () => [
      {
        header: 'Requested file',
        Cell: ({ required: requirements, uploaded: uploadedFile, requirementFileId: id, mediaId }) => {
          return (
            <RequestedFileCell
              requirements={requirements}
              uploadedFile={uploadedFile}
              id={id}
              mediaId={mediaId}
              handleFileDownloading={handleFileDownloading}
            />
          )
        }
      },
      {
        header: 'Period Start',
        Cell: ({ dateStart }) => formatDateFullYear(dateStart),
        style: { maxWidth: tableColumnsSize.mediaPeriod }
      },
      {
        header: 'Status',
        Cell: ({ uploaded: uploadedFile, requirementFileId }) => (
          <FileStatusCell
            uploadedFile={uploadedFile}
            requirementFileId={requirementFileId}
            handleShowDisapprovalModal={handleShowDisapprovalModal}
            handleStatusChange={handleStatusChange}
          />
        ),
        style: { width: tableColumnsSize.status, minWidth: tableColumnsSize.status }
      },
      {
        header: 'Due date',
        Cell: ({ deadline, uploaded: uploadedFile }) => (!uploadedFile ? <DueDateField deadline={deadline} /> : ''),
        style: { maxWidth: tableColumnsSize.dueDate }
      },
      {
        Cell: ({ requirementFileId, mediaId, uploaded: uploadedFile, deadline }) => (
          <ButtonColumn
            requirementFileId={requirementFileId}
            uploadedFile={uploadedFile}
            mediaId={mediaId}
            deadline={deadline}
            handleDelete={handleDelete}
            openUploadFileForm={openUploadFileForm}
          />
        ),
        style: { maxWidth: tableColumnsSize.uploadButton }
      },
      {
        Cell: ({ mediaId, requirementFileId, uploaded: uploadedFile }) => {
          return (
            <ActionsDropdown
              itemId={requirementFileId}
              options={getDropdownOptions({ requirementFileId, mediaId, uploadedFile })}
            />
          )
        },
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    [
      handleFileDownloading,
      handleShowDisapprovalModal,
      handleStatusChange,
      handleDelete,
      openUploadFileForm,
      getDropdownOptions
    ]
  )

  return (
    <>
      <Table
        hideFooterRow
        data={files}
        cols={requestedFilesColumns}
        itemUpdatingId={getItemUpdatingId}
        updatingIds={updatingIds}
      />

      {showDisapprovalReasonModal && (
        // Don't render modal if it's not open, to reset the form on close
        <ModalForDisapprovalReason
          isOpen={showDisapprovalReasonModal}
          onClose={handleCloseDisapprovalModal}
          onSubmit={onDisapproveStatusChange}
          requirementFileId={selectedRequirementId}
          uploadedFile={selectedUploadedFile}
        />
      )}

      <FileInstancesModal
        isOpen={showFileInstancesModal}
        onFilesStatusChange={bulkUpdateStatusHandler}
        onClose={handleCloseFileInstancesModal}
        data={fileInstancesModalData}
      />
    </>
  )
}

RequestedFilesTableData.propTypes = {
  files: PropTypes.array.isRequired
}

export default RequestedFilesTableData

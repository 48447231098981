import { createStyles } from '../../../../../../../../styles/helpers'

import { darkGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  cellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal'
  },
  period: {
    color: darkGrey,
    fontSize: 12,
    marginTop: 6
  },
  productName: {
    whiteSpace: 'normal',
    fontSize: 12
  },
  multipleAssets: {
    fontSize: 12
  }
})

export default useStyles

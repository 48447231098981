import React, { useMemo } from 'react'

import Chip from '../../../../../../components/Chip'

import { getContractStatusChipColor } from '../../../../../../features/components/Contract/helpers'

import {
  CONTRACT_NEW_STATUS,
  CONTRACT_PENDING_APPROVAL_STATUS,
  QUOTATION_STATUS
} from '../../../../../../constants/contacts'

import useStyles from './styles'

const StatusCell = ({ quotation_status, status }) => {
  const classes = useStyles()

  // if quotation_status is not expired, we use status
  const statusToUse = quotation_status !== QUOTATION_STATUS.EXPIRED ? status : quotation_status

  const statusText = useMemo(() => {
    switch (statusToUse) {
      case CONTRACT_PENDING_APPROVAL_STATUS:
        return 'pending'
      case CONTRACT_NEW_STATUS:
        return 'active'
      default:
        return statusToUse
    }
  }, [statusToUse])

  return (
    <Chip
      className={classes.statusChip}
      text={statusText}
      color={getContractStatusChipColor(statusToUse)}
      fontSize={11}
    />
  )
}

export default StatusCell

import { all, call, put, takeEvery } from 'redux-saga/effects'

import { getFileNameFromHeaders } from '../../helpers/api'

import {
  GET_MEDIA_PACKAGE,
  getMediaPackageSuccess,
  getMediaPackageFailure,
  GET_MEDIA_PACKAGES_AS_CSV,
  getMediaPackagesAsCsvSuccess,
  getMediaPackagesAsCsvFailure
} from '../actions/mediaPackages'
import { getMediaPackagesAsCsvService, getMediaPackageService } from '../services/mediaPackages'

function* mediaPackagesWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MEDIA_PACKAGE, getMediaPackageWorker),
    takeEvery(GET_MEDIA_PACKAGES_AS_CSV, getMediaPackagesAsCsvWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getMediaPackageWorker({ id, params }) {
  try {
    const response = yield call(getMediaPackageService, id, params)
    yield put(getMediaPackageSuccess(response))
  } catch (error) {
    yield put(getMediaPackageFailure(error))
  }
}

function* getMediaPackagesAsCsvWorker({ params }) {
  try {
    const response = yield call(getMediaPackagesAsCsvService, params)
    const { data, headers } = response
    // file name is stored in response content-disposition header
    const formattedFilename = getFileNameFromHeaders(headers)

    yield put(getMediaPackagesAsCsvSuccess(data, formattedFilename))
  } catch (error) {
    yield put(getMediaPackagesAsCsvFailure(error))
  }
}

export default mediaPackagesWatcher

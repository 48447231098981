import React from 'react'
import PropTypes from 'prop-types'

import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'
import ErrorMessage from '../../../../../../../components/Form/ErrorMessage'

import useStyles from './styles'

const ContractFieldWrapper = ({ isEditMode, label, children, maxWidth, minWidth, preview, error, fieldTouched }) => {
  const classes = useStyles({ maxWidth, minWidth })

  return (
    <FieldLabelWrapper
      label={label}
      className={classes.fieldLabelWrapper}
      labelClassName={isEditMode ? '' : classes.previewModeLabel}
    >
      {isEditMode ? (
        children
      ) : (
        <>
          {preview && <h3>{preview}</h3>}
          {error && fieldTouched && <ErrorMessage className={classes.error} error={error} />}
        </>
      )}
    </FieldLabelWrapper>
  )
}

ContractFieldWrapper.propTypes = {
  isEditMode: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string
}

export default ContractFieldWrapper

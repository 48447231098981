import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectedControllerRelatedSelfAccountsListSelector,
  selectedSelfAccountDataSelector
} from '../../../../modules/selectors/app'
import { setContextSelfAccount } from '../../../../modules/actions/app'

export const MediaOrderFormContext = React.createContext()

export function MediaOrderFormProvider({ children, initialSelfAccount }) {
  const dispatch = useDispatch()

  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)
  const initialAccountData = useMemo(() => {
    return controllerSelfAccountsList.find(account => account.id === initialSelfAccount)
  }, [controllerSelfAccountsList, initialSelfAccount])

  const [createdContractPayload, setCreatedContractPayload] = useState(null)
  const [formikSetFieldValue, setFormikSetFieldValue] = useState(() => null)
  const [formikSetTouched, setFormikSetTouched] = useState(() => null)
  const [contextSelfAccountData, setContextSelfAccountData] = useState(initialAccountData || selectedSelfAccountData)

  const currency = useMemo(
    () => ({
      code: contextSelfAccountData?.currency,
      symbol: contextSelfAccountData?.currency_symbol
    }),
    [contextSelfAccountData]
  )

  const handleContextSelfAccountChange = useCallback(
    newSelfAccountData => {
      setContextSelfAccountData(newSelfAccountData)
      dispatch(setContextSelfAccount(newSelfAccountData?.id))
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      // reset context self account
      dispatch(setContextSelfAccount(undefined))
    }
  }, [dispatch])

  return (
    <MediaOrderFormContext.Provider
      value={{
        currency,
        createdContractPayload,
        setCreatedContractPayload,
        contextSelfAccountData,
        handleContextSelfAccountChange,
        formikSetFieldValue,
        setFormikSetFieldValue,
        formikSetTouched,
        setFormikSetTouched
      }}
    >
      {children}
    </MediaOrderFormContext.Provider>
  )
}

import React, { useCallback } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import BrandsSection from './BrandsSection'
import MinimizedListField from '../../../../../../features/components/Form/MinimizedListField'

import {
  initialBrandValue,
  BRANDS,
  BRAND_NAME
} from '../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import useStyles from './styles'

const BrandsContent = ({ formik }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleBrandTitleGenerate = useCallback(itemValue => {
    const brandName = getIn(itemValue, BRAND_NAME)
    return brandName || 'Set brand name'
  }, [])

  return (
    <MinimizedListField
      formik={formik}
      fieldPath={BRANDS}
      initialItemIdKey="id"
      initialListValue={initialBrandValue}
      sectionHeaderClassName={classes.brandSectionHeader}
      firstItemText={t('+ Add a brand name')}
      nextItemText={t('+ Add another brand name')}
      handleTitleGenerate={handleBrandTitleGenerate}
    >
      <BrandsSection formik={formik} />
    </MinimizedListField>
  )
}

BrandsContent.propTypes = {
  formik: PropTypes.object.isRequired
}

export default BrandsContent

import { useMediaQuery } from 'react-responsive'
import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../components/Form'
import ManageControllerFormContent from './ManageControllerFormContent'

import { clearUpdateController, updateController } from '../../../../modules/actions/controller'
import {
  controllersSelector,
  updateControllerErrorSelector,
  updateControllerIsLoadingSelector,
  updateControllerWasUpdatedSelector
} from '../../../../modules/selectors/controller'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { MANAGE_CONTROLLER } from '../../../../constants/forms'
import { languagesList } from '../../../../constants/selectLists/languagesList'
import { getInitialValues, transformValuesToBE, validationSchema } from './fields'

import { tabletUpSize } from '../../../../styles/const/breakpoints'
import useStyles from '../../EditProfile/styles'
import useFormWrapperStyles from '../../../../styles/common/formWrapper'

const ManageControllerForm = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const formWrapperClasses = useFormWrapperStyles()
  const dispatch = useDispatch()

  const isTabletUp = useMediaQuery({ minWidth: tabletUpSize })

  const controllers = useSelector(controllersSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const controllerWasUpdated = useSelector(updateControllerWasUpdatedSelector)
  const selectedController = useMemo(() => {
    return controllers.find(controller => controller.id === selectedControllerId) || {}
  }, [controllers, selectedControllerId])

  const onSubmit = useCallback(
    values => {
      const transformedValues = transformValuesToBE(values)
      dispatch(updateController(selectedControllerId, transformedValues))
    },
    [dispatch, selectedControllerId]
  )

  const formik = useFormik({
    initialValues: getInitialValues(selectedController, languagesList),
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  const clearUpdateControllerHandler = useCallback(() => {
    dispatch(clearUpdateController())
  }, [dispatch])

  return (
    <Form
      formName={MANAGE_CONTROLLER}
      formik={formik}
      successSubmit={controllerWasUpdated}
      errorSelector={updateControllerErrorSelector}
      isLoadingSelector={updateControllerIsLoadingSelector}
      submitText={t('Save changes')}
      formFooterClassName={isTabletUp ? formWrapperClasses.formFooter : null}
      buttonProps={{
        wrapperClassName: isTabletUp ? classes.submitButtonWrapper : null
      }}
      clearHandler={clearUpdateControllerHandler}
    >
      <ManageControllerFormContent formik={formik} languagesList={languagesList} />
    </Form>
  )
}

export default ManageControllerForm

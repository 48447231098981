import * as Yup from 'yup'

import nameValidation from '../../../../features/validations/name'

import {
  ADDRESS,
  EMAIL,
  EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS,
  FIRST_NAME,
  LAST_NAME,
  SEND_BOOKING_SUMMARY_NOTIFICATION,
  INTERNAL_ID,
  STORE_NAME,
  STORES,
  SUMMARY_DAYS_ADVANCE,
  SUMMARY_INTERVAL
} from './fields'

export const externalRecipientShape = Yup.object({
  // Validate the first name and last name only if the email is filled
  [FIRST_NAME]: Yup.string().when(EMAIL, {
    is: value => value?.length > 0,
    then: () => Yup.string().required('First name is required')
  }),
  [LAST_NAME]: Yup.string().when(EMAIL, {
    is: value => value?.length > 0,
    then: () => Yup.string().required('Last name is required')
  }),
  [EMAIL]: Yup.string().email('Please enter correct email')
})

export const storeShape = Yup.object({
  // validate the store name only if the store internal id is filled
  [STORE_NAME]: Yup.string().when(INTERNAL_ID, {
    is: value => value?.length > 0,
    then: () => Yup.string().required('Store name is required')
  })
})

export const validationSchema = Yup.object({
  ...nameValidation,
  [EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]: Yup.array().of(externalRecipientShape),
  [ADDRESS]: Yup.string().required('Address is required'),
  [SUMMARY_INTERVAL]: Yup.string().when(SEND_BOOKING_SUMMARY_NOTIFICATION, {
    is: true,
    then: () => Yup.string().required('Summary interval is required'),
    otherwise: () => Yup.string().notRequired().nullable()
  }),
  [SUMMARY_DAYS_ADVANCE]: Yup.string().when(SEND_BOOKING_SUMMARY_NOTIFICATION, {
    is: true,
    then: () => Yup.string().required('Summary days advance is required'),
    otherwise: () => Yup.string().notRequired().nullable()
  }),
  [STORES]: Yup.array().of(storeShape)
})

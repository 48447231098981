import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import SaveDataDetails from './SaveDataDetails'
import ProposalStatus from './ProposalStatus'
import Chip from '../../../../../components/Chip'

import { activeContractSelector } from '../../../../../modules/selectors/contracts'

import { CHIP_COLORS } from '../../../../../constants/other'

import useProposalStatusStyles from '../styles'
import useCommonStyles from '../../../../../styles/common/listPage'
import useStyles from './SaveDataDetails/styles'

const ProposalInfo = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const proposalStatusClasses = useProposalStatusStyles()

  // active contract is used to check if we are in edit mode(user may crete new contract or edit existing one)
  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id
  const sequentialId = activeContract?.media_order?.sequential_id || ''

  return (
    <>
      <h3 className={commonClasses.title}>{t('Proposal') + ' ' + sequentialId}</h3>
      {createdContractId ? (
        <>
          <SaveDataDetails />
          <ProposalStatus />
        </>
      ) : (
        <>
          <ul className={classes.saveDetailsContainer}>
            <li className={classes.detail}>{t('Created: Today')}</li>
            <li className={classes.detail}>{t('Last updated: Today')}</li>
          </ul>
          <div className={proposalStatusClasses.chipsContainer}>
            <Chip text={'Active'} color={CHIP_COLORS.green} />
          </div>
        </>
      )}
    </>
  )
}

export default ProposalInfo

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import ProductLocationsTableData from './ProductLocationsTableData'

import useSearch from '../../../../../hooks/useSearch'
import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import {
  mediaProductLocationsErrorSelector,
  mediaProductLocationsIsLoadingSelector,
  mediaProductLocationsNextSelector,
  mediaProductLocationsSelector,
  mediaProductLocationsWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import {
  clearGetMediaProductLocations,
  getMediaProductLocations
} from '../../../../../modules/actions/mediaOrdersProductLocations'

import { PRODUCT_LOCATIONS_SEARCH } from '../index'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const ProductLocationsTable = () => {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const controllerId = useSelector(selectedControllerIdSelector)
  const mediaProductLocationsNext = useSelector(mediaProductLocationsNextSelector)
  const mediaProductLocations = useSelector(mediaProductLocationsSelector)

  const productLocationsColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'title'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no product locations')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  const searchTerm = useSearch(PRODUCT_LOCATIONS_SEARCH)

  const params = useMemo(
    () => ({
      search: searchTerm,
      controller: controllerId
    }),
    [controllerId, searchTerm]
  )

  const loadMoreProductLocationsHandler = useLoadPaginatedList({
    params,
    action: getMediaProductLocations,
    clearAction: clearGetMediaProductLocations,
    next: mediaProductLocationsNext
  })

  return (
    <TableDataLoader
      loadMore={loadMoreProductLocationsHandler}
      itemsLength={mediaProductLocations.length}
      errorSelector={mediaProductLocationsErrorSelector}
      wasLoadedSelector={mediaProductLocationsWasLoadedSelector}
      isLoadingSelector={mediaProductLocationsIsLoadingSelector}
      skeletonProps={{ cols: productLocationsColumnsSchema }}
      noDataContent={noDataContent}
    >
      <ProductLocationsTableData />
    </TableDataLoader>
  )
}

export default ProductLocationsTable

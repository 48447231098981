import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import HeaderCell from '../HeaderCell'

import { ReactComponent as SortingIcon } from '../../../../assets/icons/sort.svg'

import { SORT } from '../../../../constants/other'

import useStyles from './styles'

const SortableCell = React.memo(
  ({
    tableSort,
    sortParameter,
    onSortingChange,
    enableSortingRemoval,
    sortDescFirst,
    tanstackHeader,
    tanstackSort,
    ...headerProps
  }) => {
    const classes = useStyles()

    const { handleSortingChange, isAsc, isDesc } = useMemo(() => {
      if (onSortingChange) {
        const isSorted = tableSort.parameter === sortParameter

        const handleSortingChange = () => {
          if (!isSorted) {
            // If sorting a different column, start with 'desc'
            // Determine default sort direction based on column type and sortDescFirst flag
            const defaultDirection = sortDescFirst ? SORT.DESC : SORT.ASC
            onSortingChange({ parameter: sortParameter, direction: defaultDirection })
          } else if (isDesc) {
            // If currently 'desc', switch to SORT.ASC
            onSortingChange({ parameter: sortParameter, direction: SORT.ASC })
          } else if (isAsc) {
            if (enableSortingRemoval) {
              // If removal is enabled, cycle to 'none' (i.e., remove sorting)
              onSortingChange({})
            } else {
              // If removal is disabled, cycle back to 'desc'
              onSortingChange({ parameter: sortParameter, direction: SORT.DESC })
            }
          } else {
            // Default case: set to 'desc'
            onSortingChange({ parameter: sortParameter, direction: SORT.DESC })
          }
        }

        // enableSortingRemoval Enables/Disables the ability to remove sorting for the table
        // If true then changing sort order will circle like: 'none' -> 'desc' -> 'asc' -> 'none' -> ...
        // If false then changing sort order will circle like: 'none' -> 'desc' -> 'asc' -> 'desc' -> 'asc' -> ...
        return {
          handleSortingChange,
          isSorted: tableSort.parameter === sortParameter,
          isAsc: isSorted && tableSort.direction === SORT.ASC,
          isDesc: isSorted && tableSort.direction === SORT.DESC
        }
      } else {
        const isSorted = tanstackHeader.column.getIsSorted()
        return {
          handleSortingChange: tanstackHeader.column.getToggleSortingHandler(),
          isAsc: isSorted === SORT.ASC,
          isDesc: isSorted === SORT.DESC
        }
      }
    }, [enableSortingRemoval, onSortingChange, sortDescFirst, sortParameter, tableSort, tanstackHeader.column])

    return (
      <HeaderCell {...headerProps} className={classes.sortCell} onClick={handleSortingChange}>
        <div
          className={classnames(classes.sortIconWrapper, {
            [classes.isAsc]: isAsc,
            [classes.isDesc]: isDesc
          })}
        >
          <SortingIcon className={classes.sortIcon} />
        </div>
      </HeaderCell>
    )
  }
)

SortableCell.propTypes = {
  tableSort: PropTypes.shape({
    parameter: PropTypes.string,
    direction: PropTypes.string
  }),
  sortParameter: PropTypes.string,
  enableSortingRemoval: PropTypes.bool,
  onSortingChange: PropTypes.func
}

export default SortableCell

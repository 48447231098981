import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import LocationsImagesPreview from './LocationsImagesPreview'

import {
  clearGetMediaProductLocation,
  getMediaProductLocation
} from '../../../../../../../../../../modules/actions/mediaOrdersProductLocations'
import { mediaProductLocationSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProductLocations'

import { ALT_TEXT } from '../../../../../../../../MediaProducts/MediaProductLocationForms/fields'

const ProductLocationImages = ({ selectedLocationId }) => {
  const dispatch = useDispatch()
  const attachedProductLocation = useSelector(mediaProductLocationSelector)

  const selectedLocationImages = useMemo(() => {
    return (
      attachedProductLocation?.images?.map((item, index) => ({
        ...item,
        // Use alt_text as title if it exists, otherwise use index + 1
        title: item[ALT_TEXT] || index + 1
      })) || []
    )
  }, [attachedProductLocation])

  useEffect(() => {
    if (selectedLocationId) {
      dispatch(
        getMediaProductLocation({
          id: selectedLocationId
        })
      )
    }
  }, [dispatch, selectedLocationId])

  useEffect(() => {
    return () => {
      dispatch(clearGetMediaProductLocation())
    }
  }, [dispatch])

  if (!selectedLocationImages.length) {
    return null
  }

  return (
    <LocationsImagesPreview
      locationName={attachedProductLocation?.name}
      selectedLocationImages={selectedLocationImages}
    />
  )
}

ProductLocationImages.propTypes = {
  selectedLocationId: PropTypes.number
}

export default ProductLocationImages

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import ActionText from '../../../../components/ActionText'
import MinimizedSectionField from './MinimizedSectionField'

const MinimizedListField = ({
  formik,
  fieldPath,
  firstItemText = '+ Add',
  nextItemText = '+ Add another',
  titleLocalizationVariable,
  handleTitleGenerate,
  initialItemIdKey,
  initialListValue,
  minCount = 0,
  maxCount = null,
  allowDelete = true,
  deleteBtnText,
  headerClassName,
  headerTitleClassName,
  sectionHeaderClassName,
  children
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const { values, setFieldValue } = formik

  const listValue = getIn(values, fieldPath)

  const listCount = listValue.length
  const allowDeleteItem = allowDelete && listCount > minCount
  const addNewItemButtonText = listCount ? nextItemText : firstItemText
  const allowAddAnother = maxCount ? listCount < maxCount : true

  const currentListValues = values[fieldPath]

  const handleAddAnotherItem = useCallback(() => {
    // open new item in list
    setSelected(currentListValues.length)
    // add new initial value to list
    const newItem = initialItemIdKey
      ? {
          ...initialListValue,
          [initialItemIdKey]: uuidv4()
        }
      : initialListValue
    setFieldValue(fieldPath, [...currentListValues, newItem])
  }, [currentListValues, initialItemIdKey, initialListValue, setFieldValue, fieldPath])

  const handleDeleteItem = useCallback(
    index => {
      // delete item from list
      const newCarouselItems = [...listValue]
      newCarouselItems.splice(index, 1)

      // open previous item in list
      setSelected(newCarouselItems.length - 1)
      setFieldValue(fieldPath, newCarouselItems)
    },
    [fieldPath, listValue, setFieldValue]
  )

  return (
    <>
      {listValue.map((item, index) => {
        return (
          <MinimizedSectionField
            key={item.id}
            formik={formik}
            itemPath={`${fieldPath}[${index}]`}
            index={index}
            selected={selected === index}
            allowDelete={allowDeleteItem}
            titleLocalizationVariable={titleLocalizationVariable}
            handleTitleGenerate={handleTitleGenerate}
            headerClassName={headerClassName}
            headerTitleClassName={headerTitleClassName}
            sectionHeaderClassName={sectionHeaderClassName}
            deleteBtnText={deleteBtnText}
            onSelect={() => setSelected(index)}
            onClose={() => setSelected(0)}
            onDelete={() => handleDeleteItem(index)}
          >
            {children}
          </MinimizedSectionField>
        )
      })}
      {allowAddAnother && (
        <ActionText onClick={handleAddAnotherItem} isDark>
          {t(addNewItemButtonText)}
        </ActionText>
      )}
    </>
  )
}

MinimizedListField.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldPath: PropTypes.string.isRequired,
  minCount: PropTypes.number,
  allowDelete: PropTypes.bool,
  firstItemText: PropTypes.string,
  nextItemText: PropTypes.string,
  initialItemIdKey: PropTypes.string,
  initialListValue: PropTypes.object.isRequired,
  headerClassName: PropTypes.string,
  headerTitleClassName: PropTypes.string,
  sectionHeaderClassName: PropTypes.string,
  titleLocalizationVariable: PropTypes.string,
  handleTitleGenerate: PropTypes.func,
  children: PropTypes.node.isRequired
}

export default MinimizedListField

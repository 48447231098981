import { getIn, setNestedObjectValues } from 'formik'
import { useCallback } from 'react'

export const useHandleAutoSave = ({
  onSuccessSave,
  productPath,
  setFieldTouched,
  validateForm,
  submitForm,
  allowAutoSave
}) => {
  const handleAutoSave = useCallback(() => {
    // don't allow to save product if has some errors
    validateForm().then(errors => {
      const productError = productPath ? getIn(errors, productPath) : errors
      const hasErrors = productError && Object.keys(productError).length > 0

      if (hasErrors) {
        // set touched to show errors
        if (productPath) {
          setFieldTouched(productPath, setNestedObjectValues(productError, true), false)
        } else {
          Object.keys(errors).forEach(key => {
            setFieldTouched(key, true, false)
          })
        }
      } else {
        // all data is already saved in the formik, so we just turn off edit mode and submit the form
        if (allowAutoSave) {
          submitForm().then(() => {
            // make sure the callback is send only after the formik onSubmit is finished
            // it helps to reset the editProductId only after the action was dispatched
            onSuccessSave && onSuccessSave()
          })
        } else {
          onSuccessSave && onSuccessSave()
        }
      }
    })
  }, [onSuccessSave, productPath, setFieldTouched, validateForm, submitForm, allowAutoSave])

  return handleAutoSave
}

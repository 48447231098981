import React, { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Qs from 'qs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ProgressButton from '../../../../../components/Form/ProgressButton'

import { downloadFileByBrowser } from '../../../../../helpers/other'

import { getDownloadTokenService } from '../../../../../modules/services/tools'
import { ENDPOINTS } from '../../../../../constants/api'

import useStyles from './styles'

const DownloadFilesButton = ({ bookedMediaId, productName }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    data: tokenData,
    isLoading: isTokenLoading,
    isError,
    isSuccess: tokenWasFetched,
    refetch: getToken
  } = useQuery({
    queryKey: ['downloadToken', bookedMediaId],
    queryFn: () => getDownloadTokenService(),
    enabled: false // Disable automatic query execution
  })

  const handleDownload = async () => {
    const params = {
      booked_media: bookedMediaId,
      download_token: tokenData.download_token
    }
    const url = `${ENDPOINTS.downloadFiles}?${Qs.stringify(params, { arrayFormat: 'repeat' })}`
    console.log('download_url', url)
    // download file without opening it in a new tab
    downloadFileByBrowser({
      url,
      fileName: productName,
      fileFormat: 'zip'
    })
  }

  const clearToken = useCallback(() => {
    queryClient.resetQueries(['downloadToken'])
  }, [queryClient])

  return (
    <ProgressButton
      wrapperClassName={classes.pbContainerReset}
      isFormLoading={isTokenLoading}
      formError={isError}
      successSubmit={tokenWasFetched}
      clearHandler={clearToken}
      onClick={getToken}
      onSuccessSubmit={handleDownload}
    >
      {t('Download All')}
    </ProgressButton>
  )
}

DownloadFilesButton.propTypes = {
  bookedMediaId: PropTypes.number.isRequired
}

export default DownloadFilesButton

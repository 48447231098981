import { concat } from '../../helpers/common'

const MODULE_NAME = 'FILES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const FILE_UPLOAD_START = concat(MODULE_NAME, 'FILE_UPLOAD_START')
export const FILE_UPLOAD_START_SUCCESS = concat(MODULE_NAME, 'FILE_UPLOAD_START_SUCCESS')
export const FILE_UPLOAD_START_FAILURE = concat(MODULE_NAME, 'FILE_UPLOAD_START_FAILURE')

export const MULTIPLE_FILES_UPLOAD_START = concat(MODULE_NAME, 'MULTIPLE_FILES_UPLOAD_START')
export const MULTIPLE_FILES_UPLOAD_START_SUCCESS = concat(MODULE_NAME, 'MULTIPLE_FILES_UPLOAD_START_SUCCESS')
export const MULTIPLE_FILES_UPLOAD_START_FAILURE = concat(MODULE_NAME, 'MULTIPLE_FILES_UPLOAD_START_FAILURE')

export const FILE_UPLOAD_TO_SERVER = concat(MODULE_NAME, 'FILE_UPLOAD_TO_SERVER')
export const FILE_UPLOAD_TO_SERVER_SUCCESS = concat(MODULE_NAME, 'FILE_UPLOAD_TO_SERVER_SUCCESS')
export const FILE_UPLOAD_TO_SERVER_FAILURE = concat(MODULE_NAME, 'FILE_UPLOAD_TO_SERVER_FAILURE')

export const FILE_UPLOAD_TO_FACEBOOK_PLATFORM = concat(MODULE_NAME, 'FILE_UPLOAD_TO_FACEBOOK_PLATFORM')
export const FILE_UPLOAD_TO_TIKTOK_PLATFORM = concat(MODULE_NAME, 'FILE_UPLOAD_TO_TIKTOK_PLATFORM')
export const FILE_UPLOAD_TO_PLATFORM_SUCCESS = concat(MODULE_NAME, 'FILE_UPLOAD_TO_PLATFORM_SUCCESS')
export const FILE_UPLOAD_TO_PLATFORM_FAILURE = concat(MODULE_NAME, 'FILE_UPLOAD_TO_PLATFORM_FAILURE')

export const GET_FACEBOOK_UPLOADED_VIDEO_DETAILS = concat(MODULE_NAME, 'GET_FACEBOOK_UPLOADED_VIDEO_DETAILS')
export const GET_TIKTOK_UPLOADED_VIDEO_DETAILS = concat(MODULE_NAME, 'GET_TIKTOK_UPLOADED_VIDEO_DETAILS')
export const GET_UPLOADED_VIDEO_DETAILS_SUCCESS = concat(MODULE_NAME, 'GET_UPLOADED_VIDEO_DETAILS_SUCCESS')
export const GET_UPLOADED_VIDEO_DETAILS_FAILURE = concat(MODULE_NAME, 'GET_UPLOADED_VIDEO_DETAILS_FAILURE')

export const GET_FILE_ACCESSIBLE_URL = concat(MODULE_NAME, 'GET_FILE_ACCESSIBLE_URL')
export const GET_FILE_ACCESSIBLE_URL_SUCCESS = concat(MODULE_NAME, 'GET_FILE_ACCESSIBLE_URL_SUCCESS')
export const GET_FILE_ACCESSIBLE_URL_FAILURE = concat(MODULE_NAME, 'GET_FILE_ACCESSIBLE_URL_FAILURE')
export const CLEAR_FILE_ACCESSIBLE_URLS = concat(MODULE_NAME, 'CLEAR_FILE_ACCESSIBLE_URLS')

export const UPDATE_FILE_UPLOAD_PROGRESS = concat(MODULE_NAME, 'UPDATE_FILE_UPLOAD_PROGRESS')

export const CLEAR_UPLOADED_FILE = concat(MODULE_NAME, 'CLEAR_UPLOADED_FILE')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

export function fileUpload(
  file,
  uploadOptions = {
    isPlatformRelated: false,
    platform: '',
    isPrivate: false,
    adAccountId: ''
  }
) {
  // file upload consists with 3 steps
  // STEP 1 - FILE_UPLOAD_START creates request to BE to /sign-url
  // on FILE_UPLOAD_START_SUCCESS next upload step starts FILE_UPLOAD_TO_SERVER
  return { type: FILE_UPLOAD_START, file, uploadOptions }
}

export function fileUploadSuccess(signedFileData) {
  return { type: FILE_UPLOAD_START_SUCCESS, signedFileData }
}

export function fileUploadFailure(error, fileName) {
  return { type: FILE_UPLOAD_START_FAILURE, error, fileName }
}

export function multipleFilesUpload(
  files,
  uploadOptions = {
    isPlatformRelated: false,
    platform: '',
    isPrivate: false,
    account: ''
  }
) {
  // file upload consists with 3 steps
  // STEP 1 - MULTIPLE_FILES_UPLOAD_START creates request to BE to /sign-url for multiple files
  // on MULTIPLE_FILES_UPLOAD_START_SUCCESS next upload step starts FILE_UPLOAD_TO_SERVER but from this point it's called for each file separately
  return { type: MULTIPLE_FILES_UPLOAD_START, files, uploadOptions }
}

export function multipleFilesUploadSuccess(signedFilesData) {
  return { type: MULTIPLE_FILES_UPLOAD_START_SUCCESS, signedFilesData }
}

export function multipleFilesUploadFailure(error) {
  return { type: MULTIPLE_FILES_UPLOAD_START_FAILURE, error }
}

export function fileUploadToServer(signedFile, uploadOptions = {}) {
  // STEP 2 - FILE_UPLOAD_TO_SERVER creates request with sending file to S3 bucket within the BE sign token
  // on FILE_UPLOAD_TO_SERVER next upload step starts FILE_UPLOAD_TO_FACEBOOK_PLATFORM when isPlatformRelated === true
  return { type: FILE_UPLOAD_TO_SERVER, signedFile, uploadOptions }
}

export function fileUploadToServerSuccess({ fileUrl, fileName, fileId, isPlatformRelated }) {
  return { type: FILE_UPLOAD_TO_SERVER_SUCCESS, fileUrl, fileName, fileId, isPlatformRelated }
}

export function fileUploadToServerFailure(error, fileName) {
  return { type: FILE_UPLOAD_TO_SERVER_FAILURE, error, fileName }
}

// platform specific start actions created to separate saga workers
export function fileUploadToFacebookPlatform(fileData, fileName, fileType, adAccountId) {
  // STEP 3 - FILE_UPLOAD_TO_SERVER creates request which triggers BE to send file from Server(s3) to Facebook platform
  return { type: FILE_UPLOAD_TO_FACEBOOK_PLATFORM, fileData, fileName, fileType, adAccountId }
}

export function fileUploadToTikTokPlatform(fileUrl, fileName, fileType, adAccountId) {
  return { type: FILE_UPLOAD_TO_TIKTOK_PLATFORM, fileUrl, fileName, fileType, adAccountId }
}

export function fileUploadToPlatformSuccess(fileData, fileName) {
  return { type: FILE_UPLOAD_TO_PLATFORM_SUCCESS, fileData, fileName }
}

export function fileUploadToPlatformFailure(error, fileName) {
  return { type: FILE_UPLOAD_TO_PLATFORM_FAILURE, error, fileName }
}

export function updateFileUploadProgress(uploadProgress) {
  return { type: UPDATE_FILE_UPLOAD_PROGRESS, uploadProgress }
}

// get uploaded video details
// platform specific start actions created to separate saga workers
export function getFacebookUploadedVideoDetails(videoId, fileName, adAccountId) {
  return { type: GET_FACEBOOK_UPLOADED_VIDEO_DETAILS, videoId, fileName, adAccountId }
}

export function getTikTokUploadedVideoDetails(videoId, fileName, adAccountId) {
  return { type: GET_TIKTOK_UPLOADED_VIDEO_DETAILS, videoId, fileName, adAccountId }
}

export function getUploadedVideoDetailsSuccess(fileData, fileName) {
  return { type: GET_UPLOADED_VIDEO_DETAILS_SUCCESS, fileData, fileName }
}

export function getUploadedVideoDetailsFailure(error, fileName) {
  return { type: GET_UPLOADED_VIDEO_DETAILS_FAILURE, error, fileName }
}

export function clearUploadedFile(fileName) {
  return { type: CLEAR_UPLOADED_FILE, fileName }
}

export function getAccessibleUrl(data, params, sentryData) {
  // usually files have private urls, with token parameters in link which allows to access the file
  // token params have expiration time, so it should be renewed when files needs to ba accessed
  // this endpoint allows to get accessible URL with new token parameters
  return { type: GET_FILE_ACCESSIBLE_URL, data, params, sentryData }
}

export function getAccessibleUrlSuccess(originalUrl, accessibleUrl) {
  return { type: GET_FILE_ACCESSIBLE_URL_SUCCESS, originalUrl, accessibleUrl }
}

export function getAccessibleUrlFailure(error, url) {
  return { type: GET_FILE_ACCESSIBLE_URL_FAILURE, error, url }
}

export function clearFileAccessibleUrls() {
  return { type: CLEAR_FILE_ACCESSIBLE_URLS }
}

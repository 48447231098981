import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import ProposalMediaOrderGenerateForm from './ProposalMediaOrderGenerateForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import { useRedirectToGeneratedMediaOrder } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/hooks/useRedirectToGeneratedMediaOrder'

import { openForm } from '../../../../../modules/actions/forms'
import { clearMembersList, getMembersList } from '../../../../../modules/actions/selfAccountTeam'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import { membersIsLoadingSelector } from '../../../../../modules/selectors/selfAccountTeam'

import { PROPOSAL_MEDIA_ORDER_RECIPIENTS } from '../../../../../constants/forms'

function ProposalMediaOrderGenerate({
  contractId,
  contractData,
  checkContractValidity,
  shouldCheckApprovalRequirements,
  isMatchRequirements,
  isDisabled
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selfAccount = useSelector(selectedSelfAccountSelector)
  const membersIsLoading = useSelector(membersIsLoadingSelector)

  // when we are in create mode and are creating the contract for different self account to selectedSelfAccount, then:
  // we need to pass the self account id to redirect for correct media order form
  const contractSelfAccountId = contractData?.account
  const onAfterFormClose = useCallback(() => {
    dispatch(clearMembersList())
  }, [dispatch])

  const { isFormOpen } = useManageFormsDrawer({
    formName: PROPOSAL_MEDIA_ORDER_RECIPIENTS,
    onAfterFormClose
  })

  const handleOpenCreateMediaOrder = useCallback(() => {
    dispatch(openForm({ formName: PROPOSAL_MEDIA_ORDER_RECIPIENTS }))
  }, [dispatch])

  const handleOpenGenerateDrawer = useCallback(async () => {
    const isContractValid = await checkContractValidity()

    if (isContractValid) {
      let allowCreateMediaOrder = true

      // if controller quotation_approval_workflow = true, then we need to have approvals logic on proposals builder
      if (shouldCheckApprovalRequirements) {
        allowCreateMediaOrder = isMatchRequirements()
      }

      if (allowCreateMediaOrder) {
        handleOpenCreateMediaOrder()
      }
    }
  }, [checkContractValidity, handleOpenCreateMediaOrder, isMatchRequirements, shouldCheckApprovalRequirements])

  const redirectToGeneratedMediaOrder = useRedirectToGeneratedMediaOrder(contractSelfAccountId)

  useEffect(() => {
    // we fetch members with limit 1 just to find out if there are any members,
    // if there are members we preselect radio button for platform users
    // otherwise we preselect radio button for email
    if (isFormOpen) {
      dispatch(getMembersList({ account: selfAccount, limit: 1 }))
    }
  }, [dispatch, selfAccount, isFormOpen])

  return (
    <>
      <Button onClick={handleOpenGenerateDrawer} solid={true} disabled={isDisabled}>
        {t('Confirm and book')}
      </Button>

      <FormDrawerWrapper
        formName={PROPOSAL_MEDIA_ORDER_RECIPIENTS}
        title="Confirmation Email Recipients"
        description="Select platform users or enter email addresses to receive a confirmation email for this booking. Details of the required file specifications will also be included if applicable."
        isFormLoading={membersIsLoading}
        showOpenButton={false}
      >
        <ProposalMediaOrderGenerateForm contractId={contractId} onSuccessSubmit={redirectToGeneratedMediaOrder} />
      </FormDrawerWrapper>
    </>
  )
}

ProposalMediaOrderGenerate.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  btnClassName: PropTypes.string,
  shouldCheckApprovalRequirements: PropTypes.bool,
  isMatchRequirements: PropTypes.func
}

export default ProposalMediaOrderGenerate

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import Field from '../../../../../../../components/Form/Field'
import ContractFieldWrapper from '../ContractFieldWrapper'

import { useBrandsOptions } from './hooks'

import { BRAND } from '../../../fields'

const BrandField = ({ formik, isEditMode }) => {
  const brandsOptions = useBrandsOptions()

  const { values, errors, touched } = formik
  const error = getIn(errors, BRAND)
  const fieldTouched = getIn(touched, BRAND)

  const brand = values[BRAND]

  const getBrandLabel = useCallback(
    selectedBrand => {
      return brandsOptions.find(brand => brand.value === selectedBrand)?.label || ''
    },
    [brandsOptions]
  )

  const preview = useMemo(() => getBrandLabel(brand), [getBrandLabel, brand])

  return (
    <ContractFieldWrapper
      label="Brand"
      maxWidth={'145px'}
      minWidth={'145px'}
      isEditMode={isEditMode}
      preview={preview}
      error={error}
      fieldTouched={fieldTouched}
    >
      <Field formik={formik} name={BRAND} options={brandsOptions} placeholder="Brand Name" />
    </ContractFieldWrapper>
  )
}

BrandField.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired
}

export default BrandField

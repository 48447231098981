import { createStyles } from '../../../../../styles/helpers'
import { darkGrey, mediumGrey } from '../../../../../styles/const/colors'
import { zIndex1, zIndex10 } from '../../../../../styles/const/common'

const useStyles = createStyles({
  topMargin: {
    marginTop: 32
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: zIndex10,
    backgroundColor: 'rgba(0, 0, 0, .5)',
    color: darkGrey
  },
  container: {
    position: 'relative',
    background: '#fff',
    borderRadius: 6,
    border: `1px solid ${mediumGrey}`,
    padding: '18px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: props => (!!props?.isEditMode ? zIndex10 : zIndex1),
    marginBottom: 32
  },
  mediaOrderInfo: {
    padding: '0 24px',
    minWidth: 400,
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  }
})

export default useStyles

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FieldLabelWrapper from '../../../../features/components/Form/FieldLabelWrapper'
import Field from '../../../../components/Form/Field'

import { EMAIL, FIRST_NAME } from '../fields'

import useStyles from './styles'

// itemPath is passed automatically from the wrapper component i.e. MinimizedListField
const RecipientEmailSection = ({ formik, itemPath }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <FieldLabelWrapper
        label={t('First name')}
        labelFor={`${itemPath}[${FIRST_NAME}]`}
        className={classes.firstNameFieldWrapper}
      >
        <Field
          placeholder={t('First name')}
          formik={formik}
          name={`${itemPath}[${FIRST_NAME}]`}
          type="text"
          autoComplete="given-name"
        />
      </FieldLabelWrapper>
      <FieldLabelWrapper label={t('Email address')} labelFor={`${itemPath}[${EMAIL}]`}>
        <Field placeholder={t('Email address')} formik={formik} name={`${itemPath}[${EMAIL}]`} />
      </FieldLabelWrapper>
    </>
  )
}

RecipientEmailSection.propTypes = {
  formik: PropTypes.object.isRequired
}

export default RecipientEmailSection

import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '../../../../../../../../../../helpers/numbers'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import {
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY
} from '../../../../../../../../MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useFieldOptimizedStyles from '../styles'
import useStyles from './styles'

const FieldOptimizedPlaceholder = ({ formik, index, withBookedField }) => {
  const { t } = useTranslation()
  const { values, errors, touched } = formik

  const fieldOptimizedClasses = useFieldOptimizedStyles()

  const error = errors[INVENTORY_LIST_DATA]?.[index]?.[INVENTORY_QUANTITY]
  const isTouched = touched?.[INVENTORY_LIST_DATA]?.[index]?.[INVENTORY_QUANTITY]
  const quantity = values?.[INVENTORY_LIST_DATA]?.[index]?.[INVENTORY_QUANTITY]

  const fieldPlaceholderStylesParams = useMemo(
    () => ({
      error,
      isTouched
    }),
    [error, isTouched]
  )

  const classes = useStyles(fieldPlaceholderStylesParams)

  return (
    <>
      <div
        className={classnames(
          fieldOptimizedClasses.quantityField,
          {
            [fieldOptimizedClasses.quantityFieldWithBooked]: withBookedField
          },
          classes.quantityFieldPlaceholder
        )}
      >
        {typeof quantity === 'number' ? formatNumber(quantity) : t('Qty')}
      </div>
      {error && isTouched && <ErrorMessage error={error} />}
    </>
  )
}

FieldOptimizedPlaceholder.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  withBookedField: PropTypes.bool
}

export default FieldOptimizedPlaceholder

import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Skeleton from 'react-loading-skeleton'
import CostCell from './Cells/CostCell'
import ActionsCell from './Cells/ActionsCell'
import ReachCell from './Cells/ReachCell'
import DatesCell from './Cells/DatesCell'
import DiscountCell from './Cells/DiscountCell'
import QuantityCell from './Cells/QuantityCell'
import FooterSummary from './FooterSummary'
import Table from '../../../../../../components/Table'
import { ReactComponent as DragableDots } from '../../../../../../assets/icons/draggable-dots.svg'
import LocationCategoryCell from './Cells/LocationCategoryCell'
import LocationRegionCell from './Cells/LocationRegionCell'
import CategoryCell from './Cells/CategoryCell'

import { ContractBuilderContext } from '../ContractBuilderContext'
import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import useSaveData from './useSaveData'
import useReachData from './useReachData'
import useOrderProducts from './useOrderProducts'
import { useAssetColumns } from './Cells/useAssetColumns'

import { useFetchProductsTableData } from './useFetchProductsTableData'
import { insertIf } from '../../../../../../helpers/common'
import { formatCurrency } from '../../../../../../helpers/numbers'
import { getOriginalProductPrice } from './helpers/getProductValue'
import { getTableCopyFormat } from './helpers/tableCopyFormat'

import { getVariablesToBeAsked } from '../../AdditionalContractInfo/ContractFields/helpers'
import { bookingVariablesSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import { updatedContractProductIdsSelector } from '../../../../../../modules/selectors/contracts'

import { MEDIA_PRODUCTS, VARIABLES } from '../../fields'
import {
  DATA_COST,
  DATA_DATES,
  DATA_DISCOUNT,
  DATA_QUANTITY,
  DATA_RATE,
  DATA_REACH,
  SORT_PARAMETERS
} from './constants'
import useStyles from './styles'

export const QUOTATION_BUILDER_TABLE_ID = 'quotation-builder-table'
export const tableColumnsSize = {
  dragBtn: 40,
  rate: 110,
  discount: 68,
  cost: 110
}

const ProductsTable = ({
  formik,
  isAdditionalInfoEdit,
  allowEdit,
  allowAutoSave,
  checkInventory = true,
  isAmendment
}) => {
  const classes = useStyles()
  // when the Media order contract is in amendment process, we allow to edit the products
  const isAmendProcess = isAmendment && allowEdit

  const bookingVariables = useSelector(bookingVariablesSelector)
  const updatingProductIds = useSelector(updatedContractProductIdsSelector)

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [locationCategory, setLocationCategory] = useState(null)
  const [locationRegion, setLocationRegion] = useState(null)
  const { tableSort, setTableSort, editProductId, setEditProductId } = useContext(ContractBuilderContext)
  const { values, setFieldValue } = formik

  const products = values[MEDIA_PRODUCTS]
  const selectedProductsIds = useMemo(() => {
    return products.map(product => product.data?.id)
  }, [products])

  const {
    // contextSelfAccountData,
    currency
  } = useContext(MediaOrderFormContext)
  const currencySymbol = currency?.symbol

  const { handleSaveDataToBE } = useSaveData({ values, allowEdit, allowAutoSave })
  const { orderedProducts, handleProductsOrderUpdate, handleSortChange } = useOrderProducts({
    products,
    values,
    setFieldValue,
    setTableSort,
    allowEdit,
    allowAutoSave
  })

  const handleDeleteProduct = useCallback(
    productId => {
      const newProducts = products.filter(product => product.id !== productId)
      setFieldValue(MEDIA_PRODUCTS, newProducts)

      handleSaveDataToBE({ updatedProductValues: newProducts })
      // reset selected category - cover case when empty row was deleted
      setSelectedCategory(null)

      // Get the list of variables that should currently be visible based on booking details and new media products.
      const variablesToBeAsked = getVariablesToBeAsked(bookingVariables, newProducts)
      // If the number of variables to be shown is less than the current variables in form state...
      if (variablesToBeAsked.length < values[VARIABLES].length) {
        // Filter out the variables that are no longer applicable by matching their IDs.
        const newVariables = values[VARIABLES].filter(value => {
          return variablesToBeAsked.find(variable => variable.id === value.data.id)
        })
        // Update the form state to remove variables that are no longer needed.
        setFieldValue(VARIABLES, newVariables)
      }
    },
    [products, setFieldValue, handleSaveDataToBE, bookingVariables, values]
  )

  const handleEditProduct = useCallback(
    productId => {
      setEditProductId(productId)
    },
    [setEditProductId]
  )

  const assetColumns = useAssetColumns({
    handleSortChange,
    values,
    setFieldValue,
    setEditProductId,
    selectedCategory,
    setSelectedCategory,
    locationCategory,
    setLocationCategory,
    locationRegion,
    setLocationRegion,
    setTableSort,
    selectedProductsIds
  })

  const { hasReachData } = useReachData({ selectedProducts: products, setFieldValue, isAmendment })
  useFetchProductsTableData({ allowFetch: allowEdit })

  const productPricesColumns = useMemo(() => {
    return [
      ...insertIf(allowEdit, {
        Cell: rowData => {
          return (
            // Do not show drag button until product is selected
            <div style={{ opacity: rowData.data ? 1 : 0 }}>
              <DragableDots />
            </div>
          )
        },
        style: { maxWidth: tableColumnsSize.dragBtn, padding: 0 }
      }),
      {
        header: 'Region',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 120, maxWidth: 120, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.LOCATION_REGION,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          return (
            <LocationRegionCell
              productValues={productValues}
              locationRegion={locationRegion}
              setLocationRegion={setLocationRegion}
            />
          )
        }
      },
      {
        header: 'Location',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 140, maxWidth: 140, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.LOCATION,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          const productData = productValues.data
          const isProductLoading = productValues.isLoading

          return isProductLoading ? <Skeleton width="100%" /> : productData?.location?.name || '-'
        }
      },
      {
        header: 'Category',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 125, maxWidth: 125, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.LOCATION_CATEGORY,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          return (
            <LocationCategoryCell
              productValues={productValues}
              locationCategory={locationCategory}
              setLocationCategory={setLocationCategory}
            />
          )
        }
      },
      {
        header: 'Asset Type',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 150, maxWidth: 150, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.CATEGORY,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          return (
            <CategoryCell
              productValues={productValues}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )
        }
      },
      ...assetColumns,
      {
        header: 'Dates',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { flexWrap: 'wrap', minWidth: 200, maxWidth: 200 },
        attributes: { 'data-copy': DATA_DATES },
        sortParameter: SORT_PARAMETERS.DATES,
        onSortingChange: handleSortChange,
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <DatesCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              checkInventory={checkInventory}
              isEditMode={editProductId === productValues.id}
              isAmendment={isAmendment}
            />
          )
        }
      },
      {
        header: 'Qty.',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 50, maxWidth: 50, padding: 0 },
        attributes: { 'data-copy': DATA_QUANTITY },
        Cell: (productValues, productIndex) => {
          const productRowId = productValues.id
          const isInEditMode = editProductId === productRowId
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <QuantityCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              isEditMode={isInEditMode}
            />
          )
        }
      },
      ...insertIf(hasReachData, {
        header: 'Reach',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 60, maxWidth: 60, justifyContent: 'flex-end' },
        attributes: { 'data-copy': DATA_REACH },
        Cell: productValues => {
          const productRowId = productValues.id
          const isInEditMode = editProductId === productRowId
          const isProductLoading = productValues.isLoading
          // Reach is updated on BE based on selected periods, so we show loading state until the data is updated
          // on contract data update the useReachData hook is triggered and updates for the product data reach value
          const productIsUpdating = updatingProductIds.includes(productRowId)

          // just show loader for amendment process, as reach is calculated on BE, and we make the request to BE only when Generate Amendment is submitted
          if (isAmendProcess || isProductLoading || productIsUpdating) {
            return <Skeleton width="100%" />
          }

          // don't show the reach for the product if it's not selected or in edit mode(to avoid not correct data representation if periods or quantity is changed)
          const isProductSelected = !!productValues.data
          const showReach = isProductSelected && !isInEditMode
          return showReach ? (
            <ReachCell isInEditMode={isInEditMode} productValues={productValues} isAmendment={isAmendment} />
          ) : null
        }
      }),
      {
        header: 'Rate',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: tableColumnsSize.rate, maxWidth: tableColumnsSize.rate, justifyContent: 'flex-end' },
        attributes: { 'data-copy': DATA_RATE },
        sortParameter: SORT_PARAMETERS.RATE,
        onSortingChange: handleSortChange,
        Cell: productValues => {
          const isProductLoading = productValues.isLoading
          const isProductSelected = Boolean(productValues.data)

          if (isProductLoading) {
            return <Skeleton width="100%" />
          } else if (isProductSelected) {
            const totalPrice = getOriginalProductPrice(productValues)

            return formatCurrency(totalPrice, { min: 2, max: 2 }, { symbol: currencySymbol })
          } else {
            return ''
          }
        }
      },
      {
        header: 'Disc. %',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        attributes: { 'data-copy': DATA_DISCOUNT },
        style: { minWidth: tableColumnsSize.discount, maxWidth: tableColumnsSize.discount },
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading
          const isProductSelected = !!productValues.data

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : isProductSelected ? (
            <DiscountCell
              formik={formik}
              productValues={productValues}
              productIndex={productIndex}
              isEditMode={editProductId === productValues.id}
            />
          ) : null
        }
      },
      {
        header: 'Cost',
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: tableColumnsSize.cost, maxWidth: tableColumnsSize.cost, justifyContent: 'flex-end' },
        attributes: { 'data-copy': DATA_COST },
        sortParameter: SORT_PARAMETERS.COST,
        onSortingChange: handleSortChange,
        Cell: (productValues, productIndex) => {
          const productRowId = productValues.id
          const isProductLoading = productValues.isLoading
          const isEditMode = editProductId === productRowId

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <CostCell
              formik={formik}
              productValues={productValues}
              isEditMode={isEditMode}
              productIndex={productIndex}
              currencySymbol={currencySymbol}
            />
          )
        }
      },
      ...insertIf(allowEdit, {
        style: { minWidth: 56, maxWidth: 56, height: '100%', padding: 0, justifyContent: 'flex-end' },
        header: 'action',
        headClassName: classes.actionsHeader,
        Cell: (productValues, productIndex) => {
          const isProductLoading = productValues.isLoading

          return isProductLoading ? (
            <Skeleton width="100%" />
          ) : (
            <ActionsCell
              formik={formik}
              productIndex={productIndex}
              productValues={productValues}
              editProductId={editProductId}
              allowAutoSave={allowAutoSave}
              handleDeleteProduct={handleDeleteProduct}
              handleProductEdit={handleEditProduct}
            />
          )
        }
      })
    ]
  }, [
    allowEdit,
    classes.quotationBuilderCol,
    classes.actionsHeader,
    handleSortChange,
    assetColumns,
    hasReachData,
    locationRegion,
    locationCategory,
    selectedCategory,
    formik,
    checkInventory,
    editProductId,
    isAmendment,
    updatingProductIds,
    isAmendProcess,
    currencySymbol,
    allowAutoSave,
    handleDeleteProduct,
    handleEditProduct
  ])

  const tableCopyFormat = useMemo(() => {
    return getTableCopyFormat(hasReachData)
  }, [hasReachData])

  const hasNotSelectedProducts = products.some(product => !product.data)

  return (
    <Table
      className={classes.quotationsProductsTable}
      cols={productPricesColumns}
      data={orderedProducts}
      tableSort={tableSort}
      tableId={QUOTATION_BUILDER_TABLE_ID}
      footerClassName={classes.footer}
      onRowDrag={allowEdit ? handleProductsOrderUpdate : undefined}
      // enable copy functionality
      formatCopyCells={tableCopyFormat}
      Footer={
        <FooterSummary
          formik={formik}
          allowEdit={allowEdit}
          allowAutoSave={allowAutoSave}
          isAmendment={isAmendment}
          isAmendProcess={isAmendProcess}
          disableProductAdd={Boolean(editProductId) || hasNotSelectedProducts || isAdditionalInfoEdit}
          editProductId={editProductId}
          hasReachData={hasReachData}
          handleTotalEdit={handleEditProduct}
        />
      }
    />
  )
}

ProductsTable.propTypes = {
  formik: PropTypes.object,
  isAdditionalInfoEdit: PropTypes.bool,
  allowEdit: PropTypes.bool
}

export default ProductsTable

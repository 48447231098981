import { createStyles } from '../../../../../../styles/helpers'
import { darkGrey, mediumGrey } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  mediaOrderFieldsWrapper: {
    borderLeft: `1px solid ${mediumGrey}`,
    padding: '20px 24px',
    flexGrow: 1
  },
  editModeToggleButton: {
    position: 'absolute',
    right: 13,
    top: 13,
    fontSize: 12,
    borderBottom: `1px solid ${darkGrey}`,
    color: darkGrey
  },
  disabledEditModeToggleButton: {
    color: mediumGrey,
    borderBottom: `1px solid ${mediumGrey}`
  },

  mediaOrderFields: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16
  }
})

export default useStyles

import { call, put, all, takeEvery } from 'redux-saga/effects'

import { getFileNameFromHeaders } from '../../helpers/api'

import {
  GET_STATEMENTS,
  UPDATE_STATEMENT,
  getStatementsSuccess,
  getStatementsFailure,
  updateStatementSuccess,
  updateStatementFailure,
  GET_STATEMENTS_AS_CSV,
  getStatementsAsCsvFailure,
  getStatementsAsCsvSuccess,
  GET_CURRENT_STATEMENT,
  getCurrentStatementSuccess,
  getCurrentStatementFailure
} from '../actions/statements'
import {
  getStatementsService,
  updateStatementService,
  getStatementsAsCsvService,
  getCurrentStatementService
} from '../services/statements'

function* statementsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CURRENT_STATEMENT, getCurrentStatementWorker),
    takeEvery(GET_STATEMENTS_AS_CSV, getStatementsAsCsvWorker),
    takeEvery(UPDATE_STATEMENT, updateStatementWorker),
    takeEvery(GET_STATEMENTS, getStatementsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getCurrentStatementWorker({ id, params }) {
  try {
    const response = yield call(getCurrentStatementService, id, params)
    yield put(getCurrentStatementSuccess(response))
  } catch (error) {
    yield put(getCurrentStatementFailure(error))
  }
}

function* getStatementsAsCsvWorker({ params }) {
  try {
    const response = yield call(getStatementsAsCsvService, params)
    const { data, headers } = response
    // file name is stored in response content-disposition header
    const formattedFilename = getFileNameFromHeaders(headers)

    yield put(getStatementsAsCsvSuccess(data, formattedFilename))
  } catch (error) {
    yield put(getStatementsAsCsvFailure(error))
  }
}

function* updateStatementWorker({ id, data }) {
  try {
    const response = yield call(updateStatementService, id, data)
    yield put(updateStatementSuccess(response))
  } catch (error) {
    yield put(updateStatementFailure(error))
  }
}

function* getStatementsWorker({ params }) {
  try {
    const response = yield call(getStatementsService, params)
    yield put(getStatementsSuccess(response))
  } catch (error) {
    yield put(getStatementsFailure(error))
  }
}

export default statementsWatcher

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { NOTIFICATION_EMAILS, NOTIFICATION_USERS } from '../../../../forms/ReusableFormFields/RecipientsFields/fields'
import { mediaOrderSelector } from '../../../../modules/selectors/mediaOrders'

export function useMediaOrderRecipients() {
  const mediaOrder = useSelector(mediaOrderSelector)

  const { [NOTIFICATION_USERS]: initialNotificationUsers = [], [NOTIFICATION_EMAILS]: initialNotificationEmails = [] } =
    mediaOrder

  return useMemo(
    () => ({
      hasRecipients: Boolean(initialNotificationUsers?.length || initialNotificationEmails?.length),
      initialNotificationUsers,
      initialNotificationEmails
    }),
    [initialNotificationEmails, initialNotificationUsers]
  )
}

import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DownloadFiles from './DownloadFiles'
import BookedMediaFile from './BookedMediaFile'
import UploadBookedMediaFiles from '../../../../forms/Multiplatform/MediaOrderForms/UploadBookedMediaFiles'
import MinimisedSection from '../../../../components/MinimisedSection'
import RequestedFilesTable from './RequestedFilesTable'
import TableDataLoader from '../../../../components/Table/TableDataLoader'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../../features/components/ContentSection'
import { DesktopUp, DesktopDown } from '../../../../components/hoc/ResponsiveRendering'
import AddCommentsToUploadedFiles from '../../../../forms/Multiplatform/MediaOrderForms/AddCommentsToUploadedFiles'

import {
  areAllFilesUploaded,
  getMappedFiles,
  getFormattedMediaDate,
  getRowIdByMediaIdAndRequiredFileId
} from './helpers'
import { clearGetOrderBookedMedia, getOrderBookedMedia } from '../../../../modules/actions/mediaOrdersBookings'

import {
  bookedMediaSelector,
  bookedMediaIsLoadingSelector,
  bookedMediaErrorSelector,
  bookedMediaWasLoadedSelector
} from '../../../../modules/selectors/mediaOrdersBookings'

import useSidebarPageStyles from '../../../../styles/common/sidebarPage'
import useStyles from './styles'
import useCommonStyles from '../../../../styles/common/listPage'

const RequestedFilesSection = ({ mediaOrderId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sidebarPageClasses = useSidebarPageStyles()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const bookedMedia = useSelector(bookedMediaSelector)

  const bookedMediaWithFiles = useMemo(() => {
    return bookedMedia?.filter(
      // media could have no files requirements but have already uploaded files
      media => !!media.uploaded_files?.length || !!media.file_requirements?.length
    )
  }, [bookedMedia])

  const groupedMediaByProduct = Object.values(
    bookedMedia.reduce((acc, media) => {
      const hasFilesRequirements = !!media.file_requirements?.length
      // media could have no files requirements but have files
      const hasFiles = !!media.uploaded_files?.length

      if (hasFiles || hasFilesRequirements) {
        // show only media with files requirements or uploaded files
        acc[media.product] = [
          ...(acc[media.product] || []),
          {
            ...media, // shallow copy of media
            file_requirements: media.file_requirements?.map(file => ({ ...file })), // deep copy of
            // file_requirements to avoid data mutation
            uploaded_files: media.uploaded_files?.map(file => ({ ...file })), // deep copy of uploaded_files to
            // avoid data mutation
            files: getMappedFiles(media)
          }
        ]
      }

      return acc
    }, {})
  )
  const allFilesUploaded = areAllFilesUploaded(bookedMediaWithFiles)

  const getProductFiles = useCallback(productBookedMedias => {
    return productBookedMedias
      ?.map(media => {
        return media.files.map(file => {
          return {
            ...file,
            mediaId: media.id,
            deadline: media.deadline_date,
            dateStart: media.date_start,
            dateEnd: media.date_end,
            requirementFileId: file?.required?.id,
            uploadedFileId: file?.uploaded?.id,
            id: getRowIdByMediaIdAndRequiredFileId(media?.id, file?.required?.id || file?.uploaded?.id)
          }
        })
      })
      .flat(1)
  }, [])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no files')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  useEffect(() => {
    dispatch(
      getOrderBookedMedia({
        media_order: mediaOrderId,
        sequence_first_item: true
      })
    )

    return () => {
      dispatch(clearGetOrderBookedMedia())
    }
  }, [dispatch, mediaOrderId])

  return (
    <>
      <DesktopUp>
        <ContentSection title={t('Requested files')}>
          <TableDataLoader
            itemsLength={groupedMediaByProduct?.length}
            errorSelector={bookedMediaErrorSelector}
            wasLoadedSelector={bookedMediaWasLoadedSelector}
            isLoadingSelector={bookedMediaIsLoadingSelector}
            noDataContent={noDataContent}
          >
            {groupedMediaByProduct?.map((productBookedMedias, index) => {
              const productName = productBookedMedias[0]?.original_product_name
              const productBookedMedia = productBookedMedias[0]?.id
              const files = getProductFiles(productBookedMedias)
              const uploadedFiles = files.filter(file => !!file.uploaded)

              return (
                <div className={classes.product} key={index}>
                  <ContentRow
                    title={productName}
                    description={t('Please upload the requested files')}
                    leftColumnChildren={
                      uploadedFiles.length > 1 ? (
                        <DownloadFiles bookedMediaId={productBookedMedia} productName={productName} />
                      ) : null
                    }
                  >
                    <RequestedFilesTable files={files} />
                  </ContentRow>
                </div>
              )
            })}
          </TableDataLoader>
          <UploadBookedMediaFiles />
        </ContentSection>
      </DesktopUp>
      <DesktopDown>
        <MinimisedSection
          headerContent={
            <div>
              <h3 className={sidebarPageClasses.sectionHeader}>Requested files</h3>
            </div>
          }
          className={classes.filesSection}
          toggleWrapperClassName={classes.filesSectionToggle}
          // if there are required files then opened section
          // if there are required files but all of them where loaded - closed section by default
          defaultOpened={!allFilesUploaded}
        >
          <>
            {groupedMediaByProduct?.map((productBookedMedias, index) => {
              const productName = productBookedMedias[0]?.original_product_name

              return (
                <div className={classes.product} key={index}>
                  <h4 className={classes.productHeadline}>{productName}</h4>
                  <div className={classes.productBookedMedia}>
                    {productBookedMedias.map(media => (
                      <div key={media.id}>
                        <h4 className={classes.mediaTitle}>{getFormattedMediaDate(media.date_start, media.period)}</h4>
                        <div className={classes.filesCardsGrid}>
                          {media.files.map((file, index) => {
                            return (
                              <BookedMediaFile
                                file={file}
                                deadline={media.deadline_date}
                                mediaId={media.id}
                                key={index}
                              />
                            )
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
            <UploadBookedMediaFiles />
          </>
        </MinimisedSection>
      </DesktopDown>
      <AddCommentsToUploadedFiles />
    </>
  )
}

export default RequestedFilesSection

import {
  ATTRIBUTE,
  BOOKING_EMAIL_TEXT,
  CAN_SELECT_MULTIPLE,
  FILE_NAME,
  FILE_URL,
  MEDIA_PRODUCT_ATTRIBUTE,
  NAME,
  OPTION_NAME,
  OPTIONS,
  REQUIRED,
  VALUES
} from '../../fields'

export const transformValuesToBE = ({ values, initialOptions }) => {
  const currentOptions = Object.values(values[OPTIONS]).filter(item => !!item)

  // if option is new - we need to create it
  // if option is updated - we need to create new one and delete the old one
  // if option is deleted - we need to delete it

  const createOptions = currentOptions
    .filter(currentOption => {
      const correspondingInitialOption = initialOptions.find(initialOption => initialOption.id === currentOption.id)

      const isNameUpdated = correspondingInitialOption?.[OPTION_NAME] !== currentOption?.[OPTION_NAME]

      return !correspondingInitialOption || isNameUpdated
    })
    .map(item => {
      return {
        [OPTION_NAME]: item[OPTION_NAME],
        [FILE_URL]: item[FILE_URL],
        [FILE_NAME]: item[FILE_NAME]
      }
    })

  const deleteOptionsIds = initialOptions
    .filter(initialOption => {
      const correspondingCurrentOption = currentOptions.find(currentOption => currentOption.id === initialOption.id)

      const isNameUpdated = correspondingCurrentOption?.[OPTION_NAME] !== initialOption?.[OPTION_NAME]

      return !correspondingCurrentOption || isNameUpdated
    })
    .map(item => item.id)

  return {
    [NAME]: values[NAME],
    [MEDIA_PRODUCT_ATTRIBUTE]: {
      [ATTRIBUTE]: values[MEDIA_PRODUCT_ATTRIBUTE][ATTRIBUTE],
      [VALUES]: values[MEDIA_PRODUCT_ATTRIBUTE][VALUES].map(item => item.value)
    },
    [REQUIRED]: values[REQUIRED],
    [CAN_SELECT_MULTIPLE]: values[CAN_SELECT_MULTIPLE],
    [BOOKING_EMAIL_TEXT]: values[BOOKING_EMAIL_TEXT],
    [OPTIONS]: {
      create: createOptions,
      delete: deleteOptionsIds
    }
  }
}

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import DatePickerPeriodSelector from './DatePickerPeriodSelector'
import CustomDatePickerPeriodSelector from './CustomDatePeriodSelector'

import { ContractBuilderContext } from '../../../../ContractBuilderContext'
import { SelectPeriodsContextProvider } from '../../../SelectPeriodsContext'

import { selectedControllerDataSelector } from '../../../../../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS, PRODUCT_PERIODS_DATES } from '../../../../../fields'

const EditPeriods = ({ formik, productValues, productIndex, checkInventory, isAmendment }) => {
  const { contractId } = useContext(ContractBuilderContext)
  const selectedController = useSelector(selectedControllerDataSelector)
  const supportCustomDates = selectedController?.custom_booking_dates
  const allowPastPeriods = selectedController?.allow_create_past_proposals

  const productData = productValues.data
  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  const periodsPath = `${productPath}.${PRODUCT_PERIODS_DATES}`

  const mediaOrderProductId = productData?.mediaOrderProductId

  return (
    <SelectPeriodsContextProvider
      contractId={contractId}
      productData={productValues.data}
      // allow to fetch periods in the past for amendments or proposals with controller setting to approve
      allowToFetchPreviousPeriods={isAmendment || allowPastPeriods}
      handleCompetingQuotations={!isAmendment}
      // mediaOrderProductId used only for Amendments to fulfill the inevntory with booked products whihin the order
      mediaOrderProductId={isAmendment ? mediaOrderProductId : null}
    >
      <div>
        {supportCustomDates ? (
          <CustomDatePickerPeriodSelector
            formik={formik}
            isNewProduct={productValues.isNewProduct}
            selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
            productItemPath={productPath}
            productPublicationsDatesPath={periodsPath}
            checkInventory={checkInventory}
          />
        ) : (
          <DatePickerPeriodSelector
            formik={formik}
            isNewProduct={productValues.isNewProduct}
            selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
            productItemPath={productPath}
            productPublicationsDatesPath={periodsPath}
            checkInventory={checkInventory}
          />
        )}
      </div>
    </SelectPeriodsContextProvider>
  )
}

EditPeriods.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default EditPeriods

import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'
import { createJsonFromQueryString } from '../../../../../helpers/url'

import { getControllerMembersService } from '../../../../../modules/services/controller'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

export const MULTIPLE_CONTROLLER_MEMBERS_FILTER = 'multipleControllerMembersFilter'

const MultipleControllerMembersFilter = ({ Context, placeholder = 'Owner' }) => {
  const controllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: multipleControllerMembersFilter } = filtersState

  const setMultipleControllerMembersFilter = useCallback(
    data => {
      setFiltersState({
        ...filtersState,
        [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: data
      })
    },
    [filtersState, setFiltersState]
  )

  const formatControllerMembers = useCallback(controllerMembers => {
    return formatOptionsList({
      list: controllerMembers,
      valueName: 'id',
      labelName: 'full_name'
    })
  }, [])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getControllerMembersService({
        ...(search && { search }),
        controller: controllerId,
        limit: 100,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newControllerMembersOptions = formatControllerMembers(response.results)

      return {
        options: newControllerMembersOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [controllerId, formatControllerMembers]
  )

  const onFilterChangeHandler = useCallback(
    data => {
      setMultipleControllerMembersFilter(data)
    },
    [setMultipleControllerMembersFilter]
  )

  const setControllerMembersHandler = useCallback(
    (name, data) => {
      onFilterChangeHandler(data)
    },
    [onFilterChangeHandler]
  )

  return (
    <MultiSelectBox
      name="Owner"
      loadOptions={loadOptions}
      value={multipleControllerMembersFilter}
      onChange={onFilterChangeHandler}
      placeholder={placeholder}
      setFieldValue={setControllerMembersHandler}
      portaled
    />
  )
}

MultipleControllerMembersFilter.propTypes = {
  Context: PropTypes.object,
  placeholder: PropTypes.string
}

export default MultipleControllerMembersFilter

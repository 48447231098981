import { useMediaQuery } from 'react-responsive'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ControllerInvitesTableData from './ControllerInvitesTableData'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'

import {
  controllerInvitesErrorSelector,
  controllerInvitesIsLoadingSelector,
  controllerInvitesSelector,
  controllerInvitesWasLoadedSelector
} from '../../../../../modules/selectors/controller'

import { mobileOneLineColumnsSchema } from '../../../../../constants/other'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'
import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  status: 140,
  role: 124,
  actions: 40
}

const ControllerInvitesTable = ({ onResendInvite, onDeleteInvite, loadMore }) => {
  const controllerInvites = useSelector(controllerInvitesSelector)

  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const membersColumnsSchema = useMemo(
    () => [
      {
        header: 'Name'
      },
      {
        header: 'Status',
        style: { maxWidth: tableColumnsSize.status }
      },
      {
        header: 'Role',
        style: { maxWidth: tableColumnsSize.role }
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no invites')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      loadMore={loadMore}
      itemsLength={controllerInvites.length}
      errorSelector={controllerInvitesErrorSelector}
      wasLoadedSelector={controllerInvitesWasLoadedSelector}
      isLoadingSelector={controllerInvitesIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileOneLineColumnsSchema : membersColumnsSchema }}
      noDataContent={noDataContent}
    >
      <ControllerInvitesTableData onResendInvite={onResendInvite} onDeleteInvite={onDeleteInvite} />
    </TableDataLoader>
  )
}

ControllerInvitesTable.propTypes = {
  loadMore: PropTypes.func,
  onResendInvite: PropTypes.func.isRequired,
  onDeleteInvite: PropTypes.func.isRequired
}

export default ControllerInvitesTable

import { useEffect } from 'react'
import { formatInventoryToFormikValues } from './fields'
import { useSelector } from 'react-redux'

import {
  lastFetchedPortionOfInventorySelector,
  lastFetchedPortionOfInventoryWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'

import {
  INVENTORY_LIST_DATA,
  INVENTORY_START_DATE
} from '../../../MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export const useUpdateInventoryListDataWithNewPortionOfInventory = ({
  formik,
  isMoreInventoryItemsLoading,
  setIsMoreInventoryItemsLoading
}) => {
  const lastFetchedPortionOfInventory = useSelector(lastFetchedPortionOfInventorySelector)
  const lastFetchedPortionOfInventoryWasLoaded = useSelector(lastFetchedPortionOfInventoryWasLoadedSelector)

  const { values, setFieldValue } = formik

  const inventoryListData = values[INVENTORY_LIST_DATA]

  useEffect(() => {
    const newPortionOfInventoryWasAddedToFormikValues = lastFetchedPortionOfInventory?.every(item => {
      return inventoryListData.find(listItem => listItem?.id === item.id)
    })

    if (
      lastFetchedPortionOfInventoryWasLoaded &&
      // newPortionOfInventoryWasAddedToFormikValues variable helps to prevent useEffect triggering when new portion of was added to formik values
      !newPortionOfInventoryWasAddedToFormikValues &&
      // isMoreInventoryItemsLoading helps to prevent useEffect triggering when inventoryListData item is removed
      isMoreInventoryItemsLoading
    ) {
      const formattedNewPortionOfInventory = formatInventoryToFormikValues(lastFetchedPortionOfInventory)
      // We filter out all null values from array, (when we delete item - we don't delete it right away, but we set it to null)
      const validInventoryItems = inventoryListData.filter(item => !!item)

      // concat previous inventory list with new portion of inventory
      const updatedInventory = [...validInventoryItems, ...formattedNewPortionOfInventory]

      // Convert dates to timestamps once before sorting
      const updatedInventoryWithTimestamps = updatedInventory.map(item => ({
        ...item,
        timestamp: new Date(item[INVENTORY_START_DATE]).getTime()
      }))

      // Sort using the timestamps
      // we do sorting to keep newly added items at the end of the list
      const sortedUpdatedInventory = updatedInventoryWithTimestamps
        .sort((a, b) => {
          return a.timestamp - b.timestamp
        })
        .map(item => {
          const { timestamp, ...rest } = item // Remove the temporary timestamp property
          return rest
        })

      setIsMoreInventoryItemsLoading(false)
      setFieldValue(INVENTORY_LIST_DATA, sortedUpdatedInventory)
    }
  }, [
    lastFetchedPortionOfInventoryWasLoaded,
    lastFetchedPortionOfInventory,
    setFieldValue,
    inventoryListData,
    isMoreInventoryItemsLoading,
    setIsMoreInventoryItemsLoading
  ])
}

import {
  EMAIL,
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from './fields'
import * as Yup from 'yup'

export const notificationUsersValidation = {
  [NOTIFICATION_USERS]: Yup.array().when(USER_OPTION, {
    is: SEND_TO_PLATFORM_USER,
    then: () => Yup.array().min(1, 'Please select at least one user')
  })
}
export const notificationValidation = {
  ...notificationUsersValidation,
  [NOTIFICATION_EMAILS]: Yup.array().when(USER_OPTION, {
    is: SEND_BY_EMAIL,
    then: () =>
      Yup.array()
        .of(
          Yup.object().shape({
            [EMAIL]: Yup.string().email('Invalid email').required('Please enter email'),
            [FIRST_NAME]: Yup.string().required('Please enter first name')
          })
        )
        .min(1, 'Please add at least one email')
  })
}

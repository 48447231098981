import { createStyles } from '../../../styles/helpers'

import { zIndex8 } from '../../../styles/const/common'

export default createStyles(theme => ({
  tooltipBody: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    border: ({ borderColor, borderWidth }) => `${borderWidth}px solid ${borderColor}`,
    borderRadius: theme.defaultBorderRadius,
    cursor: 'auto',
    zIndex: zIndex8,
    boxShadow: 'none',
    color: ({ color }) => color
  }
}))

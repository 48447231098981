import React, { useCallback } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import SingleFileUpload from '../../../../../../../../forms/ReusableFormFields/SingleFileUpload'

import { MEDIA_HEIGHT, MEDIA_RATIO, MEDIA_WIDTH } from '../../../../../../../../forms/Facebook/AdForms/fields'

const BrandLogoUpload = ({ formik, logoUrlPath, logoFileNamePath, logoValidationSchema }) => {
  const { values, setFieldValue, touched, errors } = formik

  const logoUrl = getIn(values, logoUrlPath)
  const logoName = getIn(values, logoFileNamePath)

  const fileTouched = getIn(touched, logoUrlPath)
  const fileError = getIn(errors, logoUrlPath)
  const fileMissingError = fileTouched && fileError

  const fileUploadHandler = useCallback(
    uploadedFile => {
      setFieldValue(logoUrlPath, uploadedFile.url)
      setFieldValue(logoFileNamePath, uploadedFile.name)
    },
    [setFieldValue, logoUrlPath, logoFileNamePath]
  )

  const fileRemoveHandler = useCallback(() => {
    setFieldValue(logoUrlPath, '')
    setFieldValue(logoFileNamePath, '')
  }, [setFieldValue, logoUrlPath, logoFileNamePath])

  const logoValidationHandler = useCallback(
    file => {
      return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file)

        const image = new Image()
        image.src = url

        image.onload = () => {
          // Clean up the blob URL after the image has loaded
          URL.revokeObjectURL(url)

          resolve({
            [MEDIA_WIDTH]: image.width,
            [MEDIA_HEIGHT]: image.height,
            [MEDIA_RATIO]: image.width / image.height
          })
        }

        image.onerror = error => {
          // Clean up on error as well
          URL.revokeObjectURL(url)
          reject(error)
        }
      }).then(values => logoValidationSchema.validate(values))
    },
    [logoValidationSchema]
  )

  return (
    <SingleFileUpload
      fileName={logoName}
      fileURL={logoUrl}
      onFileUploaded={fileUploadHandler}
      onFileRemove={fileRemoveHandler}
      fileValidationHandler={logoValidationHandler}
      error={fileMissingError}
      accept="image/png,image/jpeg"
      isImagePreview
    />
  )
}

BrandLogoUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  logoUrlPath: PropTypes.string.isRequired,
  logoFileNamePath: PropTypes.string.isRequired
}

export default BrandLogoUpload

import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import BookingVariableEditForm from './BookingVariableEditForm'

import useManageEditFormData from '../../../../../../hooks/formHooks/useManageEditFormData'

import {
  clearBookingVariableOptions,
  clearGetBookingVariable,
  getBookingVariable,
  getBookingVariableOptions
} from '../../../../../../modules/actions/mediaOrdersBookings'
import {
  bookingVariableOptionsWasLoadedSelector,
  bookingVariableSelector,
  bookingVariablesSelector,
  bookingVariableWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'

import { BOOKING_VARIABLE_EDIT } from '../../../../../../constants/forms'

const BookingVariableEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bookingVariables = useSelector(bookingVariablesSelector)
  const bookingVariable = useSelector(bookingVariableSelector)
  const bookingVariableWasLoaded = useSelector(bookingVariableWasLoadedSelector)
  const bookingVariableOptionsWasLoaded = useSelector(bookingVariableOptionsWasLoadedSelector)

  const getBookingVariableDataHandler = useCallback(
    selectedEditItemId => {
      dispatch(getBookingVariable({ id: selectedEditItemId }))
      dispatch(
        getBookingVariableOptions({
          media_product_variable_ids: [selectedEditItemId],
          limit: 20
        })
      )
    },
    [dispatch]
  )

  const clearBookingVariableDataHandler = useCallback(() => {
    dispatch(clearGetBookingVariable())
    dispatch(clearBookingVariableOptions())
  }, [dispatch])

  const selectedEditItemId = useManageEditFormData({
    formName: BOOKING_VARIABLE_EDIT,
    getDataHandler: getBookingVariableDataHandler,
    clearDataHandler: clearBookingVariableDataHandler,
    loadedDataId: bookingVariable.id
  })

  const title = useMemo(() => {
    const bookingVariableFromList = bookingVariables.find(({ id }) => id === selectedEditItemId)
    const bookingVariableName = bookingVariableFromList?.name || bookingVariable.name

    if (bookingVariableName) {
      return t('editBookingVariable', { bookingVariableName })
    } else {
      // Name may be not available when:
      // - details are not loaded yet
      // - and item is not present in the list (user opened edit form directly by URL)
      return t('Edit booking variable')
    }
  }, [t, bookingVariables, selectedEditItemId, bookingVariable.name])

  return (
    <FormDrawerWrapper
      formName={BOOKING_VARIABLE_EDIT}
      isFormLoading={!bookingVariableWasLoaded || !bookingVariableOptionsWasLoaded}
      title={title}
      showOpenButton={false}
    >
      <BookingVariableEditForm />
    </FormDrawerWrapper>
  )
}

export default BookingVariableEdit

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ProductSelection from '../ProductSelection'

import { SORT_PARAMETERS } from '../constants'
import { defaultTableSort } from '../../ContractBuilderContext'

import { getInitialProductSetup, MEDIA_PRODUCTS } from '../../../fields'

import useStyles from '../styles'

export const useAssetColumns = ({
  handleSortChange,
  values,
  setFieldValue,
  setEditProductId,
  setSelectedCategory,
  setLocationCategory,
  setLocationRegion,
  setTableSort,
  selectedCategory,
  locationCategory,
  locationRegion
}) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const products = values[MEDIA_PRODUCTS]

  const selectedProductsIds = useMemo(() => {
    return products.map(product => product.data?.id)
  }, [products])

  const handleAddProductError = useCallback(
    productIndex => {
      // here the product has failed so we need to stop loading
      // don't add full products array to dependencies as it provokes infinite loop
      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
      const isLoadingPath = `${productPath}.isLoading`

      setFieldValue(isLoadingPath, false)
    },
    [setFieldValue]
  )

  const handleSelectProduct = useCallback(
    (productName, productIndex) => {
      // first we select product and start fetching it data
      const productValues = products[productIndex]

      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`

      setFieldValue(productPath, {
        ...productValues,
        isLoading: true,
        name: productName
      })
    },
    [products, setFieldValue]
  )

  const handleProductDataAdd = useCallback(
    (productData, productIndex) => {
      // here the product is fetching and we add it data
      const productValues = products[productIndex]

      const newProductData = {
        ...productData,
        value: productData.id,
        label: productData.internal_id ? `${productData.internal_id} - ${productData.name}` : productData.name
      }
      const productFormattedData = getInitialProductSetup(newProductData)

      const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`

      setFieldValue(productPath, {
        ...productValues,
        isLoading: false,
        isNewProduct: true,
        ...productFormattedData
      })
      // set edit more for new selected product
      setEditProductId(productValues.id)
      setSelectedCategory(null) // reset selected category on product selection
      setLocationCategory(null)
      setLocationRegion(null)
      setTableSort(defaultTableSort) // reset sorting
    },
    [
      products,
      setFieldValue,
      setEditProductId,
      setSelectedCategory,
      setLocationCategory,
      setLocationRegion,
      setTableSort
    ]
  )

  return useMemo(() => {
    return [
      {
        header: () => {
          return (
            <div>
              <div>{t('Asset ID')}</div>
              <div>{t('Asset')}</div>
            </div>
          )
        },
        headClassName: classes.quotationBuilderCol,
        className: classes.quotationBuilderCol,
        style: { minWidth: 215, whiteSpace: 'normal' },
        sortParameter: SORT_PARAMETERS.ASSET,
        onSortingChange: handleSortChange,
        Cell: (product, index) => {
          return product.data ? (
            <div>
              {product.data.internal_id && <div className={classes.assetId}>{product.data.internal_id}</div>}
              <div className={classes.name}>{product.data.name}</div>
            </div>
          ) : (
            <ProductSelection
              productIndex={index}
              onAddProductError={handleAddProductError}
              onSelectProduct={handleSelectProduct}
              onAddProduct={handleProductDataAdd}
              selectedSubCategory={selectedCategory}
              locationCategory={locationCategory}
              locationRegion={locationRegion}
              selectedProductsIds={selectedProductsIds}
            />
          )
        }
      }
    ]
  }, [
    t,
    classes.name,
    classes.assetId,
    classes.quotationBuilderCol,
    handleAddProductError,
    handleProductDataAdd,
    handleSelectProduct,
    handleSortChange,
    locationCategory,
    locationRegion,
    selectedCategory,
    selectedProductsIds
  ])
}

import React, { useCallback } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import SelfAccountMembersPaginatedSelect from '../../../../../../../forms/ReusableFormFields/RecipientsFields/SelfAccountMembersPaginatedSelect'
import MinimizedListField from '../../../../../../../features/components/Form/MinimizedListField'
import RecipientEmailSection from '../../../../../../../forms/ReusableFormFields/RecipientsFields/RecipientEmailSection'

import { DO_NOT_SEND } from '../fields'
import {
  NOTIFICATION_EMAILS,
  initialNotificationEmailSection,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION,
  EMAIL
} from '../../../../../../../forms/ReusableFormFields/RecipientsFields/fields'

const ProposalMediaOrderRecipientsFormContent = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const handleEmailTitleGenerate = useCallback((itemValue, index) => {
    const email = getIn(itemValue, EMAIL)
    return email || `Recipient ${index + 1}`
  }, [])

  return (
    <>
      <RadioBlock
        id="platform_user_radio_button"
        name={USER_OPTION}
        value={SEND_TO_PLATFORM_USER}
        selectedValue={values[USER_OPTION]}
        label={t('Select platform user(s)')}
        setFieldValue={setFieldValue}
      >
        <SelfAccountMembersPaginatedSelect formik={formik} isMulti={true} />
      </RadioBlock>
      <RadioBlock
        id="send_by_email_radio_button"
        name={USER_OPTION}
        value={SEND_BY_EMAIL}
        selectedValue={values[USER_OPTION]}
        label={t('Send by email')}
        setFieldValue={setFieldValue}
      >
        <MinimizedListField
          formik={formik}
          fieldPath={NOTIFICATION_EMAILS}
          initialItemIdKey="id"
          initialListValue={initialNotificationEmailSection}
          minCount={1}
          firstItemText={t('+ Add recipient')}
          nextItemText={t('+ Add another recipient')}
          handleTitleGenerate={handleEmailTitleGenerate}
        >
          <RecipientEmailSection formik={formik} />
        </MinimizedListField>
      </RadioBlock>
      <RadioBlock
        id="do_not_send_radio_button"
        name={USER_OPTION}
        value={DO_NOT_SEND}
        selectedValue={values[USER_OPTION]}
        label={t('Do not send / send later')}
        setFieldValue={setFieldValue}
      />
    </>
  )
}

export default ProposalMediaOrderRecipientsFormContent

import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { determineDiscountsFromProducts } from './helpers'

import { clearContractBuilderData, setContractBuilderData } from '../../../../../../../modules/actions/contracts'
import { calculateDiscountCost } from '../../../../../../../features/components/Contract/helpers/isDiscountsValid'

export const DiscountsContext = React.createContext()

export function DiscountsProvider({ children, allSelectedProducts, provideToRedux }) {
  const dispatch = useDispatch()
  const productsDiscount = useMemo(() => determineDiscountsFromProducts(allSelectedProducts), [allSelectedProducts])

  useEffect(() => {
    if (provideToRedux) {
      // provide discounts to redux store
      dispatch(
        setContractBuilderData({
          calculatedDiscounts: productsDiscount,
          calculatedDiscountCost: calculateDiscountCost(allSelectedProducts, productsDiscount)
        })
      )

      return () => {
        dispatch(clearContractBuilderData())
      }
    }
  }, [dispatch, productsDiscount, allSelectedProducts, provideToRedux])

  return <DiscountsContext.Provider value={{ productsDiscount }}>{children}</DiscountsContext.Provider>
}

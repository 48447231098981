import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Field from '../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../features/formatters'
import { getLoadOptionsHandler } from '../../../../../../features/helpers/componentsHelpers'

import { getControllerMembersService } from '../../../../../../modules/services/controller'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { BUYER } from '../../fields'

import useStyles from './styles'

const defaultOptions = []
const ControllerMembersPaginatedSelect = ({ formik, limit = 100 }) => {
  const classes = useStyles()

  const controllerId = useSelector(selectedControllerIdSelector)

  const formatControllerMembers = useCallback(controllerMembers => {
    return formatOptionsList({
      list: controllerMembers,
      valueName: 'user',
      labelName: 'full_name'
    })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getControllerMembersService,
      params: {
        ordering: 'name',
        controller: controllerId
      },
      formatOptions: formatControllerMembers,
      limit
    })
  }, [controllerId, formatControllerMembers, limit])

  return (
    <Field
      className={classes.select}
      formik={formik}
      name={BUYER}
      placeholder="Select buyer"
      loadOptions={loadOptions}
      options={defaultOptions}
      expandMenu={true}
      setFullOptionObject={true}
    />
  )
}

export default ControllerMembersPaginatedSelect

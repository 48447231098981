import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Field from '../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../features/formatters'

import { mediaProductLocationCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProductLocations'

import { MEDIA_PRODUCT_LOCATION_CATEGORY } from '../../fields'

const LocationCategorySelect = ({ formik }) => {
  const mediaProductLocationCategories = useSelector(mediaProductLocationCategoriesSelector)

  const { values, setFieldValue } = formik

  const formattedCategories = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationCategories])

  const clearLocationCategoryHandler = useCallback(() => {
    setFieldValue(MEDIA_PRODUCT_LOCATION_CATEGORY, null)
  }, [setFieldValue])

  return (
    <Field
      name={MEDIA_PRODUCT_LOCATION_CATEGORY}
      formik={formik}
      placeholder="Category"
      options={formattedCategories}
      portaled={true}
      showClearInputIcon={!!values[MEDIA_PRODUCT_LOCATION_CATEGORY]}
      onClearInput={clearLocationCategoryHandler}
    />
  )
}

export default LocationCategorySelect

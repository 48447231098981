import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { TABLE_COL_CLASSNAME, TABLE_ROW_CLASSNAME } from '../../../../constants/pdf'

import useStyles from '../../styles'
import useColumnStyles from '../TableRowWrapper/Col/styles'

// SkeletonTable is rendering the Skeleton with same quantity of columns as real row component
// also each column have similar width to the real one
const SkeletonTableRow = React.memo(({ cols = [{}, {}, {}], isBigRow }) => {
  const classes = useStyles({ isBigRow })
  const columnClasses = useColumnStyles()

  return (
    <div className={classes[TABLE_ROW_CLASSNAME]}>
      {cols.map((colData, index) => (
        <div
          key={index}
          className={classnames(columnClasses[TABLE_COL_CLASSNAME], colData.className)}
          style={colData.style}
        >
          <Skeleton width="100%" />
        </div>
      ))}
    </div>
  )
})

SkeletonTableRow.propTypes = {
  cols: PropTypes.array,
  isBigRow: PropTypes.bool
}

export default SkeletonTableRow

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import CancelMediaOrder from './CancelMediaOrder'
import Button from '../../../../../../components/Button'
import Can from '../../../../../../features/components/Can'
import GenerateCampaignReportModal from './GenerateCampaignReportModal'
import UpdateMediaOrderRecipients from '../UpdateMediaOrderRecipients'

import { mediaOrderSelector } from '../../../../../../modules/selectors/mediaOrders'
import { createAmendmentIsLoadingSelector } from '../../../../../../modules/selectors/amendments'

import { AMENDMENT_PERMISSION, MEDIA_ORDER_RECIPIENTS_PERMISSIONS } from '../../../../../../constants/permissions'

import useStyles from './styles'
import { tabletDownSize } from '../../../../../../styles/const/breakpoints'

const ContractActions = ({ handleStopAmendmentProcess }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false)

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const mediaOrder = useSelector(mediaOrderSelector)
  const createAmendmentIsLoading = useSelector(createAmendmentIsLoadingSelector)

  const allowGenerateReachReport = mediaOrder?.total_actual_reach > 0
  const mediaOrderId = mediaOrder.id

  return (
    <div className={classes.mediaOrderActions}>
      <Can I="delete" a={AMENDMENT_PERMISSION}>
        <CancelMediaOrder mediaOrderId={mediaOrderId} isDisabled={createAmendmentIsLoading} />
      </Can>
      {allowGenerateReachReport && (
        <>
          <Button
            type="button"
            onClick={() => setShowGenerateReportModal(true)}
            isSmall={!isTablet}
            solid={true}
            disabled={createAmendmentIsLoading}
          >
            {t('Post Campaign Report')}
          </Button>
          <GenerateCampaignReportModal
            isOpen={showGenerateReportModal}
            onClose={() => setShowGenerateReportModal(false)}
            mediaOrderId={mediaOrderId}
          />
        </>
      )}
      <Can I="manage" a={MEDIA_ORDER_RECIPIENTS_PERMISSIONS}>
        <UpdateMediaOrderRecipients />
      </Can>

      <Can I="create" a={AMENDMENT_PERMISSION}>
        <Button
          type="button"
          onClick={handleStopAmendmentProcess}
          isSmall={!isTablet}
          solid={true}
          disabled={createAmendmentIsLoading}
        >
          {t('Amend Booking')}
        </Button>
      </Can>
    </div>
  )
}

ContractActions.propTypes = {
  handleStopAmendmentProcess: PropTypes.func
}

export default ContractActions

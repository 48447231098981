import * as Yup from 'yup'
import { NAME, BUDGET_DAILY, TARGET_ROAS, BUDGET_LIFETIME } from '../../../../ReusableFormFields/CampaignForms/fields'
import { getDateFromTomorrowByDays } from '../../../../../helpers/date'
import { OPTION_CUSTOM } from '../../../../../constants/forms'
import nameValidation from '../../../../../features/validations/name'
import { CAMPAIGN_GOOGLE_DISPLAY_TYPE, CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE } from '../../../../../constants/campaigns'

// GENERAL
export const CAMPAIGN_TYPE = 'advertising_channel_type'
export const DISPLAY_TYPE = CAMPAIGN_GOOGLE_DISPLAY_TYPE.toString()
export const PERFORMANCE_MAX_TYPE = CAMPAIGN_GOOGLE_PERFORMANCE_MAX_TYPE.toString()

// START TIME
export const START_OPTION = 'start_option'
export const START_OPTION_NOW = 'start_option_now'
export const START_DATE = 'date_start'

// STOP TIME
export const STOP_OPTION = 'stop_option'
export const STOP_OPTION_ONE_WEEK = 'stop_option_one_week'
export const STOP_OPTION_ONE_MONTH = 'stop_option_one_month'
export const STOP_OPTION_INDEFINITELY = 'stop_option_indefinitely'
export const STOP_DATE = 'date_stop'

export const campaignTypeOptions = [
  { label: 'Performance Max', value: PERFORMANCE_MAX_TYPE },
  { label: 'Display', value: DISPLAY_TYPE }
]

//ROAS TARGET
export const TARGET_ROAS_OPTION = 'target_roas_option'
export const SET_TARGET_ROAS = 'set_target_roas'
export const OPTIMISE_TARGET_ROAS = 'optimise_target_roas'

// STATUS
export const STATUS = 'status'

// STATUS OPTIONS
export const STATUS_PAUSED = 'paused'
export const STATUS_ACTIVE = 'active'

export const budgetOptions = [
  {
    label: 'Lifetime',
    isDisabled: true,
    value: BUDGET_LIFETIME
  },
  {
    label: 'Daily',
    value: BUDGET_DAILY
  }
]

export const initialValues = {
  [CAMPAIGN_TYPE]: PERFORMANCE_MAX_TYPE,
  [NAME]: '',
  [BUDGET_DAILY]: '',
  [STATUS]: STATUS_ACTIVE,
  // TARGET ROAS
  [TARGET_ROAS_OPTION]: SET_TARGET_ROAS,
  [TARGET_ROAS]: '',
  // START TIME
  [START_OPTION]: START_OPTION_NOW,
  [START_DATE]: getDateFromTomorrowByDays(1),
  // STOP TIME
  [STOP_OPTION]: OPTION_CUSTOM,
  [STOP_DATE]: getDateFromTomorrowByDays(31)
}

export const budgetDailyValidation = {
  [BUDGET_DAILY]: Yup.string().required('Budget Required')
}

export const validationSchema = Yup.object({
  ...nameValidation,
  ...budgetDailyValidation,

  [TARGET_ROAS]: Yup.string().when(TARGET_ROAS_OPTION, {
    is: SET_TARGET_ROAS,
    then: () => Yup.string().required('Target ROAS required')
  })
})

export const defaultGoogleCampaignPayload = values => {
  if (values[CAMPAIGN_TYPE] === DISPLAY_TYPE) {
    return {
      network_settings: {
        target_google_search: false,
        target_search_network: false,
        target_content_network: true,
        target_partner_search_network: false
      },
      advertising_channel_type: values[CAMPAIGN_TYPE]
    }
  } else if (values[CAMPAIGN_TYPE] === PERFORMANCE_MAX_TYPE) {
    return {
      network_settings: {
        target_google_search: true,
        target_search_network: true,
        target_content_network: true,
        target_partner_search_network: false
      },
      advertising_channel_type: values[CAMPAIGN_TYPE],
      url_expansion_opt_out: true
    }
  }
}

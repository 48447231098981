import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ResendProposal from '../ResendProposal'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'

import { formatDateFullYear } from '../../../../../../../constants/dates'

import { QUOTATION_STATUS } from '../../../../../../../constants/contacts'

import useStyles from './styles'

function RecipientInfo({ quotationData }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const proposalId = quotationData?.id
  const userData = quotationData?.quotation_receiver_user

  const isExpired = quotationData.quotation_status === QUOTATION_STATUS.EXPIRED

  // We don't allow to resend expired quotation
  const showResend = !isExpired

  const recipientInfo = userData ? (
    <div>
      <p>{userData?.full_name}</p>
      <p>{userData?.email}</p>
    </div>
  ) : (
    <p>{quotationData?.quotation_receiver_email}</p>
  )

  return (
    <FieldsSection title={t(`Proposal sent on ${formatDateFullYear(quotationData?.quotation_sent_date)}`)}>
      <div className={classes.recipientContainer}>
        <div className={classes.recipientInfo}>{recipientInfo}</div>
        {showResend && <ResendProposal proposalId={proposalId} />}
      </div>
    </FieldsSection>
  )
}

RecipientInfo.propTypes = {
  quotationData: PropTypes.shape({
    quotation_receiver_user: PropTypes.number,
    quotation_receiver_email: PropTypes.string,
    quotation_sent_date: PropTypes.string
  })
}

export default RecipientInfo

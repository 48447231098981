import { createUseStyles } from 'react-jss'

import { mediumGrey } from '../../../../../../styles/const/colors'

const useStyles = createUseStyles({
  table: {
    border: `1px solid ${mediumGrey}`,
    borderRadius: '4px',
    fontSize: 12,
    maxWidth: 530
  },
  row: {
    display: 'flex'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:not(:last-child)': {
      borderRight: `1px solid ${mediumGrey}`
    }
  },
  cell: {
    padding: 10,
    lineHeight: '1.6'
  },
  header: {
    borderBottom: `1px solid ${mediumGrey}`,
    fontWeight: 700
  },
  notBold: {
    fontWeight: 400
  }
})

export default useStyles

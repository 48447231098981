import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../components/Form/Field'

import { DESCRIPTION } from '../../../MediaProductCreate/MediaProductCreateForm/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const DescriptionSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Product Description')}</h3>
      <Field formik={formik} name={DESCRIPTION} placeholder="Description" enableReinitialize autoComplete="off" />
    </section>
  )
}

export default DescriptionSection

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Select from '../../../../../../../../../components/Select'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import {
  getMediaProductLocationRegionsIsLoadingSelector,
  mediaProductLocationRegionsSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersProductLocations'

import useStyles from './styles'

const LocationRegionSelect = ({ locationRegion, setLocationRegion }) => {
  const classes = useStyles()

  const mediaProductLocationRegions = useSelector(mediaProductLocationRegionsSelector)
  const getMediaProductLocationRegionsIsLoading = useSelector(getMediaProductLocationRegionsIsLoadingSelector)

  const formattedRegions = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationRegions,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationRegions])

  const onChangeHandler = useCallback(
    locationRegion => {
      setLocationRegion(locationRegion.value)
    },
    [setLocationRegion]
  )

  const onClearInput = useCallback(() => {
    setLocationRegion('')
  }, [setLocationRegion])

  return (
    <Select
      placeholder="Region"
      isLoading={getMediaProductLocationRegionsIsLoading}
      value={locationRegion}
      onChange={onChangeHandler}
      className={classes.locationRegionSelect}
      options={formattedRegions}
      portaled={true}
      isSmall
      showClearInputIcon={!!locationRegion}
      onClearInput={onClearInput}
    />
  )
}

LocationRegionSelect.propTypes = {
  locationRegion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setLocationRegion: PropTypes.func.isRequired
}

export default LocationRegionSelect

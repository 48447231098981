import React, { useCallback } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import RecipientEmailSection from '../RecipientEmailSection'

import MinimizedListField from '../../../../features/components/Form/MinimizedListField'
import { NOTIFICATION_EMAILS, initialNotificationEmailSection, EMAIL } from '../fields'

const MultipleEmailRecipientsField = ({ formik, maxCount }) => {
  const { t } = useTranslation()

  const handleEmailTitleGenerate = useCallback((itemValue, index) => {
    const email = getIn(itemValue, EMAIL)
    return email || `Recipient ${index + 1}`
  }, [])

  return (
    <MinimizedListField
      formik={formik}
      fieldPath={NOTIFICATION_EMAILS}
      initialItemIdKey="id"
      initialListValue={initialNotificationEmailSection}
      minCount={1}
      maxCount={maxCount}
      firstItemText={t('+ Add recipient')}
      nextItemText={t('+ Add another recipient')}
      handleTitleGenerate={handleEmailTitleGenerate}
    >
      <RecipientEmailSection formik={formik} />
    </MinimizedListField>
  )
}

MultipleEmailRecipientsField.propTypes = {
  formik: PropTypes.object,
  maxCount: PropTypes.number
}

export default MultipleEmailRecipientsField

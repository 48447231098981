import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import EditFootfallAreasForm from './FootfallAreasEditForm'
import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import {
  getFootfallAreasIsLoadingSelector,
  getFootfallAreasSelector,
  getFootfallAreasWasLoadedSelector
} from '../../../../../modules/selectors/footfallData'
import { FOOTFALL_AREAS_EDIT } from '../../../../../constants/forms'

function FootfallAreasEdit() {
  const { t } = useTranslation()
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: FOOTFALL_AREAS_EDIT
  })

  const footfallAreas = useSelector(getFootfallAreasSelector)
  const footfallAreasIsLoading = useSelector(getFootfallAreasIsLoadingSelector)
  const footfallAreasWasLoaded = useSelector(getFootfallAreasWasLoadedSelector)

  const isFormLoading = useMemo(() => {
    return footfallAreasIsLoading || !footfallAreasWasLoaded
  }, [footfallAreasIsLoading, footfallAreasWasLoaded])

  const editFootfallAreaData = useMemo(
    () => footfallAreas.find(footfallArea => footfallArea?.id === Number(selectedEditItemId)) || {},
    [footfallAreas, selectedEditItemId]
  )

  const title = editFootfallAreaData?.name
    ? `${t('Edit Footfall Areas')} - ${editFootfallAreaData.name}`
    : t('Edit Footfall Areas')

  return (
    <FormDrawerWrapper
      formName={FOOTFALL_AREAS_EDIT}
      title={title}
      isFormLoading={isFormLoading}
      showOpenButton={false}
      closeOnSubmit={true}
    >
      <EditFootfallAreasForm footfallAreaData={editFootfallAreaData} />
    </FormDrawerWrapper>
  )
}

export default FootfallAreasEdit

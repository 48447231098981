import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  pdfContainer: {
    margin: '0 auto',
    maxWidth: 800
  },
  downloadSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px'
  },
  download: {
    margin: '0 0 0 auto'
  }
})

export default useStyles

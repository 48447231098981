import { formatDateShort } from '../../../../constants/dates'
import {
  calculateDiscountedPrice,
  getDecimalDiscount
} from '../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import { calc } from '../../../../helpers/numbers'
import { getDiscountedTotalPrice, getOriginalTotalPrice } from './helpers'
import { isDiscountValid } from '../../../../features/components/Contract/helpers/isDiscountsValid'

import { REPRESENTATIVE } from '../../../../constants/mediaOrders'
import {
  BRAND,
  BUYER,
  CAMPAIGN_NAME,
  CAN_SELECT_MULTIPLE,
  CUSTOM_PRODUCT_DISCOUNT,
  CUSTOM_PRODUCT_PRICE,
  MEDIA_PRODUCTS,
  ORIGINAL_PRODUCT_PRICE,
  PRODUCT_PERIODS_DATES,
  QUANTITY,
  VARIABLES
} from './fields'
import {
  CONTRACT_APPROVED_STATUS,
  CONTRACT_DISAPPROVED_STATUS,
  CONTRACT_NEW_STATUS,
  CONTRACT_PENDING_APPROVAL_STATUS
} from '../../../../constants/contacts'

const getPeriodsPrice = productValues => {
  const selectedPeriods = productValues[PRODUCT_PERIODS_DATES]
  const customDiscount = getDecimalDiscount(productValues[CUSTOM_PRODUCT_DISCOUNT])

  const originalTotalProductPrice = calc(productValues[ORIGINAL_PRODUCT_PRICE])
    .mul(productValues[QUANTITY])
    .toDP(2)
    .toNumber()
  const customProductPrice = productValues[CUSTOM_PRODUCT_PRICE]

  if (customProductPrice === originalTotalProductPrice) {
    // if custom product price is the same as original it means there was no custom price set
    // so it means the discount could be applied
    return selectedPeriods.map(({ price, date_start, date_end, inventory }) => {
      return {
        inventory,
        date_start: date_start,
        date_end: date_end,
        // we send the price per 1 period, BE will calculate the total price based on the quantity
        price: price,
        price_with_discount: calculateDiscountedPrice(price, 1, customDiscount)
      }
    })
  } else {
    // if custom product price is different then original it means the custom price was set and we just pass it
    // the custom price should be split by each period equally
    // if the custom price is set to 100 and there are 5 periods, each period should have 20
    // if the number of periods is not divisible by the custom price, the last period should have the rest
    const customProductPricePerPeriod = calc(customProductPrice)
      .div(selectedPeriods.length)
      .div(productValues[QUANTITY])
      .toDP(2)
      .toNumber()
    const totalBack = calc(customProductPricePerPeriod)
      .mul(selectedPeriods.length)
      .mul(productValues[QUANTITY])
      .toDP(2)
      .toNumber()
    // find missing rest
    const rest = calc(customProductPrice).minus(totalBack).div(productValues[QUANTITY]).toDP(2).toNumber()

    return selectedPeriods.map(({ inventory, price, date_start, date_end }, index) => {
      const isLast = index === selectedPeriods.length - 1
      const priceToSet = isLast ? calc(customProductPricePerPeriod).add(rest).toNumber() : customProductPricePerPeriod
      return {
        inventory,
        date_start: date_start,
        date_end: date_end,
        price: price,
        price_with_discount: priceToSet
      }
    })
  }
}

export const formatProducts = (products, selfAccountRepresentative) => {
  return products.map(productValues => {
    const productData = productValues.data

    const customDiscount = getDecimalDiscount(productValues[CUSTOM_PRODUCT_DISCOUNT])

    return {
      // when the contract is already created the original product id is set to media_product
      // when it's new product selected, that is just the id of the product
      media_product: productData.id,
      discount: customDiscount || 0,
      original_product_name: productData.name,
      periods_price: getPeriodsPrice(productValues),
      [QUANTITY]: productValues[QUANTITY],
      [REPRESENTATIVE]: selfAccountRepresentative || ''
    }
  })
}

export const formatVariablesToBE = variables => {
  return variables.map(variable => {
    const selectedOptionsIds = variable.data[CAN_SELECT_MULTIPLE]
      ? variable.value.map(option => {
          return option.id
        })
      : [variable.value?.id]
    const filteredOptions = selectedOptionsIds.filter(option => !!option)

    return { id: variable.data.id, options: filteredOptions }
  })
}

export const transformValuesToBE = ({ selfAccountData, controllerId, selectedCurrency, values }) => {
  // for cases when the product is not selected(no data), we need to ignore that product values
  const productsValues = values[MEDIA_PRODUCTS]?.filter(product => !!product.data)
  const formattedProducts = formatProducts(productsValues, selfAccountData[REPRESENTATIVE])

  const originalTotalCost = getOriginalTotalPrice(productsValues)
  const discountedTotalCost = getDiscountedTotalPrice(productsValues)

  return {
    name: 'Media Booking - ' + formatDateShort(new Date()),
    account: selfAccountData.id,
    controller: controllerId,
    cost: originalTotalCost,
    cost_with_discount: Number(discountedTotalCost),
    currency: selectedCurrency?.code,
    [BRAND]: values[BRAND],
    [CAMPAIGN_NAME]: values[CAMPAIGN_NAME],
    [MEDIA_PRODUCTS]: formattedProducts,
    [BUYER]: values[BUYER]?.value,
    [VARIABLES]: formatVariablesToBE(values[VARIABLES])
  }
}

export const getApprovalStatus = ({
  contractStatus,
  oldCostWithDiscount,
  calculatedDiscountCost,
  transformedData,
  isPackageApprover
}) => {
  const newCostWithDiscount = transformedData?.cost_with_discount
  const getIsDiscountValid = () =>
    isDiscountValid({
      contractDiscountedCost: newCostWithDiscount,
      calculatedTotalDiscountCost: calculatedDiscountCost
    })?.isValid

  if (contractStatus === CONTRACT_APPROVED_STATUS) {
    if (isPackageApprover) {
      // if the user has package approval permissions, the status should always be Approved
      return CONTRACT_APPROVED_STATUS
    } else {
      // if the proposal has a status of approved, and a user without approval permissions changes:
      // the amount to a larger discount / lower cost then change the status to New. So they will need to go through the approval process again
      // check if the new cost is higher than the old cost(which was approved)
      const isNewCostHigher = newCostWithDiscount >= oldCostWithDiscount

      return isNewCostHigher ? CONTRACT_APPROVED_STATUS : CONTRACT_NEW_STATUS
    }
  } else if (contractStatus === CONTRACT_PENDING_APPROVAL_STATUS || contractStatus === CONTRACT_DISAPPROVED_STATUS) {
    // If proposal is status pending_approval or disapproved and If proposal is edited in a way that causes the price to go equal to or above the rate card discount, update quotation_status to Active('new').
    const isCostValid = getIsDiscountValid()

    // when allowResetStatusToActive reset status in cases of status pending_approval or disapproved and discount is valid
    // if not valid - still pass old status as BE resets it to 'new' on each update
    return isCostValid ? CONTRACT_NEW_STATUS : contractStatus
  } else {
    return contractStatus
  }
}

export const getCommonProposalManageData = ({
  contextSelfAccountData,
  controllerId,
  hasProposalApprovalFlow,
  isPackageApprover,
  contractStatus,
  proposalWasSent,
  oldCostWithDiscount,
  transformedData,
  calculatedDiscountCost
}) => {
  const status = hasProposalApprovalFlow
    ? getApprovalStatus({
        contractStatus,
        oldCostWithDiscount,
        calculatedDiscountCost,
        transformedData,
        isPackageApprover
      })
    : contractStatus

  return {
    // in this case the PDF file generation will occur in the background and will not affect the response time
    // as we are not using the PDF file for contract builder, we can set this to false speed up the request
    // when contract was sent, the PDF file should be generated in background to not slow down the response but update the contract to user
    generate_pdf: proposalWasSent ? 'background' : 'false', // should be string
    quotation: true, // BE need to get it passed for correct work
    set_amendment_cost: true, // allows to set modified pricing
    account: contextSelfAccountData.id,
    controller: controllerId,
    status
  }
}

import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductTagsCreateForm from './MediaProductTagsCreateForm'

import { CREATE_PRODUCT_TAGS } from '../../../../../constants/forms'

function MediaProductTagsCreate() {
  return (
    <FormDrawerWrapper
      formName={CREATE_PRODUCT_TAGS}
      title="Create tag"
      openButtonText="Create tag"
      closeOnSubmit={true}
    >
      <MediaProductTagsCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaProductTagsCreate

import React from 'react'
import { getIn } from 'formik'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'
import MediaSubCategoriesPaginatedSelect from './MediaSubCategoriesPaginatedSelect'
import MediaSubSubCategoriesPaginatedSelect from './MediaSubSubCategoriesPaginatedSelect'
import LocationsPaginatedSelect from './LocationsPaginatedSelect'

import { ATTRIBUTE, MEDIA_PRODUCT_ATTRIBUTE, VALUES } from '../../../fields'
import { mediaCategories } from '../../../../../../../../constants/mediaOrders'
import { MEDIA_CATEGORY } from '../../../../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import {
  LOCATION,
  MEDIA_SUB_CATEGORY,
  MEDIA_SUB_SUB_CATEGORY
} from '../../../../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/fields'

const MediaAssetFilterValuesSelect = ({ formik }) => {
  const { values, setFieldValue } = formik

  if (getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`) === MEDIA_CATEGORY) {
    return (
      <MultiSelectBox
        setFieldValue={setFieldValue}
        name={`${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`}
        placeholder="Select"
        options={mediaCategories}
        value={getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`)}
        isLoading={false}
        portaled={true}
      />
    )
  }

  if (getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`) === MEDIA_SUB_CATEGORY) {
    return <MediaSubCategoriesPaginatedSelect formik={formik} />
  }

  if (getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`) === MEDIA_SUB_SUB_CATEGORY) {
    return <MediaSubSubCategoriesPaginatedSelect formik={formik} />
  }

  if (getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`) === LOCATION) {
    return <LocationsPaginatedSelect formik={formik} />
  }

  return null
}

export default MediaAssetFilterValuesSelect

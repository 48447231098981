import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getCurrentStatementService(id, params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.statements}${id}/`
  })
}

export function getStatementsAsCsvService(params) {
  return madeRequest('GET', {
    params,
    returnHeaders: true,
    url: `${ENDPOINTS.statements}export_to_csv/`
  })
}

export function updateStatementService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.statements}${id}/`
  })
}

export function getStatementsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.statements
  })
}

import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Field from '../../../../components/Form/Field'
import MultiSelectBox from '../../../../features/components/Form/MultiSelectBox'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getMembersListService } from '../../../../modules/services/selfAccountTeam'
import { selectedSelfAccountSelector } from '../../../../modules/selectors/app'

import { NOTIFICATION_USERS } from '../fields'

const defaultOptions = []
const SelfAccountMembersPaginatedSelect = ({ formik, limit = 50, selfAccountId, optionsIdsToFilter = [], isMulti }) => {
  const { values, errors, touched, setFieldValue } = formik

  const selectedSelfAccountId = useSelector(selectedSelfAccountSelector)

  const formatOptions = useCallback(
    options =>
      options
        .filter(option => !optionsIdsToFilter.includes(option.user))
        .map(option => ({
          value: option.user,
          label: option.full_name
        })),
    [optionsIdsToFilter]
  )

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getMembersListService({
        search,
        ordering: 'name',
        account: selfAccountId || selectedSelfAccountId,
        fields: ['full_name', 'user'].join(','),
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newOptions = formatOptions(response.results)

      return {
        options: newOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selfAccountId, selectedSelfAccountId, limit, formatOptions]
  )

  const commonSelectProps = useMemo(
    () => ({
      loadOptions,
      expandMenu: true,
      portaled: true
    }),
    [loadOptions]
  )

  return isMulti ? (
    <MultiSelectBox
      placeholder="Select users"
      name={NOTIFICATION_USERS}
      value={values[NOTIFICATION_USERS]}
      error={errors[NOTIFICATION_USERS]}
      touched={touched[NOTIFICATION_USERS]}
      setFieldValue={setFieldValue}
      {...commonSelectProps}
    />
  ) : (
    <Field
      formik={formik}
      // we use array to reuse structure but be able to select only one user
      name={`${[NOTIFICATION_USERS]}[0]`}
      placeholder="Select user"
      loadOptions={loadOptions}
      options={defaultOptions}
      {...commonSelectProps}
    />
  )
}

SelfAccountMembersPaginatedSelect.propTypes = {
  formik: PropTypes.object.isRequired,
  limit: PropTypes.number,
  optionsIdsToFilter: PropTypes.arrayOf(PropTypes.number),
  selfAccountId: PropTypes.number,
  isMulti: PropTypes.bool
}

export default SelfAccountMembersPaginatedSelect

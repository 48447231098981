import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import { ASSET_MANAGER } from '../../../../../../../constants/permissions'
import { MEDIA_SUB_CATEGORIES } from '../../../../../../Settings/ManageControllerTeam/ControllerInviteCreate/ControllerInviteCreateForm/fields'
import { MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER } from '../../../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import { MULTIPLE_REPRESENTATIVE_FILTER } from '../../../../../../../features/components/Filters/RepresentativeFilter'
import { SELECTED_LOCATIONS } from '../../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import { SELECTED_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import { SELECTED_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { SELECTED_SUB_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import { SELECTED_PRODUCTS } from '../../../../../../../features/components/DropdownFilters/components/ProductsFilter'
import { BRAND_CATEGORY } from '../../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import { BRAND_SUB_CATEGORY } from '../../../../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'
import { LOCATION_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/LocationCategoriesFilter'
import { LOCATION_REGIONS } from '../../../../../../../features/components/DropdownFilters/components/LocationRegionsFilter'

export const BookedMediaCalendarFiltersContext = React.createContext()

export function BookedMediaCalendarDataProvider({ children, onFiltersChange }) {
  const {
    category: selectedControllerCategory,
    role: selectedControllerRole,
    [MEDIA_SUB_CATEGORIES]: selectedControllerMediaSubCategories
  } = useSelector(selectedControllerDataSelector)

  const formattedSelectedControllerMediaSubCategories = useMemo(() => {
    return selectedControllerMediaSubCategories?.map(item => ({
      value: item.id,
      label: item.name
    }))
  }, [selectedControllerMediaSubCategories])

  const formattedSelectedControllerCategory = useMemo(
    () => ({
      value: selectedControllerCategory?.id,
      label: selectedControllerCategory?.name
    }),
    [selectedControllerCategory]
  )

  const [filtersState, setFiltersState] = useState({
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: [],
    [MULTIPLE_REPRESENTATIVE_FILTER]: [],
    [SELECTED_LOCATIONS]: [],
    [LOCATION_CATEGORIES]: [],
    [LOCATION_REGIONS]: [],
    [SELECTED_CATEGORIES]: [],
    [SELECTED_SUB_CATEGORIES]:
      selectedControllerRole === ASSET_MANAGER ? formattedSelectedControllerMediaSubCategories : [],
    [SELECTED_SUB_SUB_CATEGORIES]: [],
    [SELECTED_PRODUCTS]: [],
    [BRAND_CATEGORY]: selectedControllerCategory ? [formattedSelectedControllerCategory] : [],
    [BRAND_SUB_CATEGORY]: []
  })

  const {
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption,
    [MULTIPLE_REPRESENTATIVE_FILTER]: multipleRepresentativeFilterOption,
    [SELECTED_LOCATIONS]: selectedLocations,
    [LOCATION_CATEGORIES]: locationCategories,
    [LOCATION_REGIONS]: locationRegions,
    [SELECTED_CATEGORIES]: selectedCategories,
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories,
    [SELECTED_PRODUCTS]: productFilterOption,
    [BRAND_CATEGORY]: brandCategory,
    [BRAND_SUB_CATEGORY]: brandSubCategory
  } = filtersState

  const filterRequestParams = useMemo(() => {
    return {
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption.map(option => option.value) }),
      ...(multipleRepresentativeFilterOption?.length && {
        representative: multipleRepresentativeFilterOption.map(option => option.value)
      }),
      ...(selectedCategories.length && { media_category: selectedCategories.map(option => option.value) }),
      ...(selectedSubCategories.length && { media_sub_category: selectedSubCategories.map(option => option.value) }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: selectedSubSubCategories.map(option => option.value)
      }),
      ...(productFilterOption.length && { media_product: productFilterOption.map(option => option.value) }),
      ...(selectedLocations.length && { location: selectedLocations.map(option => option.value) }),
      ...(locationCategories.length && { location_category: locationCategories.map(option => option.value) }),
      ...(locationRegions.length && { location_region: locationRegions.map(option => option.value) }),
      ...(brandCategory?.length && { brand_category: brandCategory.map(option => option.value) }),
      ...(brandSubCategory?.length && { brand_subcategory: brandSubCategory.map(option => option.value) })
    }
  }, [
    multipleAccountFilterOption,
    multipleRepresentativeFilterOption,
    selectedCategories,
    selectedSubCategories,
    selectedSubSubCategories,
    productFilterOption,
    selectedLocations,
    locationCategories,
    locationRegions,
    brandCategory,
    brandSubCategory
  ])

  const bookedMediaCalendarFilterProps = useMemo(() => {
    return {
      filterRequestParams,
      filtersState,
      setFiltersState
    }
  }, [filterRequestParams, filtersState])

  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, bookedMediaCalendarFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [onFiltersChange, filterRequestParams])

  return (
    <BookedMediaCalendarFiltersContext.Provider value={bookedMediaCalendarFilterProps}>
      {children}
    </BookedMediaCalendarFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = {
  account: PropTypes.array,
  representative: PropTypes.array,
  media_category: PropTypes.array,
  media_sub_category: PropTypes.array,
  media_sub_sub_category: PropTypes.array,
  media_product: PropTypes.array,
  location: PropTypes.array,
  brand_category: PropTypes.array,
  brand_subcategory: PropTypes.array
}

const propTypes = {
  filterRequestParams: PropTypes.shape(filterRequestParamsTypes),
  filtersState: PropTypes.object,
  setFiltersState: PropTypes.func
}

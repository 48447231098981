import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TableNoData from '../../../../../components/Table/TableNoData'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'
import FootfallAreasTableData from './FootfallAreasTableData'

import { useDeleteOffset } from './useDeleteOffest'
import useSearch from '../../../../../hooks/useSearch'
import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import { clearGetFootfallAreas, getFootfallAreas } from '../../../../../modules/actions/footfallData'
import {
  getFootfallAreasErrorSelector,
  getFootfallAreasIsLoadingSelector,
  getFootfallAreasNextSelector,
  getFootfallAreasSelector,
  getFootfallAreasWasLoadedSelector
} from '../../../../../modules/selectors/footfallData'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { columnsSchema } from './const/columns'
import { FOOTFALL_AREAS_SEARCH } from '../index'

const FootfallAreasTable = () => {
  const { t } = useTranslation()

  const deletedCount = useDeleteOffset()

  const data = useSelector(getFootfallAreasSelector)
  const next = useSelector(getFootfallAreasNextSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const searchTerm = useSearch(FOOTFALL_AREAS_SEARCH)

  const params = useMemo(
    () => ({
      search: searchTerm,
      controller: selectedControllerId,
      ordering: 'name',
      status: 'active'
    }),
    [searchTerm, selectedControllerId]
  )

  const loadMoreHandler = useLoadPaginatedList({
    params,
    action: getFootfallAreas,
    clearAction: clearGetFootfallAreas,
    next,
    offsetAdjust: deletedCount
  })

  const skeletonProps = useMemo(() => ({ cols: columnsSchema }), [])

  return (
    <TableDataLoader
      itemsLength={data.length}
      errorSelector={getFootfallAreasErrorSelector}
      wasLoadedSelector={getFootfallAreasWasLoadedSelector}
      isLoadingSelector={getFootfallAreasIsLoadingSelector}
      skeletonProps={skeletonProps}
      noDataContent={<TableNoData title={t('There are no footfall areas')} />}
      loadMore={loadMoreHandler}
    >
      <FootfallAreasTableData />
    </TableDataLoader>
  )
}

export default FootfallAreasTable

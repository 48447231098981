import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import FileDetailsPreview from '../../../../../../../../../components/FileDetailsPreview'

import { getFileFormat, getFileType } from '../../../../../../../../../features/helpers/other'
import useFileUpload from '../../../../../../../../../features/hooks/useFileUpload'
import useBlobUrl from '../../../../useBlobUrl'

const FilePreview = ({ file, onFileUploaded }) => {
  const filePreviewType = useMemo(() => file.type && getFileType(file), [file])
  const fileFormat = useMemo(() => file.name && getFileFormat(file), [file])

  const fileURLBlob = useBlobUrl(file)

  const onFileUploadedHandler = useCallback(
    uploadedFileData => {
      onFileUploaded &&
        onFileUploaded({
          ...uploadedFileData,
          fileURLBlob,
          file
        })
    },
    [onFileUploaded, file, fileURLBlob]
  )

  const { isFileLoading, fileUploadProgress } = useFileUpload({
    fileId: file.id,
    onFileUploaded: onFileUploadedHandler
  })

  return (
    <FileDetailsPreview
      key={file.name}
      fileName={file.name}
      isFileLoading={isFileLoading}
      fileUploadProgress={fileUploadProgress}
      previewUrl={fileURLBlob}
      fileFormat={fileFormat}
      filePreviewType={filePreviewType}
    />
  )
}

FilePreview.propTypes = {
  file: PropTypes.object.isRequired,
  onFileUploaded: PropTypes.func
}

export default FilePreview

import React from 'react'

import FiltersSearch from '../../../features/components/Filters/FiltersSearch'

import useStyles from './styles'

export const MEDIA_ORDERS_SEARCH = 'media_orders_search'
const MediaOrdersActions = () => {
  const classes = useStyles()

  return (
    <div className={classes.mediaOrderActions}>
      <FiltersSearch searchId={MEDIA_ORDERS_SEARCH} placeholder="Search by ID" setOnlyNumber={true} />
    </div>
  )
}

export default MediaOrdersActions

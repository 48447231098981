import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLoadPaginatedList } from '../../../../features/hooks/useLoadPaginatedList'
import { mapListForQuery } from '../../../../helpers/common'

import { clearGetQuotations, getQuotations } from '../../../../modules/actions/quotations'

import { quotationsNextSelector, quotationsSelector } from '../../../../modules/selectors/quotations'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

import { SORT } from '../../../../constants/other'
import { SORT_CREATED } from '../QuotationsTableData/helpers'
import {
  CONTRACT_NEW_STATUS,
  CONTRACT_SIGNED_STATUS,
  CONTRACT_PENDING_APPROVAL_STATUS,
  CONTRACT_DISAPPROVED_STATUS,
  CONTRACT_APPROVED_STATUS
} from '../../../../constants/contacts'
import { MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER } from '../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import { MULTIPLE_CONTROLLER_MEMBERS_FILTER } from '../../../../features/components/DropdownFilters/components/MultipleControllerMembersFilter'
import { BRAND_CATEGORY } from '../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import { SELECTED_PRODUCTS } from '../../../../features/components/DropdownFilters/components/ProductsFilter'

export const QuotationTableContext = React.createContext()

const statuses = [
  CONTRACT_NEW_STATUS,
  CONTRACT_SIGNED_STATUS,
  CONTRACT_APPROVED_STATUS,
  CONTRACT_PENDING_APPROVAL_STATUS,
  CONTRACT_DISAPPROVED_STATUS
]
export const QuotationTableContextProvider = ({ children, accountFilter: initialAccountFilter }) => {
  const [rowSelection, setRowSelection] = useState({})
  const [tableSort, setTableSort] = useState({
    parameter: SORT_CREATED,
    direction: SORT.DESC
  })

  const quotations = useSelector(quotationsSelector)
  const quotationsNext = useSelector(quotationsNextSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const [filtersState, setFiltersState] = useState({
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: initialAccountFilter ? [initialAccountFilter] : [],
    [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: [],
    [BRAND_CATEGORY]: [],
    [SELECTED_PRODUCTS]: []
  })

  const {
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption,
    [MULTIPLE_CONTROLLER_MEMBERS_FILTER]: multipleControllerMembersFilter,
    [BRAND_CATEGORY]: brandCategory,
    [SELECTED_PRODUCTS]: selectedProducts
  } = filtersState

  const rowIds = useMemo(() => {
    return quotations?.map(({ id }) => id)
  }, [quotations])

  const filtersParams = useMemo(() => {
    return {
      ...(multipleAccountFilterOption.length && {
        account: multipleAccountFilterOption.map(option => option.value).join(',')
      }),
      // created_by
      ...(multipleControllerMembersFilter?.length && {
        created_by: mapListForQuery(multipleControllerMembersFilter, 'user').join(',')
      }),
      // brand category
      ...(brandCategory.length && { brand_category: mapListForQuery(brandCategory, 'value').join(',') }),
      ...(selectedProducts.length && { media_product: mapListForQuery(selectedProducts, 'value').join(',') })
    }
  }, [multipleAccountFilterOption, multipleControllerMembersFilter, brandCategory, selectedProducts])

  const quotationsParams = useMemo(() => {
    const sortDirectionParam = tableSort.direction === SORT.DESC ? '-' : ''
    return {
      controller: controllerId,
      // we need to add a - so that the ordering is newest first
      ordering: `${sortDirectionParam}${tableSort.parameter}`,
      calculate_proposals: true, // to return total value for all elements - total_value
      status: statuses.join(','),
      ...filtersParams,
      limit: 20
    }
  }, [tableSort, controllerId, filtersParams])

  const loadMoreQuotationsHandler = useLoadPaginatedList({
    params: quotationsParams,
    action: getQuotations,
    clearAction: clearGetQuotations,
    next: quotationsNext
  })

  useEffect(() => {
    // there is case when the data element(row) could be removed from the data array
    // so we need to remove the row from the selection
    setRowSelection(prevRowSelection => {
      const newSelection = Object.keys(prevRowSelection).reduce((acc, rowId) => {
        // convert to number after getting from object keys
        const numericRowId = Number(rowId)

        if (rowIds.includes(numericRowId)) {
          acc[numericRowId] = prevRowSelection[numericRowId]
        }
        return acc
      }, {})
      return newSelection
    })
  }, [rowIds])

  return (
    <QuotationTableContext.Provider
      value={{
        rowIds,
        rowSelection,
        setRowSelection,
        tableSort,
        setTableSort,
        loadMoreQuotationsHandler,
        filtersState,
        setFiltersState
      }}
    >
      {children}
    </QuotationTableContext.Provider>
  )
}

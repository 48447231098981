import * as Yup from 'yup'

import {
  IMAGES,
  MEDIA_CATEGORY,
  PERIOD_OPTION,
  PLATFORMS
} from '../../MediaProductCreate/MediaProductCreateForm/fields'
import { nameValidation } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/fields'
import { imageValidation } from '../../MediaProductCreate/MediaProductCreateForm/validation'
import { CURRENCY } from './fields'
import { SUB_PRODUCTS } from '../../fields'
import { platformsValidation } from '../../validation'

export const validationSchema = Yup.object({
  ...nameValidation,
  [SUB_PRODUCTS]: Yup.array().min(1, 'Select at least one product'),
  [MEDIA_CATEGORY]: Yup.string().required('Media category required'),
  [PERIOD_OPTION]: Yup.string().required('Period required'),
  [CURRENCY]: Yup.string().required('Currency required'),
  [PLATFORMS]: platformsValidation,
  [IMAGES]: imageValidation
})

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../../../../../components/Button'
import ProgressButton from '../../../../../components/Form/ProgressButton'

import { getInfoModalButtonColor, PRIMARY_BUTTON, SECONDARY_BUTTON, THEME_PRIMARY_BUTTON } from './helpers'

import { green } from '../../../../../styles/const/colors'
import useStyles from './styles'

const InfoModalButtons = ({ buttons }) => {
  const classes = useStyles()

  return (
    <div className={classes.buttonsWrapper}>
      {buttons.map((button, index) => {
        const { type, text, className, buttonColor, ...buttonProps } = button
        const buttonClassNameColor = getInfoModalButtonColor(buttonColor, classes)

        if (type === 'progress') {
          // todo make a separate helper if need to support more colors
          const loadingColor = buttonColor === PRIMARY_BUTTON ? green : undefined
          return (
            <ProgressButton
              key={index}
              className={classnames(buttonClassNameColor, className)}
              wrapperClassName={classes.progressBtn}
              loadingBackgroundColor={loadingColor}
              {...buttonProps}
            >
              {text}
            </ProgressButton>
          )
        }
        return (
          <Button key={index} className={classnames(buttonClassNameColor, className)} {...buttonProps}>
            {text}
          </Button>
        )
      })}
    </div>
  )
}

InfoModalButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['progress', 'button']).isRequired,
      buttonColor: PropTypes.oneOf([PRIMARY_BUTTON, SECONDARY_BUTTON, THEME_PRIMARY_BUTTON]),
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      className: PropTypes.string,
      solid: PropTypes.bool,
      btnColor: PropTypes.string,
      successSubmit: PropTypes.any,
      isFormLoading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      formError: PropTypes.object,
      onErrorSubmit: PropTypes.func,
      onSuccessSubmit: PropTypes.func,
      clearHandler: PropTypes.func,
      iconClassName: PropTypes.string
    })
  ).isRequired
}

export default InfoModalButtons

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CompetingQuotationsTable from './CompetingQuotationsTable'
import { orangeShop } from '../../../../../../../../../styles/const/colors'
import Dropdown from '../../../../../../../../../components/Dropdown'

import { ReactComponent as AlertIcon } from '../../../../../../../../../assets/icons/alertIcon.svg'

import { splitPeriodsToGroups } from '../../../../../fields'
import { PERIOD_DAY } from '../../../../../../../../../constants/mediaOrders'

import useStyles from './styles'

const CompetingQuotations = ({ competingPeriods, productPeriod }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // each period has its own a competing quotations, which could be duplicated in other periods
  // so we need to group them by quotation id to avoid duplicates, and add all period dates to each quotation
  const groupedCompetingQuotations = competingPeriods.reduce((quotations, period) => {
    const competingQuotations = period.competing_quotation.competing_quotation_details

    competingQuotations.forEach(quotation => {
      const quotationId = quotation.id
      const wasQuotationAdded = quotations[quotationId]

      if (wasQuotationAdded) {
        quotations[quotationId].periods.push({
          date_start: period.date_start,
          date_end: period.date_end
        })
      } else {
        quotations[quotationId] = {
          ...quotation,
          periods: [{ date_start: period.date_start, date_end: period.date_end }]
        }
      }
    })

    return quotations
  }, {})

  const groupedCompetingQuotationsList = useMemo(() => {
    const quotationsList = Object.values(groupedCompetingQuotations)

    if (productPeriod === PERIOD_DAY) {
      // If period=day, show them as continuous ranges and not individual rows per day.
      return quotationsList.map(quotation => {
        return {
          ...quotation,
          periods: splitPeriodsToGroups(quotation.periods, 'day').map(periodsGroup => {
            return {
              date_start: periodsGroup[0].date_start,
              date_end: periodsGroup[periodsGroup.length - 1].date_end
            }
          })
        }
      })
    } else {
      return quotationsList
    }
  }, [groupedCompetingQuotations, productPeriod])

  return (
    <Dropdown
      className={classes.dropdown}
      placement="left"
      borderWidth={2}
      borderColor={orangeShop}
      withFloatingArrow={true}
      triggerElement={<AlertIcon className={classes.competingWarningIcon} />}
    >
      <div className={classes.dropdownBodyWrapper}>
        <h4>{t('Competing Proposals')}</h4>
        <p className={classes.description}>
          {t('There are competing proposals and not enough inventory to book them all.')}
        </p>
        <CompetingQuotationsTable competingQuotations={groupedCompetingQuotationsList} />
      </div>
    </Dropdown>
  )
}

CompetingQuotations.propTypes = {
  competingPeriods: PropTypes.array,
  productPeriod: PropTypes.string
}

export default CompetingQuotations

import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import BookingVariablesActions from '../BookingVariablesActions'
import Table from '../../../../../../components/Table'

import {
  bookingVariablesSelector,
  updateBookingVariableIdSelector,
  updateBookingVariableIsLoadingSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'

import { tableColumnsSize } from '../../../../MediaProductOptions/ProductTags/MediaTagsTable'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const BookingVariablesTableData = () => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const bookingVariables = useSelector(bookingVariablesSelector)
  const updateBookingVariableIsLoading = useSelector(updateBookingVariableIsLoadingSelector)
  const updateBookingVariableId = useSelector(updateBookingVariableIdSelector)

  const loadingId = useMemo(() => {
    if (updateBookingVariableIsLoading) {
      return updateBookingVariableId
    }

    // default value null if nothing is updating or deleting
    return null
  }, [updateBookingVariableId, updateBookingVariableIsLoading])

  const tagsColumns = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        Cell: data => <BookingVariablesActions data={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile]
  )

  return (
    <Table
      hideHeadlineRow
      data={bookingVariables}
      cols={tagsColumns}
      itemUpdatingId={loadingId}
      tableId="BookingVariablesTableData"
      hideFooterRow
    />
  )
}

export default BookingVariablesTableData

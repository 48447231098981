import React from 'react'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import ProductLocationsTable from './ProductLocationsTable'
import FiltersSearch from '../../../../features/components/Filters/FiltersSearch'
import MediaProductLocationEdit from '../../../../forms/Multiplatform/MediaProducts/MediaProductLocationForms/MediaProductLocationEdit'
import MediaProductLocationCreate from '../../../../forms/Multiplatform/MediaProducts/MediaProductLocationForms/MediaProductLocationCreate'

import useStyles from './styles'

export const PRODUCT_LOCATIONS_SEARCH = 'product_locations_search'

const ProductLocations = () => {
  const classes = useStyles()

  return (
    <ContentRow
      title="Product Locations"
      description="Enter a list of locations (e.g. malls, stores) to group physical locations"
      leftColumnChildren={<MediaProductLocationCreate />}
    >
      <div className={classes.productLocationsTableActions}>
        <FiltersSearch className={classes.productLocationsSearch} searchId={PRODUCT_LOCATIONS_SEARCH} />
      </div>
      <ProductLocationsTable />
      <MediaProductLocationEdit />
    </ContentRow>
  )
}

export default ProductLocations

import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import CreateFootfallAreasForm from './CreateFootfallAreasForm'

import { FOOTFALL_AREAS_CREATE } from '../../../../../constants/forms'

function CreateFootfallAreas() {
  return (
    <FormDrawerWrapper
      formName={FOOTFALL_AREAS_CREATE}
      title="Create footfall area"
      description="Assign one or more stores for this footfall area to apply external footfall data for reach and impression calculations."
      openButtonText="Create Footfall Area"
    >
      <CreateFootfallAreasForm />
    </FormDrawerWrapper>
  )
}

export default CreateFootfallAreas

import { createStyles } from '../../../../../styles/helpers'
import { darkGrey, green } from '../../../../../styles/const/colors'

const useStyles = createStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10
  },
  secondaryBtn: {
    color: darkGrey,
    borderColor: darkGrey,
    '&:hover': {
      backgroundColor: darkGrey,
      borderColor: darkGrey
    }
  },
  progressBtn: {
    width: 'auto !important'
  },
  primaryBtn: {
    '&.solid': {
      backgroundColor: green,
      borderColor: green
    },
    '&:hover.solid': {
      color: green
    }
  }
})

export default useStyles

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import AdaptiveActions from '../../../../../../features/components/AdaptiveActions'

import { openForm } from '../../../../../../modules/actions/forms'
import {
  clearUpdateBookingVariable,
  updateBookingVariable
} from '../../../../../../modules/actions/mediaOrdersBookings'
import { updateBookingVariableWasUpdatedSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import { BOOKING_VARIABLE_EDIT } from '../../../../../../constants/forms'

const BookingVariablesActions = ({ data }) => {
  const { t } = useTranslation()

  const [isDeleting, setIsDeleting] = useState(false)

  const dispatch = useDispatch()

  const updateBookingVariableWasUpdated = useSelector(updateBookingVariableWasUpdatedSelector)

  const { id } = data

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: BOOKING_VARIABLE_EDIT }))
  }, [dispatch, id])

  const deleteHandler = useCallback(() => {
    dispatch(
      updateBookingVariable(id, {
        status: 'deleted'
      })
    )
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit Variable'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete Variable'),
        onClickHandler: deleteHandler
      }
    ],
    [t, openEditFormHandler, deleteHandler]
  )

  useEffect(() => {
    // When we delete a booking variable, we actually update it with status 'deleted'
    // we need to clear the updateBookingVariable state after the booking variable was updated
    if (isDeleting && updateBookingVariableWasUpdated) {
      dispatch(clearUpdateBookingVariable())
      setIsDeleting(false)
    }
  }, [dispatch, isDeleting, updateBookingVariableWasUpdated])

  return <AdaptiveActions options={dropdownOptions} />
}

BookingVariablesActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

export default BookingVariablesActions

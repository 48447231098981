import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useRequestHandler from '../../../../../../hooks/useRequestHandler'

import {
  clearSendCampaignReachReport,
  sendCampaignReachReport
} from '../../../../../../modules/actions/mediaOrdersBookings'
import {
  sendCampaignReachReportErrorSelector,
  sendCampaignReachReportIsLoadingSelector,
  campaignReachReportWasSendSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'

export function useSendReachReport(mediaOrderId) {
  const dispatch = useDispatch()

  const handleSendReport = useCallback(() => {
    dispatch(
      sendCampaignReachReport({
        id: mediaOrderId
      })
    )
  }, [dispatch, mediaOrderId])

  const clearSendReportHandler = useCallback(() => {
    dispatch(clearSendCampaignReachReport())
  }, [dispatch])

  const { isLoading, requestSuccess, requestError } = useRequestHandler({
    clearHandler: clearSendReportHandler,
    isLoadingRequestSelector: sendCampaignReachReportIsLoadingSelector,
    successRequestSelector: campaignReachReportWasSendSelector,
    errorRequestSelector: sendCampaignReachReportErrorSelector
  })

  return useMemo(
    () => ({
      handleSendReport,
      isLoading,
      requestSuccess,
      requestError
    }),
    [handleSendReport, isLoading, requestSuccess, requestError]
  )
}

import update from 'immutability-helper'

import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'

import {
  GET_MEDIA_PACKAGE,
  GET_MEDIA_PACKAGE_SUCCESS,
  GET_MEDIA_PACKAGE_FAILURE,
  CLEAR_GET_MEDIA_PACKAGE,
  GET_MEDIA_PACKAGE_CATEGORIES,
  GET_MEDIA_PACKAGE_CATEGORIES_SUCCESS,
  GET_MEDIA_PACKAGE_CATEGORIES_FAILURE,
  CLEAR_MEDIA_PACKAGE_CATEGORIES,
  CREATE_MEDIA_PACKAGE_CATEGORY,
  CREATE_MEDIA_PACKAGE_CATEGORY_SUCCESS,
  CREATE_MEDIA_PACKAGE_CATEGORY_FAILURE,
  CLEAR_CREATE_MEDIA_PACKAGE_CATEGORY,
  UPDATE_MEDIA_PACKAGE_CATEGORY,
  UPDATE_MEDIA_PACKAGE_CATEGORY_SUCCESS,
  UPDATE_MEDIA_PACKAGE_CATEGORY_FAILURE,
  CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORY,
  UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER,
  UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_SUCCESS,
  UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_FAILURE,
  CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER,
  GET_GROUPED_MEDIA_PACKAGES,
  GET_GROUPED_MEDIA_PACKAGES_SUCCESS,
  GET_GROUPED_MEDIA_PACKAGES_FAILURE,
  CLEAR_GROUPED_MEDIA_PACKAGES,
  GET_MEDIA_PACKAGES,
  GET_MEDIA_PACKAGES_SUCCESS,
  GET_MEDIA_PACKAGES_FAILURE,
  CLEAR_MEDIA_PACKAGES,
  CREATE_MEDIA_PACKAGE,
  CREATE_MEDIA_PACKAGE_SUCCESS,
  CREATE_MEDIA_PACKAGE_FAILURE,
  CLEAR_CREATE_MEDIA_PACKAGE,
  UPDATE_MEDIA_PACKAGE,
  UPDATE_MEDIA_PACKAGE_SUCCESS,
  UPDATE_MEDIA_PACKAGES_BULK_SUCCESS,
  UPDATE_MEDIA_PACKAGE_FAILURE,
  CLEAR_UPDATE_MEDIA_PACKAGE,
  DELETE_MEDIA_PACKAGE,
  DELETE_MEDIA_PACKAGE_SUCCESS,
  DELETE_MEDIA_PACKAGE_FAILURE,
  CLEAR_DELETE_MEDIA_PACKAGE,
  UPDATE_MEDIA_PACKAGES_ORDER,
  UPDATE_MEDIA_PACKAGES_ORDER_SUCCESS,
  UPDATE_MEDIA_PACKAGES_ORDER_FAILURE,
  CLEAR_UPDATE_MEDIA_PACKAGES_ORDER,
  GET_MEDIA_PACKAGES_AS_CSV,
  GET_MEDIA_PACKAGES_AS_CSV_SUCCESS,
  GET_MEDIA_PACKAGES_AS_CSV_FAILURE,
  CLEAR_GET_MEDIA_PACKAGES_AS_CSV
} from '../actions/mediaPackages'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  getMediaPackage: INITIAL_GET_REDUCER,
  mediaPackageCategories: INITIAL_GET_RESULTS_REDUCER,
  createMediaPackageCategory: INITIAL_CREATE_REDUCER,
  updateMediaPackageCategory: INITIAL_UPDATE_REDUCER,
  updateMediaPackageCategoriesOrder: INITIAL_UPDATE_REDUCER,
  groupedMediaPackages: INITIAL_GET_RESULTS_REDUCER,
  mediaPackages: INITIAL_GET_RESULTS_REDUCER,
  createMediaPackage: INITIAL_CREATE_REDUCER,
  updateMediaPackage: INITIAL_UPDATE_REDUCER,
  deleteMediaPackage: INITIAL_DELETE_REDUCER,
  updateMediaPackagesOrder: INITIAL_UPDATE_REDUCER,
  mediaPackagesAsCsv: INITIAL_GET_REDUCER
}

export default function mediaPackages(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get media package
    case GET_MEDIA_PACKAGE:
      return update(state, {
        getMediaPackage: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PACKAGE_SUCCESS:
      return update(state, {
        getMediaPackage: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_MEDIA_PACKAGE_FAILURE:
      return update(state, {
        getMediaPackage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PACKAGE:
      return update(state, {
        getMediaPackage: { $set: initialState.getMediaPackage }
      })

    // Get media package categories
    case GET_MEDIA_PACKAGE_CATEGORIES:
      return update(state, {
        mediaPackageCategories: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PACKAGE_CATEGORIES_SUCCESS:
      return update(state, {
        mediaPackageCategories: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_MEDIA_PACKAGE_CATEGORIES_FAILURE:
      return update(state, {
        mediaPackageCategories: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MEDIA_PACKAGE_CATEGORIES:
      return update(state, {
        mediaPackageCategories: { $set: initialState.mediaPackageCategories }
      })

    // Create media package category
    case CREATE_MEDIA_PACKAGE_CATEGORY:
      return update(state, {
        createMediaPackageCategory: { $merge: { isLoading: true } }
      })
    case CREATE_MEDIA_PACKAGE_CATEGORY_SUCCESS:
      return update(state, {
        createMediaPackageCategory: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        mediaPackageCategories: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_MEDIA_PACKAGE_CATEGORY_FAILURE:
      return update(state, {
        createMediaPackageCategory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MEDIA_PACKAGE_CATEGORY:
      return update(state, {
        createMediaPackageCategory: { $set: initialState.createMediaPackageCategory }
      })

    // Update media package category
    case UPDATE_MEDIA_PACKAGE_CATEGORY:
      return update(state, {
        updateMediaPackageCategory: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case UPDATE_MEDIA_PACKAGE_CATEGORY_SUCCESS:
      return update(state, {
        updateMediaPackageCategory: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        mediaPackageCategories: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case UPDATE_MEDIA_PACKAGE_CATEGORY_FAILURE:
      return update(state, {
        updateMediaPackageCategory: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        },
        mediaPackageCategories: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORY:
      return update(state, {
        updateMediaPackageCategory: { $set: initialState.updateMediaPackageCategory }
      })

    // Update media package categories order
    case UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER:
      return update(state, {
        updateMediaPackageCategoriesOrder: {
          $merge: {
            isLoading: true
          }
        },
        // we re-order data on FE, and just send actual BE re-ordering request on background
        mediaPackageCategories: {
          $merge: {
            results: action.data
          }
        }
      })
    case UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_SUCCESS:
      return update(state, {
        updateMediaPackageCategoriesOrder: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        }
      })
    case UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_FAILURE:
      return update(state, {
        updateMediaPackageCategoriesOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER:
      return update(state, {
        updateMediaPackageCategoriesOrder: { $set: initialState.updateMediaPackageCategoriesOrder }
      })

    // Get grouped media packages
    case GET_GROUPED_MEDIA_PACKAGES:
      return update(state, {
        groupedMediaPackages: { $merge: { isLoading: true } }
      })
    case GET_GROUPED_MEDIA_PACKAGES_SUCCESS:
      return update(state, {
        groupedMediaPackages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data
          }
        }
      })
    case GET_GROUPED_MEDIA_PACKAGES_FAILURE:
      return update(state, {
        groupedMediaPackages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GROUPED_MEDIA_PACKAGES:
      return update(state, {
        groupedMediaPackages: { $set: initialState.groupedMediaPackages }
      })

    // Get media packages
    case GET_MEDIA_PACKAGES:
      return update(state, {
        mediaPackages: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PACKAGES_SUCCESS:
      return update(state, {
        mediaPackages: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            results: action.data.results
          }
        }
      })
    case GET_MEDIA_PACKAGES_FAILURE:
      return update(state, {
        mediaPackages: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_MEDIA_PACKAGES:
      return update(state, {
        mediaPackages: { $set: initialState.mediaPackages }
      })

    // Create media package
    case CREATE_MEDIA_PACKAGE:
      return update(state, {
        createMediaPackage: { $merge: { isLoading: true } }
      })
    case CREATE_MEDIA_PACKAGE_SUCCESS:
      return update(state, {
        createMediaPackage: {
          $merge: {
            isLoading: false,
            wasCreated: true
          }
        },
        mediaPackages: {
          results: {
            $push: [action.data]
          }
        }
      })
    case CREATE_MEDIA_PACKAGE_FAILURE:
      return update(state, {
        createMediaPackage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_MEDIA_PACKAGE:
      return update(state, {
        createMediaPackage: { $set: initialState.createMediaPackage }
      })

    // Update media package
    case UPDATE_MEDIA_PACKAGE:
      return update(state, {
        updateMediaPackage: { $merge: { isLoading: true, id: action.id } }
      })
    case UPDATE_MEDIA_PACKAGE_SUCCESS:
      return update(state, {
        updateMediaPackage: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        mediaPackages: {
          results: {
            $apply: items => updateItem(items, action.data)
          }
        }
      })
    // separate case for bulk updates, which replaces all packages
    case UPDATE_MEDIA_PACKAGES_BULK_SUCCESS:
      return update(state, {
        updateMediaPackage: {
          $merge: {
            isLoading: false,
            wasUpdated: true
          }
        },
        mediaPackages: {
          results: { $set: action.data }
        }
      })
    case UPDATE_MEDIA_PACKAGE_FAILURE:
      return update(state, {
        updateMediaPackage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PACKAGE:
      return update(state, {
        updateMediaPackage: { $set: initialState.updateMediaPackage }
      })

    // Delete media package
    case DELETE_MEDIA_PACKAGE:
      return update(state, {
        deleteMediaPackage: {
          $merge: {
            id: action.id,
            isLoading: true
          }
        }
      })
    case DELETE_MEDIA_PACKAGE_SUCCESS:
      return update(state, {
        deleteMediaPackage: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: {
              id: action.id
            }
          }
        },

        mediaPackages: {
          results: {
            $apply: items => deleteItemById(items, action.id)
          }
        }
      })
    case DELETE_MEDIA_PACKAGE_FAILURE:
      return update(state, {
        deleteMediaPackage: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_MEDIA_PACKAGE:
      return update(state, {
        deleteMediaPackage: { $set: initialState.deleteMediaPackage }
      })
    // Update media packages order
    case UPDATE_MEDIA_PACKAGES_ORDER:
      return update(state, {
        updateMediaPackagesOrder: { $merge: { isLoading: true } },
        // we re-order data on FE, and just send actual BE re-ordering request on background
        mediaPackages: {
          $merge: {
            results: action.data
          }
        }
      })
    case UPDATE_MEDIA_PACKAGES_ORDER_SUCCESS:
      return update(state, {
        updateMediaPackagesOrder: {
          $merge: {
            isLoading: false
          }
        }
      })
    case UPDATE_MEDIA_PACKAGES_ORDER_FAILURE:
      return update(state, {
        updateMediaPackagesOrder: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_MEDIA_PACKAGES_ORDER:
      return update(state, {
        updateMediaPackagesOrder: { $set: initialState.updateMediaPackagesOrder }
      })

    // get media products as csv
    case GET_MEDIA_PACKAGES_AS_CSV:
      return update(state, {
        mediaPackagesAsCsv: { $merge: { isLoading: true } }
      })
    case GET_MEDIA_PACKAGES_AS_CSV_SUCCESS:
      return update(state, {
        mediaPackagesAsCsv: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data,
            fileName: action.fileName
          }
        }
      })
    case GET_MEDIA_PACKAGES_AS_CSV_FAILURE:
      return update(state, {
        mediaPackagesAsCsv: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_MEDIA_PACKAGES_AS_CSV:
      return update(state, {
        mediaPackagesAsCsv: { $set: initialState.mediaPackagesAsCsv }
      })

    default:
      return state
  }
}

import React, { cloneElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import MinimisedSectionNew from '../../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'

import { isStepFieldsValid } from '../../../Forms/StepForm/helpers'
import { keys } from '../../../../../helpers/common'

const MinimizedSectionField = ({
  formik,
  itemPath,
  index,
  headerClassName,
  headerTitleClassName,
  sectionHeaderClassName,
  deleteBtnText = 'Delete',
  titleLocalizationVariable = 'Item',
  handleTitleGenerate,
  children,
  selected,
  onSelect,
  onClose,
  onDelete,
  allowDelete
}) => {
  const { t } = useTranslation()

  const { values, errors, touched } = formik

  const itemValue = getIn(values, itemPath)
  const recipientIndexTitle = useMemo(() => {
    if (handleTitleGenerate) {
      return handleTitleGenerate(itemValue, index)
    } else {
      // allow to pass the translation variable
      return t(titleLocalizationVariable, { index: index + 1 })
    }
  }, [handleTitleGenerate, itemValue, index, t, titleLocalizationVariable])

  const sectionError = getIn(errors, itemPath)
  const sectionTouched = getIn(touched, itemPath)

  // determine if section has errors only by checking touched fields
  const isSectionValid = sectionTouched && sectionError ? isStepFieldsValid(sectionError, keys(sectionTouched)) : true

  const onClickHandler = useCallback(() => {
    if (selected) {
      onClose()
    } else {
      onSelect()
    }
  }, [selected, onSelect, onClose])

  const getComponentProps = children => {
    // populate component with props during the rendering
    return cloneElement(children, {
      key: index,
      itemPath,
      isActive: selected,
      formik
    })
  }

  return (
    <MinimisedSectionNew
      headerClassName={sectionHeaderClassName}
      headerContent={
        <MinimisedSectionHeader
          className={headerClassName}
          titleClassName={headerTitleClassName}
          title={recipientIndexTitle}
          onDelete={allowDelete ? onDelete : undefined}
          deleteBtnText={deleteBtnText ? deleteBtnText : t('Delete')}
        />
      }
      isOpened={selected}
      onHeaderClick={onClickHandler}
      hasError={!isSectionValid}
    >
      {getComponentProps(children)}
    </MinimisedSectionNew>
  )
}

MinimizedSectionField.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  headerTitleClassName: PropTypes.string,
  sectionHeaderClassName: PropTypes.string,
  // key for the localization of the title
  titleLocalizationVariable: PropTypes.string,
  handleTitleGenerate: PropTypes.func,
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
}

export default MinimizedSectionField

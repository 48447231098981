import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import DateRange from '../../../../../features/components/DateRange'

import useStyles from '../styles'

const FootfallDataDateRangeFilter = ({ dateRangeFilter, setDateRangeFilter }) => {
  const classes = useStyles()

  const onDateRangeChange = useCallback(
    rangeValues => {
      setDateRangeFilter({
        startDate: rangeValues.startDate,
        endDate: rangeValues.endDate
      })
    },
    [setDateRangeFilter]
  )

  return (
    <DateRange
      className={classes.select}
      placeholder="Dates"
      dateRangeData={dateRangeFilter}
      onDateRangeChange={onDateRangeChange}
      isSmall
      isSelectedValueBrandPrimary={true}
    />
  )
}

FootfallDataDateRangeFilter.propTypes = {
  dateRangeFilter: PropTypes.object.isRequired,
  setDateRangeFilter: PropTypes.func.isRequired
}

export default FootfallDataDateRangeFilter

import { useCallback, useState } from 'react'

import { DISAPPROVED_REASON } from '../../../../../../../features/components/Modals/AskReasonModal/fields'

const defaultFileInstancesModalData = {
  status: '',
  uploadedFile: {},
  [DISAPPROVED_REASON]: ''
}
export function useFileInstancesModal() {
  const [showFileInstancesModal, setShowFileInstancesModal] = useState(false)
  const [fileInstancesModalData, setFileInstancesModalData] = useState(defaultFileInstancesModalData)

  const handleCloseFileInstancesModal = useCallback(() => {
    setShowFileInstancesModal(false)
    setFileInstancesModalData(defaultFileInstancesModalData)
  }, [])

  const openFileInstancesModal = useCallback(data => {
    setShowFileInstancesModal(true)
    setFileInstancesModalData(data)
  }, [])

  return {
    handleCloseFileInstancesModal,
    showFileInstancesModal,
    fileInstancesModalData,
    openFileInstancesModal
  }
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import PeriodsDateRange from '../Reusable/PeriodsDateRange'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'
import SelectedPeriodsList from '../Reusable/SelectedPeriodsList'
import PeriodDateCell from '../../../../../../../../../../features/components/MediaOrder/PeriodDateCell'

import { SelectPeriodsContext } from '../../../../SelectPeriodsContext'
import { useSetPeriods } from '../Reusable/useSetPeriods'
import { useInventoryPeriods } from './hooks/useInventoryPeriods'
import { useGetDisabledDates } from './hooks/useGetDisabledDates'
import { useHandlePeriodSelection } from './hooks/useHandlePeriodSelection'

import calculateProductPrice from '../../../../helpers/calculateProductPrice'
import { formatDateToBE } from '../../../../../../../../../../constants/dates'
import { createJsonFromQueryString } from '../../../../../../../../../../helpers/url'
import { getEndAdjustedDate } from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { selectedControllerDataSelector } from '../../../../../../../../../../modules/selectors/app'
import { inventoryLastItemSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { QUANTITY } from '../../../../../../fields'
import { PERIOD_DAYS_QUANTITY } from '../../../../../../../../../../constants/mediaOrders'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../../fields'

import useStyles from './styles'

// This component allows to select booking period for the product using the date range
// Each of selected date range is represented as a group of periods, in meanwhile we still manage the selected periods separately
// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
function CustomDatePeriodSelector({
  formik,
  isNewProduct,
  selectedPeriods,
  productItemPath,
  productPublicationsDatesPath,
  checkInventory
}) {
  const classes = useStyles()
  const { values, setFieldValue, errors, touched } = formik

  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [isEndDateSelection, setIsEndDateSelection] = useState(false)

  const { allowToFetchPreviousPeriods, productPeriod, currencySymbol } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)
  const selectedController = useSelector(selectedControllerDataSelector)

  const daysInPeriod = PERIOD_DAYS_QUANTITY[productPeriod]
  const beforeAfterPercentage = selectedController?.before_percentage || 0
  const additionalAvailableDays = beforeAfterPercentage ? Math.round(daysInPeriod * beforeAfterPercentage) : 0

  const quantity = getIn(values, `${productItemPath}.${QUANTITY}`)
  const selectedGroupedPeriods = getIn(values, `${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`)

  const { periodOptions, firstPeriod, lastPeriod, availablePeriods } = useInventoryPeriods({
    selectedPeriods,
    additionalAvailableDays
  })

  const { disabledDates, datesStatuses } = useGetDisabledDates({
    // set selectedStartDate for isEndDateSelection, for correct isContinuousBroken determination for future dates
    firstPeriod: isEndDateSelection ? selectedStartDate : firstPeriod,
    lastPeriod,
    selectedPeriods,
    availablePeriods,
    isEndDateSelection,
    selectedStartDate,
    additionalAvailableDays
  })

  const handleClose = useCallback(() => {
    setSelectedStartDate(null)
    setIsEndDateSelection(false)
  }, [])

  const handleStartDateSelect = useCallback(startDate => {
    setSelectedStartDate(startDate)
    setIsEndDateSelection(true)
  }, [])

  const { setNewPeriods, handleRemovePeriodGroup } = useSetPeriods({
    isNewProduct,
    selectedPeriods,
    values,
    calculateProductPrice,
    setFieldValue,
    productItemPath,
    productPublicationsDatesPath
  })

  const handleDaysPeriodSelection = useHandlePeriodSelection({
    availablePeriods,
    additionalAvailableDays,
    setNewPeriods
  })

  const minDate = useMemo(() => {
    if (isEndDateSelection) {
      return selectedStartDate
    } else {
      return firstPeriod || new Date()
    }
  }, [firstPeriod, isEndDateSelection, selectedStartDate])
  const maxDate = useMemo(() => {
    const lastItemEndDate = getEndAdjustedDate(inventoryLastItem?.date_end, additionalAvailableDays)

    return lastItemEndDate || undefined
  }, [additionalAvailableDays, inventoryLastItem?.date_end])

  const CustomDayComponent = useCallback(
    dayDate => {
      const formattedDate = format(dayDate, 'dd')
      // if the date is not a period start date, it should be greyed out (although still selectable)
      // once the start date is selected, if it’s not period end date it should be greyed out and also selectable
      const dateStatus = datesStatuses?.[formatDateToBE(dayDate)]
      const isPseudoDisabled = isEndDateSelection
        ? !dateStatus?.isOriginalEndPeriod
        : !dateStatus?.isOriginalStartPeriod
      // for performance reason we pass only formatted dates as well as make the search for the periodData only inside
      // when the data actually changed
      return (
        <PeriodDateCell
          currencySymbol={currencySymbol}
          formattedDate={formattedDate}
          formattedDateBE={formatDateToBE(dayDate)}
          periodOptions={periodOptions}
          datesStatuses={datesStatuses}
          isPseudoDisabled={isPseudoDisabled}
        />
      )
    },
    [currencySymbol, periodOptions, datesStatuses, isEndDateSelection]
  )

  useEffect(() => {
    // ability to automate the period selection based on the inventory dates
    // mode=periodAutoSelect&inventoryFrom=2024-12-01&inventoryTo=2025-01-31&periodStart=2024-12-04&periodEnd=2025-01-29
    const { mode, periodStart, periodEnd } = createJsonFromQueryString(window.location.search)

    if (mode === 'periodAutoSelect' && availablePeriods?.length > 0 && selectedPeriods?.length === 0) {
      handleDaysPeriodSelection({
        startDate: periodStart,
        endDate: periodEnd
      })
    }
  }, [availablePeriods, handleDaysPeriodSelection, selectedPeriods?.length])

  const commonProps = useMemo(
    () => ({
      allowToFetchPreviousPeriods: allowToFetchPreviousPeriods,
      minDate: allowToFetchPreviousPeriods ? undefined : minDate,
      maxDate: maxDate,
      disabledDates: disabledDates,
      earliestPeriod: firstPeriod ? new Date(firstPeriod) : new Date(),
      additionalAvailableDays,
      // UI:
      periodSelectorClassName: classes.periodSelector,
      CustomDayComponent: CustomDayComponent,
      // callbacks
      onDateRangeChange: handleDaysPeriodSelection,
      onStartDateSelect: handleStartDateSelect,
      onClose: handleClose
    }),
    [
      allowToFetchPreviousPeriods,
      minDate,
      maxDate,
      disabledDates,
      firstPeriod,
      additionalAvailableDays,
      classes.periodSelector,
      CustomDayComponent,
      handleDaysPeriodSelection,
      handleStartDateSelect,
      handleClose
    ]
  )
  const error = getIn(errors, productPublicationsDatesPath)
  const wasTouched = getIn(touched, productPublicationsDatesPath)

  return (
    <>
      <SelectedPeriodsList
        handleRemovePeriodGroup={handleRemovePeriodGroup}
        selectedQuantity={quantity}
        selectedGroupedPeriods={selectedGroupedPeriods}
        checkInventory={checkInventory}
      />
      <PeriodsDateRange {...commonProps} />
      {/* selectedPeriods?.length < 1 is workaround for the validation which stuck. Remove all to see error add 1
       the error remains until the 2nd is added. If there are 2 and 1 removed there is no error */}
      {selectedPeriods?.length < 1 && wasTouched && error && <ErrorMessage error={error} />}
    </>
  )
}

CustomDatePeriodSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  isNewProduct: PropTypes.bool,
  productItemPath: PropTypes.string.isRequired,
  productPublicationsDatesPath: PropTypes.string.isRequired,
  selectedPeriods: PropTypes.array.isRequired
}
export default CustomDatePeriodSelector

import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../../components/Form/Field'

import { NAME } from '../../../../fields'

const MediaSubSubCategoryField = ({ formik, itemPath }) => {
  const { t } = useTranslation()

  const subSubCategoryNamePath = `${itemPath}.${NAME}`

  return <Field formik={formik} name={subSubCategoryNamePath} placeholder={t('Name')} />
}

export default MediaSubSubCategoryField

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ManageControllerForm from './ManageControllerForm'
import ManageControllerFormSkeleton from './ManageControllerForm/Skeleton'
import ManageDoohlyAPIKeyForm from './ManageDoohlyAPIKeyForm'
import ManageDoohlyAPIKeyFormSkeleton from './ManageDoohlyAPIKeyForm/Skeleton'
import { TabletDown, TabletUp } from '../../../components/hoc/ResponsiveRendering'

import { controllersSelector, controllersWasLoadedSelector } from '../../../modules/selectors/controller'

import { selectedControllerIdSelector } from '../../../modules/selectors/app'
import { doohlyDataWasLoadedSelector } from '../../../modules/selectors/doohly'
import { getControllers } from '../../../modules/actions/controller'
import { getDoohlyApiKey, clearGetDoohlyApiKey } from '../../../modules/actions/doohly'

import useSettingsClasses from '../styles'
import useFormWrapperStyles from '../../../styles/common/formWrapper'

const ManageController = () => {
  const { t } = useTranslation()
  const formWrapperClasses = useFormWrapperStyles()
  const dispatch = useDispatch()

  const settingsClasses = useSettingsClasses()

  const controllers = useSelector(controllersSelector)
  const controllersWasLoaded = useSelector(controllersWasLoadedSelector)
  const doohlyDataWasLoaded = useSelector(doohlyDataWasLoadedSelector)
  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const selectedController = controllers.find(controller => controller.id === selectedControllerId) || {}

  const controllerName = selectedController?.name

  useEffect(() => {
    dispatch(getControllers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getDoohlyApiKey({ controller_id: selectedControllerId, integration: 'doohly' }))
  }, [dispatch, selectedControllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetDoohlyApiKey())
    }
  }, [dispatch])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('controllerSettings', { controllerName })}</h1>
      <TabletUp>
        <ContentSection title="General Settings">
          <ContentRow
            title={t('controllerSettings', { controllerName })}
            description={t('generalSettingsForAllUsers', { controllerName })}
          >
            <div className={formWrapperClasses.formWrapper}>
              {controllersWasLoaded ? <ManageControllerForm /> : <ManageControllerFormSkeleton />}
            </div>
          </ContentRow>
          <ContentRow title={t('OOH Integration')} description={t('API key for digital screen CMS platform')}>
            <div className={formWrapperClasses.formWrapper}>
              {doohlyDataWasLoaded ? <ManageDoohlyAPIKeyForm /> : <ManageDoohlyAPIKeyFormSkeleton />}
            </div>
          </ContentRow>
        </ContentSection>
      </TabletUp>

      <TabletDown>
        <p>{t('generalSettingsForAllUsers', { controllerName })}</p>
        {controllersWasLoaded ? <ManageControllerForm /> : <ManageControllerFormSkeleton />}
        <p className={settingsClasses.textMarginTop}>{t('API key for digital screen CMS platform')}</p>
        {doohlyDataWasLoaded ? <ManageDoohlyAPIKeyForm /> : <ManageDoohlyAPIKeyFormSkeleton />}
      </TabletDown>
    </>
  )
}

export default ManageController

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import useRequestHandler from '../../../../../hooks/useRequestHandler'

import { clearUpdateContract, updateContract } from '../../../../../modules/actions/contracts'
import {
  contractWasUpdatedSelector,
  updateContractErrorSelector,
  updateContractIsLoadingSelector
} from '../../../../../modules/selectors/contracts'

export function useUpdateContractForApprove({ contractId, updateData, onSuccessSubmit }) {
  const dispatch = useDispatch()

  const [requirementsUpdateProcess, setRequirementsUpdateProcess] = useState(false)

  const handleUpdateContract = useCallback(
    data => {
      setRequirementsUpdateProcess(true)
      dispatch(
        updateContract(
          contractId,
          {
            ...updateData,
            ...data
          },
          {
            requestMethod: 'PATCH'
          }
        )
      )
    },
    [contractId, dispatch, updateData]
  )

  const clearUpdateContractHandler = useCallback(() => {
    setRequirementsUpdateProcess(false)
    dispatch(clearUpdateContract())
  }, [dispatch])

  const { isLoading, requestSuccess, requestError } = useRequestHandler({
    clearHandler: clearUpdateContractHandler,
    onSuccessSubmit,
    isLoadingRequestSelector: requirementsUpdateProcess ? updateContractIsLoadingSelector : undefined,
    successRequestSelector: requirementsUpdateProcess ? contractWasUpdatedSelector : undefined,
    errorRequestSelector: requirementsUpdateProcess ? updateContractErrorSelector : undefined
  })

  return {
    isLoading,
    requestSuccess,
    requestError,
    handleUpdateContract
  }
}

import { createStyles } from '../../../../../../../../styles/helpers'

import { darkGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  locationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal'
  },
  location: {
    fontSize: 12
  },
  subLocation: {
    fontSize: 12,
    color: darkGrey,
    marginTop: 6
  }
})

export default useStyles

import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderContext } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import {
  getCommonProposalManageData,
  transformValuesToBE
} from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/formatters'

import {
  clearCreateContract,
  createContract,
  updateContract,
  clearUpdateContract
} from '../../../../modules/actions/contracts'

import {
  activeContractSelector,
  contractWasCreatedSelector,
  createContractsErrorSelector,
  createContractsIsLoadingSelector,
  contractWasUpdatedSelector,
  updateContractIsLoadingSelector,
  updateContractErrorSelector,
  contractBuilderCalculatedDiscountCostSelector
} from '../../../../modules/selectors/contracts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { useShouldCheckApproval } from '../ProposalBookOrSend/ProposalMediaOrderGenerate/useShouldCheckApproval'

export function useHandleSubmitForm() {
  const dispatch = useDispatch()

  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id
  const proposalWasSent = activeContract?.quotation_sent

  // if contract was created during creation or loaded for editing the quotation, we are in edit mode
  const [isEditMode, setEditMode] = useState(Boolean(createdContractId))
  const controllerId = useSelector(selectedControllerIdSelector)
  const contractWasCreated = useSelector(contractWasCreatedSelector)
  const contractWasUpdated = useSelector(contractWasUpdatedSelector)
  const calculatedDiscountCost = useSelector(contractBuilderCalculatedDiscountCostSelector)

  const { editProductId } = useContext(ContractBuilderContext) // pass product id within the update request to control the product update
  const { contextSelfAccountData, currency } = useContext(MediaOrderFormContext)

  const contractStatus = activeContract?.status
  const { hasProposalApprovalFlow, isPackageApprover } = useShouldCheckApproval(contractStatus)
  const contractCostWithDiscount = activeContract?.media_order.cost_with_discount

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        selfAccountData: contextSelfAccountData,
        controllerId,
        selectedCurrency: currency,
        values
      })

      const commonProposalData = getCommonProposalManageData({
        contextSelfAccountData,
        controllerId,
        hasProposalApprovalFlow,
        isPackageApprover,
        contractStatus,
        oldCostWithDiscount: contractCostWithDiscount,
        transformedData,
        calculatedDiscountCost,
        proposalWasSent
      })

      const contractData = {
        detail: transformedData,
        ...commonProposalData
      }

      if (createdContractId) {
        // don't pass the hardcoded without_requirements flag for update, beacuse of case:
        // contract was send and the without_requirements set to false, if we update it back to true, then user will not be able to sign it
        dispatch(
          updateContract(createdContractId, contractData, {
            // pass the product id to know which product was updated in latest update
            editProductId
          })
        )
      } else {
        dispatch(
          createContract({
            ...contractData,
            without_requirements: true // allows to create media order without sign and don't start expiration process
          })
        )
      }
    },
    [
      contextSelfAccountData,
      controllerId,
      currency,
      hasProposalApprovalFlow,
      isPackageApprover,
      contractStatus,
      contractCostWithDiscount,
      calculatedDiscountCost,
      proposalWasSent,
      createdContractId,
      dispatch,
      editProductId
    ]
  )

  const clearCreateHandler = useCallback(() => dispatch(clearCreateContract()), [dispatch])
  const clearUpdateHandler = useCallback(() => dispatch(clearUpdateContract()), [dispatch])

  useEffect(() => {
    return () => {
      // stop handling success if page is left during update/create contract
      // if not cancel, then it will save data in redux which will not be cleared
      // and in places with handling success on update/create contract form it will trigger success submit action
      clearCreateHandler()
      clearUpdateHandler()
    }
  }, [dispatch, clearCreateHandler, clearUpdateHandler])

  return {
    onSubmit,
    setEditMode,
    clearSubmitHandler: isEditMode ? clearUpdateHandler : clearCreateHandler,
    successSelector: isEditMode ? contractWasUpdatedSelector : contractWasCreatedSelector,
    successSubmit: isEditMode ? contractWasUpdated : contractWasCreated,
    errorSelector: isEditMode ? updateContractErrorSelector : createContractsErrorSelector,
    isLoadingSelector: isEditMode ? updateContractIsLoadingSelector : createContractsIsLoadingSelector
  }
}

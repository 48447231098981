import { useState, useEffect } from 'react'

const useBlobUrl = file => {
  const [blobUrl, setBlobUrl] = useState(null)

  useEffect(() => {
    if (!file || Object.keys(file).length === 0) {
      setBlobUrl('')
      return
    }

    // Create the blob URL
    const url = URL.createObjectURL(file)
    setBlobUrl(url)

    // Cleanup: revoke the blob URL when the file changes or component unmounts
    return () => {
      URL.revokeObjectURL(url)
    }
  }, [file])

  return blobUrl
}

export default useBlobUrl

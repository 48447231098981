import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import ActionText from '../../../../../../../components/ActionText'

import { useClearMediaOrderForm } from '../../../../ContractMediaOrderGenerate/hooks/useClearMediaOrderForm'
import { clearGenerateMediaOrder, generateMediaOrder } from '../../../../../../../modules/actions/mediaOrders'

import {
  generateMediaOrderErrorSelector,
  generateMediaOrderIsLoadingSelector,
  mediaOrderWasGeneratedSelector
} from '../../../../../../../modules/selectors/mediaOrders'
import useStyles from '../styles'

function CreateNoTermsMediaOrder({ contractId, onSuccessSubmit }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // in case of the media order creation without contract we should use the createContract endpoint

  const mediaOrderWasGenerated = useSelector(mediaOrderWasGeneratedSelector)
  const generateMediaOrderError = useSelector(generateMediaOrderErrorSelector)
  const generateMediaOrderIsLoading = useSelector(generateMediaOrderIsLoadingSelector)

  const handleCreateMediaOrder = useCallback(() => {
    // the contract with without_requirements creates the media order without need of sign or approve
    // so even though the contract is creating, the media order is created behind the scenes.

    dispatch(
      generateMediaOrder(
        contractId,
        {},
        {
          without_requirements: true // when confirming and book, the media order should be generated without sign
        }
      )
    )
  }, [dispatch, contractId])

  const handleSubmitFormClear = useClearMediaOrderForm()

  useEffect(() => {
    if (mediaOrderWasGenerated) {
      handleSubmitFormClear()
      onSuccessSubmit()
    }
  }, [mediaOrderWasGenerated, handleSubmitFormClear, onSuccessSubmit])

  useEffect(() => {
    if (generateMediaOrderError) {
      dispatch(clearGenerateMediaOrder())
    }
  }, [dispatch, generateMediaOrderError])

  return generateMediaOrderIsLoading ? (
    <div className={classes.createMediaOrderAction}>
      <Skeleton width="100px" />
    </div>
  ) : (
    <ActionText onClick={handleCreateMediaOrder} className={classes.createMediaOrderAction} isBold>
      {t('Create without contract')}
    </ActionText>
  )
}

CreateNoTermsMediaOrder.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func.isRequired
}

export default CreateNoTermsMediaOrder

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ReachValue from '../ReachItem'

import {
  activeContractSelector,
  updatedContractProductIdsSelector
} from '../../../../../../../../../../modules/selectors/contracts'

import useStyles from '../styles'

const ReachTotalEstimated = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const activeContract = useSelector(activeContractSelector)
  const updatingProductIds = useSelector(updatedContractProductIdsSelector)
  const mediaOrder = activeContract.media_order

  // the estimated data is making on FE after the product creation/update, so we need to represent loading during update
  const isProductUpdating = updatingProductIds.length > 0

  const reachEstimated = mediaOrder.total_estimated_reach
  const impressionsEstimated = mediaOrder.total_estimated_impressions
  const frequencyEstimated = mediaOrder.total_estimated_frequency

  return (
    <div className={classes.reachContainer}>
      <div className={classes.titles}>
        <div>{t('Forecast Reach')}:</div>
        <div>{t('Forecast Impressions')}:</div>
        <div>{t('Forecast Frequency')}:</div>
      </div>
      <div className={classes.values}>
        <ReachValue value={reachEstimated} isLoading={isProductUpdating} />
        <ReachValue value={impressionsEstimated} isLoading={isProductUpdating} />
        <ReachValue value={frequencyEstimated} isLoading={isProductUpdating} skeletonWidth={30} />
      </div>
    </div>
  )
}

export default ReachTotalEstimated

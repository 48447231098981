import React from 'react'

import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'

import { MAX_PERIODS, MIN_PERIODS } from '../../fields'

const MinAndMaxPeriodsFields = ({ formik }) => {
  return (
    <>
      <FieldRow
        title="Minimum Periods (Optional)"
        description="A minimum number of periods this product must be booked for."
      >
        <Field formik={formik} name={MIN_PERIODS} type="number" placeholder="Quantity" autoComplete="off" />
      </FieldRow>
      <FieldRow
        title="Maximum Periods (Optional)"
        description="A maximum number of periods this product must be booked for."
      >
        <Field formik={formik} name={MAX_PERIODS} type="number" placeholder="Quantity" autoComplete="off" />
      </FieldRow>
    </>
  )
}

export default MinAndMaxPeriodsFields

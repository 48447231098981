import {
  LOCATION,
  MEDIA_SUB_CATEGORY,
  MEDIA_SUB_SUB_CATEGORY
} from '../../../../MediaProducts/MediaProductForms/fields'
import { MEDIA_CATEGORY } from '../../../../MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export const getVariablesToBeAsked = (variables = [], products = []) => {
  // Get variables that don't have any media product attributes.
  // If variable has no media_product_attribute exists, these will be shown immediately.
  const variablesWithoutMediaAttributes = variables.filter(
    variable => !variable.media_product_attribute?.values?.length
  )

  // Get variables that have media product attributes.
  // These fields will only be shown once a matching media product is added to the proposal.
  const variablesWithMediaAttributes = variables.filter(variable => variable.media_product_attribute?.values?.length)

  // object with keys of media attributes and values of sets of attribute values.
  const groupedProductsMediaAttributesValues = getGroupedProductsMediaAttributesValues(products)

  // Combine always-shown variables with those that have a matching media attribute.
  return [
    ...variablesWithoutMediaAttributes,
    ...variablesWithMediaAttributes.filter(variable => {
      const variableAttribute = variable.media_product_attribute.attribute
      const variableAttributeValues = variable.media_product_attribute.values

      // Check if any of the variable's attribute values match the media product's attribute values.
      return variableAttributeValues.some(value => {
        return groupedProductsMediaAttributesValues[variableAttribute]?.has(value)
      })
    })
  ]
}

export const getGroupedProductsMediaAttributesValues = products => {
  const mediaAttributes = [LOCATION, MEDIA_SUB_SUB_CATEGORY, MEDIA_SUB_CATEGORY, MEDIA_CATEGORY]

  // Filter products that have at least one of the defined media attributes in their data.
  const productsWithMediaAttribute = products.filter(product => mediaAttributes.some(key => product.data?.[key]))

  const groupedProductsMediaAttributesValues = productsWithMediaAttribute.reduce((acc, product) => {
    mediaAttributes.forEach(key => {
      if (product.data?.[key]) {
        if (!acc[key]) {
          acc[key] = new Set()
        }

        if (key === LOCATION) {
          // location is an object, we need to get an id
          acc[key].add(String(product.data[key].id))
        } else {
          acc[key].add(String(product.data[key]))
        }
      }
    })
    return acc
  }, {})

  return groupedProductsMediaAttributesValues
}

import { formatDateToBE } from '../../../../../constants/dates'

import {
  ACCESS_ACCOUNTS,
  AVAILABILITY_DATE_END,
  AVAILABILITY_DATE_START,
  CATEGORY,
  DATE_END,
  DATE_START,
  MEDIA_CATEGORIES,
  MEDIA_PRODUCTS,
  NAME,
  SUBTEXT,
  TAGS
} from '../../fields'
import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../ReusableFormFields/PricePairFields/fields'
import { PRODUCTS_FILTER } from '../../MediaPackageCreate/MediaPackageCreateForm/fields'
import { PHYSICAL_SPACES } from '../../../../../constants/mediaOrders'

import { LOCATION } from '../../../MediaProducts/MediaProductForms/fields'
import { FILE_NAME, FILE_URL } from '../../../../../constants/files'

export const transformValuesToBE = ({ values, isPackageWithMediaCategories }) => {
  return {
    [NAME]: values[NAME],
    [CATEGORY]: values[CATEGORY],
    [AVAILABILITY_DATE_START]: formatDateToBE(values[AVAILABILITY_DATE_START]),
    [AVAILABILITY_DATE_END]: formatDateToBE(values[AVAILABILITY_DATE_END]),
    ...(values[DATE_START] && { [DATE_START]: formatDateToBE(values[DATE_START]) }),
    ...(values[DATE_END] && { [DATE_END]: formatDateToBE(values[DATE_END]) }),
    [SUBTEXT]: values[SUBTEXT],
    image: values[FILE_URL],
    [FILE_NAME]: values[FILE_NAME],
    ...(isPackageWithMediaCategories
      ? { [MEDIA_CATEGORIES]: values[MEDIA_CATEGORIES].map(item => item.value) }
      : { [MEDIA_PRODUCTS]: values[MEDIA_PRODUCTS].map(item => item.value) }),
    prices: values[PRICE_PAIRS].map(pricePair => ({
      price: pricePair[PRICE_VALUE],
      currency: pricePair[PRICE_CURRENCY]
    })),
    [TAGS]: values[TAGS]?.map(tag => tag?.value),
    [ACCESS_ACCOUNTS]: values[ACCESS_ACCOUNTS]?.map(member => member?.id),
    [PRODUCTS_FILTER]: { [PHYSICAL_SPACES]: { location: values[LOCATION] } }
  }
}

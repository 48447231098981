import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'

import TableRows from './TableRows'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import ConditionalWrapper from '../hoc/ConditionalWrapper'
import DragAndDropWrapper from '../../features/components/DragAndDropWrapper'

import { useTableCopy } from './useTableCopy'

import { TABLE_CLASSNAME } from '../../constants/pdf'
import { SKIP_CELL_FOR_COPY } from './constants'

import { phonesDownSize } from '../../styles/const/breakpoints'

import useStyles from './styles'

const Table = React.memo(
  ({
    cols,
    formatCopyCells,
    rowTemplate,
    data,
    initialTanstackState,
    tableSort,
    enableSorting,
    stickyHeader,
    hideHeadlineRow,
    hideFooterRow,
    SubHeadlineRow,
    itemUpdatingId,
    updatingIds,
    footerClassName,
    Footer,
    isBigRow,
    onRowDrag,
    customColumns,
    tableId,
    className,
    mobileColumnsWrapperClassName,
    mobileMinimisedSectionBtnWrapperClassName,
    rowLoadingIds,
    expandableRowsOpenStatesContext
  }) => {
    useTableCopy({
      data,
      formatCopyCells,
      tableId
    })

    const columns = useMemo(() => {
      // there is issue when column doesn't have header of the string type - the useReactTable fails
      // workaround to support old structure of columns within the implementation and integration of the tansctack table
      // ideally each column definition in our app should have a header or id instead of it.
      // the cons having this workaround is generating new id each time when this hook is called, and may lead to unnceseary re-renders
      // todo refactor when add id to all columns across the app

      return cols.map(col => {
        if (typeof col.header === 'string') {
          return col
        } else {
          // Columns require an id when using a non-string header
          return {
            ...col,

            id: col.fieldKey || uuidv4()
          }
        }
      })
    }, [cols])

    const tanstackTable = useReactTable({
      columns: columns,
      data,
      enableSorting,
      initialState: initialTanstackState,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel()
    })

    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
    const hasMobileHiddenColumns = useMemo(() => {
      return isMobile && cols.some(({ showOnMobile }) => showOnMobile === false)
    }, [cols, isMobile])

    const isSupportExpandableRow = rowTemplate?.params?.isExpandable
    const hasExpandableRow = useMemo(() => {
      // check through all data rows if there is at least one expandable row when the row params support it
      if (isSupportExpandableRow) {
        return data.some(rowData => isSupportExpandableRow(rowData))
      } else {
        return false
      }
    }, [data, isSupportExpandableRow])
    const classes = useStyles({ isBigRow, isMobile, hasMobileHiddenColumns, stickyHeader })

    const showHeadline = !hideHeadlineRow && !isMobile

    return (
      <div className={classnames(classes[TABLE_CLASSNAME], className)} id={tableId}>
        {/* first row is headline with columns */}
        {showHeadline && (
          <TableHeader
            cols={cols}
            tanstackHeaderGroup={tanstackTable.getHeaderGroups()?.[0]}
            rowTemplate={rowTemplate}
            tableSort={tableSort}
            stickyHeader={stickyHeader}
            // triggers re-rendering on sorting change, because we need to update the sort icon
            tanstackSort={enableSorting && tanstackTable.getState().sorting}
            isBigRow={isBigRow}
            hasMobileHiddenColumns={hasMobileHiddenColumns}
            hasExpandableRow={hasExpandableRow}
          />
        )}
        {/*In some cases we need to additional row below headline row. E.g. this is used in LineItemsBreakdown component*/}
        {SubHeadlineRow && SubHeadlineRow}
        <ConditionalWrapper
          condition={!!onRowDrag}
          wrapper={children => (
            <DragAndDropWrapper onDragEnd={onRowDrag} droppableId={tableId}>
              {children}
            </DragAndDropWrapper>
          )}
        >
          <TableRows
            colsTemplate={cols}
            rowTemplate={rowTemplate}
            data={tanstackTable.getRowModel().rows}
            itemUpdatingId={itemUpdatingId}
            updatingIds={updatingIds}
            isBigRow={isBigRow}
            onRowDrag={onRowDrag}
            customColumns={customColumns}
            hasMobileHiddenColumns={hasMobileHiddenColumns}
            mobileColumnsWrapperClassName={mobileColumnsWrapperClassName}
            mobileMinimisedSectionBtnWrapperClassName={mobileMinimisedSectionBtnWrapperClassName}
            hasExpandRowSpacing={hasExpandableRow}
            rowLoadingIds={rowLoadingIds}
            expandableRowsOpenStatesContext={expandableRowsOpenStatesContext}
          />
        </ConditionalWrapper>
        {!hideFooterRow && (
          <TableFooter
            cols={cols}
            isBigRow={isBigRow}
            Footer={Footer}
            // when we have drag and drop functionality we need to show top border for footer
            // that is because last row will not have border
            showTopBorder={Boolean(onRowDrag)}
            footerClassName={footerClassName}
          />
        )}
      </div>
    )
  }
)

const colsPropTypeShape = {
  showOnMobile: PropTypes.bool,
  attributes: PropTypes.shape({
    // allow to add HTML elements attributes to cell
    [SKIP_CELL_FOR_COPY]: PropTypes.bool // allow to skip cell for copy
  }),
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // for sorting both are needed:
  sortParameter: PropTypes.string,
  onSortingChange: PropTypes.func,
  sortDescFirst: PropTypes.bool, //sort for column in descending order first (default is ascending)
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  initialTanstackState: PropTypes.object,
  // allows to format columns and rows for copy to clipboard
  formatCopyCells: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      // one of the following is required - formatCellContent or renderedDataAttribute/formatRenderedContent
      formatCellContent: (props, propName, componentName) => {
        if (!props[propName] && !props.renderedDataAttribute && !props.formatRenderedContent) {
          return new Error(
            `One of \`formatCellContent\`, \`renderedDataAttribute\`, or \`formatRenderedContent\` is required in \`${componentName}\`.`
          )
        }
        if (props[propName] && typeof props[propName] !== 'function') {
          return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected a function.`)
        }
      },
      renderedDataAttribute: PropTypes.string, // allows to get content from the rendered table cell
      formatRenderedContent: PropTypes.func // allows to get and format content from the rendered table cell
    })
  ),
  cols: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)),
  tableSort: PropTypes.object,
  rowTemplate: PropTypes.shape({
    params: PropTypes.shape({
      isExpandable: PropTypes.func,
      expandedContent: PropTypes.func
    }),
    markerColor: PropTypes.string,
    determineRowMarker: PropTypes.func,
    rowClassName: PropTypes.string,
    headlineRowClassName: PropTypes.string,
    getRowClassName: PropTypes.func,
    getMobileRowClassName: PropTypes.func,
    columnClassName: PropTypes.string
  }),
  stickyHeader: PropTypes.bool, // allows to make header sticky, disable horizontal scroll
  hideHeadlineRow: PropTypes.bool,
  hideFooterRow: PropTypes.bool,
  SubHeadlineRow: PropTypes.node,
  // is an object of available custom columns layouts
  // allows to render row with custom columns if rowData has key of customColumns
  customColumns: PropTypes.object,
  tableId: PropTypes.string,
  // updating, deleting and etc..
  onRowDrag: PropTypes.func, // allows to change rows order - adds drag and drop functionality
  itemUpdatingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBigRow: PropTypes.bool,
  footerClassName: PropTypes.string,
  Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  mobileColumnsWrapperClassName: PropTypes.string,
  mobileMinimisedSectionBtnWrapperClassName: PropTypes.string,
  rowLoadingIds: PropTypes.array
}

export default Table

import * as Yup from 'yup'

import { BRAND, BUYER, CAMPAIGN_NAME, MEDIA_PRODUCTS, VARIABLES } from './fields'
import { productPublicationDatesValidation } from '../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/validation'

import {
  CAN_SELECT_MULTIPLE,
  REQUIRED
} from '../../../../pages/Settings/BookingEmailAttachments/BookingVariables/forms/fields'

// internal constant to trigger additional fields validation for media order, used for Send/Book buttons or Amendments for now
// during the regular proposal create/update we don't need to validate additional fields

export const VALIDATE_ADDITIONAL_FIELDS = 'validate_additional_fields'
export const getValidationSchema = ({ askBrandName, askCampaignName, isBrandRequired, askBuyer, askVariables }) => {
  return Yup.object({
    [MEDIA_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
    ...getAdditionalOrderInfoValidation({ askBrandName, askCampaignName, isBrandRequired, askBuyer, askVariables })
  })
}

// we don't check additional media order validation during contract creation/update
// we check it only in case of validateAdditionalFields triggered
const getAdditionalOrderInfoValidation = ({
  askBrandName,
  askCampaignName,
  isBrandRequired,
  askBuyer,
  askVariables
}) => {
  return {
    ...(askCampaignName && {
      [CAMPAIGN_NAME]: Yup.string().when(VALIDATE_ADDITIONAL_FIELDS, {
        is: validateAdditionalFields => {
          return validateAdditionalFields === true && askCampaignName
        },
        then: () => Yup.string().required('Please enter a campaign name')
      })
    }),
    ...(askBrandName && {
      [BRAND]: Yup.string().when(VALIDATE_ADDITIONAL_FIELDS, {
        is: true,
        then: () => (isBrandRequired ? Yup.string().required('Please select the brand') : Yup.string())
      })
    }),
    ...(askBuyer && {
      [BUYER]: Yup.object().shape({
        value: Yup.number().when(VALIDATE_ADDITIONAL_FIELDS, {
          is: true,
          then: () => Yup.number().required('Please select the buyer')
        })
      })
    }),
    ...(askVariables && {
      [VARIABLES]: Yup.array().when(VALIDATE_ADDITIONAL_FIELDS, {
        is: true,
        then: () =>
          Yup.array().of(
            Yup.object().shape({
              value: Yup.mixed()
                .nullable()
                .test(
                  'options-check',
                  '', // we'll provide custom messages via createError
                  function (value) {
                    // Access sibling fields: required and can_select_multiple.
                    const variable = this.parent
                    const { [REQUIRED]: required, [CAN_SELECT_MULTIPLE]: canSelectMultiple } = variable.data

                    if (!required) {
                      return true
                    }

                    if (canSelectMultiple === true) {
                      // If the variable is marked as required, the array must not be empty.
                      if (value?.length === 0) {
                        return this.createError({
                          path: `${this.path}`,
                          message: 'Please select at least one option'
                        })
                      }
                    } else {
                      // When only a single option is allowed, value should be an object with an "id" property.
                      if (!value?.id) {
                        return this.createError({
                          path: `${this.path}`,
                          message: 'Please select an option'
                        })
                      }
                    }
                    return true
                  }
                )
            })
          )
      })
    })
  }
}

export const getAdditionalOrderInfoValidationSchema = params => {
  return Yup.object(getAdditionalOrderInfoValidation(params))
}

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

import NameSection from '../../../sections/NameSection'
import DescriptionSection from '../../../sections/DescriptionSection'
import MediaCategorySection from '../../../sections/MediaCategorySection'
import PeriodSection from '../../../sections/PeriodSection'
import LocationSection from '../../../sections/LocationSection'
import SubProductsField from '../../../../MediaProductGroupCreate/MediaProductGroupCreateForm/Steps/DetailsStep/SubProductsField'
import ImagesSection from '../../../sections/ImagesSection'
import TagsSection from '../../../MediaProductEdit/MediaProductEditForm/TagsSection'
import VariablesSection from '../../../sections/VariablesSection'
import InternalIdSection from '../../../sections/InternalIdSection'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const MediaProductGroupEditFormContent = ({ formik, editProductData, formattedSubProductsOptions }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <>
      <NameSection formik={formik} />
      <DescriptionSection formik={formik} />
      <MediaCategorySection formik={formik} MediaCategorySection={false} />
      <PeriodSection formik={formik} />
      <LocationSection formik={formik} editProductData={editProductData} />

      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Media Products')}</h3>
        <SubProductsField formik={formik} formattedSubProductsOptions={formattedSubProductsOptions} />
      </section>

      <ImagesSection formik={formik} />
      <TagsSection formik={formik} />
      <VariablesSection formik={formik} />
      <InternalIdSection formik={formik} />
    </>
  )
}

MediaProductGroupEditFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  editProductData: PropTypes.object,
  formattedSubProductsOptions: PropTypes.array
}

export default MediaProductGroupEditFormContent

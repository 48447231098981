import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ProgressButton from '../../../../../../../components/Form/ProgressButton'

import useRequestHandler from '../../../../../../../hooks/useRequestHandler'

import { showToasts } from '../../../../../../../helpers/toasts'
import { clearResendQuotation, resendQuotation } from '../../../../../../../modules/actions/quotations'

import {
  resendQuotationErrorSelector,
  resendQuotationIsLoadingSelector,
  resendQuotationWasResentSelector
} from '../../../../../../../modules/selectors/quotations'

import { TOAST_TYPE } from '../../../../../../../constants/other'

import useStyles from './styles'

function ResendProposal({ proposalId }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const resendQuotationHandler = useCallback(() => {
    dispatch(resendQuotation(proposalId))
  }, [dispatch, proposalId])

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearResendQuotation())
  }, [dispatch])

  const handleSuccessSubmit = useCallback(() => {
    showToasts({
      type: TOAST_TYPE.success,
      message: t('The proposal has been resent')
    })
  }, [t])

  const { isLoading, requestSuccess, requestError } = useRequestHandler({
    clearHandler: clearSubmitHandler,
    onSuccessSubmit: handleSuccessSubmit,
    isLoadingRequestSelector: resendQuotationIsLoadingSelector,
    successRequestSelector: resendQuotationWasResentSelector,
    errorRequestSelector: resendQuotationErrorSelector
  })

  return (
    <ProgressButton
      solid={false}
      onClick={resendQuotationHandler}
      wrapperClassName={classes.resendBtn}
      // processing
      isFormLoading={isLoading}
      successSubmit={requestSuccess}
      formError={requestError}
    >
      {t('Resend')}
    </ProgressButton>
  )
}

ResendProposal.propTypes = {
  proposalId: PropTypes.number
}

export default ResendProposal

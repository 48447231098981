import { createStyles } from '../../../../../styles/helpers'

const useStyles = createStyles({
  rightSide: {
    minWidth: 240
  },
  bookingSummaryNotificationRow: {
    minHeight: 150,
    '&:not(:last-child)': {
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  bookingSummaryNotification: {
    '& > *': {
      marginBottom: 4
    }
  },
  subLocationsSpoilerTitle: {
    margin: '20px 0'
  }
})

export default useStyles

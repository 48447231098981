const internalColumns = ['Store', 'Date', 'Reach', 'Period type']
const externalColumns = ['Area', 'Date', 'Reach', 'Period type']

const allowedHeaderSets = [internalColumns, externalColumns]

export const csvFileValidationHandler = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => {
      const content = event.target.result
      // Split into rows, handling various newline characters
      const rows = content.split(/\r\n|\n/)
      if (rows.length === 0 || !rows[0].trim()) {
        reject(new Error('CSV file is empty or missing its header row.'))
        return
      }

      // Get headers from the first row and trim whitespace
      const headers = rows[0].split(',').map(h => h.trim())

      // Check if header row includes all required columns for at least one allowed set
      const isValid = allowedHeaderSets.some(requiredSet =>
        requiredSet.every(requiredHeader => headers.map(h => h.toLowerCase()).includes(requiredHeader.toLowerCase()))
      )

      if (!isValid) {
        reject(new Error('The column headers in this file do not match the template.'))
      } else {
        resolve()
      }
    }

    reader.onerror = () => {
      reject(new Error('There was an error reading the CSV file.'))
    }

    reader.readAsText(file)
  })
}

export const getFootfallDataFileUploadErrorMessage = error => {
  const defaultMessage = 'An error occurred while uploading footfall data'

  if (!error || typeof error !== 'object') {
    return defaultMessage
  }

  // If the error has a direct string property (e.g. error.errors is a string)
  if (typeof error.errors === 'string') {
    return error.errors
  }

  // If errors is an object, check common keys
  if (error.errors && typeof error.errors === 'object') {
    // Check if errors.file exists and is an array with at least one element.
    if (Array.isArray(error.errors.file) && error.errors.file.length > 0) {
      return error.errors.file[0]
    }

    // Check if errors.error exists and is a string.
    if (typeof error.errors.error === 'string') {
      return error.errors.error
    }
  }

  // Fallback: if error has a message property at the root level, use that.
  if (error.message && typeof error.message === 'string') {
    return error.message
  }

  // Return default error message if nothing else found.
  return defaultMessage
}

import React from 'react'
import { useSelector } from 'react-redux'

import CreatedContract from './CreatedContract'
import CreateNoTermsMediaOrder from './CreateNoTermsMediaOrder'
import Can from '../../../../../../features/components/Can'
import DrawerHeadline from '../../../../../../components/Drawer/DrawerHeadline'
import BackButton from '../../../../../../features/components/Forms/StepForm/BackButton'

import { useRedirectToGeneratedMediaOrder } from '../../../ContractMediaOrderGenerate/hooks/useRedirectToGeneratedMediaOrder'

import { activeContractSelector } from '../../../../../../modules/selectors/contracts'

import { CONTRACT_SIGNED_STATUS } from '../../../../../../constants/contacts'
import { CREATE_DIRECT_MEDIA_ORDER } from '../../../../../../constants/permissions'

import useStyles from './styles'

function MediaOrderGenerateForm({ onStepBack }) {
  const classes = useStyles()

  const activeContract = useSelector(activeContractSelector)
  const contractId = activeContract?.id
  const isContractSigned = activeContract?.status === CONTRACT_SIGNED_STATUS
  const redirectToGeneratedMediaOrder = useRedirectToGeneratedMediaOrder()

  const allowCreateMediaOrder = !isContractSigned

  return (
    <>
      <DrawerHeadline
        title="Review, sign and book"
        description="Please review, sign and submit the booking contract."
      />
      <CreatedContract onSuccessSubmit={redirectToGeneratedMediaOrder} isContractSigned={isContractSigned} />
      <div className={classes.actionsContainer}>
        <Can I="create" a={CREATE_DIRECT_MEDIA_ORDER}>
          {/* When the contract is in unsigned state, Only controller admin or manager users can see this*/}
          {allowCreateMediaOrder && (
            <CreateNoTermsMediaOrder contractId={contractId} onSuccessSubmit={redirectToGeneratedMediaOrder} />
          )}
        </Can>
        <BackButton onStepBack={onStepBack} showButton />
      </div>
    </>
  )
}

export default MediaOrderGenerateForm

import React, { useCallback } from 'react'

import ContentRow from '../../../../../../../features/components/ContentSection/ContentRow'

import MultipleFilesUpload from './MultipleFilesUpload'
import VariableOptionsList from './VariableOptionsList'

import { FILE_NAME, FILE_URL, OPTION_NAME, OPTIONS } from '../../fields'

import useVariablesFormContentStyles from '../styles'

const uploadOptions = {
  isPrivate: true
}

const VariableOptionsContentRow = ({ formik }) => {
  const variablesFormContentClasses = useVariablesFormContentStyles()

  const { setFieldValue } = formik

  const onFileUploadedHandler = useCallback(
    fileData => {
      const fileId = fileData.file.id

      setFieldValue(`${OPTIONS}.${fileData.file.id}`, {
        id: fileId,
        [FILE_NAME]: fileData.name,
        [FILE_URL]: fileData.url,
        // file name without extension
        [OPTION_NAME]: fileData.name.replace(/\.[^/.]+$/, ''),
        file: fileData.file
      })
    },
    [setFieldValue]
  )

  return (
    <ContentRow
      title="Variable Options"
      description="Add multiple files at once, and the file name will be used as the option name. You can edit the option name if required."
      leftSideClassName={variablesFormContentClasses.leftSide}
      rightSideClassName={variablesFormContentClasses.rightSide}
    >
      <VariableOptionsList formik={formik} />

      <MultipleFilesUpload
        onFileUploaded={onFileUploadedHandler}
        uploadOptions={uploadOptions}
        // Remove previews on upload, because we will show custom previews based on formik values
        shouldRemovePreviewOnUpload={true}
      />
    </ContentRow>
  )
}

export default VariableOptionsContentRow

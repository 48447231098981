import * as Yup from 'yup'
import { t } from 'i18next'

import { getAllPackageProducts } from './helpers/products'
import { isPeriodsInventoryValid } from '../../../ContractBuilder/ProductsManage/ProductsTable/helpers/periods'
import { getAvailableCategories, getPackageFlow } from './helpers'

import {
  MAGAZINE_PRODUCTS,
  OOH_PRODUCTS,
  IN_STORE_PRODUCTS,
  EDM_PRODUCTS,
  DIGITAL_PRODUCTS,
  PHYSICAL_SPACES_PRODUCTS,
  SELECTED_PACKAGES,
  CAMPAIGN_NAME,
  BRAND,
  PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from './fields'
import {
  DIGITAL,
  EDM,
  IN_STORE,
  OOH,
  PERIOD_DAY,
  PERIOD_ONE_MONTH,
  PERIOD_ONE_WEEK,
  PHYSICAL_SPACES,
  PRINT
} from '../../../../../../constants/mediaOrders'
import { VARIABLES } from '../../../../MediaProducts/MediaProductForms/fields'
import { CAN_SELECT_MULTIPLE } from '../../../../MediaProducts/MediaProductVariableForms/fields'
import { MULTIPLE_VARIABLE_OPTIONS, PRODUCT_PERIODS_DATES, QUANTITY, SINGLE_VARIABLE_OPTION } from '../../../fields'
import { VALIDATE_ADDITIONAL_FIELDS } from '../../../ContractBuilder/validation'

export const PACKAGE_ERROR = 'package'

const getMinPeriodsErrorMessage = (minPeriods, period) => {
  const translatedPeriod = t(period)

  if (period === PERIOD_DAY || period === PERIOD_ONE_WEEK || period === PERIOD_ONE_MONTH) {
    return t('minimumCountOfNamedPeriods', { period: translatedPeriod, count: Number(minPeriods) })
  } else {
    return t('minimumCountOfPeriods', { count: Number(minPeriods) })
  }
}

const getMaxPeriodsErrorMessage = (maxPeriods, period) => {
  const translatedPeriod = t(period)

  if (period === PERIOD_DAY || period === PERIOD_ONE_WEEK || period === PERIOD_ONE_MONTH) {
    return t('maximumCountOfNamedPeriods', { period: translatedPeriod, count: Number(maxPeriods) })
  } else {
    return t('maximumCountOfPeriods', { count: Number(maxPeriods) })
  }
}

const productPeriodsDatesValidationSchema = {
  [PRODUCT_PERIODS_DATES]: Yup.array()
    .test('min-max-periods', '', function (publicationDates) {
      const { min_periods, max_periods, period } = this.parent
      const isValidMinPeriods = min_periods ? publicationDates.length >= min_periods : true
      const isValidMaxPeriods = max_periods ? publicationDates.length <= max_periods : true

      const productQuantity = this.parent[QUANTITY]
      // the VALIDATE_ADDITIONAL_FIELDS is triggered to true on Send/Confirm, at this point the contract should be valid according to the additional fields
      const periodMissInventory =
        this.options.context?.[VALIDATE_ADDITIONAL_FIELDS] &&
        !isPeriodsInventoryValid(this.originalValue, productQuantity)
      if (periodMissInventory) {
        return this.createError({
          message: 'Not enough inventory'
        })
      }

      if (!isValidMinPeriods) {
        return this.createError({
          message: getMinPeriodsErrorMessage(min_periods, period)
        })
      }

      if (!isValidMaxPeriods) {
        return this.createError({
          message: getMaxPeriodsErrorMessage(max_periods, period)
        })
      }

      return true // Validation is successful if there were no errors
    })
    .min(1, 'Select at least one period')
}

export const productPublicationDatesValidation = Yup.object({
  ...productPeriodsDatesValidationSchema
})

export const productSetupValidation = Yup.object({
  ...productPeriodsDatesValidationSchema,

  [VARIABLES]: Yup.array().of(
    Yup.object().test({
      name: 'variables validation',
      test: (value, context) => {
        const { [SINGLE_VARIABLE_OPTION]: singleVariableOption, [MULTIPLE_VARIABLE_OPTIONS]: multipleVariableOptions } =
          value
        const { required, [CAN_SELECT_MULTIPLE]: canSelectMultiple } = value
        const { path } = context

        if (required) {
          if (canSelectMultiple) {
            if (!multipleVariableOptions || !multipleVariableOptions.length) {
              return context.createError({
                path: `${path}.${MULTIPLE_VARIABLE_OPTIONS}`,
                message: 'Option is required'
              })
            } else {
              return true
            }
          } else {
            if (!singleVariableOption) {
              return context.createError({
                path: `${path}.${SINGLE_VARIABLE_OPTION}`,
                message: 'Option is required'
              })
            } else {
              return true
            }
          }
        }

        return true
      }
    })
  )
})

// the validation is used on OrderCartStep
export const getValidationSchema = ({ askBrandName, askCampaignName, isBrandRequired }) => {
  return Yup.object({
    [SELECTED_PACKAGES]: Yup.array()
      .of(
        Yup.lazy(mediaPackage => {
          const packageFlow = getPackageFlow(mediaPackage)

          if (packageFlow === PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE) {
            const allPackageProducts = getAllPackageProducts(mediaPackage)

            return Yup.object().shape({
              [MAGAZINE_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [OOH_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [IN_STORE_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [PHYSICAL_SPACES_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [EDM_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [DIGITAL_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
              [PACKAGE_ERROR]: Yup.array().test(
                'at-least-one-product',
                'Please select at least one product',
                function () {
                  return allPackageProducts.length > 0
                }
              )
            })
          } else {
            const availableCategories = getAvailableCategories(mediaPackage)

            return Yup.object().shape({
              ...(availableCategories[PRINT] && {
                [MAGAZINE_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              }),
              ...(availableCategories[OOH] && {
                [OOH_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              }),
              ...(availableCategories[IN_STORE] && {
                [IN_STORE_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              }),
              ...(availableCategories[PHYSICAL_SPACES] && {
                [PHYSICAL_SPACES_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              }),
              ...(availableCategories[EDM] && {
                [EDM_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              }),
              ...(availableCategories[DIGITAL] && {
                [DIGITAL_PRODUCTS]: Yup.array()
                  .min(1, 'Please select at least one product')
                  .of(productPublicationDatesValidation)
              })
            })
          }
        })
      )
      .min(1, 'Select at least one package'),
    ...(askCampaignName && {
      [CAMPAIGN_NAME]: Yup.string().required('Please enter a campaign name')
    }),
    ...(askBrandName && {
      [BRAND]: isBrandRequired ? Yup.string().required('Please select the brand') : Yup.string()
    })
  })
}

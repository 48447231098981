import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Select from '../../../../../../../../../components/Select'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import {
  getMediaProductLocationCategoriesIsLoadingSelector,
  mediaProductLocationCategoriesSelector
} from '../../../../../../../../../modules/selectors/mediaOrdersProductLocations'

import useStyles from './styles'

const LocationCategorySelect = ({ locationCategory, setLocationCategory }) => {
  const classes = useStyles()

  const mediaProductLocationCategories = useSelector(mediaProductLocationCategoriesSelector)
  const getMediaProductLocationCategoriesIsLoading = useSelector(getMediaProductLocationCategoriesIsLoadingSelector)
  const formattedCategories = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationCategories])

  const onChangeHandler = useCallback(
    locationCategory => {
      setLocationCategory(locationCategory.value)
    },
    [setLocationCategory]
  )

  const onClearInput = useCallback(() => {
    setLocationCategory('')
  }, [setLocationCategory])

  return (
    <Select
      placeholder="Category"
      isLoading={getMediaProductLocationCategoriesIsLoading}
      value={locationCategory}
      onChange={onChangeHandler}
      className={classes.locationCategorySelect}
      options={formattedCategories}
      portaled={true}
      isSmall
      showClearInputIcon={!!locationCategory}
      onClearInput={onClearInput}
    />
  )
}

LocationCategorySelect.propTypes = {
  locationCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setLocationCategory: PropTypes.func.isRequired
}

export default LocationCategorySelect

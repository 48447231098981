import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import Field from '../../../../../../../components/Form/Field'
import FieldLabelWrapper from '../../../../../../../features/components/Form/FieldLabelWrapper'

import { COMMENT } from '../../fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const fields = [COMMENT]
function CommentStep({ formik, mediaData, handleStepChange, uploadedFiles }) {
  const { t } = useTranslation()

  const productName = mediaData?.original_product_name

  const drawerFormClasses = useDrawerFormStyles()

  const handleContinue = useCallback(() => {
    handleStepChange(fields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Add a comment"
      stepDescription={t('addCommentToUploadedFile', { productName })}
      handleContinue={uploadedFiles.length > 1 ? handleContinue : null}
    >
      <section className={drawerFormClasses.section}>
        <FieldLabelWrapper label="Comment">
          <Field formik={formik} name={COMMENT} placeholder="Comment" isTextarea={true} />
        </FieldLabelWrapper>
      </section>
    </Step>
  )
}

CommentStep.propTypes = {
  formik: PropTypes.object,
  mediaData: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func,
  uploadedFiles: PropTypes.array
}

export default CommentStep

import React from 'react'
import { components } from 'react-select'
import classnames from 'classnames'

import Chip from '../../Chip'

import useStyles from './styles'

export default function Option(props) {
  const { description, label, labelColor, icon: Icon, tag, tagColor, disabled, isDisabled, disabledText } = props.data

  const classes = useStyles({ labelColor })

  return (
    <components.Option {...props}>
      <div
        className={classnames(classes.option, {
          // fallback to old version - disabled prop
          [classes.disabled]: isDisabled || disabled
        })}
      >
        {Icon && (
          <div className={classes.iconContainer}>
            <Icon />
          </div>
        )}
        <div>
          <div className={classes.label}>
            {label}
            {isDisabled && disabledText}
          </div>
          {description && <div className={classes.description}>{description}</div>}
        </div>
        {tag && (
          <div className={classes.optionTag}>
            <Chip text={tag} color={tagColor} className={classes.tag} />
          </div>
        )}
      </div>
    </components.Option>
  )
}

import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import StatusUpdateDropdown from '../../../../../../../../features/components/StatusUpdateDropdown'

import { bulkUpdateBookedMedia } from '../../../../../../../../modules/actions/mediaOrdersBookings'

import { bookedMediaStatusesArray } from '../../../../../../../../constants/bookedMedia'

const StatusCell = ({ status, sequentialIds, id }) => {
  const dispatch = useDispatch()

  const updateStatusHandler = useCallback(
    status => {
      dispatch(
        bulkUpdateBookedMedia({
          ids: sequentialIds || [id],
          status: status
        })
      )
    },
    [dispatch, sequentialIds, id]
  )

  return (
    <StatusUpdateDropdown
      currentStatus={status}
      statuses={bookedMediaStatusesArray}
      onStatusUpdate={updateStatusHandler}
    />
  )
}

StatusCell.propTypes = {
  status: PropTypes.string,
  sequentialIds: PropTypes.array,
  id: PropTypes.number
}

export default StatusCell

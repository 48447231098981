import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FootfallAreas from './FootfallAreas'
import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import DownloadFootfallDataSection from './DownloadFootfallDataSection'
import UploadFootfallDataSection from './UploadFootfallDataSection'
import CreateFootfallAreas from '../../../forms/Multiplatform/FootfallForms/FootfallAreas/CreateFootfallAreas'

import { controllersSelector } from '../../../modules/selectors/controller'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'

import useSettingsClasses from '../styles'

const FootfallData = () => {
  const { t } = useTranslation()
  const settingsClasses = useSettingsClasses()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const controllers = useSelector(controllersSelector)
  const selectedController = controllers.find(controller => controller.id === selectedControllerId) || {}

  const controllerName = selectedController?.name

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Footfall Data')}</h1>
      <ContentSection title={t('controllerFootfallData', { controllerName })}>
        <ContentRow
          title={t('Upload Footfall Data')}
          description={t(
            'Upload external and internal footfall data for reach, impression and frequency calculations.'
          )}
        >
          <UploadFootfallDataSection />
        </ContentRow>
        <ContentRow title={t('Download Footfall Data')} description={t('Export the already uploaded footfall data.')}>
          <DownloadFootfallDataSection />
        </ContentRow>
        <ContentRow
          title={t('Footfall Areas')}
          description={t(
            'Define footfall areas to apply external footfall data to a store or group of stores. This will be deduplicated when calculating total reach on a booking.'
          )}
          leftColumnChildren={<CreateFootfallAreas />}
        >
          <FootfallAreas />
        </ContentRow>
      </ContentSection>
    </>
  )
}

export default FootfallData

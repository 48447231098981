import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import Tabs from '../../../../../../../../../features/components/Tabs'
import MultiRowFields from '../../../../../../../../../features/components/Form/MultiRowFields'
import DiscountPairsFields from '../../../../../../../../ReusableFormFields/DiscountPairFields'
import Checkbox from '../../../../../../../../../components/Form/Checkbox'
import Select from '../../../../../../../../../components/Select'

import { discountPresetsSelector } from '../../../../../../../../../modules/selectors/discounts'

import {
  CUSTOM_DISCOUNT_CHECKBOX,
  DISCOUNT_PAIRS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_QUANTITY,
  discountPairInitialValue,
  DISCOUNTS_TAB_CUSTOM,
  DISCOUNTS_TAB_PRESET,
  DISCOUNTS_TABS,
  discountTabOptions,
  DISCOUNT_PRESET
} from '../../../fields'

import useStyles from './styles'

const DiscountsSection = ({ formik }) => {
  const { setFieldValue, values } = formik
  const classes = useStyles()

  const selectedTab = values[DISCOUNTS_TABS]

  const discountPresets = useSelector(discountPresetsSelector)

  const formattedDiscountPresets = useMemo(() => {
    return formatOptionsList({
      list: discountPresets,
      valueName: 'id',
      labelName: 'name'
    })
  }, [discountPresets])

  const selectTabHandler = useCallback(
    value => {
      setFieldValue(DISCOUNTS_TABS, value)
    },
    [setFieldValue]
  )

  const selectDiscountPresetHandler = useCallback(
    discountPreset => {
      setFieldValue(DISCOUNT_PRESET, discountPreset?.value)
    },
    [setFieldValue]
  )

  const clearDiscountPresetHandler = useCallback(() => {
    setFieldValue(DISCOUNT_PRESET, '')
  }, [setFieldValue])

  const customDiscountCheckboxHandler = useCallback(() => {
    setFieldValue(CUSTOM_DISCOUNT_CHECKBOX, !values[CUSTOM_DISCOUNT_CHECKBOX])
  }, [values, setFieldValue])

  // show tabs only if there are discount presets
  const showTabs = !!discountPresets.length
  const showCustomDiscounts = (showTabs && selectedTab === DISCOUNTS_TAB_CUSTOM) || values[CUSTOM_DISCOUNT_CHECKBOX]
  const showSelectDiscountPreset = showTabs && selectedTab === DISCOUNTS_TAB_PRESET

  return (
    <>
      {showTabs ? (
        <Tabs
          options={discountTabOptions}
          selectedValue={selectedTab}
          onSelectValue={selectTabHandler}
          className={classes.discountTabs}
        />
      ) : (
        <Checkbox
          id="custom_discount_checkbox"
          title="Apply discounts"
          className={classes.customDiscountCheckbox}
          name={CUSTOM_DISCOUNT_CHECKBOX}
          checked={values[CUSTOM_DISCOUNT_CHECKBOX]}
          onCheck={customDiscountCheckboxHandler}
        />
      )}

      {showSelectDiscountPreset && (
        <Select
          options={formattedDiscountPresets}
          value={values[DISCOUNT_PRESET]}
          showClearInputIcon={Boolean(values[DISCOUNT_PRESET])}
          onChange={selectDiscountPresetHandler}
          isSearchable={false}
          onClearInput={clearDiscountPresetHandler}
        />
      )}

      {showCustomDiscounts && (
        <MultiRowFields
          formik={formik}
          FieldsComponent={DiscountPairsFields}
          initialValueTemplate={discountPairInitialValue}
          mainValueName={DISCOUNT_PAIRS}
          subValueNames={[DISCOUNT_PERCENTAGE, DISCOUNT_QUANTITY]}
          addNewRowText="+ Add another discount"
        />
      )}
    </>
  )
}

export default DiscountsSection

export const PRIMARY_BUTTON = 'primary'
export const SECONDARY_BUTTON = 'secondary'
export const THEME_PRIMARY_BUTTON = 'theme_primary'

export const getInfoModalButtonColor = (colorType, classes) => {
  switch (colorType) {
    case PRIMARY_BUTTON:
      return classes.primaryBtn
    case THEME_PRIMARY_BUTTON:
      // button is using theme primary color by default
      return null
    case SECONDARY_BUTTON:
    default:
      return classes.secondaryBtn
  }
}

import { createStyles } from '../../../styles/helpers'

import { darkGrey, lightGrey, mediumGrey, textGrey } from '../../../styles/const/colors'
import { smallDesktopDown, tabletDown } from '../../../styles/const/breakpoints'
import { transitionShort } from '../../../styles/const/common'
import { textEllipsis } from '../../../styles/mixins/text'
import { container, pageContainerMaxWidth } from '../../../styles/common'

const useStyles = createStyles(theme => ({
  tabsWrapper: {
    padding: '0px 48px',
    borderBottom: `1px solid ${mediumGrey}`,
    background: 'white'
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: pageContainerMaxWidth,
    margin: '0 auto'
  },
  tabsRow: {
    display: 'flex',

    [`@media screen and (${smallDesktopDown})`]: {
      flexWrap: 'wrap'
    }
  },
  rightSideWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 48px 20px',
    borderBottom: `1px solid ${mediumGrey}`,
    background: 'white'
  },
  contentWrapper: {
    padding: '0px 48px'
  },
  contentContainer: {
    ...container,
    paddingTop: 32
  },
  tabItem: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    textDecoration: 'none',
    transform: 'translateY(1px)',
    color: darkGrey,
    fontSize: 16,
    fontWeight: 600,
    padding: '13px 26px 11px',
    border: '1px solid transparent',
    borderBottomWidth: 0,
    borderTopLeftRadius: theme.bigBorderRadius,
    borderTopRightRadius: theme.bigBorderRadius,
    ...textEllipsis(),
    '&.selected': {
      color: textGrey,
      background: lightGrey,
      border: `1px solid ${mediumGrey}`,
      borderBottomWidth: 0,
      '&::before': {
        content: '""',
        display: 'block',
        borderBottom: `4px solid ${theme.brandPrimary}`,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transition: transitionShort
      }
    },
    '&:hover': {
      color: textGrey,
      transition: transitionShort
    }
  },
  [`@media screen and (${smallDesktopDown})`]: {
    tabItem: {
      padding: '13px 16px 11px',
      '&.selected': {
        background: 'white',
        border: 'none',

        '&::before': {
          top: 'unset',
          bottom: 0
        }
      }
    }
  },
  [`@media screen and (${tabletDown})`]: {
    tabsWrapper: {
      padding: '0px 24px'
    },
    contentWrapper: {
      padding: '0px 24px'
    },
    rightSideWrapper: {
      padding: '20px 24px',
      justifyContent: 'flex-start'
    }
  },
  mobileTabsSelect: {
    padding: '24px 24px 0'
  }
}))

export default useStyles

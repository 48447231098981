import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import FieldsSection from '../../../../../../../../../../features/components/Form/FieldsSection'
import VariableOptionsSelector from '../VariableOptionsSelector'

import { getMediaProductVariablesIsLoadingSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProductVariables'
import { selectedControllerIdSelector } from '../../../../../../../../../../modules/selectors/app'

import {
  clearGetMediaProductVariables,
  getMediaProductVariables
} from '../../../../../../../../../../modules/actions/mediaOrdersProductVariables'
import { VARIABLES } from '../../../../../../../../MediaProducts/MediaProductForms/fields'

const ProductVariables = ({ formik, productItemPath, stepErrors, showError }) => {
  const dispatch = useDispatch()

  const { values } = formik

  const controllerId = useSelector(selectedControllerIdSelector)
  const getMediaProductVariablesIsLoading = useSelector(getMediaProductVariablesIsLoadingSelector)

  const currentProduct = getIn(values, productItemPath)
  const productVariables = getIn(values, `${productItemPath}.${VARIABLES}`)

  useEffect(() => {
    dispatch(getMediaProductVariables({ controller: controllerId }))

    return () => {
      dispatch(clearGetMediaProductVariables())
    }
  }, [dispatch, controllerId])

  return (
    <FieldsSection title="Product variables">
      {productVariables.map((variable, index) => {
        const selectedVariableId = getIn(currentProduct, `${VARIABLES}[${index}.id`)

        if (getMediaProductVariablesIsLoading) {
          return <Skeleton />
        }

        return (
          <VariableOptionsSelector
            key={selectedVariableId}
            formik={formik}
            stepErrors={stepErrors}
            productItemPath={productItemPath}
            index={index}
            selectedVariableId={selectedVariableId}
            showError={showError}
          />
        )
      })}
    </FieldsSection>
  )
}

ProductVariables.propTypes = {
  formik: PropTypes.object.isRequired,
  productItemPath: PropTypes.string.isRequired,
  stepErrors: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired
}

export default ProductVariables

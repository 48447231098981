import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  templates: {
    width: 'unset'
  },
  columnList: {
    paddingLeft: 20,
    marginBottom: 16
  },
  templatesHeadline: {
    margin: '16px 0',
    '&:first-child': {
      marginTop: 0
    }
  },
  regularWeight: {
    fontWeight: 400
  },
  downloadTemplatesButtons: {
    display: 'flex',
    marginTop: 16,
    gap: 10
  },
  downloadTemplateButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '&:hover path': {
      fill: 'white'
    }
  }
})

export default useStyles

import { format } from 'date-fns'

import { getProductStartDate, getProductStartPeriod } from './periodHelpers'

import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../../constants/mediaOrders'
import {
  CAMPAIGN_START_PERIOD_OPTION,
  CATEGORY_PRODUCTS_PACKAGE,
  FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE,
  FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE,
  PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
} from '../fields'
import { DATES_FORMAT_BE } from '../../../../../../../constants/dates'
import { USER_CAN_SELECT_PRODUCTS_FROM_LIST } from '../../../../../MediaPackageForms/MediaPackageCreate/MediaPackageCreateForm/fields'
import { VARIABLES } from '../../../../../MediaProducts/MediaProductForms/fields'
import { CAN_SELECT_MULTIPLE } from '../../../../../MediaProducts/MediaProductVariableForms/fields'
import {
  MULTIPLE_VARIABLE_OPTIONS,
  PRODUCT_GROUPED_PUBLICATIONS_DATES,
  PRODUCT_PERIODS_DATES,
  QUANTITY,
  SINGLE_VARIABLE_OPTION
} from '../../../../fields'

export const getPackageFlow = selectedMediaPackage => {
  const {
    media_categories: mediaCategories,
    media_products: preselectedMediaProducts = [],
    fixed_price: fixedPrice
  } = selectedMediaPackage
  const hasPreselectedProducts = preselectedMediaProducts.length
  const canSelectProducts = selectedMediaPackage[USER_CAN_SELECT_PRODUCTS_FROM_LIST]
  const noFixedPrice = !fixedPrice

  if (mediaCategories && !!mediaCategories.length) {
    // Package that has products categories and no fixed dates
    return CATEGORY_PRODUCTS_PACKAGE
  } else if (canSelectProducts) {
    // Package that has pre-defined products and no fixed dates
    // and user can select one or more products from the pre-defined products
    return PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
  } else if (hasPreselectedProducts && noFixedPrice) {
    // Package that has pre-defined products and no fixed dates
    // and user must buy all pre-defined products
    return FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
  } else {
    // Package that has pre-defined products and fixed dates (and user must buy all pre-defined products)
    return FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE
  }
}

export const getAvailableCategories = selectedMediaPackage => {
  const { media_categories: mediaCategories, media_products: mediaProducts } = selectedMediaPackage

  // order is Print -> OOH -> InStore -> PhysicalSpaces -> EDM -> Digital
  const activeCategories = {
    [PRINT]: false,
    [OOH]: false,
    [IN_STORE]: false,
    [EDM]: false,
    [PHYSICAL_SPACES]: false,
    [DIGITAL]: false
  }

  if (mediaProducts?.length) {
    // If we have preselected mediaProducts - get categories from products
    // is used in PackageBreakdown - FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE
    mediaProducts.forEach(productData => {
      const productMediaCategory = productData.media_category

      activeCategories[productMediaCategory] = true
    })
  } else if (mediaCategories) {
    // there are packages without mediaCategories
    mediaCategories.forEach(category => {
      activeCategories[category] = true
    })
  }

  return activeCategories
}

export const initialProductValues = {
  // selected date periods
  [PRODUCT_PERIODS_DATES]: [],
  [PRODUCT_GROUPED_PUBLICATIONS_DATES]: [],
  // product quantity
  [QUANTITY]: 1,
  [VARIABLES]: []
}
export const getInitialProductSetup = productData => {
  const productStartDate = getProductStartDate(productData)

  const startPeriodDate = getProductStartPeriod(productData.period, productStartDate)

  return {
    ...initialProductValues,
    data: productData,
    // magazine campaign start month
    [CAMPAIGN_START_PERIOD_OPTION]: format(startPeriodDate, DATES_FORMAT_BE),
    [VARIABLES]: productData[VARIABLES].map(variable => ({
      ...variable,
      ...(!!variable[CAN_SELECT_MULTIPLE]
        ? // Add additional field for multiple variable options
          { [MULTIPLE_VARIABLE_OPTIONS]: [] }
        : { [SINGLE_VARIABLE_OPTION]: '' })
      // Add additional field for single variable option
    }))
  }
}

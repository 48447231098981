import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FormField from '../UserProfileContent/EditProfileFormContent/FormField'
import Form from '../../../../components/Form'
import SetupTOTPTokenModal from './SetupTOTPTokenModal'
import MFAContentFormFields from './MFAContentFormFields'
import { TabletDown, TabletUp } from '../../../../components/hoc/ResponsiveRendering'

import { clearSetMFAType, setMFAType } from '../../../../modules/actions/app'
import {
  getCurrentUserSelector,
  setMFATypeErrorSelector,
  setMFATypeIsLoadingSelector,
  setMFATypeWasCreatedSelector
} from '../../../../modules/selectors/app'

import { isEqual } from '../../../../helpers/common'

import { getInitialValues, getValidationSchema, DISABLED_MFA, MFA_TYPE, TOTP_MFA } from './fields'
import { VERIFICATION_REQUIRED_CHECK } from './fields'

import useFormWrapperStyles from '../../../../styles/common/formWrapper'

const MFAContent = () => {
  const formWrapperClasses = useFormWrapperStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isSetupTOTPTokenModalOpened, setIsSetupTOTPTokenModalOpened] = useState(false)
  const [isRegenerateTOTPFlow, setIsRegenerateTOTPFlow] = useState(false)

  const user = useSelector(getCurrentUserSelector)
  const setMFATypeWasCreated = useSelector(setMFATypeWasCreatedSelector)

  const { mfa_is_required: isUserLevelMFARequired, is_mfa_enabled: userCurrentMFAType } = user

  const onSubmit = (values, shouldOpenTOTPSetupModal = true) => {
    if (values[VERIFICATION_REQUIRED_CHECK] && values[MFA_TYPE] === TOTP_MFA && shouldOpenTOTPSetupModal) {
      // when user wants to set TOTP_MFA, we initially open TOTP auth app setup modal
      // only after user verify app token, we perform setting up mfa type TOTP_MFA
      setIsSetupTOTPTokenModalOpened(true)
    } else {
      dispatch(
        setMFAType({
          mfa: values[VERIFICATION_REQUIRED_CHECK] ? values[MFA_TYPE] : DISABLED_MFA
        })
      )
    }
  }

  const initialValues = useMemo(() => getInitialValues(userCurrentMFAType), [userCurrentMFAType])

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(isUserLevelMFARequired),
    enableReinitialize: true,
    onSubmit
  })

  const { values } = formik

  const formHasNoChanges = isEqual(values, initialValues)

  const onRegenerateTOTPTokenHandler = () => {
    setIsSetupTOTPTokenModalOpened(true)
    setIsRegenerateTOTPFlow(true)
  }

  const onSetupTOTPTokenModalCloseHandler = () => {
    setIsSetupTOTPTokenModalOpened(false)
  }

  const onSetupTOTPTokenSuccessHandler = () => {
    if (isRegenerateTOTPFlow) {
      // if regenerate flow, just close the modal
      setIsSetupTOTPTokenModalOpened(false)
      setIsRegenerateTOTPFlow(false)
    } else {
      // when token is set up and the modal is closed, trigger mfa type update by calling onSubmit
      onSubmit(values, false)
    }
  }

  const clearSetMFATypeHandler = () => {
    dispatch(clearSetMFAType())
  }

  return (
    <>
      <TabletUp>
        <div className={formWrapperClasses.formWrapper}>
          <Form
            formName="editVerificationType"
            formik={formik}
            successSubmit={setMFATypeWasCreated}
            errorSelector={setMFATypeErrorSelector}
            isLoadingSelector={setMFATypeIsLoadingSelector}
            clearHandler={clearSetMFATypeHandler}
            submitText={t('Save changes')}
            formFooterClassName={formWrapperClasses.formFooter}
            buttonProps={{
              disabled: formHasNoChanges,
              wrapperClassName: formWrapperClasses.submitButtonWrapper
            }}
          >
            <FormField label={t('Verification type')} isLastFormField>
              <MFAContentFormFields
                formik={formik}
                formHasChanges={!formHasNoChanges}
                onRegenerateTOTPTokenHandler={onRegenerateTOTPTokenHandler}
              />
            </FormField>
          </Form>
        </div>
      </TabletUp>
      <TabletDown>
        <Form
          formName="editProfile"
          formik={formik}
          method="post"
          successSubmit={setMFATypeWasCreated}
          errorSelector={setMFATypeErrorSelector}
          isLoadingSelector={setMFATypeIsLoadingSelector}
          clearHandler={clearSetMFATypeHandler}
          submitText={t('Save')}
          className={formWrapperClasses.form}
          buttonProps={{
            disabled: formHasNoChanges
          }}
        >
          <div className={formWrapperClasses.formRow}>
            <MFAContentFormFields
              formik={formik}
              formHasChanges={!formHasNoChanges}
              onRegenerateTOTPTokenHandler={onRegenerateTOTPTokenHandler}
            />
          </div>
        </Form>
      </TabletDown>

      <SetupTOTPTokenModal
        isModalOpened={isSetupTOTPTokenModalOpened}
        onClose={onSetupTOTPTokenModalCloseHandler}
        onSuccessSubmitHandler={onSetupTOTPTokenSuccessHandler}
      />
    </>
  )
}

export default MFAContent

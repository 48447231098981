import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ContentSection from '../../../../../../features/components/ContentSection'
import ContentRow from '../../../../../../features/components/ContentSection/ContentRow'
import Field from '../../../../../../components/Form/Field'
import MediaAssetFilterContentRow from './MediaAssetFilterContentRow'
import Checkbox from '../../../../../../components/Form/Checkbox'
import VariableOptionsContentRow from './VariableOptionsContentRow'

import { BOOKING_EMAIL_TEXT, CAN_SELECT_MULTIPLE, NAME, REQUIRED } from '../fields'

import useStyles from './styles'

const BookingVariablesFormContent = ({ formik }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { values, setFieldValue } = formik

  const checkboxHandler = useCallback(
    e => {
      const { name } = e.target
      setFieldValue(name, !values[name])
    },
    [values, setFieldValue]
  )

  return (
    <ContentSection title="Brand page">
      <ContentRow
        title="Name"
        description="This will be used as the form field’s label on booking forms."
        leftSideClassName={classes.leftSide}
        rightSideClassName={classes.rightSide}
      >
        <Field formik={formik} id={NAME} name={NAME} placeholder="e.g Overlay" disablePlaceholderFormatting={true} />
      </ContentRow>
      <MediaAssetFilterContentRow formik={formik} />
      <ContentRow
        title="Required Status"
        description="Check this box if this variable must be provided before booking."
        leftSideClassName={classes.leftSide}
        rightSideClassName={classes.rightSide}
      >
        <Checkbox
          id="required_status_checkbox"
          title={t('Required field')}
          name={REQUIRED}
          checked={values[REQUIRED]}
          onCheck={checkboxHandler}
        />
      </ContentRow>
      <ContentRow
        title="Multiple Allowed"
        description="Check this box if users can select more than one option for this variable."
        leftSideClassName={classes.leftSide}
        rightSideClassName={classes.rightSide}
      >
        <Checkbox
          id="can_select_multiple_checkbox"
          title={t('User can select multiple')}
          name={CAN_SELECT_MULTIPLE}
          checked={values[CAN_SELECT_MULTIPLE]}
          onCheck={checkboxHandler}
        />
      </ContentRow>
      <ContentRow
        title="Booking confirmation email text (optional)"
        description="This text will be added to the booking confirmation email."
        leftSideClassName={classes.leftSide}
        rightSideClassName={classes.rightSide}
      >
        <Field
          formik={formik}
          id={BOOKING_EMAIL_TEXT}
          name={BOOKING_EMAIL_TEXT}
          placeholder="e.g. The overlay file is attach..."
          disablePlaceholderFormatting={true}
        />
      </ContentRow>
      <VariableOptionsContentRow formik={formik} />
    </ContentSection>
  )
}

export default BookingVariablesFormContent

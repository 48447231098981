export const generateDetailsText = historyItem => {
  const formattedDetail = historyItem.detail || {}
  const {
    user,
    reason,
    disapproved_reason,
    buyer_disapproved_reason,
    file_name,
    product_name,
    booked_media_name,
    booked_media_status,
    file_status,
    sent_to,
    created_by,
    uploaded_by_first_name,
    uploaded_by_email
  } = formattedDetail

  switch (historyItem.action) {
    case 'created':
      return `Media booking was created by ${user}`
    case 'updated':
      return `Media booking was updated by ${user}`
    case 'deleted':
      return `Media booking was deleted by ${user}`
    case 'media_order_file_created':
      return `Media booking file was uploaded by ${user}`
    case 'media_order_file_deleted':
      return `Media booking file was removed by ${user}`
    case 'cancelled':
      return `Media booking was cancelled by ${user}. Reason for cancellation: ${reason}`
    case 'contract_created':
      return `Booking contract was created by ${user}`
    case 'contract_updated':
      return `Booking contract was updated by ${user}`
    case 'contract_signed':
      return `Booking contract was signed by ${user}`
    case 'contract_generate_media_order':
      return `Media booking was created by ${user}`
    case 'save_as_quotation':
      return `Proposal was created by ${user}`
    case 'send_quotation':
      return `Proposal was sent for signature by ${user}`
    case 'updated_quotation_cost':
      return `Proposal cost was updated by ${user}`
    case 'quotation_expired':
      return `Proposal expired ${user}`
    case 'amendment_created':
      return `Amendment was created by ${user}`
    case 'amendment_updated':
      return `Amendment was updated by ${user}`
    case 'amendment_signed':
      return `Amendment was signed by ${user}`
    case 'amendment_controller_signed':
      return `Amendment was signed by ${user}`
    case 'send_amendment':
      return `Amendment was sent for signature by ${user}`
    case 'submit_amendment':
      return `Booking was created by ${user}`
    case 'updated_booked_media_status':
      return `Status of booked media “${booked_media_name}“ was updated to “${booked_media_status}” by ${user}`
    case 'uploaded_file_created':
      if (created_by === 'external user') {
        return `A file “${file_name}” was uploaded to product “${product_name}“ by ${uploaded_by_first_name} (${uploaded_by_email})`
      }
      return `A file “${file_name}” was uploaded to product “${product_name}“ by ${user}`
    case 'uploaded_file_updated':
      return `A file “${file_name}” linked to product “${product_name}“ was updated by ${user}`
    case 'uploaded_file_deleted':
      return `A file “${file_name}” linked to product “${product_name}“ was removed by ${user}`
    case 'upload_file_approval_status_updated':
      const dissaporvedReason = disapproved_reason || buyer_disapproved_reason
      if (file_status === 'disapproved' || file_status === 'buyer_disapproved') {
        return `Status of uploaded file “${file_name}“ was updated to “${file_status}” by ${user}. Reason: ${dissaporvedReason}`
      }
      return `Status of uploaded file “${file_name}“ was updated to “${file_status}” by ${user}`
    case 'upload_file_status_updated':
      return `Status of uploaded file “${file_name}“ was updated to “${file_status}” by ${user}`
    case 'sent_booking_confirmation_email':
      return `Booking Confirmation email was sent to ${sent_to} by ${user}`
    case 'resent_booking_confirmation_email':
      return `Booking Confirmation email was resent to ${sent_to} by ${user}`
    default:
      return 'Unknown action'
  }
}

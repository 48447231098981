import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { eachDayOfInterval, isAfter, isBefore, isWithinInterval } from 'date-fns'
import { formatDateToBE } from '../../../../../../../../../../../constants/dates'

export function useGetDisabledDates(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useGetDisabledDates')

  const {
    firstPeriod,
    lastPeriod,
    availablePeriods,
    selectedPeriods,
    isEndDateSelection,
    selectedStartDate,
    additionalAvailableDays
  } = props

  return useMemo(() => {
    if (firstPeriod && lastPeriod) {
      // find earliest and latest date in periodOptions to generate all available dates in range
      const allInventoryDates = eachDayOfInterval({
        start: new Date(firstPeriod),
        end: new Date(lastPeriod)
      })

      const disabledDates = []
      const datesStatuses = {}
      // this is used during isEndDateSelection to determine if the date is continuous to the last continuous date
      // if the is not selectable date on the calendar, then it shouldn't be possible to select past that date
      let isContinuousBroken = false

      // iterate over allInventoryDates to determine disabledDates and datesStatuses
      allInventoryDates.forEach(date => {
        const dateKey = formatDateToBE(date, 'yyyy-MM-dd')

        // Check if the date is within any selected period
        const isSelected = selectedPeriods?.some(period => {
          const periodStart = new Date(period.date_start)
          periodStart.setHours(0, 0, 0, 0)
          const periodEnd = new Date(period.date_end)
          periodEnd.setHours(23, 59, 59, 999)
          return isWithinInterval(date, { start: periodStart, end: periodEnd })
        })

        const { isAvailable, isInOriginalPeriod, isOriginalStartPeriod, isOriginalEndPeriod, isInAdjustDate } =
          isInAvailablePeriods({
            date,
            dateKey,
            availablePeriods,
            additionalAvailableDays
          })

        const disablePastDates = isEndDateSelection && isBefore(date, selectedStartDate)
        const isDisabled = isSelected || !isAvailable || disablePastDates

        // check if the date is continuous to the last continuous date
        if (isEndDateSelection && !isContinuousBroken) {
          if (isDisabled && isAfter(date, selectedStartDate)) {
            isContinuousBroken = true
          }
        }

        // additional disable dates that are not continuous
        if (isDisabled || isContinuousBroken) {
          disabledDates.push(date)
        }

        // Store the status of each date
        datesStatuses[dateKey] = {
          isSelected,
          isAvailable,
          isInAdjustDate,
          isContinuousBroken,
          isInOriginalPeriod,
          isOriginalStartPeriod,
          isOriginalEndPeriod
        }
      })

      return { disabledDates, datesStatuses }
    } else if (lastPeriod) {
      // when no first period is available disable all dates from today to last period
      // that could be the case if the first available period is in few months from now
      const disabledDates = eachDayOfInterval({
        start: new Date(),
        end: new Date(lastPeriod)
      })

      const datesStatuses = {}
      disabledDates.forEach(date => {
        const dateKey = formatDateToBE(date, 'yyyy-MM-dd')
        datesStatuses[dateKey] = {
          isSelected: false,
          isAvailable: false,
          isInAdjustDate: false,
          isOriginalStartPeriod: false,
          isOriginalEndPeriod: false
        }
      })

      return { disabledDates, datesStatuses }
    } else {
      return { disabledDates: [], datesStatuses: {} }
    }
  }, [
    firstPeriod,
    lastPeriod,
    selectedPeriods,
    availablePeriods,
    isEndDateSelection,
    additionalAvailableDays,
    selectedStartDate
  ])
}

const propTypes = {
  firstPeriod: PropTypes.instanceOf(Date),
  lastPeriod: PropTypes.instanceOf(Date),
  availablePeriods: PropTypes.array,
  selectedPeriods: PropTypes.array,
  additionalAvailableDays: PropTypes.number
}

function isInAvailablePeriods({ date, dateKey, availablePeriods, additionalAvailableDays }) {
  if (availablePeriods) {
    // Check if the date is within any original period dates
    const { isInOriginalPeriod, isOriginalStartPeriod, isOriginalEndPeriod } = isDateInOriginalPeriod({
      date,
      dateKey,
      availablePeriods
    })

    // Check if the date is within any adjusted available period
    const isAvailable = isDateInAdjustedPeriod({
      date,
      availablePeriods,
      additionalAvailableDays
    })
    // Determine if the date is in adjustment days
    const isInAdjustDate = isAvailable && !isInOriginalPeriod

    return {
      isAvailable,
      isInOriginalPeriod,
      isOriginalStartPeriod,
      isOriginalEndPeriod,
      isInAdjustDate
    }
  } else {
    return {
      isAvailable: false,
      isInOriginalPeriod: false,
      isOriginalStartPeriod: false,
      isOriginalEndPeriod: false,
      isInAdjustDate: false
    }
  }
}

function isDateInOriginalPeriod({ date, dateKey, availablePeriods }) {
  let isInOriginalPeriod = false
  let isOriginalStartPeriod = false
  let isOriginalEndPeriod = false

  availablePeriods.forEach(period => {
    const originalStartDate = new Date(period.date_start)
    originalStartDate.setHours(0, 0, 0, 0)

    const originalEndDate = new Date(period.date_end)
    originalEndDate.setHours(23, 59, 59, 999)

    if (dateKey === period.date_start) {
      // Check if the date is the original period start date
      isOriginalStartPeriod = true
    }
    if (dateKey === period.date_end) {
      // Check if the date is the original period end date
      isOriginalEndPeriod = true
    }

    if (
      !isInOriginalPeriod &&
      isWithinInterval(date, {
        start: originalStartDate,
        end: originalEndDate
      })
    ) {
      isInOriginalPeriod = true
    }
  })

  return { isInOriginalPeriod, isOriginalStartPeriod, isOriginalEndPeriod }
}

function isDateInAdjustedPeriod({ date, availablePeriods, additionalAvailableDays }) {
  return !!availablePeriods.some(period => {
    // Adjust start and end dates with additionalAvailableDays
    const adjustedStartDate = new Date(period.date_start)
    adjustedStartDate.setDate(adjustedStartDate.getDate() - additionalAvailableDays)
    adjustedStartDate.setHours(0, 0, 0, 0)

    const adjustedEndDate = new Date(period.date_end)
    adjustedEndDate.setDate(adjustedEndDate.getDate() + additionalAvailableDays)
    adjustedEndDate.setHours(23, 59, 59, 999)

    return isWithinInterval(date, { start: adjustedStartDate, end: adjustedEndDate })
  })
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import MultiRowFields from '../../../../../../../features/components/Form/MultiRowFields'
import PricePairFields from '../../../../../../ReusableFormFields/PricePairFields'

import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  PRICE_VALUE,
  pricePairInitialValue
} from '../../../../../../ReusableFormFields/PricePairFields/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const PricesSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Prices')}</h3>
      <MultiRowFields
        formik={formik}
        initialValueTemplate={pricePairInitialValue}
        mainValueName={PRICE_PAIRS}
        subValueNames={[PRICE_CURRENCY, PRICE_VALUE]}
        FieldsComponent={PricePairFields}
        addNewRowText="Add another price"
      />
    </section>
  )
}

export default PricesSection

import { fetchAuthSession } from '@aws-amplify/auth'

import store from '../../store'
import { submitLogout } from '../../modules/actions/cognito'
import { ExtendedError } from '../errors'
import { isAuthorizedSelector } from '../../modules/selectors/cognito'

export async function getToken() {
  // getting token from AWS provides having correct token everytime
  // session tokens expires every 1hr, and fetchAuthSession() manage to get a new one automatically
  return await fetchAuthSession()
    .then(session => session.tokens?.accessToken?.toString())
    .catch(sessionError => {
      console.error('current session:', sessionError)
      return ''
    })
}

export function checkAuthToken(token, apiUrl) {
  const currentState = store.getState()
  const isUserAuthorized = isAuthorizedSelector(currentState)

  if (isUserAuthorized && !token) {
    // if user is authorized but token is not available, then we need to logout the user
    // so that user can login again, this is a case when token has expired
    console.warn('Failed api point:', apiUrl)
    store.dispatch(submitLogout())

    throw new ExtendedError('Your session has expired. Please log in again.', {
      showErrorToast: false
    })
  }
}

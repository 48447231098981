import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  proposalsActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    alignItems: 'center'
  },
  deleteBtn: {
    display: 'flex',
    alignItems: ' center',
    gap: '6px'
  },
  proposalsFilters: {
    marginLeft: 'auto'
  }
})

export default useStyles

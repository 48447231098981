import { useCallback, useState } from 'react'

const defaultUploadedFile = {}
export function useDisapprovalModal() {
  const [showDisapprovalReasonModal, setShowDisapprovalReasonModal] = useState(false)
  const [selectedRequirementId, setSelectedRequirementId] = useState('')
  const [selectedUploadedFile, setSelectedUploadedFile] = useState(defaultUploadedFile)

  const handleShowDisapprovalModal = useCallback((requirementId, uploadedFile) => {
    setShowDisapprovalReasonModal(true)
    setSelectedRequirementId(requirementId)
    setSelectedUploadedFile(uploadedFile)
  }, [])

  const handleCloseDisapprovalModal = useCallback(() => {
    setShowDisapprovalReasonModal(false)
    setSelectedRequirementId('')
    setSelectedUploadedFile(defaultUploadedFile)
  }, [])

  return {
    handleShowDisapprovalModal,
    handleCloseDisapprovalModal,
    showDisapprovalReasonModal,
    selectedRequirementId,
    selectedUploadedFile
  }
}

import { addMonths, endOfMonth, startOfMonth } from 'date-fns'

import { calc } from '../../../../helpers/numbers'
import { EVENTS_AREA_PADDING, MONTHS, ONE_DAY } from '../../../../constants/timeline'

export const getYearTitleCols = (startDate, endDate, monthsCount = 12) => {
  if (startDate.getFullYear() === endDate.getFullYear()) {
    return [{ text: startDate.getFullYear(), colSpan: monthsCount }]
  }

  return [
    { text: startDate.getFullYear(), colSpan: monthsCount - startDate.getMonth() },
    { text: endDate.getFullYear(), colSpan: endDate.getMonth() + 1 }
  ]
}

export const getSubtitleMonthBasedCols = (startDate, monthsCount) => {
  return [...Array(monthsCount)].map((_, index) => {
    const currentDate = addMonths(startDate, index)
    const monthIndex = currentDate.getMonth()

    return {
      text: MONTHS[monthIndex]
    }
  })
}

export const calculateMonthsInPx = args => {
  const { tableInnerWidth, intervalsCount, startDate } = args
  const pxPerInterval = tableInnerWidth / intervalsCount

  const timeToPx = [...Array(intervalsCount)].map((_, index) => {
    const start = addMonths(startOfMonth(startDate), index)
    const end = endOfMonth(start)

    const daysCount = calc(end.getTime()).minus(start.getTime()).div(ONE_DAY).toNumber()

    let monthPxPerInterval = pxPerInterval
    if (index === 0 || index === intervalsCount - 1) {
      // first and last intervals have padding in columns when the event ends or starts on the edge
      monthPxPerInterval = pxPerInterval - EVENTS_AREA_PADDING
    }

    return {
      oneDayInPx: monthPxPerInterval / daysCount,
      daysCount: daysCount,
      start: start.getTime(),
      end: end.getTime()
    }
  })

  return timeToPx
}

import * as Yup from 'yup'

import { IMAGES } from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { FILE_NAME } from '../../../../../../../constants/files'

export const imagesValidation = {
  [IMAGES]: Yup.array().test({
    message: 'Please upload an image',
    // ensure that we don't have empty file_name
    test: images => !!images[0][FILE_NAME]
  })
}

import { useCallback, useMemo, useState } from 'react'

import { useHandleAutoSave } from './useHandleAutoSave'

import { BRAND, CAMPAIGN_NAME, VARIABLES } from './fields'

export const useEditActions = ({ formik, defaultValues, allowAutoSave, setIsEditMode }) => {
  const { setFieldValue, validateForm, submitForm, setFieldTouched } = formik

  // set productValues as default values to avoid case when newly product is selected and there is no data yet
  const [initialValues, setInitialValues] = useState(defaultValues)

  const handleStartEditContract = useCallback(() => {
    // save the values before edit start, to be able to reset on these
    setInitialValues(defaultValues)
    setIsEditMode(true)
  }, [defaultValues, setIsEditMode])

  const onSuccessSave = useCallback(() => {
    setIsEditMode(false)
  }, [setIsEditMode])

  const handleSaveContract = useHandleAutoSave({
    onSuccessSave,
    setFieldTouched,
    validateForm,
    submitForm,
    allowAutoSave
  })

  const handleResetContract = useCallback(() => {
    setFieldValue(BRAND, initialValues[BRAND])
    setFieldValue(CAMPAIGN_NAME, initialValues[CAMPAIGN_NAME])
    setFieldValue(VARIABLES, initialValues[VARIABLES])
    setIsEditMode(false)
  }, [initialValues, setIsEditMode, setFieldValue])

  return useMemo(
    () => ({
      handleStartEditContract,
      handleSaveContract,
      handleResetContract
    }),
    [handleStartEditContract, handleSaveContract, handleResetContract]
  )
}

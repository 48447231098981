import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import FilePreview from './FilePreview'
import DropFileUploader from '../../../../../../../../components/Form/DropFileUploader'

import { multipleFilesUpload } from '../../../../../../../../modules/actions/files'
import { fileUploadErrorSelector } from '../../../../../../../../modules/selectors/files'
import { showToasts } from '../../../../../../../../helpers/toasts'

const MultipleFilesUpload = ({ onFileUploaded, uploadOptions = {}, shouldRemovePreviewOnUpload = false }) => {
  const dispatch = useDispatch()

  const fileUploadError = useSelector(fileUploadErrorSelector)

  const [selectedFiles, setSelectedFiles] = useState([])

  const handleStartUpload = useCallback(
    files => {
      let filesFilledWithIds = files

      filesFilledWithIds.forEach(file => {
        file.id = uuidv4()
      })

      setSelectedFiles(prevFiles => {
        return [...prevFiles, ...filesFilledWithIds]
      })

      dispatch(multipleFilesUpload(files, uploadOptions))
    },
    [dispatch, uploadOptions]
  )

  const onFileUploadedHandler = useCallback(
    fileData => {
      onFileUploaded && onFileUploaded(fileData)

      if (shouldRemovePreviewOnUpload) {
        setSelectedFiles(prevFiles => {
          return prevFiles.filter(file => file.id !== fileData.file.id)
        })
      }
    },
    [onFileUploaded, shouldRemovePreviewOnUpload]
  )

  useEffect(() => {
    if (fileUploadError) {
      setSelectedFiles([])
      showToasts({
        type: 'error',
        message: 'Sorry, some file types are not supported.'
      })
    }
  }, [fileUploadError])

  return (
    <>
      {selectedFiles.map(file => {
        return <FilePreview key={file.id} file={file} onFileUploaded={onFileUploadedHandler} />
      })}
      {/*// todo SHOP-2593 Add support of input file upload on mobile*/}
      <DropFileUploader onUpload={handleStartUpload} multiple={true} />
    </>
  )
}

MultipleFilesUpload.propTypes = {
  onFileUploaded: PropTypes.func,
  uploadOptions: PropTypes.object,
  shouldRemovePreviewOnUpload: PropTypes.bool
}

export default MultipleFilesUpload

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import ContractActions from './ContractActions'
import Form from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import ContractBuilder from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder'
import MediaOrderInfo from './MediaOrderInfo'

import { MediaOrderFormContext } from '../../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderContext } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import usePermissions from '../../../../../hooks/usePermissions'
import { usePurifiedFormik } from '../../../../../hooks/formHooks/usePurifiedFormik'

import { createAmendment, clearCreateAmendment, clearActiveAmendment } from '../../../../../modules/actions/amendments'
import { contractSelector } from '../../../../../modules/selectors/contracts'
import { mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'
import { selectedControllerIdSelector, selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import {
  amendmentWasCreatedSelector,
  createAmendmentErrorSelector,
  createAmendmentIsLoadingSelector,
  createdAmendmentSelector
} from '../../../../../modules/selectors/amendments'
import { bookingVariablesSelector } from '../../../../../modules/selectors/mediaOrdersBookings'

import { transformValuesToBE } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/formatters'
import { getInitialValues } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'
import { getValidationSchema } from '../../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'

import { AMENDMENT_PERMISSION } from '../../../../../constants/permissions'
import { MEDIA_ORDER_AMEND_CONTRACT_CREATE } from '../../../../../constants/forms'

import useStyles from './styles'

const ContractDetails = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)
  const { editProductId, setEditProductId } = useContext(ContractBuilderContext)

  const mediaOrder = useSelector(mediaOrderSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const amendmentContract = useSelector(createdAmendmentSelector)
  const amendmentWasCreated = useSelector(amendmentWasCreatedSelector)
  const createAmendmentIsLoading = useSelector(createAmendmentIsLoadingSelector)
  // loaded contract is used to get initial values for the form
  const loadedContract = useSelector(contractSelector)

  const bookingVariables = useSelector(bookingVariablesSelector)

  const [activeContract, setActiveContract] = useState(loadedContract)

  const permissions = usePermissions()
  const allowAmend = permissions.can('create', AMENDMENT_PERMISSION)

  const mediaOrderId = mediaOrder.id
  const isOrderCancelled = mediaOrder?.status === 'cancelled'

  const [isAmendmentProcess, setIsAmendmentProcess] = useState(false)
  const [isAdditionalInfoEdit, setIsAdditionalInfoEdit] = useState(false)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        selfAccountData: contextSelfAccountData,
        controllerId,
        selectedCurrency: mediaOrder.currency,
        values
      })

      const amendmentData = {
        detail: {
          ...transformedData,
          account: selectedSelfAccount,
          controller: controllerId,
          // copy paste fields from media order:
          name: mediaOrder.name
        },
        amendment: true,
        // This field is used to manually update the cost of the amendment
        set_amendment_cost: true,
        original_media_order: mediaOrderId,
        account: selectedSelfAccount,
        controller: controllerId,
        without_requirements: mediaOrder.without_requirements
      }

      dispatch(createAmendment(amendmentData))
    },
    [contextSelfAccountData, controllerId, mediaOrder, selectedSelfAccount, mediaOrderId, dispatch]
  )

  const clearCreateHandler = useCallback(() => {
    dispatch(clearCreateAmendment())
    dispatch(clearActiveAmendment())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues(activeContract, bookingVariables)
  }, [activeContract, bookingVariables])

  const validationSchema = useMemo(() => {
    // keep only products validation
    return getValidationSchema({})
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { resetForm } = purifiedFormik

  const handleStopAmendmentProcess = useCallback(() => {
    setIsAmendmentProcess(true)
    resetForm()
  }, [resetForm])

  const cancelAmendmentProcessBtn = useMemo(() => {
    return (
      <Button
        type="button"
        onClick={() => {
          setIsAmendmentProcess(false)
          setEditProductId(null)
          resetForm()
        }}
        className={classes.cancelAmendmentButton}
        disabled={createAmendmentIsLoading}
      >
        {t('Cancel')}
      </Button>
    )
  }, [classes.cancelAmendmentButton, createAmendmentIsLoading, resetForm, setEditProductId, t])

  useEffect(() => {
    if (amendmentWasCreated) {
      setActiveContract(amendmentContract)
    }
  }, [amendmentWasCreated, amendmentContract, setActiveContract])

  useEffect(() => {
    return () => {
      if (createAmendmentIsLoading) {
        // stop handling createAmendmentSuccess if page is left during createAmendmentIsLoading
        // if not cancel the createAmendmentSuccess, then it will save data in redux which will not be cleared
        // and during next amendment form it will trigger success submit action
        dispatch(clearCreateAmendment())
      }
    }
  }, [dispatch, createAmendmentIsLoading])

  return (
    <div className={classes.mediaOrderDetailsWrapper}>
      {/*keep ContractActions outside the Form, to avoid unexpected behaviour of form submit. The ContractActions
       has a lot of buttons inside as well as other formik with submit buttons, which trigger to submit the
        amendment form */}
      {!isOrderCancelled && !isAmendmentProcess && (
        <ContractActions handleStopAmendmentProcess={handleStopAmendmentProcess} />
      )}
      <Form
        submitText="Generate Amendment"
        formik={purifiedFormik}
        showSubmit={isAmendmentProcess}
        buttonProps={{ disabled: Boolean(editProductId) || isAdditionalInfoEdit }}
        componentBeforeSubmit={isAmendmentProcess ? cancelAmendmentProcessBtn : null}
        formName={MEDIA_ORDER_AMEND_CONTRACT_CREATE}
        className={classes.contractProductsTable}
        formFooterClassName={classes.formSubmitFooter}
        // processing
        successSubmit={amendmentWasCreated}
        errorSelector={createAmendmentErrorSelector}
        isLoadingSelector={createAmendmentIsLoadingSelector}
        // after form submit
        clearHandler={clearCreateHandler}
        // set edit more after successful creation to use update handlers and selectors
        onSuccessSubmit={() => setIsAmendmentProcess(false)}
      >
        <ContractBuilder
          formik={purifiedFormik}
          hasTopMargin={false}
          isAdditionalInfoEdit={isAdditionalInfoEdit}
          setIsAdditionalInfoEdit={setIsAdditionalInfoEdit}
          allowEdit={isAmendmentProcess}
          hasEditPermissions={allowAmend}
          allowAutoSave={false}
          checkInventory={false}
          isAmendment={true}
        >
          <MediaOrderInfo />
        </ContractBuilder>
      </Form>
    </div>
  )
}

export default ContractDetails

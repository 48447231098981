import { call, put, all, takeEvery } from 'redux-saga/effects'

import {
  BULK_UPLOAD_FOOTFALL_DATA,
  bulkUploadFootfallDataSuccess,
  bulkUploadFootfallDataFailure,
  GET_FOOTFALL_DATA_AS_CSV,
  getFootfallDataAsCsvSuccess,
  getFootfallDataAsCsvFailure,
  GET_FOOTFALL_AREAS,
  getFootfallAreasSuccess,
  getFootfallAreasFailure,
  DELETE_FOOTFALL_AREAS,
  deleteFootfallAreasSuccess,
  deleteFootfallAreasFailure,
  createFootfallAreasSuccess,
  createFootfallAreasFailure,
  CREATE_FOOTFALL_AREAS,
  UPDATE_FOOTFALL_AREA,
  updateFootfallAreaSuccess,
  updateFootfallAreaFailure
} from '../actions/footfallData'

import {
  bulkUploadFootfallDataService,
  updateFootfallAreasService,
  getFootfallAreasService,
  getFootfallDataAsCsvService,
  createFootfallAreasService,
  updateFootfallAreaService
} from '../services/footfallData'
import { getFileNameFromHeaders } from '../../helpers/api'
import { showToasts } from '../../helpers/toasts'
import { TOAST_TYPE } from '../../constants/other'

function* footfallDataWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_FOOTFALL_DATA_AS_CSV, getFootfallDataAsCsvWorker),
    takeEvery(BULK_UPLOAD_FOOTFALL_DATA, bulkUploadFootfallDataWorker),
    takeEvery(GET_FOOTFALL_AREAS, getFootfallAreasWorker),
    takeEvery(CREATE_FOOTFALL_AREAS, createFootfallAreasWorker),
    takeEvery(UPDATE_FOOTFALL_AREA, updateFootfallAreaWorker),
    takeEvery(DELETE_FOOTFALL_AREAS, deleteFootfallAreasWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getFootfallDataAsCsvWorker({ params }) {
  try {
    const response = yield call(getFootfallDataAsCsvService, params)

    const { data, headers } = response
    // file name is stored in response content-disposition header
    const formattedFilename = getFileNameFromHeaders(headers)

    yield put(getFootfallDataAsCsvSuccess(data, formattedFilename))
  } catch (error) {
    yield put(getFootfallDataAsCsvFailure(error))
  }
}

function* bulkUploadFootfallDataWorker({ data }) {
  try {
    const response = yield call(bulkUploadFootfallDataService, data)
    yield put(bulkUploadFootfallDataSuccess(response))
  } catch (error) {
    yield put(bulkUploadFootfallDataFailure(error))
  }
}

function* getFootfallAreasWorker({ params }) {
  try {
    const response = yield call(getFootfallAreasService, params)
    yield put(getFootfallAreasSuccess(response))
  } catch (error) {
    yield put(getFootfallAreasFailure(error))
  }
}

const createUpdateErrorToast = error => {
  if (error?.errors?.error) {
    showToasts({
      type: TOAST_TYPE.error,
      message: error.errors.error
    })
  }
}

function* createFootfallAreasWorker({ data }) {
  try {
    const response = yield call(createFootfallAreasService, data)
    yield put(createFootfallAreasSuccess(response))
  } catch (error) {
    // represent BE error to toast, as it doesn't have field validation
    createUpdateErrorToast(error)
    yield put(createFootfallAreasFailure(error))
  }
}

function* updateFootfallAreaWorker({ id, data }) {
  try {
    const response = yield call(updateFootfallAreaService, id, data)
    yield put(updateFootfallAreaSuccess(response))
  } catch (error) {
    createUpdateErrorToast(error)
    yield put(updateFootfallAreaFailure(error))
  }
}

function* deleteFootfallAreasWorker({ id }) {
  try {
    // the delete is actually update to status deleted
    const response = yield call(updateFootfallAreasService, id, { status: 'deleted' }, 'PATCH')
    yield put(deleteFootfallAreasSuccess(id, response))
  } catch (error) {
    yield put(deleteFootfallAreasFailure(error))
  }
}

export default footfallDataWatcher

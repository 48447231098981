import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import InfoModalNew from '../../../../../../features/components/Modals/InfoModalNew'
import InfoModalButtons from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons'

import { useModalDescription } from '../useModalDescription'
import { useUpdateContractForApprove } from '../useUpdateContractForApprove'

import { PRIMARY_BUTTON } from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons/helpers'
import { CONTRACT_APPROVED_STATUS } from '../../../../../../constants/contacts'

function ReviewProcessModal({ isOpen, onClose, onDisapprove, data }) {
  const { t } = useTranslation()

  const message = useModalDescription(data)

  const { isLoading, requestSuccess, requestError, handleUpdateContract } = useUpdateContractForApprove({
    contractId: data?.contractId
  })
  const handleSendForApproval = useCallback(() => {
    handleUpdateContract({
      status: CONTRACT_APPROVED_STATUS,
      generate_pdf: 'false' // should be string
    })
  }, [handleUpdateContract])

  // Clicking Disapprove shows a disapproval reason modal and then when submitted sets the status to Disapproved.
  const buttons = useMemo(
    () => [
      {
        type: 'button',
        text: t('Disapprove'),
        onClick: onDisapprove
      },
      // when the user approves it just closes the modal and updates the status.
      {
        type: 'progress',
        text: t('Approve'),
        buttonColor: PRIMARY_BUTTON,
        onClick: handleSendForApproval,
        successSubmit: requestSuccess,
        isFormLoading: isLoading,
        formError: requestError,
        onSuccessSubmit: onClose
      }
    ],
    [handleSendForApproval, isLoading, onClose, onDisapprove, requestError, requestSuccess, t]
  )

  return (
    <InfoModalNew
      modalTitle={t('Discounting approval required')}
      ModalMessage={message}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InfoModalButtons buttons={buttons} />
    </InfoModalNew>
  )
}

ReviewProcessModal.propTypes = {
  onApprove: PropTypes.func,
  onDisapprove: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object
}

export default ReviewProcessModal

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '../../../../../helpers/numbers'

export function useModalDescription(data) {
  const { t } = useTranslation()

  return useMemo(() => {
    const proposalCost = formatCurrency(data?.proposalCost, { min: 2, max: 2 }, { symbol: data?.currencySymbol })
    const calculatedRateCost = formatCurrency(
      data?.calculatedRateCost,
      { min: 2, max: 2 },
      { symbol: data?.currencySymbol }
    )

    return t('discountApprovalRequired', { proposalCost, calculatedRateCost })
  }, [data?.calculatedRateCost, data?.currencySymbol, data?.proposalCost, t])
}

import { v4 as uuidv4 } from 'uuid'

import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  initialNotificationEmailSection,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../../../forms/ReusableFormFields/RecipientsFields/fields'

export const getInitialValues = (members = []) => {
  return {
    [USER_OPTION]: members.length ? SEND_TO_PLATFORM_USER : SEND_BY_EMAIL,
    [NOTIFICATION_USERS]: [],
    [NOTIFICATION_EMAILS]: [{ ...initialNotificationEmailSection, id: uuidv4() }]
  }
}

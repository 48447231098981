export const tableColumnsSize = {
  stores: 90,
  actions: 30
}

export const columnsStyle = {
  name: { maxWidth: tableColumnsSize.name },
  stores: { maxWidth: tableColumnsSize.stores }
}

export const columnsSchema = [
  {
    fieldKey: 'name'
  },
  {
    fieldKey: 'stores',
    style: { ...columnsStyle.stores }
  },
  {
    style: { maxWidth: tableColumnsSize.actions }
  }
]

import React from 'react'

import ProductSubCategoriesTable from './ProductSubCategoriesTable'
import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import MediaSubCategoryEdit from '../../../../forms/Multiplatform/MediaProducts/MediaSubCategory/MediaSubCategoryEdit'
import MediaSubCategoryCreate from '../../../../forms/Multiplatform/MediaProducts/MediaSubCategory/MediaSubCategoryCreate'

const ProductSubCategories = () => {
  return (
    <ContentRow
      title="Asset Types"
      description="Categorise media products for easier filtering and reporting."
      leftColumnChildren={<MediaSubCategoryCreate />}
    >
      <ProductSubCategoriesTable />
      <MediaSubCategoryEdit />
    </ContentRow>
  )
}

export default ProductSubCategories

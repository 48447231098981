import React, { useCallback, useEffect, useMemo, useState } from 'react'
import history from '../../../../history'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DownloadContract from '../DownloadContract'
import DrawerHeadline from '../../../../components/Drawer/DrawerHeadline'
import DrawerHeadlineSkeleton from '../../../../components/Drawer/DrawerHeadline/Skeleton'
import ResponsiveContractPdfViewer from '../../../../features/components/Contract/ResponsiveContractPdfViewer'
import PdfPageSkeleton from '../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'

import ContractMediaOrderGenerate from '../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate'

import useQuotationData from '../../../Proposals/QuotationReviewDrawer/useQuotationData'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getContractErrorSelector } from '../../../../modules/selectors/contracts'

import { CONTRACT_DELETED_STATUS, CONTRACT_MEDIA_ORDER_CREATED_STATUS } from '../../../../constants/contacts'
import useStyles from './styles'
import useQuotationSignStyles from '../styles'

function QuotationSignContent({ onMediaOrderCreate }) {
  const classes = useStyles()
  const quotationSignClasses = useQuotationSignStyles()
  const { t } = useTranslation()

  // if token is expired we get an access error, also handle regular error:
  const [loadContractError, setLoadContractError] = useState()

  const getContractError = useSelector(getContractErrorSelector)

  // token param is used to provide auth access to not registered users
  // itemId is contract/quotation id
  const { token, itemId } = createJsonFromQueryString(history.location.search, false)

  const tokenParams = useMemo(() => {
    return { token }
  }, [token])

  const { quotationContract, isDataLoading, handleClearSelectedContract } = useQuotationData(itemId, tokenParams)

  const handleSuccessCreate = useCallback(() => {
    handleClearSelectedContract()
    onMediaOrderCreate(quotationContract)
  }, [quotationContract, onMediaOrderCreate, handleClearSelectedContract])

  useEffect(() => {
    // set error to local state as 401 error logout and is cleaning up the redux store
    if (getContractError) {
      setLoadContractError(getContractError)
    }
  }, [getContractError, setLoadContractError])

  if (loadContractError) {
    return (
      <div className={quotationSignClasses.infoMessageContainer}>
        <h2>{t('Sorry, that link has expired')}</h2>
        <p>{t('Please ask your account manager to resend the quotation')}</p>
      </div>
    )
  }

  const pdfFileUrl = quotationContract?.file
  // signed and booked contract:
  const wasMediaOrderCreated =
    quotationContract?.status === CONTRACT_MEDIA_ORDER_CREATED_STATUS ||
    quotationContract?.status === CONTRACT_DELETED_STATUS
  const allowDownload = Boolean(pdfFileUrl) && wasMediaOrderCreated

  if (isDataLoading) {
    return (
      <div className={classes.pdfContainer}>
        <DrawerHeadlineSkeleton hasTitle hasDescription />
        <PdfPageSkeleton />
      </div>
    )
  }

  if (wasMediaOrderCreated) {
    return (
      <div className={classes.pdfContainer}>
        <DrawerHeadline
          title={t('Booking Contract')}
          description={t('This booking contract has already been signed and submitted.')}
        />
        {allowDownload && (
          <div className={classes.downloadSection}>
            <DownloadContract contractId={quotationContract.id} className={classes.download} />
          </div>
        )}
        <ResponsiveContractPdfViewer pdfFile={pdfFileUrl} onLastPageClick={null} showPagesCount={true} />
      </div>
    )
  }

  return (
    <div className={classes.pdfContainer}>
      {/* Current page headline design is the same as Drawer headline design, so the Drawer component is used*/}
      <DrawerHeadline
        title={t('Review, sign and book')}
        description={t('Please review, sign and submit the booking contract.')}
      />
      <ContractMediaOrderGenerate
        contract={quotationContract}
        tokenParams={tokenParams}
        onSuccessSubmit={handleSuccessCreate}
      />
    </div>
  )
}

export default QuotationSignContent

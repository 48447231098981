import * as Yup from 'yup'
import { MEDIA_CATEGORY, NAME } from './fields'
import { MEDIA_SUB_SUB_CATEGORY } from '../MediaProductForms/fields'

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name required'),
  [MEDIA_CATEGORY]: Yup.string().required('Category required'),
  [MEDIA_SUB_SUB_CATEGORY]: Yup.array().of(
    Yup.object().shape({
      [NAME]: Yup.string().required('Name required')
    })
  )
})

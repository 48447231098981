import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import ProductVariablesList from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/ProductVariablesList'
import SelectedPeriodsList from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/PackageBreakdown/ProductBreakdown/SelectedPeriodsList'
import { DiscountsContext } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext'

import { VARIABLES } from '../../../../../MediaProducts/MediaProductForms/fields'
import { PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'

import useStyles from './styles'

function ProductBreakdown({ productValues, productData, currency, isSmall }) {
  const classes = useStyles()

  const { productsDiscount } = useContext(DiscountsContext)

  const isProductGroup = productData?.group
  const productsQuantity = productValues[QUANTITY]

  const currentDiscount = productsDiscount.find(
    discountProduct => discountProduct.id === productData.id
  )?.discountPercentage

  return (
    <div className={classes.product} key={productData.id}>
      <div className={classes.productDetails}>
        <h4 className={classes.name}>{productData.name}</h4>
      </div>
      {isProductGroup && (
        <div className={classes.subProductsList}>
          {productData?.selectedSubProducts?.map(subProduct => subProduct.name).join(' | ')}
        </div>
      )}
      <ProductVariablesList variables={productValues[VARIABLES]} />
      <SelectedPeriodsList
        currencySymbol={currency.symbol}
        productPeriods={productValues[PRODUCT_PERIODS_DATES]}
        currentDiscount={currentDiscount}
        productsQuantity={productsQuantity}
        showPeriodPrice
        listItemClassName={isSmall ? classes.smallPeriods : null}
      />
    </div>
  )
}

ProductBreakdown.propTypes = {
  productValues: PropTypes.object.isRequired,
  productData: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  isSmall: PropTypes.bool
}

export default ProductBreakdown

export const USER_OPTION = 'user_option'
export const SEND_TO_PLATFORM_USER = 'send_to_platform_user'
export const SEND_BY_EMAIL = 'send_by_email'
export const NOTIFICATION_USERS = 'notification_users'
export const NOTIFICATION_EMAILS = 'notification_emails'
export const EMAIL = 'email'
export const FIRST_NAME = 'first_name'

export const initialNotificationEmailSection = {
  [EMAIL]: '',
  [FIRST_NAME]: ''
}

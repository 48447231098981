import React from 'react'

import Field from '../../../components/Form/Field'

import {
  DISCOUNT_PAIRS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_QUANTITY
} from '../../Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const DiscountPairsFields = ({ formik, index }) => {
  const classes = useStyles()

  return (
    <>
      <Field
        type="number"
        formik={formik}
        name={`${DISCOUNT_PAIRS}[${index}].${DISCOUNT_PERCENTAGE}`}
        className={classes.percentageField}
        placeholder="Discount"
        autoComplete="off"
        symbol="%"
        symbolPosition="end"
        decimalScale={2}
      />
      <Field
        type="number"
        formik={formik}
        className={classes.valueField}
        name={`${DISCOUNT_PAIRS}[${index}].${DISCOUNT_QUANTITY}`}
        placeholder="Quantity"
        autoComplete="off"
      />
    </>
  )
}

export default DiscountPairsFields

import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import FormDrawerWrapper from '../../../../features/components/FormDrawerWrapper'
import AddCommentsToUploadedFilesForm from './AddCommentsToUploadedFilesForm'

import { createJsonFromQueryString } from '../../../../helpers/url'
import useManageFormsDrawer from '../../../../hooks/formHooks/useManageFormsDrawer'

import { bookedMediaSelector, bookedMediaWasLoadedSelector } from '../../../../modules/selectors/mediaOrdersBookings'

import { ADD_COMMENTS_TO_UPLOADED_FILES } from '../../../../constants/forms'

function AddCommentsToUploadedFiles() {
  const bookedMedia = useSelector(bookedMediaSelector)
  const { mediaId } = createJsonFromQueryString(window.location.search)
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: ADD_COMMENTS_TO_UPLOADED_FILES
  })

  const bookedMediaWasLoaded = useSelector(bookedMediaWasLoadedSelector)

  const selectedFileId = Number(selectedEditItemId)

  const selectedFileMediaData = useMemo(
    () => bookedMedia.find(media => media.id === Number(mediaId)),
    [bookedMedia, mediaId]
  )

  const uploadedFilesFromAllBookedMedia = useMemo(() => {
    return bookedMedia.reduce((acc, media) => {
      return acc.concat(media.uploaded_files)
    }, [])
  }, [bookedMedia])

  return (
    <FormDrawerWrapper
      formName={ADD_COMMENTS_TO_UPLOADED_FILES}
      showOpenButton={false}
      hasDefaultDrawerContentSpacing={false}
      isWideDrawer
      isFormLoading={!bookedMediaWasLoaded || !selectedFileMediaData}
    >
      <AddCommentsToUploadedFilesForm
        selectedFileMediaData={selectedFileMediaData}
        selectedFileId={selectedFileId}
        uploadedFiles={uploadedFilesFromAllBookedMedia}
      />
    </FormDrawerWrapper>
  )
}

export default AddCommentsToUploadedFiles

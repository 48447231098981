import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'
import StoresPaginatedSelectField from '../../StoresPaginatedSelectField'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'

import { createFootfallAreas, clearCreateFootfallAreas } from '../../../../../../modules/actions/footfallData'
import {
  createFootfallAreasErrorSelector,
  createFootfallAreasIsLoadingSelector,
  footfallAreasWasCreatedSelector
} from '../../../../../../modules/selectors/footfallData'

import { FOOTFALL_AREAS_CREATE } from '../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'
import { transformValuesToBE } from './formatters'
import { NAME } from '../../fields'

function CreateFootfallAreasForm() {
  const dispatch = useDispatch()

  const footfallAreasWasCreated = useSelector(footfallAreasWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(createFootfallAreas(transformedData))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemHandler = useCallback(() => dispatch(clearCreateFootfallAreas()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={FOOTFALL_AREAS_CREATE}
      // processing
      successSubmit={footfallAreasWasCreated}
      errorSelector={createFootfallAreasErrorSelector}
      isLoadingSelector={createFootfallAreasIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemHandler}
    >
      <FieldsSection title="Footfall Areas">
        <FieldRow title="Name">
          <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
        </FieldRow>
        <FieldRow title="Stores">
          <StoresPaginatedSelectField formik={formik} />
        </FieldRow>
      </FieldsSection>
    </Form>
  )
}

export default CreateFootfallAreasForm

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../components/Form/ProgressButton'

import { downloadCSVFile } from '../../../../helpers/other'

import { clearGetFootfallDataAsCsv, getFootfallDataAsCsv } from '../../../../modules/actions/footfallData'
import {
  footfallDataAsCsvSelector,
  footfallDataAsCsvWasLoadedSelector,
  footfallDataFileNameSelector,
  getFootfallDataAsCsvErrorSelector,
  getFootfallDataAsCsvIsLoadingSelector
} from '../../../../modules/selectors/footfallData'

import useStyles from './styles'

const FootfallDataDownloadCsv = ({ paramsData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const isLoading = useSelector(getFootfallDataAsCsvIsLoadingSelector)
  const error = useSelector(getFootfallDataAsCsvErrorSelector)
  const wasLoaded = useSelector(footfallDataAsCsvWasLoadedSelector)
  const csvData = useSelector(footfallDataAsCsvSelector)
  const csvFileName = useSelector(footfallDataFileNameSelector)

  const clearHandler = () => {
    dispatch(clearGetFootfallDataAsCsv())
  }

  const handleOnClick = () => {
    dispatch(getFootfallDataAsCsv(paramsData))
  }

  const handleDownloadCSVFile = useCallback(() => {
    downloadCSVFile({ data: csvData, fileName: csvFileName || 'footfallData' })
  }, [csvData, csvFileName])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.downloadButtonWrapper}
      solid={false}
    >
      {t('Download footfall data')}
    </ProgressButton>
  )
}

export default FootfallDataDownloadCsv

import React, { Fragment, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import GroupNameCol from './GroupNameCol'
import CalendarEventBar from '../CalendarEventBar'

import { resizeRow } from './helpers'

import useStyles from './styles'

const CalendarRow = React.memo(
  ({ renderEvents, product, colsCount, startDate, endDate, timeToPx, tableInnerRowWidth, isSkeleton }) => {
    const rowRef = useRef(null)
    const classes = useStyles()

    const eventGroupId = product.group.id

    useEffect(() => {
      resizeRow({ rowElement: rowRef.current })
    })

    return (
      <tr
        className={classes.row}
        key={eventGroupId}
        ref={el => {
          rowRef.current = el
        }}
        style={{ height: isSkeleton && '37px' }}
      >
        <GroupNameCol eventGroupData={product.group} />
        <td className={classes.idContainer} key="asset_id">
          <div className={classnames(classes.id)}>{product.group.asset_id}</div>
        </td>
        {[...Array(colsCount)].map((_, index) => (
          <td
            key={`${eventGroupId}-${index}`}
            className={classnames('js-so-inner-col', classes.innerCol, {
              [classes.eventsContainerCol]: index === 0
            })}
          >
            {index === 0 &&
              renderEvents &&
              product.events?.map(event => {
                return (
                  <Fragment key={`${eventGroupId}-${index}-${event.start_timestamp}-${event.end_timestamp}`}>
                    <CalendarEventBar
                      event={event}
                      timeToPx={timeToPx}
                      calendarStartDate={startDate}
                      calendarEndDate={endDate}
                      tableInnerRowWidth={tableInnerRowWidth}
                    />
                  </Fragment>
                )
              })}
          </td>
        ))}
      </tr>
    )
  }
)

CalendarRow.propTypes = {
  product: PropTypes.object.isRequired,
  // helps to optimize rendering when we need to render only rows
  renderEvents: PropTypes.bool.isRequired,
  colsCount: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  timeToPx: PropTypes.array.isRequired,
  tableInnerRowWidth: PropTypes.number.isRequired,
  isSkeleton: PropTypes.bool
}

export default CalendarRow

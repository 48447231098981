import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import RecipientInfo from './RecipientInfo'
import Form from '../../../../../../components/Form'
import FieldsSection from '../../../../../../features/components/Form/FieldsSection'
import RecipientsTabs from '../../../../../ReusableFormFields/RecipientsFields/RecipientsTabs'

import { transformValuesToBE } from './formatters'
import { showToasts } from '../../../../../../helpers/toasts'

import { clearSendQuotation, sendQuotation } from '../../../../../../modules/actions/quotations'

import {
  sendQuotationErrorSelector,
  sendQuotationIsLoadingSelector,
  sendQuotationWasSentSelector
} from '../../../../../../modules/selectors/quotations'
import { validationSchema } from './validation'
import { initialValues } from './fields'
import { PROPOSAL_SEND } from '../../../../../../constants/forms'
import { TOAST_TYPE } from '../../../../../../constants/other'

function ProposalSendForm({ quotationData }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const contractId = quotationData?.id

  const quotationWasSent = useSelector(sendQuotationWasSentSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)
      dispatch(
        sendQuotation(
          {
            ...transformedData,
            without_requirements: false // enable sign and expiration process for contract
          },
          contractId
        )
      )
    },
    [dispatch, contractId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const proposalSentUser = quotationData?.quotation_receiver_user?.id
  const filterSelfAccountMembers = useMemo(() => (proposalSentUser ? [proposalSentUser] : []), [proposalSentUser])

  const handleSuccessSubmit = useCallback(() => {
    showToasts({
      type: TOAST_TYPE.success,
      message: t('The proposal was sent successfully')
    })
  }, [t])

  const clearItemSubmit = useCallback(() => dispatch(clearSendQuotation()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={PROPOSAL_SEND}
      // processing
      successSubmit={quotationWasSent}
      errorSelector={sendQuotationErrorSelector}
      isLoadingSelector={sendQuotationIsLoadingSelector}
      // after form submit
      onSuccessSubmit={handleSuccessSubmit}
      clearHandler={clearItemSubmit}
      submitText={'Send Proposal'}
    >
      {quotationData?.quotation_sent && <RecipientInfo quotationData={quotationData} />}
      <FieldsSection title={t('Select new recipient')}>
        <RecipientsTabs
          formik={formik}
          maxRecipientsCount={1}
          selfAccountId={quotationData?.account}
          selfAccountMembersIdsToFilter={filterSelfAccountMembers}
        />
      </FieldsSection>
    </Form>
  )
}

ProposalSendForm.propTypes = {
  selfAccountId: PropTypes.number,
  quotationData: PropTypes.object
}

export default ProposalSendForm

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductVariableEditForm from './MediaProductVariableEditForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'
import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import {
  mediaProductVariableOptionsNextSelector,
  mediaProductVariableOptionsWasLoadedSelector,
  mediaProductVariablesSelector,
  mediaProductVariablesWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProductVariables'
import {
  clearGetMediaProductVariableOptions,
  getMediaProductVariableOptions
} from '../../../../../modules/actions/mediaOrdersProductVariables'

import { MEDIA_PRODUCT_VARIABLE_EDIT } from '../../../../../constants/forms'

const MediaProductVariableEdit = () => {
  const dispatch = useDispatch()
  const mediaProductVariables = useSelector(mediaProductVariablesSelector)
  const mediaProductVariablesWasLoaded = useSelector(mediaProductVariablesWasLoadedSelector)

  const { isFormOpen, selectedEditItemId } = useManageFormsDrawer({
    formName: MEDIA_PRODUCT_VARIABLE_EDIT
  })

  const mediaProductVariableOptionsWasLoaded = useSelector(
    mediaProductVariableOptionsWasLoadedSelector(selectedEditItemId)
  )
  const mediaProductVariableOptionsNext = useSelector(mediaProductVariableOptionsNextSelector(selectedEditItemId))

  const editProductVariableData = useMemo(
    () => mediaProductVariables.find(item => item.id === Number(selectedEditItemId)),
    [mediaProductVariables, selectedEditItemId]
  )

  const isFormLoading = useMemo(
    () => !editProductVariableData || !mediaProductVariablesWasLoaded || !mediaProductVariableOptionsWasLoaded,
    [editProductVariableData, mediaProductVariablesWasLoaded, mediaProductVariableOptionsWasLoaded]
  )

  const onAfterFormCloseHandler = useCallback(() => {
    dispatch(clearGetMediaProductVariableOptions())
  }, [dispatch])

  const variableOptionsParams = useMemo(
    () => ({
      media_product_variable: selectedEditItemId
    }),
    [selectedEditItemId]
  )

  const loadMoreVariableOptionsHandler = useLoadPaginatedList({
    allowFetch: isFormOpen && selectedEditItemId,
    params: variableOptionsParams,
    action: getMediaProductVariableOptions,
    clearAction: clearGetMediaProductVariableOptions,
    next: mediaProductVariableOptionsNext
  })

  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_VARIABLE_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product variable"
      showOpenButton={false}
      onAfterFormClose={onAfterFormCloseHandler}
    >
      <MediaProductVariableEditForm
        editProductVariableData={editProductVariableData}
        loadMoreVariableOptionsHandler={loadMoreVariableOptionsHandler}
      />
    </FormDrawerWrapper>
  )
}

export default MediaProductVariableEdit

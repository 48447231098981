import * as Yup from 'yup'

import { nameValidation } from '../../../../../../pages/Settings/AdvertiserAccounts/ClientAccountForms/fields'
import { PRICE_PAIRS, pricePairsValidation } from '../../../../../ReusableFormFields/PricePairFields/fields'
import {
  CREATIVE_DEADLINE_DAYS,
  CUSTOM_DISCOUNT_CHECKBOX,
  DEFAULT_INVENTORY_DATE_START,
  DISCOUNT_PAIRS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_QUANTITY,
  DISCOUNTS_TAB_CUSTOM,
  DISCOUNTS_TABS,
  FILE_SPECIFICATION_TYPE,
  FILE_SPECIFICATION_VALUE,
  FILE_SPECIFICATIONS_PAIRS,
  FILES_REQUIREMENTS,
  FILES_REQUIREMENTS_NAME,
  IMAGES,
  INVENTORY_QUANTITY,
  INVENTORY_START_DATE,
  MEDIA_CATEGORY,
  PLATFORMS
} from './fields'

import { DIGITAL } from '../../../../../../constants/mediaOrders'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD } from '../../fields'
import { inventoryValidationTest, minMaxPeriodsValidation, platformsValidation } from '../../validation'
import { FILE_NAME } from '../../../../../../constants/files'

export const discountPairsValidation = Yup.array().of(
  Yup.object().shape({
    [DISCOUNT_PERCENTAGE]: Yup.number().required('Discount Required'),
    [DISCOUNT_QUANTITY]: Yup.number().required('Quantity Required')
  })
)

export const inventoryPairsValidation = Yup.array().of(
  Yup.object().shape({
    [INVENTORY_START_DATE]: Yup.string().required('Start Date Required'),
    [INVENTORY_QUANTITY]: Yup.number().required('Required') // there is no space for longer error messages
  })
)

export const filesRequirementsValidation = {
  [FILES_REQUIREMENTS]: Yup.array().of(
    Yup.object().shape({
      [FILES_REQUIREMENTS_NAME]: Yup.string().required('Name Required'),
      // [FILE_SPECIFICATIONS_PAIRS]: Yup.array().test({
      //   // we need to validate that value is selected when type is selected
      //   // here are variables FILE_SPECIFICATION_TYPE, FILE_SPECIFICATION_VALUE
      //   // write validation
      //   message: 'Select specification type and value',
      //   test: fileSpecificationsPairs => {
      //     return fileSpecificationsPairs.every(pair => pair[FILE_SPECIFICATION_TYPE] && pair[FILE_SPECIFICATION_VALUE])
      //   }
      // })
      [FILE_SPECIFICATIONS_PAIRS]: Yup.array().of(
        Yup.object().shape({
          [FILE_SPECIFICATION_TYPE]: Yup.string().required('Type Required'),
          [FILE_SPECIFICATION_VALUE]: Yup.string().required('Value Required')
        })
      )
    })
  )
}

export const imageValidation = Yup.array().test({
  message: 'Please upload an image',
  // ensure that we don't have empty file_name
  test: images => !!images[0][FILE_NAME]
})

export const validationSchema = Yup.object({
  ...nameValidation,
  [PRICE_PAIRS]: pricePairsValidation,
  [DISCOUNT_PAIRS]: Yup.array().when([DISCOUNTS_TABS, CUSTOM_DISCOUNT_CHECKBOX], {
    is: (discountsTab, discountCheckbox) => discountsTab === DISCOUNTS_TAB_CUSTOM || discountCheckbox,
    then: () => discountPairsValidation
  }),
  [CREATIVE_DEADLINE_DAYS]: Yup.number().when(MEDIA_CATEGORY, {
    is: DIGITAL,
    then: () => Yup.number().notRequired(),
    otherwise: () => Yup.number().required('Creative Deadline Days Required')
  }),
  [PLATFORMS]: platformsValidation,
  [INVENTORY_AMOUNT]: inventoryValidationTest(INVENTORY_AMOUNT, 'Inventory required'),
  [INVENTORY_PERIOD]: inventoryValidationTest(INVENTORY_PERIOD, 'Number of periods required'),
  [DEFAULT_INVENTORY_DATE_START]: inventoryValidationTest(DEFAULT_INVENTORY_DATE_START, 'Start Date Required'),

  ...minMaxPeriodsValidation,
  ...filesRequirementsValidation,
  [IMAGES]: imageValidation
})

import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import CategoriesSelectPaginated from './CategoriesSelectPaginated'
import Field from '../../../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../../../features/formatters'

import {
  clearGetSelfAccountSubCategories,
  getSelfAccountSubCategories
} from '../../../../../../../../modules/actions/selfAccounts'
import {
  selfAccountSubCategoriesIsLoadingSelector,
  selfAccountSubCategoriesSelector,
  selfAccountSubCategoriesWasLoadedSelector
} from '../../../../../../../../modules/selectors/selfAccounts'
import { selectedControllerIdSelector } from '../../../../../../../../modules/selectors/app'

import { CATEGORY, SUBCATEGORY } from '../../../../fields'

const CategoriesRows = ({ formik, itemPath, isActive }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { values, setFieldValue } = formik

  const subCategories = useSelector(selfAccountSubCategoriesSelector)
  const getSubCategoriesIsLoading = useSelector(selfAccountSubCategoriesIsLoadingSelector)
  const subCategoriesWasLoaded = useSelector(selfAccountSubCategoriesWasLoadedSelector)

  const categorySelectName = `${itemPath}.${CATEGORY}`
  const selectedCategory = getIn(values, categorySelectName)

  const subCategorySelectName = `${itemPath}.${SUBCATEGORY}`
  const controllerId = useSelector(selectedControllerIdSelector)

  const showSubCategoryRow = useMemo(() => {
    return (
      (selectedCategory && getSubCategoriesIsLoading) ||
      (selectedCategory && subCategoriesWasLoaded && subCategories.length > 0)
    )
  }, [selectedCategory, getSubCategoriesIsLoading, subCategoriesWasLoaded, subCategories])

  const formattedSubCategoriesOptions = useMemo(
    () =>
      formatOptionsList({
        list: subCategories,
        valueName: 'id',
        labelName: 'name'
      }),
    [subCategories]
  )

  const onCategoryChangeHandler = useCallback(() => {
    // Clear subcategory field when category is changed
    setFieldValue(subCategorySelectName, '')
  }, [setFieldValue, subCategorySelectName])

  useEffect(() => {
    if (selectedCategory && isActive) {
      dispatch(
        getSelfAccountSubCategories({
          category: selectedCategory.value,
          controller: controllerId
        })
      )
    }
  }, [dispatch, selectedCategory, controllerId, isActive])

  useEffect(() => {
    return () => {
      dispatch(clearGetSelfAccountSubCategories())
    }
  }, [dispatch])

  return (
    <>
      <FieldRow title={t('Category')}>
        <CategoriesSelectPaginated formik={formik} itemPath={itemPath} onCategoryChange={onCategoryChangeHandler} />
      </FieldRow>
      {showSubCategoryRow && (
        <FieldRow title={t('Subcategory')}>
          {getSubCategoriesIsLoading ? (
            <Skeleton height={38} />
          ) : (
            <Field
              name={subCategorySelectName}
              formik={formik}
              placeholder="Select subcategory"
              options={formattedSubCategoriesOptions}
            />
          )}
        </FieldRow>
      )}
    </>
  )
}

CategoriesRows.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  isActive: PropTypes.bool
}

export default CategoriesRows

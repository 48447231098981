import React, { useMemo } from 'react'
import { getIn } from 'formik'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

const ProductNestedFieldCell = ({ productValues, productFieldPath, children }) => {
  const productData = productValues.data
  const isProductLoading = productValues.isLoading
  const isProductSelected = !!productData
  const isProductAdded = isProductLoading || isProductSelected

  const productFieldName = useMemo(() => getIn(productData, productFieldPath), [productData, productFieldPath])

  if (isProductAdded) {
    if (isProductSelected) {
      return productFieldName || '-'
    } else {
      return <Skeleton width="100%" />
    }
  } else {
    return children
  }
}

ProductNestedFieldCell.propTypes = {
  productValues: PropTypes.object.isRequired,
  productFieldPath: PropTypes.string.isRequired
}

export default ProductNestedFieldCell

import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import ActionsDropdown from '../../../../../../features/components/ActionsDropdown'
import ActionsButtonsList from '../../../../../../features/components/ActionsButtonsList'

import { openForm } from '../../../../../../modules/actions/forms'

import { MEDIA_PRODUCT_LOCATION_EDIT } from '../../../../../../constants/forms'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const ProductLocationsActions = ({ location, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { id } = location

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: MEDIA_PRODUCT_LOCATION_EDIT }))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      }
    ],
    [t, openEditFormHandler]
  )

  return isMobile ? (
    <ActionsButtonsList options={dropdownOptions} className={className} />
  ) : (
    <ActionsDropdown options={dropdownOptions} />
  )
}

ProductLocationsActions.propTypes = {
  location: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default ProductLocationsActions

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import MediaOrderNameCell from '../../MediaOrderTableCells/MediaOrderNameCell'
import Table from '../../../../components/Table'
import AdaptiveActions from '../../AdaptiveActions'
import MediaOrderCostCell from '../../MediaOrderTableCells/MediaOrderCostCell'
import Chip from '../../../../components/Chip'
import AccountBrandCell from '../../../../components/Table/ReusableCells/AccountBrandCell'

import { useRedirectToMediaOrderSummary } from './hooks'

import { mediaOrdersSelector } from '../../../../modules/selectors/mediaOrders'

import { formatDateFullYear } from '../../../../constants/dates'
import { bookedMediaStatusesLabels, statusColors } from '../../../../constants/bookedMedia'
import { phonesDownSize } from '../../../../styles/const/breakpoints'

import { red } from '../../../../styles/const/colors'
import useStyles from './styles'

const MediaOrdersTableData = ({ tableColumnMinSize, tableColumnsSize }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const mediaOrders = useSelector(mediaOrdersSelector)

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const goToMediaOrderSummaryHandler = useRedirectToMediaOrderSummary()

  const actionOptions = useMemo(() => {
    return [
      {
        text: t('Go to media order'),
        onClickHandler: (e, id) => goToMediaOrderSummaryHandler(id)
      }
    ]
  }, [t, goToMediaOrderSummaryHandler])

  const mediaOrdersColumns = useMemo(
    () => [
      {
        header: 'ID',
        Cell: ({ sequential_id, id }) => <span onClick={() => goToMediaOrderSummaryHandler(id)}>{sequential_id}</span>,
        style: { maxWidth: tableColumnsSize.id, cursor: 'pointer' }
      },
      {
        header: 'Name',
        Cell: mediaOrder => (
          <MediaOrderNameCell
            mediaOrder={mediaOrder}
            onClick={isMobile ? undefined : goToMediaOrderSummaryHandler}
            showIcon={false}
            infoTextClassName={classes.infoTextClassName}
          />
        ),
        showOnMobile: true,
        style: { maxWidth: tableColumnsSize.name, minWidth: tableColumnMinSize.name, whiteSpace: 'normal' }
      },
      // next coulms are not used right now but probably be used in future, so the code is left commented out
      // {
      //   header: 'Media',
      //   Cell: ({ products_names: productsNames }) => {
      //     const { fieldName, moreItemText } = generateMediaFieldText(productsNames)
      //     return <MediaCell mediaText={fieldName} moreProductsText={moreItemText} />
      //   },
      //   showOnMobile: false,
      //   style: isMobile
      //     ? mediaColumnSizesMobile
      //     : { maxWidth: tableColumnsSize.media, minWidth: tableColumnsSize.media - 200 }
      // },
      // {
      //   header: 'Requested Files',
      //   Cell: ({ outstanding_required_files, id }) => {
      //     const hasRequiredFiles = outstanding_required_files > 0
      //     return (
      //       hasRequiredFiles && (
      //         <RequestedFilesCell
      //           id={id}
      //           onClick={goToMediaOrderSummaryHandler}
      //           outstandingRequiredFiles={outstanding_required_files}
      //         />
      //       )
      //     )
      //   },
      //   showOnMobile: false,
      //   style: { maxWidth: tableColumnsSize.requestedFiles }
      // },
      {
        header: 'Account',
        Cell: ({ account_name, brand_name }) => {
          return <AccountBrandCell account={account_name} brand={brand_name} />
        },
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.account
        }
      },
      {
        header: 'Campaign Name',
        Cell: ({ campaign_name }) => campaign_name || '-',
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.campaignName,
          minWidth: tableColumnMinSize.campaignName,
          whiteSpace: 'normal'
        }
      },
      {
        header: 'Created',
        Cell: ({ created }) => (created ? formatDateFullYear(created) : '-'),
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.created, minWidth: tableColumnMinSize.created }
      },
      {
        header: 'Created By',
        Cell: ({ created_by = {} }) => {
          const { username } = created_by
          return username || '-'
        },
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.createdBy, minWidth: tableColumnMinSize.createdBy, whiteSpace: 'normal' }
      },
      {
        header: 'Amended',
        Cell: ({ last_amendment_date }) => (last_amendment_date ? formatDateFullYear(last_amendment_date) : '-'),
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.lastAmended,
          minWidth: tableColumnMinSize.lastAmended,
          whiteSpace: 'normal'
        }
      },
      {
        header: 'Cost',
        Cell: mediaOrder => <MediaOrderCostCell mediaOrder={mediaOrder} />,
        showOnMobile: false,
        // numerical column right aligned
        style: { maxWidth: tableColumnsSize.cost, minWidth: tableColumnMinSize.cost, justifyContent: 'flex-end' }
      },
      {
        header: 'Status',
        Cell: ({ status }) => {
          return status ? <Chip text={bookedMediaStatusesLabels[status]} color={statusColors[status]} /> : '-'
        },
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.status, minWidth: tableColumnMinSize.status }
      },
      {
        Cell: ({ id }) => <AdaptiveActions itemId={id} options={actionOptions} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [tableColumnsSize, tableColumnMinSize, actionOptions, goToMediaOrderSummaryHandler, isMobile, classes]
  )

  const rowTemplate = useMemo(
    () => ({
      rowClassName: classes.row,
      columnClassName: classes.column,
      // cancelled media order has marker on the row
      determineRowMarker: rowData => rowData.status === 'cancelled',
      markerColor: red
    }),
    [classes]
  )

  return <Table data={mediaOrders} cols={mediaOrdersColumns} hideFooterRow rowTemplate={rowTemplate} />
}

export default MediaOrdersTableData

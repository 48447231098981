import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ControllerInvites from './ControllerInvites'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ContentSection from '../../../features/components/ContentSection'
import ControllerInviteCreate from './ControllerInviteCreate'
import DeactivateUserModal from '../ManageSelfAccountTeam/DeactivateUserModal'
import ControllerPermissionsEdit from './ControllerPermissionsEdit'
import ControllerMemberEdit from './ControllerMemberEdit'
import ControllerMembersTable from './ControllerMembersTable'

import FiltersSearch from '../../../features/components/Filters/FiltersSearch'
import useSearch from '../../../hooks/useSearch'

import useModalManage from '../../../hooks/useModalManage'
import { useLoadPaginatedList } from '../../../features/hooks/useLoadPaginatedList'
import {
  clearControllerMembers,
  clearUpdateControllerMember,
  getControllerMembers,
  updateControllerMember,
  updateControllerMemberStatus
} from '../../../modules/actions/controller'

import { controllerMembersNextSelector, updatedControllerMemberSelector } from '../../../modules/selectors/controller'

import { selectedControllerDataSelector } from '../../../modules/selectors/app'

import { MEMBER_CHANGE_DATA, MEMBER_CHANGE_STATUS } from '../../../constants/forms'
import useSettingsClasses from '../styles'
import useStyles from './styles'

export const CONTROLLER_USERS_SEARCH = 'controller_users_search'

function ManageControllerTeam() {
  const classes = useStyles()
  const settingsClasses = useSettingsClasses()

  const [memberId, setMemberId] = useState(null)

  const searchTerm = useSearch(CONTROLLER_USERS_SEARCH)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedController = useSelector(selectedControllerDataSelector)
  const controllerMembersNext = useSelector(controllerMembersNextSelector)

  const member = useSelector(updatedControllerMemberSelector)
  const { wasUpdated: memberWasUpdated, updatingType } = member

  const memberStatusWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_STATUS
  const memberDataWasUpdated = memberWasUpdated && updatingType === MEMBER_CHANGE_DATA

  const { isModalOpened, closeModalHandler, openModalHandler } = useModalManage({
    triggerClose: memberStatusWasUpdated
  })

  const changeMemberStatusHandler = useCallback(
    ({ id, isActive }) => {
      if (isActive) {
        setMemberId(id)
        openModalHandler()
      } else {
        dispatch(updateControllerMemberStatus(id, 'inactive'))
      }
    },
    [dispatch, openModalHandler]
  )

  const changeMemberRoleHandler = useCallback(
    ({ id, newRole }) => {
      dispatch(updateControllerMember(id, { role: newRole }))
    },
    [dispatch]
  )

  const deactivateHandler = useCallback(() => {
    dispatch(updateControllerMemberStatus(memberId, 'active'))
  }, [dispatch, memberId])

  const clearUpdateMemberHandler = useCallback(() => {
    dispatch(clearUpdateControllerMember())
  }, [dispatch])

  const params = useMemo(
    () => ({
      controller: selectedController.id,
      ...(searchTerm && { search: searchTerm })
    }),
    [searchTerm, selectedController.id]
  )

  const loadMoreControllerMembers = useLoadPaginatedList({
    params,
    action: getControllerMembers,
    clearAction: clearControllerMembers,
    next: controllerMembersNext
  })

  useEffect(() => {
    if (memberStatusWasUpdated || memberDataWasUpdated) {
      clearUpdateMemberHandler()
    }
  }, [memberStatusWasUpdated, memberDataWasUpdated, clearUpdateMemberHandler])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('manageTeam', { selectedControllerName: selectedController?.name })}</h1>
      <ContentSection title={t('Team members')}>
        <ContentRow
          title={t('Users and invites')}
          description={t(
            'Users will also need access to the ad platforms (e.g. Meta) directly to be able to access campaigns and reporting.'
          )}
          leftColumnChildren={<ControllerInviteCreate controller={selectedController} />}
        >
          <div className={classes.usersSection}>
            <h3>{t('Users')}</h3>
            <FiltersSearch searchId={CONTROLLER_USERS_SEARCH} />
          </div>
          <ControllerMembersTable
            loadMore={loadMoreControllerMembers}
            onChangeMemberStatus={changeMemberStatusHandler}
            onChangeMemberRole={changeMemberRoleHandler}
          />
          <ControllerInvites />
        </ContentRow>
      </ContentSection>
      <ControllerPermissionsEdit />
      <ControllerMemberEdit />

      <DeactivateUserModal
        member={member}
        deactivateHandler={deactivateHandler}
        isModalOpened={isModalOpened}
        closeModalHandler={closeModalHandler}
        memberStatusWasUpdated={memberStatusWasUpdated}
        clearHandler={clearUpdateMemberHandler}
        accountName={`${selectedController?.name} controller.`}
      />
    </>
  )
}

export default ManageControllerTeam

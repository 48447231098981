import { createStyles } from '../../../../../../styles/helpers'

const useStyles = createStyles({
  leftSide: {
    minWidth: '150px',
    maxWidth: '150px'
  },
  rightSide: {
    maxWidth: '235px'
  }
})

export default useStyles

import { MEDIA_CATEGORY } from '../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import {
  LOCATION,
  MEDIA_SUB_CATEGORY,
  MEDIA_SUB_SUB_CATEGORY
} from '../../../../../forms/Multiplatform/MediaProducts/MediaProductForms/fields'

export const NAME = 'name'
export const MEDIA_PRODUCT_ATTRIBUTE = 'media_product_attribute'
export const ATTRIBUTE = 'attribute'
export const VALUES = 'values'
export const REQUIRED = 'required'
export const CAN_SELECT_MULTIPLE = 'can_select_multiple'
export const BOOKING_EMAIL_TEXT = 'booking_email_text'
export const OPTIONS = 'options'
export const OPTION_NAME = 'name'
export const FILE_URL = 'file_url'
export const FILE_NAME = 'filename'
export const FILE_TYPE = 'file_type'

export const mediaProductAttributeOptions = [
  {
    value: MEDIA_CATEGORY,
    label: 'Media category'
  },
  {
    value: MEDIA_SUB_CATEGORY,
    label: 'Asset type'
  },
  {
    value: MEDIA_SUB_SUB_CATEGORY,
    label: 'Asset subtype'
  },
  {
    value: LOCATION,
    label: 'Location'
  }
]

import React from 'react'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import BookingVariablesTable from './BookingVariablesTable'
import BookingVariableCreate from './forms/BookingVariableCreate'
import BookingVariableEdit from './forms/BookingVariableEdit'

const BookingVariables = () => {
  return (
    <ContentRow
      title="Booking Variables"
      description="Booking variables are asked at the point of booking, and can be used to attach linked files to the booking confirmation email."
      leftColumnChildren={<BookingVariableCreate />}
    >
      <BookingVariablesTable />

      <BookingVariableEdit />
    </ContentRow>
  )
}

export default BookingVariables

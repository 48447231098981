import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'

import ContractFieldWrapper from '../ContractFieldWrapper'
import Field from '../../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { getLoadOptionsHandler } from '../../../../../../../features/helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'
import { getBookingVariableOptionsService } from '../../../../../../../modules/services/mediaOrdersBookings'

import { CAN_SELECT_MULTIPLE, VARIABLES } from '../../../fields'

import useStyles from './styles'

const initialOptions = []

const VariableField = ({ formik, isEditMode, variable, index }) => {
  const { t } = useTranslation()

  const { values, errors, touched } = formik

  const classes = useStyles()

  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const canSelectMultiple = variable.data[CAN_SELECT_MULTIPLE]

  const path = `${VARIABLES}[${index}].value`

  const error = getIn(errors, path)
  const fieldTouched = getIn(touched, path)

  const preview = useMemo(() => {
    const value = getIn(values, path)

    return canSelectMultiple
      ? value && Array.isArray(value)
        ? value.map(item => item.label).join(', ')
        : ''
      : value && typeof value === 'object'
        ? value.label
        : ''
  }, [values, path, canSelectMultiple])

  const formatOptions = useCallback(
    options =>
      formatOptionsList({
        list: options,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const params = useMemo(
    () => ({
      media_product_variable_ids: [variable.data.id],
      ordering: 'name',
      controller: selectedControllerId
    }),
    [variable.data.id, selectedControllerId]
  )

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getBookingVariableOptionsService,
      params,
      formatOptions
    })
  }, [params, formatOptions])

  return (
    <ContractFieldWrapper
      label={variable.data.name}
      maxWidth={'200px'}
      minWidth={'200px'}
      isEditMode={isEditMode}
      preview={preview}
      error={error}
      fieldTouched={fieldTouched}
    >
      {canSelectMultiple ? (
        <MultiSelectBox
          formik={formik}
          name={path}
          placeholder={t('Variable option')}
          value={getIn(formik.values, path) || []}
          setFieldValue={formik.setFieldValue}
          loadOptions={loadOptions}
          options={initialOptions}
          className={classes.multiSelectBox}
          touched={fieldTouched}
          error={error}
        />
      ) : (
        <>
          <Field
            key={path}
            id={path}
            formik={formik}
            name={path}
            placeholder={t('Variable option')}
            loadOptions={loadOptions}
            options={initialOptions}
            // setFullOptionObject is added to be able to represent selected label when not in edit mode
            // also with this prop Select works the same way as MultiSelectBox
            setFullOptionObject={true}
          />
        </>
      )}
    </ContractFieldWrapper>
  )
}

VariableField.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  variable: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default VariableField

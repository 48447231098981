import React from 'react'
import PropTypes from 'prop-types'

import Preview from './Preview'
import EditPeriods from './EditPeriods'

import useStyles from './styles'

const DatesCell = ({ formik, productValues, productIndex, isEditMode, checkInventory, isAmendment }) => {
  const classes = useStyles()

  const productData = productValues.data
  const isProductSelected = !!productData

  if (!isProductSelected) {
    return null
  }

  return (
    <div className={classes.datesCol}>
      {isEditMode ? (
        <EditPeriods
          formik={formik}
          checkInventory={checkInventory}
          productValues={productValues}
          isAmendment={isAmendment}
          productIndex={productIndex}
        />
      ) : (
        <Preview checkInventory={checkInventory} productValues={productValues} productIndex={productIndex} />
      )}
    </div>
  )
}

DatesCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  isEditMode: PropTypes.bool,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default DatesCell

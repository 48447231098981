import { createStyles } from '../../../../../../styles/helpers'
import { phonesDown, tabletDown } from '../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  mediaOrderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
    marginBottom: 10
  },
  [`@media screen and (${tabletDown})`]: {
    mediaOrderActions: {
      maxWidth: 'unset',
      position: 'unset',
      marginBottom: 16
    }
  },
  [`@media screen and (${phonesDown})`]: {
    mediaOrderActions: {
      flexDirection: 'column'
    }
  }
})

export default useStyles

import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  row: {
    fontSize: 12
  },
  column: {
    padding: '0 10px'
  },
  infoTextClassName: {
    fontSize: 11
  }
})

export default useStyles

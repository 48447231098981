import React, { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import UploadedFilesCell from './UploadedFilesCell'
import StatusCell from './StatusCell'
import ProductAndPeriodCell from './ProductAndPeriodCell'
import LocationCell from './LocationCell'
import BookingCell from './BookingCell'
import ActionsCell from './ActionsCell'
import AccountBrandCell from '../../../../../../../components/Table/ReusableCells/AccountBrandCell'

import { filesColumnSort, getMediaOrderOrMediaOrderGroupSelfAccountId } from './helpers'

import { phonesDownSize } from '../../../../../../../styles/const/breakpoints'
import { tableColumnsSize } from '../constants'

const getUploadedFilesColumnSize = maxUploadedFilesAmount => {
  switch (maxUploadedFilesAmount) {
    case 1:
    case 0:
      return 80
    case 2:
      return 100
    case 3:
    default:
      return 150
  }
}

export const useInstallationColumns = ({ maxUploadedFilesAmount }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const uploadedFilesColumnSize = useMemo(() => {
    return getUploadedFilesColumnSize(maxUploadedFilesAmount)
  }, [maxUploadedFilesAmount])

  return useMemo(() => {
    return [
      {
        accessorKey: 'booking',
        sortingFn: 'alphanumeric', // use built-in sorting function by name
        header: 'Booking',
        Cell: ({ booking, media_order: mediaOrderId, account_id: accountId, sequential_list: sequentialList }) => {
          const selfAccountId = getMediaOrderOrMediaOrderGroupSelfAccountId(accountId, sequentialList)

          return <BookingCell booking={booking} mediaOrderId={mediaOrderId} accountId={selfAccountId} />
        },

        style: { maxWidth: tableColumnsSize.booking }
      },
      {
        header: 'Account',
        accessorKey: 'account',
        Cell: ({
          account,
          brand,
          campaign_name: campaignName,
          sequential_list: sequentialList,
          sequential_ids: sequentialIds
        }) => {
          return (
            <AccountBrandCell
              account={account}
              brand={brand}
              campaignName={campaignName}
              sequentialList={sequentialList}
              sequentialIds={sequentialIds}
              isSmall={true}
            />
          )
        },
        showOnMobile: false,
        style: {
          maxWidth: tableColumnsSize.account
        }
      },
      {
        header: 'Asset ID',
        fieldKey: 'asset_id',
        accessorKey: 'asset_id',
        showOnMobile: false,
        style: { maxWidth: tableColumnsSize.asset_id }
      },
      {
        header: 'Product and Period',
        // Use accessorFn to merge product and name fields
        accessorFn: row => row.product || row.name,
        // sortingFn: productNamesColumnSort,
        Cell: ({ product, date_start, date_end, sequential_list }) => (
          <ProductAndPeriodCell
            isGroup={!!sequential_list}
            product={product}
            date_start={date_start}
            date_end={date_end}
          />
        ),
        showOnMobile: false,
        style: {
          flexGrow: 1,
          minWidth: tableColumnsSize.product
        }
      },
      {
        header: 'Location',
        // groups doesn't have the location field, so add fallback to empty string for correct sorting
        accessorFn: row => row.location || '',
        sortingFn: 'textCaseSensitive',
        Cell: ({ location, sub_location }) => <LocationCell location={location} sub_location={sub_location} />,
        style: { maxWidth: tableColumnsSize.location }
      },
      {
        header: 'Qty',
        accessorKey: 'quantity',
        sortingFn: 'alphanumeric', // use built-in sorting function by number
        fieldKey: 'quantity',
        style: { maxWidth: tableColumnsSize.quantity },
        showOnMobile: false
      },
      {
        header: 'Files',
        accessorKey: 'file_urls',
        // use custom sorting function directly
        sortingFn: filesColumnSort,
        Cell: installationReportRowData => {
          return <UploadedFilesCell installationReportRowData={installationReportRowData} />
        },
        style: {
          maxWidth: uploadedFilesColumnSize
        },
        showOnMobile: false
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: ({ status, sequential_ids: sequentialIds, id }) => {
          return <StatusCell status={status} sequentialIds={sequentialIds} id={id} />
        },
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        Cell: ({ media_order: mediaOrderId, id, account_id: accountId }) => (
          <ActionsCell id={id} mediaOrderId={mediaOrderId} accountId={accountId} />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ]
  }, [uploadedFilesColumnSize, isMobile])
}

import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TableDataLoader from '../../../components/Table/TableDataLoader'
import QuotationsTableData from './QuotationsTableData'
import { QuotationTableContext } from './QuotationTableContext'

import {
  quotationsErrorSelector,
  quotationsIsLoadingSelector,
  quotationsNextSelector,
  quotationsSelector,
  quotationsWasLoadedSelector
} from '../../../modules/selectors/quotations'

import { quotationsColumnsSchema } from './helpers'

import useCommonStyles from '../../../styles/common/table'

const QuotationsTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const quotations = useSelector(quotationsSelector)
  const quotationsNext = useSelector(quotationsNextSelector)

  const { loadMoreQuotationsHandler } = useContext(QuotationTableContext)

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContentInTheMiddleOfTheTable}>
        <div>{t('There are no quotations')}</div>
      </div>
    ),
    [commonClasses.noDataContentInTheMiddleOfTheTable, t]
  )

  return (
    <TableDataLoader
      itemsLength={quotations.length}
      errorSelector={quotationsErrorSelector}
      wasLoadedSelector={quotationsWasLoadedSelector}
      isLoadingSelector={quotationsIsLoadingSelector}
      skeletonProps={{ cols: quotationsColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={quotationsNext ? loadMoreQuotationsHandler : null}
    >
      <QuotationsTableData />
    </TableDataLoader>
  )
}

export default QuotationsTable

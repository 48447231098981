import { v4 as uuidv4 } from 'uuid'
import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  initialNotificationEmailSection,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../ReusableFormFields/RecipientsFields/fields'

export const PROPOSAL_PLATFORM_USER_RECEIVER = 'quotation_receiver_user'
export const PROPOSAL_EMAIL_RECEIVER = 'quotation_receiver_email'

export const initialValues = {
  [USER_OPTION]: SEND_TO_PLATFORM_USER,
  [NOTIFICATION_USERS]: [],
  [NOTIFICATION_EMAILS]: [{ ...initialNotificationEmailSection, id: uuidv4() }]
}

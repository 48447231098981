import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../../components/Form'
import BookingVariablesFormContent from '../../BookingVariablesFormContent'

import { transformValuesToBE } from '../formatters'

import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'
import {
  createBookingVariableErrorSelector,
  createBookingVariableIsLoadingSelector,
  createBookingVariableWasCreatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'
import {
  clearCreateBookingVariable,
  createBookingVariable
} from '../../../../../../../modules/actions/mediaOrdersBookings'

import { BOOKING_VARIABLE_CREATE } from '../../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'

function BookingVariableCreateForm() {
  const dispatch = useDispatch()

  const itemWasCreated = useSelector(createBookingVariableWasCreatedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const onSubmit = useCallback(
    values => {
      const formattedValues = transformValuesToBE(values)

      dispatch(
        createBookingVariable({
          controller: controllerId,
          status: 'active',
          ...formattedValues
        })
      )
    },
    [dispatch, controllerId]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearCreateBookingVariable())
  }, [dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={BOOKING_VARIABLE_CREATE}
      successSubmit={itemWasCreated}
      clearHandler={clearHandler}
      errorSelector={createBookingVariableErrorSelector}
      isLoadingSelector={createBookingVariableIsLoadingSelector}
    >
      <BookingVariablesFormContent formik={formik} />
    </Form>
  )
}

export default BookingVariableCreateForm

import { createStyles } from '../../../styles/helpers'

import { container } from '../../../styles/common'
import { smallDesktopDown, smallDesktopUp, phonesDown } from '../../../styles/const/breakpoints'
import { lighterGrey } from '../../../styles/const/colors'
import { transitionShort, zIndex1, zIndex9, zIndex2, zIndex3, zIndex10 } from '../../../styles/const/common'

export const headerHeight = 48

const useStyles = createStyles(theme => ({
  header: {
    width: '100%',
    padding: '0 20px',
    backgroundColor: 'white',
    boxShadow: '0 1px 5px 2px rgba(0,0,0,.05)',
    minHeight: headerHeight,
    position: 'fixed',
    zIndex: zIndex9,
    top: 0,
    left: 0
  },
  container: {
    extend: container,
    maxWidth: 1500,
    display: 'flex',
    alignItems: 'center',
    minHeight: headerHeight,
    '&.is-centered': {
      justifyContent: 'center'
    }
  },
  logo: {
    display: 'flex',
    fontSize: 0,
    width: 'auto',
    maxWidth: '70px',
    height: 30,
    position: 'relative',
    '& svg, & img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain'
    }
  },
  menu: {
    flex: 1,
    paddingLeft: 18,
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuMain: {},
  menuAdditional: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '4px 0 4px 8px',
    '& > *:not(:last-child)': {
      marginRight: 20
    }
  },
  additionalItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  toggleBtn: {},
  welcomeText: {},
  overlay: {},
  [`@media screen and (${phonesDown})`]: {
    header: {
      zIndex: zIndex10
    }
  },
  [`@media screen and (${smallDesktopUp})`]: {
    menuMain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& > *:not(:last-child)': {
        marginRight: 22
      }
    }
  },
  [`@media screen and (${smallDesktopDown})`]: {
    container: {
      justifyContent: 'center'
    },
    toggleBtn: {
      cursor: 'pointer',
      position: 'absolute',
      zIndex: zIndex3,
      top: 'calc(50% - 9)',
      right: 16,
      width: 25,
      height: 18,
      border: 0,
      padding: 0,
      fontSize: 0,
      outline: 'none',
      backgroundColor: 'transparent',
      '&:before, &:after': {
        content: '""'
      },
      '&:before, &:after, & span': {
        position: 'absolute',
        left: 0,
        width: 25,
        height: 2,
        borderRadius: 2,
        backgroundColor: theme.brandPrimary,
        transition: transitionShort
      },
      '&:before': {
        top: menuOpened => (menuOpened ? 8 : 0),
        transform: menuOpened => (menuOpened ? 'rotate(-135deg)' : 'rotate(0deg)')
      },
      '& span': {
        top: 8,
        opacity: menuOpened => (menuOpened ? 0 : 1)
      },
      '&:after': {
        top: menuOpened => (menuOpened ? 8 : 16),
        transform: menuOpened => (menuOpened ? 'rotate(135deg)' : 'rotate(0deg)')
      }
    },
    menu: {
      position: 'fixed',
      zIndex: zIndex2,
      top: 0,
      right: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      width: 300,
      maxWidth: 'calc(100% - 30px)',
      height: '100vh',
      backgroundColor: lighterGrey,
      padding: '42px 23px 12vh',
      transition: transitionShort,
      transform: menuOpened => (menuOpened ? 'translateX(0)' : 'translateX(100%)'),
      '& [class*="avatarBlock"]': {
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      '& [class*="userMenu"]': {
        marginTop: 'auto'
      }
    },
    welcomeText: {
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
      marginTop: 5
    },
    overlay: {
      position: 'fixed',
      zIndex: zIndex1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,.6)',
      transition: transitionShort,
      opacity: menuOpened => (menuOpened ? 1 : 0),
      pointerEvents: menuOpened => (menuOpened ? 'all' : 'none')
    }
  }
}))

export default useStyles

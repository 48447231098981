import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import InstallationReportTableContent from './InstallationReportTableContent'
import TableDataLoader from '../../../../../../components/Table/TableDataLoader'

import { clearControllerMembers, getControllerMembers } from '../../../../../../modules/actions/controller'
import {
  getInstallationBookedMediaReportErrorSelector,
  getInstallationBookedMediaReportIsLoadingSelector,
  installationBookedMediaReportSelector,
  installationBookedMediaReportWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { controllerMembersWasLoadedSelector } from '../../../../../../modules/selectors/controller'

import { skeletonColumnsSchema } from './constants'

import useCommonStyles from '../../../../../../styles/common/table'

const InstallationReportTable = ({ noDataContentText = 'There are no bookings to show', showNoData = false }) => {
  const dispatch = useDispatch()

  const installationBookedMediaReport = useSelector(installationBookedMediaReportSelector)
  const controllerMembersWasLoaded = useSelector(controllerMembersWasLoadedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const commonClasses = useCommonStyles()
  const { t } = useTranslation()

  const noDataContent = useMemo(
    () => (
      <div className={classnames(commonClasses.noDataContentInTheMiddleOfTheTable, commonClasses.noDataDark)}>
        <div>{t(noDataContentText)}</div>
        <br />
      </div>
    ),
    [commonClasses, t, noDataContentText]
  )

  useEffect(() => {
    // members are used in UploadedFilesCell
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  if (showNoData) {
    // TableDataLoader shows no data only in case the data was loaded and there is no data
    // this covers custom case to show no data message
    return noDataContent
  }

  return (
    <TableDataLoader
      itemsLength={installationBookedMediaReport?.length}
      errorSelector={getInstallationBookedMediaReportErrorSelector}
      wasLoadedSelector={installationBookedMediaReportWasLoadedSelector}
      isLoadingSelector={getInstallationBookedMediaReportIsLoadingSelector}
      skeletonProps={{ cols: skeletonColumnsSchema }}
      additionalDataIsLoading={!controllerMembersWasLoaded}
      noDataContent={noDataContent}
    >
      <InstallationReportTableContent />
    </TableDataLoader>
  )
}

export default InstallationReportTable

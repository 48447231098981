import { formatStringWithUnderscore, toLower, upperCaseSpecificWords } from '../../helpers/common'
import { createJsonFromQueryString } from '../../helpers/url'

import {
  googleListItemInsights,
  CHIP_COLORS,
  tikTokListItemInsights,
  dv360ListItemInsights,
  facebookListItemInsights
} from '../../constants/other'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'
import { objectiveUpperCaseWords } from '../../constants/campaigns'

export const getStatusTagColor = (status = '') => {
  switch (toLower(status)) {
    case 'paused':
      return CHIP_COLORS.blue
    case 'deleted':
      return CHIP_COLORS.grey
    default:
      return CHIP_COLORS.green
  }
}

export const getListItemInsights = platform => {
  if (platform === FACEBOOK_PLATFORM) {
    return facebookListItemInsights
  } else if (platform === GOOGLE_PLATFORM) {
    return googleListItemInsights
  } else if (platform === TIKTOK_PLATFORM) {
    return tikTokListItemInsights
  } else if (platform === DV_360_PLATFORM) {
    return dv360ListItemInsights
  }
}

export const getFormattedListItemObjective = (platform, objective) => {
  if (platform === GOOGLE_PLATFORM || platform === DV_360_PLATFORM || platform === TIKTOK_PLATFORM) {
    return upperCaseSpecificWords(formatStringWithUnderscore(objective.toLowerCase()), objectiveUpperCaseWords)
  } else if (platform === FACEBOOK_PLATFORM) {
    const cleanedObjective = objective.replace('outcome_', '')
    return formatStringWithUnderscore(cleanedObjective)
  } else {
    return formatStringWithUnderscore(objective)
  }
}

export const getLoadOptionsHandler = ({ getOptionsService, params, formatOptions, limit = 100 }) => {
  return async (search, loadedOptions, { next }) => {
    try {
      const response = await getOptionsService({
        ...params,
        search,
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })

      const newOptions = formatOptions(response.results)

      return {
        options: newOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    } catch (error) {
      console.error('Error while loading options', error)

      return {
        options: [],
        hasMore: false,
        additional: {}
      }
    }
  }
}

import PropTypes from 'prop-types'
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { setNestedObjectValues } from 'formik'

import ProposalSend from './ProposalSend'
import ProposalApprovalReview from './ProposalApprovalReview'
import MediaOrderGenerateForm from './ProposalMediaOrderGenerate'

import ApprovalProcessModal from './ProposalModals/ApprovalProcessModal'

import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { useApprovalProcess } from './ProposalMediaOrderGenerate/useApprovalProcess'

import { activeContractSelector, updateContractIsLoadingSelector } from '../../../../modules/selectors/contracts'

import { VALIDATE_ADDITIONAL_FIELDS } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'
import { CONTRACT_DISAPPROVED_STATUS, CONTRACT_PENDING_APPROVAL_STATUS } from '../../../../constants/contacts'

import useStyles from './styles'

const ProposalBookOrSend = ({ contractId }) => {
  const classes = useStyles()

  const { formikSetFieldValue, formikSetTouched } = useContext(MediaOrderFormContext)

  const activeContract = useSelector(activeContractSelector)
  const updateContractIsLoading = useSelector(updateContractIsLoadingSelector)

  const hasProducts = activeContract?.media_order?.products?.length > 0
  const isDisabled = updateContractIsLoading || !hasProducts

  const checkContractValidity = useCallback(() => {
    // triggers validation and allows to validate additional fields like Brand, Campaign name, Variables and etc..
    // then sets errors to formik, which will be shown in the form
    return formikSetFieldValue(VALIDATE_ADDITIONAL_FIELDS, true)
      .then(errors => {
        const updatedTouched = setNestedObjectValues(errors, true)
        formikSetTouched(updatedTouched)

        // reset the flag and additional fields validation, don't trigger validation on update, to keep previous errors
        formikSetFieldValue(VALIDATE_ADDITIONAL_FIELDS, false, false)
        // is valid if there are no errors
        return Object.keys(errors).length === 0
      })
      .catch(error => {
        return false
      })
  }, [formikSetFieldValue, formikSetTouched])

  const {
    isMatchRequirements,
    isPackageApprover,
    shouldCheckApprovalRequirements,
    isApprovalModalOpen,
    approvalModalData,
    handleCloseApprovalModal
  } = useApprovalProcess({ contractData: activeContract })
  const contractStatus = activeContract?.status
  // If status is pending_approval or disapproved, and user has is_package_approver permission a button ‘Review Discount’ is shown (and the confirm and book and send for signature buttons are not shown)
  const isReviewMode =
    isPackageApprover &&
    (contractStatus === CONTRACT_PENDING_APPROVAL_STATUS || contractStatus === CONTRACT_DISAPPROVED_STATUS)

  return (
    <div className={classes.buttonsContainer}>
      {/*If proposal is status pending_approval and user has is_package_approver permission, they will see a Review discount button:*/}
      {isReviewMode ? (
        <ProposalApprovalReview
          contractId={contractId}
          isDisabled={isDisabled}
          checkContractValidity={checkContractValidity}
        />
      ) : (
        <>
          {/*User should not be able to send for signature if approval is needed, as that is equivalent to booking.*/}
          <ProposalSend
            contractId={contractId}
            isDisabled={isDisabled}
            checkContractValidity={checkContractValidity}
            shouldCheckApprovalRequirements={shouldCheckApprovalRequirements}
            isMatchRequirements={isMatchRequirements}
          />

          {shouldCheckApprovalRequirements && (
            <ApprovalProcessModal
              isOpen={isApprovalModalOpen}
              onClose={handleCloseApprovalModal}
              data={approvalModalData}
            />
          )}
          <MediaOrderGenerateForm
            contractData={activeContract}
            contractId={contractId}
            isDisabled={isDisabled}
            checkContractValidity={checkContractValidity}
            shouldCheckApprovalRequirements={shouldCheckApprovalRequirements}
            isMatchRequirements={isMatchRequirements}
          />
        </>
      )}
    </div>
  )
}

ProposalBookOrSend.propTypes = {
  contractId: PropTypes.number
}

export default ProposalBookOrSend

import { createStyles } from '../../../../../../styles/helpers'
import { greyOutline } from '../../../../../../styles/const/colors'

const useStyles = createStyles({
  detail: {
    fontSize: '12px',
    color: greyOutline,
    margin: '4px 0'
  }
})

export default useStyles

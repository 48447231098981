import { concat } from '../../helpers/common'

const MODULE_NAME = 'STATEMENTS/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_CURRENT_STATEMENT = concat(MODULE_NAME, 'GET_CURRENT_STATEMENT')
export const GET_CURRENT_STATEMENT_SUCCESS = concat(MODULE_NAME, 'GET_CURRENT_STATEMENT_SUCCESS')
export const GET_CURRENT_STATEMENT_FAILURE = concat(MODULE_NAME, 'GET_CURRENT_STATEMENT_FAILURE')
export const CLEAR_GET_CURRENT_STATEMENT = concat(MODULE_NAME, 'CLEAR_GET_CURRENT_STATEMENT')

export const GET_STATEMENTS_AS_CSV = concat(MODULE_NAME, 'GET_STATEMENTS_AS_CSV')
export const GET_STATEMENTS_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_STATEMENTS_AS_CSV_SUCCESS')
export const GET_STATEMENTS_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_STATEMENTS_AS_CSV_FAILURE')
export const CLEAR_GET_STATEMENTS_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_STATEMENTS_AS_CSV')

export const UPDATE_STATEMENT = concat(MODULE_NAME, 'UPDATE_STATEMENT')
export const UPDATE_STATEMENT_SUCCESS = concat(MODULE_NAME, 'UPDATE_STATEMENT_SUCCESS')
export const UPDATE_STATEMENT_FAILURE = concat(MODULE_NAME, 'UPDATE_STATEMENT_FAILURE')
export const CLEAR_UPDATE_STATEMENT = concat(MODULE_NAME, 'CLEAR_UPDATE_STATEMENT')

export const GET_STATEMENTS = concat(MODULE_NAME, 'GET_STATEMENTS')
export const GET_STATEMENTS_SUCCESS = concat(MODULE_NAME, 'GET_STATEMENTS_SUCCESS')
export const GET_STATEMENTS_FAILURE = concat(MODULE_NAME, 'GET_STATEMENTS_FAILURE')
export const CLEAR_GET_STATEMENTS = concat(MODULE_NAME, 'CLEAR_GET_STATEMENTS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get current statement
export function getCurrentStatement(id, params) {
  return { type: GET_CURRENT_STATEMENT, id, params }
}

export function getCurrentStatementSuccess(data) {
  return { type: GET_CURRENT_STATEMENT_SUCCESS, data }
}

export function getCurrentStatementFailure(error) {
  return { type: GET_CURRENT_STATEMENT_FAILURE, error }
}

export function clearGetCurrentStatement() {
  return { type: CLEAR_GET_CURRENT_STATEMENT }
}

// Get statements as csv
export function getStatementsAsCsv(params) {
  return { type: GET_STATEMENTS_AS_CSV, params }
}

export function getStatementsAsCsvSuccess(data, fileName) {
  return { type: GET_STATEMENTS_AS_CSV_SUCCESS, data, fileName }
}

export function getStatementsAsCsvFailure(error) {
  return { type: GET_STATEMENTS_AS_CSV_FAILURE, error }
}

export function clearGetStatementsAsCsv() {
  return { type: CLEAR_GET_STATEMENTS_AS_CSV }
}

// Update statement
export function updateStatement(id, data) {
  return { type: UPDATE_STATEMENT, id, data }
}

export function updateStatementSuccess(data) {
  return { type: UPDATE_STATEMENT_SUCCESS, data }
}

export function updateStatementFailure(error) {
  return { type: UPDATE_STATEMENT_FAILURE, error }
}

export function clearUpdateStatement() {
  return { type: CLEAR_UPDATE_STATEMENT }
}

// Get statements
export function getStatements(params) {
  return { type: GET_STATEMENTS, params }
}

export function getStatementsSuccess(data) {
  return { type: GET_STATEMENTS_SUCCESS, data }
}

export function getStatementsFailure(error) {
  return { type: GET_STATEMENTS_FAILURE, error }
}

export function clearGetStatements() {
  return { type: CLEAR_GET_STATEMENTS }
}

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import MediaSubCategoryFields from './MediaSubCategoryFields'

import {
  createMediaSubCategory,
  clearCreateMediaSubCategory
} from '../../../../../../modules/actions/mediaOrdersProducts'
import {
  createMediaSubCategoryErrorSelector,
  createMediaSubCategoryIsLoadingSelector,
  mediaSubCategoryWasCreatedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_SUB_CATEGORY_CREATE } from '../../../../../../constants/forms'
import { initialValues, transformValuesToBE } from '../../fields'
import { validationSchema } from '../../validation'

function MediaSubCategoryCreateForm() {
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const subCategoryWasCreated = useSelector(mediaSubCategoryWasCreatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, selectedControllerId)
      dispatch(
        createMediaSubCategory({
          ...transformedData,
          controller: selectedControllerId
        })
      )
    },
    [dispatch, selectedControllerId]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearCreateItemSubmit = useCallback(() => dispatch(clearCreateMediaSubCategory()), [dispatch])

  return (
    <Form
      formik={formik}
      formName={MEDIA_SUB_CATEGORY_CREATE}
      // processing
      successSubmit={subCategoryWasCreated}
      errorSelector={createMediaSubCategoryErrorSelector}
      isLoadingSelector={createMediaSubCategoryIsLoadingSelector}
      // after form submit
      clearHandler={clearCreateItemSubmit}
    >
      <MediaSubCategoryFields formik={formik} />
    </Form>
  )
}

export default MediaSubCategoryCreateForm

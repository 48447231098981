import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'
import { useOpenMediaOrderSummaryInNewTab } from '../hooks'
import * as Sentry from '@sentry/react'

const BookingCell = ({ booking, mediaOrderId, accountId }) => {
  const classes = useStyles()

  if (!accountId) {
    const selfAccountMissingError = new Error('Installation report Booking cell self account id is missing')
    Sentry.captureException(selfAccountMissingError, {
      extra: {
        booking,
        mediaOrderId,
        accountId
      }
    })
  }
  const goToMediaOrderSummaryHandler = useOpenMediaOrderSummaryInNewTab({ mediaOrderId, accountId })

  return booking ? (
    <div className={classes.booking} onClick={goToMediaOrderSummaryHandler}>
      {booking}
    </div>
  ) : (
    '-'
  )
}

BookingCell.propTypes = {
  booking: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BookingCell

import React, { useCallback } from 'react'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'

import FilesRequirementsContent from '../../../../ReusableFormFIelds/FilesRequirementsContent'

import { FILES_REQUIREMENTS } from '../../fields'

const stepFields = [FILES_REQUIREMENTS]

const FilesRequirementsStep = ({ formik, handleStepChange }) => {
  const { values } = formik

  const btnText = values[FILES_REQUIREMENTS].length ? 'Continue' : 'Continue without requested files'

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields, values)
  }, [handleStepChange, values])

  return (
    <Step
      btnText={btnText}
      handleContinue={handleContinue}
      stepTitle="Specify any requested creative files"
      stepDescription="The advertiser will be asked to upload these files prior to the creative deadline date"
    >
      <FilesRequirementsContent formik={formik} />
    </Step>
  )
}

export default FilesRequirementsStep

import React, { useCallback } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import FilesRequirementsSectionFields from './FilesRequirementsSectionFields'
import MinimizedListField from '../../../../../../features/components/Form/MinimizedListField'

import {
  FILE_ID,
  fileRequirementsInitialValue,
  FILES_REQUIREMENTS,
  FILES_REQUIREMENTS_NAME
} from '../../MediaProductCreate/MediaProductCreateForm/fields'

const FilesRequirementsContent = ({ formik }) => {
  const { t } = useTranslation()

  const handleFileTitleGenerate = useCallback((itemValue, index) => {
    const fileName = getIn(itemValue, FILES_REQUIREMENTS_NAME)
    return fileName || `File ${index + 1}`
  }, [])

  return (
    <MinimizedListField
      formik={formik}
      fieldPath={FILES_REQUIREMENTS}
      initialItemIdKey={FILE_ID}
      initialListValue={fileRequirementsInitialValue}
      firstItemText={t('+ Add a file')}
      nextItemText={t('+ Add another file')}
      deleteBtnText={t('Remove file')}
      handleTitleGenerate={handleFileTitleGenerate}
    >
      <FilesRequirementsSectionFields />
    </MinimizedListField>
  )
}

export default FilesRequirementsContent

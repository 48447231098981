import { calc } from '../../../../helpers/numbers'
import { calculateDiscountedPrice } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

import {
  ORIGINAL_PRODUCT_PRICE,
  QUANTITY
} from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'

export const calculateDiscountCost = (selectedProductsValues, contractBuilderDiscounts) => {
  // calculate the total cost of the products with the applied automatic(calculated) discounts
  return selectedProductsValues.reduce((acc, product) => {
    const calculatedDiscount =
      contractBuilderDiscounts.find(discount => discount.id === product.data?.id)?.discountPercentage || 0
    const originalPrice = product[ORIGINAL_PRODUCT_PRICE]
    const totalProductPrice = calculateDiscountedPrice(originalPrice, product[QUANTITY], calculatedDiscount)

    return calc(acc).add(totalProductPrice).toDP(2).toNumber()
  }, 0)
}

export const isDiscountValid = ({ contractDiscountedCost, calculatedTotalDiscountCost }) => {
  return {
    isValid: contractDiscountedCost >= calculatedTotalDiscountCost,
    calculatedTotalDiscountCost
  }
}

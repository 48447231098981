import React from 'react'
import PropTypes from 'prop-types'

import ProductNestedFieldCell from '../ProductNestedFieldCell'
import CategorySelection from './CategorySelection'

const CategoryCell = ({ productValues, selectedCategory, setSelectedCategory }) => {
  return (
    <ProductNestedFieldCell productValues={productValues} productFieldPath={'media_sub_category_name'}>
      <CategorySelection selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
    </ProductNestedFieldCell>
  )
}

CategoryCell.propTypes = {
  productValues: PropTypes.object,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func
}

export default CategoryCell

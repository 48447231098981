import React from 'react'
import PropTypes from 'prop-types'

import ProductNestedFieldCell from '../ProductNestedFieldCell'
import LocationRegionSelect from './LocationRegionSelect'

const LocationRegionCell = ({ productValues, locationRegion, setLocationRegion }) => {
  return (
    <ProductNestedFieldCell productValues={productValues} productFieldPath="location.region.name">
      <LocationRegionSelect locationRegion={locationRegion} setLocationRegion={setLocationRegion} />
    </ProductNestedFieldCell>
  )
}

LocationRegionCell.propTypes = {
  productValues: PropTypes.object,
  locationRegion: PropTypes.object,
  setLocationRegion: PropTypes.func
}

export default LocationRegionCell

import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../../features/formatters'
import { getLoadOptionsHandler } from '../../../../../features/helpers/componentsHelpers'

import { getProductStoresService } from '../../../../../modules/services/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { STORES } from '../fields'

const StoresPaginatedSelectField = ({ formik }) => {
  const { values, setFieldValue, errors, touched } = formik
  const controllerId = useSelector(selectedControllerIdSelector)

  const formatStores = useCallback(stores => {
    return formatOptionsList({
      list: stores,
      valueName: 'id',
      labelName: 'name'
    })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getProductStoresService,
      params: {
        ordering: 'name',
        controller: controllerId
      },
      formatOptions: formatStores
    })
  }, [controllerId, formatStores])

  return (
    <MultiSelectBox
      placeholder="Select stores"
      name={STORES}
      value={values[STORES]}
      error={errors[STORES]}
      touched={touched[STORES]}
      loadOptions={loadOptions}
      setFieldValue={setFieldValue}
      expandMenu={true}
    />
  )
}

export default StoresPaginatedSelectField

import * as Yup from 'yup'
import nameValidation from '../../../../features/validations/name'
import { OPTIONS } from './fields'

export const validationSchema = Yup.object({
  ...nameValidation,
  [OPTIONS]: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required('Option is required')
      })
    )
    .min(1, 'At least one option is required')
})

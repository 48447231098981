import React, { useCallback, useContext, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import EditTotalFields from './EditTotalFields'
import ReachTotalActual from './Reach/ReachTotalActual'
import ReachTotalEstimated from './Reach/ReachTotalEstimated'

import Actions, { EDIT_TOTAL } from '../Actions'

import { MediaOrderFormContext } from '../../../../../MediaOrderFormContext'
import useSaveData from '../../useSaveData'

import { calc, formatCurrency } from '../../../../../../../../helpers/numbers'
import { calculateDiscountPercentage } from '../../Cells/DiscountCell/helpers'
import { getDiscountedTotalPrice, getOriginalTotalPrice } from '../../../../helpers'

import {
  CUSTOM_ORDER_DISCOUNT,
  CUSTOM_ORDER_PRICE,
  CUSTOM_PRODUCT_DISCOUNT,
  CUSTOM_PRODUCT_PRICE,
  MEDIA_PRODUCTS,
  ORIGINAL_PRODUCT_PRICE,
  QUANTITY
} from '../../../../fields'
import { tableColumnsSize } from '../../index'
import useStyles from '../styles'

const ManageTotalPrice = ({
  formik,
  allowEdit,
  allowAutoSave,
  isAmendment,
  isAmendProcess,
  editProductId,
  hasReachData,
  handleTotalEdit
}) => {
  const classes = useStyles()
  const { values, setFieldValue } = formik

  const isEditMode = editProductId === EDIT_TOTAL
  const products = values[MEDIA_PRODUCTS]

  const { handleSaveDataToBE } = useSaveData({ values, allowEdit, allowAutoSave })
  const { currency } = useContext(MediaOrderFormContext)
  const currencySymbol = currency?.symbol

  const originalTotalPrice = useMemo(() => getOriginalTotalPrice(products), [products])
  const calculatedTotalCost = useMemo(() => getDiscountedTotalPrice(products), [products])

  // initial discount on total open:
  const calculatedDiscount = calculateDiscountPercentage(originalTotalPrice, calculatedTotalCost)
  // check if the total custom price was set
  const wasCustomTotalPriceSet = values[CUSTOM_ORDER_PRICE] !== calculatedTotalCost

  // check if discount was changed from the initial value
  const wasDiscountSet = values[CUSTOM_ORDER_DISCOUNT] !== calculatedDiscount

  const handleSaveTotal = useCallback(() => {
    const customDiscount = values[CUSTOM_ORDER_DISCOUNT]

    // only one of the fields could be filled at the same time
    if (wasDiscountSet) {
      const repricedProducts = products.map(product => ({
        ...product,
        [CUSTOM_PRODUCT_PRICE]: calc(product[ORIGINAL_PRODUCT_PRICE]).mul(product[QUANTITY]).toDP(2).toNumber(),
        [CUSTOM_PRODUCT_DISCOUNT]: customDiscount
      }))

      setFieldValue(MEDIA_PRODUCTS, repricedProducts)
      setFieldValue(CUSTOM_ORDER_DISCOUNT, 0)
      handleSaveDataToBE({ updatedProductValues: repricedProducts })
    } else {
      // split the total cost between all products, calculate the new price for each product
      const customOrderPrice = values[CUSTOM_ORDER_PRICE]
      const originalTotalCost = products.reduce((acc, product) => {
        const productTotal = calc(product[ORIGINAL_PRODUCT_PRICE]).mul(product[QUANTITY]).toDP(2).toNumber()
        return calc(acc).add(productTotal).toDP(2).toNumber()
      }, 0)

      // find the total discount which was made to original products price,
      // with unlimited accuracy to decimal places i.e. 0.9999123123123
      const discount = calc(customOrderPrice).div(originalTotalCost).toNumber()
      let calculatedTotal = 0

      const repricedProducts = products.map(product => {
        // we find total discount and multiply original product price by this discount,
        // the ending of the total is adding to last product
        let updatedProductPrice = 0
        const isLastProduct = products.indexOf(product) === products.length - 1
        if (isLastProduct) {
          // last product should have the rest of the total
          // this covers case when the total cost is not divided evenly
          updatedProductPrice = calc(customOrderPrice).sub(calculatedTotal).toDP(2).toNumber()
        } else {
          if (originalTotalCost === 0) {
            // if the total cost is 0, we can't divide by 0, so we set the price apportion the cost evenly
            updatedProductPrice = calc(customOrderPrice).div(products.length).toDP(2).toNumber()
          } else {
            // set price in the proportion to the original price
            updatedProductPrice = calc(product[ORIGINAL_PRODUCT_PRICE]).mul(discount).toDP(2).toNumber()
          }
          calculatedTotal = calc(calculatedTotal).add(updatedProductPrice).toDP(2).toNumber()
        }

        return {
          ...product,
          // set price in the proportion to the original price
          [CUSTOM_PRODUCT_PRICE]: updatedProductPrice,
          [CUSTOM_PRODUCT_DISCOUNT]: 0
        }
      })
      setFieldValue(MEDIA_PRODUCTS, repricedProducts)
      setFieldValue(CUSTOM_ORDER_PRICE, 0)
      handleSaveDataToBE({ updatedProductValues: repricedProducts })
    }
  }, [handleSaveDataToBE, products, setFieldValue, values, wasDiscountSet])

  return (
    <div className={classes.totalsContainer}>
      {isEditMode ? (
        <EditTotalFields
          formik={formik}
          originalTotalPrice={originalTotalPrice}
          calculatedTotalCost={calculatedTotalCost}
          calculatedDiscount={calculatedDiscount}
          wasCustomTotalPriceSet={wasCustomTotalPriceSet}
          wasDiscountSet={wasDiscountSet}
        />
      ) : (
        <>
          <h5 className={classes.totalTitle}>Totals:</h5>
          {hasReachData && (
            <div className={classes.totalCol}>
              {isAmendment ? <ReachTotalActual isAmendProcess={isAmendProcess} /> : <ReachTotalEstimated />}
            </div>
          )}
          <div
            className={classes.totalCol}
            style={{
              width: tableColumnsSize.rate,
              textAlign: 'right'
            }}
          >
            {formatCurrency(originalTotalPrice, { min: 2, max: 2 }, { symbol: currencySymbol })}
          </div>
          <div className={classes.totalCol} style={{ width: tableColumnsSize.discount }}>
            {calculateDiscountPercentage(originalTotalPrice, calculatedTotalCost)}%
          </div>
          <div
            className={classnames(classes.totalCol, classes.totalCost)}
            style={{ width: tableColumnsSize.cost, textAlign: 'right' }}
          >
            {formatCurrency(
              calculatedTotalCost,
              {
                min: 2,
                max: 2
              },
              { symbol: currencySymbol }
            )}
          </div>
        </>
      )}
      {allowEdit && (
        <Actions
          setFieldValue={setFieldValue}
          productsValues={products}
          isEditMode={isEditMode}
          // when some row is in edit mode, don't allow to start editing total
          disableEditStart={Boolean(editProductId)}
          handleTotalEdit={handleTotalEdit}
          onTotalSave={handleSaveTotal}
        />
      )}
    </div>
  )
}

ManageTotalPrice.propTypes = {
  formik: PropTypes.object,
  editProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAmendment: PropTypes.bool,
  isAmendProcess: PropTypes.bool,
  hasReachData: PropTypes.bool,
  handleTotalEdit: PropTypes.func,
  allowEdit: PropTypes.bool,
  allowAutoSave: PropTypes.bool
}

export default ManageTotalPrice

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearTriggerTableCopy } from '../../modules/actions/app'
import { triggerTableCopySelector } from '../../modules/selectors/app'

import { TABLE_ROW_CLASSNAME } from '../../constants/pdf'

const getFormattedRowsContent = ({ rowsData, formatCopyCells, tableId }) => {
  return rowsData.map((rowData, index) => {
    // Get all rows from the table, including headers
    const table = document.querySelector(`#${tableId}`)
    const renderedRows = Array.from(table.querySelectorAll(`[class^="${TABLE_ROW_CLASSNAME}-"]`))
    // +1 to skip the header row
    const renderedRow = renderedRows[index + 1]

    return formatCopyCells
      .map(col => {
        // return original data or rendered content
        if (col.renderedDataAttribute) {
          // get content from the rendered table cell to simplify data processing
          const cell = renderedRow.querySelector(`[data-copy="${col.renderedDataAttribute}"]`)
          const cellContent = cell?.innerText || ''

          return col.formatRenderedContent ? col.formatRenderedContent(cellContent) : cellContent
        } else {
          // format content based on the original data
          return col.formatCellContent(rowData)
        }
      })
      .join('\t') // Join cells by tab
  })
}

export function useTableCopy({ data, formatCopyCells, tableId }) {
  const dispatch = useDispatch()
  const triggeredCopyTableId = useSelector(triggerTableCopySelector)
  const shouldCopyTable = formatCopyCells && triggeredCopyTableId === tableId

  const formatTableContentToSheet = useCallback(
    rowsData => {
      // format and copy table data to clipboard with the format to paste in Excel or sheets
      const headerRow = formatCopyCells.map(col => col.header || '').join('\t')
      // return original data or rendered content
      const formattedTableRows = getFormattedRowsContent({ rowsData, tableId, formatCopyCells })

      return [headerRow, ...formattedTableRows].join('\n')
    },
    [formatCopyCells, tableId]
  )

  const handleCopyToClipboard = useCallback(() => {
    // allow to copy table data to clipboard with the format to paste in excel or sheets
    const tableString = formatTableContentToSheet(data)

    // Copy the resulting string to the clipboard
    navigator.clipboard
      .writeText(tableString)
      .then(() => {
        // success
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }, [data, formatTableContentToSheet])

  useEffect(() => {
    if (shouldCopyTable) {
      handleCopyToClipboard()
      dispatch(clearTriggerTableCopy())
    }
  }, [dispatch, handleCopyToClipboard, shouldCopyTable])
}

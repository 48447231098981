import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'

import { VARIABLES } from '../../fields'

import { getLoadOptionsHandler } from '../../../../../../features/helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { getMediaProductVariablesService } from '../../../../../../modules/services/mediaOrdersProductVariables'

const VariablesPaginatedMultiSelect = ({ formik }) => {
  const { t } = useTranslation()

  const controllerId = useSelector(selectedControllerIdSelector)

  const { values, setFieldValue } = formik

  const formatVariablesBuyers = useCallback(mediaProductVariables => {
    // used regular map instead of formatOptionsList because spreading all fields leads to unexpected behavior in MultiSelectBox
    return mediaProductVariables.map(variable => ({
      label: variable.name,
      value: variable.id
    }))
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getMediaProductVariablesService,
      params: {
        controller: controllerId
      },
      formatOptions: formatVariablesBuyers
    })
  }, [controllerId, formatVariablesBuyers])

  return (
    <MultiSelectBox
      placeholder={t('Select variables')}
      name={VARIABLES}
      // options={variablesOptions}
      loadOptions={loadOptions}
      value={values[VARIABLES]}
      setFieldValue={setFieldValue}
    />
  )
}

export default VariablesPaginatedMultiSelect

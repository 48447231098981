import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Dropdown from '../../../components/Dropdown'
import DropdownContent from './DropdownContent'
import DropdownContentWrapper from './DropdownContentWrapper'
import UploadedFileThumbnail from './UploadedFileThumbnail'

import useStyles from './styles'

// show a certain number of files
const maxImageCountToShow = 3

const UploadedFilesField = ({
  uploadedFiles,
  onApproveHandler,
  onDisapproveHandler,
  bookedMediaId,
  goToMediaOrderSummaryHandler,
  installationReportRowData
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const slicedUploadedFiles = useMemo(() => uploadedFiles.slice(0, maxImageCountToShow), [uploadedFiles])

  if (uploadedFiles?.length > maxImageCountToShow) {
    return (
      <u className={classes.countOfFiles} onClick={goToMediaOrderSummaryHandler}>
        {t('filesCount', { count: uploadedFiles?.length })}
      </u>
    )
  }

  return (
    <div className={classes.container}>
      {slicedUploadedFiles.map(file => {
        return (
          <Dropdown
            key={file.id}
            withFloatingArrow
            placement="top"
            triggerElement={<UploadedFileThumbnail file={file} />}
          >
            <div className={classes.tooltipContainer}>
              <DropdownContentWrapper bookedMediaId={bookedMediaId}>
                <DropdownContent
                  file={file}
                  installationReportRowData={installationReportRowData}
                  onApproveHandler={onApproveHandler}
                  onDisapproveHandler={onDisapproveHandler}
                />
              </DropdownContentWrapper>
            </div>
          </Dropdown>
        )
      })}
    </div>
  )
}

UploadedFilesField.propTypes = {
  uploadedFiles: PropTypes.array,
  fileRequirements: PropTypes.array,
  onApproveHandler: PropTypes.func.isRequired,
  onDisapproveHandler: PropTypes.func.isRequired,
  goToMediaOrderSummaryHandler: PropTypes.func.isRequired,
  installationReportRowData: PropTypes.object
}

export default UploadedFilesField

import { createSelector } from 'reselect'

// PLOP_APPEND_PATTERN_ANCHOR

// Get footfall data as csv

export const footfallDataAsCsvSelector = createSelector(
  ({ footfallData }) => footfallData.footfallDataAsCsv.data,
  data => data
)

export const getFootfallDataAsCsvIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.footfallDataAsCsv.isLoading,
  isLoading => isLoading
)

export const getFootfallDataAsCsvErrorSelector = createSelector(
  ({ footfallData }) => footfallData.footfallDataAsCsv.error,
  error => error
)

export const footfallDataAsCsvWasLoadedSelector = createSelector(
  ({ footfallData }) => footfallData.footfallDataAsCsv.wasLoaded,
  wasLoaded => wasLoaded
)

export const footfallDataFileNameSelector = createSelector(
  ({ footfallData }) => footfallData.footfallDataAsCsv.fileName,
  fileName => fileName
)

// Bulk upload footfall data
export const bulkUploadFootfallDataSelector = createSelector(
  ({ footfallData }) => footfallData.bulkUploadFootfallData.data,
  data => data
)

export const bulkUploadFootfallDataIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.bulkUploadFootfallData.isLoading,
  isLoading => isLoading
)

export const bulkUploadFootfallDataErrorSelector = createSelector(
  ({ footfallData }) => footfallData.bulkUploadFootfallData.error,
  error => error
)

export const bulkUploadFootfallDataWasCreatedSelector = createSelector(
  ({ footfallData }) => footfallData.bulkUploadFootfallData.wasCreated,
  wasCreated => wasCreated
)

// Get footfall areas
export const getFootfallAreasSelector = createSelector(
  ({ footfallData }) => footfallData.footfallAreas.results,
  results => results
)

export const getFootfallAreasIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.footfallAreas.isLoading,
  isLoading => isLoading
)

export const getFootfallAreasNextSelector = createSelector(
  ({ footfallData }) => footfallData.footfallAreas.paging.next,
  next => next
)

export const getFootfallAreasErrorSelector = createSelector(
  ({ footfallData }) => footfallData.footfallAreas.error,
  error => error
)

export const getFootfallAreasWasLoadedSelector = createSelector(
  ({ footfallData }) => footfallData.footfallAreas.wasLoaded,
  wasLoaded => wasLoaded
)

// Create footfall areas
export const createFootfallAreasSelector = createSelector(
  ({ footfallData }) => footfallData.createFootfallAreas.results,
  results => results
)

export const createFootfallAreasIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.createFootfallAreas.isLoading,
  isLoading => isLoading
)

export const createFootfallAreasErrorSelector = createSelector(
  ({ footfallData }) => footfallData.createFootfallAreas.error,
  error => error
)

export const footfallAreasWasCreatedSelector = createSelector(
  ({ footfallData }) => footfallData.createFootfallAreas.wasCreated,
  wasCreated => wasCreated
)

// Update footfall area
export const updateFootfallAreaSelector = createSelector(
  ({ footfallData }) => footfallData.updateFootfallArea.results,
  results => results
)

export const updateFootfallAreaIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.updateFootfallArea.isLoading,
  isLoading => isLoading
)

export const updateFootfallAreaErrorSelector = createSelector(
  ({ footfallData }) => footfallData.updateFootfallArea.error,
  error => error
)

export const footfallAreaWasUpdatedSelector = createSelector(
  ({ footfallData }) => footfallData.updateFootfallArea.wasUpdated,
  wasUpdated => wasUpdated
)

// Delete footfall areas
export const deleteFootfallAreasSelector = createSelector(
  ({ footfallData }) => footfallData.deleteFootfallAreas.results,
  results => results
)
export const deleteFootfallAreasIdSelector = createSelector(
  ({ footfallData }) => footfallData.deleteFootfallAreas.id,
  id => id
)

export const deleteFootfallAreasIsLoadingSelector = createSelector(
  ({ footfallData }) => footfallData.deleteFootfallAreas.isLoading,
  isLoading => isLoading
)

export const deleteFootfallAreasErrorSelector = createSelector(
  ({ footfallData }) => footfallData.deleteFootfallAreas.error,
  error => error
)

export const footfallAreasWasDeletedSelector = createSelector(
  ({ footfallData }) => footfallData.deleteFootfallAreas.wasDeleted,
  wasDeleted => wasDeleted
)

import React from 'react'
import { useTranslation } from 'react-i18next'

import ExternalRecipientFields from './ExternalRecipientSection'
import MinimizedListField from '../../../../../../features/components/Form/MinimizedListField'

import { EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS, initialExternalRecipientValue } from '../../fields'

import useStyles from './styles'

const ExternalRecipientsContent = ({ formik }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <MinimizedListField
      formik={formik}
      fieldPath={EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS}
      initialItemIdKey="id"
      initialListValue={initialExternalRecipientValue}
      minCount={1}
      headerClassName={classes.recipientsHeader}
      headerTitleClassName={classes.recipientsHeaderTitle}
      firstItemText={t('+ Add an external recipient')}
      nextItemText={t('+ Add another external recipient')}
      titleLocalizationVariable={'recipientIndexTitle'}
      deleteBtnText={t('Remove recipient')}
    >
      <ExternalRecipientFields formik={formik} />
    </MinimizedListField>
  )
}

export default ExternalRecipientsContent

import { createStyles } from '../../../styles/helpers'

import { phonesDown, tabletDown } from '../../../styles/const/breakpoints'

export default createStyles(theme => ({
  content: {
    textAlign: 'center',
    marginTop: 32
  },
  icon: {
    marginBottom: 24
  },
  form: {
    overflowX: 'hidden',
    minHeight: 480
  },
  formRow: {
    display: 'flex',
    columnGap: 30,
    '& .field': {
      width: '100%'
    },
    '&:not(:first-of-type)': {
      marginTop: 16
    }
  },
  [`@media screen and (${tabletDown})`]: {
    content: {
      marginTop: 20
    }
  },
  [`@media screen and (${phonesDown})`]: {
    formRow: {
      flexDirection: 'column',
      rowGap: 16,
      '&:first-of-type': {
        marginTop: 8
      }
    },
    form: {
      minHeight: 'max-content'
    }
  },
  submitButtonWrapper: {
    width: 'auto'
  }
}))

import React from 'react'
import { useTranslation } from 'react-i18next'

import VariablesPaginatedMultiSelect from '../../../ReusableFormFIelds/VariablesPaginatedMultiSelect'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const VariablesSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Product variables (optional)')}</h3>
      <VariablesPaginatedMultiSelect formik={formik} />
    </section>
  )
}

export default VariablesSection

import { createStyles } from '../../../../styles/helpers'
import { bannerGrey, darkGrey, mediumGrey } from '../../../../styles/const/colors'
import { NAME_COL_WIDTH, ID_COL_WIDTH } from '../constants'

export default createStyles({
  row: {
    '&:last-child td': {
      borderBottom: `1px solid ${bannerGrey}`
    }
  },
  col: {
    fontSize: '12px',
    padding: '10px 2px 5px',
    textAlign: 'center',
    userSelect: 'none',
    display: 'table-cell',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: darkGrey
  },
  titleCol: {
    extend: 'col'
  },
  subtitleCol: {
    extend: 'col',
    fontSize: 12,
    border: `1px solid ${bannerGrey}`,
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent'
  },
  placeholder: {
    fontSize: '12px',
    padding: '3px 3px 3px 20px',
    position: 'relative',
    display: 'table-cell',
    border: `1px solid ${mediumGrey}`,
    borderLeft: '0px solid transparent',
    borderTop: '0px solid transparent',
    color: darkGrey
  },
  namePlaceholder: {
    extend: 'placeholder',
    width: `${NAME_COL_WIDTH}px`
  },
  idPlaceholder: {
    extend: 'placeholder',
    width: `${ID_COL_WIDTH}px`
  }
})

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Chip from '../../../../../../../components/Chip'
import ErrorMessage from '../../../../../../../components/Form/ErrorMessage'
import FileStatusChipDropdown from './FileStatusChipDropdown'

import {
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalFileStatus
} from '../../../../../../../features/components/UploadedFilesField/helpers'

import { CHIP_COLORS } from '../../../../../../../constants/other'

import useStyles from './styles'
import { MEDIA_ORDER_FILES_PERMISSIONS } from '../../../../../../../constants/permissions'
import usePermissions from '../../../../../../../hooks/usePermissions'

const FileStatusCell = ({ uploadedFile, requirementFileId, handleShowDisapprovalModal, handleStatusChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const approvalStatus = uploadedFile?.approval_status
  const disapprovedReason = uploadedFile?.disapproved_reason
  const buyerDisapprovedReason = uploadedFile?.buyer_disapproved_reason

  const permissions = usePermissions()
  const hasApprovePermissions = permissions && permissions.can('manage', MEDIA_ORDER_FILES_PERMISSIONS)

  const FileStatusChip = useCallback(() => {
    if (hasApprovePermissions) {
      return (
        <FileStatusChipDropdown
          fileStatus={approvalStatus}
          uploadedFile={uploadedFile}
          requirementFileId={requirementFileId}
          handleShowDisapprovalModal={handleShowDisapprovalModal}
          handleStatusChange={handleStatusChange}
        />
      )
    } else {
      const { color, text } = getApprovalFileStatus(approvalStatus, CHIP_COLORS.yellow)
      return <Chip text={text} color={color} />
    }
  }, [
    approvalStatus,
    uploadedFile,
    requirementFileId,
    handleShowDisapprovalModal,
    handleStatusChange,
    hasApprovePermissions
  ])

  if (!approvalStatus) {
    return <Chip text={t('Required')} color={CHIP_COLORS.grey} />
  }

  if (approvalStatus === DISAPPROVED || approvalStatus === BUYER_DISAPPROVED) {
    // if status is disapproved or buyer disapproved, show tooltip with disapproval reason
    return (
      <div className={classes.disapprovedChipContainer}>
        <FileStatusChip />
        <ErrorMessage
          className={classes.errorMessage}
          error={approvalStatus === DISAPPROVED ? disapprovedReason : buyerDisapprovedReason}
        />
      </div>
    )
  } else {
    return <FileStatusChip />
  }
}

FileStatusCell.propTypes = {
  uploadedFile: PropTypes.shape({
    approval_status: PropTypes.string,
    disapproved_reason: PropTypes.string,
    buyer_disapproved_reason: PropTypes.string
  }),
  requirementFileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleStatusChange: PropTypes.func,
  handleShowDisapprovalModal: PropTypes.func
}

export default FileStatusCell

import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  createdContractSelector,
  updateContractSelector,
  updatedContractProductIdSelector
} from '../../../../../../modules/selectors/contracts'
import { MEDIA_PRODUCTS } from '../../fields'

export default function useReachData({ selectedProducts, setFieldValue, isAmendment }) {
  const createdContractData = useSelector(createdContractSelector)
  const updatedContractData = useSelector(updateContractSelector)
  const updatedContractProductId = useSelector(updatedContractProductIdSelector)

  // get the created product in case of the initial Proposal creation or updated products in case of the Proposal update
  const createdProposalProduct = createdContractData?.media_order?.products?.[0]
  const updatedProducts = updatedContractData?.media_order?.products

  function getSelectedProductIndex(selectedProducts, createdProposalProduct, updatedContractProductId) {
    if (createdProposalProduct) {
      // for newly created proposal there is only one product, so we can find it by index 0
      return 0
    } else {
      return selectedProducts.findIndex(
        // find the product in the selected products list by the product id or media_product id
        // check by id, as newly created product has `id` field which is generated on FE by the `uuid` package, so in response the `id` field is not present
        product => product.id === updatedContractProductId || product.data.media_product === updatedContractProductId
      )
    }
  }

  const updateReachIfNeeded = useCallback(
    (selectedProduct, newData, selectedProductIndex) => {
      // Helper: compares and updates the estimated reach if there is a difference between the updated and existed product
      const updatedProductReach = newData.estimated_reach
      const selectedProductReach = selectedProduct.data?.estimated_reach

      if (updatedProductReach !== selectedProductReach) {
        // update only one field for performance reasons
        const productReachPath = `${MEDIA_PRODUCTS}[${selectedProductIndex}].data.estimated_reach`
        setFieldValue(productReachPath, updatedProductReach)
      }
    },
    [setFieldValue]
  )

  useEffect(() => {
    // trigger reach fields update on product create/update
    const isProductUpdated = updatedContractProductId && updatedProducts

    // trigger when the product was created or updated
    if (createdProposalProduct || isProductUpdated) {
      // for newly created proposal there is only one product, so we can find it by index 0
      const selectedProductIndex = getSelectedProductIndex(
        selectedProducts,
        createdProposalProduct,
        updatedContractProductId
      )
      if (selectedProductIndex === -1) {
        return // if the product is not found in the selected products list, then do nothing
      }
      const selectedProduct = selectedProducts[selectedProductIndex]

      // find by media_product id as the updated product doesn't generated on FE
      const mediaProductId = selectedProduct.data?.id
      // found the data of the updated and existed product which triggererd update - for performance reasons make find only when there is updatedContractProductId
      const newData =
        createdProposalProduct || updatedProducts.find(product => product.media_product === mediaProductId)

      updateReachIfNeeded(selectedProduct, newData, selectedProductIndex)
    }
  }, [
    setFieldValue,
    selectedProducts,
    updatedContractProductId,
    updatedContractData,
    createdProposalProduct,
    updatedProducts,
    updateReachIfNeeded
  ])

  const hasReachData = useMemo(() => {
    // for proposal we use estimated_reach, for created mediaOrder/amendment - actual_reach
    const reachField = isAmendment ? 'actual_reach' : 'estimated_reach'
    // don’t show the Reach column unless at least 1 media product has Reach figure (i.e. is > 0)
    return selectedProducts.some(product => product.data?.[reachField] > 0)
  }, [isAmendment, selectedProducts])

  return useMemo(() => {
    return {
      hasReachData
    }
  }, [hasReachData])
}

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getLoadOptionsHandler } from '../../../../../../../features/helpers/componentsHelpers'

import { getProductsService } from '../../../../../../../modules/services/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../../modules/selectors/app'

const useLoadProductOptions = ({ selectedSubCategory, locationCategory, locationRegion, currencyCode }) => {
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)

  const formatProductOptions = useCallback(products => {
    if (products) {
      return products.map(product => ({
        ...product,
        value: product.id,
        label: product.internal_id ? `${product.internal_id} - ${product.name}` : product.name
      }))
    } else {
      return []
    }
  }, [])

  return useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getProductsService,
      params: {
        ordering: 'name',
        controller: selfAccountControllerId,
        ...(locationCategory && { location_category: locationCategory }),
        ...(selectedSubCategory && { media_sub_category: selectedSubCategory }),
        ...(locationRegion && { location_region: locationRegion }),
        fields: ['id', 'name', 'internal_id'].join(','),
        in_stock: true,
        currency_code: currencyCode,
        status: 'active' // filter by active only(but currently for amendments we will fetch all)
      },
      formatOptions: formatProductOptions,
      limit: 50
    })
  }, [
    selfAccountControllerId,
    selectedSubCategory,
    currencyCode,
    formatProductOptions,
    locationCategory,
    locationRegion
  ])
}

export default useLoadProductOptions

import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import Table from '../../../../../../components/Table'
import AdaptiveActions from '../../../../../../features/components/AdaptiveActions'

import { openForm } from '../../../../../../modules/actions/forms'
import { deleteFootfallAreas } from '../../../../../../modules/actions/footfallData'

import {
  deleteFootfallAreasIdSelector,
  getFootfallAreasSelector
} from '../../../../../../modules/selectors/footfallData'

import { columnsStyle, tableColumnsSize } from '../const/columns'
import { FOOTFALL_AREAS_TABLE_ID } from '../const'
import { FOOTFALL_AREAS_EDIT } from '../../../../../../constants/forms'
import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const FootfallAreasTableData = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const { t } = useTranslation()

  const data = useSelector(getFootfallAreasSelector)
  const deleteFootfallAreasId = useSelector(deleteFootfallAreasIdSelector)

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ formName: FOOTFALL_AREAS_EDIT, id }))
    },
    [dispatch]
  )

  const deleteItemHandler = useCallback(
    (e, id) => {
      dispatch(deleteFootfallAreas(id))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete'),
        onClickHandler: deleteItemHandler
      }
    ]
  }, [deleteItemHandler, openEditFormHandler, t])

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        fieldKey: 'name',
        style: { ...columnsStyle.name }
      },
      {
        header: 'No. of stores',
        Cell: data => data.media_stores?.length,
        style: { ...columnsStyle.stores, justifyContent: 'flex-end' }
      },
      {
        Cell: data => <AdaptiveActions itemId={data.id} options={dropdownOptions} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, dropdownOptions]
  )

  return (
    <Table
      data={data}
      cols={columns}
      tableId={FOOTFALL_AREAS_TABLE_ID}
      itemUpdatingId={deleteFootfallAreasId}
      hideFooterRow
    />
  )
}

export default FootfallAreasTableData

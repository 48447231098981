import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import InfoModalNew from '../../../../../../features/components/Modals/InfoModalNew'
import InfoModalButtons from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons'

import { useModalDescription } from '../useModalDescription'
import { useUpdateContractForApprove } from '../useUpdateContractForApprove'

import { CONTRACT_PENDING_APPROVAL_STATUS } from '../../../../../../constants/contacts'
import { PRIMARY_BUTTON } from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons/helpers'

function ApprovalProcessModal({ isOpen, onClose, data }) {
  const { t } = useTranslation()

  const { isLoading, requestSuccess, requestError, handleUpdateContract } = useUpdateContractForApprove({
    contractId: data?.contractId
  })
  const handleSendForApproval = useCallback(() => {
    handleUpdateContract({
      status: CONTRACT_PENDING_APPROVAL_STATUS,
      generate_pdf: 'false' // should be string
    })
  }, [handleUpdateContract])

  const message = useModalDescription(data)

  const buttons = useMemo(() => {
    return [
      {
        type: 'button',
        text: t('Continue editing'),
        onClick: onClose
      },
      {
        type: 'progress',
        text: t('Send for approval'),
        buttonColor: PRIMARY_BUTTON,
        onClick: handleSendForApproval,
        successSubmit: requestSuccess,
        isFormLoading: isLoading,
        formError: requestError,
        onSuccessSubmit: onClose
      }
    ]
  }, [handleSendForApproval, isLoading, onClose, requestError, requestSuccess, t])

  return (
    <InfoModalNew
      modalTitle={t('Discounting approval required')}
      ModalMessage={message}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InfoModalButtons buttons={buttons} />
    </InfoModalNew>
  )
}

ApprovalProcessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object
}

export default ApprovalProcessModal

import { call, put, all, takeEvery, select, takeLatest } from 'redux-saga/effects'

import {
  GET_SELF_ACCOUNTS,
  getSelfAccountsSuccess,
  getSelfAccountsFailure,
  CREATE_SELF_ACCOUNT,
  createSelfAccountSuccess,
  createSelfAccountFailure,
  UPDATE_SELF_ACCOUNT,
  updateSelfAccountSuccess,
  updateSelfAccountFailure,
  GET_SELF_ACCOUNT_CATEGORIES,
  getSelfAccountCategoriesSuccess,
  getSelfAccountCategoriesFailure,
  GET_SELF_ACCOUNT_SUB_CATEGORIES,
  getSelfAccountSubCategoriesSuccess,
  getSelfAccountSubCategoriesFailure,
  getSelfAccountSuccess,
  getSelfAccountFailure,
  GET_SELF_ACCOUNT,
  GET_SELF_ACCOUNT_CATEGORY,
  getSelfAccountCategorySuccess,
  getSelfAccountCategoryFailure
} from '../actions/selfAccounts'
import {
  createSelfAccountService,
  getSelfAccountCategoriesService,
  getSelfAccountService,
  getSelfAccountsService,
  getSelfAccountSubCategoriesService,
  updateSelfAccountService,
  getSelfAccountCategoryService
} from '../services/selfAccounts'

import { getTagsList } from '../actions/tags'
import { createCurrentUserSelfAccount, setSelectedSelfAccount, updateCurrentUserSelfAccount } from '../actions/app'
import { selectedControllerIdSelector } from '../selectors/app'

function* selfAccountsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeLatest(GET_SELF_ACCOUNTS, getSelfAccountsWorker),
    takeEvery(CREATE_SELF_ACCOUNT, createSelfAccountWorker),
    takeEvery(UPDATE_SELF_ACCOUNT, updateSelfAccountWorker),
    takeEvery(GET_SELF_ACCOUNT_CATEGORIES, getCategoriesWorker),
    takeEvery(GET_SELF_ACCOUNT_SUB_CATEGORIES, getSubCategoriesWorker),
    takeEvery(GET_SELF_ACCOUNT, getSelfAccountWorker),
    takeEvery(GET_SELF_ACCOUNT_CATEGORY, getSelfAccountCategoryWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getSelfAccountsWorker({ params }) {
  try {
    const response = yield call(getSelfAccountsService, params)
    yield put(getSelfAccountsSuccess(response))
  } catch (e) {
    yield put(getSelfAccountsFailure(e))
  }
}

function* createSelfAccountWorker({ selfAccountData, selectSelfAccountOnSuccess }) {
  try {
    const response = yield call(createSelfAccountService, selfAccountData)
    yield put(createSelfAccountSuccess(response))

    // also update current user profile accounts
    yield put(createCurrentUserSelfAccount(response))

    // we need to set selected self account in case like first login flow
    if (selectSelfAccountOnSuccess) {
      yield put(setSelectedSelfAccount(response.id))
    }
    const controllerId = yield select(selectedControllerIdSelector)
    yield put(getTagsList({ controller: controllerId }))
  } catch (e) {
    yield put(createSelfAccountFailure(e))
  }
}

function* updateSelfAccountWorker({ id, selfAccountData }) {
  try {
    const response = yield call(updateSelfAccountService, id, selfAccountData)
    yield put(updateSelfAccountSuccess(response))

    // also update current user profile accounts
    yield put(
      updateCurrentUserSelfAccount({
        // we use FE payload instead of BE response as BE structure is different to the users/me accounts
        ...selfAccountData,
        id: response.id,
        // take brands from response as payload misses the id for new brands and full object subfields
        brands: response.brands
      })
    )
    const controllerId = yield select(selectedControllerIdSelector)
    yield put(getTagsList({ controller: controllerId }))
  } catch (e) {
    yield put(updateSelfAccountFailure(e))
  }
}

function* getCategoriesWorker({ params }) {
  try {
    const response = yield call(getSelfAccountCategoriesService, params)
    yield put(getSelfAccountCategoriesSuccess(response))
  } catch (error) {
    yield put(getSelfAccountCategoriesFailure(error))
  }
}

function* getSubCategoriesWorker({ params }) {
  try {
    const response = yield call(getSelfAccountSubCategoriesService, params)
    yield put(getSelfAccountSubCategoriesSuccess(response))
  } catch (error) {
    yield put(getSelfAccountSubCategoriesFailure(error))
  }
}

function* getSelfAccountWorker({ params }) {
  try {
    const response = yield call(getSelfAccountService, params)
    yield put(getSelfAccountSuccess(response))
  } catch (error) {
    yield put(getSelfAccountFailure(error))
  }
}

function* getSelfAccountCategoryWorker({ params }) {
  try {
    const response = yield call(getSelfAccountCategoryService, params)
    yield put(getSelfAccountCategorySuccess(response))
  } catch (error) {
    yield put(getSelfAccountCategoryFailure(error))
  }
}

export default selfAccountsWatcher

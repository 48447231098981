import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  buttonsContainer: {
    display: 'flex',
    gap: '16px'
  }
})

export default useStyles

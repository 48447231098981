import React from 'react'
import { useTranslation } from 'react-i18next'

import StoreSectionFields from './StoreSection'
import MinimizedListField from '../../../../../../features/components/Form/MinimizedListField'

import { STORES, initialStoreValue } from '../../fields'

const StoresContent = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <MinimizedListField
      formik={formik}
      fieldPath={STORES}
      initialItemIdKey="id"
      initialListValue={initialStoreValue}
      minCount={1}
      firstItemText={t('+ Add a sub-location')}
      nextItemText={t('+ Add another sub-location')}
      titleLocalizationVariable={'subLocationIndexTitle'}
    >
      <StoreSectionFields formik={formik} />
    </MinimizedListField>
  )
}

export default StoresContent

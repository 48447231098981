import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SelectPaginated from '../../../../components/SelectPaginated'

import { formatOptionsList } from '../../../formatters'
import { getLoadOptionsHandler } from '../../../helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { getProductTagsService } from '../../../../modules/services/tags'

import { mediaProductTagsParams } from '../../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export const SELECTED_TAG = 'selectedTag'

const ProductTagsFilter = ({ Context, placeholder = 'Filter' }) => {
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const { filtersState, setFiltersState } = useContext(Context)

  const { [SELECTED_TAG]: selectedTag } = filtersState

  const formatTagsOptions = useCallback(tags => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [])

  const setSelectedTag = useCallback(
    newTag => {
      const updatedFiltersState = {
        ...filtersState,
        [SELECTED_TAG]: newTag
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  const handleChangeFilter = useCallback(
    option => {
      setSelectedTag(option?.value)
    },
    [setSelectedTag]
  )

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getProductTagsService,
      params: {
        ...mediaProductTagsParams,
        controller: selectedControllerId,
        ordering: 'name'
      },
      formatOptions: formatTagsOptions
    })
  }, [formatTagsOptions, selectedControllerId])

  return (
    <SelectPaginated
      value={selectedTag}
      onChange={handleChangeFilter}
      loadOptions={loadOptions}
      placeholder={placeholder}
      isSmall
      portaled={true}
      isHighlighted={true}
    />
  )
}

ProductTagsFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  placeholder: PropTypes.string
}

export default ProductTagsFilter

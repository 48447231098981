import { IMAGES, ORDER } from '../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  ADDRESS,
  ALT_TEXT,
  BOOKING_NOTIFICATION_RECIPIENTS,
  EMAIL,
  EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS,
  NAME,
  SUMMARY_DAYS_ADVANCE,
  SUMMARY_INTERVAL,
  summaryIntervals,
  WEEK_M,
  WEEK_S
} from './fields'
import { FILE_URL } from '../../../../constants/files'

const formatImagesToBE = images => {
  return images.map(image => ({
    image: image[FILE_URL],
    ...(image[ALT_TEXT] && { [ALT_TEXT]: image[ALT_TEXT] }),
    [ORDER]: image[ORDER]
  }))
}

export const formatSummaryIntervalToBE = (values, controllerTimezone) => {
  if (values[SUMMARY_INTERVAL] === summaryIntervals.WEEK) {
    // To tell whether to use Sunday or Monday wed use the controller timezone.
    // if it’s Asia/Riyadh then it’s week_s otherwise week_m
    return controllerTimezone === 'Asia/Riyadh' ? WEEK_S : WEEK_M
  } else {
    return values[SUMMARY_INTERVAL]
  }
}

export const reusableTransformValuesToBE = (values, controllerTimezone) => {
  const filledImages = values[IMAGES].filter(image => image[FILE_URL])
  const filledExternalRecipients = values[EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS].filter(
    recipient => recipient[EMAIL]
  )

  return {
    [NAME]: values[NAME],
    [ADDRESS]: values[ADDRESS],
    ...(filledImages.length && {
      [IMAGES]: formatImagesToBE(filledImages)
    }),
    [BOOKING_NOTIFICATION_RECIPIENTS]: values[BOOKING_NOTIFICATION_RECIPIENTS].map(item => item.value),
    [EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]: filledExternalRecipients,
    [SUMMARY_INTERVAL]: formatSummaryIntervalToBE(values, controllerTimezone),
    [SUMMARY_DAYS_ADVANCE]: Number(values[SUMMARY_DAYS_ADVANCE])
  }
}

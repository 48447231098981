import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { endOfDay, startOfDay } from 'date-fns'
import { useSelector } from 'react-redux'

import TableActions from './TableActions'
import ProductsTable from './ProductsTable'
import ConditionalWrapper from '../../../../../components/hoc/ConditionalWrapper'
import ProductsCalendarPreview from '../../../../../pages/Proposals/ProductsCalendarPreview'

import { DiscountsProvider } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/DiscountsContext'
import { ProductsCalendarProvider } from '../../../../../pages/Proposals/ProductsCalendarPreview/ProductsCalendarContext'
import { MediaOrderFormContext } from '../../MediaOrderFormContext'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS, PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../fields'

function ProductsManage({ formik, isAdditionalInfoEdit, allowEdit, allowAutoSave, checkInventory, isAmendment }) {
  const { currency } = useContext(MediaOrderFormContext)

  const controllerData = useSelector(selectedControllerDataSelector)
  // if controller quotation_approval_workflow = true, then we need to have approvals logic on proposals builder
  const hasProposalApprovalFlow = controllerData?.quotation_approval_workflow

  const { values } = formik

  const selectedProducts = useMemo(() => values[MEDIA_PRODUCTS], [values])
  const hasProductWithData = useMemo(
    () => selectedProducts.some(product => product[PRODUCT_GROUPED_PUBLICATIONS_DATES].length > 0),
    [selectedProducts]
  )

  const calendar = useMemo(() => {
    const calendarDates = {
      earliestDate: null,
      latestDate: null
    }

    // show only products row with data(selected product)
    const formattedProducts = selectedProducts
      .filter(product => Boolean(product.data))
      .map(product => {
        return {
          group: {
            id: product.id,
            name: product.data?.name,
            asset_id: product.data?.internal_id
          },
          events: product[PRODUCT_GROUPED_PUBLICATIONS_DATES].map(periods => {
            const startDate = new Date(periods[0].date_start)
            const endDate = new Date(periods[periods.length - 1].date_end)

            if (calendarDates.earliestDate && calendarDates.latestDate) {
              // check if startDate is earlier then the calendar.start date
              if (startDate < calendarDates.earliestDate) {
                calendarDates.earliestDate = startDate
              }
              // check if endDate is later then the calendar.end date
              if (endDate > calendarDates.latestDate) {
                calendarDates.latestDate = endDate
              }
            } else {
              // set the first start and end date
              calendarDates.earliestDate = startDate
              calendarDates.latestDate = endDate
            }

            return {
              ...periods,
              currency,
              start_timestamp: +startOfDay(startDate), // use timestamps instead of dates
              // take the full end date (end of the day) to show the event on the full day
              end_timestamp: +endOfDay(endDate) //  use timestamps instead of dates
            }
          })
        }
      })

    return {
      ...calendarDates,
      products: formattedProducts
    }
  }, [currency, selectedProducts])

  return (
    // use discounts provider only for non-amendment contracts
    <ConditionalWrapper
      condition={!isAmendment}
      wrapper={children => (
        <DiscountsProvider
          // provide discounts to redux store only for contracts with approval flow, to be able to use it before Media
          // order generation
          provideToRedux={hasProposalApprovalFlow}
          allSelectedProducts={selectedProducts.filter(product => {
            return product.data
          })}
        >
          {children}
        </DiscountsProvider>
      )}
    >
      <ProductsCalendarProvider calendar={calendar}>
        {hasProductWithData && <ProductsCalendarPreview products={calendar.products} />}
      </ProductsCalendarProvider>
      <TableActions
        values={values}
        isAmendment={isAmendment}
        products={selectedProducts}
        setFieldValue={formik.setFieldValue}
      />
      <ProductsTable
        formik={formik}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        allowEdit={allowEdit}
        allowAutoSave={allowAutoSave}
        checkInventory={checkInventory}
        isAmendment={isAmendment}
      />
    </ConditionalWrapper>
  )
}

ProductsManage.propTypes = {
  formik: PropTypes.object.isRequired,
  isAdditionalInfoEdit: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowAutoSave: PropTypes.bool,
  isAmendment: PropTypes.bool,
  checkInventory: PropTypes.bool
}

export default ProductsManage

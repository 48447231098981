import React, { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

import ProductLocationsActions from '../ProductLocationsActions'
import Table from '../../../../../../components/Table'

import { mediaProductLocationsSelector } from '../../../../../../modules/selectors/mediaOrdersProductLocations'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'
import { tableColumnsSize } from '../index'

import useProductCardsStyles from '../../../../MediaProducts/productCardStyles'

const ProductLocationsTableData = () => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const classes = useProductCardsStyles()

  const mediaProductLocations = useSelector(mediaProductLocationsSelector)

  const productLocationColumns = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        Cell: data => <ProductLocationsActions location={data} className={classes.actionsStyles} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, classes]
  )

  return (
    <Table
      hideHeadlineRow
      data={mediaProductLocations}
      cols={productLocationColumns}
      tableId="ProductLocationsTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

export default ProductLocationsTableData

import { useCallback } from 'react'

import { formatDateToBE } from '../../../../../../../../../../../constants/dates'
import { findMultiplePeriodsCovering, findSinglePeriodCovering } from '../helpers'

export function useHandlePeriodSelection({ availablePeriods, additionalAvailableDays, setNewPeriods }) {
  return useCallback(
    ({ endDate, startDate }) => {
      const dateRangeStart = new Date(startDate)
      dateRangeStart.setHours(0, 0, 0, 0)
      const dateRangeEnd = new Date(endDate)
      dateRangeEnd.setHours(23, 59, 59, 999)

      // First, check if any single period fully covers the selected date range
      const coveringPeriod = findSinglePeriodCovering({
        availablePeriods,
        additionalAvailableDays,
        dateRangeStart,
        dateRangeEnd
      })

      if (coveringPeriod) {
        // SINGLE PERIOD SELECTION
        // The selected date range is fully covered by one period
        const adjustedPeriod = {
          ...coveringPeriod,
          date_start: formatDateToBE(dateRangeStart),
          date_end: formatDateToBE(dateRangeEnd)
        }

        // Update periods
        const newPeriods = [adjustedPeriod]
        setNewPeriods(newPeriods)
      } else {
        // MULTIPLE PERIODS SELECTION
        const overlappingPeriods = findMultiplePeriodsCovering({
          availablePeriods,
          additionalAvailableDays,
          dateRangeStart,
          dateRangeEnd
        })

        const newPeriods = []
        // Set the starting point for processing dates to the beginning of the desired date range
        let currentDate = dateRangeStart

        // Iterate through each period that overlaps with the desired date range
        for (let i = 0; i < overlappingPeriods.length; i++) {
          const { periodData, periodAvailableStartDate, periodAvailableEndDate } = overlappingPeriods[i]
          const nextPeriod = overlappingPeriods[i + 1]

          // Calculate the adjusted start date for the current period
          // It should be the latest of:
          // - currentDate (the date we're processing)
          // - periodAvailableStartDate (when the period becomes available)
          // - dateRangeStart (the overall start of the desired date range)
          const adjustedStartDate = new Date(
            Math.max(currentDate.getTime(), periodAvailableStartDate.getTime(), dateRangeStart.getTime())
          )

          // Calculate the initial adjusted end date for the current period
          // It should be the earliest of:
          // - periodAvailableEndDate (when the period is no longer available)
          // - dateRangeEnd (the overall end of the desired date range)
          let adjustedEndDate = new Date(Math.min(periodAvailableEndDate.getTime(), dateRangeEnd.getTime()))

          // If there is a subsequent period, adjust the end date to be just before the next period starts
          if (nextPeriod) {
            // Create a Date object for the start of the next period and reset its time to midnight
            const nextPeriodStartDate = new Date(nextPeriod.startPeriodDate)
            nextPeriodStartDate.setHours(0, 0, 0, 0)

            // Determine the day before the next period starts
            const dayBeforeNextPeriodStart = new Date(nextPeriodStartDate)
            dayBeforeNextPeriodStart.setDate(dayBeforeNextPeriodStart.getDate() - 1)
            dayBeforeNextPeriodStart.setHours(23, 59, 59, 999)

            // Adjust the end date to be the earlier of the current adjusted end date
            // or the last moment before the next period starts
            adjustedEndDate = new Date(Math.min(adjustedEndDate.getTime(), dayBeforeNextPeriodStart.getTime()))
          }

          // Check if the adjusted start date is on or before the adjusted end date
          if (adjustedStartDate <= adjustedEndDate) {
            // Add the current period with the newly adjusted start and end dates to newPeriods
            newPeriods.push({
              ...periodData,
              date_start: formatDateToBE(adjustedStartDate), // Format the start date as needed
              date_end: formatDateToBE(adjustedEndDate) // Format the end date as needed
            })

            // Update currentDate to the day after the adjusted end date to continue processing
            currentDate = new Date(adjustedEndDate)
            currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
            currentDate.setHours(0, 0, 0, 0)
          } else {
            // If there's no valid period between adjustedStartDate and adjustedEndDate,
            // move the currentDate forward to the adjustedStartDate to avoid infinite loops
            currentDate = adjustedStartDate
          }

          // If the updated currentDate exceeds the overall end of the desired date range, exit the loop
          if (currentDate > dateRangeEnd) {
            break // No further processing needed as we've covered the entire date range
          }
        }

        setNewPeriods(newPeriods)
      }
    },
    [availablePeriods, additionalAvailableDays, setNewPeriods]
  )
}

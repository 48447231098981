import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useExampleColumns = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        header: 'Store',
        Cell: () => (
          <div>
            <div>10</div>
            <div>10</div>
            <div>10</div>
            <div>11</div>
            <div>11</div>
            <div>11</div>
          </div>
        )
      },
      {
        header: 'Date',
        Cell: () => (
          <div>
            <div>2025-01-01</div>
            <div>2025-01-01</div>
            <div>2025-01-01</div>
            <div>2025-01-01</div>
            <div>2025-01-01</div>
            <div>2025-01-01</div>
          </div>
        )
      },
      {
        header: 'Reach',
        Cell: () => (
          <div>
            <div>1700</div>
            <div>1750</div>
            <div>1800</div>
            <div>3455</div>
            <div>3620</div>
            <div>3500</div>
          </div>
        )
      },
      {
        header: 'Period type',
        Cell: () => (
          <div>
            <div>{t('daily')}</div>
            <div>{t('daily')}</div>
            <div>{t('daily')}</div>
            <div>{t('daily')}</div>
            <div>{t('daily')}</div>
            <div>{t('daily')}</div>
          </div>
        )
      }
    ],
    [t]
  )
}

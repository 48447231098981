import { createStyles } from '../../../styles/helpers'

import { greyOutline } from '../../../styles/const/colors'
import { textEllipsis } from '../../../styles/mixins/text'

const useStyles = createStyles(theme => ({
  header: {
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    ...textEllipsis(),
    whiteSpace: 'unset'
  },
  removeFileBtn: {
    color: greyOutline
  }
}))

export default useStyles

import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../../../components/Form'
import ProposalMediaOrderRecipientsFormContent from './ProposalMediaOrderRecipientsFormContent'

import { clearGenerateMediaOrder, generateMediaOrder } from '../../../../../../modules/actions/mediaOrders'

import { membersSelector } from '../../../../../../modules/selectors/selfAccountTeam'
import {
  generateMediaOrderErrorSelector,
  generateMediaOrderIsLoadingSelector,
  mediaOrderWasGeneratedSelector
} from '../../../../../../modules/selectors/mediaOrders'

import { PROPOSAL_MEDIA_ORDER_RECIPIENTS } from '../../../../../../constants/forms'
import { validationSchema } from './validation'

import { getInitialValues } from './fields'
import { transformNotificationUsersOrEmailsToBE } from '../../../../../../forms/ReusableFormFields/RecipientsFields/formatters'

function ProposalMediaOrderGenerateForm({ contractId, onSuccessSubmit }) {
  const dispatch = useDispatch()

  const members = useSelector(membersSelector)
  const wasGenerated = useSelector(mediaOrderWasGeneratedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformNotificationUsersOrEmailsToBE(values)

      dispatch(
        generateMediaOrder(
          contractId,
          {},
          {
            ...transformedData,
            without_requirements: true // when confirming and book, the media order should be generated without sign
          }
        )
      )
    },
    [contractId, dispatch]
  )

  const initialValues = useMemo(() => {
    return getInitialValues(members)
  }, [members])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearSubmitHandler = useCallback(() => {
    dispatch(clearGenerateMediaOrder())
  }, [dispatch])

  return (
    <Form
      formik={formik}
      formName={PROPOSAL_MEDIA_ORDER_RECIPIENTS}
      // processing
      successSubmit={wasGenerated}
      errorSelector={generateMediaOrderErrorSelector}
      isLoadingSelector={generateMediaOrderIsLoadingSelector}
      // after form submit
      clearHandler={clearSubmitHandler}
      submitText="Confirm and book"
      onSuccessSubmit={onSuccessSubmit}
    >
      <ProposalMediaOrderRecipientsFormContent formik={formik} />
    </Form>
  )
}

ProposalMediaOrderGenerateForm.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func
}

export default ProposalMediaOrderGenerateForm

import React from 'react'
import { useTranslation } from 'react-i18next'

import ProductSubCategories from './ProductSubCategories'
import ContentSection from '../../../features/components/ContentSection'
import ProductTags from '../MediaProductOptions/ProductTags'
import ProductLocations from '../MediaProductOptions/ProductLocations'
import ProductVariables from '../MediaProductOptions/ProductVariables'

import useSettingsClasses from '../styles'

const MediaProductOptions = () => {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Media Product Options')}</h1>
      <ContentSection title="Media Product Options">
        <ProductSubCategories />
        <ProductLocations />
        <ProductVariables />
        <ProductTags />
      </ContentSection>
    </>
  )
}

export default MediaProductOptions

import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  usersSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  }
})

export default useStyles

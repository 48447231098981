export const tableColumnsSize = {
  actions: 30
}

export const columnsSchema = [
  {
    fieldKey: 'name'
  },
  {
    style: { maxWidth: tableColumnsSize.actions }
  }
]

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import PlatformsCheckboxes from './PlatformsCheckboxes'
import Field from '../../../../../../../../components/Form/Field'
import InventoryFields from '../../../../../../../../features/components/InventoryFields'
import DiscountsSection from '../../../../MediaProductCreate/MediaProductCreateForm/Steps/PricingStep/DiscountsSection'
import FilesRequirementsContent from '../../../../ReusableFormFIelds/FilesRequirementsContent'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import TagsSection from '../TagsSection'
import Spoiler from '../../../../../../../../components/Spoiler'
import MinAndMaxPeriodsFields from '../../../../ReusableFormFIelds/MinAndMaxPeriodsFields'
import DatePicker from '../../../../../../../../components/DatePicker'
import NameSection from '../../../sections/NameSection'
import DescriptionSection from '../../../sections/DescriptionSection'
import MediaCategorySection from '../../../sections/MediaCategorySection'
import PeriodSection from '../../../sections/PeriodSection'
import LocationSection from '../../../sections/LocationSection'
import PricesSection from '../../../sections/PricesSection'
import ImagesSection from '../../../sections/ImagesSection'
import VariablesSection from '../../../sections/VariablesSection'
import InternalIdSection from '../../../sections/InternalIdSection'
import AttachedFilesSection from '../AttachedFilesSection'

import {
  CREATIVE_DEADLINE_DAYS,
  DEFAULT_INVENTORY_DATE_START,
  MEDIA_CATEGORY
} from '../../../../MediaProductCreate/MediaProductCreateForm/fields'
import { DIGITAL } from '../../../../../../../../constants/mediaOrders'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const MediaProductEditFormContent = ({ formik, editProductData }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const { offsite } = editProductData

  const { setFieldValue, values, errors, touched } = formik

  const handleStartDatePickerChange = useCallback(
    value => {
      setFieldValue(DEFAULT_INVENTORY_DATE_START, value)
    },
    [setFieldValue]
  )

  const isDigital = useMemo(() => values[MEDIA_CATEGORY] === DIGITAL, [values])

  return (
    <>
      <NameSection formik={formik} />
      <DescriptionSection formik={formik} />
      <MediaCategorySection formik={formik} />
      <PeriodSection formik={formik} />
      <LocationSection formik={formik} editProductData={editProductData} />

      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Creative Deadline Days')}</h3>
        <Field
          formik={formik}
          name={CREATIVE_DEADLINE_DAYS}
          placeholder="Creative Deadline Days"
          enableReinitialize
          type="number"
          decimalScale={0}
          allowNegative={false}
        />
      </section>

      <PricesSection formik={formik} />

      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Discounts')}</h3>
        <DiscountsSection formik={formik} />
      </section>

      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Inventory')}</h3>
        <InventoryFields formik={formik} />
        <FieldRow title="Inventory start date" description={t('The first date that the inventory is available')}>
          <DatePicker
            placeholder={t('Inventory start date')}
            value={values[DEFAULT_INVENTORY_DATE_START]}
            changeHandler={handleStartDatePickerChange}
            error={touched[DEFAULT_INVENTORY_DATE_START] ? errors[DEFAULT_INVENTORY_DATE_START] : ''}
          />
        </FieldRow>
        <Spoiler title="Advanced settings">
          <MinAndMaxPeriodsFields formik={formik} />
        </Spoiler>
      </section>

      {isDigital && offsite && (
        <section className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>{t('Platforms')}</h3>
          <p>{t('Which platform(s) should the digital campaign be executed on?')}</p>
          <PlatformsCheckboxes formik={formik} />
        </section>
      )}
      {!isDigital && (
        <section className={drawerFormClasses.section}>
          <h3 className={drawerFormClasses.sectionTitle}>{t('Required files')}</h3>
          <FilesRequirementsContent formik={formik} />
        </section>
      )}
      <ImagesSection formik={formik} />
      <TagsSection formik={formik} />
      <VariablesSection formik={formik} />
      <InternalIdSection formik={formik} />
      <AttachedFilesSection formik={formik} />
    </>
  )
}

MediaProductEditFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  editProductData: PropTypes.object.isRequired
}

export default MediaProductEditFormContent

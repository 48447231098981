import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Page from '../index'
import QuotationsTable from './QuotationsTable'
import ProposalsPageHeader from './ProposalsPageHeader'
import SuccessQuotationSentModal from './SuccessQuotationSentModal'
import QuotationsTableActions from './QuotationsTable/QuotationsTableActions'

import { QuotationTableContextProvider } from './QuotationsTable/QuotationTableContext'

import {
  isClientViewSelector,
  isUserControllerSelector,
  selectedSelfAccountSelector
} from '../../modules/selectors/app'

const Proposals = () => {
  const isClientView = useSelector(isClientViewSelector)
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const isUserController = useSelector(isUserControllerSelector)

  const [accountFilterOption, setAccountFilterOption] = useState(undefined)

  const accountFilter = useMemo(() => {
    // filter is shown only to controller user when View as client OFF
    if (isUserController && !isClientView) {
      return accountFilterOption
    } else {
      return selectedSelfAccount
    }
  }, [accountFilterOption, isClientView, isUserController, selectedSelfAccount])

  return (
    <Page helmetTitle="helmetTitle.ProposalsPage">
      <ProposalsPageHeader />
      <QuotationTableContextProvider accountFilter={accountFilter}>
        <QuotationsTableActions
          accountFilterOption={accountFilterOption}
          setAccountFilterOption={setAccountFilterOption}
        />
        <QuotationsTable />
      </QuotationTableContextProvider>
      <SuccessQuotationSentModal />
      {/*for now, it's hidden due to issue with the PDF generation - PDF contract is not updating on each update, so it's not generated when the GET is done  */}
      {/*<QuotationReviewDrawer />*/}
    </Page>
  )
}

export default Proposals

import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import MediaSubCategoryFields from '../../MediaSubCategoryCreate/MediaSubCategoryCreateForm/MediaSubCategoryFields'

import {
  updateMediaSubCategory,
  clearUpdateMediaSubCategory
} from '../../../../../../modules/actions/mediaOrdersProducts'

import {
  mediaSubCategoryWasUpdatedSelector,
  updateMediaSubCategoryErrorSelector,
  updateMediaSubCategoryIsLoadingSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_SUB_CATEGORY_EDIT } from '../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { getInitialValues } from './fields'
import { transformValuesToBE } from '../../fields'

function MediaSubCategoryEditForm({ subCategoryData }) {
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const itemWasUpdated = useSelector(mediaSubCategoryWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values, selectedControllerId)
      dispatch(
        updateMediaSubCategory(subCategoryData?.id, {
          ...transformedData,
          controller: selectedControllerId
        })
      )
    },
    [dispatch, selectedControllerId, subCategoryData?.id]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearUpdateMediaSubCategory())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues({ subCategoryData })
  }, [subCategoryData])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={MEDIA_SUB_CATEGORY_EDIT}
      successSubmit={itemWasUpdated}
      errorSelector={updateMediaSubCategoryErrorSelector}
      isLoadingSelector={updateMediaSubCategoryIsLoadingSelector}
      clearHandler={handleClearHandler}
    >
      {/* don’t allow to delete, because that will cause issues so leave off delete for now. */}
      <MediaSubCategoryFields formik={formik} allowDeleteSubSubCategory={false} />
    </Form>
  )
}

export default MediaSubCategoryEditForm

import React from 'react'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import BookingVariableCreateForm from './BookingVariableCreateForm'

import { BOOKING_VARIABLE_CREATE } from '../../../../../../constants/forms'

function BookingVariableCreate() {
  return (
    <FormDrawerWrapper
      formName={BOOKING_VARIABLE_CREATE}
      title="Create a booking variable"
      description="Booking variables are asked at the point of booking, and can be used to attach linked files to the booking confirmation email."
      openButtonText="Add new variable"
      closeOnSubmit={true}
    >
      <BookingVariableCreateForm />
    </FormDrawerWrapper>
  )
}

export default BookingVariableCreate

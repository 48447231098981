import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Form from '../../../../../../../components/Form'
import MediaProductGroupEditFormContent from './MediaProductGroupEditFormContent'

import { transformValuesToBE } from './formatters'
import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'

import { clearUpdateProduct, updateProduct } from '../../../../../../../modules/actions/mediaOrdersProducts'
import {
  updateProductErrorSelector,
  updateProductIsLoadingSelector,
  updateProductWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { productTagsSelector } from '../../../../../../../modules/selectors/tags'

import { MEDIA_PRODUCT_GROUP_EDIT } from '../../../../../../../constants/forms'
import { getInitialValues } from './fields'
import { validationSchema } from './validation'
import { SUB_PRODUCTS } from '../../../fields'

function MediaProductGroupEditForm({ editProductData = {} }) {
  const dispatch = useDispatch()

  const { id: productId } = editProductData

  const productWasUpdated = useSelector(updateProductWasUpdatedSelector)
  const productTags = useSelector(productTagsSelector)

  const subProducts = useMemo(() => editProductData[SUB_PRODUCTS] || [], [editProductData])

  const formattedSubProductsOptions = useMemo(() => {
    return subProducts.map(subProduct => ({
      ...subProduct,
      value: subProduct.id,
      label: subProduct.name
    }))
  }, [subProducts])

  const initialValues = useMemo(
    () => getInitialValues({ editProductData, productTags, formattedSubProductsOptions }),
    [editProductData, productTags, formattedSubProductsOptions]
  )

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({ initialValues, values })

      dispatch(updateProduct(productId, transformedData))
    },
    [dispatch, productId, initialValues]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearEditMediaProduct = useCallback(() => {
    dispatch(clearUpdateProduct())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={MEDIA_PRODUCT_GROUP_EDIT}
      successSubmit={productWasUpdated}
      errorSelector={updateProductErrorSelector}
      isLoadingSelector={updateProductIsLoadingSelector}
      clearHandler={clearEditMediaProduct}
    >
      <MediaProductGroupEditFormContent
        formik={formik}
        editProductData={editProductData}
        formattedSubProductsOptions={formattedSubProductsOptions}
      />
    </Form>
  )
}

MediaProductGroupEditForm.propTypes = {
  editProductData: PropTypes.object,
  products: PropTypes.array
}

export default MediaProductGroupEditForm

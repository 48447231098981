import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import PdfPageSkeleton from '../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'
import AmendmentSubmitButton from './AmendmentSubmitButton'
import ManageAmendmentContract from '../Amendment/ManageAmendmentContract'
import Button from '../../../../components/Button'

import { deleteAmendmentContractSignature } from '../../../../modules/actions/amendments'
import {
  createAmendmentErrorSelector,
  deleteAmendmentContractSignatureIsLoadingSelector
} from '../../../../modules/selectors/amendments'

import useStyles from './styles'

const signType = 'user'

const AmendmentSubmit = ({ contract, onSuccessSubmit, tokenParams }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const deleteContractSignatureIsLoading = useSelector(deleteAmendmentContractSignatureIsLoadingSelector)
  const createContractsError = useSelector(createAmendmentErrorSelector)

  const contractId = contract?.id
  const isContractSigned = !!contract?.user_signature
  const isContractInvalid = contract?.status === 'invalid'

  const showSubmitButton = useMemo(
    () => isContractSigned && !deleteContractSignatureIsLoading,
    [isContractSigned, deleteContractSignatureIsLoading]
  )

  const handleSignatureClear = useCallback(() => {
    dispatch(deleteAmendmentContractSignature({ id: contractId, params: tokenParams, signType }))
  }, [dispatch, contractId, tokenParams])

  const SignedContractActions = () => {
    return showSubmitButton ? (
      <>
        <h4 className={classes.approveTitle}>{t('Ready to confirm your booking and reserve the inventory?')}</h4>
        <div className={classes.buttons}>
          <Button type="button" onClick={handleSignatureClear}>
            {t('Clear signature')}
          </Button>
          <AmendmentSubmitButton contractId={contractId} onSuccessSubmit={onSuccessSubmit} tokenParams={tokenParams} />
        </div>
      </>
    ) : (
      <div className={classes.buttons}>
        <Button type="button" onClick={handleSignatureClear}>
          {t('Clear signature')}
        </Button>
      </div>
    )
  }

  if (deleteContractSignatureIsLoading) {
    return <PdfPageSkeleton />
  }

  if (isContractInvalid) {
    return (
      <p>
        {t(
          'Sorry, there was an error generating this contract. We are looking into this issue and will contact you with an update as soon as possible.'
        )}
      </p>
    )
  }

  if (createContractsError) {
    return null
  }

  return (
    <>
      <ManageAmendmentContract
        signType={signType}
        contract={contract}
        isContractSigned={isContractSigned}
        tokenParams={tokenParams}
      />
      {isContractSigned && <SignedContractActions />}
    </>
  )
}

AmendmentSubmit.propTypes = {
  contract: PropTypes.object,
  onSuccessSubmit: PropTypes.func,
  tokenParams: PropTypes.object
}

export default AmendmentSubmit

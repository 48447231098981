import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../../../../../features/components/Form/MultiSelectBox'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { formatOptionsList } from '../../../../../../../../../../features/formatters'

import { mediaProductVariablesSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProductVariables'

import { CAN_SELECT_MULTIPLE, REQUIRED } from '../../../../../../../../MediaProducts/MediaProductVariableForms/fields'
import { MULTIPLE_VARIABLE_OPTIONS, SINGLE_VARIABLE_OPTION } from '../../../../../../../fields'

import { getLoadOptionsHandler } from '../../../../../../../../../../features/helpers/componentsHelpers'
import { getMediaProductVariableOptionsService } from '../../../../../../../../../../modules/services/mediaOrdersProductVariables'

import useStyles from './styles'

const initialOptions = []
const VariableOptionsSelector = ({ formik, productItemPath, index, selectedVariableId, stepErrors, showError }) => {
  const classes = useStyles()

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const selectedVariable = useMemo(
    () => mediaProductVariables.find(variable => variable.id === selectedVariableId) || {},
    [mediaProductVariables, selectedVariableId]
  )
  const isSelectedVariableRequired = selectedVariable?.required
  const canSelectMultipleOptions = selectedVariable?.[CAN_SELECT_MULTIPLE]
  const selectedVariableName = selectedVariable?.name

  const formatVariables = useCallback(mediaProductVariableOptions => {
    return formatOptionsList({
      list: mediaProductVariableOptions,
      labelName: 'value',
      valueName: 'id'
    })
  }, [])

  const { setFieldValue, values } = formik

  const singleVariableError = getIn(stepErrors, `variables[${index}].${SINGLE_VARIABLE_OPTION}`)
  const singleVariableOption = getIn(values, `${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`)
  const variableIsRequired = getIn(values, `${productItemPath}.variables[${index}].${REQUIRED}`)

  const clearSingleVariableOptionHandler = useCallback(() => {
    setFieldValue(`${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`, '')
  }, [setFieldValue, productItemPath, index])

  const loadOptions = useMemo(
    () =>
      getLoadOptionsHandler({
        getOptionsService: getMediaProductVariableOptionsService,
        params: {
          media_product_variable: selectedVariableId
        },
        limit: 100,
        formatOptions: formatVariables
      }),
    [selectedVariableId, formatVariables]
  )

  return (
    <FieldRow
      description={
        <div className={classes.description}>
          {selectedVariableName}
          {isSelectedVariableRequired && <span>*</span>}
        </div>
      }
    >
      {canSelectMultipleOptions ? (
        <MultiSelectBox
          setFieldValue={setFieldValue}
          name={`${productItemPath}.variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`}
          value={getIn(values, `${productItemPath}.variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`)}
          error={getIn(stepErrors, `variables[${index}].${MULTIPLE_VARIABLE_OPTIONS}`)}
          touched={showError}
          loadOptions={loadOptions}
          placeholder="Select options"
          shouldFetchDefaultOptions={true}
        />
      ) : (
        <>
          <Field
            name={`${productItemPath}.variables[${index}].${SINGLE_VARIABLE_OPTION}`}
            formik={formik}
            placeholder="Select option"
            options={initialOptions}
            loadOptions={loadOptions}
            shouldFetchDefaultOptions={true}
            // show clear icon only if the option is selected and the variable is not required
            showClearInputIcon={!!singleVariableOption && !variableIsRequired}
            onClearInput={clearSingleVariableOptionHandler}
          />
          {/*Error of this field is not managed via formik, so me need to represent the error manually*/}
          {showError && singleVariableError && <ErrorMessage error={singleVariableError} />}
        </>
      )}
    </FieldRow>
  )
}

VariableOptionsSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  productItemPath: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedVariableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepErrors: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired
}

export default VariableOptionsSelector

import React from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../components/Form/Field'

import { EMAIL, FIRST_NAME, LAST_NAME } from '../../../fields'

import useStyles from '../styles'

const ExternalRecipientFields = ({ formik, itemPath }) => {
  const classes = useStyles()

  return (
    <>
      <Field formik={formik} name={`${itemPath}.${FIRST_NAME}`} placeholder="First Name" />
      <hr className={classes.horizontalRow} />
      <Field formik={formik} name={`${itemPath}.${LAST_NAME}`} placeholder="Last Name" />
      <hr className={classes.horizontalRow} />
      <Field formik={formik} name={`${itemPath}.${EMAIL}`} placeholder="Email" />
    </>
  )
}

ExternalRecipientFields.propTypes = {
  formik: PropTypes.object.isRequired
}

export default ExternalRecipientFields

import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { DateRange as ExternalDateRange } from 'react-date-range'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isBefore, isSameDay } from 'date-fns'

import Dropdown from '../../../../../../../../../../../components/Dropdown'
import ActionText from '../../../../../../../../../../../components/ActionText'

import { SelectPeriodsContext } from '../../../../../SelectPeriodsContext'
import useDateRange from './hooks/hooks'

import { fetchedInventoryDateRangesSelector } from '../../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { getIsMatchDateRange } from '../../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/PeriodsDatePicker/helpers'
import { initialDatePeriod } from '../../../../../../../../../../../constants/dates'

import useCommonStyles from '../../DatePickerPeriodSelector/styles'
import useStyles from './styles'

const inputRanges = []

// this component is a duplication of DateRange component with some customizations,
// to avoid make original component more complex, as it often reusable
function PeriodsDateRange({
  earliestPeriod,
  periodSelectorClassName,
  allowToFetchPreviousPeriods,
  onDateRangeChange,
  minDate,
  maxDate,
  additionalAvailableDays,
  onStartDateSelect,
  disabledDates,
  onClose,
  CustomDayComponent
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  // ref for tracking start date selection status
  const isStartDateSelected = useRef(false)
  const [shownMonth, setShownMonth] = useState(new Date())

  const fetchedInventoryDateRanges = useSelector(fetchedInventoryDateRangesSelector)

  const { isOpen, setIsOpen, checkAndFetchInventoryByDateRange } = useContext(SelectPeriodsContext)

  const { weekStartsOn, ranges, rangeColors, rangeSelectedTextColor, setDatePeriod } = useDateRange()

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      if (isOpen && !show) {
        onClose && onClose()
        // reset shown month to current month, as library doesn't call onShownDateChange
        setShownMonth(new Date())
        setDatePeriod(initialDatePeriod)

        // reset isStartDateSelected state when user closes without selection
        isStartDateSelected.current = false
      }
    },
    [setIsOpen, isOpen, onClose, setDatePeriod]
  )

  const onShownDateChangeHandler = useCallback(
    dateRange => {
      checkAndFetchInventoryByDateRange(dateRange, allowToFetchPreviousPeriods)
      setShownMonth(dateRange)
    },
    [checkAndFetchInventoryByDateRange, allowToFetchPreviousPeriods]
  )

  const handleDateRangeChange = useCallback(
    period => {
      setDatePeriod(period.selection)
      if (isStartDateSelected.current) {
        // we want to execute onDateRangeChange callback only when user selects full date range
        // for that reason we add extra state using ref to track double selection
        setDatePeriod(initialDatePeriod)
        onDateRangeChange(period.selection)
      } else {
        onStartDateSelect && onStartDateSelect(period?.selection?.startDate)
        isStartDateSelected.current = true
      }
    },
    [onDateRangeChange, onStartDateSelect, setDatePeriod]
  )

  const handleFocusChange = useCallback(
    focus => {
      if (focus && !focus[1]) {
        // close dropdown and reset isDateSelected when endDate is selected:
        setIsOpen(false)
        // reset shown month to current month, as library doesn't call onShownDateChange
        setShownMonth(new Date())
        onClose && onClose()
        isStartDateSelected.current = false
      }
    },
    [setIsOpen, onClose]
  )

  const handleDetectDisabledDates = useCallback(
    date => {
      // don't allow select dates before first fetched period
      return isBefore(date, earliestPeriod) && !isSameDay(date, earliestPeriod)
    },
    [earliestPeriod]
  )

  const renderDayCell = useMemo(() => {
    // additionalAvailableDays - helps to check the available period which doesn't need to be fetched
    const isDataFetched = getIsMatchDateRange(fetchedInventoryDateRanges, shownMonth, additionalAvailableDays)
    const DaySkeleton = () => <Skeleton width={55} height={36} />

    return isDataFetched ? CustomDayComponent : DaySkeleton
  }, [CustomDayComponent, fetchedInventoryDateRanges, shownMonth, additionalAvailableDays])

  return (
    <Dropdown
      // selects should always show the body at the bottom
      className={classes.dropdownBody}
      allowPlacementFlip={false}
      placement="bottom-end"
      portaled={true}
      strategy={'absolute'} // avoid cut when there is no enough space at the bottom of the page
      isOpen={isOpen}
      offsetSize={5}
      onOpenChange={handleDropdownVisibility}
      triggerElement={
        <ActionText className={commonClasses.actionText} isDark>
          {t('+ Add booking period')}
        </ActionText>
      }
    >
      <div className={classes.dateRangeDropdown}>
        <div className={classes.dateRanges}>
          <ExternalDateRange
            // start week day
            weekStartsOn={weekStartsOn}
            onChange={handleDateRangeChange}
            className={classnames(classes.range, periodSelectorClassName)}
            color={rangeSelectedTextColor}
            showDateDisplay={false}
            ranges={ranges}
            inputRanges={inputRanges}
            rangeColors={rangeColors}
            onRangeFocusChange={handleFocusChange}
            minDate={minDate && new Date(minDate)}
            maxDate={maxDate && new Date(maxDate)}
            // represent current date in calendar
            direction="horizontal"
            // disable drag selection if there are disabled dates, because drag selection still selects disabled dates
            dragSelectionEnabled={!disabledDates}
            disabledDates={disabledDates}
            disabledDay={handleDetectDisabledDates}
            dayContentRenderer={renderDayCell}
            onShownDateChange={onShownDateChangeHandler}
          />
        </div>
      </div>
    </Dropdown>
  )
}

PeriodsDateRange.propTypes = {
  periodSelectorClassName: PropTypes.string,
  onDateRangeChange: PropTypes.func.isRequired,
  additionalAvailableDays: PropTypes.number,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  disabledDates: PropTypes.array,
  isLoading: PropTypes.bool,
  CustomDayComponent: PropTypes.func,
  onStartDateSelect: PropTypes.func,
  onShownDateChange: PropTypes.func,
  onClose: PropTypes.func
}

export default PeriodsDateRange

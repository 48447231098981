import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const ContentRow = ({
  children,
  title,
  description,
  className,
  leftColumnChildren,
  leftSideClassName,
  rightSideClassName
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classnames(classes.item, className)}>
      <div className={classnames(classes.leftSide, leftSideClassName)}>
        {title && <h4 className={classes.title}>{t(title)}</h4>}
        {description && <p className={classes.description}>{t(description)}</p>}
        {leftColumnChildren && <div className={classes.leftColumnChildren}>{leftColumnChildren}</div>}
      </div>
      <div className={classnames(classes.rightSide, rightSideClassName)}>{children}</div>
    </div>
  )
}

ContentRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  leftColumnChildren: PropTypes.element,
  className: PropTypes.string,
  leftSideClassName: PropTypes.string,
  rightSideClassName: PropTypes.string
}

export default ContentRow

import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import CheckboxBlock from '../../../../../../features/components/Form/CheckboxBlock'
import Form from '../../../../../../components/Form'
import Field from '../../../../../../components/Form/Field'
import OfflineCampaignsTypeField from './OfflineCampaignsType'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import BrandsContent from '../../reusalbeFormFields/BrandsContent'
import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'
import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { formatOptionsList } from '../../../../../../features/formatters'
import { transformValuesToBE } from './formatters'

import { CLIENT_ACCOUNT_EDIT } from '../../../../../../constants/forms'
import { getInitialValues, NAME, TAGS } from './fields'

import { clearUpdateSelfAccount, updateSelfAccount } from '../../../../../../modules/actions/selfAccounts'
import {
  updateSelfAccountErrorSelector,
  updateSelfAccountIsLoadingSelector,
  wasSelfAccountUpdatedSelector
} from '../../../../../../modules/selectors/selfAccounts'
import {
  isUserControllerSelector,
  selectedControllerDataSelector,
  selectedSelfAccountDataSelector
} from '../../../../../../modules/selectors/app'
import { tagsIsLoadingSelector, tagsSelector } from '../../../../../../modules/selectors/tags'
import { clearControllerMembers, getControllerMembers } from '../../../../../../modules/actions/controller'
import { controllerMembersSelector } from '../../../../../../modules/selectors/controller'

import { ALLOW_CUSTOM_CLICK_THROUGH_LINK } from '../../../../../../forms/Multiplatform/UploadAdCreative/fields'
import { validationSchema } from './validation'

import { REPRESENTATIVE } from '../../../../../../constants/mediaOrders'
import { CURRENCY, CUSTOMER_ID } from '../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'
import currenciesList from '../../../../../../constants/selectLists/currencies'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'

const ClientAccountEditForm = ({ editData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const drawerFormClasses = useDrawerFormStyles()

  const wasSelfAccountUpdated = useSelector(wasSelfAccountUpdatedSelector)
  const selectedSelfAccountData = useSelector(selectedSelfAccountDataSelector)
  const tagsIsLoading = useSelector(tagsIsLoadingSelector)
  const tags = useSelector(tagsSelector)
  const controllerMembers = useSelector(controllerMembersSelector)
  const isUserController = useSelector(isUserControllerSelector)

  const selectedController = useSelector(selectedControllerDataSelector)
  const selectedControllerName = selectedController?.name || ''

  const tagsTitles = useMemo(() => tags.map(tag => tag.title), [tags])

  const formattedControllerMembers = useMemo(
    () =>
      formatOptionsList({
        list: controllerMembers,
        labelName: 'full_name',
        valueName: 'user'
      }),
    [controllerMembers]
  )

  const { controller: controllerId } = selectedSelfAccountData

  const { selectedEditItemId } = useManageFormsDrawer({
    formName: CLIENT_ACCOUNT_EDIT
  })

  const clearEditSelfAccountSubmit = useCallback(() => {
    dispatch(clearUpdateSelfAccount())
  }, [dispatch])

  const onSubmit = useCallback(
    values => {
      const transformedValues = transformValuesToBE({ values, isController: isUserController, controllerId })

      // when form is opened by direct url and not from the list then selectedEditItemId is string
      dispatch(updateSelfAccount(Number(selectedEditItemId), transformedValues))
    },
    [dispatch, selectedEditItemId, isUserController, controllerId]
  )

  const initialValues = useMemo(
    () =>
      getInitialValues({
        account: editData,
        isController: isUserController,
        tagsTitles
      }),
    [editData, isUserController, tagsTitles]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })

  const purifiedFormik = usePurifiedFormik(formik)

  const { values, setFieldValue, errors } = purifiedFormik

  const tagOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const handleCreateTag = useCallback(
    newTag => {
      setFieldValue(TAGS, [...values[TAGS], { value: newTag, label: newTag }])
    },
    [values, setFieldValue]
  )

  useEffect(() => {
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  return (
    <Form
      formik={purifiedFormik}
      formName={CLIENT_ACCOUNT_EDIT}
      clearHandler={clearEditSelfAccountSubmit}
      successSubmit={wasSelfAccountUpdated}
      errorSelector={updateSelfAccountErrorSelector}
      isLoadingSelector={updateSelfAccountIsLoadingSelector}
    >
      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Account Settings')}</h3>
        <FieldRow title="Account name">
          <Field placeholder="Account name" formik={purifiedFormik} name={NAME} enableReinitialize />
        </FieldRow>
        <FieldRow
          title={t('representative', { controllerName: selectedControllerName })}
          description="The default account manager for this advertiser."
        >
          <Field
            formik={formik}
            name={REPRESENTATIVE}
            placeholder="Representative"
            options={formattedControllerMembers}
            showClearInputIcon={Boolean(values[REPRESENTATIVE])}
          />
        </FieldRow>
        <FieldRow title="Customer ID" description="An internal ID to identify this customer">
          <Field formik={formik} name={CUSTOMER_ID} placeholder="Customer ID" autoComplete="off" />
        </FieldRow>
        <FieldRow
          title={t('Advertiser Currency')}
          description={t(
            'The currency that this partner will be billed in. This must match their ad account currencies.'
          )}
        >
          <Field formik={formik} name={CURRENCY} options={currenciesList} placeholder="Currency" autoComplete="off" />
        </FieldRow>
      </section>
      <section className={drawerFormClasses.section}>
        <h3 className={drawerFormClasses.sectionTitle}>{t('Brand name(s)')}</h3>
        <BrandsContent formik={formik} />
      </section>
      {isUserController && (
        <>
          <section className={drawerFormClasses.section}>
            <h3 className={drawerFormClasses.sectionTitle}>{t('Tags')}</h3>
            <MultiSelectBox
              placeholder={t('Add tags')}
              name={TAGS}
              onCreateOption={handleCreateTag}
              options={tagOptions}
              value={getIn(values, TAGS)}
              setFieldValue={setFieldValue}
              error={errors[TAGS]}
              isLoading={tagsIsLoading}
            />
          </section>
          <section className={drawerFormClasses.section}>
            <h3 className={drawerFormClasses.sectionTitle}>{t('Custom click through link')}</h3>
            <CheckboxBlock
              id="custom_link_checkbox"
              title="Allow custom click through links"
              checked={!!values[ALLOW_CUSTOM_CLICK_THROUGH_LINK]}
              onCheck={() => setFieldValue(ALLOW_CUSTOM_CLICK_THROUGH_LINK, !values[ALLOW_CUSTOM_CLICK_THROUGH_LINK])}
            />
          </section>
          <section className={drawerFormClasses.section}>
            <h3 className={drawerFormClasses.sectionTitle}>{t('Offline Campaigns Type')}</h3>
            <OfflineCampaignsTypeField formik={formik} />
          </section>
        </>
      )}
    </Form>
  )
}

export default ClientAccountEditForm

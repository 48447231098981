import { createSelector } from 'reselect'

import { formatOptionsList } from '../../features/formatters'

import { AGENCY_TYPE, CONTROLLER_TYPE, REGULAR_TYPE } from '../../constants/permissions'
import {
  DV_360_PLATFORM,
  FACEBOOK_PLATFORM,
  GOOGLE_PLATFORM,
  TIKTOK_PLATFORM
} from '../../constants/selectLists/platformList'
import store from '../../store'

// PLOP_APPEND_PATTERN_ANCHOR

export const isClientViewSelector = createSelector(
  ({ app }) => app.isClientView,
  isClientView => isClientView
)

// Submit server logout
export const submitServerLogoutSelector = createSelector(
  ({ app }) => app.submitServerLogout.results,
  results => results
)

export const submitServerLogoutIsLoadingSelector = createSelector(
  ({ app }) => app.submitServerLogout.isLoading,
  isLoading => isLoading
)

export const submitServerLogoutErrorSelector = createSelector(
  ({ app }) => app.submitServerLogout.error,
  error => error
)

export const submitServerLogoutWasLoadedSelector = createSelector(
  ({ app }) => app.submitServerLogout.wasLoaded,
  wasLoaded => wasLoaded
)

// GET current user endpoint START
export const getCurrentUserSelector = createSelector(
  ({ app }) => app.userProfile.data,
  data => data
)

export const getCurrentUserIsLoadingSelector = createSelector(
  ({ app }) => app.userProfile.isLoading,
  isLoading => isLoading
)

export const getCurrentUserWasLoadedSelector = createSelector(
  ({ app }) => app.userProfile.wasLoaded,
  wasLoaded => wasLoaded
)

export const getCurrentUserErrorSelector = createSelector(
  ({ app }) => app.userProfile.error,
  error => error
)

// GET current user endpoint END

// Get user invites
export const userInvitesSelector = createSelector(
  ({ app }) => app.userInvites.data,
  data => data
)

export const getUserInvitesIsLoadingSelector = createSelector(
  ({ app }) => app.userInvites.isLoading,
  isLoading => isLoading
)

export const getUserInvitesErrorSelector = createSelector(
  ({ app }) => app.userInvites.error,
  error => error
)

export const getUserInvitesWasLoadedSelector = createSelector(
  ({ app }) => app.userInvites.wasLoaded,
  wasLoaded => wasLoaded
)

// USER data START
export const currentUserIdSelector = createSelector(
  ({ app }) => app.userProfile.data?.id,
  id => id
)
export const currentUserControllersSelector = createSelector(
  ({ app }) => app.userProfile.data.controllers,
  controllers => {
    return controllers ? [...controllers]?.sort((a, b) => a.name.localeCompare(b.name, { sensitivity: 'case' })) : []
  }
)

export const currentUserSelfAccountsSelector = createSelector(
  ({ app }) => app.userProfile.data?.accounts,
  accounts => accounts
)

export const currentUserSelfAccountsListSelector = createSelector(currentUserSelfAccountsSelector, accounts =>
  formatOptionsList({
    list: accounts,
    valueName: 'id',
    labelName: 'name'
  })
)

export const userProfileAgenciesSelector = createSelector(
  ({ app }) => app.userProfile.data.agencies,
  agencies => agencies
)

// USER data END

export const updateCurrentUserLoadingSelector = createSelector(
  ({ app }) => app.updateUserProfile.isLoading,
  isLoading => isLoading
)

export const updateCurrentUserErrorSelector = createSelector(
  ({ app }) => app.updateUserProfile.error,
  error => error
)

export const updateCurrentUserWasUpdatedSelector = createSelector(
  ({ app }) => app.updateUserProfile.wasUpdated,
  wasUpdated => wasUpdated
)

// Verify current user
export const verifyCurrentUserIsLoadingSelector = createSelector(
  ({ app }) => app.verifyCurrentUser.isLoading,
  isLoading => isLoading
)

export const verifyCurrentUserErrorSelector = createSelector(
  ({ app }) => app.verifyCurrentUser.error,
  error => error
)

export const verifyCurrentUserWasVerifiedSelector = createSelector(
  ({ app }) => app.verifyCurrentUser.wasUpdated,
  wasUpdated => wasUpdated
)

export const showMFASetupPageSelector = createSelector(
  ({ app }) => app.showMFASetupPage,
  showMFASetupPage => showMFASetupPage
)

// Set mfa type
export const setMFATypeIsLoadingSelector = createSelector(
  ({ app }) => app.setMFAType.isLoading,
  isLoading => isLoading
)

export const setMFATypeErrorSelector = createSelector(
  ({ app }) => app.setMFAType.error,
  error => error
)

export const setMFATypeWasCreatedSelector = createSelector(
  ({ app }) => app.setMFAType.wasCreated,
  wasCreated => wasCreated
)

export const selectedSelfAccountSelector = createSelector(
  ({ app }) => app.selectedSelfAccount,
  selectedSelfAccount => selectedSelfAccount
)

export const selectedSelfAccountDataSelector = createSelector(
  currentUserSelfAccountsListSelector,
  selectedSelfAccountSelector,
  (selfAccounts, selectedSelfAccountId) => {
    return selfAccounts.find(({ id }) => id === selectedSelfAccountId) || {}
  }
)

export const contextSelfAccountSelector = createSelector(
  ({ app }) => app.contextSelfAccount,
  contextSelfAccount => contextSelfAccount
)

export const selectedControllerIdSelector = createSelector(selectedSelfAccountDataSelector, account => {
  return account && account.controller
})

export const selectedAgencyIdSelector = createSelector(selectedSelfAccountDataSelector, account => {
  return account && account.agency
})

export const selectedSelfAccountPermissionsSelector = createSelector(
  selectedSelfAccountDataSelector,
  selectedSelfAccount => selectedSelfAccount?.permissions
)

export const selectedControllerDataSelector = createSelector(
  [currentUserControllersSelector, selectedControllerIdSelector],
  (controllers, selectedControllerId) => {
    return (
      controllers &&
      controllers.length &&
      selectedControllerId &&
      controllers.find(controller => controller.id === selectedControllerId)
    )
  }
)

export const isControllerBuyerApprovalSelector = createSelector(
  selectedControllerDataSelector,
  controller => controller?.buyer_approval
)

export const selectedControllerCurrencySelector = createSelector(
  selectedControllerDataSelector,
  selectedControllerData => selectedControllerData?.currency
)

export const selectedControllerCurrencySymbolSelector = createSelector(
  selectedControllerDataSelector,
  selectedControllerData => selectedControllerData?.currency_symbol
)

export const selectedControllerRelatedSelfAccountsSelector = createSelector(
  [currentUserSelfAccountsListSelector, selectedControllerIdSelector],
  (selfAccounts, selectedControllerId) => {
    // selected controller is based on selected self account
    // so here is the opposite - find other self accounts, which could be related to this controller
    return selectedControllerId
      ? selfAccounts?.filter(selfAccount => selfAccount.controller === selectedControllerId)
      : []
  }
)

export const selectedControllerRelatedSelfAccountsListSelector = createSelector(
  selectedControllerRelatedSelfAccountsSelector,
  controllerAccountsList =>
    formatOptionsList({
      list: controllerAccountsList,
      valueName: 'id',
      labelName: 'name'
    }).sort((a, b) => a.label && b.label && a.label.localeCompare(b.label, { sensitivity: 'case' }))
)

export const selectedControllerColorSelector = createSelector(
  selectedControllerDataSelector,
  controller => controller?.color
)

export const selectedControllerPermissionsSelector = createSelector(
  selectedControllerDataSelector,
  controller => controller?.permissions
)

export const selectedAgencyDataSelector = createSelector(
  [userProfileAgenciesSelector, selectedAgencyIdSelector],
  (agencies, selectedAgencyId) => {
    return agencies?.length && selectedAgencyId && agencies.find(agency => agency.id === selectedAgencyId)
  }
)

export const selectedAgencyPermissionsSelector = createSelector(
  selectedAgencyDataSelector,
  agency => agency?.permissions
)

export const userSelfAccountTypeSelector = createSelector(
  [selectedControllerDataSelector, selectedAgencyDataSelector],
  (controllerData, agencyData) => {
    // the hierarchy is controller > agency > without
    // user determination to the controller or agency account is based on fact that he has some role in account
    if (controllerData && !!controllerData.role) {
      return CONTROLLER_TYPE
    } else if (agencyData && !!agencyData.role) {
      return AGENCY_TYPE
    } else {
      return REGULAR_TYPE
    }
  }
)

export const isUserControllerSelector = createSelector(
  userSelfAccountTypeSelector,
  userSelfAccountType => userSelfAccountType === CONTROLLER_TYPE
)

export const isUserAgencySelector = createSelector(
  userSelfAccountTypeSelector,
  userSelfAccountType => userSelfAccountType === AGENCY_TYPE
)

export const userSelfAccountRoleSelector = createSelector(
  [selectedSelfAccountDataSelector, selectedControllerDataSelector, selectedAgencyDataSelector],
  (selectedSelfAccount, controllerData, agencyData) => {
    // the hierarchy is controller > agency > without
    // user role determination is based on fact that he has some role in account type, if that is regular account then role is taken from self account
    if (controllerData && !!controllerData.role) {
      return controllerData.role
    } else if (agencyData && !!agencyData.role) {
      return agencyData.role
    } else {
      return selectedSelfAccount.role ? selectedSelfAccount.role : null
    }
  }
)

export const selectedPlatformSelector = createSelector(
  ({ app }) => app.selectedPlatform,
  selectedPlatform => selectedPlatform
)

export const selectedAdAccountsSelector = createSelector(selectedSelfAccountDataSelector, selectedSelfAccount => {
  const formatAdAccountData = adAccountData => ({
    ...adAccountData,
    // it is used selectedAdAccount.id across all app
    // instead adAccount from BE have set id in external_id field, so id should be reset to external_id here:
    id: adAccountData.external_id
  })

  const {
    fb_ad_account: facebookAdAccount,
    ga_ad_account: googleAdAccount,
    dv360_ad_account: dv360AdAccount,
    tiktok_ad_account: tikTokAdAccount
  } = selectedSelfAccount

  // adAccounts data is placed on selfAccount level
  return {
    [FACEBOOK_PLATFORM]: facebookAdAccount ? formatAdAccountData(facebookAdAccount) : {},
    [GOOGLE_PLATFORM]: googleAdAccount ? formatAdAccountData(googleAdAccount) : {},
    [DV_360_PLATFORM]: dv360AdAccount ? formatAdAccountData(dv360AdAccount) : {},
    [TIKTOK_PLATFORM]: tikTokAdAccount ? formatAdAccountData(tikTokAdAccount) : {}
  }
})

export const selectedAdAccountDataSelector = createSelector(
  [selectedPlatformSelector, selectedAdAccountsSelector],
  (selectedPlatform, selectedAdAccounts) => {
    // selectedAdAccounts is object of platform related selfAccounts
    return selectedAdAccounts[selectedPlatform]
  }
)

export const selectedAdAccountIdSelector = createSelector(
  selectedAdAccountDataSelector,
  selectedAdAccount => selectedAdAccount?.id
)

export const selectedFacebookAdAccountIdSelector = createSelector(
  selectedAdAccountsSelector,
  selectedAdAccounts => selectedAdAccounts[FACEBOOK_PLATFORM]?.id
)

export const selectedGoogleAdAccountIdSelector = createSelector(
  selectedAdAccountsSelector,
  selectedAdAccounts => selectedAdAccounts[GOOGLE_PLATFORM]?.id
)

export const selectedDv360AdAccountIdSelector = createSelector(
  selectedAdAccountsSelector,
  selectedAdAccounts => selectedAdAccounts[DV_360_PLATFORM]?.id
)

export const selectedTikTokAdAccountIdSelector = createSelector(
  selectedAdAccountsSelector,
  selectedAdAccounts => selectedAdAccounts[TIKTOK_PLATFORM]?.id
)

export const selectedAdAccountIdByPlatformSelector = platform =>
  createSelector(selectedAdAccountsSelector, selectedAdAccounts => selectedAdAccounts[platform]?.id)

export const searchTermSelector = createSelector(
  ({ app }) => app.searchTerm,
  searchTerm => searchTerm
)

export const createAudienceSuggestionTypeSelector = createSelector(
  ({ app }) => app.createAudienceSuggestionType,
  createAudienceSuggestionType => createAudienceSuggestionType
)

// GET banners START
export const bannersSelector = createSelector(
  ({ app }) => app.bannersData.results,
  results => results
)

export const bannersWasLoadedSelector = createSelector(
  ({ app }) => app.bannersData.wasLoaded,
  wasLoaded => wasLoaded
)
// GET banners END

// Get controller landing page setup
export const controllerLandingPageSetupSelector = createSelector(
  ({ app }) => app.getControllerLandingPageSetup.results,
  results => results
)

export const selectedControllerLandingPageSetupSelector = createSelector(
  [selectedControllerIdSelector, controllerLandingPageSetupSelector],
  (selectedControllerIdS, controllersLandingPageSetup) =>
    controllersLandingPageSetup?.find(item => item.controller === selectedControllerIdS)
)

export const getControllerLandingPageSetupIsLoadingSelector = createSelector(
  ({ app }) => app.getControllerLandingPageSetup.isLoading,
  isLoading => isLoading
)

export const getControllerLandingPageSetupErrorSelector = createSelector(
  ({ app }) => app.getControllerLandingPageSetup.error,
  error => error
)

export const controllerLandingPageSetupWasLoadedSelector = createSelector(
  ({ app }) => app.getControllerLandingPageSetup.wasLoaded,
  wasLoaded => wasLoaded
)

export const asyncSessionDataSelector = createSelector(
  ({ app }) => app.asyncSession.data,
  data => data
)

export const pdfGenerationSelector = createSelector(
  ({ app }) => app.pdfGeneration,
  pdfGeneration => pdfGeneration
)

export const triggerTableCopySelector = createSelector(
  ({ app }) => app.triggerTableCopy,
  tableId => tableId
)

export const selectedLanguageSelector = createSelector(
  ({ app }) => app.selectedLanguage,
  selectedLanguage => selectedLanguage
)

export const getSelectedAdAccountTimezone = () => {
  const currentState = store.getState()
  const adAccount = selectedAdAccountDataSelector(currentState)

  const { timezone_name: timezone } = adAccount

  return timezone
}

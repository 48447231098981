export const getMediaOrderOrMediaOrderGroupSelfAccountId = (accountId, sequentialList) => {
  // if current items is a group - account_id is missing on this item, so we need to get it from the first item in the group
  return sequentialList?.length ? sequentialList[0].account_id : accountId
}

export const filesColumnSort = (rowA, rowB) => {
  const additionalRecipientsA = rowA.original.additional_recipients
  const additionalRecipientsB = rowB.original.additional_recipients
  const sequentialListRecipientsA = rowA.original.sequential_list?.[0].additional_recipients
  const sequentialListRecipientsB = rowB.original.sequential_list?.[0].additional_recipients
  const rowAUploadedFilesLength = rowA.original.uploaded_files?.length
  const rowBUploadedFilesLength = rowB.original.uploaded_files?.length

  // sort by quantity of uploaded files and when quantity is 0, then by additional recipients
  if (rowAUploadedFilesLength !== rowBUploadedFilesLength) {
    return rowAUploadedFilesLength - rowBUploadedFilesLength
  } else if (additionalRecipientsA !== additionalRecipientsB) {
    return additionalRecipientsA - additionalRecipientsB
  } else if (sequentialListRecipientsA !== sequentialListRecipientsB) {
    return sequentialListRecipientsA - sequentialListRecipientsB
  } else {
    return 0
  }
}

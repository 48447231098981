import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../../../../components/Form/Checkbox'

export const AMENDED_STATUS_FILTER = 'has_amendment'

const AmendedStatusFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const { [AMENDED_STATUS_FILTER]: hasAmendStatus } = filtersState

  const handleStatusChange = useCallback(() => {
    const updatedFiltersState = {
      ...filtersState,
      [AMENDED_STATUS_FILTER]: !hasAmendStatus
    }

    setFiltersState(updatedFiltersState)
  }, [hasAmendStatus, filtersState, setFiltersState])

  return (
    <Checkbox
      id={AMENDED_STATUS_FILTER}
      title={'Has been amended'}
      checked={hasAmendStatus}
      onCheck={handleStatusChange}
    />
  )
}

AmendedStatusFilter.propTypes = {
  Context: PropTypes.object.isRequired
}

export default AmendedStatusFilter

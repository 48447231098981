import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo } from 'react'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import MediaProductGroupEditForm from './MediaProductGroupEditForm'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../../hooks/formHooks/useManageEditFormData'

import { getProductTags } from '../../../../../../modules/actions/tags'
import { clearProduct, getProduct } from '../../../../../../modules/actions/mediaOrdersProducts'
import { productSelector, productWasLoadedSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { productTagsWasLoadedSelector } from '../../../../../../modules/selectors/tags'

import { MEDIA_PRODUCT_GROUP_EDIT } from '../../../../../../constants/forms'

const MediaProductGroupEdit = () => {
  const dispatch = useDispatch()

  const product = useSelector(productSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const productWasLoaded = useSelector(productWasLoadedSelector)
  const productTagsWasLoaded = useSelector(productTagsWasLoadedSelector)

  const getProductHandler = useCallback(
    selectedEditItemId => {
      dispatch(getProduct({ id: selectedEditItemId }))
    },
    [dispatch]
  )

  const clearProductHandler = useCallback(() => {
    dispatch(clearProduct())
  }, [dispatch])

  const { isFormOpen } = useManageFormsDrawer({ formName: MEDIA_PRODUCT_GROUP_EDIT })

  useManageEditFormData({
    formName: MEDIA_PRODUCT_GROUP_EDIT,
    getDataHandler: getProductHandler,
    clearDataHandler: clearProductHandler,
    loadedDataId: product.id
  })

  useEffect(() => {
    if (isFormOpen && !productTagsWasLoaded) {
      // clear is making on Media products page unmount
      dispatch(getProductTags({ controller: controllerId }))
    }
  }, [dispatch, controllerId, productTagsWasLoaded, isFormOpen])

  const isFormLoading = useMemo(
    () => !productWasLoaded || !productTagsWasLoaded,
    [productTagsWasLoaded, productWasLoaded]
  )
  return (
    <FormDrawerWrapper
      formName={MEDIA_PRODUCT_GROUP_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product grouping"
      showOpenButton={false}
    >
      <MediaProductGroupEditForm editProductData={product} />
    </FormDrawerWrapper>
  )
}

export default MediaProductGroupEdit

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import SignContractModal from '../../ContractMediaOrderGenerate/SignContractModal'
import ResponsiveContractPdfViewer from '../../../../../features/components/Contract/ResponsiveContractPdfViewer'

import { useAmendmentContractSign } from './useContractSign'

function ManageAmendmentContract({ contract = {}, isContractSigned, tokenParams, signType }) {
  const [showSignContractModal, setShowSignContractModal] = useState(false)

  const { id: contractId } = contract
  const pdfFile = contract?.file

  const signControllerFormProps = useAmendmentContractSign({ contractId, tokenParams, signType })

  const openSignContractModal = useCallback(() => {
    if (!isContractSigned) {
      // show sign contract modal only if last page was clicked and contract hadn't been signed yet
      setShowSignContractModal(true)
    }
  }, [isContractSigned])

  return (
    <>
      <ResponsiveContractPdfViewer
        pdfFile={pdfFile}
        onLastPageClick={!isContractSigned ? openSignContractModal : null}
        showPagesCount={true}
      />
      {/*don't render conditionally with checking isContractSigned,
      because the clear handler will not be called on success sign*/}
      <SignContractModal
        showSignContractModal={showSignContractModal}
        onModalClose={() => setShowSignContractModal(false)}
        formProps={signControllerFormProps}
      />
    </>
  )
}

ManageAmendmentContract.propTypes = {
  contract: PropTypes.object,
  isContractSigned: PropTypes.bool.isRequired,
  tokenParams: PropTypes.object,
  signType: PropTypes.oneOf(['controller', 'user']).isRequired
}

export default ManageAmendmentContract

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import CalendarRow from '../CalendarRow'

import { getTimeInPx } from '../../../../features/components/Calendar/CalendarTable'

const CalendarRows = ({
  renderEvents = true,
  products,
  selectedViewType,
  colsCount,
  tableInnerRowWidth,
  startDate,
  endDate,
  isSkeleton
}) => {
  const timeToPx = useMemo(() => {
    return getTimeInPx(selectedViewType, tableInnerRowWidth, startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInnerRowWidth, startDate, endDate])

  return (
    <tbody id="rows-container">
      {products.map(product => {
        return (
          <CalendarRow
            key={product.group.id}
            product={product}
            startDate={startDate}
            endDate={endDate}
            colsCount={colsCount}
            timeToPx={timeToPx}
            isSkeleton={isSkeleton}
            renderEvents={renderEvents}
            tableInnerRowWidth={tableInnerRowWidth}
          />
        )
      })}
    </tbody>
  )
}

CalendarRows.propTypes = {
  renderEvents: PropTypes.bool,
  products: PropTypes.array.isRequired,
  selectedViewType: PropTypes.string.isRequired,
  colsCount: PropTypes.number.isRequired,
  tableInnerRowWidth: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default CalendarRows

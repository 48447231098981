import * as Yup from 'yup'

import { checkIsPdfOnly } from './helpers'
import { calc } from '../../../../../helpers/numbers'
import { convertBytesToMegabytes } from '../../../../../components/Form/DropFileUploader/helpers'

import { FILE_URL, FILE_SELECTED_URL, FILE_SELECT_EXISTING } from './fields'
import { FILE_UPLOAD, FILE_UPLOAD_OPTION } from './fields'
import {
  MEDIA_SIZE,
  FILE_TYPE,
  MEDIA_HEIGHT,
  MEDIA_RATIO,
  MEDIA_WIDTH
} from '../../../../ReusableFormFields/AdFileUpload/fields'
import { MEDIA_PHYSICAL_HEIGHT, MEDIA_PHYSICAL_WIDTH } from '../../../../Facebook/AdForms/fields'

export const validationSchema = Yup.object({
  [FILE_URL]: Yup.string().when(FILE_UPLOAD_OPTION, {
    is: FILE_UPLOAD,
    then: () => Yup.string().required('Please select file')
  }),
  [FILE_SELECTED_URL]: Yup.string().when(FILE_UPLOAD_OPTION, {
    is: FILE_SELECT_EXISTING,
    then: () => Yup.string().required('Please select file')
  })
})

const getDimensionValidation = ({ width, height, isPdfOnly, dimensionSymbol = 'px' }) => {
  // width and height are arrays so we support multiple dimensions
  const dimensions = width.map((w, i) => ({
    width: w,
    height: height[i]
  }))

  const generateErrorMessage = (uploadedWidth, uploadedHeight) => {
    // error message need include possible dimensions as well as the uploaded dimensions
    const uploadedDimensions = `[${uploadedWidth}${dimensionSymbol} x ${uploadedHeight}${dimensionSymbol}]`
    const validDimensions = dimensions
      .map(({ width, height }) => {
        if (width === '0.00') return `[h:${height}]`
        if (height === '0.00') return `[w:${width}]`
        return `[${width} x ${height}]`
      })
      .join(' or ')

    return `The file dimensions should be ${validDimensions}, but the file you are trying to upload is ${uploadedDimensions}`
  }

  const widthFieldName = isPdfOnly ? MEDIA_PHYSICAL_WIDTH : MEDIA_WIDTH
  const heightFieldName = isPdfOnly ? MEDIA_PHYSICAL_HEIGHT : MEDIA_HEIGHT

  // tolerance is used to allow a small difference in the dimensions
  // if it is pdf only, we need to allow a tolerance
  const tolerance = isPdfOnly ? 0.5 : 0

  return {
    media_dimensions: Yup.number().test('', (val, context) => {
      const widthValue = context.parent[widthFieldName]
      const heightValue = context.parent[heightFieldName]

      // validating that the file matches at least one of the dimensions:
      let isUploadedDimensionsValid = false
      dimensions.forEach(({ width, height }) => {
        const formattedWidth = Number(width)
        const formattedHeight = Number(height)

        // if the width or height is 0.00, we don't need to validate it
        // if the difference is less than the tolerance, it is valid
        const isWidthValid = width === '0.00' || Math.abs(widthValue - formattedWidth) <= tolerance
        const isHeightValid = height === '0.00' || Math.abs(heightValue - formattedHeight) <= tolerance

        if (isWidthValid && isHeightValid) {
          isUploadedDimensionsValid = true
        }
      })

      // if none of the dimensions are valid, return an error
      return (
        isUploadedDimensionsValid ||
        context.createError({
          message: generateErrorMessage(widthValue, heightValue)
        })
      )
    })
  }
}

const getWidthValidation = ({ fieldName = MEDIA_WIDTH, minWidth, maxWidth, dimension = 'px' }) => {
  const validationRow = {
    [fieldName]: Yup.number()
  }
  if (minWidth) {
    const formattedRequirement = Number(minWidth)
    validationRow[fieldName] = validationRow[fieldName].min(Number(formattedRequirement), function ({ value }) {
      return `The minimum supported file width is ${formattedRequirement}${dimension}, but the file you are trying to upload has width of ${value}${dimension}`
    })
  }
  if (maxWidth) {
    const formattedRequirement = Number(maxWidth)
    validationRow[fieldName] = validationRow[fieldName].max(formattedRequirement, function ({ value }) {
      return `The maximum supported file width is ${formattedRequirement}${dimension}, but the file you are trying to upload has width of ${value}${dimension}`
    })
  }
  return validationRow
}

const getHeightValidation = ({ fieldName = MEDIA_HEIGHT, minHeight, maxHeight, dimension = 'px' }) => {
  const validationRow = {
    [fieldName]: Yup.number()
  }
  if (minHeight) {
    const formattedRequirement = Number(minHeight)
    validationRow[fieldName] = validationRow[fieldName].min(formattedRequirement, function ({ value }) {
      return `The minimum supported file height is ${formattedRequirement}${dimension}, but the file you are trying to upload has height of ${value}${dimension}`
    })
  }
  if (maxHeight) {
    const formattedRequirement = Number(maxHeight)
    validationRow[fieldName] = validationRow[fieldName].max(formattedRequirement, function ({ value }) {
      return `The maximum supported file height is ${formattedRequirement}${dimension}, but the file you are trying to upload has height of ${value}${dimension}`
    })
  }
  return validationRow
}

const getRatioValidation = ({ minRatio, maxRatio }) => {
  const validationRow = {
    [MEDIA_RATIO]: Yup.number()
  }
  if (minRatio) {
    validationRow[MEDIA_RATIO] = validationRow[MEDIA_RATIO].min(minRatio, function ({ value }) {
      return `The minimum supported file ratio is ${minRatio}, but the file you are trying to upload has ratio of ${value}`
    })
  }
  if (maxRatio) {
    validationRow[MEDIA_RATIO] = validationRow[MEDIA_RATIO].max(maxRatio, function ({ value }) {
      return `The maximum supported file ratio is ${maxRatio}, but the file you are trying to upload has ratio of ${value}`
    })
  }
  return validationRow
}

const getSizeValidation = ({ minSize, maxSize }) => {
  const maxSizeInBits = Number(maxSize) * 1024 * 1024
  const minSizeInBits = Number(minSize) * 1024 * 1024
  const validationRow = {
    [MEDIA_SIZE]: Yup.number()
  }
  if (minSize) {
    validationRow[MEDIA_SIZE] = validationRow[MEDIA_SIZE].min(minSizeInBits, function ({ value }) {
      const valueInMb = convertBytesToMegabytes(value)
      const roundedMb = calc(valueInMb).toDP(2).toString()

      return `The minimum supported file size is ${Number(minSize)}Mb, but the file you are trying to upload has size of ${roundedMb}Mb`
    })
  }
  if (maxSize) {
    validationRow[MEDIA_SIZE] = validationRow[MEDIA_SIZE].max(maxSizeInBits, function ({ value }) {
      const valueInMb = convertBytesToMegabytes(value)
      const roundedMb = calc(valueInMb).toDP(2).toString()

      return `The maximum supported file size is ${Number(maxSize)}Mb, but the file you are trying to upload has size of ${roundedMb}Mb`
    })
  }
  return validationRow
}

const getSupportedTypes = supportedFileTypes => {
  const fileFormatPairs = {
    jpg: 'jpeg',
    jpeg: 'jpg'
    // add other pairs here
  }

  if (supportedFileTypes?.length) {
    Object.entries(fileFormatPairs).forEach(([key, value]) => {
      // add the value to the supportedFileTypes if it is not already there
      if (supportedFileTypes.includes(key) && !supportedFileTypes.includes(value)) {
        supportedFileTypes.push(value)
      }
    })

    return {
      [FILE_TYPE]: Yup.string().oneOf(supportedFileTypes, function ({ value }) {
        return `The file should be: ${supportedFileTypes?.length > 1 ? supportedFileTypes.join(',') : supportedFileTypes[0]}, but the file you are trying to upload ${value}`
      })
    }
  }
  return {}
}

export const getFileValidationSchema = fileRequirements => {
  const {
    height,
    max_file_size: maxSize,
    max_height: maxHeight,
    max_width: maxWidth,
    min_file_size: minSize,
    min_height: minHeight,
    min_width: minWidth,
    ratio_from: ratioFrom,
    ratio_to: ratioTo,
    supported_file_types: supportedFileTypes,
    width
  } = fileRequirements

  const isPdfOnly = checkIsPdfOnly(supportedFileTypes)
  const hasDimensions = !!width?.length || height?.length
  const dimensionSymbol = isPdfOnly ? 'mm' : 'px'

  return Yup.object().shape({
    ...((maxWidth || minWidth || width) &&
      getWidthValidation({
        fieldName: isPdfOnly ? MEDIA_PHYSICAL_WIDTH : MEDIA_WIDTH,
        minWidth,
        maxWidth,
        width,
        dimension: dimensionSymbol
      })),
    ...((minHeight || maxHeight || height) &&
      getHeightValidation({
        fieldName: isPdfOnly ? MEDIA_PHYSICAL_HEIGHT : MEDIA_HEIGHT,
        minHeight,
        maxHeight,
        height,
        dimension: dimensionSymbol
      })),
    ...(hasDimensions && getDimensionValidation({ width, height, isPdfOnly, dimensionSymbol })),
    // create new supportedFileTypes to avoid data mutation
    ...(supportedFileTypes && getSupportedTypes([...supportedFileTypes])),
    ...((ratioFrom || ratioTo) && getRatioValidation({ minRatio: ratioFrom, maxRatio: ratioTo })),
    ...((minSize || maxSize) && getSizeValidation({ minSize, maxSize }))
  })
}

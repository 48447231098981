export const CONTRACT_SIGNED_STATUS = 'signed'
export const CONTRACT_PENDING_APPROVAL_STATUS = 'pending_approval'
export const CONTRACT_APPROVED_STATUS = 'approved'
export const CONTRACT_DISAPPROVED_STATUS = 'disapproved'
export const CONTRACT_MEDIA_ORDER_CREATED_STATUS = 'media_order_created'
export const CONTRACT_DELETED_STATUS = 'deleted'
export const CONTRACT_INVALID_STATUS = 'invalid'
export const CONTRACT_NEW_STATUS = 'new'

export const QUOTATION_STATUS = {
  ACTIVE: 'active',
  EXPIRED: 'expired'
}

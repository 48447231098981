import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import ProductLocationFormContent from '../../ProductLocationFormContent'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'

import {
  clearUpdateMediaProductLocation,
  updateMediaProductLocation
} from '../../../../../../modules/actions/mediaOrdersProductLocations'
import {
  updateMediaProductLocationErrorSelector,
  updateMediaProductLocationIsLoadingSelector,
  updateMediaProductLocationWasUpdatedSelector
} from '../../../../../../modules/selectors/mediaOrdersProductLocations'
import { controllerSelector } from '../../../../../../modules/selectors/controller'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_PRODUCT_LOCATION_EDIT } from '../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { STORE_NAME, STORES } from '../../fields'
import { getInitialValues } from './fields'

function MediaProductLocationEditForm({ editProductLocationData }) {
  const dispatch = useDispatch()

  const { id: productLocationId } = editProductLocationData

  const productLocationWasUpdated = useSelector(updateMediaProductLocationWasUpdatedSelector)
  const { timezone: controllerTimezone } = useSelector(controllerSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const initialValues = useMemo(() => getInitialValues({ editProductLocationData }), [editProductLocationData])

  const initialStores = editProductLocationData[STORES]

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        initialStores,
        values,
        controllerTimezone,
        controllerId
      })

      dispatch(updateMediaProductLocation(transformedData, productLocationId))
    },
    [dispatch, productLocationId, controllerTimezone, controllerId, initialStores]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const initialOpenSubLocations = useMemo(
    () =>
      // if there are stores in the values, open the spoiler
      initialValues?.[STORES]?.filter(item => !!item[STORE_NAME])?.length,
    [initialValues]
  )

  const purifiedFormik = usePurifiedFormik(formik)

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateMediaProductLocation())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={MEDIA_PRODUCT_LOCATION_EDIT}
      successSubmit={productLocationWasUpdated}
      errorSelector={updateMediaProductLocationErrorSelector}
      isLoadingSelector={updateMediaProductLocationIsLoadingSelector}
      clearHandler={clearHandler}
    >
      <ProductLocationFormContent formik={formik} initialOpenSubLocations={initialOpenSubLocations} />
    </Form>
  )
}

export default MediaProductLocationEditForm

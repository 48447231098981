import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  applyDiscountsBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px'
  }
})

export default useStyles

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Can from '../../../../../features/components/Can'
import Button from '../../../../../components/Button'
import ProposalSendDrawer from '../../../../../forms/Multiplatform/MediaOrderForms/Proposals/ProposalSendDrawer'

import { openForm } from '../../../../../modules/actions/forms'
import { activeContractSelector } from '../../../../../modules/selectors/contracts'

import { PROPOSAL_SEND } from '../../../../../constants/forms'
import { SEND_QUOTATION_PERMISSION } from '../../../../../constants/permissions'

const ProposalSend = ({
  isDisabled,
  checkContractValidity,
  contractId,
  shouldCheckApprovalRequirements,
  isMatchRequirements
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const activeContractData = useSelector(activeContractSelector)

  const wasSent = activeContractData?.quotation_sent

  const handleOpenSendDrawer = useCallback(() => {
    dispatch(openForm({ formName: PROPOSAL_SEND, id: contractId }))
  }, [contractId, dispatch])

  const handleAbilityToOpenDrawer = useCallback(async () => {
    const isContractValid = await checkContractValidity()

    if (isContractValid) {
      let allowCreateMediaOrder = true

      // if controller quotation_approval_workflow = true, then we need to have approvals logic on proposals builder
      if (shouldCheckApprovalRequirements) {
        allowCreateMediaOrder = isMatchRequirements()
      }

      if (allowCreateMediaOrder) {
        handleOpenSendDrawer()
      }
    }
  }, [checkContractValidity, handleOpenSendDrawer, isMatchRequirements, shouldCheckApprovalRequirements])

  return (
    <Can I="manage" a={SEND_QUOTATION_PERMISSION}>
      <Button onClick={handleAbilityToOpenDrawer} disabled={isDisabled}>
        {t(wasSent ? 'Manage Recipients' : 'Send for Signature')}
      </Button>
      <ProposalSendDrawer contractData={activeContractData} />
    </Can>
  )
}

ProposalSend.propTypes = {
  isDisabled: PropTypes.bool,
  contractId: PropTypes.number,
  isWithoutRequirements: PropTypes.bool
}

export default ProposalSend

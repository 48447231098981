import { updateItem } from './index'
import * as Sentry from '@sentry/react'

const updateItemsBySequentialIds = (items, updatedMedia) => {
  return items.map(item => {
    if (String(item.sequential_ids[0]) === String(updatedMedia.sequential_ids[0])) {
      return updatedMedia
    }
    return item
  })
}

const updateGroupBookedMediaFile = ({ items, oldMedia, action }) => {
  const updatedMedia = {
    ...oldMedia,
    uploaded_files: oldMedia.uploaded_files.map(file => {
      if (action.data.ids.includes(file.id)) {
        // update the approval status of the files that were updated
        return {
          ...file,
          approval_status: action.data.approval_status
        }
      } else {
        return file
      }
    }),
    sequential_list: oldMedia.sequential_list.map(sequentialItem => {
      return {
        ...sequentialItem,
        uploaded_files: sequentialItem.uploaded_files.map(file => {
          if (action.data.ids.includes(file.id)) {
            // update the approval status of the files that were updated
            return {
              ...file,
              approval_status: action.data.approval_status
            }
          } else {
            return file
          }
        })
      }
    })
  }

  // regular updateItem doesn't work here because group doesn't have id
  return updateItemsBySequentialIds(items, updatedMedia)
}

const updateRegularBookedMediaFile = ({ items, oldMedia, action }) => {
  const updatedMedia = {
    ...oldMedia,
    uploaded_files: oldMedia.uploaded_files.map(file => {
      if (action.data.ids.includes(file.id)) {
        // update the approval status of the files that were updated
        return {
          ...file,
          approval_status: action.data.approval_status
        }
      } else {
        return file
      }
    })
  }

  return updateItem(items, updatedMedia, action.data.booked_media)
}

export const updateInstallationReportItemsFiles = (items, action) => {
  const oldMedia = items.find(
    item =>
      // find by id
      item.id === action.data.booked_media ||
      // or by first sequential id
      item.sequential_ids?.includes(action.data.booked_media)
  )
  const isGroup = !!oldMedia.sequential_list

  if (isGroup) {
    // if it's a group - update the file in all the groups
    return updateGroupBookedMediaFile({ items, oldMedia, action })
  } else {
    return updateRegularBookedMediaFile({ items, oldMedia, action })
  }
}

export const updateBookedMediaUploadedFilesComments = (items, action) => {
  const keys = ['comment', 'approval_status', 'buyer_disapproved_reason', 'disapproved_reason']
  return items.map(bookedMedia => ({
    ...bookedMedia,
    uploaded_files: bookedMedia.uploaded_files.map(uploadedFile =>
      action.data.ids.includes(uploadedFile.id)
        ? {
            ...uploadedFile,
            ...keys.reduce((acc, key) => (action.data[key] ? { ...acc, [key]: action.data[key] } : acc), {})
          }
        : uploadedFile
    )
  }))
}

export const updateInstallationReportItemsStatus = (items, action) => {
  let updatedMedia = []

  const parentRowWithSequentialIds = items.find(item => {
    return item.sequential_list?.some(subItem => action.data.ids.includes(subItem.id))
  })
  const isParentRow = parentRowWithSequentialIds?.sequential_ids?.every(id => action.data.ids.includes(id))

  if (action.data.ids.length === 1 && !parentRowWithSequentialIds) {
    Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 1` })
    // if user update a regular single row without sub rows (without sequential_list)

    updatedMedia = items.map(item => {
      if (item.sequential_ids?.includes(action.data.ids[0])) {
        return {
          ...item,
          // update status of the item
          status: action.data.status
        }
      } else {
        return item
      }
    })
  } else if (action.data.ids.length > 1 && isParentRow) {
    Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 2` })

    // if user update a group - update the status in all the groups
    updatedMedia = items.map(item => {
      if (action.data.ids.some(id => item.sequential_ids?.includes(id))) {
        return {
          ...item,
          // update status of the item
          status: action.data.status,
          ...(item.sequential_list && {
            // and update status of all sub items if exist
            sequential_list: item.sequential_list.map(sequentialItem => {
              return {
                ...sequentialItem,
                status: action.data.status
              }
            })
          })
        }
      } else {
        return item
      }
    })
  } else if (parentRowWithSequentialIds) {
    // if user update a sub row of a group - update only the status of the sub row, and move the item out of the group
    Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 3` })

    updatedMedia = items.flatMap(item => {
      if (item.sequential_ids?.includes(action.data.ids[0])) {
        Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 3.1` })
        if (item.sequential_list.length > 2) {
          Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 3.1.1` })
          // if it's a group with more than 2 items
          let updatedSequentialList = item.sequential_list.filter(subitem => subitem.id !== action.data.ids[0])
          let ungroupedSubItem = item.sequential_list.find(subitem => subitem.id === action.data.ids[0])

          const updatedUploadedFiles = item.uploaded_files
            .filter(file => {
              return updatedSequentialList.some(subitem =>
                subitem.uploaded_files.some(subfile => {
                  return file?.file_ids?.find(fileId => fileId === subfile.id)
                })
              )
            })
            .map(multipleAssetUploadedFile => {
              const allUploadedFiles = updatedSequentialList.flatMap(subitem => subitem.uploaded_files)
              const allUploadedFilesWithTheSameFileUrl = allUploadedFiles.filter(uploadedFile => {
                return uploadedFile.file_url === multipleAssetUploadedFile.file_url
              })
              const updatedFileIds = allUploadedFilesWithTheSameFileUrl.map(uploadedFile => uploadedFile.id)

              return {
                ...multipleAssetUploadedFile,
                file_ids: updatedFileIds,
                id: updatedFileIds[0],
                approval_status: allUploadedFilesWithTheSameFileUrl[0].approval_status
              }
            })

          return [
            {
              ...item,
              sequential_list: updatedSequentialList,
              sequential_ids: updatedSequentialList.map(subitem => subitem.id),
              uploaded_files: updatedUploadedFiles
            },
            {
              ...ungroupedSubItem,
              status: action.data.status,
              sequential_ids: [ungroupedSubItem.id]
            }
          ]
        } else {
          Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 3.1.2` })

          // if it's a group with 2 items, transform to a single item
          let remainingSubitem = item.sequential_list.find(subitem => subitem.id !== action.data.ids[0])
          let ungroupedSubItem = item.sequential_list.find(subitem => subitem.id === action.data.ids[0])

          return [
            {
              ...remainingSubitem,
              sequential_ids: [remainingSubitem.id]
            },
            {
              ...ungroupedSubItem,
              status: action.data.status,
              sequential_ids: [ungroupedSubItem.id]
            }
          ]
        }
      } else {
        Sentry.addBreadcrumb({ category: 'Installation report update', message: `Conditional 3.2` })
        return item
      }
    })
  }

  return updatedMedia
}

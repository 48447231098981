import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_PACKAGES/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_MEDIA_PACKAGE = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE')
export const GET_MEDIA_PACKAGE_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE_SUCCESS')
export const GET_MEDIA_PACKAGE_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE_FAILURE')
export const CLEAR_GET_MEDIA_PACKAGE = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PACKAGE')

export const GET_MEDIA_PACKAGE_CATEGORIES = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE_CATEGORIES')
export const GET_MEDIA_PACKAGE_CATEGORIES_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE_CATEGORIES_SUCCESS')
export const GET_MEDIA_PACKAGE_CATEGORIES_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PACKAGE_CATEGORIES_FAILURE')
export const CLEAR_MEDIA_PACKAGE_CATEGORIES = concat(MODULE_NAME, 'CLEAR_MEDIA_PACKAGE_CATEGORIES')

export const CREATE_MEDIA_PACKAGE_CATEGORY = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE_CATEGORY')
export const CREATE_MEDIA_PACKAGE_CATEGORY_SUCCESS = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE_CATEGORY_SUCCESS')
export const CREATE_MEDIA_PACKAGE_CATEGORY_FAILURE = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE_CATEGORY_FAILURE')
export const CLEAR_CREATE_MEDIA_PACKAGE_CATEGORY = concat(MODULE_NAME, 'CLEAR_CREATE_MEDIA_PACKAGE_CATEGORY')

export const UPDATE_MEDIA_PACKAGE_CATEGORY = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_CATEGORY')
export const UPDATE_MEDIA_PACKAGE_CATEGORY_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_CATEGORY_SUCCESS')
export const UPDATE_MEDIA_PACKAGE_CATEGORY_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_CATEGORY_FAILURE')
export const CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORY = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORY')

export const UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER')
export const UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_SUCCESS = concat(
  MODULE_NAME,
  'UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_SUCCESS'
)
export const UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_FAILURE = concat(
  MODULE_NAME,
  'UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_FAILURE'
)
export const CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER = concat(
  MODULE_NAME,
  'CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER'
)

export const GET_GROUPED_MEDIA_PACKAGES = concat(MODULE_NAME, 'GET_GROUPED_MEDIA_PACKAGES')
export const GET_GROUPED_MEDIA_PACKAGES_SUCCESS = concat(MODULE_NAME, 'GET_GROUPED_MEDIA_PACKAGES_SUCCESS')
export const GET_GROUPED_MEDIA_PACKAGES_FAILURE = concat(MODULE_NAME, 'GET_GROUPED_MEDIA_PACKAGES_FAILURE')
export const CLEAR_GROUPED_MEDIA_PACKAGES = concat(MODULE_NAME, 'CLEAR_GROUPED_MEDIA_PACKAGES')

export const GET_MEDIA_PACKAGES = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES')
export const GET_MEDIA_PACKAGES_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES_SUCCESS')
export const GET_MEDIA_PACKAGES_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES_FAILURE')
export const CLEAR_MEDIA_PACKAGES = concat(MODULE_NAME, 'CLEAR_MEDIA_PACKAGES')

export const CREATE_MEDIA_PACKAGE = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE')
export const CREATE_MEDIA_PACKAGE_SUCCESS = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE_SUCCESS')
export const CREATE_MEDIA_PACKAGE_FAILURE = concat(MODULE_NAME, 'CREATE_MEDIA_PACKAGE_FAILURE')
export const CLEAR_CREATE_MEDIA_PACKAGE = concat(MODULE_NAME, 'CLEAR_CREATE_MEDIA_PACKAGE')

export const UPDATE_MEDIA_PACKAGE = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE')
export const UPDATE_MEDIA_PACKAGE_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_SUCCESS')
export const UPDATE_MEDIA_PACKAGES_BULK_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGES_BULK_SUCCESS')
export const UPDATE_MEDIA_PACKAGE_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGE_FAILURE')
export const CLEAR_UPDATE_MEDIA_PACKAGE = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_PACKAGE')

export const DELETE_MEDIA_PACKAGE = concat(MODULE_NAME, 'DELETE_MEDIA_PACKAGE')
export const DELETE_MEDIA_PACKAGE_SUCCESS = concat(MODULE_NAME, 'DELETE_MEDIA_PACKAGE_SUCCESS')
export const DELETE_MEDIA_PACKAGE_FAILURE = concat(MODULE_NAME, 'DELETE_MEDIA_PACKAGE_FAILURE')
export const CLEAR_DELETE_MEDIA_PACKAGE = concat(MODULE_NAME, 'CLEAR_DELETE_MEDIA_PACKAGE')

export const UPDATE_MEDIA_PACKAGES_ORDER = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGES_ORDER')
export const UPDATE_MEDIA_PACKAGES_ORDER_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGES_ORDER_SUCCESS')
export const UPDATE_MEDIA_PACKAGES_ORDER_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_PACKAGES_ORDER_FAILURE')
export const CLEAR_UPDATE_MEDIA_PACKAGES_ORDER = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_PACKAGES_ORDER')

// get media packages as csv
export const GET_MEDIA_PACKAGES_AS_CSV = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES_AS_CSV')
export const GET_MEDIA_PACKAGES_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES_AS_CSV_SUCCESS')
export const GET_MEDIA_PACKAGES_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_PACKAGES_AS_CSV_FAILURE')
export const CLEAR_GET_MEDIA_PACKAGES_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_PACKAGES_AS_CSV')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get media package
export function getMediaPackage(id, params) {
  return { type: GET_MEDIA_PACKAGE, id, params }
}

export function getMediaPackageSuccess(data) {
  return { type: GET_MEDIA_PACKAGE_SUCCESS, data }
}

export function getMediaPackageFailure(error) {
  return { type: GET_MEDIA_PACKAGE_FAILURE, error }
}

export function clearGetMediaPackage() {
  return { type: CLEAR_GET_MEDIA_PACKAGE }
}

// Get media package categories
export function getMediaPackageCategories(params) {
  return { type: GET_MEDIA_PACKAGE_CATEGORIES, params }
}

export function getMediaPackageCategoriesSuccess(data) {
  return { type: GET_MEDIA_PACKAGE_CATEGORIES_SUCCESS, data }
}

export function getMediaPackageCategoriesFailure(error) {
  return { type: GET_MEDIA_PACKAGE_CATEGORIES_FAILURE, error }
}

export function clearMediaPackageCategories() {
  return { type: CLEAR_MEDIA_PACKAGE_CATEGORIES }
}

// Create media package category
export function createMediaPackageCategory(data) {
  return { type: CREATE_MEDIA_PACKAGE_CATEGORY, data }
}

export function createMediaPackageCategorySuccess(data) {
  return { type: CREATE_MEDIA_PACKAGE_CATEGORY_SUCCESS, data }
}

export function createMediaPackageCategoryFailure(error) {
  return { type: CREATE_MEDIA_PACKAGE_CATEGORY_FAILURE, error }
}

export function clearCreateMediaPackageCategory() {
  return { type: CLEAR_CREATE_MEDIA_PACKAGE_CATEGORY }
}

// Update media package category
export function updateMediaPackageCategory(id, data) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORY, id, data }
}

export function updateMediaPackageCategorySuccess(data) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORY_SUCCESS, data }
}

export function updateMediaPackageCategoryFailure(error) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORY_FAILURE, error }
}

export function clearUpdateMediaPackageCategory() {
  return { type: CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORY }
}

// Update media package categories order
export function updateMediaPackageCategoriesOrder(data) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER, data }
}

export function updateMediaPackageCategoriesOrderSuccess(data) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_SUCCESS, data }
}

export function updateMediaPackageCategoriesOrderFailure(error) {
  return { type: UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER_FAILURE, error }
}

export function clearUpdateMediaPackageCategoriesOrder() {
  return { type: CLEAR_UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER }
}

// Get grouped media packages
export function getGroupedMediaPackages(params) {
  return { type: GET_GROUPED_MEDIA_PACKAGES, params }
}

export function getGroupedMediaPackagesSuccess(data) {
  return { type: GET_GROUPED_MEDIA_PACKAGES_SUCCESS, data }
}

export function getGroupedMediaPackagesFailure(error) {
  return { type: GET_GROUPED_MEDIA_PACKAGES_FAILURE, error }
}

export function clearGroupedMediaPackages() {
  return { type: CLEAR_GROUPED_MEDIA_PACKAGES }
}

// Get media packages
export function getMediaPackages(params) {
  return { type: GET_MEDIA_PACKAGES, params }
}

export function getMediaPackagesSuccess(data) {
  return { type: GET_MEDIA_PACKAGES_SUCCESS, data }
}

export function getMediaPackagesFailure(error) {
  return { type: GET_MEDIA_PACKAGES_FAILURE, error }
}

export function clearMediaPackages() {
  return { type: CLEAR_MEDIA_PACKAGES }
}

// Create media package
export function createMediaPackage(data) {
  return { type: CREATE_MEDIA_PACKAGE, data }
}

export function createMediaPackageSuccess(data) {
  return { type: CREATE_MEDIA_PACKAGE_SUCCESS, data }
}

export function createMediaPackageFailure(error) {
  return { type: CREATE_MEDIA_PACKAGE_FAILURE, error }
}

export function clearCreateMediaPackage() {
  return { type: CLEAR_CREATE_MEDIA_PACKAGE }
}

// Update media package
export function updateMediaPackage(id, data) {
  return { type: UPDATE_MEDIA_PACKAGE, id, data }
}

export function updateMediaPackageSuccess(data) {
  return { type: UPDATE_MEDIA_PACKAGE_SUCCESS, data }
}

export function updateMediaPackagesBulkSuccess(data) {
  return { type: UPDATE_MEDIA_PACKAGES_BULK_SUCCESS, data }
}

export function updateMediaPackageFailure(error) {
  return { type: UPDATE_MEDIA_PACKAGE_FAILURE, error }
}

export function clearUpdateMediaPackage() {
  return { type: CLEAR_UPDATE_MEDIA_PACKAGE }
}

// Delete media package
export function deleteMediaPackage(id) {
  return { type: DELETE_MEDIA_PACKAGE, id }
}

export function deleteMediaPackageSuccess(id) {
  return { type: DELETE_MEDIA_PACKAGE_SUCCESS, id }
}

export function deleteMediaPackageFailure(error) {
  return { type: DELETE_MEDIA_PACKAGE_FAILURE, error }
}

export function clearDeleteMediaPackage() {
  return { type: CLEAR_DELETE_MEDIA_PACKAGE }
}

// Update media packages order
export function updateMediaPackagesOrder(data) {
  return { type: UPDATE_MEDIA_PACKAGES_ORDER, data }
}

export function updateMediaPackagesOrderSuccess(data) {
  return { type: UPDATE_MEDIA_PACKAGES_ORDER_SUCCESS, data }
}

export function updateMediaPackagesOrderFailure(error) {
  return { type: UPDATE_MEDIA_PACKAGES_ORDER_FAILURE, error }
}

export function clearUpdateMediaPackagesOrder() {
  return { type: CLEAR_UPDATE_MEDIA_PACKAGES_ORDER }
}

// get media packages as csv
export function getMediaPackagesAsCsv(params) {
  return { type: GET_MEDIA_PACKAGES_AS_CSV, params }
}

export function getMediaPackagesAsCsvSuccess(data, fileName) {
  return { type: GET_MEDIA_PACKAGES_AS_CSV_SUCCESS, data, fileName }
}

export function getMediaPackagesAsCsvFailure(error) {
  return { type: GET_MEDIA_PACKAGES_AS_CSV_FAILURE, error }
}

export function clearGetMediaPackagesAsCsv() {
  return { type: CLEAR_GET_MEDIA_PACKAGES_AS_CSV }
}

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getFileDetailsError } from '../../Facebook/AdForms/AdFacebookCreate/AdFacebookCreateForm/validation'

import ErrorMessage from '../../../components/Form/ErrorMessage'
import FileUploadInput from '../../../components/Form/FileUploadInput'
import FileDetailsPreview from '../../../components/FileDetailsPreview'
import DropFileUploader from '../../../components/Form/DropFileUploader'

import {
  AD_FILE,
  FILE_NAME,
  FILE_TYPE,
  MEDIA_DURATION,
  MEDIA_FORMAT,
  MEDIA_HEIGHT,
  MEDIA_RATIO,
  MEDIA_SIZE,
  MEDIA_TYPE,
  MEDIA_WIDTH
} from '../../Facebook/AdForms/fields'

import { clearUploadedFile, updateFileUploadProgress } from '../../../modules/actions/files'
import {
  fileUploadErrorSelector,
  fileUploadProgressSelector,
  uploadedFileSelector
} from '../../../modules/selectors/files'

import { showToasts } from '../../../helpers/toasts'
import { getFileFormat, getFileType } from '../../../features/helpers/other'

import { TOAST_TYPE } from '../../../constants/other'
import { FILE_IS_PROCESSING } from '../../../constants/ads'
import { UPLOADED_MEDIA_TYPE } from './fields'

function AdFileUpload({
  values,
  fileValidationSchema,
  validateForm,
  error,
  onFileStartUpload,
  onFileUploaded,
  onFileRemove,
  mediaType,
  fileNameKey,
  fileURLKey,
  fileUrlBlobKey,
  fileTypeKey,
  uploadedMediaTypeKey,
  recommendedSize,
  accept,
  maxSize,
  isDraggable
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState({})
  // when file successfully uploaded set the internal state, that is done to make one time callback on successful
  // FileUploaded to avoid infinity loops based on onFileUploaded func change due to dependencies
  const [fileWasUploaded, setFileWasUploaded] = useState(false)

  // file name should be taken from Formik to make sure that yup fileValidationSchema was made over the file
  const fileName = getIn(values, fileNameKey)
  const fileUrl = getIn(values, fileURLKey)
  const fileType = getIn(values, fileTypeKey)
  const fileUrlBlob = getIn(values, fileUrlBlobKey)
  const uploadedMediaType = getIn(values, uploadedMediaTypeKey)

  const [allowFileDelete, setAllowFileDelete] = useState(!!fileUrl)

  const filePreviewType = useMemo(() => selectedFile.type && getFileType(selectedFile), [selectedFile])
  const filePreview = useMemo(
    () => (selectedFile.name && URL.createObjectURL(selectedFile)) || fileUrl,
    [selectedFile, fileUrl]
  )

  const fileUploadError = useSelector(fileUploadErrorSelector)
  const { [fileName]: uploadedFileData } = useSelector(uploadedFileSelector)
  const { [fileName]: fileUploadProgress } = useSelector(fileUploadProgressSelector)

  const isFileProcessing = uploadedFileData && uploadedFileData.status === FILE_IS_PROCESSING

  const fileIsUploaded = useMemo(() => fileUploadProgress === 100, [fileUploadProgress])
  const isFileLoading = useMemo(() => !!fileUploadProgress && !fileIsUploaded, [fileUploadProgress, fileIsUploaded])
  // Preview is representing only when selected file was uploaded from device => uploadedMediaType === mediaType
  const showPreview = (isFileLoading || fileIsUploaded || fileUrl) && uploadedMediaType === mediaType

  const startFileUploading = (file, newFileValues) => {
    // set selected file only if there are no errors after validation
    setSelectedFile(file)
    // set file values to formik
    const fileUrlBlob = URL.createObjectURL(file)
    onFileStartUpload(file, newFileValues, fileUrlBlob)
  }

  const validateFile = (newFileValues, file) => {
    // use either separate schema for validation or formik validateForm method
    // todo refactor to use fileValidationSchema instead of full form validation and keeping validation fields in formik
    if (fileValidationSchema) {
      fileValidationSchema
        .validate(newFileValues)
        .then(() => startFileUploading(file, newFileValues))
        .catch(error => {
          const errorMessage = error?.message
          showToasts({
            type: TOAST_TYPE.error,
            message: t(errorMessage)
          })
        })
    } else {
      validateForm({ ...values, ...newFileValues }).then(errors => {
        const noError = !getFileDetailsError(errors)
        if (noError && file) {
          startFileUploading(file, newFileValues)
        }
      })
    }
  }

  const handleFileChange = file => {
    const url = URL.createObjectURL(file)
    const size = file.size
    const fileName = file.name
    const type = getFileType(file)
    const format = getFileFormat(file)

    const newFileValues = {
      [FILE_NAME]: fileName,
      [MEDIA_TYPE]: type,
      [FILE_TYPE]: format,
      [UPLOADED_MEDIA_TYPE]: mediaType
    }

    if (type === 'video') {
      const video = document.createElement('video')
      video.src = url
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        // Clean up the blob URL after metadata is loaded
        URL.revokeObjectURL(video.src)

        const newVideoValues = {
          [MEDIA_SIZE]: size,
          [MEDIA_WIDTH]: video.videoWidth,
          [MEDIA_HEIGHT]: video.videoHeight,
          [MEDIA_FORMAT]: format,
          [MEDIA_RATIO]: video.videoWidth / video.videoHeight,
          [MEDIA_DURATION]: video.duration
        }
        validateFile({ ...newFileValues, ...newVideoValues }, file)
      }

      // Clean up the blob URL if the video fails to load
      video.onerror = function () {
        URL.revokeObjectURL(video.src)
      }
    } else if (type === 'image') {
      const image = new Image()
      image.src = url
      image.onload = function () {
        // Clean up the blob URL after the image loads
        URL.revokeObjectURL(image.src)

        const newImageValues = {
          [MEDIA_SIZE]: size,
          [MEDIA_WIDTH]: image.width,
          [MEDIA_HEIGHT]: image.height,
          [MEDIA_FORMAT]: format,
          [MEDIA_RATIO]: image.width / image.height
        }
        validateFile({ ...newFileValues, ...newImageValues }, file)
      }

      // Clean up the blob URL if the image fails to load
      image.onerror = function () {
        URL.revokeObjectURL(image.src)
      }
    } else {
      // For unsupported file types, revoke the URL immediately
      URL.revokeObjectURL(url)
      // represent other files type error
      showToasts({
        type: TOAST_TYPE.error,
        message: t(
          "Sorry, that's not a supported file type. Please ensure that your file is a valid image or video file."
        )
      })
    }
  }

  const removeFile = useCallback(() => {
    setSelectedFile({})
    setAllowFileDelete(false)
    dispatch(updateFileUploadProgress({ [fileName]: 0 }))
    dispatch(clearUploadedFile(fileName))
    // callback for parent components
    onFileRemove && onFileRemove()
  }, [dispatch, onFileRemove, fileName])

  useEffect(() => {
    if (uploadedFileData) {
      setFileWasUploaded(true)
    }
  }, [uploadedFileData])

  useEffect(() => {
    // when file successfully uploaded make a callback to Parent component
    if (fileWasUploaded) {
      onFileUploaded(uploadedFileData)
      setFileWasUploaded(false)
      setAllowFileDelete(true)
    }
  }, [fileWasUploaded, uploadedFileData, onFileUploaded])

  useEffect(() => {
    if (fileUploadError) {
      // remove file when the uploading failed
      removeFile()
    }
  }, [removeFile, fileUploadError])

  return (
    <>
      {showPreview ? (
        <FileDetailsPreview
          fileName={fileName}
          // represent preview from fileUrl if filePreview is empty - for example when data is preloaded form saved data in session storage
          previewUrl={filePreview || fileUrlBlob}
          filePreviewType={filePreviewType || fileType}
          removeFile={removeFile}
          isFileLoading={isFileLoading}
          allowDelete={allowFileDelete}
          fileUploadProgress={fileUploadProgress}
        />
      ) : (
        <>
          {recommendedSize && <p>{t('Recommended size') + ': ' + recommendedSize}</p>}
          {isDraggable ? (
            <DropFileUploader onUpload={handleFileChange} />
          ) : (
            <FileUploadInput name={AD_FILE} onFileUpload={handleFileChange} accept={accept} maxSize={maxSize} />
          )}
        </>
      )}
      {error && !isFileLoading && !fileIsUploaded && (
        <ErrorMessage error={isFileProcessing ? 'Please wait while the video file is processing' : error} />
      )}
    </>
  )
}

AdFileUpload.propTypes = {
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func,
  fileValidationSchema: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFileStartUpload: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func,
  fileNameKey: PropTypes.string,
  fileURLKey: PropTypes.string,
  fileTypeKey: PropTypes.string,
  recommendedSize: PropTypes.string,
  maxSize: PropTypes.number,
  isDraggable: PropTypes.bool
}

export default AdFileUpload

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ReachValue from '../ReachItem'

import { activeContractSelector } from '../../../../../../../../../../modules/selectors/contracts'

import useStyles from '../styles'

const ReachTotalActual = ({ isAmendProcess }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const activeContract = useSelector(activeContractSelector)
  const mediaOrder = activeContract.media_order

  const reachActual = mediaOrder.total_actual_reach
  const impressionsActual = mediaOrder.total_actual_impressions
  const frequencyActual = mediaOrder.total_actual_frequency

  return (
    <div className={classes.reachContainer}>
      <div className={classes.titles}>
        <div>{t('Reach')}:</div>
        <div>{t('Impressions')}:</div>
        <div>{t('Frequency')}:</div>
      </div>
      <div className={classes.values}>
        <ReachValue value={reachActual} isLoading={isAmendProcess} />
        <ReachValue value={impressionsActual} isLoading={isAmendProcess} />
        <ReachValue value={frequencyActual} isLoading={isAmendProcess} skeletonWidth={30} />
      </div>
    </div>
  )
}

ReachTotalActual.propTypes = {
  isAmendProcess: PropTypes.bool
}

export default ReachTotalActual

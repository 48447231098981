import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../../../../components/Form'
import MediaProductEditFormContent from './MediaProductEditFormContent'

import { usePurifiedFormik } from '../../../../../../../hooks/formHooks/usePurifiedFormik'
import { transformValuesToBE } from './formatters'

import { clearUpdateProduct, updateProduct } from '../../../../../../../modules/actions/mediaOrdersProducts'
import {
  updateProductErrorSelector,
  updateProductIsLoadingSelector,
  updateProductWasUpdatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'
import { productTagsSelector } from '../../../../../../../modules/selectors/tags'
import { discountPresetsSelector } from '../../../../../../../modules/selectors/discounts'

import { PRODUCT_EDIT } from '../../../../../../../constants/forms'

import { getInitialValues } from './fields'
import { validationSchema } from './validation'

function MediaProductEditForm({ editProductData = {} }) {
  const dispatch = useDispatch()

  const { id: productId } = editProductData

  const productWasUpdated = useSelector(updateProductWasUpdatedSelector)
  const productTags = useSelector(productTagsSelector)

  const discountPresets = useSelector(discountPresetsSelector)

  const initialValues = useMemo(
    () => getInitialValues({ editProductData, productTags, discountPresets }),
    [editProductData, productTags, discountPresets]
  )

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({ initialValues, values, discountPresets })

      dispatch(updateProduct(productId, transformedData))
    },
    [dispatch, productId, discountPresets, initialValues]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const clearEditMediaProduct = useCallback(() => {
    dispatch(clearUpdateProduct())
  }, [dispatch])

  return (
    <Form
      formik={purifiedFormik}
      formName={PRODUCT_EDIT}
      successSubmit={productWasUpdated}
      errorSelector={updateProductErrorSelector}
      isLoadingSelector={updateProductIsLoadingSelector}
      clearHandler={clearEditMediaProduct}
    >
      <MediaProductEditFormContent formik={formik} editProductData={editProductData} />
    </Form>
  )
}

export default MediaProductEditForm

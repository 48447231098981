import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  select: {
    minWidth: 206,
    maxWidth: 206,
    marginLeft: 'auto'
  }
})

export default useStyles

export const getMediaOrdersTableSchema = tableColumnsSize => [
  {
    header: 'ID',
    style: { maxWidth: tableColumnsSize.id }
  },
  {
    header: 'Name',
    style: { maxWidth: tableColumnsSize.name }
  },
  {
    header: 'Account',
    style: { maxWidth: tableColumnsSize.account }
  },
  {
    header: 'Campaign Name',
    style: { maxWidth: tableColumnsSize.campaignName }
  },
  {
    header: 'Created',
    style: { maxWidth: tableColumnsSize.created }
  },
  {
    header: 'Created By',
    style: { maxWidth: tableColumnsSize.createdBy }
  },
  {
    header: 'Cost',
    style: { maxWidth: tableColumnsSize.cost }
  },
  {
    header: 'Status',
    style: { maxWidth: tableColumnsSize.status }
  },
  {
    style: { maxWidth: tableColumnsSize.actions }
  }
]

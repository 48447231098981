import React from 'react'
import useStyles from './styles'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

const TemplateTable = ({ columns }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.table}>
      <div className={classes.row}>
        {columns.map((column, index) => (
          <div key={index} className={classes.col}>
            <div className={classnames(classes.cell, classes.header)}>
              {typeof column.header === 'string' ? t(column.header) : column.header()}
            </div>
            <div className={classes.cell}>{typeof column.Cell === 'string' ? t(column.Cell) : column.Cell()}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TemplateTable

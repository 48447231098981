import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ProgressButton from '../../../../components/Form/ProgressButton'

import { downloadCSVFile } from '../../../../helpers/other'

import {
  getMediaPackagesAsCsvErrorSelector,
  getMediaPackagesAsCsvIsLoadingSelector,
  getMediaPackagesAsCsvSelector,
  getMediaPackagesAsCsvWasLoadedSelector,
  mediaPackagesCsvFileNameSelector
} from '../../../../modules/selectors/mediaPackages'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { clearGetMediaPackagesAsCsv, getMediaPackagesAsCsv } from '../../../../modules/actions/mediaPackages'

import useStyles from './styles'

const ExportMediaPackagesAsCsv = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const isLoading = useSelector(getMediaPackagesAsCsvIsLoadingSelector)
  const error = useSelector(getMediaPackagesAsCsvErrorSelector)
  const wasLoaded = useSelector(getMediaPackagesAsCsvWasLoadedSelector)
  const csvData = useSelector(getMediaPackagesAsCsvSelector)
  const csvFileName = useSelector(mediaPackagesCsvFileNameSelector)

  const handleOnClick = useCallback(() => {
    dispatch(
      getMediaPackagesAsCsv({
        controller: selfAccountControllerId
      })
    )
  }, [dispatch, selfAccountControllerId])

  const handleDownloadCSVFile = useCallback(() => {
    downloadCSVFile({ data: csvData, fileName: csvFileName || 'mediaPackages' })
  }, [csvData, csvFileName])

  const clearHandler = useCallback(() => {
    dispatch(clearGetMediaPackagesAsCsv())
  }, [dispatch])

  return (
    <ProgressButton
      onClick={handleOnClick}
      isFormLoading={isLoading}
      formError={error}
      successSubmit={wasLoaded}
      clearHandler={clearHandler}
      onSuccessSubmit={handleDownloadCSVFile}
      wrapperClassName={classes.exportButtonWrapper}
    >
      {t('Export as CSV')}
    </ProgressButton>
  )
}

export default ExportMediaPackagesAsCsv

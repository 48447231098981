import React, { useCallback } from 'react'

import SubProductsField from './SubProductsField'
import Field from '../../../../../../../../components/Form/Field'
import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import LocationSectionContent from '../../../../ReusableFormFIelds/LocationSectionContent'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import MediaCategoryFields from '../../../../MediaProductCreate/MediaProductCreateForm/Steps/DetailsStep/MediaCategoryFields'
import AdvancedSettingsFields from '../../../../MediaProductCreate/MediaProductCreateForm/Steps/DetailsStep/AdvancedSettingsFields'

import { CURRENCY } from '../../fields'
import {
  NAME,
  DESCRIPTION,
  MEDIA_CATEGORY,
  PERIOD_OPTION,
  PLATFORMS,
  TAGS
} from '../../../../MediaProductCreate/MediaProductCreateForm/fields'
import { inChargePeriods } from '../../../../../../../../constants/mediaOrders'
import currenciesList from '../../../../../../../../constants/selectLists/currencies'
import { SUB_PRODUCTS } from '../../../../fields'

const stepFields = [NAME, DESCRIPTION, MEDIA_CATEGORY, PERIOD_OPTION, TAGS, PLATFORMS, SUB_PRODUCTS]

function DetailsStep({ formik, handleStepChange }) {
  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Enter the product grouping details"
      stepDescription="Please provide the following details for the new media product grouping"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Media product details">
        <FieldRow title="Name" description="Media product grouping name.">
          <Field formik={formik} name={NAME} placeholder="Product name" autoComplete="off" />
        </FieldRow>
        <FieldRow title="Description" description="More details about the product.">
          <Field formik={formik} name={DESCRIPTION} placeholder="Product description" autoComplete="off" isTextarea />
        </FieldRow>
        <FieldRow title="Currency">
          <Field formik={formik} name={CURRENCY} options={currenciesList} placeholder="Currency" autoComplete="off" />
        </FieldRow>
        <FieldRow title="Media Category" description="The category of media.">
          <MediaCategoryFields formik={formik} allowShowSelectChannelField={false} />
        </FieldRow>
        <FieldRow title="Publication / delivery period" description="The time period that this product is sold in.">
          <Field
            formik={formik}
            name={PERIOD_OPTION}
            options={inChargePeriods}
            placeholder="Delivery period"
            autoComplete="off"
          />
        </FieldRow>
        <FieldRow title="Media Products" description="The products contained within this bookable grouping">
          <SubProductsField formik={formik} />
        </FieldRow>
        <FieldRow title="Location (optional)" description="Group media products by location (e.g. by mall, store)">
          <LocationSectionContent formik={formik} />
        </FieldRow>
        <AdvancedSettingsFields formik={formik} />
      </FieldsSection>
    </Step>
  )
}

export default DetailsStep

export const SORT_PARAMETERS = {
  BOOKING: 'booking',
  ACCOUNT: 'account',
  ASSET_ID: 'asset_id',
  PRODUCT: 'product',
  LOCATION: 'location',
  QUANTITY: 'quantity',
  FILES: 'files',
  STATUS: 'status'
}

export const tableColumnsSize = {
  booking: 80,
  account: 200,
  product: 150,
  asset_id: 150,
  location: 200,
  quantity: 60,
  status: 155,
  actions: 40
}

export const skeletonColumnsSchema = [
  {
    header: 'Booking',
    style: { maxWidth: tableColumnsSize.booking }
  },
  {
    header: 'Account',
    style: { maxWidth: tableColumnsSize.account }
  },
  {
    header: 'Asset ID',
    style: { maxWidth: tableColumnsSize.asset_id }
  },
  {
    header: 'Product'
  },
  {
    header: 'Location',
    style: { maxWidth: tableColumnsSize.location }
  },
  {
    header: 'Quantity',
    style: { maxWidth: tableColumnsSize.quantity }
  },
  {
    header: 'Files'
  },
  {
    header: 'Status',
    style: { maxWidth: tableColumnsSize.status }
  },
  {
    header: 'Actions',
    style: { maxWidth: tableColumnsSize.actions }
  }
]

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Table from '../../../../../../../../../../components/Table'

import { ReactComponent as LinkIcon } from '../../../../../../../../../../assets/icons/new-tab.svg'

import { formatDateFullYear } from '../../../../../../../../../../constants/dates'

import { replaceRouteParams } from '../../../../../../../../../../helpers/url'
import { ROUTE_PARAMS, ROUTES } from '../../../../../../../../../../constants/routes'

import useStyles from './styles'

const CompetingQuotationsTable = ({ competingQuotations }) => {
  const classes = useStyles()

  const productPricesColumns = useMemo(() => {
    return [
      {
        header: 'ID',
        style: { maxWidth: 60 },
        Cell: ({ sequential_id }) => {
          return sequential_id
        }
      },
      {
        header: 'Period',
        style: { minWidth: 215 },
        className: classes.periodsCol,
        Cell: ({ periods }) => {
          return periods.map(({ date_start, date_end }) => {
            return <p>{`${formatDateFullYear(date_start)} - ${formatDateFullYear(date_end)}`}</p>
          })
        }
      },
      {
        header: 'Account',
        style: { minWidth: 150 },
        Cell: ({ account }) => {
          return account
        }
      },
      {
        header: 'Created By',
        style: { minWidth: 150 },
        Cell: ({ created_by }) => {
          return created_by
        }
      },
      {
        headerKey: 'url',
        style: { maxWidth: 40, display: 'flex', justifyContent: 'flex-end' },
        Cell: quotation => {
          return (
            <a
              className={classes.linkIcon}
              href={replaceRouteParams(ROUTES.proposalsEdit, {
                id: quotation.id,
                [ROUTE_PARAMS.selfAccount]: quotation.account_id
              })}
              onClick={e => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
            >
              <LinkIcon />
            </a>
          )
        }
      }
    ]
  }, [classes.linkIcon, classes.periodsCol])

  const rowTemplate = useMemo(
    () => ({
      headlineRowClassName: classes.competingRow,
      rowClassName: classes.competingRow
    }),
    [classes.competingRow]
  )

  return (
    <Table
      className={classes.competingTable}
      cols={productPricesColumns}
      data={competingQuotations}
      rowTemplate={rowTemplate}
      hideFooterRow
    />
  )
}

CompetingQuotationsTable.propTypes = {
  competingQuotations: PropTypes.array
}

export default CompetingQuotationsTable

import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SingleFileUpload from '../../../../ReusableFormFields/SingleFileUpload'

import { selectedTikTokAdAccountIdSelector } from '../../../../../modules/selectors/app'

import { IDENTITY_ICON_ID, IDENTITY_ICON_RATIO, IDENTITY_ICON_URL, initialIdentityIconValues } from '../fields'
import { TIKTOK_PLATFORM } from '../../../../../constants/selectLists/platformList'
import { identityIconValidationSchema } from '../validation'

import useDrawerFormStyles from '../../../../../styles/common/drawerForms'

const IdentityIconSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const { values, setValues, errors, touched } = formik

  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)

  const iconTouched = getIn(touched, IDENTITY_ICON_URL)
  const iconError = getIn(errors, IDENTITY_ICON_URL)
  const iconMissingError = iconTouched && iconError

  const fileUploadOptions = useMemo(
    () => ({
      isPlatformRelated: true,
      platform: TIKTOK_PLATFORM,
      adAccountId: tikTokAdAccountId
    }),
    [tikTokAdAccountId]
  )

  const iconUploadedHandler = useCallback(
    uploadedFile => {
      setValues({
        ...values,
        [IDENTITY_ICON_URL]: uploadedFile.url,
        [IDENTITY_ICON_ID]: uploadedFile['image_id']
      })
    },
    [setValues, values]
  )

  const iconRemoveHandler = useCallback(() => {
    setValues({ ...values, ...initialIdentityIconValues })
  }, [setValues, values])

  const iconValidationHandler = useCallback(file => {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file)

      const image = new Image()
      image.src = url

      image.onload = () => {
        // Clean up the blob URL once it's loaded
        URL.revokeObjectURL(url)
        resolve({ [IDENTITY_ICON_RATIO]: image.width / image.height })
      }

      image.onerror = error => {
        // Clean up in case of an error as well
        URL.revokeObjectURL(url)
        reject(error)
      }
    }).then(values => {
      return identityIconValidationSchema.validate(values)
    })
  }, [])

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Identity Icon')}</h3>
      <SingleFileUpload
        fileURL={values[IDENTITY_ICON_URL]}
        onFileUploaded={iconUploadedHandler}
        onFileRemove={iconRemoveHandler}
        fileValidationHandler={iconValidationHandler}
        fileUploadOptions={fileUploadOptions}
        error={iconMissingError}
        accept="image/png,image/jpg,image/jpeg"
      />
    </div>
  )
}

export default IdentityIconSection

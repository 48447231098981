import { createStyles } from '../../../styles/helpers'

import { mediumGrey } from '../../../styles/const/colors'

export default createStyles(theme => ({
  productsCalendar: {
    position: 'relative',
    maxWidth: '1340px',
    margin: '0 auto',
    marginBottom: '32px',
    backgroundColor: 'white',
    border: {
      width: '1px',
      style: 'solid',
      color: mediumGrey
    },
    borderRadius: theme.bigBorderRadius
  },
  calendarToggle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 12,
    padding: 16,
    color: theme.brandPrimary,
    fontWeight: 600,
    '&:hover': {
      color: theme.brandPrimaryHover
    }
  },
  isLoading: {
    pointerEvents: 'none'
  },
  isOpened: {
    transform: 'rotate(180deg)'
  },
  table: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    borderSpacing: '0px'
  }
}))

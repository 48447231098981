import { useMemo } from 'react'
import { useTheme } from 'react-jss'

import useStartWeekDay from '../../../../../../../../../../../../features/hooks/useStartWeekDay'

export default function useDateRange() {
  const theme = useTheme()

  const weekStartsOn = useStartWeekDay()

  const rangeSelectedTextColor = useMemo(
    () => theme.brandPrimaryTextContrastColor,
    [theme.brandPrimaryTextContrastColor]
  )

  return useMemo(
    () => ({
      weekStartsOn,
      rangeSelectedTextColor
    }),
    [rangeSelectedTextColor, weekStartsOn]
  )
}

import { createStyles } from '../../../../../../../styles/helpers'
import { greyOutline } from '../../../../../../../styles/const/colors'

const useStyles = createStyles({
  fieldLabelWrapper: {
    marginTop: '0 !important',
    width: 'unset',
    flexGrow: 1,
    maxWidth: props => props.maxWidth || 'unset',
    minWidth: props => props.minWidth || 'unset'
  },
  previewModeLabel: {
    fontSize: 14,
    fontWeight: 600,
    display: 'block',
    marginBottom: 14,
    color: greyOutline
  },
  error: {
    paddingLeft: 'unset'
  }
})

export default useStyles

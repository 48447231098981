import React, { useCallback } from 'react'

import DownloadPdfButton from '../../../../../features/components/PdfGeneration/DownloadPdfButton'

import useGetAccessibleUrl from '../../../../../features/hooks/useGetAccessibleUrl'

function DownloadContract({ pdfUrl, fileName, className }) {
  const { handleFileDownloading, isAccessibleUrlLoading } = useGetAccessibleUrl()

  const handleFileDownload = useCallback(() => {
    handleFileDownloading({
      url: pdfUrl,
      file_name: `${fileName}.pdf`
    })
  }, [fileName, handleFileDownloading, pdfUrl])

  return <DownloadPdfButton className={className} onClick={handleFileDownload} isLoading={isAccessibleUrlLoading} />
}

export default DownloadContract

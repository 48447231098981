import React from 'react'

import FootfallAreasTable from './FootfallAreasTable'
import FootfallAreasEdit from '../../../../forms/Multiplatform/FootfallForms/FootfallAreas/FootfallAreasEdit'
import FiltersSearch from '../../../../features/components/Filters/FiltersSearch'

import useStyles from './styles'

export const FOOTFALL_AREAS_SEARCH = 'footfall_areas_search'

const FootfallAreas = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.footfallAreasTableActions}>
        <FiltersSearch className={classes.footfallAreasSearch} searchId={FOOTFALL_AREAS_SEARCH} />
      </div>
      <FootfallAreasTable />
      <FootfallAreasEdit />
    </>
  )
}

export default FootfallAreas

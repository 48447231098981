import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import MultipleControllerSelfAccountsFilter, {
  MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER
} from '../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import ProductsFilter, {
  SELECTED_PRODUCTS
} from '../../../../../features/components/DropdownFilters/components/ProductsFilter'
import {
  BRAND_CATEGORY,
  BrandCategory
} from '../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import MultipleControllerMembersFilter, {
  MULTIPLE_CONTROLLER_MEMBERS_FILTER
} from '../../../../../features/components/DropdownFilters/components/MultipleControllerMembersFilter'
import DropdownFilters from '../../../../../features/components/DropdownFilters'

import { QuotationTableContext } from '../../QuotationTableContext'

const ProposalsFilters = () => {
  const filters = useMemo(() => {
    return [
      {
        component: <MultipleControllerSelfAccountsFilter Context={QuotationTableContext} />,
        title: 'Accounts',
        filterName: MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER,
        show: true
      },
      {
        component: <MultipleControllerMembersFilter Context={QuotationTableContext} />,
        filterName: MULTIPLE_CONTROLLER_MEMBERS_FILTER,
        title: 'Created by',
        show: true
      },
      {
        component: <BrandCategory Context={QuotationTableContext} />,
        title: 'Brand Category',
        filterName: BRAND_CATEGORY,
        show: true
      },
      {
        component: <ProductsFilter Context={QuotationTableContext} />,
        title: 'Products',
        filterName: SELECTED_PRODUCTS,
        show: true
      }
    ]
  }, [])

  return <DropdownFilters filters={filters} Context={QuotationTableContext} />
}

ProposalsFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default ProposalsFilters

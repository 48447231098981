import { createStyles } from '../../styles/helpers'

import { lightRed, coralPink, mediumGrey, red } from '../../styles/const/colors'

export const fileNameMargin = 8

const useStyles = createStyles({
  fileDetails: {
    marginTop: 10,
    marginBottom: 8,
    '&:first-child': {
      marginTop: 16
    },
    '&:last-child': {
      marginBottom: 0
    },
    border: `1px solid ${mediumGrey}`,
    borderRadius: 4,
    padding: 5,
    cursor: ({ onClick }) => (onClick ? 'pointer' : 'default')
  },
  progressBar: {
    marginTop: 10
  },
  deleteBtn: {
    minWidth: 18,
    width: 18,
    height: 18,
    marginLeft: fileNameMargin,
    background: lightRed,
    borderRadius: '50%',

    '& svg': {
      width: 6,
      height: 6
    },

    '& path': {
      fill: coralPink
    },

    '&:hover path': {
      fill: red
    }
  },
  uploadedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    marginBottom: 6
  }
})

export default useStyles

import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Select from '../../../../../../components/Select'
import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import { selectedControllerRelatedSelfAccountsListSelector } from '../../../../../../modules/selectors/app'

import { BRAND, initialBrand } from '../../fields'

import useStyles from './styles'

function AccountSelectionSection({ currencyLimiter, setFieldValue, className }) {
  const classes = useStyles()
  const controllerSelfAccountsList = useSelector(selectedControllerRelatedSelfAccountsListSelector)

  const allowedSelfAccountsOptions = useMemo(() => {
    if (currencyLimiter) {
      // during contract edit allow to select only accounts with the same currency
      return controllerSelfAccountsList.map(account => {
        const isDisabled = account.currency !== currencyLimiter
        return {
          ...account,
          isDisabled,
          ...(isDisabled && { tag: account.currency_symbol })
        }
      })
    } else {
      // during contract creation allow to select any account
      return controllerSelfAccountsList
    }
  }, [controllerSelfAccountsList, currencyLimiter])

  const { contextSelfAccountData, handleContextSelfAccountChange } = useContext(MediaOrderFormContext)
  const handleSelfAccountChange = useCallback(
    newSelfAccount => {
      handleContextSelfAccountChange(newSelfAccount)
      // reset selected brand which relates on selfAccount
      setFieldValue(BRAND, initialBrand)
    },
    [handleContextSelfAccountChange, setFieldValue]
  )

  return (
    <Select
      placeholder="Account"
      className={classnames(classes.accountSelect, className)}
      options={allowedSelfAccountsOptions}
      value={contextSelfAccountData}
      onChange={handleSelfAccountChange}
      expandMenu
      formatValue={false} // save full data object
      isSearchable={true}
    />
  )
}

AccountSelectionSection.propTypes = {
  currencyLimiter: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default AccountSelectionSection

import React from 'react'

import { CAN_SELECT_MULTIPLE } from '../../../../../../../../../MediaProducts/MediaProductVariableForms/fields'
import { MULTIPLE_VARIABLE_OPTIONS, SINGLE_VARIABLE_OPTION } from '../../../../../../../../fields'

import useStyles from './styles'

const ProductVariablesList = ({ variables }) => {
  const classes = useStyles()

  return variables.map(variable => {
    const {
      name,
      [CAN_SELECT_MULTIPLE]: canSelectMultiple,
      [SINGLE_VARIABLE_OPTION]: singleVariableOption,
      [MULTIPLE_VARIABLE_OPTIONS]: multipleVariableOptions
    } = variable
    const formattedMultipleOptions = multipleVariableOptions?.map(item => item.value).join(', ')

    return (
      <p className={classes.productVariableName} key={variable.id}>
        {name}: {canSelectMultiple ? formattedMultipleOptions : singleVariableOption}
      </p>
    )
  })
}

export default ProductVariablesList

import store from '../../store'

import { ENDPOINTS } from '../../constants/api'
import { replaceRouteParams } from '../../helpers/url'
import { getFormData, madeRequest } from '../../helpers/api'
import { simulateFileUploadProgress } from '../../helpers/modules/reducerHelpers'

import { updateFileUploadProgress } from '../actions/files'
import { selectedAdAccountIdSelector } from '../selectors/app'

// PLOP_APPEND_PATTERN_ANCHOR

export function fileSignService({ name }, isPrivate) {
  return madeRequest('POST', {
    url: isPrivate ? ENDPOINTS.privateUploadUrl : ENDPOINTS.signUrl,
    data: { file_name: name }
  })
}

export function multipleFilesSignService(filesNames, isPrivate) {
  const formattedFilesNames = filesNames.map(file => ({ file_name: file.name }))

  return madeRequest('POST', {
    url: isPrivate ? ENDPOINTS.privateUploadUrlBatch : ENDPOINTS.signUrlBatch,
    data: { files: formattedFilesNames }
  })
}

export function fileUploadToServerService(signedFile) {
  return madeRequest('POST', {
    url: signedFile.url,
    contentType: false,
    processData: false,
    headers: { 'x-amz-acl': 'public-read' },
    data: getFormData({
      ...signedFile.fields,
      file: signedFile.file
    }),
    onUploadProgress: progressEvent => {
      // multiply to 80 as other 5% dedicated to FILE_UPLOAD_START_SUCCESS and 15% to FILE_UPLOAD_TO_FACEBOOK_PLATFORM
      const percentCompleted = Math.round((progressEvent.loaded * 80) / progressEvent.total)

      // File may be uploaded and stored in redux by id or name
      const fileUploadingKey = signedFile.file.id || signedFile.file.name

      // update only if progress bigger then 1 to avoid reset to 0 or 1
      // i.e. loading big files as 3000mb on the beginning could have progress rounded to 0
      percentCompleted > 1 &&
        store.dispatch(
          updateFileUploadProgress({
            [fileUploadingKey]: percentCompleted + 5
          })
        )
    },
    // disable timeout to support big files upload, or from a user with a slow upload speed.
    timeout: 0
  })
}

export function fileUploadToFacebookPlatformService(fileUrl, fileType, fileName, adAccountId) {
  // get selectedAdAccountId value directly from store to avoid passing through 3 requests
  const currentState = store.getState()
  const selectedAdAccountId = selectedAdAccountIdSelector(currentState)
  let progressInterval = null

  return madeRequest('POST', {
    url: fileType === 'image' ? ENDPOINTS.facebookMediaImage : ENDPOINTS.facebookMediaVideo,
    // if ad account is passed from outside, use it instead of selected one
    data: getFormData({ file_url: fileUrl, file_name: fileName, account: adAccountId || selectedAdAccountId }),
    // simulate file upload progress:
    onUploadProgress: () => (progressInterval = simulateFileUploadProgress(fileName)),
    onDownloadProgress: () => {
      clearInterval(progressInterval)
      updateFileUploadProgress({ [fileName]: 99 })
    }
  })
}

export function fileUploadToTikTokPlatformService(data, fileName, fileType) {
  let progressInterval = null

  return madeRequest('POST', {
    url: fileType === 'image' ? ENDPOINTS.tikTokImages : ENDPOINTS.tikTokVideos,
    data,
    // simulate file upload progress:
    onUploadProgress: () => (progressInterval = simulateFileUploadProgress(fileName)),
    onDownloadProgress: () => {
      clearInterval(progressInterval)
      updateFileUploadProgress({ [fileName]: 99 })
    }
  })
}

export function getFacebookVideoDetailsService(videoId, adAccountId) {
  // this endpoint is start calling periodically after success FILE_UPLOAD_TO_PLATFORM from filesWatcher saga
  return madeRequest('GET', {
    url: replaceRouteParams(ENDPOINTS.facebookMediaVideoDetail, {
      id: videoId
    }),
    params: {
      account: adAccountId
    }
  })
}

export function getTikTokVideoDetailsService(videoId, adAccountId) {
  // this endpoint is start calling periodically after success FILE_UPLOAD_TO_PLATFORM from filesWatcher saga
  return madeRequest('GET', {
    url: `${ENDPOINTS.tikTokVideos}${videoId}/`,
    params: {
      account: adAccountId
    }
  })
}

export function accessibleUrlService(data, params) {
  return madeRequest('POST', {
    url: ENDPOINTS.accessibleUrl,
    params,
    data
  })
}

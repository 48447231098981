import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MediaOrderFileActions from './MediaOrderFileActions'
import Can from '../../../../features/components/Can'
import NavItemsMenu from '../../../../features/components/NavItemsMenu'
import ActionText from '../../../../components/ActionText'
import NavItemsMenuSkeleton from '../../../../features/components/NavItemsMenu/Skeleton'
import DeleteItemModal from '../../../../features/components/Modals/DeleteModal/DeleteItemModal'
import UploadMediaOrderFile from '../../../../forms/Multiplatform/MediaOrderForms/UploadMediaOrderFile'

import useDeleteAction from '../../../../features/hooks/useDeleteAction'

import { openForm } from '../../../../modules/actions/forms'
import {
  clearDeleteMediaOrderFile,
  clearGetMediaOrderFiles,
  deleteMediaOrderFile,
  getMediaOrderFiles
} from '../../../../modules/actions/mediaOrders'

import {
  deleteMediaOrderFileErrorSelector,
  deleteMediaOrderFileIsLoadingSelector,
  deleteMediaOrderFileSelector,
  getMediaOrderFilesIsLoadingSelector,
  mediaOrderFilesSelector,
  mediaOrderSelector
} from '../../../../modules/selectors/mediaOrders'

import { MEDIA_ORDER_FILE_TYPES } from './constants'
import { UPLOAD_MEDIA_ORDER_FILE } from '../../../../constants/forms'
import { UPLOAD_MEDIA_ORDER_FILES } from '../../../../constants/permissions'

import useStyles from './styles'

const DocumentsSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [selectedFileId, setSelectedFileId] = useState(null)

  const mediaOrder = useSelector(mediaOrderSelector)
  const { id: mediaOrderId } = mediaOrder
  const mediaOrderFiles = useSelector(mediaOrderFilesSelector)
  const mediaOrderFilesIsLoading = useSelector(getMediaOrderFilesIsLoadingSelector)

  const isCancelled = mediaOrder?.status === 'cancelled'
  const firstFile = useMemo(() => {
    if (isCancelled) {
      // workaround to represent canceled contract after media order cancellation
      // preselect booking contract to show that it is canceled
      return mediaOrderFiles?.find(file => file.file_type === MEDIA_ORDER_FILE_TYPES.booking)
    } else {
      return mediaOrderFiles[0]
    }
  }, [mediaOrderFiles, isCancelled])

  const selectedFile = useMemo(
    () => mediaOrderFiles.find(file => file.id === selectedFileId),
    [mediaOrderFiles, selectedFileId]
  )

  const deleteFileHandler = useCallback(
    params => {
      dispatch(deleteMediaOrderFile(params))
    },
    [dispatch]
  )

  const clearDeleteFileHandler = useCallback(() => {
    dispatch(clearDeleteMediaOrderFile())
  }, [dispatch])

  const { handleDelete, handleDeleteRequest, handleCloseDeleteModal, isDeleteModalOpened, deleteItemIsLoading } =
    useDeleteAction({
      onDelete: deleteFileHandler,
      onClearDelete: clearDeleteFileHandler,
      deletedItemSelector: deleteMediaOrderFileSelector,
      deleteItemErrorSelector: deleteMediaOrderFileErrorSelector,
      deleteItemIsLoadingSelector: deleteMediaOrderFileIsLoadingSelector
      // deleteModalMessage: deleteModalMessage
    })

  const handleDeleteFile = useCallback(() => {
    const selectedFile = mediaOrderFiles.find(file => file.id === selectedFileId)
    handleDeleteRequest({ item: selectedFile })
  }, [handleDeleteRequest, mediaOrderFiles, selectedFileId])

  const handleFileChange = useCallback(id => {
    setSelectedFileId(id)
  }, [])

  const handleUploadFile = useCallback(() => {
    dispatch(openForm({ formName: UPLOAD_MEDIA_ORDER_FILE }))
  }, [dispatch])

  const isReachReport = selectedFile?.file_type === MEDIA_ORDER_FILE_TYPES.reachReport

  useEffect(() => {
    dispatch(getMediaOrderFiles({ media_order: mediaOrderId }))
  }, [dispatch, mediaOrderId])

  useEffect(() => {
    return () => dispatch(clearGetMediaOrderFiles())
  }, [dispatch])

  useEffect(() => {
    // preselect first file when it's loaded, or if active file was deleted
    if (firstFile) {
      setSelectedFileId(firstFile.id)
    }
  }, [firstFile])

  return (
    <div className={classes.container}>
      <div className={classes.menuWrapper}>
        {mediaOrderFilesIsLoading ? (
          <NavItemsMenuSkeleton />
        ) : (
          <>
            <NavItemsMenu
              itemsList={mediaOrderFiles}
              selectedItemId={selectedFileId}
              onMenuItemClick={handleFileChange}
            />
            <Can I="manage" a={UPLOAD_MEDIA_ORDER_FILES}>
              <ActionText className={classes.uploadFile} onClick={handleUploadFile} isDark>
                + Upload a file
              </ActionText>
              <UploadMediaOrderFile mediaOrderId={mediaOrder?.id} />
              {isReachReport && (
                <DeleteItemModal
                  modalTitle={t('Delete report?')}
                  deleteModalMessage={t(
                    'Once deleted, the post campaign report will need to be regenerated if required.'
                  )}
                  handleDelete={handleDelete}
                  handleCloseDeleteModal={handleCloseDeleteModal}
                  isDeleteModalOpened={isDeleteModalOpened}
                  deleteItemIsLoading={deleteItemIsLoading}
                  showCancelButton={true}
                />
              )}
            </Can>
          </>
        )}
      </div>
      {/* don't show any preview if there is no file */}
      {firstFile && (
        <MediaOrderFileActions
          fileUrl={selectedFile?.file}
          isReachReport={isReachReport}
          handleDeleteFile={isReachReport ? handleDeleteFile : undefined}
          fileName={`${selectedFile?.file_type}_${mediaOrder.sequential_id}`}
          downloadButtonClassName={classes.downloadPdfButton}
        />
      )}
    </div>
  )
}

export default DocumentsSection

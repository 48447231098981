import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearGetBookingVariables, getBookingVariables } from '../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

export const useFetchBookingVariablesForContractBuilder = () => {
  const dispatch = useDispatch()
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const params = useMemo(
    () => ({
      controller: selectedControllerId,
      ordering: 'name',
      status: ['active'],
      media_product_attribute: true
    }),
    [selectedControllerId]
  )

  useEffect(() => {
    dispatch(getBookingVariables(params))

    return () => {
      dispatch(clearGetBookingVariables())
    }
  }, [dispatch, params])
}

import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import usePermissions from '../../../../hooks/usePermissions'

import { insertIf } from '../../../../helpers/common'
import { redirectTo } from '../../../../helpers/url'

import { updateQuotationsStatusBulk } from '../../../../modules/actions/quotations'
import { quotationsSelector } from '../../../../modules/selectors/quotations'

import { ROUTE_PARAMS, ROUTES } from '../../../../constants/routes'
import { MODIFY_QUOTATION_PRICING, QUOTATION_PERMISSION } from '../../../../constants/permissions'

export default function useQuotationActions() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const quotations = useSelector(quotationsSelector)

  const permissions = usePermissions()
  const allowDelete = permissions.can('delete', QUOTATION_PERMISSION)
  const showModifyPricingOption = permissions.can('manage', MODIFY_QUOTATION_PRICING)

  // const viewQuotationHandler = useCallback(
  //   (e, id) => {
  //     dispatch(openForm({ id, formName: PROPOSAL_REVIEW }))
  //   },
  //   [dispatch]
  // )

  const deleteQuotationHandler = useCallback(
    (e, itemId) => {
      // contract delete is just status replacement to deleted
      dispatch(
        updateQuotationsStatusBulk({
          ids: [itemId],
          status: 'deleted'
        })
      )
    },
    [dispatch]
  )

  const handleEditRedirect = useCallback(({ proposalId, accountId }) => {
    // keep logs to track issue with redirect to the object instead of [object Object] account id
    console.log('handleEditRedirect - accountId:', accountId, 'proposalId:', proposalId)

    redirectTo(ROUTES.proposalsEdit, {
      id: proposalId,
      [ROUTE_PARAMS.selfAccount]: accountId
    })
  }, [])

  const getDropdownOptions = useCallback(
    proposalId => {
      const currentQuotationData = quotations.find(({ id: quotationId }) => quotationId === proposalId) || {}
      const account = currentQuotationData?.account
      const accountId = account?.id
      const accountIdType = typeof accountId
      if (accountIdType === 'object') {
        console.log('accountId is an object', accountId)
      }

      return [
        // {
        //   text: t('View'),
        //   onClickHandler: viewQuotationHandler
        // },
        ...insertIf(showModifyPricingOption, {
          text: t('Edit'),
          onClickHandler: () => handleEditRedirect({ proposalId, accountId })
        }),
        ...insertIf(allowDelete, {
          text: t('Delete'),
          onClickHandler: deleteQuotationHandler
        })
      ]
    },
    [quotations, t, showModifyPricingOption, handleEditRedirect, allowDelete, deleteQuotationHandler]
  )

  return useMemo(
    () => ({
      handleEditRedirect,
      getDropdownOptions
    }),
    [getDropdownOptions, handleEditRedirect]
  )
}

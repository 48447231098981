import React from 'react'
import PropTypes from 'prop-types'

import { formatNumber } from '../../../../../../../../helpers/numbers'

import useStyles from './styles'

const ReachCell = ({ productValues, isAmendment }) => {
  const classes = useStyles()

  const productData = productValues.data
  // for media order details it will use media_order:products:actual_reach
  const productReach = isAmendment ? productData?.actual_reach : productData?.estimated_reach
  const formattedReach = formatNumber(productReach)

  return <div className={classes.reachCell}>{formattedReach}</div>
}

ReachCell.propTypes = {
  productValues: PropTypes.object,
  isAmendment: PropTypes.bool
}

export default ReachCell

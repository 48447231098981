import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import ProductVariablesTable from './ProductVariablesTable'
import MediaProductVariableCreate from '../../../../forms/Multiplatform/MediaProducts/MediaProductVariableForms/MediaProductVariableCreate'
import MediaProductVariableEdit from '../../../../forms/Multiplatform/MediaProducts/MediaProductVariableForms/MediaProductVariableEdit'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import {
  clearGetMediaProductVariables,
  getMediaProductVariables
} from '../../../../modules/actions/mediaOrdersProductVariables'

const ProductVariables = () => {
  const dispatch = useDispatch()

  const controllerId = useSelector(selectedControllerIdSelector)

  useEffect(() => {
    dispatch(getMediaProductVariables({ controller: controllerId }))

    return () => {
      dispatch(clearGetMediaProductVariables())
    }
  }, [dispatch, controllerId])

  return (
    <ContentRow
      title="Product Variables"
      description="Product variables are options or parameters that the user selects during the booking process."
      leftColumnChildren={<MediaProductVariableCreate />}
    >
      <ProductVariablesTable />
      <MediaProductVariableEdit />
    </ContentRow>
  )
}

export default ProductVariables

import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../components/Form/Field'
import FieldRow from '../Form/FieldsSection/FieldRow'

import { PERIOD_OPTION } from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import { inChargePeriods, periodsPluralTitle } from '../../../constants/mediaOrders'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD } from '../../../forms/Multiplatform/MediaProducts/MediaProductForms/fields'

const InventoryFields = ({ formik }) => {
  const { values } = formik

  const { t } = useTranslation()

  const periodOption = values[PERIOD_OPTION]
  // find period label
  const periodLabel = inChargePeriods.find(period => period.value === periodOption)?.label
  const pluralPeriodLabel = periodsPluralTitle[periodOption]

  const translatedPeriodLabel = t(periodLabel).toLowerCase()
  const translatedPluralPeriodLabel = t(pluralPeriodLabel)

  return (
    <>
      <FieldRow
        title="Inventory per period"
        description={t('productAvailabilityPeriod', { period: translatedPeriodLabel })}
      >
        <Field formik={formik} name={INVENTORY_AMOUNT} type="number" placeholder="Quantity" autoComplete="off" />
      </FieldRow>
      <FieldRow
        title={t('numberOfPeriods', { period: translatedPluralPeriodLabel })}
        description={t('inventoryPeriodApplication', { period: translatedPluralPeriodLabel })}
      >
        <Field formik={formik} name={INVENTORY_PERIOD} type="number" placeholder="Quantity" autoComplete="off" />
      </FieldRow>
    </>
  )
}

export default InventoryFields

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { differenceInCalendarMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'
import AnimateHeight from 'react-animate-height'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-arrow-down.svg'

import CalendarRows from './CalendarRows'
import CalendarTableHeader from './CalendarTableHeader'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { ProductsCalendarContext } from './ProductsCalendarContext'

import { getTitleCols } from '../../../features/components/Calendar/CalendarTable'
import { getSubtitleMonthBasedCols } from '../../../features/components/Calendar/CalendarTable/year'
import { getQuarterSubtitleCols } from '../../../features/components/Calendar/CalendarTable/quarter'
import { getSubtitleDaysColumns } from '../../../features/components/Calendar/CalendarTable/month'

import { ID_COL_WIDTH, NAME_COL_WIDTH } from './constants'
import { EVENTS_AREA_PADDING } from '../../../constants/timeline'
import { CALENDAR_VIEW_TYPE } from '../../../constants/selectLists/calendarList'

import useStyles from './styles'

const ProductsCalendarPreview = ({ products }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { startDate, endDate, viewType } = useContext(ProductsCalendarContext)

  const tableRef = useRef(null)
  const [width] = useWindowSize()
  const [tableWidth, setTableWidth] = useState(1000)
  const [height, setHeight] = useState(0)

  const tableElementWidth = tableRef.current && tableRef.current.offsetWidth

  const isMinimized = height === 0

  const onToggleSpoiler = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  useEffect(() => {
    // change table width on window width change
    if (!tableRef.current) return
    setTableWidth(tableElementWidth)
  }, [width, tableElementWidth])

  const getSubtitleCols = (viewType, startDate, endDate) => {
    switch (viewType) {
      case CALENDAR_VIEW_TYPE.YEAR:
        const monthsCount = differenceInCalendarMonths(endDate, startDate) + 1
        return getSubtitleMonthBasedCols(startDate, monthsCount)
      case CALENDAR_VIEW_TYPE.QUARTER:
        return getQuarterSubtitleCols(startDate, endDate)
      case CALENDAR_VIEW_TYPE.MONTH:
        return getSubtitleDaysColumns(startDate, endDate)
      default:
        throw new Error(t('unsupportedViewType', { viewType }))
    }
  }

  const [titleCols, subtitleCols] = useMemo(() => {
    const monthsCount = differenceInCalendarMonths(endDate, startDate) + 1
    return [getTitleCols(viewType, startDate, endDate, monthsCount), getSubtitleCols(viewType, startDate, endDate)]

    // there is no need to add selectedViewType as changing selectedViewType will update dates so dates and selectedViewType will always be in sync here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const tableInnerRowWidth = tableWidth - NAME_COL_WIDTH - ID_COL_WIDTH - 2 * EVENTS_AREA_PADDING

  // set tableRef on container, as table is not rendering when minimized so we can't get width from table
  return (
    <div className={classes.productsCalendar} ref={tableRef}>
      <div className={classes.calendarToggle} onClick={onToggleSpoiler}>
        {t('Calendar View')}
        <ArrowIcon
          className={classnames(classes.arrowIcon, {
            [classes.isOpened]: !isMinimized
          })}
        />
      </div>

      <AnimateHeight duration={300} height={height}>
        <div id="so-calendar" className={classes.root}>
          <table className={classes.table}>
            <CalendarTableHeader titleCols={titleCols} subtitleCols={subtitleCols} />
            <CalendarRows
              renderEvents={!isMinimized}
              products={products}
              selectedViewType={viewType}
              colsCount={subtitleCols.length}
              tableInnerRowWidth={tableInnerRowWidth}
              startDate={startDate}
              endDate={endDate}
            />
          </table>
        </div>
      </AnimateHeight>
    </div>
  )
}

ProductsCalendarPreview.propTypes = {
  products: PropTypes.array
}

export default ProductsCalendarPreview

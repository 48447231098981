import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useRequestHandler from '../../../../../../../hooks/useRequestHandler'

import {
  clearGenerateCampaignReachReport,
  generateCampaignReachReport
} from '../../../../../../../modules/actions/mediaOrdersBookings'
import {
  generateCampaignReachReportErrorSelector,
  generateCampaignReachReportIsLoadingSelector,
  generateCampaignReachReportWasCreatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersBookings'

export function useGenerateReachReport(mediaOrderId) {
  const dispatch = useDispatch()

  const handleGenerateReport = useCallback(() => {
    dispatch(
      generateCampaignReachReport({
        id: mediaOrderId
      })
    )
  }, [dispatch, mediaOrderId])

  const clearGenerateHandler = useCallback(() => {
    dispatch(clearGenerateCampaignReachReport())
  }, [dispatch])

  const { isLoading, requestSuccess, requestError } = useRequestHandler({
    clearHandler: clearGenerateHandler,
    isLoadingRequestSelector: generateCampaignReachReportIsLoadingSelector,
    successRequestSelector: generateCampaignReachReportWasCreatedSelector,
    errorRequestSelector: generateCampaignReachReportErrorSelector
  })

  return useMemo(
    () => ({
      handleGenerateReport,
      isLoading,
      requestSuccess,
      requestError
    }),
    [handleGenerateReport, isLoading, requestSuccess, requestError]
  )
}

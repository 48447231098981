import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import PeriodPrice from './PeriodPrice'
import ActionText from '../../../../../../../../../../../components/ActionText'
import ErrorMessage from '../../../../../../../../../../../components/Form/ErrorMessage'

import { calc } from '../../../../../../../../../../../helpers/numbers'

import { splitPeriodsToGroups } from '../../../../../../../../ContractBuilder/fields'
import { generateGroupLabel } from '../../../../../../../../ContractBuilder/ProductsManage/ProductsTable/Cells/DatesCell/helpers'

import useStyles from '../styles'

function SelectedPeriodsList({
  productPeriods,
  productsQuantity,
  currencySymbol,
  handleProductPeriodsSetup,
  productPublicationsError,
  currentDiscount,
  showPeriodPrice = true,
  listItemClassName
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  // show all media products as continuous ranges. If there are gaps between periods, they will be shown as separate ranges
  // use the solution from the contract builder
  const groupedPeriods = useMemo(() => splitPeriodsToGroups(productPeriods), [productPeriods])
  const formattedGroup = useMemo(() => {
    return groupedPeriods.map(group => {
      return {
        label: generateGroupLabel(group),
        price: group.reduce((acc, period) => calc(acc).add(period.price).toNumber(), 0)
      }
    })
  }, [groupedPeriods])

  return (
    <>
      {!!productPeriods.length ? (
        <ul className={classes.publicationsList}>
          {formattedGroup.map(period => (
            <li className={classnames(classes.publicationDetails, listItemClassName)} key={period.label}>
              {period.label} {productsQuantity > 1 && `x ${productsQuantity}`}
              {showPeriodPrice && (
                <PeriodPrice
                  className={classes.price}
                  productQuantity={productsQuantity}
                  periodPrice={period.price}
                  currentDiscount={currentDiscount}
                  currencySymbol={currencySymbol}
                />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          {handleProductPeriodsSetup && (
            <ActionText onClick={handleProductPeriodsSetup} isDark>
              {t('+ Select dates')}
            </ActionText>
          )}
          {productPublicationsError && <ErrorMessage error={productPublicationsError} />}
        </>
      )}
    </>
  )
}

SelectedPeriodsList.propTypes = {
  productPeriods: PropTypes.array.isRequired,
  currentDiscount: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  productsQuantity: PropTypes.number,
  handleProductPeriodsSetup: PropTypes.func,
  productPublicationsError: PropTypes.string,
  showPeriodPrice: PropTypes.bool,
  listItemClassName: PropTypes.string
}

export default SelectedPeriodsList

import update from 'immutability-helper'
import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  GET_CURRENT_STATEMENT,
  GET_CURRENT_STATEMENT_SUCCESS,
  GET_CURRENT_STATEMENT_FAILURE,
  CLEAR_GET_CURRENT_STATEMENT,
  GET_STATEMENTS,
  GET_STATEMENTS_FAILURE,
  GET_STATEMENTS_SUCCESS,
  CLEAR_GET_STATEMENTS,
  UPDATE_STATEMENT,
  UPDATE_STATEMENT_FAILURE,
  UPDATE_STATEMENT_SUCCESS,
  CLEAR_UPDATE_STATEMENT,
  GET_STATEMENTS_AS_CSV_SUCCESS,
  GET_STATEMENTS_AS_CSV_FAILURE,
  GET_STATEMENTS_AS_CSV,
  CLEAR_GET_STATEMENTS_AS_CSV
} from '../actions/statements'
import { INITIAL_GET_RESULTS_REDUCER, INITIAL_UPDATE_REDUCER, INITIAL_GET_REDUCER } from '../../constants/reducer'
import { updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  currentStatement: INITIAL_GET_REDUCER,
  csvStatement: INITIAL_GET_REDUCER,
  updateStatement: INITIAL_UPDATE_REDUCER,
  statements: INITIAL_GET_RESULTS_REDUCER
}

export default function reports(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get current statement
    case GET_CURRENT_STATEMENT:
      return update(state, {
        currentStatement: { $merge: { isLoading: true } }
      })
    case GET_CURRENT_STATEMENT_SUCCESS:
      return update(state, {
        currentStatement: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        }
      })
    case GET_CURRENT_STATEMENT_FAILURE:
      return update(state, {
        currentStatement: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_CURRENT_STATEMENT:
      return update(state, {
        currentStatement: { $set: initialState.currentStatement }
      })

    // Get statements as csv
    case GET_STATEMENTS_AS_CSV:
      return update(state, {
        csvStatement: { $merge: { isLoading: true } }
      })
    case GET_STATEMENTS_AS_CSV_SUCCESS:
      return update(state, {
        csvStatement: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data,
            fileName: action.fileName
          }
        }
      })
    case GET_STATEMENTS_AS_CSV_FAILURE:
      return update(state, {
        csvStatement: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_STATEMENTS_AS_CSV:
      return update(state, {
        csvStatement: { $set: initialState.csvStatement }
      })

    // Update statement
    case UPDATE_STATEMENT:
      return update(state, {
        updateStatement: { $merge: { isLoading: true, id: action.id } }
      })
    case UPDATE_STATEMENT_SUCCESS:
      return update(state, {
        updateStatement: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data
          }
        },
        statements: {
          results: { $apply: items => updateItem(items, action.data, action.id) }
        }
      })
    case UPDATE_STATEMENT_FAILURE:
      return update(state, {
        updateStatement: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_STATEMENT:
      return update(state, {
        updateStatement: { $set: initialState.updateStatement }
      })

    // Get statements
    case GET_STATEMENTS:
      return update(state, {
        statements: { $merge: { isLoading: true } }
      })
    case GET_STATEMENTS_SUCCESS:
      return update(state, {
        statements: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            next: action.data.next,
            results: action.data.results
          }
        }
      })
    case GET_STATEMENTS_FAILURE:
      return update(state, {
        statements: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_STATEMENTS:
      return update(state, {
        statements: { $set: initialState.statements }
      })

    default:
      return state
  }
}

import { createStyles } from '../../../../../../../../../styles/helpers'

const useStyles = createStyles({
  reachContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'right'
  },
  totalItem: {
    textAlign: 'right'
  },
  titles: {
    flexGrow: 1,
    marginRight: 12
  }
})

export default useStyles

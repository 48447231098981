import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import { formatNumber } from '../../../../../../../../../../helpers/numbers'

const ReachValue = ({ value, isLoading, skeletonWidth = 46 }) => {
  return <div>{isLoading ? <Skeleton width={skeletonWidth} /> : formatNumber(value, { max: 2 })}</div>
}

ReachValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  skeletonWidth: PropTypes.number
}

export default ReachValue

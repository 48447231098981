import { NAME, STORES } from '../../fields'
import { formatOptionsList } from '../../../../../../features/formatters'

export const getInitialValues = footfallData => {
  const formattedMediaStores = formatOptionsList({
    list: footfallData.media_stores,
    valueName: 'id',
    labelName: 'name'
  })

  return {
    [NAME]: footfallData[NAME] || '',
    [STORES]: formattedMediaStores || []
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import SortableUploadImagesList from '../../../../../../../features/components/Form/SortableUploadImagesList'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const ImagesSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Image')}</h3>
      <FieldRow
        title="Image file"
        description={
          <>
            <p>{t('File type: png (transparent)')}</p>
            <p>{t('Dimensions: 600x600 px')}</p>
            <p>{t('Max file size: 200kb')}</p>
          </>
        }
      >
        <SortableUploadImagesList formik={formik} isInDrawer={true} />
      </FieldRow>
    </section>
  )
}

export default ImagesSection

import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ActionText from '../../../../../../components/ActionText'
import AccountField from './AccountField'
import BrandField from './BrandField'
import CampaignNameField from './CampaignNameField'
import BuyerField from './BuyerField'
import VariableField from './VariableField'

import { useRequiredFields } from '../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'
import { useBrandsOptions } from './BrandField/hooks'

import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import { BRAND, VARIABLES } from '../../fields'

import useStyles from './styles'

const ContractFields = ({ formik, isEditContract, isEditMode, handleStartEditContract, handleSaveContract }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const brandsOptions = useBrandsOptions()

  const { setFieldValue, values, errors } = formik

  const hasNotValidProducts = useMemo(() => {
    return Boolean(errors.products)
  }, [errors.products])

  const { askBrandName, askCampaignName, isBrandRequired, askBuyer, preselectFirstBrand } =
    useRequiredFields(contextSelfAccountData)
  // If there is at least one brand name, show a dropdown and require the user to select one
  const showBrandName = askBrandName && isBrandRequired

  useEffect(() => {
    if (preselectFirstBrand) {
      // if there is only one brand, dont ask the question and just add it automatically
      setFieldValue(BRAND, brandsOptions[0].value)
    }
  }, [setFieldValue, preselectFirstBrand, brandsOptions])

  return (
    <div className={classes.mediaOrderFieldsWrapper}>
      <ActionText
        // if product row is added, but product itself is not selected or period is not selected
        // then product is not valid, therefore we don't allow to edit additional contract fields
        // because we still have product related errors
        isDisabled={hasNotValidProducts}
        onClick={isEditMode ? handleSaveContract : handleStartEditContract}
        className={classnames(classes.editModeToggleButton, {
          [classes.disabledEditModeToggleButton]: hasNotValidProducts
        })}
      >
        {isEditMode ? t('Done') : t('Edit')}
      </ActionText>
      <div className={classes.mediaOrderFields}>
        <AccountField formik={formik} isEditContract={isEditContract} isEditMode={isEditMode} />
        {showBrandName && <BrandField formik={formik} isEditMode={isEditMode} />}
        {askCampaignName && <CampaignNameField formik={formik} isEditMode={isEditMode} />}
        {askBuyer && <BuyerField formik={formik} isEditMode={isEditMode} />}
        {values[VARIABLES].map((variable, index) => (
          <VariableField
            key={variable.data.id}
            formik={formik}
            isEditMode={isEditMode}
            variable={variable}
            index={index}
          />
        ))}
      </div>
    </div>
  )
}

ContractFields.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditContract: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleStartEditContract: PropTypes.func.isRequired,
  handleSaveContract: PropTypes.func.isRequired
}

export default ContractFields

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Dropdown from '../../../../../../../../components/Dropdown'
import Chip from '../../../../../../../../components/Chip'

import { useGetApprovalOptions } from '../../hooks'

import { insertIf } from '../../../../../../../../helpers/common'
import {
  BUYER_DISAPPROVED,
  DISAPPROVED,
  getApprovalFileStatus,
  PENDING_APPROVAL
} from '../../../../../../../../features/components/UploadedFilesField/helpers'

import { CHIP_COLORS } from '../../../../../../../../constants/other'

import useStyles from '../../../../../../../../features/components/StatusUpdateDropdown/styles'

const FileStatusChipDropdown = ({
  fileStatus,
  uploadedFile,
  requirementFileId,
  handleShowDisapprovalModal,
  handleStatusChange
}) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { color, text } = getApprovalFileStatus(fileStatus, CHIP_COLORS.yellow)

  const getApprovalOptions = useGetApprovalOptions({ handleShowDisapprovalModal, handleStatusChange })

  const fileStatusOptions = useMemo(() => {
    return [
      ...insertIf(fileStatus !== PENDING_APPROVAL, {
        text: t('Pending Approval'),
        status: PENDING_APPROVAL
      }),

      ...getApprovalOptions({
        uploadedFile,
        requirementFileId
      })
    ]
  }, [t, fileStatus, getApprovalOptions, requirementFileId, uploadedFile])

  const updateFileStatusHandler = useCallback(
    status => {
      if (status === DISAPPROVED || status === BUYER_DISAPPROVED) {
        handleShowDisapprovalModal(requirementFileId, uploadedFile)
      } else {
        handleStatusChange({ status, uploadedFile, requirementFileId })
      }
    },
    [handleShowDisapprovalModal, handleStatusChange, uploadedFile, requirementFileId]
  )

  return (
    <Dropdown closeOnInsideClick placement="bottom-start" triggerElement={<Chip text={text} color={color} />}>
      <div className={classes.container}>
        {fileStatusOptions.map(fileStatusOption => {
          const color =
            fileStatusOption.text === 'Approve'
              ? CHIP_COLORS.green
              : fileStatusOption.text === 'Disapprove'
                ? CHIP_COLORS.red
                : CHIP_COLORS.grey

          return (
            <Chip
              key={fileStatusOption.status}
              isHoverable
              text={fileStatusOption.text}
              color={color}
              onClick={() => updateFileStatusHandler(fileStatusOption.status)}
            />
          )
        })}
      </div>
    </Dropdown>
  )
}

FileStatusChipDropdown.propTypes = {
  fileStatus: PropTypes.string,
  uploadedFile: PropTypes.object,
  requirementFileId: PropTypes.number,
  handleShowModal: PropTypes.func
}

export default FileStatusChipDropdown

import { createStyles } from '../../../styles/helpers'
import { darkGrey, textGrey } from '../../../styles/const/colors'
import { lineHeight } from '../../../styles/typography'

const useStyles = createStyles({
  option: {
    display: 'flex',
    alignItems: 'center'
  },
  selectOptionCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    marginRight: 7,
    width: 18,
    height: 18,

    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: 'auto'
    },
    '& img': {
      maxWidth: '100%'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: lineHeight,
    // avoid menu to go out of view or cut when options are long
    wordBreak: 'break-word',
    // props object could be missed
    color: props => (props?.labelColor ? props.labelColor : textGrey)
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'not-allowed'
  },
  description: {
    color: darkGrey,
    fontSize: 12,
    lineHeight: lineHeight,
    fontWeight: 400
  },
  optionTag: {
    marginLeft: 'auto !important',
    paddingLeft: '3px'
  },
  tag: {
    marginRight: 0
  },
  tags: {
    display: 'flex',
    gap: 6,
    marginTop: 4
  }
})

export default useStyles

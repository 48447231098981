import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Periods from './Periods'
import CompetingQuotations from '../CompetingQuotations'

import { generateGroupLabel } from '../helpers'

import { isPeriodsInventoryValid } from '../../../helpers/periods'

import { AVAILABLE_QUANTITY } from '../EditPeriods/DatePickerPeriodSelector/hooks/useInventoryPeriods'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES, PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../../fields'

import useStyles from '../styles'

const Preview = ({ productValues, checkInventory, isAmendment }) => {
  const classes = useStyles()

  const productData = productValues.data
  const selectedQuantity = productValues[QUANTITY]
  const selectedPeriods = productValues[PRODUCT_PERIODS_DATES]
  const selectedPeriodsGroups = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]

  const competingPeriods = useMemo(() => {
    if (selectedPeriods) {
      return selectedPeriods.filter(period => {
        const competingQuantity = period.competing_quotation?.competing_quotation_quantity

        if (competingQuantity) {
          // if there are any inventory periods where selected quantity on proposal + sum of quantity of
          // competing quotations is > available inventory for that period
          return competingQuantity + selectedQuantity > period[AVAILABLE_QUANTITY]
        } else {
          return false
        }
      })
    } else {
      return []
    }
  }, [selectedPeriods, selectedQuantity])

  const hasCompetingPeriods = competingPeriods.length > 0

  return (
    <>
      <div>
        {selectedPeriodsGroups?.length ? (
          <ul className={classes.selectedDatesList}>
            {selectedPeriodsGroups.map(group => {
              const isAvailable = checkInventory ? isPeriodsInventoryValid(group, selectedQuantity) : true

              const label = generateGroupLabel(group)

              // the li element is used for selector of copy functionality in formatTableContentToSheet helper
              return (
                <li className={classnames({ [classes.isNotAvailable]: !isAvailable })} key={label}>
                  {label}
                </li>
              )
            })}
          </ul>
        ) : (
          '-'
        )}
        <Periods productValues={productValues} />
      </div>
      {!isAmendment && hasCompetingPeriods && (
        <CompetingQuotations competingPeriods={competingPeriods} productPeriod={productData.period} />
      )}
    </>
  )
}

Preview.propTypes = {
  productValues: PropTypes.object,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default Preview

import React from 'react'
import PropTypes from 'prop-types'

import NameField from '../NameField'

import useStyles from './styles'

const RequestedFileCell = ({ requirements, uploadedFile, id, mediaId, handleFileDownloading }) => {
  const classes = useStyles()

  return (
    <div>
      <NameField
        formats={requirements?.supported_file_types}
        uploadedFile={uploadedFile}
        requirementsFileName={requirements?.file_name}
        id={id}
        mediaId={mediaId}
        handleFileDownloading={handleFileDownloading}
      />
      {uploadedFile?.comment && <p className={classes.uploadedFileComment}>{uploadedFile.comment}</p>}
    </div>
  )
}

RequestedFileCell.propTypes = {
  requirements: PropTypes.object,
  uploadedFile: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleFileDownloading: PropTypes.func
}

export default RequestedFileCell

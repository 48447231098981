import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../../../../../../components/Form/Field'

import { INTERNAL_ID } from '../../../MediaProductCreate/MediaProductCreateForm/fields'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const InternalIdSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <section className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Internal ID (optional)')}</h3>
      <Field formik={formik} name={INTERNAL_ID} placeholder="Internal ID" enableReinitialize autoComplete="off" />
    </section>
  )
}

export default InternalIdSection

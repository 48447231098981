import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import InfoBlock from '../../../../../features/components/InfoBlock'
import { MobileDown, MobileUp } from '../../../../../components/hoc/ResponsiveRendering'
import AppLoader from '../../../../../components/Loaders/AppLoader'
import { BookedMediaDataPeriodsProvider } from './CalendarDesktop/BookedMediaDataPeriodsContext'
import CalendarBookedMediaFilters from './CalendarBookedMediaFilters'
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendarTimeline/icons/calendar-colorful.svg'

import { getInitialDateRange } from '../../../../../features/components/Calendar/CalendarTable'
import { formatDateToBE } from '../../../../../constants/dates'

import { getBookedMediaReportErrorSelector } from '../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { clearGetBookedMediaReport, getBookedMediaReport } from '../../../../../modules/actions/mediaOrdersBookings'

import { CALENDAR_VIEW_TYPE } from '../../../../../constants/selectLists/calendarList'

// lazy load Calendar component to reduce the initial bundle file size
const CalendarDesktop = lazy(() => import('./CalendarDesktop'))

const BookedMediaReport = () => {
  const bookedMediaReportError = useSelector(getBookedMediaReportErrorSelector)
  const selfAccountControllerId = useSelector(selectedControllerIdSelector)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [params, setParams] = useState(null)
  // find if there is params and some keys in it once empty object:
  const hasSelectedFilters = params && Object.keys(params).length
  // set date range to ref, to avoid the component re-render and handleDataFetch change, when the date range changes
  // as if handleDataFetch changes it will trigger the data fetch automatically in the CalendarBookedMediaFilters useEffect
  const dateRangeRef = useRef(getInitialDateRange(CALENDAR_VIEW_TYPE.QUARTER))

  const handleDateRangeChange = useCallback(dateRange => {
    dateRangeRef.current = dateRange
  }, [])

  const handleDataFetch = useCallback(
    ({
      account,
      representative,
      media_category,
      media_sub_category,
      media_sub_sub_category,
      media_product,
      location,
      brand_category,
      brand_subcategory,
      ...filterRequestParams
    }) => {
      const requestParams = {
        ...(account?.length && { account: account.join(',') }),
        ...(representative?.length && {
          representative: representative.join(',')
        }),
        ...(media_category?.length && { media_category: media_category.join(',') }),
        ...(media_sub_category?.length && { media_sub_category: media_sub_category.join(',') }),
        ...(media_sub_sub_category?.length && { media_sub_sub_category: media_sub_sub_category.join(',') }),
        ...(media_product?.length && { media_product: media_product.join(',') }),
        ...(location?.length && { location: location.join(',') }),
        ...(brand_category?.length && { brand_category: brand_category.join(',') }),
        ...(brand_subcategory?.length && { brand_subcategory: brand_subcategory.join(',') }),
        ...filterRequestParams
      }

      setParams(requestParams)

      // find if there is params and some keys in it once empty object:
      const hasSelectedFilters = requestParams && Object.keys(requestParams).length

      if (hasSelectedFilters) {
        // due to performance issues to get all data without filters we allow to fetch data only when some filter is
        // applied.
        const { startDate, endDate } = dateRangeRef.current
        dispatch(
          getBookedMediaReport({
            params: {
              controller: selfAccountControllerId,
              ordering: 'name',
              ...requestParams,
              date_end_after: formatDateToBE(startDate),
              date_start_before: formatDateToBE(endDate),
              group_by_location: true
            },
            loadOptions: {
              shouldClearExistingState: true
            }
          })
        )
      } else {
        // clear the data if no filters are applied
        dispatch(clearGetBookedMediaReport())
      }
    },
    [dispatch, selfAccountControllerId]
  )

  useEffect(() => {
    return () => {
      dispatch(clearGetBookedMediaReport())
    }
  }, [dispatch])

  if (bookedMediaReportError) {
    return (
      <InfoBlock title={t('Sorry, something went wrong')} centered greyDescription>
        <div>{t('Please try again later')}</div>
      </InfoBlock>
    )
  }

  return (
    <>
      <MobileUp>
        <CalendarBookedMediaFilters onFiltersChange={handleDataFetch} />
        <Suspense fallback={<AppLoader isFixed />}>
          <BookedMediaDataPeriodsProvider params={params} onDateRangeChange={handleDateRangeChange}>
            <CalendarDesktop hasSelectedFilters={hasSelectedFilters} />
          </BookedMediaDataPeriodsProvider>
        </Suspense>
      </MobileUp>
      <MobileDown>
        <InfoBlock Icon={CalendarIcon} title={t('The planner is best viewed on desktop')}>
          {t('Please use the desktop version to view your planner')}
        </InfoBlock>
      </MobileDown>
    </>
  )
}

export default BookedMediaReport

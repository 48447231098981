import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import NameCell from './NameCell'
import StatusField from './StatusField'
import AdaptiveActions from '../../components/AdaptiveActions'
import { showToasts } from '../../../helpers/toasts'

import { TOAST_TYPE } from '../../../constants/other'
import { tableColumnsSize } from '../../../pages/Settings/ManageControllerTeam/ControllerInvites/ControllerInvitesTable'

import { phonesDownSize } from '../../../styles/const/breakpoints'
import useStyles from './styles'

export const useInvitesColumns = ({ onDeleteInvite, onResendInvite }) => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { t } = useTranslation()

  const resendInvitationHandler = useCallback(
    (e, id) => {
      onResendInvite(id)
      showToasts({
        type: TOAST_TYPE.success,
        message: t('The invitation has been sent via email')
      })
    },
    [onResendInvite, t]
  )

  const removeInvitationHandler = useCallback(
    (e, id) => {
      onDeleteInvite(id)
    },
    [onDeleteInvite]
  )

  const getDropdownOptions = useCallback(
    (role, isActive, id) => {
      return [
        {
          text: t('Resend invite'),
          onClickHandler: e => resendInvitationHandler(e, id)
        },
        {
          text: t('Delete invitation'),
          onClickHandler: e => removeInvitationHandler(e, id)
        }
      ]
    },
    [t, resendInvitationHandler, removeInvitationHandler]
  )

  return useMemo(
    () => [
      {
        header: 'Name',
        Cell: ({ full_name, email }) => <NameCell email={email} name={full_name} />,
        className: classes.mobileNameHeader
      },
      {
        header: 'Status',
        Cell: ({ is_active }) => <StatusField isActive={is_active} className={classes.statusMobileField} />,
        style: { maxWidth: tableColumnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Role',
        Cell: ({ role = '' }) => role,
        style: { maxWidth: tableColumnsSize.role, textTransform: 'capitalize' },
        showOnMobile: false
      },
      {
        Cell: ({ role, is_active, id }) => (
          <AdaptiveActions itemId={id} options={getDropdownOptions(role, is_active, id)} />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [getDropdownOptions, isMobile, classes]
  )
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'

import DownloadContract from '../DownloadContract'
import Button from '../../../../../components/Button'
import Can from '../../../../../features/components/Can'
import SendCampaignReportModal from './SendCampaignReportModal'
import ResponsiveContractPdfViewer from '../../../../../features/components/Contract/ResponsiveContractPdfViewer'
import PdfPageSkeleton from '../../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'

import { getAccessibleUrl } from '../../../../../modules/actions/files'
import { fileAccessibleURLSelector } from '../../../../../modules/selectors/files'
import { getMediaOrderFilesIsLoadingSelector, mediaOrderSelector } from '../../../../../modules/selectors/mediaOrders'

import { UPLOAD_MEDIA_ORDER_FILES } from '../../../../../constants/permissions'

import useStyles from './styles'

const MediaOrderFileActions = ({ fileUrl, fileName, isReachReport, downloadButtonClassName, handleDeleteFile }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [showSendReportModal, setShowSendReportModal] = useState(false)

  // the url is private and has token to access it, often the token is already expired when user tries to open it
  // to make sure the url can be accessed, need to get accessible url
  const { [fileUrl]: accessibleUrl } = useSelector(fileAccessibleURLSelector)
  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderFilesIsLoading = useSelector(getMediaOrderFilesIsLoadingSelector)

  const { id: mediaOrderId } = mediaOrder
  const isAccessibleUrlLoading = !!accessibleUrl && accessibleUrl.isLoading

  const handleSendToRecipients = () => {
    setShowSendReportModal(true)
  }

  useEffect(() => {
    if (fileUrl) {
      dispatch(
        getAccessibleUrl({
          url: fileUrl,
          file_name: fileName
        })
      )
    }
  }, [dispatch, fileName, fileUrl])

  if (mediaOrderFilesIsLoading || isAccessibleUrlLoading) {
    return (
      <div className={classes.pdfContainer}>
        <div className={classes.actionsContainer}>
          <Can I="manage" a={UPLOAD_MEDIA_ORDER_FILES}>
            <Skeleton width="80px" height="37px" />
          </Can>
          <Skeleton width="170px" height="37px" />
        </div>
        <PdfPageSkeleton />
      </div>
    )
  }

  return (
    <div className={classes.pdfContainer}>
      <div className={classes.actionsContainer}>
        {handleDeleteFile && (
          <Can I="manage" a={UPLOAD_MEDIA_ORDER_FILES}>
            <Button onClick={handleDeleteFile} danger>
              {t('Delete')}
            </Button>
          </Can>
        )}
        {isReachReport && (
          <Can I="manage" a={UPLOAD_MEDIA_ORDER_FILES}>
            <Button onClick={handleSendToRecipients}>{t('Send to Recipients')}</Button>
            <SendCampaignReportModal
              mediaOrderId={mediaOrderId}
              isOpen={showSendReportModal}
              onClose={() => {
                setShowSendReportModal(false)
              }}
            />
          </Can>
        )}
        {/*pass accessibleUrl?.data instead of fileUrl to avoid PDF rendering issues based on loading state change*/}
        <DownloadContract
          pdfUrl={
            accessibleUrl?.data +
            // sometimes the accessible url is the same as the requested file url, to prevent the bug, add random text to the signature
            'new_contract_file'
          }
          fileName={fileName}
          className={downloadButtonClassName}
        />
      </div>
      <ResponsiveContractPdfViewer pdfFile={accessibleUrl?.data} showPagesCount={false} />
    </div>
  )
}

MediaOrderFileActions.propTypes = {
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
  isReachReport: PropTypes.bool,
  downloadButtonClassName: PropTypes.string,
  handleDeleteFile: PropTypes.func
}

export default MediaOrderFileActions

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useSearch from '../../../hooks/useSearch'
import { useLoadPaginatedMediaOrders } from '../hooks'

import { validMediaOrdersStatuses } from '../../../constants/mediaOrders'
import { selectedSelfAccountSelector } from '../../../modules/selectors/app'

import { MEDIA_ORDERS_SEARCH } from '../MediaOrdersActions'

export const MediaOrdersTableContext = React.createContext()

export const MediaOrdersTableContextProvider = ({ children }) => {
  const selectedSelfAccount = useSelector(selectedSelfAccountSelector)
  const searchTerm = useSearch(MEDIA_ORDERS_SEARCH)

  const mediaOrdersParams = useMemo(() => {
    // remove comma if it is last character, as BE crashes with it for example '1730,1729,' or '1730,1729, '
    const search = searchTerm.replace(/,\s*$/, '')

    return {
      status: validMediaOrdersStatuses.join(','),
      account: selectedSelfAccount,
      // search is actually sequential_id filter, user can type 1730 or 1730,1729, so don’t validate for only integers.
      ...(search && { sequential_id: search })
    }
  }, [searchTerm, selectedSelfAccount])
  const loadMoreMediaOrdersHandler = useLoadPaginatedMediaOrders(mediaOrdersParams)

  return (
    <MediaOrdersTableContext.Provider
      value={{
        loadMoreMediaOrdersHandler
      }}
    >
      {children}
    </MediaOrdersTableContext.Provider>
  )
}

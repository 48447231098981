import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import Field from '../../../../../../../../../components/Form/Field'

import {
  FILE_SPECIFICATION_TYPE,
  FILE_SPECIFICATION_VALUE,
  FILE_SPECIFICATIONS_PAIRS
} from '../../../../../MediaProductCreate/MediaProductCreateForm/fields'

import { specificationsList } from './specificationsList'

import useStyles from './styles'

const FileSpecificationFields = ({ formik, index, filePath, isPdfOnly }) => {
  const classes = useStyles()

  const { values } = formik

  const typeFieldName = `${filePath}.${FILE_SPECIFICATIONS_PAIRS}[${index}].${FILE_SPECIFICATION_TYPE}`
  const valueFieldName = `${filePath}.${FILE_SPECIFICATIONS_PAIRS}[${index}].${FILE_SPECIFICATION_VALUE}`

  const fileSpecifications = getIn(values, `${filePath}.${FILE_SPECIFICATIONS_PAIRS}`)

  const currentSpecificationType = getIn(values, typeFieldName)

  const selectedSpecificationTypes = useMemo(() => {
    return (
      fileSpecifications
        .map(specification => specification[FILE_SPECIFICATION_TYPE])
        // filter out last empty row
        .filter(item => !!item)
    )
  }, [fileSpecifications])

  const filteredSpecifications = useMemo(() => {
    return specificationsList.filter(
      specification =>
        // filter out specifications that are already selected
        !selectedSpecificationTypes.includes(specification.value) ||
        // if specification is selected in current row - include it in options to be able to show it in select
        specification.value === currentSpecificationType
    )
  }, [selectedSpecificationTypes, currentSpecificationType])

  const measurementUnit = useMemo(() => {
    const selectedOption = specificationsList.find(specification => specification.value === currentSpecificationType)

    if (isPdfOnly) {
      // if pdf only is selected, then we need to show mm instead of px
      if (selectedOption?.symbol === 'px') {
        return 'mm'
      } else {
        return selectedOption?.symbol
      }
    } else {
      return selectedOption?.symbol
    }
  }, [currentSpecificationType, isPdfOnly])

  return (
    <>
      <Field
        id={typeFieldName}
        type="number"
        formik={formik}
        name={typeFieldName}
        className={classes.typeField}
        placeholder=""
        autoComplete="off"
        options={filteredSpecifications}
      />
      <Field
        id={valueFieldName}
        type="number"
        formik={formik}
        className={classnames(classes.valueField, {
          [classes.valueFieldWithSymbol]: !!measurementUnit
        })}
        name={valueFieldName}
        placeholder="Value"
        autoComplete="off"
        symbol={measurementUnit}
      />
    </>
  )
}

FileSpecificationFields.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  filePath: PropTypes.string.isRequired
}

export default FileSpecificationFields

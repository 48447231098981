import { format } from 'date-fns'
import { DATES_FORMAT_FULL_YEAR } from '../../../../../../../../constants/dates'

export const generateGroupLabel = group => {
  const firstPeriodDate = group[0]?.date_start
  const lastPeriodDate = group[group.length - 1]?.date_end
  const label = `${format(firstPeriodDate, DATES_FORMAT_FULL_YEAR)} - ${format(lastPeriodDate, DATES_FORMAT_FULL_YEAR)}`

  return label
}

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const VariableOptionsListSkeleton = () => {
  return (
    <>
      <Skeleton count={3} style={{ marginBottom: 8 }} height={80} />
    </>
  )
}

export default VariableOptionsListSkeleton

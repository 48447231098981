import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import AdaptiveActions from '../../../../../../../../features/components/AdaptiveActions'

import { useOpenMediaOrderSummaryInNewTab } from '../hooks'
import * as Sentry from '@sentry/react'

const ActionsCell = ({ id, mediaOrderId, accountId }) => {
  if (!accountId) {
    const selfAccountMissingError = new Error('Installation report Action cell self account id is missing')
    Sentry.captureException(selfAccountMissingError, {
      extra: {
        id,
        mediaOrderId,
        accountId
      }
    })
  }
  const goToMediaOrderSummaryHandler = useOpenMediaOrderSummaryInNewTab({ mediaOrderId, accountId })

  const options = useMemo(
    () => [
      {
        text: 'Go to media booking',
        onClickHandler: goToMediaOrderSummaryHandler
      }
    ],
    [goToMediaOrderSummaryHandler]
  )

  return <AdaptiveActions itemId={id} options={options} />
}

ActionsCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ActionsCell

import { calc } from '../../../../../../../../helpers/numbers'
import { CUSTOM_PRODUCT_DISCOUNT, CUSTOM_PRODUCT_PRICE, PRODUCT_PERIODS_DATES, QUANTITY } from '../../../../fields'
import { getDecimalDiscount } from '../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'
import calculateProductPrice from '../../helpers/calculateProductPrice'

export const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
  if (originalPrice === 0) return 0
  return calc(originalPrice).minus(discountedPrice).div(originalPrice).mul(100).toDP(0).toNumber()
}

export const getDiscountLabel = productValues => {
  const periods = productValues[PRODUCT_PERIODS_DATES]
  const quantity = productValues[QUANTITY]
  const customPrice = productValues[CUSTOM_PRODUCT_PRICE]
  const discount = productValues[CUSTOM_PRODUCT_DISCOUNT]
  const formattedDiscount = getDecimalDiscount(discount)

  const calculatedPrice = calculateProductPrice(periods, quantity, formattedDiscount)
  const originalPrice = calculatedPrice.totalPrice
  const wasCustomPriceSet = customPrice !== originalPrice

  return {
    wasCustomPriceSet,
    // find the discount percentage amount between calculatedPrice and originalPrice:
    label: wasCustomPriceSet ? calculateDiscountPercentage(originalPrice, customPrice) : discount || 0
  }
}

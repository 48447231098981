import React from 'react'
import PropTypes from 'prop-types'

import ContractFieldWrapper from '../ContractFieldWrapper'
import Field from '../../../../../../../components/Form/Field'

import { CAMPAIGN_NAME } from '../../../fields'

const CampaignNameField = ({ formik, isEditMode }) => {
  const { values, errors, touched } = formik

  const campaignName = values[CAMPAIGN_NAME]
  const error = errors[CAMPAIGN_NAME]
  const fieldTouched = touched[CAMPAIGN_NAME]

  return (
    <ContractFieldWrapper
      label="Campaign name"
      value={campaignName}
      maxWidth={'300px'}
      isEditMode={isEditMode}
      preview={campaignName}
      error={error}
      fieldTouched={fieldTouched}
    >
      <Field formik={formik} name={CAMPAIGN_NAME} placeholder="Campaign name" />
    </ContractFieldWrapper>
  )
}

CampaignNameField.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired
}

export default CampaignNameField

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import PropTypes from 'prop-types'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { mapListForQuery } from '../../../../../../../helpers/common'

import {
  isControllerBuyerApprovalSelector,
  selectedControllerDataSelector
} from '../../../../../../../modules/selectors/app'

import { formatDateToBE } from '../../../../../../../constants/dates'
import {
  filesApprovalStatusOptions,
  STATUS_FILTER_AWAITING_FILES,
  STATUS_FILTER_FILES_OVERDUE,
  STATUS_FILTER_FILES_PENDING_REVIEW
} from '../../../../../../../features/components/Filters/BookedMediaStatusFilter/options'
import { bookedMediaStatuses } from '../../../../../../../constants/bookedMedia'
import { PENDING_BUYER_APPROVAL } from '../../../../../../../features/components/UploadedFilesField/helpers'
import { MEDIA_SUB_CATEGORIES } from '../../../../../../Settings/ManageControllerTeam/ControllerInviteCreate/ControllerInviteCreateForm/fields'
import { ASSET_MANAGER, BUYER, INSTALLER } from '../../../../../../../constants/permissions'
import { SELECTED_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { SELECTED_SUB_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import { BRAND_SUB_CATEGORY } from '../../../../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'
import { BRAND_CATEGORY } from '../../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import { SELECTED_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import { SELECTED_FILE_STATUSES } from '../../../../../../../features/components/DropdownFilters/components/FileStatusesFilter'
import { SELECTED_LOCATIONS } from '../../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import { SELECTED_PRODUCTS } from '../../../../../../../features/components/DropdownFilters/components/ProductsFilter'
import { SELECTED_STATUSES } from '../../../../../../../features/components/DropdownFilters/components/StatusesFilter'
import { DATE_RANGE_FILTER } from '../../../../../../../features/components/DropdownFilters/components/DateFilter'
import { LOCATION_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/LocationCategoriesFilter'
import { LOCATION_REGIONS } from '../../../../../../../features/components/DropdownFilters/components/LocationRegionsFilter'
import { MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER } from '../../../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'

export const InstallationReportFiltersContext = React.createContext()

const nowDate = new Date()

const installerRoleDefaultBookedMediaStatuses = [
  bookedMediaStatuses.awaiting_set_up,
  bookedMediaStatuses.creative_updated,
  bookedMediaStatuses.scheduled,
  bookedMediaStatuses.remove_creative,
  bookedMediaStatuses.pause_request,
  bookedMediaStatuses.paused
]

const resetValues = {
  [DATE_RANGE_FILTER]: {
    startDate: nowDate,
    endDate: addDays(nowDate, 30)
  }
}

export function InstallationReportDataProvider({ children, onFiltersChange }) {
  const dispatch = useDispatch()

  const isControllerBuyerApproval = useSelector(isControllerBuyerApprovalSelector)
  const {
    category: selectedControllerCategory,
    role: selectedControllerRole,
    [MEDIA_SUB_CATEGORIES]: selectedControllerMediaSubCategories
  } = useSelector(selectedControllerDataSelector)

  const formattedSelectedControllerCategory = useMemo(() => {
    return {
      value: selectedControllerCategory?.id,
      label: selectedControllerCategory?.name
    }
  }, [selectedControllerCategory])

  const formattedSelectedControllerMediaSubCategories = useMemo(() => {
    return formatOptionsList({
      list: selectedControllerMediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selectedControllerMediaSubCategories])

  const [filtersState, setFiltersState] = useState({
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: [],
    [SELECTED_LOCATIONS]: [],
    [LOCATION_CATEGORIES]: [],
    [LOCATION_REGIONS]: [],
    [SELECTED_PRODUCTS]: [],
    [SELECTED_CATEGORIES]: [],
    [SELECTED_SUB_CATEGORIES]:
      selectedControllerRole === ASSET_MANAGER || selectedControllerRole === INSTALLER
        ? formattedSelectedControllerMediaSubCategories
        : [],
    [SELECTED_SUB_SUB_CATEGORIES]: [],
    [SELECTED_FILE_STATUSES]:
      // if controller is buyer approval then file statuses filter has preselected value PENDING_BUYER_APPROVAL
      isControllerBuyerApproval && selectedControllerRole === BUYER
        ? [
            {
              value: PENDING_BUYER_APPROVAL,
              label: 'Pending Buyer Approval'
            }
          ]
        : [],
    [SELECTED_STATUSES]: selectedControllerRole === INSTALLER ? installerRoleDefaultBookedMediaStatuses : [],
    [BRAND_CATEGORY]: selectedControllerCategory ? [formattedSelectedControllerCategory] : [],
    [BRAND_SUB_CATEGORY]: [],
    [DATE_RANGE_FILTER]: {
      startDate: nowDate,
      endDate: addDays(nowDate, 30)
    }
  })

  const {
    [MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER]: multipleAccountFilterOption,
    [SELECTED_LOCATIONS]: selectedLocations,
    [LOCATION_CATEGORIES]: locationCategories,
    [LOCATION_REGIONS]: locationRegions,
    [SELECTED_PRODUCTS]: selectedProducts,
    [SELECTED_CATEGORIES]: selectedCategories,
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [SELECTED_SUB_SUB_CATEGORIES]: selectedSubSubCategories,
    [SELECTED_FILE_STATUSES]: selectedFileStatuses,
    [SELECTED_STATUSES]: selectedStatuses,
    [BRAND_CATEGORY]: brandCategory,
    [BRAND_SUB_CATEGORY]: brandSubCategory,
    [DATE_RANGE_FILTER]: dateRangeFilter
  } = filtersState

  const filterRequestParams = useMemo(() => {
    const filesApprovalStatusOptionsValues = filesApprovalStatusOptions.map(option => option.value)
    const selectedFilesApprovalStatuses = mapListForQuery(selectedFileStatuses, 'value').filter(status =>
      filesApprovalStatusOptionsValues.includes(status)
    )

    return {
      ...(multipleAccountFilterOption?.length && {
        account: multipleAccountFilterOption.map(option => option.value).join(',')
      }),
      date_start_after: formatDateToBE(dateRangeFilter.startDate),
      date_start_before: formatDateToBE(dateRangeFilter.endDate),
      ...(selectedLocations.length && { location: mapListForQuery(selectedLocations).join(',') }),
      ...(locationCategories.length && { location_category: mapListForQuery(locationCategories).join(',') }),
      ...(locationRegions.length && { location_region: mapListForQuery(locationRegions).join(',') }),
      ...(selectedProducts.length && { media_product: mapListForQuery(selectedProducts).join(',') }),
      ...(selectedCategories.length && { media_category: mapListForQuery(selectedCategories, 'value').join(',') }),
      ...(selectedSubCategories.length && {
        media_sub_category: mapListForQuery(selectedSubCategories, 'value').join(',')
      }),
      ...(selectedSubSubCategories.length && {
        media_sub_sub_category: mapListForQuery(selectedSubSubCategories).join(',')
      }),
      // file statuses
      ...(mapListForQuery(selectedFileStatuses, 'value').includes(STATUS_FILTER_AWAITING_FILES) && {
        file_pending: true
      }),
      ...(mapListForQuery(selectedFileStatuses, 'value').includes(STATUS_FILTER_FILES_PENDING_REVIEW) && {
        files_pending_review: true
      }),
      ...(mapListForQuery(selectedFileStatuses, 'value').includes(STATUS_FILTER_FILES_OVERDUE) && {
        files_overdue: true
      }),
      // files approval statuses
      ...(selectedFilesApprovalStatuses.length && { files_approval_status: selectedFilesApprovalStatuses.join(',') }),
      // statuses
      ...(selectedStatuses.length && { status: mapListForQuery(selectedStatuses, 'value').join(',') }),
      // brand category
      ...(brandCategory.length && { brand_category: mapListForQuery(brandCategory, 'value').join(',') }),
      // brand sub category
      ...(brandSubCategory.length && { brand_subcategory: mapListForQuery(brandSubCategory).join(',') })
    }
  }, [
    multipleAccountFilterOption,
    dateRangeFilter,
    selectedLocations,
    locationCategories,
    locationRegions,
    selectedProducts,
    selectedCategories,
    selectedFileStatuses,
    selectedStatuses,
    selectedSubCategories,
    selectedSubSubCategories,
    brandCategory,
    brandSubCategory
  ])

  const installationReportFilterProps = useMemo(
    () => ({
      filterRequestParams,
      filtersState,
      setFiltersState,
      resetValues
    }),
    [filterRequestParams, filtersState, setFiltersState]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, installationReportFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [dispatch, filterRequestParams, onFiltersChange])

  return (
    <InstallationReportFiltersContext.Provider value={installationReportFilterProps}>
      {children}
    </InstallationReportFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  date_start_after: PropTypes.string.isRequired,
  date_start_before: PropTypes.string.isRequired,
  location: PropTypes.string,
  media_product: PropTypes.string,
  media_category: PropTypes.string,
  media_sub_category: PropTypes.string,
  media_sub_sub_category: PropTypes.string,
  file_pending: PropTypes.bool,
  files_pending_review: PropTypes.bool,
  files_overdue: PropTypes.bool,
  files_approval_status: PropTypes.string,
  status: PropTypes.string,
  brand_category: PropTypes.string,
  brand_subcategory: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  filtersState: PropTypes.shape({
    [SELECTED_LOCATIONS]: PropTypes.array,
    [SELECTED_PRODUCTS]: PropTypes.array,
    [SELECTED_CATEGORIES]: PropTypes.array,
    [SELECTED_SUB_CATEGORIES]: PropTypes.array,
    [SELECTED_SUB_SUB_CATEGORIES]: PropTypes.array,
    [SELECTED_FILE_STATUSES]: PropTypes.array,
    [SELECTED_STATUSES]: PropTypes.array,
    [BRAND_CATEGORY]: PropTypes.array,
    [BRAND_SUB_CATEGORY]: PropTypes.array,
    [DATE_RANGE_FILTER]: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date)
    })
  }).isRequired,
  setFiltersState: PropTypes.func.isRequired,
  resetValues: PropTypes.shape({
    [DATE_RANGE_FILTER]: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date)
    })
  }).isRequired
}

import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import TableRowWrapper from './TableRowWrapper'
import SkeletonTableRow from './TableRowWrapper/Skeleton'
import ConditionalWrapper from '../../hoc/ConditionalWrapper'
import DraggableItemWrapper from '../../../features/components/DragAndDropWrapper/DraggableItemWrapper'

import { phonesDownSize } from '../../../styles/const/breakpoints'
import useTableStyles from '../styles'
import useStyles from './styles'

const TableRows = React.memo(
  ({
    colsTemplate,
    rowTemplate,
    data,
    itemUpdatingId,
    updatingIds,
    isBigRow,
    onRowDrag,
    customColumns,
    hasMobileHiddenColumns,
    mobileColumnsWrapperClassName,
    mobileMinimisedSectionBtnWrapperClassName,
    hasExpandRowSpacing,
    rowLoadingIds,
    expandableRowsOpenStatesContext
  }) => {
    const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

    const classes = useStyles(rowTemplate?.markerColor)
    const tableClasses = useTableStyles({ isBigRow, isMobile, hasMobileHiddenColumns })

    return data.map((tanstackRow, rowIndex) => {
      const rowData = tanstackRow.original
      const isRowUpdating = (itemUpdatingId && itemUpdatingId === rowData.id) || updatingIds?.includes(rowData.id)
      const key = rowData.id || uuidv4()

      // when there is customColumns check if there is key of customColumns in rowData
      const customColumnKey = customColumns && Object.keys(customColumns).find(key => rowData[key])
      // determine if the row should render custom columns layout - if rowData has key of customColumns
      const columnsTemplate = customColumnKey ? customColumns[customColumnKey] : colsTemplate
      const isExpandable = rowTemplate?.params?.isExpandable(rowData)
      const rowClassName = rowTemplate?.rowClassName
      const getRowClassName = rowTemplate?.getRowClassName
      const getMobileRowClassName = rowTemplate?.getMobileRowClassName
      const hasMarker = rowTemplate?.determineRowMarker?.(rowData)
      const columnClassName = rowTemplate?.columnClassName

      const dynamicRowClassName = getRowClassName && getRowClassName(rowData)
      const dynamicMobileRowClassName = getMobileRowClassName && getMobileRowClassName(rowData)

      return isRowUpdating ? (
        <SkeletonTableRow cols={colsTemplate} key={key} />
      ) : (
        <ConditionalWrapper
          key={key}
          condition={
            (!!onRowDrag &&
              // additional check, in some tables we need to disable drag and drop for some rows
              // in this case we add forbidDragAndDrop to rowData
              rowData.forbidDragAndDrop === undefined) ||
            rowData.forbidDragAndDrop === false
          }
          wrapper={children => (
            <DraggableItemWrapper className={tableClasses.draggableRow} id={rowData.id || uuidv4()} index={rowIndex}>
              {children}
            </DraggableItemWrapper>
          )}
        >
          <TableRowWrapper
            isExpandable={isExpandable}
            renderExpandedContent={rowTemplate?.params?.renderExpandedContent}
            mobileMinimisedSectionBtnWrapperClassName={mobileMinimisedSectionBtnWrapperClassName}
            mobileColumnsWrapperClassName={mobileColumnsWrapperClassName}
            key={key}
            rowData={rowData}
            cols={columnsTemplate}
            rowIndex={rowIndex}
            isBigRow={isBigRow}
            hasMobileHiddenColumns={hasMobileHiddenColumns}
            hasExpandRowSpacing={hasExpandRowSpacing}
            rowClassName={classnames(rowClassName, dynamicRowClassName, {
              [classes.markedRow]: hasMarker
            })}
            mobileRowClassName={classnames(dynamicMobileRowClassName, {
              [classes.markedRow]: hasMarker
            })}
            columnClassName={columnClassName}
            isRowLoading={rowLoadingIds?.includes(rowData.id)}
            expandableRowsOpenStatesContext={expandableRowsOpenStatesContext}
          />
        </ConditionalWrapper>
      )
    })
  }
)

const colsPropTypeShape = {
  showOnMobile: PropTypes.bool,
  // header
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
  headClassName: PropTypes.string,
  // main cell
  Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  fieldKey: PropTypes.string,
  className: PropTypes.string,
  // footer
  footerClassName: PropTypes.string
}

TableRows.propTypes = {
  data: PropTypes.array.isRequired,
  hasMobileHiddenColumns: PropTypes.bool,
  colsTemplate: PropTypes.arrayOf(PropTypes.shape(colsPropTypeShape)).isRequired,
  rowTemplate: PropTypes.shape({
    params: PropTypes.shape({
      isExpandable: PropTypes.func,
      renderExpandedContent: PropTypes.func
    }),
    markerColor: PropTypes.string,
    determineRowMarker: PropTypes.func,
    rowClassName: PropTypes.string,
    getRowClassName: PropTypes.func,
    getMobileRowClassName: PropTypes.func
  }),
  hideHeadlineRow: PropTypes.bool,
  hideFooterRow: PropTypes.bool,
  SubHeadlineRow: PropTypes.node,
  // is an object of available custom columns layouts
  // allows to render row with custom columns if rowData has key of customColumns
  customColumns: PropTypes.object,
  tableId: PropTypes.string,
  // updating, deleting and etc..
  onRowDrag: PropTypes.func, // allows to change rows order - adds drag and drop functionality
  itemUpdatingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBigRow: PropTypes.bool,
  Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  mobileColumnsWrapperClassName: PropTypes.string,
  mobileMinimisedSectionBtnWrapperClassName: PropTypes.string,
  hasExpandRowSpacing: PropTypes.bool,
  rowLoadingIds: PropTypes.array
}

export default TableRows

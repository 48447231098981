import store from '../../store'
import * as Sentry from '@sentry/react'

import { currentUserIdSelector } from '../../modules/selectors/app'

export const captureErrorToSentry = ({ message, status, method, otherProps, data, params, error, toast }) => {
  const userId = currentUserIdSelector(store.getState())

  // skip network errors to avoid spam of errors
  const isNetworkError = error?.code === 'ERR_NETWORK'
  // skip aborted Requests(when X clicked in browser during loading) by user
  const isAbortedRequest = error?.message === 'Request aborted'
  // skip requests with 401 status when user auth is not set
  const isAuthError = status === 401
  // skip token expiration errors
  const isTokenExpired = error?.message === 'Your session has expired. Please log in again.'
  // skip contract periods validation errors as they are possible to be validate on BE only
  const isContractPeriodsValidation = toast?.toastMessage?.includes('Sorry, you must book at least')
  const skipErrorsTracking =
    isNetworkError || isTokenExpired || isAuthError || isAbortedRequest || isContractPeriodsValidation

  if (!skipErrorsTracking) {
    // throw all api errors
    const newError = new Error(`${message}, user - ${userId}`)
    newError.name = `Request failed - API: ${otherProps.url}, App - ${window.location.hostname}`
    Sentry.captureException(newError, {
      user: {
        id: userId
      },
      extra: {
        method,
        status,
        url: otherProps.url,
        otherProps,
        dataPayload: JSON.stringify(data, null, 2),
        paramsPayload: params,
        error: error,
        response: error.response,
        responseData: JSON.stringify(error.response?.data, null, 2),
        toast
      }
    })
  }
}

export const captureBulkUpdateBookingMediaFileError = ({ sentryData, error }) => {
  const userId = currentUserIdSelector(store.getState())

  const data = {
    ...sentryData,
    error
  }

  Sentry.captureException('Error bulk updating booking media file', {
    user: {
      id: userId
    },
    extra: {
      data: JSON.stringify(data, null, 2)
    }
  })
}

export const captureDownloadFileOnInstallationReportError = ({ sentryData, error }) => {
  const userId = currentUserIdSelector(store.getState())

  const data = {
    ...sentryData,
    error
  }

  Sentry.captureException('Error downloading file on installation report', {
    user: {
      id: userId
    },
    extra: {
      data: JSON.stringify(data, null, 2)
    }
  })
}

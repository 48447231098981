import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useMediaQuery } from 'react-responsive'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'

import MediaTagsTableData from './MediaTagsTableData'

import { clearGetProductTags, getProductTags } from '../../../../../modules/actions/tags'
import {
  productTagsErrorSelector,
  productTagsIsLoadingSelector,
  productTagsWasLoadedSelector
} from '../../../../../modules/selectors/tags'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { phonesDownSize } from '../../../../../styles/const/breakpoints'
import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const spaceForMargin = 5

const mobileColumnsSchema = [
  { style: { maxWidth: `calc(${tableColumnsSize.dragBtn}px - ${spaceForMargin}px)`, marginInlineEnd: spaceForMargin } },
  { style: { maxWidth: '100%' } }
]

const MediaTagsTable = ({ tags, handleDragEnd }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const controllerId = useSelector(selectedControllerIdSelector)

  const tagColumnsSchema = useMemo(
    () => [
      {
        fieldKey: 'title'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no tags')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  useEffect(() => {
    dispatch(getProductTags({ controller: controllerId }))
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetProductTags())
    }
  }, [dispatch])

  return (
    <TableDataLoader
      itemsLength={tags.length}
      errorSelector={productTagsErrorSelector}
      wasLoadedSelector={productTagsWasLoadedSelector}
      isLoadingSelector={productTagsIsLoadingSelector}
      skeletonProps={{ cols: isMobile ? mobileColumnsSchema : tagColumnsSchema }}
      noDataContent={noDataContent}
    >
      <MediaTagsTableData tags={tags} onOrderChange={handleDragEnd} />
    </TableDataLoader>
  )
}

MediaTagsTable.propTypes = {
  tags: PropTypes.array.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}

export default MediaTagsTable

import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDERS_BOOKINGS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_BOOKED_REVENUE_REPORT = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT')
export const GET_BOOKED_REVENUE_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT_SUCCESS')
export const GET_BOOKED_REVENUE_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_REVENUE_REPORT_FAILURE')
export const CLEAR_GET_BOOKED_REVENUE_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_REVENUE_REPORT')

export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV')
export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS')
export const GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE')
export const CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV')

export const UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE')
export const UPDATE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE_SUCCESS')
export const UPDATE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'UPDATE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_UPDATE_BOOKING_MEDIA_FILE')

export const GET_BOOKING_MEDIA_FILES = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES')
export const GET_BOOKING_MEDIA_FILES_SUCCESS = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES_SUCCESS')
export const GET_BOOKING_MEDIA_FILES_FAILURE = concat(MODULE_NAME, 'GET_BOOKING_MEDIA_FILES_FAILURE')
export const CLEAR_GET_BOOKING_MEDIA_FILES = concat(MODULE_NAME, 'CLEAR_GET_BOOKING_MEDIA_FILES')

export const DELETE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE')
export const DELETE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE_SUCCESS')
export const DELETE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'DELETE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_DELETE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_DELETE_BOOKING_MEDIA_FILE')

export const UPLOAD_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE')
export const UPLOAD_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE_SUCCESS')
export const UPLOAD_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'UPLOAD_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_UPLOAD_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_UPLOAD_BOOKING_MEDIA_FILE')

export const GET_ORDER_BOOKED_MEDIA = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA')
export const GET_ORDER_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA_SUCCESS')
export const GET_ORDER_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'GET_ORDER_BOOKED_MEDIA_FAILURE')
export const CLEAR_GET_ORDER_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_GET_ORDER_BOOKED_MEDIA')

export const GET_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT')
export const GET_BOOKED_MEDIA_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT_SUCCESS')
export const GET_BOOKED_MEDIA_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_REPORT_FAILURE')
export const CLEAR_GET_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_MEDIA_REPORT')

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT = concat(MODULE_NAME, 'GET_INSTALLATION_BOOKED_MEDIA_REPORT')
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS'
)
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE'
)
export const CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT = concat(
  MODULE_NAME,
  'CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT'
)

// get installation report as csv
export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV'
)

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS'
)

export const GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE = concat(
  MODULE_NAME,
  'GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE'
)

export const CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV = concat(
  MODULE_NAME,
  'CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV'
)

export const BULK_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE')
export const BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS')
export const BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE')
export const CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE = concat(MODULE_NAME, 'CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE')

export const BULK_UPDATE_BOOKED_MEDIA = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA')
export const BULK_UPDATE_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA_SUCCESS')
export const BULK_UPDATE_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'BULK_UPDATE_BOOKED_MEDIA_FAILURE')
export const CLEAR_BULK_UPDATE_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_BULK_UPDATE_BOOKED_MEDIA')

export const GET_BOOKED_MEDIA = concat(MODULE_NAME, 'GET_BOOKED_MEDIA')
export const GET_BOOKED_MEDIA_SUCCESS = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_SUCCESS')
export const GET_BOOKED_MEDIA_FAILURE = concat(MODULE_NAME, 'GET_BOOKED_MEDIA_FAILURE')
export const CLEAR_GET_BOOKED_MEDIA = concat(MODULE_NAME, 'CLEAR_GET_BOOKED_MEDIA')

export const GET_BRAND_REVENUE_REPORT = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT')
export const GET_BRAND_REVENUE_REPORT_SUCCESS = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT_SUCCESS')
export const GET_BRAND_REVENUE_REPORT_FAILURE = concat(MODULE_NAME, 'GET_BRAND_REVENUE_REPORT_FAILURE')
export const CLEAR_GET_BRAND_REVENUE_REPORT = concat(MODULE_NAME, 'CLEAR_GET_BRAND_REVENUE_REPORT')

export const GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT = concat(
  MODULE_NAME,
  'GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT'
)
export const GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT_SUCCESS = concat(
  MODULE_NAME,
  'GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT_SUCCESS'
)
export const GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT_FAILURE = concat(
  MODULE_NAME,
  'GET_UPLOADED_FILES_PENDING_APPROVAL_ITEMS_COUNT_FAILURE'
)
export const CLEAR_GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT = concat(
  MODULE_NAME,
  'CLEAR_GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT'
)

export const GET_BOOKING_VARIABLES = concat(MODULE_NAME, 'GET_BOOKING_VARIABLES')
export const GET_BOOKING_VARIABLES_SUCCESS = concat(MODULE_NAME, 'GET_BOOKING_VARIABLES_SUCCESS')
export const GET_BOOKING_VARIABLES_FAILURE = concat(MODULE_NAME, 'GET_BOOKING_VARIABLES_FAILURE')
export const CLEAR_GET_BOOKING_VARIABLES = concat(MODULE_NAME, 'CLEAR_GET_BOOKING_VARIABLES')

export const CREATE_BOOKING_VARIABLE = concat(MODULE_NAME, 'CREATE_BOOKING_VARIABLE')
export const CREATE_BOOKING_VARIABLE_SUCCESS = concat(MODULE_NAME, 'CREATE_BOOKING_VARIABLE_SUCCESS')
export const CREATE_BOOKING_VARIABLE_FAILURE = concat(MODULE_NAME, 'CREATE_BOOKING_VARIABLE_FAILURE')
export const CLEAR_CREATE_BOOKING_VARIABLE = concat(MODULE_NAME, 'CLEAR_CREATE_BOOKING_VARIABLE')

export const GET_BOOKING_VARIABLE = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE')
export const GET_BOOKING_VARIABLE_SUCCESS = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE_SUCCESS')
export const GET_BOOKING_VARIABLE_FAILURE = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE_FAILURE')
export const CLEAR_GET_BOOKING_VARIABLE = concat(MODULE_NAME, 'CLEAR_GET_BOOKING_VARIABLE')

export const UPDATE_BOOKING_VARIABLE = concat(MODULE_NAME, 'UPDATE_BOOKING_VARIABLE')
export const UPDATE_BOOKING_VARIABLE_SUCCESS = concat(MODULE_NAME, 'UPDATE_BOOKING_VARIABLE_SUCCESS')
export const UPDATE_BOOKING_VARIABLE_FAILURE = concat(MODULE_NAME, 'UPDATE_BOOKING_VARIABLE_FAILURE')
export const CLEAR_UPDATE_BOOKING_VARIABLE = concat(MODULE_NAME, 'CLEAR_UPDATE_BOOKING_VARIABLE')

export const GET_BOOKING_VARIABLE_OPTIONS = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE_OPTIONS')
export const GET_BOOKING_VARIABLE_OPTIONS_SUCCESS = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE_OPTIONS_SUCCESS')
export const GET_BOOKING_VARIABLE_OPTIONS_FAILURE = concat(MODULE_NAME, 'GET_BOOKING_VARIABLE_OPTIONS_FAILURE')
export const CLEAR_GET_BOOKING_VARIABLE_OPTIONS = concat(MODULE_NAME, 'CLEAR_GET_BOOKING_VARIABLE_OPTIONS')

export const GENERATE_CAMPAIGN_REACH_REPORT = concat(MODULE_NAME, 'GENERATE_CAMPAIGN_REACH_REPORT')
export const GENERATE_CAMPAIGN_REACH_REPORT_SUCCESS = concat(MODULE_NAME, 'GENERATE_CAMPAIGN_REACH_REPORT_SUCCESS')
export const GENERATE_CAMPAIGN_REACH_REPORT_FAILURE = concat(MODULE_NAME, 'GENERATE_CAMPAIGN_REACH_REPORT_FAILURE')
export const CLEAR_GENERATE_CAMPAIGN_REACH_REPORT = concat(MODULE_NAME, 'CLEAR_GENERATE_CAMPAIGN_REACH_REPORT')

export const SEND_CAMPAIGN_REACH_REPORT = concat(MODULE_NAME, 'SEND_CAMPAIGN_REACH_REPORT')
export const SEND_CAMPAIGN_REACH_REPORT_SUCCESS = concat(MODULE_NAME, 'SEND_CAMPAIGN_REACH_REPORT_SUCCESS')
export const SEND_CAMPAIGN_REACH_REPORT_FAILURE = concat(MODULE_NAME, 'SEND_CAMPAIGN_REACH_REPORT_FAILURE')
export const CLEAR_SEND_CAMPAIGN_REACH_REPORT = concat(MODULE_NAME, 'CLEAR_SEND_CAMPAIGN_REACH_REPORT')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get media orders bookings as csv
export function getMediaOrdersBookingsAsCsv(params) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV, params }
}

export function getMediaOrdersBookingsAsCsvSuccess(data) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_SUCCESS, data }
}

export function getMediaOrdersBookingsAsCsvFailure(error) {
  return { type: GET_MEDIA_ORDERS_BOOKINGS_AS_CSV_FAILURE, error }
}

export function clearGetMediaOrdersBookingsAsCsv() {
  return { type: CLEAR_GET_MEDIA_ORDERS_BOOKINGS_AS_CSV }
}

// Get booking media files
export function getBookingMediaFiles(params) {
  return { type: GET_BOOKING_MEDIA_FILES, params }
}

export function getBookingMediaFilesSuccess(data) {
  return { type: GET_BOOKING_MEDIA_FILES_SUCCESS, data }
}

export function getBookingMediaFilesFailure(error) {
  return { type: GET_BOOKING_MEDIA_FILES_FAILURE, error }
}

export function clearGetBookingMediaFiles() {
  return { type: CLEAR_GET_BOOKING_MEDIA_FILES }
}

// Update booking media file status
export function updateBookingMediaFile(id, data) {
  return { type: UPDATE_BOOKING_MEDIA_FILE, id, data }
}

export function updateBookingMediaFileSuccess(data) {
  return { type: UPDATE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function updateBookingMediaFileFailure(error) {
  return { type: UPDATE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearUpdateBookingMediaFile() {
  return { type: CLEAR_UPDATE_BOOKING_MEDIA_FILE }
}

// Delete booking media file
export function deleteBookingMediaFile(id, params) {
  return { type: DELETE_BOOKING_MEDIA_FILE, id, params }
}

export function deleteBookingMediaFileSuccess(data) {
  return { type: DELETE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function deleteBookingMediaFileFailure(error) {
  return { type: DELETE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearDeleteBookingMediaFile() {
  return { type: CLEAR_DELETE_BOOKING_MEDIA_FILE }
}

// Upload booking media file
export function uploadBookingMediaFile(data) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE, data }
}

export function uploadBookingMediaFileSuccess(data) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function uploadBookingMediaFileFailure(error) {
  return { type: UPLOAD_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearUploadBookingMediaFile() {
  return { type: CLEAR_UPLOAD_BOOKING_MEDIA_FILE }
}

// Get order booked media
export function getOrderBookedMedia(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_ORDER_BOOKED_MEDIA, params, loadOptions }
}

export function getOrderBookedMediaSuccess(data) {
  return { type: GET_ORDER_BOOKED_MEDIA_SUCCESS, data }
}

export function getOrderBookedMediaFailure(error) {
  return { type: GET_ORDER_BOOKED_MEDIA_FAILURE, error }
}

export function clearGetOrderBookedMedia() {
  return { type: CLEAR_GET_ORDER_BOOKED_MEDIA }
}

// Get booked media report
export function getBookedMediaReport({
  params,
  loadOptions = {
    shouldClearExistingState: false
  },
  period
}) {
  return { type: GET_BOOKED_MEDIA_REPORT, params, loadOptions, period }
}

export function getBookedMediaReportSuccess(data, period) {
  return { type: GET_BOOKED_MEDIA_REPORT_SUCCESS, data, period }
}

export function getBookedMediaReportFailure(error, period) {
  return { type: GET_BOOKED_MEDIA_REPORT_FAILURE, error, period }
}

export function clearGetBookedMediaReport() {
  return { type: CLEAR_GET_BOOKED_MEDIA_REPORT }
}

// Get booked revenue report
export function getBookedRevenueReport({
  params,
  loadOptions = {
    shouldClearExistingState: false
  },
  period
}) {
  return { type: GET_BOOKED_REVENUE_REPORT, params, loadOptions, period }
}

export function getBookedRevenueReportSuccess(data, period) {
  return { type: GET_BOOKED_REVENUE_REPORT_SUCCESS, data, period }
}

export function getBookedRevenueReportFailure(error, period) {
  return { type: GET_BOOKED_REVENUE_REPORT_FAILURE, error, period }
}

export function clearGetBookedRevenueReport() {
  return { type: CLEAR_GET_BOOKED_REVENUE_REPORT }
}

// Get installation booked media report
export function getInstallationBookedMediaReport(params) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT, params }
}

export function getInstallationBookedMediaReportSuccess(data) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_SUCCESS, data }
}

export function getInstallationBookedMediaReportFailure(error) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_FAILURE, error }
}

export function clearGetInstallationBookedMediaReport() {
  return { type: CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT }
}

export function getInstallationBookedMediaReportAsCsv(params) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV, params }
}

export function getInstallationBookedMediaReportAsCsvSuccess(data, fileName) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_SUCCESS, data, fileName }
}

export function getInstallationBookedMediaReportAsCsvFailure(error) {
  return { type: GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV_FAILURE, error }
}

export function clearGetInstallationBookedMediaReportAsCsv() {
  return { type: CLEAR_GET_INSTALLATION_BOOKED_MEDIA_REPORT_AS_CSV }
}

// Bulk update booking media file
export function bulkUpdateBookingMediaFile(data) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE, data }
}

export function bulkUpdateBookingMediaFileSuccess(data) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE_SUCCESS, data }
}

export function bulkUpdateBookingMediaFileFailure(error) {
  return { type: BULK_UPDATE_BOOKING_MEDIA_FILE_FAILURE, error }
}

export function clearBulkUpdateBookingMediaFile() {
  return { type: CLEAR_BULK_UPDATE_BOOKING_MEDIA_FILE }
}

// Bulk update booked media
export function bulkUpdateBookedMedia(data) {
  return { type: BULK_UPDATE_BOOKED_MEDIA, data }
}

export function bulkUpdateBookedMediaSuccess(data) {
  return { type: BULK_UPDATE_BOOKED_MEDIA_SUCCESS, data }
}

export function bulkUpdateBookedMediaFailure(error) {
  return { type: BULK_UPDATE_BOOKED_MEDIA_FAILURE, error }
}

export function clearBulkUpdateBookedMedia() {
  return { type: CLEAR_BULK_UPDATE_BOOKED_MEDIA }
}

// Get booked media
export function getBookedMedia(params) {
  return { type: GET_BOOKED_MEDIA, params }
}

export function getBookedMediaSuccess(data) {
  return { type: GET_BOOKED_MEDIA_SUCCESS, data }
}

export function getBookedMediaFailure(error) {
  return { type: GET_BOOKED_MEDIA_FAILURE, error }
}

export function clearGetBookedMedia() {
  return { type: CLEAR_GET_BOOKED_MEDIA }
}

// Get brand revenue report
export function getBrandRevenueReport(
  params,
  loadOption = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_BRAND_REVENUE_REPORT, params, loadOption }
}

export function getBrandRevenueReportSuccess(data) {
  return { type: GET_BRAND_REVENUE_REPORT_SUCCESS, data }
}

export function getBrandRevenueReportFailure(error) {
  return { type: GET_BRAND_REVENUE_REPORT_FAILURE, error }
}

export function clearGetBrandAnnualRevenueReport() {
  return { type: CLEAR_GET_BRAND_REVENUE_REPORT }
}

// Get uploaded files pending approval items count
export function getUploadedFilesPendingApprovalCount(params) {
  return { type: GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT, params }
}

export function getUploadedFilesPendingApprovalCountSuccess(data) {
  return { type: GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT_SUCCESS, data }
}

export function getUploadedFilesPendingApprovalCountFailure(error) {
  return { type: GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT_FAILURE, error }
}

export function clearGetUploadedFilesPendingApprovalCount() {
  return { type: CLEAR_GET_UPLOADED_FILES_PENDING_APPROVAL_COUNT }
}

// Get booking variables
export function getBookingVariables(params) {
  return { type: GET_BOOKING_VARIABLES, params }
}

export function getBookingVariablesSuccess(data) {
  return { type: GET_BOOKING_VARIABLES_SUCCESS, data }
}

export function getBookingVariablesFailure(error) {
  return { type: GET_BOOKING_VARIABLES_FAILURE, error }
}

export function clearGetBookingVariables() {
  return { type: CLEAR_GET_BOOKING_VARIABLES }
}

// Create booking variable
export function createBookingVariable(data) {
  return { type: CREATE_BOOKING_VARIABLE, data }
}

export function createBookingVariableSuccess(data) {
  return { type: CREATE_BOOKING_VARIABLE_SUCCESS, data }
}

export function createBookingVariableFailure(error) {
  return { type: CREATE_BOOKING_VARIABLE_FAILURE, error }
}

export function clearCreateBookingVariable() {
  return { type: CLEAR_CREATE_BOOKING_VARIABLE }
}

// Get booking variable
export function getBookingVariable(params) {
  return { type: GET_BOOKING_VARIABLE, params }
}

export function getBookingVariableSuccess(data) {
  return { type: GET_BOOKING_VARIABLE_SUCCESS, data }
}

export function getBookingVariableFailure(error) {
  return { type: GET_BOOKING_VARIABLE_FAILURE, error }
}

export function clearGetBookingVariable() {
  return { type: CLEAR_GET_BOOKING_VARIABLE }
}

// Update booking variable
export function updateBookingVariable(id, data) {
  return { type: UPDATE_BOOKING_VARIABLE, id, data }
}

export function updateBookingVariableSuccess(data) {
  return { type: UPDATE_BOOKING_VARIABLE_SUCCESS, data }
}

export function updateBookingVariableFailure(error) {
  return { type: UPDATE_BOOKING_VARIABLE_FAILURE, error }
}

export function clearUpdateBookingVariable() {
  return { type: CLEAR_UPDATE_BOOKING_VARIABLE }
}

// Get booking variable options
export function getBookingVariableOptions(params, loadOptions = { shouldClearExistingState: false }) {
  return { type: GET_BOOKING_VARIABLE_OPTIONS, params, loadOptions }
}

export function getBookingVariableOptionsSuccess(data) {
  return { type: GET_BOOKING_VARIABLE_OPTIONS_SUCCESS, data }
}

export function getBookingVariableOptionsFailure(error) {
  return { type: GET_BOOKING_VARIABLE_OPTIONS_FAILURE, error }
}

export function clearBookingVariableOptions() {
  return { type: CLEAR_GET_BOOKING_VARIABLE_OPTIONS }
}

// Generate campaign reach report
export function generateCampaignReachReport(params) {
  return { type: GENERATE_CAMPAIGN_REACH_REPORT, params }
}

export function generateCampaignReachReportSuccess(data) {
  return { type: GENERATE_CAMPAIGN_REACH_REPORT_SUCCESS, data }
}

export function generateCampaignReachReportFailure(error) {
  return { type: GENERATE_CAMPAIGN_REACH_REPORT_FAILURE, error }
}

export function clearGenerateCampaignReachReport() {
  return { type: CLEAR_GENERATE_CAMPAIGN_REACH_REPORT }
}

// Send campaign reach report
export function sendCampaignReachReport(params) {
  return { type: SEND_CAMPAIGN_REACH_REPORT, params }
}

export function sendCampaignReachReportSuccess(data) {
  return { type: SEND_CAMPAIGN_REACH_REPORT_SUCCESS, data }
}

export function sendCampaignReachReportFailure(error) {
  return { type: SEND_CAMPAIGN_REACH_REPORT_FAILURE, error }
}

export function clearSendCampaignReachReport() {
  return { type: CLEAR_SEND_CAMPAIGN_REACH_REPORT }
}

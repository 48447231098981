import { useMediaQuery } from 'react-responsive'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import StatusCell from './Cells/Status'
import Table from '../../../../components/Table'
import ActionsButtonsList from '../../../../features/components/ActionsButtonsList'
import ActionsDropdown from '../../../../features/components/ActionsDropdown'
import AccountBrandCell from '../../../../components/Table/ReusableCells/AccountBrandCell'
import RowSelectionCell from '../../../../components/Table/ReusableCells/RowSelectionCell'
import AllRowsSelectionCell from '../../../../components/Table/ReusableCells/AllRowsSelectionCell'

import { QuotationTableContext } from '../QuotationTableContext'
import useQuotationActions from './useQuotationActions'
import { getDaysDiffLabel } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/helpers'

import { SORT_CREATED } from './helpers'
import { insertIf } from '../../../../helpers/common'
import { formatCurrency } from '../../../../helpers/numbers'
import { formatDateFullYear } from '../../../../constants/dates'
import { calculateDiscountPercentage } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ProductsTable/Cells/DiscountCell/helpers'

import { quotationsSelector } from '../../../../modules/selectors/quotations'
import { selectedControllerDataSelector } from '../../../../modules/selectors/app'

import { columnsSize } from '../helpers'
import { dropdownColumnSizes } from '../../../MediaOrders/MediaOrdersTable/constants/columnSizes'

import { phonesDownSize } from '../../../../styles/const/breakpoints'

import useStyles from './styles'

const QuotationsTableData = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const quotations = useSelector(quotationsSelector)
  const selectedController = useSelector(selectedControllerDataSelector)

  const { tableSort, setTableSort } = useContext(QuotationTableContext)

  const { getDropdownOptions, handleEditRedirect } = useQuotationActions()

  const showCampaignColumn = selectedController?.ask_campaign_name

  const quotationsColumns = useMemo(
    () => [
      {
        header: () => <AllRowsSelectionCell Context={QuotationTableContext} />,
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        Cell: ({ id }) => {
          return <RowSelectionCell rowId={id} Context={QuotationTableContext} />
        },
        style: { maxWidth: columnsSize.checkbox }
      },
      {
        header: 'ID',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'media_order__sequential_id',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ id, account, media_order }) => {
          const handleRedirectToEditPage = () => {
            handleEditRedirect({
              proposalId: id,
              accountId: account.id
            })
          }

          return <div onClick={handleRedirectToEditPage}>{media_order.sequential_id}</div>
        },
        style: { cursor: 'pointer', maxWidth: columnsSize.id }
      },
      {
        header: 'Account',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'account__name',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ account, media_order }) => {
          const brandName = media_order.brand?.brand_name

          return <AccountBrandCell account={account?.name || '-'} brand={brandName} isSmall={true} />
        },
        style: { minWidth: columnsSize.account, maxWidth: columnsSize.account }
      },
      ...insertIf(showCampaignColumn, {
        header: 'Campaign',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        Cell: ({ media_order }) => {
          return media_order.campaign_name
        },
        style: { minWidth: columnsSize.campaign, whiteSpace: 'normal' },
        showOnMobile: false
      }),
      {
        header: 'Dates',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'media_order__start_date',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ media_order }) => {
          const { start_date, end_date } = media_order

          if (start_date && end_date) {
            return `${formatDateFullYear(start_date)} - ${formatDateFullYear(end_date)}`
          } else {
            return '-'
          }
        },
        style: { minWidth: columnsSize.dates, maxWidth: columnsSize.dates },
        showOnMobile: false
      },
      {
        header: 'Created By',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'created_by__first_name',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ media_order }) => {
          return media_order.created_by?.full_name
        },
        style: { minWidth: columnsSize.createdBy, whiteSpace: 'normal' },
        showOnMobile: false
      },
      {
        header: 'Created',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: SORT_CREATED,
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ created }) => {
          return getDaysDiffLabel(created)
        },
        style: { maxWidth: columnsSize.created },
        showOnMobile: false
      },
      {
        header: 'Updated',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        style: { maxWidth: columnsSize.updated },
        sortParameter: 'modified',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ modified }) => {
          return getDaysDiffLabel(modified)
        },
        showOnMobile: false
      },
      {
        header: 'Status',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'quotation_status',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: data => <StatusCell {...data} />,
        style: { maxWidth: columnsSize.status },
        showOnMobile: false
      },
      {
        header: 'Rate',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'media_order__cost',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ media_order }) => {
          const cost = media_order.cost
          const currencySymbol = media_order.currency

          return formatCurrency(cost, { min: 2, max: 2 }, { symbol: currencySymbol })
        },
        style: { maxWidth: columnsSize.rate, justifyContent: 'flex-end' },
        showOnMobile: false
      },
      {
        header: 'Disc.',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'annotated_discount_percentage',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ media_order }) => {
          const { cost, cost_with_discount } = media_order
          const discount = calculateDiscountPercentage(cost, cost_with_discount) || '0'
          return `${discount}%`
        },
        style: { maxWidth: columnsSize.disc },
        showOnMobile: false
      },
      {
        header: 'Cost',
        headClassName: classes.quotationCol,
        className: classes.quotationCol,
        sortParameter: 'media_order__cost_with_discount',
        onSortingChange: newSort => setTableSort(newSort),
        Cell: ({ media_order }) => {
          // we represent discounted price
          const cost = media_order.cost_with_discount
          const currencySymbol = media_order.currency

          return formatCurrency(cost, { min: 2, max: 2 }, { symbol: currencySymbol })
        },
        style: { maxWidth: columnsSize.cost, justifyContent: 'flex-end' },
        showOnMobile: false
      },
      {
        Cell: ({ id }) =>
          isMobile ? (
            <ActionsButtonsList itemId={id} options={getDropdownOptions(id)} />
          ) : (
            <ActionsDropdown itemId={id} options={getDropdownOptions(id)} closeOnInsideClick />
          ),
        style: isMobile ? { padding: 0 } : { ...dropdownColumnSizes },
        showOnMobile: false
      }
    ],
    [classes.quotationCol, showCampaignColumn, isMobile, setTableSort, handleEditRedirect, getDropdownOptions]
  )

  return <Table data={quotations} cols={quotationsColumns} tableSort={tableSort} hideFooterRow />
}

export default QuotationsTableData

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import classnames from 'classnames'

import Page from '../index'
import SettingsNavMenu from './SettingsNavMenu'
import { TabletDown, TabletUp } from '../../components/hoc/ResponsiveRendering'
import EditProfile from './EditProfile'
import ManageSelfAccountTeam from './ManageSelfAccountTeam'
import ManageMargins from './ManageMargins'
import ManageControllerTeam from './ManageControllerTeam'
import ManageLocationLists from './ManageLocationLists'
import AdvertiserAccounts from './AdvertiserAccounts'
import MediaProducts from './MediaProducts'
import MediaPackages from './MediaPackages'
// import Reporting from './Reporting'
import BookedMediaAndFiles from './BookedMediaAndFiles'
import Invoicing from './Invoicing'
import PagesAndIdentities from './PagesAndIdentities'
import ManageController from './ManageController'
import BrandPages from './BrandPages'
import Discounts from './Discounts'
import Bookings from './Bookings'
import ManageAgency from './ManageAgency'
import AgencyAccounts from './AgencyAccounts'
import MediaProductOptions from './MediaProductOptions'
import BookingEmailAttachments from './BookingEmailAttachments'
import FootfallData from './FootfallData'

import { ReactComponent as IconBack } from '../../assets/icons/chevron-arrow-down.svg'
import usePermissions from '../../hooks/usePermissions'

import { redirectTo } from '../../helpers/url'

import {
  isUserAgencySelector,
  isUserControllerSelector,
  selectedControllerDataSelector,
  selectedFacebookAdAccountIdSelector,
  selectedSelfAccountDataSelector,
  selectedTikTokAdAccountIdSelector
} from '../../modules/selectors/app'

import { ROUTES, SETTINGS_ROUTES } from '../../constants/routes'
import useStyles from './styles'

function Settings() {
  const { t } = useTranslation()
  const params = useParams()

  const [isSectionPreOpened, setIsSectionPreOpened] = useState(false)
  const [isMenuOpened, setIsMenuOpened] = useState(true)
  const [isMenuSwiped, setIsMenuSwiped] = useState(false)

  const classes = useStyles(isMenuOpened)

  const selectedController = useSelector(selectedControllerDataSelector)
  const { name: controllerName, agencies: controllerAgencies } = selectedController || {}
  const { name: selfAccountName } = useSelector(selectedSelfAccountDataSelector)

  const facebookAdAccountId = useSelector(selectedFacebookAdAccountIdSelector)
  const tikTokAdAccountId = useSelector(selectedTikTokAdAccountIdSelector)
  const isUserAgency = useSelector(isUserAgencySelector)
  const isUserController = useSelector(isUserControllerSelector)

  const permissions = usePermissions()

  const subPagePath = params.settingSection

  const showPagesAndIdentitiesSettingsPage =
    permissions.can('manage', ROUTES.pagesAndIdentities) && (!!facebookAdAccountId || !!tikTokAdAccountId)

  const hasAgency = useMemo(() => {
    return (isUserController && controllerAgencies?.length > 0) || isUserAgency
  }, [isUserController, isUserAgency, controllerAgencies])

  const closeMenu = () => {
    setIsMenuSwiped(true)
    setTimeout(() => {
      setIsMenuOpened(false)
    }, 450)
  }

  const openMenu = () => {
    setIsMenuOpened(true)
    setTimeout(() => {
      setIsMenuSwiped(false)
    }, 0)
  }

  const firstBlockSettings = useMemo(
    () => [
      {
        component: <ManageSelfAccountTeam />,
        path: ROUTES.settings,
        title: t('selfAccountNameTeam', { selfAccountName }),
        show: permissions.can('manage', ROUTES.settings)
      },
      {
        component: <ManageLocationLists />,
        path: ROUTES.manageLocationLists,
        pathParam: SETTINGS_ROUTES.MANAGE_LOCATION_LISTS,
        title: t('Location Lists'),
        show: permissions.can('manage', ROUTES.manageLocationLists)
      },
      {
        component: <BrandPages />,
        path: ROUTES.brandPages,
        pathParam: SETTINGS_ROUTES.BRAND_PAGES,
        title: t('Brand Pages'),
        show: permissions.can('manage', ROUTES.brandPages)
      },
      {
        component: <PagesAndIdentities />,
        path: ROUTES.pagesAndIdentities,
        pathParam: SETTINGS_ROUTES.PAGES_AND_IDENTITIES,
        title: t('Pages and Identities'),
        show: showPagesAndIdentitiesSettingsPage
      },
      {
        component: <ManageMargins />,
        path: ROUTES.manageMargins,
        title: t('Margins'),
        pathParam: SETTINGS_ROUTES.MANAGE_MARGINS,
        show: permissions.can('manage', ROUTES.manageMargins)
      },
      {
        component: <EditProfile />,
        path: ROUTES.myInformation,
        pathParam: SETTINGS_ROUTES.MY_INFORMATION,
        title: t('User Profile'),
        show: true
      }
      // todo some sections were hidden for the demo, decide when to uncomment them after
      // {
      //   component: <ManageAutomationRules />,
      //   path: ROUTES.manageAutomationRules,
      //   pathParam: SETTINGS_ROUTES.MANAGE_AUTOMATION_RULES,
      //   title: t('Automation Rules'),
      //   show: true
      // },
      // todo when adding triggers back, uncomment 3-dot-menu option inside useGetLineItemActionsDropdownOptions
      // {
      //   component: <ManageTriggers />,
      //   path: ROUTES.manageTriggers,
      //   pathParam: SETTINGS_ROUTES.MANAGE_TRIGGERS,
      //   title: 'Triggers',
      //   show: true
      // },
      // {
      //   component: <Help />,
      //   path: ROUTES.help,
      //   pathParam: SETTINGS_ROUTES.HELP,
      //   title: t('Help'),
      //   show: true
      // }
    ],
    [t, permissions, selfAccountName, showPagesAndIdentitiesSettingsPage]
  )

  const secondBlockSettings = useMemo(
    () => [
      {
        component: <ManageControllerTeam />,
        path: ROUTES.controllerTeam,
        title: t('controllerNameTeam', { controllerName }),
        pathParam: SETTINGS_ROUTES.CONTROLLER_TEAM,
        show: permissions.can('manage', ROUTES.controllerTeam)
      },
      {
        component: <ManageAgency />,
        path: ROUTES.agency,
        title: t('Manage Agency'),
        pathParam: SETTINGS_ROUTES.AGENCY,
        // Show agency team page if controller has agency
        show: permissions.can('manage', ROUTES.agency) && hasAgency
      },
      {
        component: <AdvertiserAccounts />,
        path: ROUTES.advertiserAccounts,
        title: t('Advertiser Accounts'),
        pathParam: SETTINGS_ROUTES.ADVERTISER_ACCOUNTS,
        show: permissions.can('manage', ROUTES.advertiserAccounts)
      },
      {
        component: <AgencyAccounts />,
        path: ROUTES.agencyAccounts,
        title: t('Agency Accounts'),
        pathParam: SETTINGS_ROUTES.AGENCY_ACCOUNTS,
        show: permissions.can('manage', ROUTES.agencyAccounts)
      },
      {
        component: <MediaProducts />,
        path: ROUTES.mediaProducts,
        title: t('Media Products'),
        pathParam: SETTINGS_ROUTES.MEDIA_PRODUCTS,
        show: permissions.can('manage', ROUTES.mediaProducts)
      },
      {
        component: <MediaProductOptions />,
        path: ROUTES.mediaProductOptions,
        title: t('Media Product Options'),
        pathParam: SETTINGS_ROUTES.MEDIA_PRODUCT_OPTIONS,
        show: permissions.can('manage', ROUTES.mediaProductOptions)
      },
      {
        component: <Discounts />,
        path: ROUTES.discounts,
        title: t('Price Modifiers'),
        pathParam: SETTINGS_ROUTES.DISCOUNTS,
        show: permissions.can('manage', ROUTES.discounts)
      },
      {
        component: <BookingEmailAttachments />,
        path: ROUTES.bookingEmailAttachments,
        title: t('Booking Email Attachments'),
        pathParam: SETTINGS_ROUTES.BOOKING_EMAIL_ATTACHMENTS,
        show: permissions.can('manage', ROUTES.bookingEmailAttachments)
      },
      {
        component: <MediaPackages />,
        path: ROUTES.mediaPackages,
        title: t('Media Packages'),
        pathParam: SETTINGS_ROUTES.MEDIA_PACKAGES,
        show: permissions.can('manage', ROUTES.mediaPackages)
      },
      {
        component: <Bookings />,
        path: ROUTES.bookings,
        title: t('Bookings'),
        pathParam: SETTINGS_ROUTES.BOOKINGS,
        show: true
      },
      {
        component: <BookedMediaAndFiles />,
        path: ROUTES.bookedMediaAndFiles,
        pathParam: SETTINGS_ROUTES.BOOKED_MEDIA_AND_FILES,
        title: t('Booked Media'),
        show: permissions.can('manage', ROUTES.bookedMediaAndFiles)
      },
      // report is temporary hidden because it times out and then causes all other endpoints to time out.
      // todo uncomment it when it's fixed
      // {
      //   component: <Reporting />,
      //   path: ROUTES.reporting,
      //   title: t('Utilisation Report'),
      //   pathParam: SETTINGS_ROUTES.REPORTING,
      //   show: permissions.can('manage', ROUTES.reporting)
      // },
      {
        component: <ManageController />,
        path: ROUTES.controller,
        title: t('controllerSettings', { controllerName }),
        pathParam: SETTINGS_ROUTES.CONTROLLER,
        show: permissions.can('manage', ROUTES.controller)
      },
      {
        component: <FootfallData />,
        path: ROUTES.footfallData,
        title: t('Footfall Data'),
        pathParam: SETTINGS_ROUTES.FOOTFALL_DATA,
        show: permissions.can('manage', ROUTES.footfallData)
      },
      {
        component: <Invoicing />,
        path: ROUTES.invoicing,
        title: t('Billing'),
        pathParam: SETTINGS_ROUTES.INVOICING,
        show: permissions.can('manage', ROUTES.invoicing)
      }

      // {
      //   component: <Inventory />,
      //   path: ROUTES.inventory,
      //   title: t('Inventory'),
      //   pathParam: SETTINGS_ROUTES.INVENTORY,
      //   show: permissions.can('manage', ROUTES.inventory)
      // },
    ],
    [t, permissions, controllerName, hasAgency]
  )

  const isSelectedSetting = useCallback(section => section.pathParam === subPagePath, [subPagePath])

  const visibleFirstBlockSettings = useMemo(() => firstBlockSettings.filter(item => item.show), [firstBlockSettings])
  const visibleSecondBlockSettings = useMemo(() => secondBlockSettings.filter(item => item.show), [secondBlockSettings])

  const allVisibleSections = useMemo(
    () => visibleFirstBlockSettings.concat(visibleSecondBlockSettings),
    [visibleFirstBlockSettings, visibleSecondBlockSettings]
  )
  const selectedRouteSettingSection = useMemo(
    () => allVisibleSections.find(isSelectedSetting),
    [allVisibleSections, isSelectedSetting]
  )
  const selectedSection = useMemo(
    () => selectedRouteSettingSection || allVisibleSections[0],
    [selectedRouteSettingSection, allVisibleSections]
  )

  useEffect(() => {
    // redirect to EditProfile section if the selected section wasn't find(could miss show permissions for example)
    if (!selectedRouteSettingSection && subPagePath) {
      redirectTo(ROUTES.myInformation)
    }
  }, [selectedRouteSettingSection, subPagePath])

  useEffect(() => {
    // pre-open section content if page is not index (have subPath)
    // it helps to prevent behaviour when navigation menu is always shown on mobile as first view
    if (subPagePath && !isSectionPreOpened) {
      setIsMenuSwiped(true)
      setIsMenuOpened(false)
      setIsSectionPreOpened(true)
    } else if (!subPagePath && !isSectionPreOpened) {
      setIsSectionPreOpened(true)
    }
  }, [isSectionPreOpened, subPagePath])

  return (
    <Page helmetTitle="helmetTitle.SettingsPage">
      <TabletDown>
        <div className={classes.controls}>
          <button type="button" className={classes.backBtn} onClick={openMenu}>
            <IconBack /> {t('Back')}
          </button>
        </div>
      </TabletDown>
      <div className={classes.settingsPage}>
        <TabletUp>
          <section className={classes.navContainer}>
            <SettingsNavMenu settings={visibleFirstBlockSettings} subPagePath={subPagePath} />
            <SettingsNavMenu settings={visibleSecondBlockSettings} subPagePath={subPagePath} />
          </section>
          <div
            className={classnames(classes.settingsContent, {
              [classes.bookedMediaAndFilesContent]: subPagePath === SETTINGS_ROUTES.BOOKED_MEDIA_AND_FILES
            })}
          >
            {selectedSection.component}
          </div>
        </TabletUp>

        <TabletDown>
          {isMenuOpened ? (
            <section className={classnames(classes.navContainer, isMenuSwiped ? 'swiped' : '')}>
              <SettingsNavMenu settings={visibleFirstBlockSettings} subPagePath={subPagePath} closeMenu={closeMenu} />
              <SettingsNavMenu settings={visibleSecondBlockSettings} subPagePath={subPagePath} closeMenu={closeMenu} />
            </section>
          ) : (
            <div className={classes.settingsContent}>{selectedSection.component}</div>
          )}
        </TabletDown>
      </div>
    </Page>
  )
}

export default Settings

import React from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../components/Form/Field'

import { INTERNAL_ID, STORE_NAME } from '../../../fields'

import useStyles from '../../ExternalRecipientsContent/styles'

const StoreSectionFields = ({ formik, itemPath }) => {
  const classes = useStyles()

  return (
    <>
      <Field formik={formik} name={`${itemPath}.${STORE_NAME}`} placeholder="Name" />
      <hr className={classes.horizontalRow} />
      <Field formik={formik} name={`${itemPath}.${INTERNAL_ID}`} placeholder="ID" />
    </>
  )
}

StoreSectionFields.propTypes = {
  formik: PropTypes.object.isRequired
}

export default StoreSectionFields

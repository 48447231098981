import { concat } from '../../helpers/common'

const MODULE_NAME = 'AGENCIES/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_AGENCIES = concat(MODULE_NAME, 'GET_AGENCIES')
export const GET_AGENCIES_SUCCESS = concat(MODULE_NAME, 'GET_AGENCIES_SUCCESS')
export const GET_AGENCIES_FAILURE = concat(MODULE_NAME, 'GET_AGENCIES_FAILURE')
export const CLEAR_GET_AGENCIES = concat(MODULE_NAME, 'CLEAR_GET_AGENCIES')

export const CREATE_AGENCY = concat(MODULE_NAME, 'CREATE_AGENCY')
export const CREATE_AGENCY_SUCCESS = concat(MODULE_NAME, 'CREATE_AGENCY_SUCCESS')
export const CREATE_AGENCY_FAILURE = concat(MODULE_NAME, 'CREATE_AGENCY_FAILURE')
export const CLEAR_CREATE_AGENCY = concat(MODULE_NAME, 'CLEAR_CREATE_AGENCY')

export const UPDATE_AGENCY = concat(MODULE_NAME, 'UPDATE_AGENCY')
export const UPDATE_AGENCY_SUCCESS = concat(MODULE_NAME, 'UPDATE_AGENCY_SUCCESS')
export const UPDATE_AGENCY_FAILURE = concat(MODULE_NAME, 'UPDATE_AGENCY_FAILURE')
export const CLEAR_UPDATE_AGENCY = concat(MODULE_NAME, 'CLEAR_UPDATE_AGENCY')

export const GET_AGENCY = concat(MODULE_NAME, 'GET_AGENCY')
export const GET_AGENCY_SUCCESS = concat(MODULE_NAME, 'GET_AGENCY_SUCCESS')
export const GET_AGENCY_FAILURE = concat(MODULE_NAME, 'GET_AGENCY_FAILURE')
export const CLEAR_GET_AGENCY = concat(MODULE_NAME, 'CLEAR_GET_AGENCY')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get agencies
export function getAgencies(params) {
  return { type: GET_AGENCIES, params }
}

export function getAgenciesSuccess(data) {
  return { type: GET_AGENCIES_SUCCESS, data }
}

export function getAgenciesFailure(error) {
  return { type: GET_AGENCIES_FAILURE, error }
}

export function clearGetAgencies() {
  return { type: CLEAR_GET_AGENCIES }
}

// Create agency
export function createAgency(agencyData) {
  return { type: CREATE_AGENCY, agencyData }
}

export function createAgencySuccess(agencyData) {
  return { type: CREATE_AGENCY_SUCCESS, agencyData }
}

export function createAgencyFailure(error) {
  return { type: CREATE_AGENCY_FAILURE, error }
}

export function clearCreateAgency() {
  return { type: CLEAR_CREATE_AGENCY }
}

// Update agency
export function updateAgency(data, id) {
  return { type: UPDATE_AGENCY, data, id }
}

export function updateAgencySuccess(data) {
  return { type: UPDATE_AGENCY_SUCCESS, data }
}

export function updateAgencyFailure(error) {
  return { type: UPDATE_AGENCY_FAILURE, error }
}

export function clearUpdateAgency() {
  return { type: CLEAR_UPDATE_AGENCY }
}

// Get agency
export function getAgency(params) {
  return { type: GET_AGENCY, params }
}

export function getAgencySuccess(data) {
  return { type: GET_AGENCY_SUCCESS, data }
}

export function getAgencyFailure(error) {
  return { type: GET_AGENCY_FAILURE, error }
}

export function clearGetAgency() {
  return { type: CLEAR_GET_AGENCY }
}

import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SelectPeriodsContext } from '../../../../../SelectPeriodsContext'

import { inventoryLastItemSelector } from '../../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import {
  getEndAdjustedDate,
  getStartAdjustedDate
} from '../../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { findEarliestPeriod, formatPeriodOptions } from '../../Reusable/helpers'

export const AVAILABLE_QUANTITY = 'availableQuantity'

export function useInventoryPeriods({ selectedPeriods, additionalAvailableDays }) {
  const { inventory, productPeriod, productStartPeriodDate } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)

  // periodOptions are generated based on product inventory period
  const periodOptions = useMemo(() => {
    return formatPeriodOptions({ productStartPeriodDate, productPeriod, inventory })
  }, [productStartPeriodDate, inventory, productPeriod])

  // find the earliest period start date
  const earliestPeriod = findEarliestPeriod(periodOptions)
  const firstPeriod = getStartAdjustedDate(earliestPeriod?.date_start, additionalAvailableDays)
  const lastPeriod = getEndAdjustedDate(
    inventoryLastItem?.date_end || periodOptions[periodOptions.length - 1]?.date_start,
    additionalAvailableDays
  )

  const availablePeriods = useMemo(() => {
    const filteredAvailablePeriods = periodOptions.filter(period => {
      // filter out selected periods:
      const isSelected = selectedPeriods.find(selectedPeriod => {
        return selectedPeriod.inventory === period.inventory
      })

      // overdue are not available to select by user
      return !period.isDisabled && !period.overdue && !isSelected
    })
    return filteredAvailablePeriods
  }, [periodOptions, selectedPeriods])

  return {
    periodOptions,
    firstPeriod,
    lastPeriod,
    availablePeriods
  }
}

import {
  getCurrentUser as getCurrentUserCognito,
  signIn,
  confirmSignIn,
  signOut,
  confirmResetPassword,
  resetPassword,
  setUpTOTP,
  verifyTOTPSetup
} from '@aws-amplify/auth'

import { showToasts } from '../../helpers/toasts'
import { TOAST_TYPE } from '../../constants/other'

// PLOP_APPEND_PATTERN_ANCHOR

export async function getCurrentUser() {
  try {
    return await getCurrentUserCognito()
  } catch (error) {
    throw error
  }
}

export async function signInService({ email, password }) {
  try {
    return await signIn({
      username: email,
      password
    })
  } catch (error) {
    console.error('error signing in', error)
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function submitMfaTypeService(mfaType) {
  try {
    return await confirmSignIn({ challengeResponse: mfaType })
  } catch (error) {
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function submitMFACodeService(code) {
  try {
    return await confirmSignIn({ challengeResponse: code })
  } catch (error) {
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function signOutService() {
  try {
    return await signOut()
  } catch (error) {
    console.error(error)
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function createPasswordService({ user, password }) {
  try {
    return await signIn({
      username: user,
      password
    })
  } catch (error) {
    console.error(error)
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function createNewPasswordService({ username, code, new_password }) {
  try {
    // set new password after forgot password request
    return await confirmResetPassword({
      username,
      newPassword: new_password,
      confirmationCode: code
    })
  } catch (error) {
    console.error(error)
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function resetPasswordService(email) {
  try {
    const options = {
      clientMetadata: {
        domain: window.location.hostname
      }
    }
    return await resetPassword({ username: email, options })
  } catch (error) {
    console.error(error)
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function getTOTPTokenService() {
  try {
    return await setUpTOTP()
  } catch (error) {
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

export async function verifyTOTPTokenService(code) {
  try {
    return await verifyTOTPSetup({ code })
  } catch (error) {
    showToasts({
      type: TOAST_TYPE.error,
      message: error?.message
    })
    throw error
  }
}

import { createStyles } from '../../../styles/helpers'

import { green, red } from '../../../styles/const/colors'
import { loaderClassName } from '../../Loaders/Loader'

const getBtnBackgroundColor = ({ success, error, isLoading, loadingBackgroundColor, theme }) => {
  if (isLoading) {
    return loadingBackgroundColor || theme.brandPrimary
  } else if (success) {
    // success button state
    return green
  } else if (error) {
    // error button state
    return red
  }
}
export default createStyles(theme => ({
  test: {
    marginBottom: 16
  },
  pbContainer: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  pbButton: {
    // show default button background color during loading
    borderColor: ({ success, error, isLoading, loadingBackgroundColor }) =>
      (success || error || isLoading) && [
        getBtnBackgroundColor({
          success,
          error,
          isLoading,
          loadingBackgroundColor,
          theme
        }),
        '!important'
      ],
    backgroundColor: ({ success, error, isLoading, loadingBackgroundColor }) =>
      (success || error || isLoading) && [
        getBtnBackgroundColor({
          success,
          error,
          isLoading,
          loadingBackgroundColor,
          theme
        }),
        '!important'
      ],
    textDecoration: 'none',
    textAlign: 'center',
    '-webkit-tap-highlight-color': 'transparent',
    outline: 'none',
    position: 'relative',

    ['& .' + loaderClassName]: {
      maxHeight: '80%',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      '& .path': {
        stroke: 'white !important',
        animation: '$dash 1.5s ease-in-out infinite, $color 6s ease-in-out infinite'
      }
    },

    '@global': {
      span: {
        display: 'inherit',
        transition: 'opacity 0.3s 0.1s',
        fontSize: '2em',
        fontWeight: 100
      },
      svg: {
        pointerEvents: 'none'
      },
      'svg path': {
        opacity: 0,
        fill: 'none'
      },
      '.pb-checkmark > path, .pb-cross > path': {
        opacity: ({ success, error }) => (success || error) && 1
      },
      'svg.pb-checkmark path, svg.pb-cross path': {
        stroke: '#fff',
        strokeLinecap: 'round',
        strokeWidth: 4
      }
    }
  },
  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: 46,
    width: 46
  },
  text: {
    transition: 'all 0.15s'
  },

  '@keyframes dash': {
    '0%': {
      strokeDasharray: '1, 200',
      strokeDashoffset: '0'
    },
    '50%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-35px'
    },
    '100%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-124px'
    }
  },

  '@keyframes color': {
    '0%, 100%': { stroke: 'white' },
    '40%': { stroke: 'white' },
    '66%': { stroke: 'white' },
    '80%, 90%': { stroke: 'white' }
  }
}))

import * as Yup from 'yup'

import { NAME, OPTION_NAME, OPTIONS } from './fields'

export const validationSchema = Yup.object({
  [NAME]: Yup.string().required('Name is required'),
  [OPTIONS]: Yup.object().test({
    name: 'Options validation',
    test: function (value) {
      const optionKeys = Object.keys(value)
      for (let i = 0; i < optionKeys.length; i++) {
        const option = value[optionKeys[i]]
        if (!option[OPTION_NAME]) {
          return this.createError({
            message: 'Option name is required',
            path: `${this.path}.${optionKeys[i]}.${OPTION_NAME}`
          })
        }
      }

      return true
    }
  })
})

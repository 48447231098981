import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import MediaTagActions from '../MediaTagActions'
import Table from '../../../../../../components/Table'

import {
  updateProductTagIdSelector,
  deleteProductTagIdSelector,
  updateProductTagIsLoadingSelector,
  deleteProductTagIsLoadingSelector
} from '../../../../../../modules/selectors/tags'

import { tableColumnsSize } from '../index'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

import useProductCardsStyles from '../../../../MediaProducts/productCardStyles'

const MediaTagsTableData = ({ tags, onOrderChange }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const classes = useProductCardsStyles()

  const updateTagId = useSelector(updateProductTagIdSelector)
  const updateTagIsLoading = useSelector(updateProductTagIsLoadingSelector)
  const deleteTagId = useSelector(deleteProductTagIdSelector)
  const deleteTagIsLoading = useSelector(deleteProductTagIsLoadingSelector)

  const loadingTagId = useMemo(() => {
    if (updateTagIsLoading) {
      return updateTagId
    }
    if (deleteTagIsLoading) {
      return deleteTagId
    }

    // default value null if nothing is updating or deleting
    return null
  }, [updateTagId, updateTagIsLoading, deleteTagId, deleteTagIsLoading])

  const tagsColumns = useMemo(
    () => [
      {
        fieldKey: 'title'
      },
      {
        Cell: data => <MediaTagActions tag={data} className={classes.actionsStyles} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, classes]
  )

  return (
    <Table
      hideHeadlineRow
      data={tags}
      cols={tagsColumns}
      onRowDrag={onOrderChange}
      itemUpdatingId={loadingTagId}
      tableId="MediaTagsTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

export default MediaTagsTableData

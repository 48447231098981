import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../components/Form/Field'
import BrandLogoUpload from './BrandLogoUpload'
import CategoriesRows from './CategoriesRows'

import {
  BRAND_ID,
  BRAND_NAME,
  LANDSCAPE_LOGO,
  LANDSCAPE_LOGO_FILE_NAME,
  SQUARE_LOGO,
  SQUARE_LOGO_FILE_NAME
} from '../../../ClientAccountWithMarginsCreate/ClientAccountWithMarginsCreateForm/fields'

import {
  landscapeLogoMinHeight,
  landscapeLogoMinWidth,
  landscapeLogoRatio,
  getLandscapeLogoValidationSchema,
  squareLogoMinHeight,
  squareLogoMinWidth,
  squareLogoRatio,
  getSquareLogoValidationSchema
} from '../../../../../../../forms/Google/AdForms/validations'

const BrandsSection = ({ formik, itemPath, isActive }) => {
  const { t } = useTranslation()

  const squareLogoValidationSchema = useMemo(() => getSquareLogoValidationSchema(), [])
  const landscapeLogoValidationSchema = useMemo(() => getLandscapeLogoValidationSchema(), [])

  return (
    <>
      <FieldRow title={t('Brand name')}>
        <Field formik={formik} name={`${itemPath}.${BRAND_NAME}`} placeholder={t('Brand name')} />
      </FieldRow>
      <FieldRow title={t('Brand id')}>
        <Field formik={formik} name={`${itemPath}.${BRAND_ID}`} placeholder={t('Brand id')} />
      </FieldRow>
      <CategoriesRows formik={formik} itemPath={itemPath} isActive={isActive} />
      {/*currently logo are used inside the google ads creation forms so the validation rules are set according to
       these requirements*/}
      <FieldRow
        title="Square logo"
        description={`Ratio ${squareLogoRatio}:1, min ${squareLogoMinWidth}x${squareLogoMinHeight}`}
      >
        <BrandLogoUpload
          formik={formik}
          logoUrlPath={`${itemPath}.${SQUARE_LOGO}`}
          logoFileNamePath={`${itemPath}.${SQUARE_LOGO_FILE_NAME}`}
          logoValidationSchema={squareLogoValidationSchema}
        />
      </FieldRow>
      <FieldRow
        title="Landscape logo"
        description={`Ratio ${landscapeLogoRatio}:1, min ${landscapeLogoMinWidth}x${landscapeLogoMinHeight}`}
      >
        <BrandLogoUpload
          formik={formik}
          logoUrlPath={`${itemPath}.${LANDSCAPE_LOGO}`}
          logoFileNamePath={`${itemPath}.${LANDSCAPE_LOGO_FILE_NAME}`}
          logoValidationSchema={landscapeLogoValidationSchema}
        />
      </FieldRow>
    </>
  )
}

BrandsSection.propTypes = {
  formik: PropTypes.object.isRequired
}

export default BrandsSection

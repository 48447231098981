import React, { useCallback, useEffect } from 'react'
import history from '../../../../history'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useQuery } from '@tanstack/react-query'

import DownloadPdfButton from '../../../../features/components/PdfGeneration/DownloadPdfButton'

import { createJsonFromQueryString } from '../../../../helpers/url'

import { getContractService } from '../../../../modules/services/contracts'

import useStyles from './styles'

function DownloadContract({ contractId, className }) {
  const classes = useStyles()

  // token param is used to provide auth access to not registered users

  const { token } = createJsonFromQueryString(history.location.search, false)
  const {
    data: contract,
    refetch,
    isFetching: contractIsLoading
  } = useQuery({
    queryKey: ['contract', contractId],
    // contract is using private file which has token in url, when user want to download file we need to get new url with token
    // useGetAccessibleUrl is not working in this case, as our user is not registered and token doesn't work for
    // getAccessibleUrl due to security reasons
    queryFn: async () => getContractService(contractId, { token }),
    enabled: false,
    retry: false
  })
  const contractFile = contract?.file

  const handleFileDownload = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (contractFile) {
      window.open(contractFile)
    }
  }, [contractFile])

  return (
    <DownloadPdfButton
      className={classnames(classes.downloadBtn, className)}
      onClick={handleFileDownload}
      isLoading={contractIsLoading}
      text="Download Signed Copy"
    />
  )
}

DownloadContract.propTypes = {
  contractId: PropTypes.number,
  className: PropTypes.string
}

export default DownloadContract

import { concat } from '../../helpers/common'

const MODULE_NAME = 'FOOTFALL_DATA/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GET_FOOTFALL_AREAS = concat(MODULE_NAME, 'GET_FOOTFALL_AREAS')
export const GET_FOOTFALL_AREAS_SUCCESS = concat(MODULE_NAME, 'GET_FOOTFALL_AREAS_SUCCESS')
export const GET_FOOTFALL_AREAS_FAILURE = concat(MODULE_NAME, 'GET_FOOTFALL_AREAS_FAILURE')
export const CLEAR_GET_FOOTFALL_AREAS = concat(MODULE_NAME, 'CLEAR_GET_FOOTFALL_AREAS')

export const CREATE_FOOTFALL_AREAS = concat(MODULE_NAME, 'CREATE_FOOTFALL_AREAS')
export const CREATE_FOOTFALL_AREAS_SUCCESS = concat(MODULE_NAME, 'CREATE_FOOTFALL_AREAS_SUCCESS')
export const CREATE_FOOTFALL_AREAS_FAILURE = concat(MODULE_NAME, 'CREATE_FOOTFALL_AREAS_FAILURE')
export const CLEAR_CREATE_FOOTFALL_AREAS = concat(MODULE_NAME, 'CLEAR_CREATE_FOOTFALL_AREAS')

export const GET_FOOTFALL_DATA_AS_CSV = concat(MODULE_NAME, 'GET_FOOTFALL_DATA_AS_CSV')
export const GET_FOOTFALL_DATA_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_FOOTFALL_DATA_AS_CSV_SUCCESS')
export const GET_FOOTFALL_DATA_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_FOOTFALL_DATA_AS_CSV_FAILURE')
export const CLEAR_GET_FOOTFALL_DATA_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_FOOTFALL_DATA_AS_CSV')

export const BULK_UPLOAD_FOOTFALL_DATA = concat(MODULE_NAME, 'BULK_UPLOAD_FOOTFALL_DATA')
export const BULK_UPLOAD_FOOTFALL_DATA_SUCCESS = concat(MODULE_NAME, 'BULK_UPLOAD_FOOTFALL_DATA_SUCCESS')
export const BULK_UPLOAD_FOOTFALL_DATA_FAILURE = concat(MODULE_NAME, 'BULK_UPLOAD_FOOTFALL_DATA_FAILURE')
export const CLEAR_BULK_UPLOAD_FOOTFALL_DATA = concat(MODULE_NAME, 'CLEAR_BULK_UPLOAD_FOOTFALL_DATA')

export const UPDATE_FOOTFALL_AREA = concat(MODULE_NAME, 'UPDATE_FOOTFALL_AREA')
export const UPDATE_FOOTFALL_AREA_SUCCESS = concat(MODULE_NAME, 'UPDATE_FOOTFALL_AREA_SUCCESS')
export const UPDATE_FOOTFALL_AREA_FAILURE = concat(MODULE_NAME, 'UPDATE_FOOTFALL_AREA_FAILURE')
export const CLEAR_UPDATE_FOOTFALL_AREA = concat(MODULE_NAME, 'CLEAR_UPDATE_FOOTFALL_AREA')

export const DELETE_FOOTFALL_AREAS = concat(MODULE_NAME, 'DELETE_FOOTFALL_AREAS')
export const DELETE_FOOTFALL_AREAS_SUCCESS = concat(MODULE_NAME, 'DELETE_FOOTFALL_AREAS_SUCCESS')
export const DELETE_FOOTFALL_AREAS_FAILURE = concat(MODULE_NAME, 'DELETE_FOOTFALL_AREAS_FAILURE')
export const CLEAR_DELETE_FOOTFALL_AREAS = concat(MODULE_NAME, 'CLEAR_DELETE_FOOTFALL_AREAS')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Get footfall areas
export function getFootfallAreas(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_FOOTFALL_AREAS, params, loadOptions }
}

export function getFootfallAreasSuccess(data) {
  return { type: GET_FOOTFALL_AREAS_SUCCESS, data }
}

export function getFootfallAreasFailure(error) {
  return { type: GET_FOOTFALL_AREAS_FAILURE, error }
}

export function clearGetFootfallAreas() {
  return { type: CLEAR_GET_FOOTFALL_AREAS }
}

// Create footfall areas
export function createFootfallAreas(data) {
  return { type: CREATE_FOOTFALL_AREAS, data }
}

export function createFootfallAreasSuccess(data) {
  return { type: CREATE_FOOTFALL_AREAS_SUCCESS, data }
}

export function createFootfallAreasFailure(error) {
  return { type: CREATE_FOOTFALL_AREAS_FAILURE, error }
}

export function clearCreateFootfallAreas() {
  return { type: CLEAR_CREATE_FOOTFALL_AREAS }
}

// Get footfall data as csv
export function getFootfallDataAsCsv(params) {
  return { type: GET_FOOTFALL_DATA_AS_CSV, params }
}

export function getFootfallDataAsCsvSuccess(data, fileName) {
  return { type: GET_FOOTFALL_DATA_AS_CSV_SUCCESS, data, fileName }
}

export function getFootfallDataAsCsvFailure(error) {
  return { type: GET_FOOTFALL_DATA_AS_CSV_FAILURE, error }
}

export function clearGetFootfallDataAsCsv() {
  return { type: CLEAR_GET_FOOTFALL_DATA_AS_CSV }
}

// Bulk upload footfall data
export function bulkUploadFootfallData(data) {
  return { type: BULK_UPLOAD_FOOTFALL_DATA, data }
}

export function bulkUploadFootfallDataSuccess(data) {
  return { type: BULK_UPLOAD_FOOTFALL_DATA_SUCCESS, data }
}

export function bulkUploadFootfallDataFailure(error) {
  return { type: BULK_UPLOAD_FOOTFALL_DATA_FAILURE, error }
}

export function clearBulkUploadFootfallData() {
  return { type: CLEAR_BULK_UPLOAD_FOOTFALL_DATA }
}

// Update footfall area
export function updateFootfallArea(id, data) {
  return { type: UPDATE_FOOTFALL_AREA, id, data }
}

export function updateFootfallAreaSuccess(data) {
  return { type: UPDATE_FOOTFALL_AREA_SUCCESS, data }
}

export function updateFootfallAreaFailure(error) {
  return { type: UPDATE_FOOTFALL_AREA_FAILURE, error }
}

export function clearUpdateFootfallArea() {
  return { type: CLEAR_UPDATE_FOOTFALL_AREA }
}

// Delete footfall areas
export function deleteFootfallAreas(id) {
  return { type: DELETE_FOOTFALL_AREAS, id }
}

export function deleteFootfallAreasSuccess(id, data) {
  return { type: DELETE_FOOTFALL_AREAS_SUCCESS, id, data }
}

export function deleteFootfallAreasFailure(error) {
  return { type: DELETE_FOOTFALL_AREAS_FAILURE, error }
}

export function clearDeleteFootfallAreas() {
  return { type: CLEAR_DELETE_FOOTFALL_AREAS }
}

import React, { useState } from 'react'

import FormField from '../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import TemplatesSection from './TemplatesSection'
import FootfallDataFileUpload from './FootfallDataFileUpload'

import useFormWrapperStyles from '../../../../styles/common/formWrapper'

const UploadFootfallDataSection = () => {
  const formWrapperClasses = useFormWrapperStyles()

  const [fileData, setFileData] = useState(null)

  return (
    <div className={formWrapperClasses.formWrapper}>
      <TemplatesSection />

      <FormField label="Footfall .csv file upload" isLastFormField>
        <FootfallDataFileUpload fileData={fileData} setFileData={setFileData} />
      </FormField>
      <div className={formWrapperClasses.formFooter} />
    </div>
  )
}

export default UploadFootfallDataSection

import { getIn } from 'formik'
import { useCallback, useContext } from 'react'

import { ContractBuilderContext } from '../../../../../ContractBuilderContext'

import { sortByStartDateValue } from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { calc } from '../../../../../../../../../../helpers/numbers'
import calculateProductPrice from '../../../../helpers/calculateProductPrice'

import {
  CUSTOM_PRODUCT_DISCOUNT,
  CUSTOM_PRODUCT_PRICE,
  ORIGINAL_PRODUCT_PRICE,
  PRODUCT_GROUPED_PUBLICATIONS_DATES,
  QUANTITY
} from '../../../../../../fields'
import {
  calculateDiscountedPrice,
  getDecimalDiscount
} from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/price'

export function useSetPeriods({
  isNewProduct,
  selectedPeriods,
  values,
  setFieldValue,
  productItemPath,
  productPublicationsDatesPath
}) {
  const { skipCostOnPeriodsChange } = useContext(ContractBuilderContext)
  const shouldUpdateCustomCost = isNewProduct || !skipCostOnPeriodsChange

  const quantity = getIn(values, `${productItemPath}.${QUANTITY}`)
  const originalPrice = getIn(values, `${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`)
  const customDiscount = getIn(values, `${productItemPath}.${CUSTOM_PRODUCT_DISCOUNT}`)
  const selectedGroupedPeriods = getIn(values, `${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`)

  const handleCustomCost = useCallback(
    newCustomPrice => {
      if (shouldUpdateCustomCost) {
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, newCustomPrice, false)
      } else if (!shouldUpdateCustomCost && customDiscount) {
        // in case of the Amendments, when original contract had set custom discount:
        // hardcode the custom price and reset the custom discount to be 0
        // so the cost stays the same on update, and discount become calculated
        const originalDiscountedPrice = calculateDiscountedPrice(
          originalPrice,
          quantity,
          getDecimalDiscount(customDiscount)
        )
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_DISCOUNT}`, 0, false)
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, originalDiscountedPrice, false)
      }
    },
    [customDiscount, originalPrice, productItemPath, quantity, setFieldValue, shouldUpdateCustomCost]
  )

  const handleRemovePeriodGroup = useCallback(
    ({ periodGroup, index }) => {
      // remove period group by index from array selectedGroupedPeriods:
      const newGroupsOfPeriods = [...selectedGroupedPeriods]
      newGroupsOfPeriods.splice(index, 1)

      const newSelectedPeriods = selectedPeriods.filter(item => {
        // find and remove all periods from selectedPeriods that are in removed group(several periods)
        return !periodGroup.find(period => {
          if (period.inventory && item.inventory) {
            return period.inventory === item.inventory
          } else {
            // fallback to remove old periods which were added without inventory id
            return period.date_start === item.date_start
          }
        })
      })

      // new periods:
      setFieldValue(productPublicationsDatesPath, newSelectedPeriods, false)
      setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, newGroupsOfPeriods, false)

      // reset prices:
      const calculatedPrice = calculateProductPrice(newSelectedPeriods, 1, 0)
      setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, calculatedPrice.totalPrice)
      // apply quantity to the price and reset custom price
      const customPrice = calc(calculatedPrice.totalPrice).mul(quantity).toDP(2).toNumber()

      handleCustomCost(customPrice)
    },
    [
      selectedGroupedPeriods,
      selectedPeriods,
      setFieldValue,
      productPublicationsDatesPath,
      productItemPath,
      quantity,
      handleCustomCost
    ]
  )

  const setNewPeriods = useCallback(
    newPeriods => {
      // push new periods to manage the selected dates in the form, send to BE and etc..
      // newly added periods should not just be pushed, these should be set by startDate order
      const sortedNewPeriods = [...selectedPeriods, ...newPeriods].sort(sortByStartDateValue)
      // push new group of periods to represent selected date range
      const sortedNewGroupPeriods = [
        ...selectedGroupedPeriods,
        // push group of periods to already selected groups
        newPeriods
        // newly added periods should not just be pushed, these should be set by startDate order
      ].sort((a, b) => new Date(a[0].date_start) - new Date(b[0].date_start))

      const calculatedPrice = calculateProductPrice(sortedNewPeriods, 1, 0)
      const newTotalProductPrice = calculatedPrice.totalPrice

      // new periods:
      setFieldValue(productPublicationsDatesPath, sortedNewPeriods, false)
      setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, sortedNewGroupPeriods, false)
      // reset prices:
      setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, newTotalProductPrice, false)
      handleCustomCost(calculatedPrice.totalPrice)

      // reset quantity to 1 when user is setting up periods to avoid inconsistency when there are not enough
      setFieldValue(`${productItemPath}.${QUANTITY}`, 1)
    },
    [
      handleCustomCost,
      productItemPath,
      productPublicationsDatesPath,
      selectedGroupedPeriods,
      selectedPeriods,
      setFieldValue
    ]
  )

  return {
    setNewPeriods,
    handleRemovePeriodGroup
  }
}

import React from 'react'
import { useSelector } from 'react-redux'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import MediaTagsTable from './MediaTagsTable'
import MediaProductTagsCreate from '../../../../forms/Multiplatform/MediaProducts/MediaProductTags/MediaProductTagsCreate'
import MediaProductTagsEdit from '../../../../forms/Multiplatform/MediaProducts/MediaProductTags/MediaProductTagsEdit'

import { productTagsSelector } from '../../../../modules/selectors/tags'

const TagsTable = () => {
  const tags = useSelector(productTagsSelector)

  // update if we need to save new order for media product tags
  // const handleDragEnd = useCallback(
  // ({ destination, source }) => {
  //   if (destination && destination.index !== source.index) {
  //     const reorderedCategories = reorderElement(orderedMediaCategories, source.index, destination.index)
  //
  //     // dispatch(updateMediaPackageCategoriesOrder(reorderedCategoriesPayload))
  //   }
  // },
  // [dispatch, orderedMediaCategories]
  // )

  return (
    <ContentRow
      title="Tags"
      description="Tags are used to categorise media products. Users can use tags to filter product lists when booking media."
      leftColumnChildren={<MediaProductTagsCreate />}
    >
      <MediaTagsTable tags={tags} handleDragEnd={() => {}} />

      <MediaProductTagsEdit />
    </ContentRow>
  )
}

export default TagsTable

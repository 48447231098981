import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearGetMediaProductLocationCategories,
  clearGetMediaProductLocationRegions,
  getMediaProductLocationCategories,
  getMediaProductLocationRegions
} from '../../../../../../modules/actions/mediaOrdersProductLocations'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import { mediaSubCategoriesWasLoadedSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  mediaProductLocationCategoriesWasLoadedSelector,
  mediaProductLocationRegionsWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersProductLocations'

export const useFetchProductsTableData = ({ allowFetch }) => {
  const dispatch = useDispatch()

  const selectedControllerId = useSelector(selectedControllerIdSelector)
  const mediaSubCategoriesWasLoaded = useSelector(mediaSubCategoriesWasLoadedSelector)
  const mediaProductLocationRegionsWasLoaded = useSelector(mediaProductLocationRegionsWasLoadedSelector)
  const mediaProductLocationCategoriesWasLoaded = useSelector(mediaProductLocationCategoriesWasLoadedSelector)

  useEffect(() => {
    if (!mediaSubCategoriesWasLoaded && allowFetch) {
      dispatch(
        getMediaSubCategories({
          controller: selectedControllerId
        })
      )
    }
  }, [dispatch, mediaSubCategoriesWasLoaded, selectedControllerId, allowFetch])

  useEffect(() => {
    if (!mediaProductLocationRegionsWasLoaded && allowFetch) {
      dispatch(
        getMediaProductLocationRegions({
          controller: selectedControllerId,
          ordering: 'name',
          limit: 999
        })
      )
    }
  }, [dispatch, mediaProductLocationRegionsWasLoaded, selectedControllerId, allowFetch])

  useEffect(() => {
    if (!mediaProductLocationCategoriesWasLoaded && allowFetch) {
      dispatch(
        getMediaProductLocationCategories({
          controller: selectedControllerId,
          ordering: 'name',
          limit: 999
        })
      )
    }
  }, [dispatch, selectedControllerId, mediaProductLocationCategoriesWasLoaded, allowFetch])

  useEffect(() => {
    return () => {
      dispatch(clearGetMediaProductLocationRegions())
      dispatch(clearGetMediaProductLocationCategories())
      dispatch(clearGetMediaSubCategories())
    }
  }, [dispatch])
}

import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SelectPaginated from '../../../../components/SelectPaginated'

import { formatOptionsList } from '../../../formatters'
import { createJsonFromQueryString } from '../../../../helpers/url'

import { selectedControllerIdSelector } from '../../../../modules/selectors/app'
import { getMediaProductLocationsService } from '../../../../modules/services/mediaOrdersProductLocations'

import { LOCATION } from '../../../../forms/Multiplatform/MediaProducts/MediaProductForms/fields'

const LocationsSelectPaginated = ({
  formik,
  onLocationChange,
  defaultOptionsList,
  shouldFetchDefaultOptions,
  limit = 20,
  onLoadOptions
}) => {
  const { values, setFieldValue } = formik

  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const selectedLocation = values[LOCATION]

  const formatLocationsOptions = useCallback(
    locations =>
      formatOptionsList({
        list: locations,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const onChangeHandler = useCallback(
    option => {
      setFieldValue(LOCATION, option?.value)
      onLocationChange && onLocationChange(option)
    },
    [setFieldValue, onLocationChange]
  )

  const clearLocationHandler = useCallback(() => {
    setFieldValue(LOCATION, '')
    onLocationChange && onLocationChange(null)
  }, [setFieldValue, onLocationChange])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      const response = await getMediaProductLocationsService({
        search,
        ordering: 'name',
        controller: selectedControllerId,
        limit,
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newLocationsOptions = formatLocationsOptions(response.results)
      onLoadOptions && onLoadOptions(newLocationsOptions)

      return {
        options: newLocationsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [selectedControllerId, formatLocationsOptions, limit, onLoadOptions]
  )

  return (
    <SelectPaginated
      placeholder="Select Location"
      value={selectedLocation}
      onChange={onChangeHandler}
      loadOptions={loadOptions}
      defaultOptionsList={defaultOptionsList}
      shouldFetchDefaultOptions={shouldFetchDefaultOptions}
      showClearInputIcon={!!selectedLocation}
      onClearInput={clearLocationHandler}
    />
  )
}

LocationsSelectPaginated.propTypes = {
  formik: PropTypes.object.isRequired,
  onLocationChange: PropTypes.func,
  defaultOptionsList: PropTypes.array,
  shouldFetchDefaultOptions: PropTypes.bool,
  limit: PropTypes.number
}

export default LocationsSelectPaginated

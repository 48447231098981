import {
  formatCategoriesToBE,
  formatDigitalProvidersToBE,
  formatDiscountsToBE,
  transformFilesToBE
} from '../../formatters'

import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../../ReusableFormFields/PricePairFields/fields'
import { DIGITAL } from '../../../../../../constants/mediaOrders'
import { ORDER } from '../../../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  CREATIVE_DEADLINE_DAYS,
  DEFAULT_INVENTORY_DATE_START,
  DESCRIPTION,
  IMAGE,
  IMAGES,
  INTERNAL_ID,
  MEDIA_CATEGORY,
  NAME,
  PERIOD_OPTION,
  SUB_LOCATION,
  TAGS
} from './fields'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD, LOCATION, MAX_PERIODS, MIN_PERIODS, VARIABLES } from '../../fields'
import { formatDateToBE } from '../../../../../../constants/dates'
import { FILE_NAME, FILE_URL } from '../../../../../../constants/files'

export const formatLocationTabsToBE = values => {
  return {
    ...(values[LOCATION] && { [LOCATION]: values[LOCATION] }),
    ...(values[SUB_LOCATION] && { [SUB_LOCATION]: values[SUB_LOCATION] })
  }
}
export const formatImagesToBE = values => {
  return {
    // filter for removing last empty value because BE needs to have filled values
    [IMAGES]: values[IMAGES].filter(image => !!image[FILE_URL]).map(image => ({
      [IMAGE]: image[FILE_URL],
      [FILE_NAME]: image[FILE_NAME],
      [ORDER]: image[ORDER]
    }))
  }
}

export const formatTagsToBE = values => {
  return { [TAGS]: values[TAGS].map(item => item.value) }
}

export const formatVariablesToBE = productValues => {
  return {
    // ids of variables
    [VARIABLES]: productValues[VARIABLES].map(item => item.value)
  }
}

export const transformValuesToBE = ({ values, controllerId, discountPresets }) => {
  const transformedFiles = transformFilesToBE(values)

  return {
    controller: controllerId,
    [NAME]: values[NAME],
    ...(values[INVENTORY_AMOUNT] && { [INVENTORY_AMOUNT]: values[INVENTORY_AMOUNT] }),
    ...(values[INVENTORY_PERIOD] && { [INVENTORY_PERIOD]: values[INVENTORY_PERIOD] }),
    ...(values[DEFAULT_INVENTORY_DATE_START] && {
      [DEFAULT_INVENTORY_DATE_START]: formatDateToBE(new Date(values[DEFAULT_INVENTORY_DATE_START]))
    }),
    [MIN_PERIODS]: values[MIN_PERIODS],
    [MAX_PERIODS]: values[MAX_PERIODS],
    ...(values[DESCRIPTION] && { [DESCRIPTION]: values[DESCRIPTION] }),
    ...formatCategoriesToBE(values),
    period: values[PERIOD_OPTION],
    prices: values[PRICE_PAIRS].map(pricePair => ({
      price: pricePair[PRICE_VALUE],
      currency: pricePair[PRICE_CURRENCY]
    })),

    ...formatDiscountsToBE({ values, discountPresets }),

    ...(values[MEDIA_CATEGORY] !== DIGITAL && { [CREATIVE_DEADLINE_DAYS]: values[CREATIVE_DEADLINE_DAYS] }),
    ...(values[MEDIA_CATEGORY] === DIGITAL && formatDigitalProvidersToBE(values)),
    ...(transformedFiles && transformedFiles),

    ...formatImagesToBE(values),
    ...formatLocationTabsToBE(values),
    ...formatTagsToBE(values),
    ...(values[VARIABLES].length && formatVariablesToBE(values)),
    ...(values[INTERNAL_ID] && { [INTERNAL_ID]: values[INTERNAL_ID] })
  }
}

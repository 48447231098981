import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { isAfter } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

import InventoryRow from './InventoryPairFields/InventoryRow'
import ActionText from '../../../../../../components/ActionText'
import InventoryRowSkeleton from './InventoryRowSkeleton'

import { getNextInventoryDate } from '../helpers'

import {
  inventoryIsLoadingSelector,
  inventoryLastItemSelector,
  inventoryNextSelector,
  inventoryWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'

import {
  BOOKED_QUANTITY,
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY,
  INVENTORY_START_DATE
} from '../../../../MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import useDrawerFormStyles from '../../../../../../styles/common/drawerForms'
import useStyles from './styles'

const InventoryList = ({ formik, period, loadMoreHandler, isMoreInventoryItemsLoading }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const drawerFormClasses = useDrawerFormStyles()

  const inventoryIsLoading = useSelector(inventoryIsLoadingSelector)
  const inventoryWasLoaded = useSelector(inventoryWasLoadedSelector)
  const inventoryNext = useSelector(inventoryNextSelector)
  const { values, setFieldValue } = formik

  const [rowIndexInFocus, setRowIndexInFocus] = useState(null)

  const isMoreItemsLoading = useMemo(
    () =>
      (inventoryWasLoaded && inventoryNext && inventoryIsLoading) ||
      // when new portion of inventory is loaded we still need to wait when formik values will be updated
      // to avoid flickering loadMore button and Skeleton
      isMoreInventoryItemsLoading,
    [inventoryIsLoading, inventoryWasLoaded, inventoryNext, isMoreInventoryItemsLoading]
  )

  // inventoryInitialLastItem is the item that might be not fetched yet,
  // but we need to know it's date to set the next inventory date
  const inventoryInitialLastItem = useSelector(inventoryLastItemSelector)

  const validInventoryItems = useMemo(() => {
    // For optimization purposes, when we delete item -
    // we don't delete it right away, but we set it to null to avoid shifting all the elements
    return values[INVENTORY_LIST_DATA].filter(item => !!item)
  }, [values])

  const lastValidInventoryItem = useMemo(
    () => validInventoryItems[validInventoryItems.length - 1],
    [validInventoryItems]
  )

  const initialValueTemplate = useMemo(() => {
    const isInitialInventoryItemAfterItemInTheList =
      inventoryInitialLastItem &&
      lastValidInventoryItem &&
      isAfter(new Date(inventoryInitialLastItem.date_start), new Date(lastValidInventoryItem[INVENTORY_START_DATE]))

    const lastInventoryDate = isInitialInventoryItemAfterItemInTheList
      ? inventoryInitialLastItem.date_start
      : lastValidInventoryItem?.[INVENTORY_START_DATE]

    const lastInventoryQuantity = isInitialInventoryItemAfterItemInTheList
      ? inventoryInitialLastItem.quantity
      : lastValidInventoryItem?.[INVENTORY_QUANTITY]

    return {
      [INVENTORY_START_DATE]: getNextInventoryDate({
        lastInventoryDate,
        periodOption: period
      }),
      [INVENTORY_QUANTITY]: lastInventoryQuantity,
      [BOOKED_QUANTITY]: ''
    }
  }, [period, inventoryInitialLastItem, lastValidInventoryItem])

  const addNewRowHandler = useCallback(() => {
    const newInventoryArray = [...values[INVENTORY_LIST_DATA], { ...initialValueTemplate, id: uuidv4() }]

    setFieldValue(INVENTORY_LIST_DATA, newInventoryArray)
  }, [initialValueTemplate, values, setFieldValue])

  const onRowClickHandler = useCallback(index => {
    setRowIndexInFocus(index)
  }, [])

  return (
    <section className={drawerFormClasses.section}>
      <div className={classes.fieldsContainer}>
        {values[INVENTORY_LIST_DATA].map((item, index) => {
          // We need to check if the item is not null,
          // row data may be null because for optimization purposes when we delete the row,
          // we don't remove it from the list to avoid shifting all the elements, but set it to null instead
          if (values[INVENTORY_LIST_DATA]?.[index]) {
            return (
              <InventoryRow
                key={item.id}
                formik={formik}
                index={index}
                item={item}
                onRowClickHandler={onRowClickHandler}
                rowIndexInFocus={rowIndexInFocus}
              />
            )
          } else {
            return null
          }
        })}
        {loadMoreHandler && !isMoreItemsLoading && (
          <ActionText className={classes.loadMoreButton} onClick={loadMoreHandler} isDark>
            {t('Load more')}
          </ActionText>
        )}
        {isMoreItemsLoading && <InventoryRowSkeleton />}
        <ActionText className={classes.addNewRowButton} onClick={addNewRowHandler} isDark>
          {t('+ Add inventory period')}
        </ActionText>
      </div>
    </section>
  )
}

InventoryList.propTypes = {
  formik: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  loadMoreHandler: PropTypes.func,
  isMoreInventoryItemsLoading: PropTypes.bool
}

export default InventoryList

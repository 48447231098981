import { t } from 'i18next'

export const FOOTFALL_TYPE = 'entry_type'
export const FOOTFALL_TYPE_INTERNAL = 'internal'
export const FOOTFALL_TYPE_EXTERNAL = 'external'

export const footfallTypesOptions = [
  {
    value: FOOTFALL_TYPE_INTERNAL,
    label: t('Internal')
  },
  {
    value: FOOTFALL_TYPE_EXTERNAL,
    label: t('External')
  }
]

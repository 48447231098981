import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import FileFormatIcon from '../../../../../../../features/components/Icons/FileFormatIcon'
import { ReactComponent as FileIcon } from '../../../../../../../assets/icons/upload-file.svg'

import { openForm } from '../../../../../../../modules/actions/forms'

import { UPLOAD_BOOKED_MEDIA_FILES } from '../../../../../../../constants/forms'

import useStyles from './styles'

const NameField = ({ formats = [], uploadedFile, id, mediaId, requirementsFileName, handleFileDownloading }) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const fileName = uploadedFile?.name || requirementsFileName || 'Uploaded file'

  const showNumberOfFormats = 3
  const formatsThatAreShowing = formats.filter((format, index) => index < showNumberOfFormats)
  const hiddenNumberOfFormats = formats?.length > 3 ? formats?.length - showNumberOfFormats : 0

  const handleFileDownload = useCallback(() => {
    handleFileDownloading({
      url: uploadedFile?.file_url,
      file_name: uploadedFile?.name
    })
  }, [handleFileDownloading, uploadedFile])

  const openUploadFileForm = useCallback(() => {
    dispatch(
      openForm({
        id,
        formName: UPLOAD_BOOKED_MEDIA_FILES,
        otherParams: `mediaId=${mediaId}`
      })
    )
  }, [dispatch, id, mediaId])

  const handleFileClick = useCallback(() => {
    if (uploadedFile?.file_url) {
      handleFileDownload()
    } else {
      openUploadFileForm()
    }
  }, [uploadedFile, handleFileDownload, openUploadFileForm])

  return (
    <div className={classes.nameField}>
      <div className={classes.thumbnail}>
        {uploadedFile?.thumbnail ? (
          <img
            onClick={handleFileDownload}
            className={classes.filePreview}
            src={uploadedFile?.thumbnail}
            alt="preview"
          />
        ) : (
          // File may be uploaded but not have a thumbnail - in this case we show a default file icon, and allow to download the file
          <div onClick={handleFileClick}>
            <FileFormatIcon
              fileFormat={uploadedFile?.file_format}
              icon={<FileIcon />}
              className={classes.icon}
              fileFormatClassName={classes.fileFormat}
            />
          </div>
        )}
      </div>
      <div className={classes.nameFieldTexts}>
        <p className={classes.nameFieldTitle} onClick={handleFileClick}>
          {fileName}
        </p>
        {!!formats?.length && !uploadedFile && (
          <div className={classes.formats}>
            {formatsThatAreShowing?.map(format => (
              <div className={classes.formatField} key={format}>
                .{format}
              </div>
            ))}
            {hiddenNumberOfFormats > 0 && (
              <div className={classes.moreHiddenNumberOfFormats}>{`+ ${hiddenNumberOfFormats} more`}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

NameField.propTypes = {
  formats: PropTypes.array,
  uploadedFile: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mediaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  requirementsFileName: PropTypes.string,
  handleFileDownloading: PropTypes.func.isRequired
}

export default NameField

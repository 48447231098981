import { getIn } from 'formik'

import { insertIf } from '../../../../../../../helpers/common'
import { generateGroupLabel } from '../Cells/DatesCell/helpers'
import { getSelectedPeriodLabel } from '../Cells/DatesCell/Preview/Periods/helpers'

import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../fields'
import { DATA_COST, DATA_DISCOUNT, DATA_RATE, DATA_REACH } from '../constants'

export const getTableCopyFormat = showReachCol => [
  {
    header: 'Region',
    formatCellContent: ({ data }) => getIn(data, 'location.region.name') || ''
  },
  {
    header: 'Location',
    formatCellContent: ({ data }) => data?.location_name || ''
  },
  {
    header: 'Category',
    formatCellContent: ({ data }) => getIn(data, 'location.category.name') || ''
  },
  {
    header: 'Asset Type',
    formatCellContent: ({ data }) => data?.media_sub_category_name || ''
  },
  {
    header: 'Asset',
    formatCellContent: ({ data }) => data?.name || ''
  },
  {
    header: 'Asset ID',
    formatCellContent: ({ data }) => data?.internal_id || ''
  },
  {
    header: 'Dates',
    formatCellContent: productValues => {
      const selectedPeriodsGroups = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]

      return selectedPeriodsGroups.length > 0
        ? selectedPeriodsGroups.map(group => generateGroupLabel(group)).join(' / ')
        : ''
    }
  },
  {
    header: 'Qty.',
    formatCellContent: ({ data }) => data?.quantity || ''
  },
  {
    header: 'Period',
    formatCellContent: productValues => {
      const productData = productValues.data
      const productPeriod = productData?.period

      const groupedPeriods = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]
      const hasGroupedPeriods = groupedPeriods.length > 0

      return hasGroupedPeriods ? getSelectedPeriodLabel({ groupedPeriods, productPeriod }) : ''
    }
  },
  ...insertIf(showReachCol, {
    header: 'Reach',
    renderedDataAttribute: DATA_REACH
  }),
  {
    header: 'Rate',
    renderedDataAttribute: DATA_RATE,
    // Remove any currency symbol from cost and rate
    formatRenderedContent: content => (content ? content.replace(/[^\d.,]/g, '').trim() : '')
  },
  {
    header: 'Disc. %',
    renderedDataAttribute: DATA_DISCOUNT
  },
  {
    header: 'Cost',
    renderedDataAttribute: DATA_COST,
    // Remove any currency symbol from cost and rate
    formatRenderedContent: content => (content ? content.replace(/[^\d.,]/g, '').trim() : '')
  }
]

import React from 'react'

import ContentRow from '../../../../features/components/ContentSection/ContentRow'
import SpecificationsDocumentsTable from './SpecificationsDocumentsTable'
import SpecificationsDocumentCreate from '../../../../forms/Multiplatform/MediaProducts/MediaProductForms/specificationsDocumentForms/SpecificationsDocumentCreate'
import SpecificationsDocumentEdit from '../../../../forms/Multiplatform/MediaProducts/MediaProductForms/specificationsDocumentForms/SpecificationsDocumentEdit'

const SpecificationsDocuments = () => {
  return (
    <ContentRow
      title="Media Product Specifications Documents"
      description="Specifications PDF files that will be combined and attached to a booking confirmation email that detail the creative specifications for the booked media products."
      leftColumnChildren={<SpecificationsDocumentCreate />}
    >
      <SpecificationsDocumentsTable />

      <SpecificationsDocumentEdit />
    </ContentRow>
  )
}

export default SpecificationsDocuments

import React, { useMemo } from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import FileDetailsPreview from '../../../../../../../../components/FileDetailsPreview'
import Field from '../../../../../../../../components/Form/Field'

import useBlobUrl from '../../../useBlobUrl'
import { getFileType } from '../../../../../../../../features/helpers/other'

import { FILE_NAME, OPTION_NAME, OPTIONS } from '../../../fields'

const OptionFilePreview = ({ formik, optionFile, removeFileHandler }) => {
  const { values } = formik

  const optionId = optionFile.id

  // In create form we have access to file directly, in edit we don't hava file,
  // so we represent empty file placeholder
  const file = useMemo(() => getIn(values, `${OPTIONS}.${optionId}.file`), [values, optionId])

  const filePreviewType = useMemo(() => (file ? getFileType(file) : ''), [file])

  const blob = useBlobUrl(file)

  return (
    <FileDetailsPreview
      key={optionFile.id}
      fileName={optionFile[FILE_NAME]}
      previewUrl={blob}
      filePreviewType={filePreviewType}
      allowDelete={true}
      removeFile={() => removeFileHandler(optionFile)}
    >
      <Field formik={formik} name={`${OPTIONS}.${optionId}.${OPTION_NAME}`} placeholder="Option name" />
    </FileDetailsPreview>
  )
}

OptionFilePreview.propTypes = {
  formik: PropTypes.object.isRequired,
  optionFile: PropTypes.object.isRequired,
  removeFileHandler: PropTypes.func.isRequired
}

export default OptionFilePreview

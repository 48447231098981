import React, { useMemo } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { productTagsIsLoadingSelector, productTagsSelector } from '../../../../../../../../modules/selectors/tags'

import { formatOptionsList } from '../../../../../../../../features/formatters'

import { TAGS } from '../../../../MediaProductCreate/MediaProductCreateForm/fields'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'

const TagsSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  const tags = useSelector(productTagsSelector)
  const tagsIsLoading = useSelector(productTagsIsLoadingSelector)

  const tagsOptions = useMemo(() => {
    return formatOptionsList({
      list: tags,
      labelName: 'title',
      valueName: 'title'
    })
  }, [tags])

  const { errors, values, setFieldValue } = formik

  const handleCreateTag = newTag => {
    setFieldValue(TAGS, [...values[TAGS], { value: newTag, label: newTag }])
  }

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Tags (optional)')}</h3>
      <MultiSelectBox
        placeholder={t('Select or create tags')}
        name={TAGS}
        onCreateOption={handleCreateTag}
        options={tagsOptions}
        value={getIn(values, TAGS)}
        setFieldValue={setFieldValue}
        error={errors[TAGS]}
        isLoading={tagsIsLoading}
        touched
      />
    </div>
  )
}

export default TagsSection

import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../../../../features/components/Form/MultiSelectBox'

import { getLoadOptionsHandler } from '../../../../../../../../../features/helpers/componentsHelpers'
import { formatOptionsList } from '../../../../../../../../../features/formatters'

import { getMediaSubCategoriesService } from '../../../../../../../../../modules/services/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'

import { MEDIA_PRODUCT_ATTRIBUTE, VALUES } from '../../../../fields'

const MediaSubCategoriesPaginatedSelect = ({ formik }) => {
  const { values, setFieldValue } = formik

  const controllerId = useSelector(selectedControllerIdSelector)

  const formatSubCategories = useCallback(list => {
    return formatOptionsList({
      list,
      valueName: 'id',
      labelName: 'name'
    })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getMediaSubCategoriesService,
      params: {
        controller: controllerId,
        ordering: 'name'
      },
      formatOptions: formatSubCategories
    })
  }, [controllerId, formatSubCategories])

  return (
    <MultiSelectBox
      setFieldValue={setFieldValue}
      name={`${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`}
      placeholder="Select"
      loadOptions={loadOptions}
      value={getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`)}
      portaled={true}
    />
  )
}

export default MediaSubCategoriesPaginatedSelect

import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getIn } from 'formik'

import MultiSelectBox from '../../../../../../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../../../../../../features/formatters'
import { getLoadOptionsHandler } from '../../../../../../../../../features/helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'
import { getMediaProductLocationsService } from '../../../../../../../../../modules/services/mediaOrdersProductLocations'

import { MEDIA_PRODUCT_ATTRIBUTE, VALUES } from '../../../../fields'

const LocationsPaginatedSelect = ({ formik }) => {
  const { values, setFieldValue } = formik

  const controllerId = useSelector(selectedControllerIdSelector)

  const formatLocations = useCallback(
    locations =>
      formatOptionsList({
        list: locations,
        valueName: 'id',
        labelName: 'name'
      }),
    []
  )

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getMediaProductLocationsService,
      params: {
        controller: controllerId,
        ordering: 'name',
        fields: ['id', 'name'].join(',')
      },
      formatOptions: formatLocations
    })
  }, [controllerId, formatLocations])

  return (
    <MultiSelectBox
      setFieldValue={setFieldValue}
      name={`${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`}
      placeholder="Select"
      loadOptions={loadOptions}
      value={getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`)}
      portaled={true}
    />
  )
}

export default LocationsPaginatedSelect

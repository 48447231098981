import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MultiRowFields from '../../../../../../../../features/components/Form/MultiRowFields'
import PricePairFields from '../../../../../../../ReusableFormFields/PricePairFields'

import {
  PRICE_CURRENCY,
  PRICE_PAIRS,
  PRICE_VALUE,
  pricePairInitialValue
} from '../../../../../../../ReusableFormFields/PricePairFields/fields'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import DiscountsSection from './DiscountsSection'

import { discountPresetsSelector } from '../../../../../../../../modules/selectors/discounts'

import { inChargePeriods } from '../../../../../../../../constants/mediaOrders'
import { DISCOUNT_PAIRS, PERIOD_OPTION } from '../../fields'

const stepFields = [PRICE_PAIRS, PRICE_CURRENCY, PRICE_VALUE, DISCOUNT_PAIRS]

function PricingStep({ formik, handleStepChange }) {
  const { t } = useTranslation()

  const discountPresets = useSelector(discountPresetsSelector)

  const { values } = formik

  const period = values[PERIOD_OPTION]
  const periodLabel = inChargePeriods.find(p => p.value === period)?.label

  const handleContinue = () => {
    handleStepChange(stepFields, values)
  }

  return (
    <Step
      stepTitle="Set the product pricing"
      stepDescription="Enter the price of the media product and any discounts"
      handleContinue={handleContinue}
    >
      <FieldsSection title="Pricing and discounts">
        <FieldRow title="Price" description={`The cost of this media product for each ${periodLabel} period.`}>
          <MultiRowFields
            formik={formik}
            initialValueTemplate={pricePairInitialValue}
            mainValueName={PRICE_PAIRS}
            subValueNames={[PRICE_CURRENCY, PRICE_VALUE]}
            FieldsComponent={PricePairFields}
            addNewRowText="+ Another currency"
          />
        </FieldRow>
        <FieldRow
          title="Discounts (optional)"
          description={
            <>
              {Boolean(discountPresets.length) && (
                <>
                  <p>{t('Apply a discount preset or custom discounts to this product.')}</p>
                  <p>{t('Presets apply discounts for multiple products and periods.')}</p>
                </>
              )}
              <p>{t('Custom discounts apply only to this product for multiple periods.')}</p>
            </>
          }
        >
          <DiscountsSection formik={formik} />
        </FieldRow>
      </FieldsSection>
    </Step>
  )
}

export default PricingStep

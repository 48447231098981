import React from 'react'
import Skeleton from 'react-loading-skeleton'

import useStyles from './styles'

const ProductsCalendarSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.productsCalendar}>
      <div className={classes.calendarToggle}>
        <Skeleton width={100} />
      </div>
    </div>
  )
}

export default ProductsCalendarSkeleton

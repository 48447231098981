import { useContext, useMemo } from 'react'

import { formatOptionsList } from '../../../../../../../features/formatters'

import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

export const useBrandsOptions = () => {
  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const brands = contextSelfAccountData?.brands

  const brandsOptions = useMemo(() => {
    return formatOptionsList({
      list: brands,
      valueName: 'id',
      labelName: 'brand_name'
    })
  }, [brands])

  return brandsOptions
}

import { createStyles } from '../../../../../../../styles/helpers'
import { phonesDown } from '../../../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  rightSide: {
    maxWidth: 260
  },
  fileSection: {
    minWidth: 480
  },
  checkboxRequired: {
    marginTop: [0, '!important']
  },

  [`@media screen and (${phonesDown})`]: {
    fileSection: {
      minWidth: '100%'
    }
  }
})

export default useStyles

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useContext, useMemo } from 'react'

import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import { updateContract } from '../../../../../../modules/actions/contracts'
import {
  activeContractSelector,
  contractBuilderCalculatedDiscountCostSelector
} from '../../../../../../modules/selectors/contracts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS } from '../../fields'
import { getCommonProposalManageData, transformValuesToBE } from '../../formatters'
import { useShouldCheckApproval } from '../../../../../../pages/Proposals/QuotationBuilderPage/ProposalBookOrSend/ProposalMediaOrderGenerate/useShouldCheckApproval'

export default function useSaveData({ values, allowEdit, allowAutoSave }) {
  const dispatch = useDispatch()
  const activeContract = useSelector(activeContractSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const calculatedDiscountCost = useSelector(contractBuilderCalculatedDiscountCostSelector)

  const createdContractId = activeContract?.id
  const contractStatus = activeContract?.status
  const proposalWasSent = activeContract?.quotation_sent
  const contractCostWithDiscount = activeContract?.media_order.cost_with_discount

  const { contextSelfAccountData, currency } = useContext(MediaOrderFormContext)
  const { hasProposalApprovalFlow, isPackageApprover } = useShouldCheckApproval(contractStatus)

  const handleSaveDataToBE = useCallback(
    ({ updatedProductValues }) => {
      if (createdContractId && allowEdit && allowAutoSave) {
        // update contract only if it was created
        // the contract creating is made in the onSubmit function
        const transformedData = transformValuesToBE({
          selfAccountData: contextSelfAccountData,
          controllerId,
          selectedCurrency: currency,
          values: {
            ...values,
            [MEDIA_PRODUCTS]: updatedProductValues
          }
        })

        const commonProposalData = getCommonProposalManageData({
          contextSelfAccountData,
          controllerId,
          hasProposalApprovalFlow,
          isPackageApprover,
          contractStatus,
          proposalWasSent,
          oldCostWithDiscount: contractCostWithDiscount,
          transformedData,
          calculatedDiscountCost
        })

        const contractData = {
          detail: transformedData,
          ...commonProposalData
        }

        dispatch(updateContract(createdContractId, contractData))
      }
    },
    [
      createdContractId,
      allowEdit,
      allowAutoSave,
      contextSelfAccountData,
      controllerId,
      currency,
      values,
      hasProposalApprovalFlow,
      isPackageApprover,
      contractStatus,
      proposalWasSent,
      contractCostWithDiscount,
      calculatedDiscountCost,
      dispatch
    ]
  )

  return useMemo(
    () => ({
      handleSaveDataToBE
    }),
    [handleSaveDataToBE]
  )
}

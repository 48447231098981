import React, { useRef, useState, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions
} from '@floating-ui/react'
import classnames from 'classnames'

export const arrowHeight = 6
const defaultGap = 2
const arrowOffset = arrowHeight + defaultGap

const TooltipContext = React.createContext(null)

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

function Tooltip({ placement = 'top', children, style, className, offsetSize = 0 }) {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const middleware = useMemo(
    () => [
      offset(arrowOffset + offsetSize),
      arrow({ element: arrowRef }),
      flip({ fallbackAxisSideDirection: 'start' }),
      shift()
    ],
    [offsetSize]
  )

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
    middleware
  })

  // Memoize interaction options
  const interactionOptions = useMemo(() => ({ move: false }), [])
  const roleOptions = useMemo(() => ({ role: 'tooltip' }), [])

  // Event listeners to change the open state
  const hover = useHover(context, interactionOptions)
  const focus = useFocus(context)
  // adds the ability to dismiss the tooltip when the user presses the esc key.
  const dismiss = useDismiss(context)
  const role = useRole(context, roleOptions)

  // Merge all the interactions into prop getters
  const interactions = useInteractions([hover, focus, dismiss, role])

  return (
    <TooltipContext.Provider
      value={{
        isOpen,
        setIsOpen,
        ...interactions,
        refs,
        floatingStyles,
        context,
        arrowRef
      }}
    >
      <div className={classnames('tooltip', className)} style={style}>
        {children}
      </div>
    </TooltipContext.Provider>
  )
}

Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  offsetSize: PropTypes.number,
  children: PropTypes.node.isRequired
}

export default Tooltip

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import ContractFieldWrapper from '../ContractFieldWrapper'
import AccountSelection from '../../AccountSelection'

import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'

import useStyles from './styles'

const AccountField = ({ formik, isEditContract, isEditMode }) => {
  const classes = useStyles()

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const { setFieldValue } = formik

  // allow to switch the account for created contract, but limit the currency for edit contract
  // the currency of new account should be the same, as the currency of the selected account for the contract
  // the reason of limitation is that the currency is used for media products filtering
  const currencyLimiter = isEditContract && contextSelfAccountData?.currency

  const preview = contextSelfAccountData?.name

  return (
    <ContractFieldWrapper label="Account" maxWidth={'145px'} isEditMode={isEditMode} preview={preview}>
      <AccountSelection
        currencyLimiter={currencyLimiter}
        setFieldValue={setFieldValue}
        className={classes.accountField}
      />
    </ContractFieldWrapper>
  )
}

AccountField.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditContract: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired
}

export default AccountField

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { tableColumnsSize } from '../../../ProductLocations/ProductLocationsTable'
import Table from '../../../../../../components/Table'
import AdaptiveActions from '../../../../../../features/components/AdaptiveActions'

import {
  deleteMediaProductVariableIdSelector,
  deleteMediaProductVariableWasDeletedSelector,
  mediaProductVariablesSelector,
  updateMediaProductVariableIdSelector
} from '../../../../../../modules/selectors/mediaOrdersProductVariables'
import { openForm } from '../../../../../../modules/actions/forms'
import {
  clearDeleteMediaProductVariable,
  deleteMediaProductVariable
} from '../../../../../../modules/actions/mediaOrdersProductVariables'

import { MEDIA_PRODUCT_VARIABLE_EDIT } from '../../../../../../constants/forms'

import useProductCardsStyles from '../../../../MediaProducts/productCardStyles'
import { phonesDownSize } from '../../../../../../styles/const/breakpoints'

const ProductVariablesTableData = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const { t } = useTranslation()

  const deleteMediaProductVariableWasDeleted = useSelector(deleteMediaProductVariableWasDeletedSelector)
  const updateMediaProductVariableId = useSelector(updateMediaProductVariableIdSelector)
  const deleteMediaProductVariableId = useSelector(deleteMediaProductVariableIdSelector)
  const itemUpdatingId = updateMediaProductVariableId || deleteMediaProductVariableId

  const classes = useProductCardsStyles()

  const mediaProductVariables = useSelector(mediaProductVariablesSelector)

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ formName: MEDIA_PRODUCT_VARIABLE_EDIT, id }))
    },
    [dispatch]
  )

  const deleteItemHandler = useCallback(
    (e, id) => {
      dispatch(deleteMediaProductVariable(id))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit Variable'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete Variable'),
        onClickHandler: deleteItemHandler
      }
    ]
  }, [openEditFormHandler, deleteItemHandler, t])

  const productVariablesColumns = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        Cell: data => <AdaptiveActions itemId={data.id} options={dropdownOptions} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, dropdownOptions]
  )

  useEffect(() => {
    if (deleteMediaProductVariableWasDeleted) {
      dispatch(clearDeleteMediaProductVariable())
    }
  }, [dispatch, deleteMediaProductVariableWasDeleted])

  return (
    <Table
      hideHeadlineRow
      data={mediaProductVariables}
      cols={productVariablesColumns}
      itemUpdatingId={itemUpdatingId}
      tableId="ProductVariablesTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

export default ProductVariablesTableData

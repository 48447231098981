import { createStyles } from '../../../../../../../styles/helpers'

const useStyles = createStyles({
  recipientContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  recipientInfo: {
    flexGrow: 1
  }
})

export default useStyles

import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  infoTitle: {
    marginBottom: 8
  },
  infoMessageContainer: {
    margin: '0 auto',
    paddingTop: '100px',
    maxWidth: 550,
    textAlign: 'center'
  },
  download: {
    margin: '40px auto 0'
  }
})

export default useStyles

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import OptionFilePreview from '../OptionFilePreview'
import ActionText from '../../../../../../../../components/ActionText'
import VariableOptionsListSkeleton from './VariableOptionsListSkeleton'

import { createJsonFromQueryString } from '../../../../../../../../helpers/url'

import {
  bookingVariableOptionsNextSelector,
  bookingVariableSelector,
  getBookingVariableOptionsIsLoadingSelector
} from '../../../../../../../../modules/selectors/mediaOrdersBookings'
import { getBookingVariableOptions } from '../../../../../../../../modules/actions/mediaOrdersBookings'

import { OPTIONS } from '../../../fields'
import { DELETED_OPTIONS_IDS } from '../../../BookingVariableEdit/BookingVariableEditForm/fields'

import useStyles from './styles'

const VariableOptionsList = ({ formik }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()

  const bookingVariable = useSelector(bookingVariableSelector)
  const bookingVariableOptionsNext = useSelector(bookingVariableOptionsNextSelector)
  const getBookingVariableOptionsIsLoading = useSelector(getBookingVariableOptionsIsLoadingSelector)

  const { values, setFieldValue } = formik

  const options = values[OPTIONS]

  const removeFileHandler = useCallback(
    optionFile => {
      const optionId = optionFile.id
      const newOptions = { ...options }
      delete newOptions[optionId]
      setFieldValue(OPTIONS, newOptions)

      // update deleted options ids
      const deletedOptionsIds = values[DELETED_OPTIONS_IDS] || []
      if (!deletedOptionsIds.includes(optionId)) {
        setFieldValue(DELETED_OPTIONS_IDS, [...deletedOptionsIds, optionId])
      }
    },
    [setFieldValue, values, options]
  )

  const loadMoreVariableOptionsHandler = useCallback(() => {
    dispatch(
      getBookingVariableOptions({
        media_product_variable_ids: [bookingVariable.id],
        ...createJsonFromQueryString(`?${bookingVariableOptionsNext.split('?')[1]}`)
      })
    )
  }, [dispatch, bookingVariable.id, bookingVariableOptionsNext])

  return (
    <>
      {Object.values(values[OPTIONS]).map(optionFile => {
        return (
          <OptionFilePreview
            key={optionFile.id}
            formik={formik}
            optionFile={optionFile}
            removeFileHandler={removeFileHandler}
          />
        )
      })}

      {getBookingVariableOptionsIsLoading && <VariableOptionsListSkeleton />}

      {bookingVariableOptionsNext && !getBookingVariableOptionsIsLoading && (
        <ActionText onClick={loadMoreVariableOptionsHandler} className={classes.loadMoreOptionsButton}>
          {t('Load more')}
        </ActionText>
      )}
    </>
  )
}

export default VariableOptionsList

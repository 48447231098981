import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import FormField from '../../../EditProfile/UserProfileContent/EditProfileFormContent/FormField'
import TemplateTable from './TemplateTable'
import Button from '../../../../../components/Button'
import Icon from '../../../../../components/Icon'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download-square.svg'

import { useFormatGuidelinesColumns } from './useFormatGuidelinesColumns'
import { useExampleColumns } from './useExampleColumns'
import { downloadStaticFileByBrowser } from '../../../../../helpers/other'

import useStyles from './styles'

const TemplatesSection = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const guidelinesColumns = useFormatGuidelinesColumns()
  const exampleColumns = useExampleColumns()

  const handleDownloadInternal = useCallback(() => {
    downloadStaticFileByBrowser({
      url: '/fonts/files/internal_footfall_template.csv',
      fileName: 'internal_footfall_template',
      fileFormat: 'csv'
    })
  }, [])

  const handleDownloadExternal = useCallback(() => {
    downloadStaticFileByBrowser({
      url: '/fonts/files/external_footfall_template.csv',
      fileName: 'external_footfall_template',
      fileFormat: 'csv'
    })
  }, [])

  return (
    <FormField childrenClassName={classes.templates}>
      <h4 className={classes.templatesHeadline}>{t('Is your footfall data prepared correctly?')}</h4>
      <p>
        {t(
          'Footfall data should be uploaded as csv files. External and Internal data should be uploaded in separate files.'
        )}
      </p>
      <ul className={classes.columnList}>
        <li className={classes.columnListItem}>
          {t('External data uploads should have an')} <strong>{t('Area')}</strong>{' '}
          {t('column with the ID of the external area.')}
        </li>
        <li className={classes.columnListItem}>
          {t('Internal data uploads should have a')} <strong>{t('Store')}</strong>{' '}
          {t('column with the ID of the store.')}
        </li>
        <li className={classes.columnListItem}>
          {t('All data uploads should have a')} <strong>{t('Date')},</strong> <strong>{t('Reach')}</strong> {t('and')}{' '}
          <strong>{t('Period type')}</strong> column.
        </li>
      </ul>
      <p>
        {t(
          'Footfall can be provided as daily, weekly or fortnightly reach. The Date provided should be the first day of the period (i.e. if weekly, the first day of the week period, if fortnightly, the first day of the fortnight period).'
        )}
      </p>
      <h4 className={classes.templatesHeadline}>{t('File format guidelines:')}</h4>
      <TemplateTable columns={guidelinesColumns} />
      <h4 className={classes.templatesHeadline}>
        {t('A correctly formatted footfall file will look something like this:')}
      </h4>
      <TemplateTable columns={exampleColumns} />
      <div className={classes.downloadTemplatesButtons}>
        <Button isSmall className={classes.downloadTemplateButton} onClick={handleDownloadInternal}>
          <Icon>
            <DownloadIcon />
          </Icon>
          {t('Download internal template')}
        </Button>
        <Button isSmall className={classes.downloadTemplateButton} onClick={handleDownloadExternal}>
          <Icon>
            <DownloadIcon />
          </Icon>
          {t('Download external template')}
        </Button>
      </div>
    </FormField>
  )
}

export default TemplatesSection

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import ReviewProcessModal from '../ProposalModals/ReviewProcessModal'
import DisapproveDiscountModal from '../ProposalModals/DisapproveDiscountModal'

import { useApprovalProcess } from '../ProposalMediaOrderGenerate/useApprovalProcess'

import { activeContractSelector } from '../../../../../modules/selectors/contracts'

const ProposalApprovalReview = ({ contractId, checkContractValidity, isDisabled }) => {
  const { t } = useTranslation()

  const activeContract = useSelector(activeContractSelector)

  const {
    isMatchRequirements,
    isApprovalModalOpen,
    approvalModalData,
    handleCloseApprovalModal,
    isDisapprovalModalOpen,
    handleOpenDisapprovalModal,
    handleCloseDisapprovalModal
  } = useApprovalProcess({ contractData: activeContract })

  const handleOpenApproveModal = useCallback(async () => {
    const isContractValid = await checkContractValidity()

    if (isContractValid) {
      isMatchRequirements()
    }
  }, [checkContractValidity, isMatchRequirements])

  const handleDisapproveReview = useCallback(() => {
    handleOpenDisapprovalModal()
    handleCloseApprovalModal()
  }, [handleCloseApprovalModal, handleOpenDisapprovalModal])

  return (
    <>
      <ReviewProcessModal
        isOpen={isApprovalModalOpen}
        onDisapprove={handleDisapproveReview}
        onClose={handleCloseApprovalModal}
        data={approvalModalData}
      />
      <DisapproveDiscountModal
        contractId={contractId}
        isOpen={isDisapprovalModalOpen}
        onClose={handleCloseDisapprovalModal}
      />
      {/*If proposal is status pending_approval and user has is_package_approver permission, they will see a Review discount button:*/}
      <Button solid={true} onClick={handleOpenApproveModal} disabled={isDisabled}>
        {t('Review discount')}
      </Button>
    </>
  )
}

ProposalApprovalReview.propTypes = {
  contractId: PropTypes.number
}

export default ProposalApprovalReview

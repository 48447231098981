import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ApplyDiscount from './ApplyDiscount'
import ButtonChangeText from '../../../../../../features/components/Buttons/ButtonChangeText'

import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy-icon.svg'
import { ContractBuilderContext } from '../ContractBuilderContext'

import { triggerTableCopy } from '../../../../../../modules/actions/app'

import { QUOTATION_BUILDER_TABLE_ID } from '../ProductsTable'

import useStyles from './styles'

function TableActions({ values, products, setFieldValue, isAmendment }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { editProductId } = useContext(ContractBuilderContext)

  const handleCopy = useCallback(() => {
    // allow to copy table data to clipboard with the format to paste in excel or sheets
    dispatch(triggerTableCopy(QUOTATION_BUILDER_TABLE_ID))
  }, [dispatch])

  return (
    <div className={classes.tableActions}>
      {!isAmendment && <ApplyDiscount values={values} setFieldValue={setFieldValue} products={products} />}
      <ButtonChangeText
        className={classes.copyTableBtn}
        type="button"
        // disable button when editing a product is in progress
        disabled={Boolean(editProductId)}
        onClick={handleCopy}
        isSmall={true}
        textTimeout={2000}
        textToChange={t('Copied!')}
      >
        <CopyIcon />
        {t('Copy table')}
      </ButtonChangeText>
    </div>
  )
}

TableActions.propTypes = {
  values: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isAmendment: PropTypes.bool
}

export default TableActions

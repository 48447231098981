import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import ContractBuilder from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder'
import ProposalInfo from './ProposalInfo'

import { useHandleSubmitForm } from './useHandleSubmitForm'
import useRequestHandler from '../../../../hooks/useRequestHandler'
import { useRequiredFields } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'

import { usePurifiedFormik } from '../../../../hooks/formHooks/usePurifiedFormik'

import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { activeContractSelector, contractSelector } from '../../../../modules/selectors/contracts'
import { bookingVariablesSelector } from '../../../../modules/selectors/mediaOrdersBookings'

import { QUOTATION_CREATE } from '../../../../constants/forms'

import { getInitialValues } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'
import { getValidationSchema } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'

import useStyles from './styles'

function ProposalCreateForm() {
  const classes = useStyles()

  const { contextSelfAccountData, setFormikSetFieldValue, setFormikSetTouched } = useContext(MediaOrderFormContext)

  // loaded contract is used to get initial values for the form
  const loadedContract = useSelector(contractSelector)

  // active contract is used to check if we are in edit mode(user may crete new contract or edit existing one)
  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id

  const bookingVariables = useSelector(bookingVariablesSelector)

  // by default force user to see additional info edit
  const showAdditionalInfoEdit = useMemo(() => !createdContractId, [createdContractId])
  const [isAdditionalInfoEdit, setIsAdditionalInfoEdit] = useState(showAdditionalInfoEdit)

  const {
    onSubmit,
    successSubmit,
    successSelector,
    errorSelector,
    isLoadingSelector,
    clearSubmitHandler,
    setEditMode
  } = useHandleSubmitForm()

  const initialValues = useMemo(() => {
    return getInitialValues(loadedContract, bookingVariables)
  }, [loadedContract, bookingVariables])

  const { askBrandName, askCampaignName, isBrandRequired, askBuyer } = useRequiredFields(contextSelfAccountData)
  const validationSchema = useMemo(() => {
    return getValidationSchema({
      askBrandName,
      askCampaignName,
      isBrandRequired,
      askBuyer,
      askVariables: true
    })
  }, [askBrandName, askBuyer, askCampaignName, isBrandRequired])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)
  const { setFieldValue, setTouched } = purifiedFormik

  const handleSuccessSubmit = useCallback(() => {
    // set edit more after successful creation to use update handlers and selectors
    setEditMode(true)
  }, [setEditMode])

  useEffect(() => {
    // provide formik setFieldValue to the MediaOrder context
    // By wrapping it in an arrow function, we explicitly ensure that setFormikSetFieldValue is set as a function that returns setFieldValue.
    setFormikSetFieldValue(() => setFieldValue)
    setFormikSetTouched(() => setTouched)
  }, [setFieldValue, setTouched, setFormikSetFieldValue, setFormikSetTouched])

  // FORM doesn't have submit button, so clear and success handlers are not working
  useRequestHandler({
    // after form submit
    clearHandler: clearSubmitHandler,
    isLoadingRequestSelector: isLoadingSelector,
    successRequestSelector: successSelector,
    errorRequestSelector: errorSelector,
    onSuccessSubmit: handleSuccessSubmit
  })

  return (
    <Form
      formik={purifiedFormik}
      formName={QUOTATION_CREATE}
      showSubmit={false}
      className={classes.quotationsProductsTable}
      // processing
      successSubmit={successSubmit}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
    >
      <ContractBuilder
        formik={purifiedFormik}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        setIsAdditionalInfoEdit={setIsAdditionalInfoEdit}
      >
        <ProposalInfo />
      </ContractBuilder>
    </Form>
  )
}

export default ProposalCreateForm

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import AskReasonModal from '../../../../../../features/components/Modals/AskReasonModal'

import { clearUpdateContract, updateContract } from '../../../../../../modules/actions/contracts'
import {
  updateContractErrorSelector,
  updateContractIsLoadingSelector,
  contractWasUpdatedSelector
} from '../../../../../../modules/selectors/contracts'

import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'

const DisapproveDiscountModal = ({ isOpen, onClose, contractId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    values => {
      dispatch(
        updateContract(
          contractId,
          {
            status: 'disapproved',
            disapproval_reason: values[DISAPPROVED_REASON],
            generate_pdf: 'false' // should be string
          },
          {
            requestMethod: 'PATCH'
          }
        )
      )
    },
    [contractId, dispatch]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearUpdateContract())
  }, [dispatch])

  return (
    <AskReasonModal
      title={t('Disapproval Reason')}
      confirmButtonText={t('Disapprove')}
      cancelButtonText={t('Cancel')}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      ModalMessage={t(
        'We will notify the user that uploaded this file that it has been disapproved. Please provide a reason.'
      )}
      successSelector={contractWasUpdatedSelector}
      errorSelector={updateContractErrorSelector}
      isLoadingSelector={updateContractIsLoadingSelector}
      clearFormHandler={clearHandler}
      onSuccessSubmit={onClose}
    />
  )
}

DisapproveDiscountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contractId: PropTypes.number
}

export default DisapproveDiscountModal

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ContentSection from '../../../../../../../features/components/ContentSection'
import ContentRow from '../../../../../../../features/components/ContentSection/ContentRow'
import Field from '../../../../../../../components/Form/Field'
import Checkbox from '../../../../../../../components/Form/Checkbox'
import MultipleOptionsField from '../../../../../../ReusableFormFields/MultipleOptionsField'

import { NAME } from '../../../../MediaProductLocationForms/fields'
import { CAN_SELECT_MULTIPLE, OPTIONS, REQUIRED } from '../../../fields'
import {
  getMediaProductVariableOptionsIsLoadingSelector,
  mediaProductVariableOptionsNextSelector,
  mediaProductVariableOptionsWasLoadedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProductVariables'

const ProductVariableFormContent = ({ formik, loadMoreVariableOptionsHandler, productVariableId }) => {
  const { setFieldValue, values } = formik

  const getMediaProductVariableOptionsIsLoading = useSelector(
    getMediaProductVariableOptionsIsLoadingSelector(productVariableId)
  )
  const mediaProductVariableOptionsWasLoaded = useSelector(
    mediaProductVariableOptionsWasLoadedSelector(productVariableId)
  )
  const mediaProductVariableOptionsNext = useSelector(mediaProductVariableOptionsNextSelector(productVariableId))

  const checkboxHandler = useCallback(
    e => {
      const { name } = e.target
      setFieldValue(name, !values[name])
    },
    [values, setFieldValue]
  )

  return (
    <ContentSection title="Product Variable">
      <ContentRow title="Name">
        <Field formik={formik} id={NAME} name={NAME} placeholder="Name" />
      </ContentRow>
      <ContentRow title="Can be multiple">
        <Checkbox
          id={CAN_SELECT_MULTIPLE}
          checked={values[CAN_SELECT_MULTIPLE]}
          name={CAN_SELECT_MULTIPLE}
          onCheck={checkboxHandler}
          title="Can be multiple"
        />
      </ContentRow>
      <ContentRow title="Required">
        <Checkbox id={REQUIRED} checked={values[REQUIRED]} name={REQUIRED} onCheck={checkboxHandler} title="Required" />
      </ContentRow>
      <ContentRow title="Options">
        <MultipleOptionsField
          fieldPlaceholder="Option"
          listFieldName={OPTIONS}
          formik={formik}
          minListQuantity={1}
          addAnotherButtonText="+ Add option"
          showLoadMoreButton={mediaProductVariableOptionsNext}
          onLoadMoreButtonClick={loadMoreVariableOptionsHandler}
          isMoreItemsLoading={mediaProductVariableOptionsWasLoaded && getMediaProductVariableOptionsIsLoading}
        />
      </ContentRow>
    </ContentSection>
  )
}

ProductVariableFormContent.propTypes = {
  formik: PropTypes.object.isRequired,
  loadMoreVariableOptionsHandler: PropTypes.func
}

export default ProductVariableFormContent

import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../../components/Form/Field'

import {
  FILE_DIMENSIONS_PAIRS,
  FILES_REQUIREMENTS_HEIGHT,
  FILES_REQUIREMENTS_WIDTH
} from '../../../../../MediaProductCreate/MediaProductCreateForm/fields'
import { PIXEL_SYMBOL } from '../../FileSpecifications/FileSpecificationsFields/specificationsList'

import useStyles from '../../FileSpecifications/FileSpecificationsFields/styles'

const FileDimensionsFields = ({ formik, index, filePath, isPdfOnly }) => {
  const classes = useStyles()

  const widthFieldName = `${filePath}.${FILE_DIMENSIONS_PAIRS}[${index}].${FILES_REQUIREMENTS_WIDTH}`
  const heightFieldName = `${filePath}.${FILE_DIMENSIONS_PAIRS}[${index}].${FILES_REQUIREMENTS_HEIGHT}`

  const measurementUnit = useMemo(() => {
    if (isPdfOnly) {
      // if pdf only is selected, then we need to show mm instead of px
      return 'mm'
    } else {
      return PIXEL_SYMBOL
    }
  }, [isPdfOnly])

  return (
    <>
      <Field
        id={widthFieldName}
        type="number"
        formik={formik}
        className={classnames(classes.valueField, classes.valueFieldWithSymbol)}
        name={widthFieldName}
        placeholder="Width"
        autoComplete="off"
        symbol={measurementUnit}
      />
      <div className={classes.fieldsSeparator}>x</div>
      <Field
        id={heightFieldName}
        type="number"
        formik={formik}
        className={classnames(classes.valueField, classes.valueFieldWithSymbol)}
        name={heightFieldName}
        placeholder="Height"
        autoComplete="off"
        symbol={measurementUnit}
      />
    </>
  )
}

FileDimensionsFields.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  filePath: PropTypes.string.isRequired
}

export default FileDimensionsFields

import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../../../../components/Form'
import SpecificationsDocumentFormContent from '../../SpecificationsDocumentFormContent'

import { selectedSelfAccountDataSelector } from '../../../../../../../modules/selectors/app'
import {
  clearCreateMediaProductAttachedFile,
  createMediaProductAttachedFile
} from '../../../../../../../modules/actions/mediaOrdersProducts'
import {
  createMediaProductAttachedFileErrorSelector,
  createMediaProductAttachedFileIsLoadingSelector,
  createMediaProductAttachedFileWasCreatedSelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'

import { SPECIFICATIONS_DOCUMENT_CREATE } from '../../../../../../../constants/forms'
import { validationSchema } from '../../validation'
import { initialValues } from './fields'
import { FILE, FILE_NAME } from '../../fields'

function SpecificationsDocumentCreateForm() {
  const dispatch = useDispatch()

  const itemWasCreated = useSelector(createMediaProductAttachedFileWasCreatedSelector)
  const { controller } = useSelector(selectedSelfAccountDataSelector)

  const onSubmit = useCallback(
    values => {
      const data = {
        [FILE_NAME]: values[FILE_NAME],
        [FILE]: values[FILE],
        controller
      }
      dispatch(createMediaProductAttachedFile(data))
    },
    [dispatch, controller]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearCreateMediaProductAttachedFile())
  }, [dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={SPECIFICATIONS_DOCUMENT_CREATE}
      successSubmit={itemWasCreated}
      clearHandler={handleClearHandler}
      errorSelector={createMediaProductAttachedFileErrorSelector}
      isLoadingSelector={createMediaProductAttachedFileIsLoadingSelector}
    >
      <SpecificationsDocumentFormContent formik={formik} />
    </Form>
  )
}

export default SpecificationsDocumentCreateForm

import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import StepForm from '../../../../../../features/components/Forms/StepForm'
import DetailsStep from './Steps/DetailsStep'
import ImageStep from '../../MediaProductCreate/MediaProductCreateForm/Steps/ImageStep'

import { usePurifiedFormik } from '../../../../../../hooks/formHooks/usePurifiedFormik'
import { useProgressItems } from './useProgressItems'

import { clearCreateProduct, createProduct } from '../../../../../../modules/actions/mediaOrdersProducts'
import {
  createProductErrorSelector,
  createProductIsLoadingSelector,
  createProductWasCreatedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  selectedControllerCurrencySelector,
  selectedControllerIdSelector
} from '../../../../../../modules/selectors/app'

import { validationSchema } from './validation'
import { getInitialValues } from './fields'
import { transformValuesToBE } from './formatters'
import { MEDIA_PRODUCT_GROUP_CREATE } from '../../../../../../constants/forms'

// the media product group is literally the same as regular products and have a lot of common fields, but it allows
// to include subProducts under it
function MediaProductGroupCreateForm() {
  const dispatch = useDispatch()

  const controllerId = useSelector(selectedControllerIdSelector)
  const productWasCreated = useSelector(createProductWasCreatedSelector)
  const controllerCurrency = useSelector(selectedControllerCurrencySelector)

  const progressItems = useProgressItems()
  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE(values)

      dispatch(
        createProduct({
          controller: controllerId,
          group: true, // turns a product into a group
          ...transformedData
        })
      )
    },
    [dispatch, controllerId]
  )

  const initialValues = useMemo(() => getInitialValues(controllerCurrency), [controllerCurrency])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const purifiedFormik = usePurifiedFormik(formik)

  const steps = useMemo(
    () => [
      {
        component: <DetailsStep />,
        show: true
      },
      {
        component: <ImageStep />, // step is used from MediaProductCreate as it is the same
        show: true
      }
    ],
    []
  )

  const clearProductCreateSubmit = useCallback(() => {
    dispatch(clearCreateProduct())
  }, [dispatch])

  return (
    <StepForm
      steps={steps}
      formProgressItems={progressItems}
      formik={purifiedFormik}
      formName={MEDIA_PRODUCT_GROUP_CREATE}
      // processing
      successSubmit={productWasCreated}
      errorSelector={createProductErrorSelector}
      isLoadingSelector={createProductIsLoadingSelector}
      // after form submit
      clearSubmitHandler={clearProductCreateSubmit}
    />
  )
}

export default MediaProductGroupCreateForm

import React, { useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import MediaProductEditForm from './MediaProductEditForm'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'
import useManageEditFormData from '../../../../../../hooks/formHooks/useManageEditFormData'

import { getProductTags } from '../../../../../../modules/actions/tags'
import { clearProduct, getProduct } from '../../../../../../modules/actions/mediaOrdersProducts'
import { clearGetDiscountPresets, getDiscountPresets } from '../../../../../../modules/actions/discounts'
import { productSelector, productWasLoadedSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  discountPresetsErrorSelector,
  discountPresetsWasLoadedSelector
} from '../../../../../../modules/selectors/discounts'
import { productTagsWasLoadedSelector } from '../../../../../../modules/selectors/tags'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { PRODUCT_EDIT } from '../../../../../../constants/forms'

const MediaProductEdit = () => {
  const dispatch = useDispatch()

  const product = useSelector(productSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const productWasLoaded = useSelector(productWasLoadedSelector)
  const productTagsWasLoaded = useSelector(productTagsWasLoadedSelector)
  const discountPresetsWasLoaded = useSelector(discountPresetsWasLoadedSelector)
  const discountPresetsError = useSelector(discountPresetsErrorSelector)

  // if discount presets fail we still may show the form
  const discountPresetsDidLoading = discountPresetsWasLoaded && !discountPresetsError

  const { isFormOpen } = useManageFormsDrawer({ formName: PRODUCT_EDIT })

  const getProductHandler = useCallback(
    selectedEditItemId => {
      dispatch(getProduct({ id: selectedEditItemId }))
    },
    [dispatch]
  )

  const clearProductHandler = useCallback(() => {
    dispatch(clearProduct())
  }, [dispatch])

  useManageEditFormData({
    formName: PRODUCT_EDIT,
    getDataHandler: getProductHandler,
    clearDataHandler: clearProductHandler,
    loadedDataId: product.id
  })

  const isFormLoading = useMemo(
    () => !productWasLoaded || !productTagsWasLoaded || !discountPresetsDidLoading,
    [productWasLoaded, productTagsWasLoaded, discountPresetsDidLoading]
  )

  useEffect(() => {
    if (controllerId && isFormOpen) {
      // fetch discount presets only when form is open
      dispatch(getDiscountPresets({ controller: controllerId }))
    }
  }, [dispatch, controllerId, isFormOpen])

  useEffect(() => {
    if (isFormOpen && !productTagsWasLoaded) {
      // clear is making on Media products page unmount
      dispatch(getProductTags({ controller: controllerId }))
    }
  }, [dispatch, controllerId, productTagsWasLoaded, isFormOpen])

  useEffect(
    () => () => {
      dispatch(clearGetDiscountPresets())
    },
    [dispatch]
  )

  return (
    <FormDrawerWrapper
      formName={PRODUCT_EDIT}
      isFormLoading={isFormLoading}
      title="Edit media product"
      showOpenButton={false}
    >
      <MediaProductEditForm editProductData={product} />
    </FormDrawerWrapper>
  )
}

export default MediaProductEdit

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import BookingVariablesTableData from './BookingVariablesTableData'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'

import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { clearGetBookingVariables, getBookingVariables } from '../../../../../modules/actions/mediaOrdersBookings'
import {
  bookingVariablesSelector,
  bookingVariablesWasLoadedSelector,
  getBookingVariablesErrorSelector,
  getBookingVariablesIsLoadingSelector,
  getBookingVariablesNextSelector
} from '../../../../../modules/selectors/mediaOrdersBookings'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const BookingVariablesTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const bookingVariables = useSelector(bookingVariablesSelector)
  const bookingVariablesNext = useSelector(getBookingVariablesNextSelector)

  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const params = useMemo(
    () => ({
      controller: selectedControllerId,
      ordering: 'name',
      status: ['paused', 'active']
    }),
    [selectedControllerId]
  )

  const loadMoreHandler = useLoadPaginatedList({
    params,
    action: getBookingVariables,
    clearAction: clearGetBookingVariables,
    next: bookingVariablesNext
  })

  const columnsSchema = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no booking variables')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={bookingVariables.length}
      errorSelector={getBookingVariablesErrorSelector}
      wasLoadedSelector={bookingVariablesWasLoadedSelector}
      isLoadingSelector={getBookingVariablesIsLoadingSelector}
      skeletonProps={{ cols: columnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreHandler}
    >
      <BookingVariablesTableData />
    </TableDataLoader>
  )
}

export default BookingVariablesTable

import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SelectPeriodsContext } from '../../../../../SelectPeriodsContext'

import { inventoryLastItemSelector } from '../../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { findLastContinuousPeriod } from '../helpers'
import { findEarliestPeriod, formatPeriodOptions } from '../../Reusable/helpers'

export const AVAILABLE_QUANTITY = 'availableQuantity'
export function useInventoryPeriods({ selectedPeriods, selectedStartDate, isEndDateSelection }) {
  const { inventory, productPeriod, productStartPeriodDate } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)

  // periodOptions are generated based on product inventory period
  const periodOptions = useMemo(() => {
    return formatPeriodOptions({ productStartPeriodDate, productPeriod, inventory })
  }, [productStartPeriodDate, inventory, productPeriod])

  // find the earliest period start date
  const earliestPeriod = findEarliestPeriod(periodOptions)
  const firstPeriod = earliestPeriod?.date_start
  const lastPeriod = inventoryLastItem?.date_end || periodOptions[periodOptions.length - 1]?.value

  const availablePeriods = useMemo(() => {
    const filteredAvailablePeriods = periodOptions.filter(period => {
      // filter out selected periods:
      const isSelected = selectedPeriods.find(selectedPeriod => selectedPeriod.value === period.value)

      // overdue are not available to select by user
      return !period.isDisabled && !period.overdue && !isSelected
    })

    if (isEndDateSelection) {
      // when user selected the start date, we need to allow the end dates
      // the end dates should be the dates that are continuous to the selected start date
      // the continuous period is a period that starts the next day after the previous period ends
      const lastContinuousPeriod = findLastContinuousPeriod(filteredAvailablePeriods, selectedStartDate, productPeriod)
      const lastContinuousDate = lastContinuousPeriod?.date_end

      return filteredAvailablePeriods.filter(period => {
        const startDate = new Date(selectedStartDate)
        const periodStartDate = new Date(period.date_start)
        const isContinuous = period.date_start === lastContinuousDate
        const isAfterSelectedStartDate = periodStartDate >= startDate
        return isContinuous || isAfterSelectedStartDate
      })
    } else {
      return filteredAvailablePeriods
    }
  }, [isEndDateSelection, periodOptions, productPeriod, selectedPeriods, selectedStartDate])

  return {
    periodOptions,
    firstPeriod,
    lastPeriod,
    availablePeriods
  }
}

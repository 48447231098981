export const tableColumnsSize = {
  id: 60,
  name: 250,
  account: 200,
  campaignName: 240,
  created: 95,
  createdBy: 200,
  lastAmended: 95,
  cost: 105,
  status: 150,
  actions: 30
}
export const tableColumnMinSize = {
  name: 170,
  account: 80,
  brand: 90,
  campaignName: 170,
  cost: 100,
  created: 95,
  createdBy: 120,
  lastAmended: 95,
  status: 150
}

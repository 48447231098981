import { createStyles } from '../../../styles/helpers'

const useStyles = createStyles({
  mediaOrderActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default useStyles

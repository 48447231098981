import React, { forwardRef } from 'react'
import classnames from 'classnames'
import { FloatingArrow, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react'

import ConditionalWrapper from '../../hoc/ConditionalWrapper'

import { arrowHeight, useTooltipContext } from '../index'

import { mediumGrey } from '../../../styles/const/colors'
import useStyles from './styles'

export const TooltipContent = forwardRef(
  (
    {
      style,
      className,
      portaled = false,
      borderWidth = 2,
      color = mediumGrey,
      borderColor = mediumGrey,
      backgroundColor = 'white',
      ...props
    },
    propRef
  ) => {
    const classes = useStyles({ backgroundColor, color, borderColor, borderWidth })
    const tooltipData = useTooltipContext()
    const ref = useMergeRefs([tooltipData.refs.setFloating, propRef])

    const { isMounted, styles } = useTransitionStyles(tooltipData.context, {
      // Configure both open and close durations:
      duration: 300,
      initial: {
        opacity: 0,
        top: '10px'
      },
      open: ({ side }) => ({
        opacity: 1,
        top: '0px'
      })
    })

    const { arrowRef, floatingStyles, getFloatingProps } = tooltipData

    // Tooltip body is not rendered until it will be opened to avoid re-rendering on scroll and adding horizontal scrolls when it is outside the isOpen container like in table
    if (!tooltipData.isOpen) return null
    const { children, ...floatingProps } = getFloatingProps(props)

    return (
      <ConditionalWrapper condition={portaled} wrapper={children => <FloatingPortal>{children}</FloatingPortal>}>
        {isMounted && (
          <div
            ref={ref}
            style={{
              ...floatingStyles,
              ...styles
            }}
            className={classnames(classes.tooltipBody, className)}
            {...floatingProps}
          >
            <FloatingArrow
              ref={arrowRef}
              context={tooltipData.context}
              width={12}
              height={arrowHeight}
              tipRadius={2}
              strokeWidth={borderWidth}
              stroke={borderColor}
              fill={backgroundColor}
            />
            {children}
          </div>
        )}
      </ConditionalWrapper>
    )
  }
)

export default TooltipContent

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import LocationsSelectPaginated from '../../../../../../features/components/locationsFields/LocationsSelectPaginated'
import Field from '../../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../../features/formatters'

import { SUB_LOCATION } from '../../MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const LocationSectionContent = ({ formik, preselectedLocationOption }) => {
  const classes = useStyles()
  const { values, setFieldValue } = formik

  const selectedSubLocation = values[SUB_LOCATION]

  const defaultLocationsOptions = useMemo(() => {
    if (preselectedLocationOption) {
      return [preselectedLocationOption]
    } else {
      return []
    }
  }, [preselectedLocationOption])

  const [selectedLocationOptionData, setSelectedLocationOptionData] = useState(preselectedLocationOption)

  const selectedLocationStores = useMemo(() => {
    return selectedLocationOptionData?.stores || []
  }, [selectedLocationOptionData])

  const formattedLocationStores = useMemo(() => {
    return formatOptionsList({
      list: selectedLocationStores,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selectedLocationStores])

  const clearSubLocationHandler = useCallback(() => {
    setFieldValue(SUB_LOCATION, '')
  }, [setFieldValue])

  const locationChangeHandler = useCallback(
    option => {
      setSelectedLocationOptionData(option)
      // Clear selected sub-location  when location changes
      clearSubLocationHandler()
    },
    [clearSubLocationHandler]
  )

  return (
    <>
      <LocationsSelectPaginated
        formik={formik}
        onLocationChange={locationChangeHandler}
        isClearable
        defaultOptionsList={defaultLocationsOptions}
      />

      {!!selectedLocationStores.length && (
        <Field
          name={SUB_LOCATION}
          formik={formik}
          placeholder="Sub-location"
          options={formattedLocationStores}
          className={classes.subLocationSelect}
          showClearInputIcon={!!selectedSubLocation}
          onClearInput={clearSubLocationHandler}
        />
      )}
    </>
  )
}

LocationSectionContent.propTypes = {
  formik: PropTypes.object.isRequired,
  preselectedLocationOption: PropTypes.object,
  isEditForm: PropTypes.bool
}

export default LocationSectionContent

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Chip from '../../../../../../components/Chip'
import ErrorMessage from '../../../../../../components/Form/ErrorMessage'

import { getContractStatusChipColor } from '../../../../../../features/components/Contract/helpers'

import { activeContractSelector } from '../../../../../../modules/selectors/contracts'

import { CHIP_COLORS } from '../../../../../../constants/other'
import { CONTRACT_NEW_STATUS } from '../../../../../../constants/contacts'

import useStyles from '../../styles'

function ProposalStatus() {
  const classes = useStyles()

  const activeContract = useSelector(activeContractSelector)
  const status = activeContract?.status

  const showSentStatus = activeContract?.quotation_sent
  const contractStatus = useMemo(() => {
    const statusTextToUse = status === CONTRACT_NEW_STATUS ? 'active' : status
    return {
      // remove underscores
      text: statusTextToUse?.replace(/_/g, ' '),
      color: getContractStatusChipColor(status)
    }
  }, [status])

  const showStatuses = useMemo(() => showSentStatus || Boolean(contractStatus), [contractStatus, showSentStatus])

  if (!showStatuses) {
    return null
  }

  return (
    <>
      <div className={classes.chipsContainer}>
        {showSentStatus && <Chip text={'Sent'} color={CHIP_COLORS.yellow} />}
        {contractStatus && <Chip text={contractStatus.text} color={contractStatus.color} />}
      </div>
      {activeContract?.status === 'disapproved' && <ErrorMessage error={activeContract?.disapproval_reason} />}
    </>
  )
}

export default ProposalStatus

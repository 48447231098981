import {
  BOOKED_QUANTITY,
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY,
  INVENTORY_START_DATE
} from '../../../MediaProducts/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

import { formatDateToBE } from '../../../../../constants/dates'

export const formatInventoryToFormikValues = inventory => {
  return inventory.map(item => ({
    [INVENTORY_START_DATE]: new Date(item.date_start),
    [INVENTORY_QUANTITY]: item.quantity,
    [BOOKED_QUANTITY]: item.booked_quantity,
    id: item.id
  }))
}

export const getInitialValues = (inventory = []) => {
  const formattedInventoryPairs = formatInventoryToFormikValues(inventory)

  return {
    [INVENTORY_LIST_DATA]: formattedInventoryPairs
  }
}

export const formatInventoryPairsToBE = ({ originalInventory, values, productId }) => {
  // We filter out all null values from array,
  // For optimization purposes, we don't delete it right away to avoid shifting all elements, but we set it to null
  const validInventoryItems = values[INVENTORY_LIST_DATA].filter(item => !!item)

  const updatedInventory = validInventoryItems
    .filter(item => originalInventory.find(({ id }) => item.id === id))
    .map(item => ({
      id: item.id,
      date_start: formatDateToBE(item[INVENTORY_START_DATE]),
      quantity: item[INVENTORY_QUANTITY],
      media_product: productId
    }))

  const addedInventory = validInventoryItems
    .filter(item => !originalInventory.find(({ id }) => item.id === id))
    .map(item => ({
      date_start: formatDateToBE(item[INVENTORY_START_DATE]),
      quantity: item[INVENTORY_QUANTITY],
      media_product: productId
    }))

  const deleteInventory = originalInventory
    .filter(item => !validInventoryItems.find(({ id }) => item.id === id))
    .map(item => ({ id: item.id }))

  updatedInventory.push(...addedInventory)

  return {
    ...(updatedInventory.length && { inventories: updatedInventory }),
    ...(deleteInventory.length && { delete_inventories: deleteInventory })
  }
}

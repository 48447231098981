import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaProductTagsEditForm from './MediaSubCategoryEditForm'
import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import {
  mediaSubCategoriesSelector,
  getMediaSubCategoriesIsLoadingSelector,
  mediaSubCategoriesWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'

import { MEDIA_SUB_CATEGORY_EDIT } from '../../../../../constants/forms'

function MediaSubCategoryEdit() {
  const { selectedEditItemId } = useManageFormsDrawer({
    formName: MEDIA_SUB_CATEGORY_EDIT
  })

  const mediaSubCategoriesIsLoading = useSelector(getMediaSubCategoriesIsLoadingSelector)
  const mediaSubCategoriesWasLoaded = useSelector(mediaSubCategoriesWasLoadedSelector)

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const isFormLoading = useMemo(() => {
    return mediaSubCategoriesIsLoading || !mediaSubCategoriesWasLoaded
  }, [mediaSubCategoriesIsLoading, mediaSubCategoriesWasLoaded])

  const editSubCategoryData = useMemo(
    () => mediaSubCategories.find(subCategory => subCategory?.id === Number(selectedEditItemId)) || {},
    [mediaSubCategories, selectedEditItemId]
  )

  return (
    <FormDrawerWrapper
      formName={MEDIA_SUB_CATEGORY_EDIT}
      title="Edit asset type"
      isFormLoading={isFormLoading}
      showOpenButton={false}
      closeOnSubmit={true}
    >
      <MediaProductTagsEditForm subCategoryData={editSubCategoryData} />
    </FormDrawerWrapper>
  )
}

export default MediaSubCategoryEdit

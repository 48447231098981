import { madeRequest } from '../../helpers/api'
import { ENDPOINTS, WEB_SOCKET_ENDPOINTS } from '../../constants/api'
import { madeSocketRequest } from '../../helpers/api/webSocket'

// PLOP_APPEND_PATTERN_ANCHOR

export function getProductsTagsService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.productsTags
  })
}

export function deleteProductService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.products}${id}/`
  })
}

export function updateProductService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.products}${id}/`
  })
}

export function getProductsService(params) {
  // the getProductsService is used outside saga flow, so double check all places in case of some changes..
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.products
  })
}

export function getProductService({ id, ...params }) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.products}${id}/`
  })
}

export function getProductGraphService(id, params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.products}${id}/graph_per_item/`
  })
}

export function getProductsGraphService(params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.products}graph/`
  })
}

export function getUtilisationReportService(params) {
  return madeRequest('POST', {
    data: params,
    url: ENDPOINTS.utilisationReport
  })
}

export function createProductService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.products
  })
}

export function updateInventoryService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.inventory}${id}/`
  })
}

export function getInventoryService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.inventory
  })
}

export function createInventoryService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.inventory
  })
}

export function updateBatchInventoryService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.inventoryBatchUpdate
  })
}

export function deleteInventoryService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.inventory}${id}/`
  })
}

export function getInventoryGroupService(params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.inventory}group_list/`
  })
}

export function updateProductsOrderService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.productsOrderUpdate
  })
}

export function getInventoryLastItemService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.inventoryLastItem
  })
}

export function getInventoryReportService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.inventoryReport
  })
}

export function getMediaProductsAsCsvService({ params, onSocketOpen }) {
  return madeSocketRequest(
    'post',
    {
      url: WEB_SOCKET_ENDPOINTS.mediaProductExportCSV,
      params
    },
    { onSocketOpen }
  )
}

export function getMediaSubCategoriesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaSubCategories
  })
}

export function createMediaSubCategoryService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaSubCategories
  })
}

export function updateMediaSubCategoryService(id, data) {
  return madeRequest('PUT', {
    data,
    url: `${ENDPOINTS.mediaSubCategories}${id}/`
  })
}

export function getMediaProductAttachedFilesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaProductAttachedFiles
  })
}

export function createMediaProductAttachedFileService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaProductAttachedFiles
  })
}

export function getMediaProductAttachedFileService({ id }) {
  return madeRequest('GET', {
    url: `${ENDPOINTS.mediaProductAttachedFiles}${id}/`
  })
}

export function updateMediaProductAttachedFileService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaProductAttachedFiles}${id}/`
  })
}

export function deleteMediaProductAttachedFileService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.mediaProductAttachedFiles}${id}/`
  })
}

import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  productLocationsTableActions: {
    display: 'flex',
    marginBottom: 16
  },
  productLocationsSearch: {
    marginLeft: 'auto'
  }
})

export default useStyles

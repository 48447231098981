export const NAME = 'name'
export const MEDIA_CATEGORY = 'media_category'
export const MEDIA_SUB_SUB_CATEGORY = 'sub_sub_categories'

export const initialSubSubCategoryValue = {
  [NAME]: ''
}

export const initialValues = {
  // name of new media sub category
  [NAME]: '',
  [MEDIA_CATEGORY]: '',
  [MEDIA_SUB_SUB_CATEGORY]: []
}

export function transformValuesToBE(values, controllerId) {
  return {
    [NAME]: values[NAME],
    [MEDIA_CATEGORY]: values[MEDIA_CATEGORY],
    [MEDIA_SUB_SUB_CATEGORY]: values[MEDIA_SUB_SUB_CATEGORY].map(subSubCategory => ({
      [NAME]: subSubCategory[NAME],
      controller: controllerId
    }))
  }
}

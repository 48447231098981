import {
  ATTRIBUTE,
  BOOKING_EMAIL_TEXT,
  CAN_SELECT_MULTIPLE,
  FILE_NAME,
  FILE_URL,
  MEDIA_PRODUCT_ATTRIBUTE,
  NAME,
  OPTION_NAME,
  OPTIONS,
  REQUIRED,
  VALUES
} from '../fields'

export const transformValuesToBE = values => {
  return {
    [NAME]: values[NAME],
    [MEDIA_PRODUCT_ATTRIBUTE]: {
      [ATTRIBUTE]: values[MEDIA_PRODUCT_ATTRIBUTE][ATTRIBUTE],
      [VALUES]: values[MEDIA_PRODUCT_ATTRIBUTE][VALUES].map(item => item.value)
    },
    [REQUIRED]: values[REQUIRED],
    [CAN_SELECT_MULTIPLE]: values[CAN_SELECT_MULTIPLE],
    [BOOKING_EMAIL_TEXT]: values[BOOKING_EMAIL_TEXT],
    [OPTIONS]: Object.values(values[OPTIONS]).map(item => {
      return {
        [OPTION_NAME]: item[OPTION_NAME],
        [FILE_URL]: item[FILE_URL],
        [FILE_NAME]: item[FILE_NAME]
      }
    })
  }
}

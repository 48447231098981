import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import InfoModal from '../../../../../../features/components/Modals/InfoModalNew'
import InfoModalButtons from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons'

import { areUrlsEqual } from './helpers'

import { bookedMediaSelector } from '../../../../../../modules/selectors/mediaOrdersBookings'

import { DISAPPROVED_REASON } from '../../../../../../features/components/Modals/AskReasonModal/fields'
import { PRIMARY_BUTTON } from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons/helpers'

import useStyles from './styles'

const FileInstancesModal = ({ isOpen, onClose, data = {}, onFilesStatusChange }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { status, uploadedFile, [DISAPPROVED_REASON]: disapprovedReason, allFileIds } = data

  const bookedMedia = useSelector(bookedMediaSelector)

  const handleStatusChange = useCallback(
    ids => {
      onFilesStatusChange({
        ids,
        status,
        disapprovedReason
      })

      onClose()
    },
    [onFilesStatusChange, status, disapprovedReason, onClose]
  )

  const updateOnlyThisAssetHandler = useCallback(() => {
    const parentBookedMedia = bookedMedia.find(media => media.id === uploadedFile.booked_media)
    const parentBookedMediaUploadedFiles = parentBookedMedia.uploaded_files
    const parentBookedMediaUploadedFilesWithSameFileUrl = parentBookedMediaUploadedFiles.filter(file => {
      return areUrlsEqual(file.file_url, uploadedFile.file_url)
    })
    const thisAsseFileIds = parentBookedMediaUploadedFilesWithSameFileUrl.map(file => file.id)

    handleStatusChange(thisAsseFileIds)
  }, [bookedMedia, uploadedFile, handleStatusChange])

  const updateAllAssetsHandler = useCallback(() => {
    handleStatusChange(allFileIds)
  }, [allFileIds, handleStatusChange])

  const buttons = [
    {
      type: 'button',
      text: t('Only this asset'),
      onClick: updateOnlyThisAssetHandler
    },
    {
      type: 'button',
      text: t('Apply to all'),
      solid: true,
      buttonColor: PRIMARY_BUTTON,
      onClick: updateAllAssetsHandler
    }
  ]

  return (
    <InfoModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Apply to all instances of this file"
      ModalMessage={<p>{t('Would you like to apply this status to all instances of this file?')}</p>}
      headerClassName={classes.header}
    >
      <InfoModalButtons buttons={buttons} />
    </InfoModal>
  )
}

export default FileInstancesModal

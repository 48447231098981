import { createStyles } from '../helpers'
import { lightGrey, mediumGrey } from '../const/colors'

const useStyles = createStyles(theme => ({
  formWrapper: {
    border: `1px solid ${mediumGrey}`,
    borderRadius: theme.bigBorderRadius,
    marginBottom: 80
  },
  formFooter: {
    marginTop: 0,
    background: lightGrey,
    height: 60,
    borderTop: `1px solid ${mediumGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 12
  }
}))

export default useStyles

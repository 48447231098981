import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ControllerInvitesTable from './ControllerInvitesTable'

import { useLoadPaginatedList } from '../../../../features/hooks/useLoadPaginatedList'
import {
  clearControllerInvites,
  deleteControllerInvite,
  getControllerInvites,
  resendControllerInvite
} from '../../../../modules/actions/controller'
import { controllerInvitesNextSelector } from '../../../../modules/selectors/controller'

import { selectedControllerDataSelector } from '../../../../modules/selectors/app'

import useStyles from './styles'

function ControllerInvites() {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const selectedController = useSelector(selectedControllerDataSelector)
  const controllerInvitesNext = useSelector(controllerInvitesNextSelector)

  const resendInviteHandler = useCallback(
    id => {
      dispatch(resendControllerInvite(id))
    },
    [dispatch]
  )

  const deleteInviteHandler = useCallback(
    id => {
      dispatch(deleteControllerInvite(id))
    },
    [dispatch]
  )

  const params = useMemo(
    () => ({
      controller: selectedController.id
    }),
    [selectedController.id]
  )

  const loadMoreControllerInvites = useLoadPaginatedList({
    params,
    action: getControllerInvites,
    clearAction: clearControllerInvites,
    next: controllerInvitesNext
  })

  return (
    <>
      <h3 className={classes.invitesTableTitle}>{t('Invites')}</h3>
      <ControllerInvitesTable
        onDeleteInvite={deleteInviteHandler}
        onResendInvite={resendInviteHandler}
        loadMore={loadMoreControllerInvites}
      />
    </>
  )
}

export default ControllerInvites

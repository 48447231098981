import { matchPath } from 'react-router'
import parser from 'tld-extract'

import store from '../store'
import history from '../history'
import { keys, reduce } from './common'

import { handlePlatformChange } from './handlePlatformSelection'
import { handleSelfAccountChange } from './handleSelfAccountSelection'

import { selectedSelfAccountSelector } from '../modules/selectors/app'
import { ROUTE_PARAMS, ROUTES } from '../constants/routes'

export function getParameterFromUrl(parameterKey) {
  const { [parameterKey]: parameterValue } = createJsonFromQueryString(window.location.search)
  return parameterValue
}

export function parsePathFromRoutes(path) {
  // retrieve path parameters like platform, adAccount, and etc..
  let match = undefined

  keys(ROUTES).forEach(route => {
    // search matching for current route through ROUTES list to retrieve params based on that
    const matched = matchPath(
      {
        path: ROUTES[route],
        exact: true,
        strict: false
      },
      path || window.location.pathname
    )
    if (matched) {
      match = matched
    }
  })

  return match || {}
}

export function getParameterFromPath(parameterKey) {
  const parsedPath = parsePathFromRoutes()
  const parameter = parsedPath.params && parsedPath.params[parameterKey]

  // avoid sending route parameter id i.e. -> :adAccount
  return parameter === ':' + parameterKey ? '' : parameter
}

export function getCurrentRoute() {
  const matchedRoute = parsePathFromRoutes().pattern

  if (matchedRoute && matchedRoute.path) {
    return matchedRoute.path
  } else {
    console.warn('Route is undefined')
    return undefined
  }
}

export function replaceRouteParams(route, params) {
  return reduce(keys(params), (acc, cur) => acc.replace(`:${cur}`, params[cur]), route)
}

export function updateRouteParams(newParams) {
  // replace only new parameters in path
  const currentRoute = getCurrentRoute()

  if (currentRoute) {
    // don't loose query params on routeParams update
    const routeWithSearch = currentRoute + window.location.search
    updateParams(routeWithSearch, newParams)
  }
}

function updateParams(route, newParams) {
  const currentParams = parsePathFromRoutes().params
  const newRoute = replaceRouteParams(route, {
    ...currentParams,
    ...newParams
  })

  history.push(newRoute)
}

export function checkIfParamsRelatedLink(url = '') {
  return url.indexOf('/:') >= 0
}

export function createJsonFromQueryString(queryString, decode = true) {
  let parsedObject = {}
  let incomingQuery = queryString.substr(1)
  if (incomingQuery !== '') {
    let jsonString = ''
    const queryParamsList = incomingQuery.split('&')

    for (let i = 0; i < queryParamsList.length; i++) {
      const paramKeyValue = queryParamsList[i].split('=')
      if (paramKeyValue.length > 1 && paramKeyValue[0] && paramKeyValue[1]) {
        const key = paramKeyValue[0]
        const value = paramKeyValue[1]

        if (decode) {
          jsonString += `"${decode ? decodeURIComponent(key) : key}":"${decode ? decodeURIComponent(value) : value}",`
        } else {
          // skip decoding and JSON creation - just save values to object directly
          parsedObject[key] = value
        }
      }
    }

    if (decode) {
      parsedObject = JSON.parse(`{${jsonString.substr(0, jsonString.length - 1)}}`)
    }
  }
  return parsedObject
}

export function redirectTo(url, newParams) {
  if (checkIfParamsRelatedLink(url)) {
    // copy existed params for ParamsRelatedLink + adjust with new
    // it will populate only params from pathname
    // so if some parameter is missing it will not be populated from redux
    updateParams(url, newParams)

    if (newParams) {
      // manage main app parameters as (selfAccount,platform,adAccount) during route switch
      // trigger parameters change only when new params exists and differs from selected
      const { app } = store.getState()
      const currentPlatform = app.selectedPlatform
      const currentSelfAccount = app.selectedSelfAccount

      const isDifferentSelfAccount =
        newParams[ROUTE_PARAMS.selfAccount] &&
        Number(currentSelfAccount) !== Number(newParams[ROUTE_PARAMS.selfAccount])
      const isDifferentPlatform =
        newParams[ROUTE_PARAMS.platform] && currentPlatform !== newParams[ROUTE_PARAMS.platform]

      if (isDifferentSelfAccount) {
        // on selfAccount change
        handleSelfAccountChange(Number(newParams[ROUTE_PARAMS.selfAccount]))
      } else if (isDifferentPlatform) {
        // on platform change
        handlePlatformChange(newParams[ROUTE_PARAMS.platform])
      }
    }
  } else {
    history.push(url)
  }
}

export function checkIfSelfAccountRelatedLink(url = '') {
  return url.indexOf('/:selfAccount/') >= 0
}

export function checkIfPlatformRelatedLink(url = '') {
  return url.indexOf('/:platform/') >= 0
}

export function checkIfAdAccountRelatedLink(url = '') {
  return url.indexOf('/:adAccount/') >= 0
}

export function getDefaultPageUrl() {
  // const { app } = store.getState()
  //
  // return replaceRouteParams(ROUTES.campaigns, {
  //   [ROUTE_PARAMS.selfAccount]: app[SELECTED_SELF_ACCOUNT],
  //   [ROUTE_PARAMS.platform]: app[SELECTED_PLATFORM],
  //   [ROUTE_PARAMS.adAccount]: providerAdAccounts.selectedProviderAdAccountId
  // })
  const storeState = store.getState()
  const selectedSelfAccountId = selectedSelfAccountSelector(storeState)
  // support redirection after login to selfAccountHome
  return replaceRouteParams(ROUTES.selfAccountHome, {
    [ROUTE_PARAMS.selfAccount]: selectedSelfAccountId
  })
}

export function isValidHttpUrl(string) {
  return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(string)
}

export function getUrlDomain(string) {
  if (isValidHttpUrl(string)) {
    try {
      const url = new URL(string)
      return parser(url).domain
    } catch (e) {
      return ''
    }
  } else {
    return ''
  }
}

import { isWithinInterval, parseISO } from 'date-fns'

import { getProductPrice } from './helpers/price'
import { getAvailableCategories, getInitialProductSetup, getPackageFlow } from './helpers'
import { getInventoryPeriod } from './helpers/periodHelpers'

import { DIGITAL, EDM, IN_STORE, OOH, PHYSICAL_SPACES, PRINT } from '../../../../../../constants/mediaOrders'
import { PRODUCT_PERIODS_DATES } from '../../../fields'
import { AVAILABLE_QUANTITY } from '../../../ContractBuilder/ProductsManage/ProductsTable/Cells/DatesCell/EditPeriods/DatePickerPeriodSelector/hooks/useInventoryPeriods'

export const SELECTED_PACKAGES = 'selectedPackages'
export const MEDIA_PRODUCTS = 'products'

// Package that has categories and no fixed dates
export const CATEGORY_PRODUCTS_PACKAGE = 'CATEGORY_PRODUCTS_PACKAGE'
// Package that has pre-defined products and no fixed dates and user can select one or more products from the
// pre-defined products
export const PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE = 'PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE'
// Package that has pre-defined products and no fixed dates and user must buy all pre-defined products
export const FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE = 'FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE'
// Package that has pre-defined products and fixed dates (and user must buy all pre-defined products)
export const FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE = 'FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE'

// categories products type
export const MAGAZINE_PRODUCTS = 'print_products'
export const DIGITAL_PRODUCTS = 'digital_products'
export const EDM_PRODUCTS = 'edm_products'
export const OOH_PRODUCTS = 'ooh_products'
export const IN_STORE_PRODUCTS = 'in_store_products'
export const PHYSICAL_SPACES_PRODUCTS = 'physical_spaces_products'

// campaign start month
export const CAMPAIGN_START_PERIOD_OPTION = 'campaign_start_period_option'

// amount of publications for product
export const ALLOWED_PRODUCT_SELECTION = 'allowed_product_selection'
export const ALLOWED_CATEGORY_PRODUCTS_SELECTION = 'allowed_product_category_selection'
// additional data:
export const CAMPAIGN_NAME = 'campaign_name'
export const BRAND = 'brand'

const generatePublicationsDate = (productValues, selectedMediaPackage) => {
  const availablePeriods = productValues.inventory.filter(period => {
    // check if period is available to book
    const { available_to_book, date_start: startPeriodDate } = period

    const { date_start: packageDateStart, date_end: packageDateEnd } = selectedMediaPackage

    // check if period is in package date range
    const isInPackageDateRange = isWithinInterval(
      // set newDate time to 00:00:00 to compare only dates
      new Date(startPeriodDate).setHours(0, 0, 0, 0),
      {
        start: new Date(packageDateStart).setHours(0, 0, 0, 0),
        end: new Date(packageDateEnd).setHours(0, 0, 0, 0)
      }
    )

    // check if the period in stock
    return available_to_book && isInPackageDateRange
  })
  // sort by periods start date from earliest to latest
  const sortedPeriods = availablePeriods.sort((a, b) => new Date(a.date_start) - new Date(b.date_start))

  const productPeriod = productValues.period

  return sortedPeriods.map(({ id, date_start, date_end, available_to_book, available_inventory, period_price }) => ({
    inventory: id,
    date_start,
    date_end,
    ...getInventoryPeriod(parseISO(date_start), productPeriod),
    isDisabled: !available_to_book,
    [AVAILABLE_QUANTITY]: available_inventory,
    price: period_price
  }))
}

export const categoryValues = [PRINT, EDM, OOH, IN_STORE, PHYSICAL_SPACES, DIGITAL]

export const CATEGORIES_VALUES_TO_FIELDS = {
  [PRINT]: MAGAZINE_PRODUCTS,
  [EDM]: EDM_PRODUCTS,
  [OOH]: OOH_PRODUCTS,
  [IN_STORE]: IN_STORE_PRODUCTS,
  [PHYSICAL_SPACES]: PHYSICAL_SPACES_PRODUCTS,
  [DIGITAL]: DIGITAL_PRODUCTS
}
const getPreselectedProductsByCategory = ({
  preselectedProducts,
  mediaPackage,
  preSelectPublications,
  hasSkippedPreselectedProducts
}) => {
  return categoryValues.reduce((acc, categoryValue) => {
    const productsCategoryField = CATEGORIES_VALUES_TO_FIELDS[categoryValue]

    acc[productsCategoryField] = preselectedProducts
      .filter(item => item.media_category === categoryValue)
      .map(product => ({
        ...product,
        ...getInitialProductSetup(product),
        ...(preSelectPublications
          ? {
              [PRODUCT_PERIODS_DATES]: hasSkippedPreselectedProducts
                ? []
                : generatePublicationsDate(product, mediaPackage)
            }
          : // dont allow to setup product if at least one of preselected products was skipped
            { isSetupAllowed: hasSkippedPreselectedProducts ? false : true })
      }))
    return acc
  }, {})
}

const emptyGroupedProducts = {
  [MAGAZINE_PRODUCTS]: [],
  [EDM_PRODUCTS]: [],
  [OOH_PRODUCTS]: [],
  [IN_STORE_PRODUCTS]: [],
  [PHYSICAL_SPACES_PRODUCTS]: [],
  [DIGITAL_PRODUCTS]: []
}

export const getInitialPackages = (mediaPackages, selectedCurrency) =>
  mediaPackages.map(mediaPackage => {
    const productsPackageFlow = getPackageFlow(mediaPackage)
    const currencyAvailableProducts = mediaPackage?.media_products?.filter(
      product => !!getProductPrice(product.prices, selectedCurrency.code)
    )
    const hasSkippedPreselectedProducts = currencyAvailableProducts.length !== mediaPackage.media_products.length

    switch (productsPackageFlow) {
      case CATEGORY_PRODUCTS_PACKAGE:
        const availableCategories = getAvailableCategories(mediaPackage)

        return {
          ...mediaPackage,
          ...emptyGroupedProducts,
          // select one or more products by each category
          [ALLOWED_CATEGORY_PRODUCTS_SELECTION]: availableCategories,
          allowBack: false
        }
      case PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE:
        return {
          ...mediaPackage,
          ...emptyGroupedProducts,
          // select one or more products from the pre-defined products
          [ALLOWED_PRODUCT_SELECTION]: true,
          allowBack: false
        }
      case FIXED_PRE_DEFINED_PRODUCTS__NO_FIXED_DATES_PACKAGE:
        return {
          ...mediaPackage,
          // products are pre-selected
          ...getPreselectedProductsByCategory({
            preselectedProducts: currencyAvailableProducts,
            mediaPackage,
            hasSkippedPreselectedProducts
          }),
          // package is invalid if at least one of selected products was skipped
          hasSkippedPreselectedProducts
        }

      case FIXED_PRE_DEFINED_PRODUCTS__FIXED_DATES_PACKAGE:
        return {
          ...mediaPackage,
          // products and dates are pre-selected
          ...getPreselectedProductsByCategory({
            preselectedProducts: currencyAvailableProducts,
            mediaPackage,
            preSelectPublications: true,
            hasSkippedPreselectedProducts
          }),
          // package is invalid if at least one of selected products was skipped
          hasSkippedPreselectedProducts
        }

      default:
        return null
    }
  })

export const getInitialValues = mediaPackages => {
  return {
    [SELECTED_PACKAGES]: mediaPackages,
    [CAMPAIGN_NAME]: '',
    [BRAND]: ''
  }
}

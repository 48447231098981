import { createStyles } from '../../../../../../../../../styles/helpers'

const useStyles = createStyles({
  mediaCategoryField: {
    marginBottom: 8
  },
  selectChannelField: {
    marginBottom: 8
  }
})

export default useStyles

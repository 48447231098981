import { concat } from '../../helpers/common'
import { setLocalStorage } from '../../helpers/storage'
import { CONTROLLER_LANDING_PAGE_VIEW, SELECTED_PLATFORM, SELECTED_SELF_ACCOUNT } from '../reducers/app'
import { getAppDomainName } from '../../features/helpers/getAppDomainName'

const MODULE_NAME = 'APP/'
// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const SET_CLIENT_VIEW = concat(MODULE_NAME, 'SET_CLIENT_VIEW')

export const GET_USER_INVITES = concat(MODULE_NAME, 'GET_USER_INVITES')
export const GET_USER_INVITES_SUCCESS = concat(MODULE_NAME, 'GET_USER_INVITES_SUCCESS')
export const GET_USER_INVITES_FAILURE = concat(MODULE_NAME, 'GET_USER_INVITES_FAILURE')
export const CLEAR_GET_USER_INVITES = concat(MODULE_NAME, 'CLEAR_GET_USER_INVITES')

export const GET_CURRENT_USER_PROFILE = concat(MODULE_NAME, 'GET_CURRENT_USER_PROFILE')
export const GET_CURRENT_USER_PROFILE_SUCCESS = concat(MODULE_NAME, 'GET_CURRENT_USER_PROFILE_SUCCESS')
export const GET_CURRENT_USER_PROFILE_FAILURE = concat(MODULE_NAME, 'GET_CURRENT_USER_PROFILE_FAILURE')

export const SUBMIT_SERVER_LOGOUT = concat(MODULE_NAME, 'SUBMIT_SERVER_LOGOUT')
export const SUBMIT_SERVER_LOGOUT_SUCCESS = concat(MODULE_NAME, 'SUBMIT_SERVER_LOGOUT_SUCCESS')
export const SUBMIT_SERVER_LOGOUT_FAILURE = concat(MODULE_NAME, 'SUBMIT_SERVER_LOGOUT_FAILURE')
export const CLEAR_SUBMIT_SERVER_LOGOUT = concat(MODULE_NAME, 'CLEAR_SUBMIT_SERVER_LOGOUT')

export const UPDATE_CURRENT_USER_PROFILE = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_PROFILE')
export const UPDATE_CURRENT_USER_PROFILE_SUCCESS = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_PROFILE_SUCCESS')
export const UPDATE_CURRENT_USER_PROFILE_FAILURE = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_PROFILE_FAILURE')
export const CLEAR_UPDATE_CURRENT_USER_PROFILE = concat(MODULE_NAME, 'CLEAR_UPDATE_CURRENT_USER_PROFILE')

export const VERIFY_CURRENT_USER = concat(MODULE_NAME, 'VERIFY_CURRENT_USER')
export const VERIFY_CURRENT_USER_SUCCESS = concat(MODULE_NAME, 'VERIFY_CURRENT_USER_SUCCESS')
export const VERIFY_CURRENT_USER_FAILURE = concat(MODULE_NAME, 'VERIFY_CURRENT_USER_FAILURE')
export const CLEAR_VERIFY_CURRENT_USER = concat(MODULE_NAME, 'CLEAR_VERIFY_CURRENT_USER')

export const SHOW_MFA_SETUP_PAGE = concat(MODULE_NAME, 'SHOW_MFA_SETUP_PAGE')

export const SET_MFA_TYPE = concat(MODULE_NAME, 'SET_MFA_TYPE')
export const SET_MFA_TYPE_SUCCESS = concat(MODULE_NAME, 'SET_MFA_TYPE_SUCCESS')
export const SET_MFA_TYPE_FAILURE = concat(MODULE_NAME, 'SET_MFA_TYPE_FAILURE')
export const CLEAR_SET_MFA_TYPE = concat(MODULE_NAME, 'CLEAR_SET_MFA_TYPE')

export const CREATE_CURRENT_USER_SELF_ACCOUNT = concat(MODULE_NAME, 'CREATE_CURRENT_USER_SELF_ACCOUNT')
export const UPDATE_CURRENT_USER_SELF_ACCOUNT = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_SELF_ACCOUNT')

export const SET_SEARCH = concat(MODULE_NAME, 'SET_SEARCH')
export const CLEAR_SEARCH = concat(MODULE_NAME, 'CLEAR_SEARCH')

export const UPDATE_CURRENT_USER_CONTROLLER = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_CONTROLLER')
export const UPDATE_CONTROLLER_PERMISSIONS = concat(MODULE_NAME, 'UPDATE_CONTROLLER_PERMISSIONS')
export const SET_SELECTED_SELF_ACCOUNT = concat(MODULE_NAME, 'SET_SELECTED_SELF_ACCOUNT')
export const SET_CONTEXT_SELF_ACCOUNT = concat(MODULE_NAME, 'SET_CONTEXT_SELF_ACCOUNT')
export const SET_SELECTED_PLATFORM = concat(MODULE_NAME, 'SET_SELECTED_PLATFORM')

export const GET_BANNERS = concat(MODULE_NAME, 'GET_BANNERS')
export const GET_BANNERS_SUCCESS = concat(MODULE_NAME, 'GET_BANNERS_SUCCESS')
export const GET_BANNERS_FAILURE = concat(MODULE_NAME, 'GET_BANNERS_FAILURE')

export const GET_CONTROLLER_LANDING_PAGE_SETUP = concat(MODULE_NAME, 'GET_CONTROLLER_LANDING_PAGE_SETUP')
export const GET_CONTROLLER_LANDING_PAGE_SETUP_SUCCESS = concat(
  MODULE_NAME,
  'GET_CONTROLLER_LANDING_PAGE_SETUP_SUCCESS'
)
export const GET_CONTROLLER_LANDING_PAGE_SETUP_FAILURE = concat(
  MODULE_NAME,
  'GET_CONTROLLER_LANDING_PAGE_SETUP_FAILURE'
)
export const CLEAR_GET_CONTROLLER_LANDING_PAGE_SETUP = concat(MODULE_NAME, 'CLEAR_GET_CONTROLLER_LANDING_PAGE_SETUP')

export const GET_ASYNC_SESSION = concat(MODULE_NAME, 'GET_ASYNC_SESSION')
export const GET_ASYNC_SESSION_SUCCESS = concat(MODULE_NAME, 'GET_ASYNC_SESSION_SUCCESS')
export const GET_ASYNC_SESSION_FAILURE = concat(MODULE_NAME, 'GET_ASYNC_SESSION_FAILURE')
export const CLEAR_ASYNC_SESSION = concat(MODULE_NAME, 'CLEAR_ASYNC_SESSION')

export const APPROVE_PLATFORM_CONNECTION = concat(MODULE_NAME, 'APPROVE_PLATFORM_CONNECTION')

export const SET_PDF_GENERATION = concat(MODULE_NAME, 'SET_PDF_GENERATION')

export const TRIGGER_TABLE_COPY = concat(MODULE_NAME, 'TRIGGER_TABLE_COPY')
export const CLEAR_TRIGGER_TABLE_COPY = concat(MODULE_NAME, 'CLEAR_TRIGGER_TABLE_COPY')

export const SET_SELECTED_LANGUAGE = concat(MODULE_NAME, 'SET_SELECTED_LANGUAGE')
export const UPDATE_CURRENT_USER_PROFILE_AGENCIES = concat(MODULE_NAME, 'UPDATE_CURRENT_USER_PROFILE_AGENCIES')

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// set client view
export function setClientView(isClientView) {
  setLocalStorage(CONTROLLER_LANDING_PAGE_VIEW, isClientView)
  return { type: SET_CLIENT_VIEW, isClientView }
}

// Get user invites
export function getUserInvites(params) {
  return { type: GET_USER_INVITES, params }
}

export function getUserInvitesSuccess(data) {
  return { type: GET_USER_INVITES_SUCCESS, data }
}

export function getUserInvitesFailure(error) {
  return { type: GET_USER_INVITES_FAILURE, error }
}

export function clearGetUserInvites() {
  return { type: CLEAR_GET_USER_INVITES }
}

export function getCurrentUserProfile() {
  return { type: GET_CURRENT_USER_PROFILE }
}

export function getCurrentUserProfileSuccess(userData) {
  if (window.analytics) {
    // Segment analytics implementation
    window.analytics.identify(userData.id, {
      company: {
        id: window.location.hostname,
        name: getAppDomainName()
      },
      website: window.location.hostname.origin,
      plan: 'some_plan',
      name: userData.full_name,
      email: userData.email
    })
  }
  return { type: GET_CURRENT_USER_PROFILE_SUCCESS, userData }
}

export function getCurrentUserProfileFailure(error) {
  return { type: GET_CURRENT_USER_PROFILE_FAILURE, error }
}

// Submit server logout
export function submitServerLogout(data) {
  return { type: SUBMIT_SERVER_LOGOUT, data }
}

export function submitServerLogoutSuccess(data) {
  return { type: SUBMIT_SERVER_LOGOUT_SUCCESS, data }
}

export function submitServerLogoutFailure(error) {
  return { type: SUBMIT_SERVER_LOGOUT_FAILURE, error }
}

export function clearSubmitServerLogout() {
  return { type: CLEAR_SUBMIT_SERVER_LOGOUT }
}

export function updateCurrentUserProfile(data) {
  return { type: UPDATE_CURRENT_USER_PROFILE, data }
}

export function updateCurrentUserProfileSuccess(userData) {
  return { type: UPDATE_CURRENT_USER_PROFILE_SUCCESS, userData }
}

export function updateCurrentUserProfileFailure(error) {
  return { type: UPDATE_CURRENT_USER_PROFILE_FAILURE, error }
}

export function clearUpdateCurrentUserProfile() {
  return { type: CLEAR_UPDATE_CURRENT_USER_PROFILE }
}

export function createCurrentUserSelfAccount(selfAccountData) {
  return { type: CREATE_CURRENT_USER_SELF_ACCOUNT, selfAccountData }
}

export function updateCurrentUserSelfAccount(selfAccountData) {
  return { type: UPDATE_CURRENT_USER_SELF_ACCOUNT, selfAccountData }
}

// Verify current user
export function verifyCurrentUser(data) {
  return { type: VERIFY_CURRENT_USER, data }
}

export function verifyCurrentUserSuccess(data) {
  return { type: VERIFY_CURRENT_USER_SUCCESS, data }
}

export function verifyCurrentUserFailure(error) {
  return { type: VERIFY_CURRENT_USER_FAILURE, error }
}

export function clearVerifyCurrentUser() {
  return { type: CLEAR_VERIFY_CURRENT_USER }
}

// Set mfa type
export function showMFASetupPage(show) {
  return { type: SHOW_MFA_SETUP_PAGE, show }
}

// Set mfa type
export function setMFAType(data) {
  return { type: SET_MFA_TYPE, data }
}

export function setMFATypeSuccess(data) {
  return { type: SET_MFA_TYPE_SUCCESS, data }
}

export function setMFATypeFailure(error) {
  return { type: SET_MFA_TYPE_FAILURE, error }
}

export function clearSetMFAType() {
  return { type: CLEAR_SET_MFA_TYPE }
}

export function setSearch(searchTerm, searchKey) {
  return { type: SET_SEARCH, searchTerm, searchKey }
}

export function clearSearch() {
  return { type: CLEAR_SEARCH }
}

export function updateCurrentUserController(controllerData) {
  return { type: UPDATE_CURRENT_USER_CONTROLLER, controllerData }
}

export function updateControllerPermissions(controllerId, permissions) {
  return { type: UPDATE_CONTROLLER_PERMISSIONS, controllerId, permissions }
}

export function setSelectedSelfAccount(accountId, newSelectedLanguage) {
  setLocalStorage(SELECTED_SELF_ACCOUNT, accountId)
  return { type: SET_SELECTED_SELF_ACCOUNT, accountId, newSelectedLanguage }
}

export function setContextSelfAccount(accountId) {
  return { type: SET_CONTEXT_SELF_ACCOUNT, accountId }
}

export function setSelectedPlatform(platform) {
  setLocalStorage(SELECTED_PLATFORM, platform)
  return { type: SET_SELECTED_PLATFORM, platform }
}

export function approvePlatformConnection(authData) {
  return { type: APPROVE_PLATFORM_CONNECTION, authData }
}

export function getBanners() {
  return { type: GET_BANNERS }
}

export function getBannersSuccess(bannersData) {
  return { type: GET_BANNERS_SUCCESS, bannersData }
}

export function getBannersFailure(error) {
  return { type: GET_BANNERS_FAILURE, error }
}

// Get controller landing page setup
export function getControllerLandingPageSetup(params) {
  return { type: GET_CONTROLLER_LANDING_PAGE_SETUP, params }
}

export function getControllerLandingPageSetupSuccess(data) {
  return { type: GET_CONTROLLER_LANDING_PAGE_SETUP_SUCCESS, data }
}

export function getControllerLandingPageSetupFailure(error) {
  return { type: GET_CONTROLLER_LANDING_PAGE_SETUP_FAILURE, error }
}

export function clearGetControllerLandingPageSetup() {
  return { type: CLEAR_GET_CONTROLLER_LANDING_PAGE_SETUP }
}

// get async sessions
export function getAsyncSession(sessionId, platform, params) {
  return { type: GET_ASYNC_SESSION, sessionId, platform, params }
}

export function getAsyncSessionSuccess(sessionData) {
  return { type: GET_ASYNC_SESSION_SUCCESS, sessionData }
}

export function getAsyncSessionFailure(error, sessionData = {}) {
  return { type: GET_ASYNC_SESSION_FAILURE, error, sessionData }
}

export function clearAsyncSession() {
  return { type: CLEAR_ASYNC_SESSION }
}

export function setPdfGeneration(isGenerating) {
  return { type: SET_PDF_GENERATION, isGenerating }
}

export function triggerTableCopy(tableId) {
  return { type: TRIGGER_TABLE_COPY, tableId }
}

export function clearTriggerTableCopy() {
  return { type: CLEAR_TRIGGER_TABLE_COPY }
}

export function setSelectedLanguage(languageCode) {
  return { type: SET_SELECTED_LANGUAGE, languageCode }
}

export function updateCurrentUserProfileAgencies(agencies) {
  // This action is used without request, only to update the current user profile agencies
  return { type: UPDATE_CURRENT_USER_PROFILE_AGENCIES, agencies }
}

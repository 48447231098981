import { ENDPOINTS } from '../../constants/api'
import { madeRequest } from '../../helpers/api'

// PLOP_APPEND_PATTERN_ANCHOR

export function getMediaPackageService(id, params) {
  return madeRequest('GET', {
    params,
    url: `${ENDPOINTS.mediaPackages}${id}/`
  })
}

export function deleteMediaPackageService(id) {
  return madeRequest('DELETE', {
    url: `${ENDPOINTS.mediaPackages}${id}/`
  })
}

export function getMediaPackageCategoriesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaPackageCategories
  })
}

export function createMediaPackageCategoryService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaPackageCategories
  })
}

export function updateMediaPackageCategoryService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaPackageCategories}${id}/`
  })
}

export function updateMediaPackageCategoriesOrderService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaPackageCategoriesOrderUpdate
  })
}

export function getGroupedMediaPackagesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.groupedMediaPackages
  })
}

export function getMediaPackagesService(params) {
  return madeRequest('GET', {
    params,
    url: ENDPOINTS.mediaPackages
  })
}

export function createMediaPackageService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaPackages
  })
}

export function updateMediaPackageService(id, data) {
  return madeRequest('PATCH', {
    data,
    url: `${ENDPOINTS.mediaPackages}${id}/`
  })
}

export function updateMediaPackagesOrderService(data) {
  return madeRequest('POST', {
    data,
    url: ENDPOINTS.mediaPackagesOrderUpdate
  })
}

export function getMediaPackagesAsCsvService(params) {
  return madeRequest('GET', {
    params,
    returnHeaders: true,
    url: ENDPOINTS.mediaPackagesExportToCsv
  })
}

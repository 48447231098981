import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import Table from '../../../../../../components/Table'
import AdaptiveActions from '../../../../../../features/components/AdaptiveActions'

import { openForm } from '../../../../../../modules/actions/forms'

import { mediaSubCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import { MEDIA_SUB_CATEGORY_EDIT } from '../../../../../../constants/forms'

import { phonesDownSize } from '../../../../../../styles/const/breakpoints'
import useProductCardsStyles from '../../../../MediaProducts/productCardStyles'
import { tableColumnsSize } from '../constants/columns'

const ProductSubCategoriesTableData = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })
  const { t } = useTranslation()

  // const updateMediaSubCategoryId = useSelector(updateMediaSubCategoryIdSelector)

  const classes = useProductCardsStyles()

  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)

  const openEditFormHandler = useCallback(
    (e, id) => {
      dispatch(openForm({ formName: MEDIA_SUB_CATEGORY_EDIT, id }))
    },
    [dispatch]
  )

  const dropdownOptions = useMemo(() => {
    return [
      {
        text: t('Edit Asset Type'),
        onClickHandler: openEditFormHandler
      }
    ]
  }, [openEditFormHandler, t])

  const assetTypeColumns = useMemo(
    () => [
      {
        fieldKey: 'name'
      },
      {
        Cell: data => <AdaptiveActions itemId={data.id} options={dropdownOptions} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ],
    [isMobile, dropdownOptions]
  )

  return (
    <Table
      hideHeadlineRow
      data={mediaSubCategories}
      cols={assetTypeColumns}
      // todo implement when edit form is ready
      // itemUpdatingId={updateMediaSubCategoryId}
      tableId="ProductSubCategoriesTableData"
      hideFooterRow
      mobileColumnsWrapperClassName={classes.mobileTableHeader}
    />
  )
}

export default ProductSubCategoriesTableData

import {
  tableColumnsSize as reusableTableColumnSize,
  tableColumnMinSize as reusableTableColumnMinSize
} from '../../../../features/components/MediaOrders/MediaOrdersTableData/constants/columnSizes'

export const tableColumnsSize = {
  ...reusableTableColumnSize
}

// reused Default media orders column, but as this page is wider, give more space to the name and campaign name
export const tableColumnMinSize = {
  ...reusableTableColumnMinSize,
  name: 200,
  campaignName: 200,
  account: 100
}

export const dropdownColumnSizes = { minWidth: tableColumnsSize.actions, maxWidth: tableColumnsSize.actions }
export const mediaColumnSizesMobile = { minWidth: '50%', maxWidth: '50%' }
export const nameColumnSizesMobile = { minWidth: '100%', maxWidth: '100%' }

export const mobileColumnsSchema = [{ style: nameColumnSizesMobile }]

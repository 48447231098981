import { useSelector } from 'react-redux'
import { useCallback, useState } from 'react'

import { contractBuilderCalculatedDiscountCostSelector } from '../../../../../modules/selectors/contracts'

import { isDiscountValid } from '../../../../../features/components/Contract/helpers/isDiscountsValid'
import { useShouldCheckApproval } from './useShouldCheckApproval'

export function useApprovalProcess({ contractData }) {
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)
  const [approvalModalData, setApprovalModalData] = useState(null)
  const [isDisapprovalModalOpen, setIsDisapprovalModalOpen] = useState(false)

  const calculatedDiscountCost = useSelector(contractBuilderCalculatedDiscountCostSelector)

  const contractId = contractData?.id
  const currencySymbol = contractData?.currency_symbol
  const contractDiscountedCost = contractData?.media_order?.cost_with_discount

  const { isPackageApprover, hasProposalApprovalFlow, shouldCheckApprovalRequirements } = useShouldCheckApproval(
    contractData?.status
  )

  const handleOpenApprovalModal = useCallback(data => {
    setIsApprovalModalOpen(true)
    setApprovalModalData(data)
  }, [])

  const handleCloseApprovalModal = useCallback(() => {
    setIsApprovalModalOpen(false)
    setApprovalModalData(null)
  }, [])

  const handleOpenDisapprovalModal = useCallback(() => {
    setIsDisapprovalModalOpen(true)
  }, [])

  const handleCloseDisapprovalModal = useCallback(() => {
    setIsDisapprovalModalOpen(false)
  }, [])

  const isMatchRequirements = useCallback(() => {
    // When proposal total cost is less than what the total cost would be with “rate card discount” applied then the proposal requires approval.
    // If the total cost is equal to or more than rate card discount, even if its on different media products, it does not need approval.
    const { isValid } = isDiscountValid({
      calculatedTotalDiscountCost: calculatedDiscountCost,
      contractDiscountedCost
    })

    if (!isValid) {
      handleOpenApprovalModal({
        contractId,
        // If proposal is status pending_approval and user has is_package_approver permission, they will see a Review discount btn and modal:
        isReviewMode: isPackageApprover,
        currencySymbol: currencySymbol,
        proposalCost: contractDiscountedCost,
        calculatedRateCost: calculatedDiscountCost
      })
    }

    // match requirements if the proposal does not require approval
    return isValid
  }, [
    calculatedDiscountCost,
    contractDiscountedCost,
    handleOpenApprovalModal,
    contractId,
    isPackageApprover,
    currencySymbol
  ])

  return {
    isPackageApprover,
    isMatchRequirements,
    hasProposalApprovalFlow,
    shouldCheckApprovalRequirements,
    // approval
    isApprovalModalOpen,
    approvalModalData,
    handleCloseApprovalModal,
    // disapproval
    isDisapprovalModalOpen,
    handleOpenDisapprovalModal,
    handleCloseDisapprovalModal
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import Spoiler from '../../../../../../../../../components/Spoiler'
import FieldRow from '../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../../../../components/Form/Field'
import CreatableTagsMultiSelect from '../CreatableTagsMultiSelect'
import VariablesPaginatedMultiSelect from '../../../../../ReusableFormFIelds/VariablesPaginatedMultiSelect'

import { INTERNAL_ID } from '../../../fields'

const AdvancedSettingsFields = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <Spoiler title="Advanced settings">
      <FieldRow
        title={t('Filter tags (optional)')}
        description={t('User will be able to filter product lists with any tags added or selected here')}
      >
        <CreatableTagsMultiSelect formik={formik} />
      </FieldRow>
      <FieldRow
        title={t('Product variables (optional)')}
        description={t('Product variables are options or parameters that the user selects during the booking process.')}
      >
        <VariablesPaginatedMultiSelect formik={formik} />
      </FieldRow>
      <FieldRow title={t('Internal ID (optional)')}>
        <Field formik={formik} name={INTERNAL_ID} autoComplete="off" placeholder="Internal ID" />
      </FieldRow>
    </Spoiler>
  )
}

export default AdvancedSettingsFields

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import InfoModalNew from '../../../../../../../features/components/Modals/InfoModalNew'
import InfoModalButtons from '../../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons'

import { useGenerateReachReport } from './useGenerateReachReport'

import { redirectTo } from '../../../../../../../helpers/url'
import { showToasts } from '../../../../../../../helpers/toasts'
import { TOAST_TYPE } from '../../../../../../../constants/other'
import { ROUTE_PARAMS, ROUTES } from '../../../../../../../constants/routes'
import { PRIMARY_BUTTON } from '../../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons/helpers'

function GenerateCampaignReportModal({ isOpen, onClose, mediaOrderId }) {
  const { t } = useTranslation()

  const { handleGenerateReport, isLoading, requestSuccess, requestError } = useGenerateReachReport(mediaOrderId)

  const handleSuccessSubmit = useCallback(() => {
    showToasts({
      type: TOAST_TYPE.success,
      message: t('Report generated successfully')
    })
    redirectTo(ROUTES.mediaOrderSummaryDocuments, {
      [ROUTE_PARAMS.mediaOrderId]: mediaOrderId
    })
  }, [mediaOrderId, t])

  const buttons = useMemo(() => {
    return [
      {
        type: 'button',
        text: t('Cancel'),
        onClick: onClose
      },
      {
        type: 'progress',
        text: t('Generate Report'),
        buttonColor: PRIMARY_BUTTON,
        onClick: handleGenerateReport,
        successSubmit: requestSuccess,
        isFormLoading: isLoading,
        formError: requestError,
        onSuccessSubmit: handleSuccessSubmit
      }
    ]
  }, [handleGenerateReport, isLoading, onClose, handleSuccessSubmit, requestError, requestSuccess, t])

  return (
    <InfoModalNew
      modalTitle={t('Generate post campaign reach report?')}
      ModalMessage={t(
        'Please ensure that all footfall data has been loaded for the campaign dates before generating the report.'
      )}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InfoModalButtons buttons={buttons} />
    </InfoModalNew>
  )
}

GenerateCampaignReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mediaOrderId: PropTypes.number
}

export default GenerateCampaignReportModal

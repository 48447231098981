import { useSelector } from 'react-redux'

import usePermissions from '../../../../../hooks/usePermissions'

import { selectedControllerDataSelector } from '../../../../../modules/selectors/app'

import { PACKAGE_APPROVE_PERMISSION } from '../../../../../constants/permissions'
import { CONTRACT_APPROVED_STATUS } from '../../../../../constants/contacts'

export function useShouldCheckApproval(contractStatus) {
  const controllerData = useSelector(selectedControllerDataSelector)

  const hasProposalApprovalFlow = controllerData?.quotation_approval_workflow

  const permissions = usePermissions()
  const hasPackageApprovePermissions = permissions.can('manage', PACKAGE_APPROVE_PERMISSION)

  // If status is approved, both buttons are active, when the click it allows them to book or send
  const isApproved = contractStatus === CONTRACT_APPROVED_STATUS

  // if controller quotation_approval_workflow = true, then we need to have approvals logic on proposals builder
  // if the user has is_package_approver permission, they won’t see the modal and will book as normal
  const shouldCheckApprovalRequirements = !isApproved && hasProposalApprovalFlow && !hasPackageApprovePermissions

  return {
    hasProposalApprovalFlow,
    isPackageApprover: hasPackageApprovePermissions,
    shouldCheckApprovalRequirements
  }
}

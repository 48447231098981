import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'

import { createJsonFromQueryString } from '../../helpers/url'

const defaultParams = {}

const initialLoadOptions = {
  shouldClearExistingState: true
}

export const useLoadPaginatedList = ({
  allowFetch = true,
  params = defaultParams,
  action,
  clearAction,
  next,
  offsetAdjust = 0
}) => {
  const dispatch = useDispatch()

  const load = useCallback(
    nextParams => {
      dispatch(
        action({
          ...params,
          ...nextParams
        })
      )
    },
    [dispatch, action, params]
  )

  const loadMoreHandler = useCallback(() => {
    const nextParams = createJsonFromQueryString(`?${next.split('?')[1]}`)

    load({
      ...nextParams,
      ...(nextParams?.offset && { offset: nextParams.offset - offsetAdjust })
    })
  }, [load, next, offsetAdjust])

  useEffect(() => {
    // Initial load
    allowFetch && dispatch(action({ limit: 20, ...params }, initialLoadOptions))
  }, [dispatch, allowFetch, action, params])

  useEffect(() => {
    return () => {
      clearAction && dispatch(clearAction())
    }
  }, [clearAction, dispatch])

  return next ? loadMoreHandler : null
}

import { PROPOSAL_PLATFORM_USER_RECEIVER, PROPOSAL_EMAIL_RECEIVER } from './fields'
import {
  EMAIL,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../ReusableFormFields/RecipientsFields/fields'

export const transformValuesToBE = values => {
  if (values[USER_OPTION] === SEND_TO_PLATFORM_USER) {
    return {
      // formik manages the array of users, but proposals support only one user
      [PROPOSAL_PLATFORM_USER_RECEIVER]: values[NOTIFICATION_USERS][0]
    }
  } else if (values[USER_OPTION] === SEND_BY_EMAIL) {
    return {
      // formik manages the array of emails, but proposals support only one email
      [PROPOSAL_EMAIL_RECEIVER]: values[NOTIFICATION_EMAILS][0][EMAIL]
    }
  }
  return {}
}

import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import MediaSubCategoryCreateForm from './MediaSubCategoryCreateForm'

import { MEDIA_SUB_CATEGORY_CREATE } from '../../../../../constants/forms'

function MediaSubCategoryCreate() {
  return (
    <FormDrawerWrapper
      formName={MEDIA_SUB_CATEGORY_CREATE}
      title="Asset Types"
      description="Asset Types categorise media products into categories and subcategories for easier filtering and reporting."
      openButtonText="Create asset type"
    >
      <MediaSubCategoryCreateForm />
    </FormDrawerWrapper>
  )
}

export default MediaSubCategoryCreate

import { differenceInDays, differenceInCalendarMonths, startOfDay, endOfDay, endOfMonth } from 'date-fns'

import {
  PERIOD_ONE_MONTH,
  PERIOD_THREE_MONTH,
  PERIOD_TWO_MONTH
} from '../../../../../../../../../../constants/mediaOrders'

/**
 * Calculates the total number of days between all selected date periods and formats it as days, weeks, or months.
 * If the period is a month, it displays the number of months.
 * Otherwise, it displays the number of days unless divisible by 7, in which case it displays the number of weeks.
 *
 * @returns {string} - The formatted period label.
 */
export const getSelectedPeriodLabel = ({ groupedPeriods, productPeriod }) => {
  const totalDays = groupedPeriods.reduce((acc, period) => {
    const groupPeriodStart = startOfDay(period[0].date_start)
    const groupPeriodEnd = endOfDay(period[period.length - 1].date_end)

    const periodDays = 1 + differenceInDays(new Date(groupPeriodEnd), groupPeriodStart)
    return acc + periodDays
  }, 0)
  const totalWeeks = totalDays / 7

  const isMonthsPeriod =
    productPeriod === PERIOD_ONE_MONTH || productPeriod === PERIOD_TWO_MONTH || productPeriod === PERIOD_THREE_MONTH
  if (isMonthsPeriod) {
    return getMonthPeriodLabel({ groupedPeriods, totalDays })
  }

  if (totalDays % 7 === 0) {
    // if the total number of days is divisible by 7, display the number of weeks
    return formatPeriodLabel(totalWeeks, 'weeks')
  }

  return formatPeriodLabel(totalDays, 'days')
}

const getMonthPeriodLabel = ({ groupedPeriods, totalDays }) => {
  const isPeriodsStartOnFirstDayOfMonth = groupedPeriods.every(period => {
    const startPeriodDate = new Date(period[0].date_start).getDate()

    return startPeriodDate === 1
  })
  const isPeriodsEndOnLastDayOfMonth = groupedPeriods.every(period => {
    const endPeriodDate = new Date(period[period.length - 1].date_end).getDate()
    const lastDayOfMonth = endOfMonth(new Date(period[period.length - 1].date_end))

    return endPeriodDate === lastDayOfMonth.getDate()
  })

  if (isPeriodsStartOnFirstDayOfMonth && isPeriodsEndOnLastDayOfMonth) {
    // find difference in months between start and end date
    const differenceInMonths = groupedPeriods.reduce((acc, period) => {
      const startPeriodDate = new Date(period[0].date_start)
      const endPeriodDate = new Date(period[period.length - 1].date_end)

      const difference = differenceInCalendarMonths(endPeriodDate, startPeriodDate) + 1
      return acc + difference
    }, 0)
    return formatPeriodLabel(differenceInMonths, 'months')
  } else {
    // If there is a case where the months are not starting on 1st and ending on end date of month you can show as days
    return formatPeriodLabel(totalDays, 'days')
  }
}

const formatPeriodLabel = (value, unit) => {
  if (value === 1) {
    // singular form
    // 1 day, 1 week, 1 month
    return `1 ${unit.slice(0, -1)}`
  }
  // plural form
  // 2 days, 2 weeks, 2 months
  return `${value} ${unit}`
}

import React, { useCallback, useMemo } from 'react'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'

import MultiSelectBox from '../../../../../../../../../features/components/Form/MultiSelectBox'

import { getLoadOptionsHandler } from '../../../../../../../../../features/helpers/componentsHelpers'

import { selectedControllerIdSelector } from '../../../../../../../../../modules/selectors/app'
import { getMediaSubCategoriesService } from '../../../../../../../../../modules/services/mediaOrdersProducts'

import { MEDIA_PRODUCT_ATTRIBUTE, VALUES } from '../../../../fields'

const MediaSubSubCategoriesPaginatedSelect = ({ formik }) => {
  const { values, setFieldValue } = formik

  const controllerId = useSelector(selectedControllerIdSelector)

  const formatSubSubCategories = useCallback(list => {
    // We don't have separate endpoint for sub sub categories,
    // so we use sub categories and flatten them
    return list
      .reduce((acc, item) => {
        if (item.sub_sub_categories.length) {
          acc = acc.concat(item.sub_sub_categories)
        }
        return acc
      }, [])
      .map(item => {
        return {
          ...item,
          value: item.id,
          label: item.name
        }
      })
  }, [])

  const loadOptions = useMemo(() => {
    return getLoadOptionsHandler({
      getOptionsService: getMediaSubCategoriesService,
      params: {
        controller: controllerId,
        ordering: 'name'
      },
      formatOptions: formatSubSubCategories
    })
  }, [controllerId, formatSubSubCategories])

  return (
    <MultiSelectBox
      setFieldValue={setFieldValue}
      name={`${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`}
      placeholder="Select"
      loadOptions={loadOptions}
      value={getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`)}
      portaled={true}
    />
  )
}

export default MediaSubSubCategoriesPaginatedSelect

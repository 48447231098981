import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import MultiSelectBox from '../../../Form/MultiSelectBox'

import { formatOptionsList } from '../../../../formatters'

import {
  getMediaProductLocationRegionsIsLoadingSelector,
  mediaProductLocationRegionsSelector
} from '../../../../../modules/selectors/mediaOrdersProductLocations'

export const LOCATION_REGIONS = 'locationRegions'

export const LocationRegionsFilter = ({ Context }) => {
  const { filtersState, setFiltersState } = useContext(Context)

  const mediaProductLocationRegions = useSelector(mediaProductLocationRegionsSelector)
  const getMediaProductLocationRegionsIsLoading = useSelector(getMediaProductLocationRegionsIsLoadingSelector)

  const { [LOCATION_REGIONS]: locationCategories } = filtersState

  const formattedMediaProductLocationRegionsOptions = useMemo(() => {
    return formatOptionsList({
      list: mediaProductLocationRegions,
      valueName: 'id',
      labelName: 'name'
    })
  }, [mediaProductLocationRegions])

  const setLocationRegionHandler = useCallback(
    (name, newLocationRegions) => {
      const updatedFiltersState = {
        ...filtersState,
        [LOCATION_REGIONS]: newLocationRegions
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState]
  )

  return (
    <MultiSelectBox
      name="Location Region"
      options={formattedMediaProductLocationRegionsOptions}
      isLoading={getMediaProductLocationRegionsIsLoading}
      value={locationCategories}
      placeholder="Location Region"
      setFieldValue={setLocationRegionHandler}
      portaled
    />
  )
}

LocationRegionsFilter.propTypes = {
  Context: PropTypes.object
}

export default LocationRegionsFilter

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import classnames from 'classnames'

import Page from '../../index'
import ProposalCreateForm from './ProposalCreateForm'
import ProposalBookOrSend from './ProposalBookOrSend'
import SkeletonQuotationBuilderForm from './Skeleton'
import ProposalBuilderPageBreadcrumbs from './ProposalBuilderPageBreadcrumbs'
import { MediaOrderFormProvider } from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderProvider } from '../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import { useFetchBookingVariablesForContractBuilder } from '../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/useFetchBookingVariablesForContractBuilder'

import { clearActiveContract, clearGetContract, getContract } from '../../../modules/actions/contracts'
import { activeContractSelector, getContractIsLoadingSelector } from '../../../modules/selectors/contracts'
import { bookingVariablesWasLoadedSelector } from '../../../modules/selectors/mediaOrdersBookings'

import useStyles from './styles'
import useCommonStyles from '../../../styles/common/listPage'

const QuotationBuilderPage = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const activeContract = useSelector(activeContractSelector)
  const contractIsLoading = useSelector(getContractIsLoadingSelector)
  const bookingVariablesWasLoaded = useSelector(bookingVariablesWasLoadedSelector)

  const createdContractId = activeContract?.id
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(
        getContract(id, {
          inventory_detail: true,
          competing_quotation: true // allows to get competing quotation data
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch(clearActiveContract())
      dispatch(clearGetContract())
    }
  }, [dispatch])

  useFetchBookingVariablesForContractBuilder()

  return (
    <Page helmetTitle="helmetTitle.QuotationCreatePage">
      {contractIsLoading || !bookingVariablesWasLoaded ? (
        <SkeletonQuotationBuilderForm />
      ) : (
        <MediaOrderFormProvider initialSelfAccount={activeContract?.account}>
          <div className={classnames(commonClasses.heading, classes.heading)}>
            <ProposalBuilderPageBreadcrumbs />
            {createdContractId && <ProposalBookOrSend contractId={createdContractId} />}
          </div>

          <ContractBuilderProvider contractId={createdContractId}>
            <ProposalCreateForm />
          </ContractBuilderProvider>
        </MediaOrderFormProvider>
      )}
    </Page>
  )
}

export default QuotationBuilderPage

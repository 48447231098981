import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  GET_CONTRACT,
  getContractSuccess,
  getContractFailure,
  CREATE_CONTRACT,
  createContractFailure,
  createContractSuccess,
  UPDATE_CONTRACT,
  updateContractSuccess,
  updateContractFailure,
  SIGN_CONTRACT,
  signContractSuccess,
  signContractFailure,
  DELETE_CONTRACT_SIGNATURE,
  deleteContractSignatureSuccess,
  deleteContractSignatureFailure,
  GET_CONTRACTS,
  getContractsSuccess,
  getContractsFailure,
  UPDATE_CONTRACTS_STATUS_BULK,
  updateContractsStatusBulkSuccess,
  updateContractsStatusBulkFailure,
  CLEAR_UPDATE_CONTRACT,
  CLEAR_CREATE_CONTRACT
} from '../actions/contracts'

import {
  getContractService,
  createContractService,
  deleteContractSignatureService,
  getContractsService,
  signContractService,
  updateContractService,
  updateContractsStatusBulkService
} from '../services/contracts'
import { race, take } from 'typed-redux-saga'
import * as Sentry from '@sentry/react'

const checkContractProductsDuplication = (detailProducts = [], mediaOrderProducts = []) => {
  // check if there are any products duplication in detailed products
  const productsIds = detailProducts.map(product => product.media_product)
  const uniqueProducts = [...new Set(productsIds)]
  const hasDetailProductsDuplicated = productsIds.length !== uniqueProducts.length

  if (hasDetailProductsDuplicated) {
    const detailError = new Error('Detail products are duplicated')
    Sentry.captureException(detailError, {
      extra: {
        ids: JSON.stringify(uniqueProducts, null, 2),
        detailProducts: JSON.stringify(detailProducts, null, 2)
      }
    })
  }

  const mediaOrderProductsIds = mediaOrderProducts.map(product => product.media_product)
  const uniqueMediaOrderProducts = [...new Set(mediaOrderProductsIds)]
  const hasMediaOrderProductsDuplicated = mediaOrderProductsIds.length !== uniqueMediaOrderProducts.length

  if (hasMediaOrderProductsDuplicated) {
    const mediaOrderError = new Error('Media order products are duplicated')
    Sentry.captureException(mediaOrderError, {
      extra: {
        ids: JSON.stringify(mediaOrderProductsIds, null, 2),
        mediaOrderProducts: JSON.stringify(mediaOrderProducts, null, 2)
      }
    })
  }

  return hasMediaOrderProductsDuplicated || hasDetailProductsDuplicated
}

function* contractsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_CONTRACT, getContractWorker),
    takeEvery(CREATE_CONTRACT, createContractWorker),
    takeLatest(UPDATE_CONTRACT, updateContractWorker),
    takeEvery(UPDATE_CONTRACTS_STATUS_BULK, updateContractsStatusBulkWorker),
    takeEvery(SIGN_CONTRACT, signContractWorker),
    takeEvery(DELETE_CONTRACT_SIGNATURE, deleteContractSignatureWorker),
    takeEvery(GET_CONTRACTS, getContractsWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* updateContractsStatusBulkWorker({ data }) {
  try {
    const response = yield call(updateContractsStatusBulkService, data)
    yield put(updateContractsStatusBulkSuccess(response))
  } catch (error) {
    yield put(updateContractsStatusBulkFailure(error))
  }
}

function* getContractWorker({ id, params }) {
  try {
    const response = yield call(getContractService, id, params)
    yield put(getContractSuccess(response))
    checkContractProductsDuplication(response?.detail?.products, response?.media_order?.products)
  } catch (error) {
    yield put(getContractFailure(error))
  }
}

function* createContractWorker({ data }) {
  try {
    const { response } = yield race({
      response: call(createContractService, data),
      // need to cancel previous task, if user make left the page
      cancel: take(CLEAR_CREATE_CONTRACT)
    })

    if (response) {
      yield put(createContractSuccess(response))
      checkContractProductsDuplication(response?.detail?.products, response?.media_order?.products)
    }
  } catch (error) {
    yield put(createContractFailure(error))
  }
}

function* updateContractWorker({ id, data, details }) {
  try {
    const { requestMethod, editProductId } = details
    const { response } = yield race({
      response: call(updateContractService, { id, data, requestMethod }),
      // need to cancel previous task, if user make a new update or left the page
      cancel: take(CLEAR_UPDATE_CONTRACT)
    })
    if (response) {
      yield put(updateContractSuccess(response, editProductId))
      checkContractProductsDuplication(response?.detail?.products, response?.media_order?.products)
    }
  } catch (error) {
    yield put(updateContractFailure(error))
  }
}

function* signContractWorker({ id, data, params }) {
  try {
    const response = yield call(signContractService, id, data, params)
    yield put(signContractSuccess(response))
  } catch (error) {
    yield put(signContractFailure(error))
  }
}

function* deleteContractSignatureWorker({ id, params }) {
  try {
    const response = yield call(deleteContractSignatureService, id, params)
    yield put(deleteContractSignatureSuccess(response))
  } catch (error) {
    yield put(deleteContractSignatureFailure(error))
  }
}

function* getContractsWorker({ params }) {
  try {
    const response = yield call(getContractsService, params)
    yield put(getContractsSuccess(response))
  } catch (error) {
    yield put(getContractsFailure(error))
  }
}

export default contractsWatcher

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

const FormField = ({ label, children, isLastFormField, className, childrenClassName }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.formField, className, {
        isLastFormField
      })}
    >
      {label && <p className={classes.formFieldLabel}>{t(label)}</p>}
      <div className={classnames(classes.childrenWrapper, childrenClassName)}>{children}</div>
    </div>
  )
}

FormField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  isLastFormField: PropTypes.bool,
  className: PropTypes.string,
  childrenClassName: PropTypes.string
}

export default FormField

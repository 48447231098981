import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearDeleteFootfallAreas } from '../../../../../modules/actions/footfallData'
import {
  footfallAreasWasDeletedSelector,
  getFootfallAreasIsLoadingSelector,
  getFootfallAreasWasLoadedSelector
} from '../../../../../modules/selectors/footfallData'

export function useDeleteOffset() {
  const dispatch = useDispatch()
  // count the amount of deleted footfall areas between the last and the current GET paginated request - reset on new portion loaded
  const [deletedCount, setDeletedCount] = useState(0)

  const getFootfallAreasIsLoading = useSelector(getFootfallAreasIsLoadingSelector)
  const getFootfallAreasWasLoaded = useSelector(getFootfallAreasWasLoadedSelector)
  const footfallAreasWasDeleted = useSelector(footfallAreasWasDeletedSelector)

  useEffect(() => {
    // set deletedCount when the item was deleted
    if (footfallAreasWasDeleted) {
      setDeletedCount(prev => prev + 1)
      dispatch(clearDeleteFootfallAreas())
    }
  }, [dispatch, footfallAreasWasDeleted])

  useEffect(() => {
    // reset deletedCount when the GET request was loaded
    if (getFootfallAreasWasLoaded && !getFootfallAreasIsLoading) {
      setDeletedCount(0)
    }
  }, [getFootfallAreasIsLoading, getFootfallAreasWasLoaded])

  return deletedCount
}

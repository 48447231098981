import React, { useCallback } from 'react'
import { getIn } from 'formik'

import ContentRow from '../../../../../../../features/components/ContentSection/ContentRow'
import Field from '../../../../../../../components/Form/Field'
import MediaAssetFilterValuesSelect from './MediaAssetFilterValuesSelect'

import { ATTRIBUTE, MEDIA_PRODUCT_ATTRIBUTE, mediaProductAttributeOptions, VALUES } from '../../fields'

import useStyles from '../styles'

const MediaAssetFilterContentRow = ({ formik }) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const attributeValues = getIn(values, `${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`)

  const onAttributeChange = useCallback(() => {
    if (attributeValues.length > 0) {
      // Clear values if attribute is changed
      setFieldValue(`${MEDIA_PRODUCT_ATTRIBUTE}.${VALUES}`, [])
    }
  }, [attributeValues, setFieldValue])

  return (
    <ContentRow
      title="Media Asset Filter (Optional)"
      description="The variable will only be asked on bookings that contain at least one qualifying media asset. Leave this blank to ask on all bookings."
      leftSideClassName={classes.leftSide}
      rightSideClassName={classes.rightSide}
    >
      <Field
        formik={formik}
        id={`${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`}
        name={`${MEDIA_PRODUCT_ATTRIBUTE}.${ATTRIBUTE}`}
        placeholder="Select Filter"
        options={mediaProductAttributeOptions}
        portaled={true}
        onValueChange={onAttributeChange}
      />
      <br />
      <MediaAssetFilterValuesSelect formik={formik} />
    </ContentRow>
  )
}

export default MediaAssetFilterContentRow

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InfoModalNew from '../../../../../../features/components/Modals/InfoModalNew'
import InfoModalButtons from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons'
import RecipientsTable from '../../../../../../features/components/MediaOrders/RecipientsTable'

import { useSendReachReport } from './useSendReachReport'
import { useMediaOrderRecipients } from '../../../../../../features/components/MediaOrders/RecipientsTable/useMediaOrderRecipients'

import { openForm } from '../../../../../../modules/actions/forms'
import { redirectTo } from '../../../../../../helpers/url'
import { showToasts } from '../../../../../../helpers/toasts'

import { ROUTES } from '../../../../../../constants/routes'
import { TOAST_TYPE } from '../../../../../../constants/other'
import { UPDATE_MEDIA_ORDER_RECIPIENTS } from '../../../../../../constants/forms'
import { PRIMARY_BUTTON } from '../../../../../../features/components/Modals/InfoModalNew/InfoModalButtons/helpers'

import useStyles from './styles'

function SendCampaignReportModal({ isOpen, onClose, mediaOrderId }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const { hasRecipients } = useMediaOrderRecipients()
  const { handleSendReport, isLoading, requestSuccess, requestError } = useSendReachReport(mediaOrderId)

  const handleAddRecipients = useCallback(() => {
    // open manage recipients drawer form
    redirectTo(ROUTES.mediaOrderSummaryDetails)

    dispatch(openForm({ formName: UPDATE_MEDIA_ORDER_RECIPIENTS }))
  }, [dispatch])

  const handleSuccessSubmit = useCallback(() => {
    showToasts({
      type: TOAST_TYPE.success,
      message: t('Report sent to booking recipients successfully')
    })
    onClose()
  }, [onClose, t])

  const noRecipientsButtons = useMemo(() => {
    return [
      {
        type: 'button',
        text: t('Cancel'),
        onClick: onClose
      },
      {
        type: 'button',
        text: t('Add recipients'),
        buttonColor: PRIMARY_BUTTON,
        onClick: handleAddRecipients
      }
    ]
  }, [handleAddRecipients, onClose, t])

  const buttons = useMemo(() => {
    return [
      {
        type: 'button',
        text: t('Cancel'),
        onClick: onClose
      },
      {
        type: 'progress',
        text: t('Send Report'),
        buttonColor: PRIMARY_BUTTON,
        onClick: handleSendReport,
        successSubmit: requestSuccess,
        isFormLoading: isLoading,
        formError: requestError,
        onSuccessSubmit: handleSuccessSubmit
      }
    ]
  }, [handleSendReport, isLoading, onClose, handleSuccessSubmit, requestError, requestSuccess, t])

  return hasRecipients ? (
    <InfoModalNew
      modalTitle={t('Send post campaign reach report?')}
      ModalMessage={t('This report will be sent to the following booking recipients:')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <RecipientsTable className={classes.sendReportRecipientsTable} showShortDetails={true} />
      <InfoModalButtons buttons={buttons} />
    </InfoModalNew>
  ) : (
    <InfoModalNew
      modalTitle={t('No recipients')}
      ModalMessage={t('There are no recipients on this booking. Please add at least 1 before proceeding.')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <InfoModalButtons buttons={noRecipientsButtons} />
    </InfoModalNew>
  )
}

SendCampaignReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mediaOrderId: PropTypes.number
}

export default SendCampaignReportModal

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TableDataLoader from '../../../../components/Table/TableDataLoader'
import MediaOrdersTableData from '../../../../features/components/MediaOrders/MediaOrdersTableData'

import { BookingsFiltersContext } from '../BookingsFilters/BookingsFiltersContext'

import { getMediaOrdersTableSchema } from '../../../MediaOrders/MediaOrdersTable/constants/columnsSchema'

import {
  mediaOrdersErrorSelector,
  mediaOrdersIsLoadingSelector,
  mediaOrdersSelector,
  mediaOrdersWasLoadedSelector
} from '../../../../modules/selectors/mediaOrders'
import {
  tableColumnMinSize,
  tableColumnsSize
} from '../../../../features/components/MediaOrders/MediaOrdersTableData/constants/columnSizes'
import useCommonStyles from '../../../../styles/common/listPage'

const BookingsTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()

  const mediaOrders = useSelector(mediaOrdersSelector)
  const { loadMoreMediaOrdersHandler } = useContext(BookingsFiltersContext)

  const bookingsColumnsSchema = useMemo(() => getMediaOrdersTableSchema(tableColumnsSize), [])

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no bookings')}</div>
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaOrders.length}
      errorSelector={mediaOrdersErrorSelector}
      wasLoadedSelector={mediaOrdersWasLoadedSelector}
      isLoadingSelector={mediaOrdersIsLoadingSelector}
      skeletonProps={{ cols: bookingsColumnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreMediaOrdersHandler}
    >
      <MediaOrdersTableData tableColumnsSize={tableColumnsSize} tableColumnMinSize={tableColumnMinSize} />
    </TableDataLoader>
  )
}

export default BookingsTable

import React from 'react'
import classnames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import useMediaOrderFieldsStyles from './ContractFields/styles'

import useStyles from './styles'

const AdditionalContractInfoSkeleton = ({ hasTopMargin }) => {
  const classes = useStyles()
  const mediaOrderFieldsClasses = useMediaOrderFieldsStyles()

  return (
    <div className={classnames(classes.container, { [classes.topMargin]: hasTopMargin })}>
      <div className={classes.mediaOrderInfo}>
        <Skeleton width={160} />
        <Skeleton width={140} />
        <Skeleton width={120} />
      </div>
      <div className={mediaOrderFieldsClasses.mediaOrderFieldsWrapper}>
        <Skeleton width={140} />
      </div>
    </div>
  )
}

export default AdditionalContractInfoSkeleton

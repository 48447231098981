import update from 'immutability-helper'

import {
  // PLOP_APPEND_PATTERN_ANCHOR_ACTIONS_IMPORT
  UPDATE_FOOTFALL_AREA,
  UPDATE_FOOTFALL_AREA_SUCCESS,
  UPDATE_FOOTFALL_AREA_FAILURE,
  CLEAR_UPDATE_FOOTFALL_AREA,
  CREATE_FOOTFALL_AREAS,
  CREATE_FOOTFALL_AREAS_SUCCESS,
  CREATE_FOOTFALL_AREAS_FAILURE,
  CLEAR_CREATE_FOOTFALL_AREAS,
  DELETE_FOOTFALL_AREAS,
  DELETE_FOOTFALL_AREAS_SUCCESS,
  DELETE_FOOTFALL_AREAS_FAILURE,
  CLEAR_DELETE_FOOTFALL_AREAS,
  GET_FOOTFALL_AREAS,
  GET_FOOTFALL_AREAS_SUCCESS,
  GET_FOOTFALL_AREAS_FAILURE,
  CLEAR_GET_FOOTFALL_AREAS,
  BULK_UPLOAD_FOOTFALL_DATA,
  BULK_UPLOAD_FOOTFALL_DATA_SUCCESS,
  BULK_UPLOAD_FOOTFALL_DATA_FAILURE,
  CLEAR_BULK_UPLOAD_FOOTFALL_DATA,
  CLEAR_GET_FOOTFALL_DATA_AS_CSV,
  GET_FOOTFALL_DATA_AS_CSV,
  GET_FOOTFALL_DATA_AS_CSV_FAILURE,
  GET_FOOTFALL_DATA_AS_CSV_SUCCESS
} from '../actions/footfallData'

import {
  INITIAL_CREATE_REDUCER,
  INITIAL_DELETE_REDUCER,
  INITIAL_GET_REDUCER,
  INITIAL_GET_RESULTS_PAGING_REDUCER,
  INITIAL_UPDATE_REDUCER
} from '../../constants/reducer'
import { deleteItemById, updateItem } from '../../helpers/modules/reducerHelpers'

const initialState = {
  // PLOP_APPEND_PATTERN_ANCHOR_INITIAL_REDUCER
  bulkUploadFootfallData: INITIAL_CREATE_REDUCER,
  footfallDataAsCsv: INITIAL_GET_REDUCER,
  footfallAreas: INITIAL_GET_RESULTS_PAGING_REDUCER,
  createFootfallAreas: INITIAL_CREATE_REDUCER,
  updateFootfallArea: INITIAL_UPDATE_REDUCER,
  deleteFootfallAreas: INITIAL_DELETE_REDUCER
}

export default function footfallData(state = initialState, action) {
  switch (action.type) {
    // PLOP_APPEND_PATTERN_ANCHOR_REDUCERS

    // Get footfall data as csv
    case GET_FOOTFALL_DATA_AS_CSV:
      return update(state, {
        footfallDataAsCsv: { $merge: { isLoading: true } }
      })
    case GET_FOOTFALL_DATA_AS_CSV_SUCCESS:
      return update(state, {
        footfallDataAsCsv: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            data: action.data,
            fileName: action.fileName
          }
        }
      })
    case GET_FOOTFALL_DATA_AS_CSV_FAILURE:
      return update(state, {
        footfallDataAsCsv: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_FOOTFALL_DATA_AS_CSV:
      return update(state, {
        footfallDataAsCsv: { $set: initialState.footfallDataAsCsv }
      })

    // Bulk upload footfall data
    case BULK_UPLOAD_FOOTFALL_DATA:
      return update(state, {
        bulkUploadFootfallData: { $merge: { isLoading: true } }
      })
    case BULK_UPLOAD_FOOTFALL_DATA_SUCCESS:
      return update(state, {
        bulkUploadFootfallData: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        }
      })
    case BULK_UPLOAD_FOOTFALL_DATA_FAILURE:
      return update(state, {
        bulkUploadFootfallData: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_BULK_UPLOAD_FOOTFALL_DATA:
      return update(state, {
        bulkUploadFootfallData: { $set: initialState.bulkUploadFootfallData }
      })

    // Get footfall areas
    case GET_FOOTFALL_AREAS:
      return update(state, {
        footfallAreas: {
          $merge: {
            ...(action.loadOptions.shouldClearExistingState && INITIAL_GET_RESULTS_PAGING_REDUCER),
            isLoading: true
          }
        }
      })
    case GET_FOOTFALL_AREAS_SUCCESS:
      return update(state, {
        footfallAreas: {
          $merge: {
            isLoading: false,
            wasLoaded: true,
            paging: {
              next: action.data?.next
            }
          },
          results: { $pushDeDuplicate: action.data.results }
        }
      })
    case GET_FOOTFALL_AREAS_FAILURE:
      return update(state, {
        footfallAreas: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_GET_FOOTFALL_AREAS:
      return update(state, {
        footfallAreas: { $set: initialState.footfallAreas }
      })

    // Create footfall areas
    case CREATE_FOOTFALL_AREAS:
      return update(state, {
        createFootfallAreas: { $merge: { isLoading: true } }
      })
    case CREATE_FOOTFALL_AREAS_SUCCESS:
      return update(state, {
        createFootfallAreas: {
          $merge: {
            isLoading: false,
            wasCreated: true,
            data: action.data
          }
        },
        footfallAreas: {
          results: { $push: [action.data] }
        }
      })
    case CREATE_FOOTFALL_AREAS_FAILURE:
      return update(state, {
        createFootfallAreas: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_CREATE_FOOTFALL_AREAS:
      return update(state, {
        createFootfallAreas: { $set: initialState.createFootfallAreas }
      })

    // Update footfall area
    case UPDATE_FOOTFALL_AREA:
      return update(state, {
        updateFootfallArea: { $merge: { isLoading: true } }
      })
    case UPDATE_FOOTFALL_AREA_SUCCESS:
      return update(state, {
        updateFootfallArea: {
          $merge: {
            isLoading: false,
            wasUpdated: true,
            data: action.data
          }
        },
        footfallAreas: {
          results: items => updateItem(items, action.data)
        }
      })
    case UPDATE_FOOTFALL_AREA_FAILURE:
      return update(state, {
        updateFootfallArea: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_UPDATE_FOOTFALL_AREA:
      return update(state, {
        updateFootfallArea: { $set: initialState.updateFootfallArea }
      })

    // Delete footfall areas
    case DELETE_FOOTFALL_AREAS:
      return update(state, {
        deleteFootfallAreas: {
          $merge: {
            isLoading: true,
            id: action.id
          }
        }
      })
    case DELETE_FOOTFALL_AREAS_SUCCESS:
      return update(state, {
        deleteFootfallAreas: {
          $merge: {
            isLoading: false,
            wasDeleted: true,
            data: action.data
          }
        },
        footfallAreas: {
          results: { $apply: items => deleteItemById(items, action.id) }
        }
      })
    case DELETE_FOOTFALL_AREAS_FAILURE:
      return update(state, {
        deleteFootfallAreas: {
          $merge: {
            isLoading: false,
            error: action.error
          }
        }
      })
    case CLEAR_DELETE_FOOTFALL_AREAS:
      return update(state, {
        deleteFootfallAreas: { $set: initialState.deleteFootfallAreas }
      })

    default:
      return state
  }
}

import { createStyles } from '../../../../../../../../styles/helpers'

import { textGrey } from '../../../../../../../../styles/const/colors'

const useStyles = createStyles({
  loadMoreOptionsButton: {
    color: textGrey,
    display: 'block',
    textAlign: 'center'
  }
})

export default useStyles
